import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Modal,
    Avatar,
    Table,
    Button,
    Radio,
    Select,
    Tag,
    Popover,
    Spin,
} from 'antd';
import LabelledSwitch from 'components/shared/LabelledSwitch';
import * as Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
    occupationTitle,
    countyName,
} from 'utils/nameformatters';
import { humanReadableHours } from 'utils/datetimeformatters';
import {
    debounce,
    sortBy,
} from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import { updateConsultantEmployer } from 'actions/consultant';
import Config from '../../config/index';
import { fetchEmployerConsultant } from '../../request';

const ApplicationModal = ({
    adminComment,
    consultant,
    confirmApplication,
    employer,
    scheduled,
    close,
    isPatching,
    denyApplication,
    rejectReasons,
    hasPatchedApplication,
    commentApplications,
    introducedLoading,
}) => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rejectMode, setRejectMode] = useState(false);
    const [deleteShifts, setDeleteShifts] = useState(false);
    const [selectedRejectReason, setSelectedRejectReason] = useState();
    const {
        adminComments, colors,
    } = Config;
    const [comment, setComment] = useState(adminComments[0].value);
    const [adminCommentUpdating, setAdminCommentUpdating] = useState(false);
    const handleUpdateConsultantComment = consultantComment => {
        dispatch(updateConsultantEmployer(consultant.id, employer.id, {
            admin_comment: consultantComment,
        }));
    };
    useEffect(() => {
        if (!isPatching && hasPatchedApplication) {
            close();
        }
    }, [
        isPatching,
        close,
        hasPatchedApplication,
    ]);
    useEffect(() => {
        setAdminCommentUpdating(introducedLoading);
    }, [introducedLoading]);

    const onConfirmApplication = () => {
        confirmApplication(consultant.id, employer.id, selectedRows.map(s => ({
            id: s.id,
            checksum: s.checksum,
        })));
    };

    function onUpdateStatus(ids, comment) {
        dispatch(commentApplications(ids, comment));
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setComment(adminComments[0].value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateAdminComment = useCallback(debounce(e => {
        setAdminCommentUpdating(true);
        handleUpdateConsultantComment(e.target.value);
    }, 500), []);

    const renderTop = () => (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        size={60}
                        src={consultant.avatar}
                    />
                    <div style={{ marginLeft: 10 }}>
                        <h5 style={{ margin: 0 }}>
                            <Link to={`/consultants/${consultant.id}`}>
                                {consultant.firstname}
                                {' '}
                                {consultant.lastname}
                            </Link>
                        </h5>
                        <div>
                            {occupationTitle(consultant.occupation)}
                        </div>
                        <div>
                            {consultant.phone}
                        </div>
                        <div>
                            {consultant.email}
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >

                        <Avatar
                            size={60}
                            src={employer.logo_url}
                        />
                        <div
                            style={{ marginLeft: 10 }}
                        >
                            <div>
                                <h5
                                    style={{ margin: 0 }}
                                >
                                    <Link to={`/employers/${employer.id}`}>
                                        {employer.name}
                                    </Link>
                                </h5>
                            </div>
                            <div>
                                {employer.department}
                            </div>
                            <div>
                                {employer.address.city}
                                ,
                                {' '}
                                {countyName(employer.county_id)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spin size='large' spinning={adminCommentUpdating}>
                <TextArea
                    defaultValue={adminComment}
                    onChange={updateAdminComment}
                />
            </Spin>
        </div>
    );

    const renderApplication = () => {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: 'Date',
            dataIndex: 'start_time',
            key: 'date',
            render: (text, r) => moment.unix(text).format('YYYY-MM-DD HH:mm-') + moment.unix(r.end_time).format('HH:mm'),
        }, {
            title: 'Break',
            dataIndex: 'shift_break',
            key: 'break',
            render: text => humanReadableHours(text),
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        }, {
            title: 'Salary',
            dataIndex: 'salary',
            key: 'salary',
        },
        {
            title: 'Comments',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, record) => {
                if (record.comment) {
                    const tagComment = adminComments.find(adminComment => adminComment.value === text);
                    const {
                        colorId,
                        display,
                        icon,
                    } = tagComment;
                    const color = colors.find(c => c.id === colorId);
                    const CommentIcon = Icon[icon];
                    return text && (
                        <Tag
                            closable
                            color={color.hex}
                            icon={<CommentIcon />}
                            onClose={e => {
                                e.preventDefault();
                                onUpdateStatus([record.id], '');
                            }}
                        >
                            {display}

                        </Tag>
                    );
                }
                return null;
            },
        }];

        const rowSelection = {
            selectedRowKeys,
            onChange: (keys, rows) => {
                setSelectedRowKeys(keys);
                setSelectedRows(rows);
            },
        };

        return (
            <div
                style={{ marginTop: 20 }}
            >
                <Table
                    columns={columns}
                    dataSource={sortBy(scheduled, 'start_time')}
                    pagination={false}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                />
            </div>
        );
    };

    const renderRejectReasons = () => (
        <div
            style={{ marginTop: 20 }}
        >
            <h6>
                Choose a reason for denying the application:
            </h6>
            <Radio.Group
                onChange={e => {
                    setSelectedRejectReason(e.target.value);
                }}
            >
                {sortBy(rejectReasons, 'order').map(r => (
                    <div
                        key={r.id}
                        style={{ padding: 5 }}
                    >
                        <Radio
                            size='large'
                            value={r.id}
                        >
                            {r.message}
                        </Radio>
                    </div>
                ))}
            </Radio.Group>
        </div>
    );

    const renderConfirmButton = () => (
        <div
            style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div>
                <Button
                    disabled={isPatching || !selectedRows.length}
                    loading={isPatching}
                    size='large'
                    style={{ visibility: rejectMode ? 'hidden' : 'visible' }}
                    type='primary'
                    onClick={() => {
                        onConfirmApplication(selectedRows);
                    }}
                >
                    Confirm booking
                </Button>
                <Button
                    danger
                    disabled={(isPatching) || (rejectMode && !selectedRejectReason) || !selectedRows.length}
                    loading={isPatching}
                    size='large'
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        if (rejectMode) {
                            denyApplication(
                                employer.id,
                                selectedRows.map(s => ({
                                    id: s.id,
                                    reason_id: selectedRejectReason,
                                })),
                                deleteShifts,
                            );
                        }
                        else {
                            setRejectMode(true);
                        }
                    }}
                >
                    Deny application
                </Button>
                <LabelledSwitch
                    hidden={!rejectMode}
                    isChecked={deleteShifts}
                    onChange={() => setDeleteShifts(!deleteShifts)}
                >
                    Delete shifts too
                </LabelledSwitch>
            </div>
        </div>
    );

    const renderCommentDropdown = () => (
        <div
            style={{
                marginTop: 20, display: 'flex', justifyContent: 'space-between',
            }}
        >
            <Select
                defaultValue={{ value: adminComments[0].value }}
                disabled={isPatching || !selectedRows.length}
                labelInValue
                size='large'
                style={{ marginLeft: 5 }}
                onChange={value => setComment(value.value)}
            >
                {adminComments.map(adminComment => <Option value={adminComment.value}>{adminComment.display}</Option>)}
            </Select>
            <Button
                disabled={isPatching || !selectedRows.length}
                ghost
                loading={isPatching}
                size='large'
                style={{
                    visibility: rejectMode ? 'hidden' : 'visible', marginLeft: 5,
                }}
                type='primary'
                onClick={() => onUpdateStatus(selectedRowKeys, comment)}
            >
                Save comment
            </Button>
        </div>
    );

    const renderContent = () => (
        <div>
            {renderTop()}
            {rejectMode ? renderRejectReasons() : renderApplication()}
            <div
                style={{
                    display: 'flex', justifyContent: 'space-between',
                }}
            >
                {renderConfirmButton()}
                {renderCommentDropdown()}
            </div>

        </div>
    );

    return (
        <Modal
            footer={null}
            title='Application'
            visible
            width={960}
            onCancel={() => {
                close();
            }}
        >
            {renderContent()}
        </Modal>
    );
};

ApplicationModal.propTypes = {
    consultant: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        avatar: PropTypes.string,
        occupation: PropTypes.number,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
    confirmApplication: PropTypes.func.isRequired,
    commentApplications: PropTypes.func.isRequired,
    employer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        logo_url: PropTypes.string,
        department: PropTypes.string,
        county_id: PropTypes.number,
        address: PropTypes.shape({ city: PropTypes.string }),
    }),
    scheduled: PropTypes.arrayOf(PropTypes.shape({})),
    close: PropTypes.func.isRequired,
    isPatching: PropTypes.bool.isRequired,
    denyApplication: PropTypes.func.isRequired,
    rejectReasons: PropTypes.arrayOf(PropTypes.shape({})),
    hasPatchedApplication: PropTypes.bool.isRequired,
};

export default ApplicationModal;
