import * as request from 'request';

export function receiveFetchTasks(tasks) {
    return { type: 'RECEIVE_FETCH_TASKS', tasks };
}

export function requestFetchTasks() {
    return { type: 'REQUEST_TASKS' };
}

const receiveUpdateTask = taskData => ({
    type: 'RECEIVE_UPDATE_TASK', task: taskData,
});

export const updateTask = (taskId, requestData) => async dispatch => {
    const resp = await request.updateTask(taskId, requestData);
    dispatch(receiveUpdateTask(resp.task));
};

export function fetchTasks() {
    return dispatch => {
        dispatch(requestFetchTasks());
        return request.fetchTasks()
            .then(resp =>  dispatch(receiveFetchTasks(resp.collection)));
    };
}
