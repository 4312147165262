import React, { useState } from 'react';
import {
    Modal,
    Select,
} from 'antd';
import { includes } from 'lodash';
import moment from 'moment';
import InputModal from '../../../components/inputmodal';
import { mapToValueTitle } from '../../../utils/nameformatters';
import config from '../../../config';

const { Option } = Select;

const EditJobAdModal = ({
    jobPosting = {},
    visible,
    setVisibility,
    settings,
    onSave,
}) => {
    const [selectedOccupation, setSelectedOccupation] = useState(2);
    const shiftTypes = [];

    settings.job_posting_shift_types.forEach(st => {
        shiftTypes.push({
            type: 'switch',
            name: `shift_type_${st.name}`,
            label: st.name,
            optionLabels: {
                on: '',
                off: '',
            },
            value: includes(jobPosting.shift_type_ids, st.id),
        });
    });

    return (
        <Modal
            footer={null}
            title='Edit job ad'
            visible={visible}
            width={860}
            onCancel={() => setVisibility(false)}
        >
            <InputModal
                close={() => setVisibility(false)}
                data={jobPosting || {}}
                inputs={
                    [{
                        type: 'input',
                        name: 'title',
                        label: 'Title',
                        value: jobPosting.title,
                    }, {
                        type: 'select',
                        name: 'occupation_id',
                        label: 'Occupation',
                        value: jobPosting.occupation_id,
                        options: mapToValueTitle(settings.occupations, 'id', 'name'),
                    }, {
                        type: 'select',
                        name: 'engagement',
                        label: 'Engagement',
                        value: jobPosting.engagement,
                        options: mapToValueTitle(settings.job_posting_engagements, 'id', 'name'),
                    }, {
                        type: 'select',
                        name: 'experience',
                        label: 'Experience',
                        value: jobPosting.experience,
                        options: config.jobPostingExperience,
                    }, {
                        type: 'multiselect',
                        name: 'specialization_ids',
                        label: 'Specializations',
                        value: jobPosting.specialization_ids || [],
                        options: settings.specializations.filter(s => s.occupation_id === selectedOccupation)
                            .map(s => (
                                <Option
                                    key={s.id}
                                    occupation={s.occupation_id}
                                    title={s.name}
                                    value={s.id}
                                >
                                    {s.name}
                                </Option>
                            )),
                    },
                    ...shiftTypes,
                    {
                        type: 'textarea',
                        name: 'work_description',
                        label: 'Description',
                        value: jobPosting.work_description,
                    },
                    {
                        type: 'textarea',
                        name: 'expectation',
                        label: 'Expectation',
                        value: jobPosting.expectation,
                    }, {
                        type: 'textarea',
                        name: 'additional_info',
                        label: 'Additional info',
                        value: jobPosting.additional_info,
                    }, {
                        type: 'switch',
                        name: 'salary_negotiable',
                        label: 'Negotiable salary',
                        optionLabels: {
                            on: 'Yes',
                            off: 'No',
                        },
                        value: jobPosting.salary_negotiable,
                    }, {
                        type: 'select',
                        name: 'min_salary',
                        label: 'Min salary',
                        value: jobPosting.min_salary,
                        options: settings.job_posting_salaries.map((s, i) => ({
                            title: s,
                            value: s,
                        })),
                    }, {
                        type: 'select',
                        name: 'max_salary',
                        label: 'Max salary',
                        value: jobPosting.max_salary,
                        options: settings.job_posting_salaries.map((s, i) => ({
                            title: s,
                            value: s,
                        })),
                    }, {
                        type: 'switch',
                        name: 'driver_license',
                        label: 'Requires drivers license',
                        optionLabels: {
                            on: 'Yes',
                            off: 'No',
                        },
                        value: jobPosting.driver_license,
                    }, {
                        type: 'date',
                        name: 'last_application_time',
                        label: 'Last application date',
                        value: (jobPosting.id ? jobPosting.last_application_time : moment().add(1, 'month').unix()) * 1000,
                    }, {
                        type: 'input',
                        name: 'contact_name',
                        label: 'Contact name',
                        value: jobPosting.contact_name,
                    }, {
                        type: 'input',
                        name: 'contact_email',
                        label: 'Contact email',
                        value: jobPosting.contact_email,
                    }, {
                        type: 'input',
                        name: 'contact_phone',
                        label: 'Contact phone',
                        value: jobPosting.contact_phone,
                    }]
                }
                onChange={(
                    formData, _, form,
                ) => {
                    console.log('formData', formData, selectedOccupation);
                    if (selectedOccupation !== formData.occupation_id) {
                        const newSspecializations = formData.specialization_ids.filter(id => {
                            const specialization = settings.specializations.find(s => s.id === id);
                            return specialization.occupation_id === formData.occupation_id;
                        });
                        setSelectedOccupation(formData.occupation_id);
                        form.updateInputsWithValue({
                            specialization_ids: newSspecializations,
                        });
                    }
                }}
                onSave={onSave}
            />
        </Modal>
    );
};

export default EditJobAdModal;
