import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Event from './Event';

const PublishEvent = ({ event: {
    created_time: eventTime,
    employer,
    meta_data: {
        shift_count: shifts,
        total_price: value,
    },

} }) => (
    <Event
        avatar={employer.logo_url}
        details={`${value.toLocaleString('sv-se')}kr`}
        header={(
            <>
                <Link to={`/employers/${employer.id}`}>{employer.name}</Link>
                {` published ${shifts} ${shifts === 1 ? 'shift' : 'shifts'}`}
            </>
        )}
        time={eventTime}
    />
);

PublishEvent.propTypes = { event: PropTypes.shape({
    created_time: PropTypes.number,
    employer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        logo_url: PropTypes.string,
    }),
    meta_data: PropTypes.shape({
        shift_count: PropTypes.number,
        total_price: PropTypes.number,
    }),
}).isRequired };

export default PublishEvent;
