import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import CheckOutlined from '@ant-design/icons';

const CopyProgress = ({ copyItems }) => {
    const copyItemsList = copyItems.filter(item => item.copy);

    return (
        <footer className='copy-progress'>
            <ul className='list'>
                {copyItemsList.map(item => (
                    <li key={item.text} className='item'>
                        {item.done ? <CheckOutlined /> : <Spin />}
                        <p className='text'>{item.text}</p>
                    </li>
                ))}
            </ul>
        </footer>
    );
};

CopyProgress.propTypes = { copyItems: PropTypes.arrayOf(PropTypes.shape({
    copy: PropTypes.bool,
    text: PropTypes.string.isRequired,
    done: PropTypes.bool.isRequired,
})) };

export default CopyProgress;
