import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    AutoComplete,
    Avatar,
    Tooltip,
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import config from 'config';

const { Option } = AutoComplete;

const TaskAssignedUser = ({
    selectedKam, onChange,
}) => {
    const [searchKamVisible, setSearchKamVisible] = useState(false);
    const [selectedKamState, setSelectedKam] = useState(selectedKam);
    const [options, setOptions] = useState(config.KAM.filter(k => k.active));
    const handleSearch = searchString => {
        setOptions(config.KAM.filter(kam => {
            if (!kam.active) {
                return false;
            }
            return kam.title.toLowerCase().includes(searchString.toLowerCase());
        }));
    };
    return (
        <Tooltip style={{ position: 'relative' }} title={selectedKam?.title}>
            <Avatar
                gap={4}
                icon={!selectedKamState ? <UserOutlined /> : undefined}
                style={{
                    backgroundColor: selectedKamState ? selectedKamState.avatarColor : undefined,
                }}
                onClick={() => {
                    if (onchange !== undefined) {
                        setSearchKamVisible(true);
                    }
                }}
            >
                {selectedKamState ? selectedKamState.initials : undefined}
            </Avatar>
            {searchKamVisible && onChange ? (
                <AutoComplete
                    autoFocus
                    getPopupContainer={trigger => trigger.parentElement}
                    open
                    placeholder='Sök'
                    style={{
                        width: '200px', position: 'absolute', top: '0px',
                    }}
                    onBlur={() => { setSearchKamVisible(false); }}
                    onSearch={handleSearch}
                    onSelect={e => {
                        // eslint-disable-next-line eqeqeq
                        const kam = config.KAM.find(k => k.value == e);
                        setSelectedKam(kam);
                        setSearchKamVisible(false);
                        if (onChange) {
                            onChange(kam);
                        }
                    }}
                >
                    {options.map(kam => (<Option key={kam.value}>{kam.title}</Option>))}
                </AutoComplete>
            ) : <></>}

        </Tooltip>
    );
};
TaskAssignedUser.propTypes = {
    selectedKam: PropTypes.shape({ title: PropTypes.string.isRequired }),
    onChange: PropTypes.func.isRequired,
};

export default TaskAssignedUser;
