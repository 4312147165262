import {
    CloseOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import {
    Button,
    Input,
    message,
    Popover,
    Switch,
} from 'antd';
import moment from 'moment';
import uuid from 'node-uuid';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';

const ShiftListImporter = ({
    addShifts,
    customShifts,
    preselectedConsultant = null,
    shouldEmptyRawInput,
}) => {
    const [rawInput, setRawInput] = useState('');
    const [inputVisible, setInputVisible] = useState(false);
    const [overwriteExisting, setOverwriteExisting] = useState(true);
    const handleInputVisible = visible => {
        setInputVisible(visible);
    };

    useEffect(() => {
        if (shouldEmptyRawInput) {
            setRawInput('');
        }
    }, [shouldEmptyRawInput]);

    const getParsedDate1 = v => {
        // D/M HH:mm - HH:mm (1/7 07:00 - 21:00)

        const matches = [...v.matchAll(/^(\d*)\D*(\d*)\D*(\d{2}).*(\d{2}).*(\d{2}).*(\d{2})$/gm)];

        return matches.map(m => ({
            dateSplit: [m[1], m[2]],
            startTime: [m[3], m[4]],
            endTime: [m[5], m[6]],
        }));
    };

    const getParsedDate2 = v => {
        // D MMMM HH.mm HH.mm (1 juli 07.00 21.00)

        const matches = [...v.matchAll(/^\W*(\d*)\W*(\w*)\D*(\d{2}).*(\d{2}).*(\d{2}).*(\d{2})$/gm)];

        const months = {
            januari: 1,
            februari: 2,
            mars: 3,
            april: 4,
            maj: 5,
            juni: 6,
            juli: 7,
            augusti: 8,
            september: 9,
            oktober: 10,
            november: 11,
            december: 12,
        };

        return matches.map(m => ({
            dateSplit: [m[1], months[m[2].toLowerCase()]],
            startTime: [m[3], m[4]],
            endTime: [m[5], m[6]],
        }));
    };

    const getParsedDate3 = v => {
        // v D/M DDD HHmm HHmm (28 1/7 mån 1700 2100)

        const matches = [...v.matchAll(/\W*\d*?\D*(\d*)\D*(\d*)\D*(\d{2})(\d{2})\D*(\d{2})(\d{2})$/gm)];

        return matches.map(m => ({
            dateSplit: [m[1], m[2]],
            startTime: [m[3], m[4]],
            endTime: [m[5], m[6]],
        }));
    };

    const getParsedDate4 = v => {
        // YYYY-MM-DD (HH:mm - HH:mm) (2019-02-22 (13:30 - 21:00))

        const matches = [...v.matchAll(/^\d{4}\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})/gm)];

        return matches.map(m => ({
            dateSplit: [m[2], m[1]],
            startTime: [m[3], m[4]],
            endTime: [m[5], m[6]],
        }));
    };

    const getParsedDate5 = v => {
        // DD-MMMM HHmm-HHmm
        function zeroPad(str) {
            switch (str.length) {
                case 4:
                    return str;
                case 3:
                    return `0${str}`;
                case 2:
                    return `${str}00`;
                case 1:
                    return `0${str}00`;
                default:
                    return str;
            }
        }

        const datePad = str => {
            switch (str.length) {
                case 1:
                    return `0${str}`;
                default:
                    return str;
            }
        };

        const months = {
            jan: 1,
            feb: 2,
            mar: 3,
            apr: 4,
            maj: 5,
            jun: 6,
            jul: 7,
            aug: 8,
            sep: 9,
            okt: 10,
            nov: 11,
            dec: 12,
        };

        const matches = [...v.matchAll(/^(\d+)-(\w{3})(?:\s)(\d{4})-(\d{4})$/gm)];

        return matches.map(a => {
            const date = a[1];
            const month = datePad(months[a[2].substr(0, 3)]);
            const startTime = zeroPad(a[3]);
            const endTime = zeroPad(a[4]);
            return {
                dateSplit: [date, month],
                startTime: [startTime.substr(0, 2), startTime.substr(2, 3)],
                endTime: [endTime.substr(0, 2), endTime.substr(2, 3)],
            };
        });
    };

    const getParsedDate6 = v => {
        const matches = [...v.matchAll(/(\d{4})-(\d{2})-(\d{2}).(\d{2}\:\d{2}).(\d{4})-(\d{2})-(\d{2}).(\d{2}\:\d{2}).?$/gm)];

        return matches.map(a => {
            const date = a[3];
            const month = a[2];
            const startTime = a[4];
            const endTime = a[8];
            const ret = {
                dateSplit: [date, month],
                startTime: [startTime.substr(0, 2), startTime.substr(3, 4)],
                endTime: [endTime.substr(0, 2), endTime.substr(3, 4)],
            };
            console.log(ret);
            return ret;
        });
    };

    const getParsedShiftTimes = v => {
        const rxs = [
            // D/M HH:mm - HH:mm ---> (1/7 07:00 - 21:00)
            /^(\d*)\/(\d*)\D*(\d{2}).*(\d{2}).*(\d{2}).*(\d{2})$/gm,

            // D MMMM HH.mm HH.mm ---> (1 juli 07.00 21.00)
            /^\W*(\d*)\s(\w*)\D*(\d{2}).*(\d{2}).*(\d{2}).*(\d{2})$/gm,

            // v D/M DDD HHmm HHmm ---> (28 1/7 mån 1700 2100)
            /^\W*\d*?\D*(\d*)\/(\d*)\D*(\d{2})(\d{2})\D*(\d{2})(\d{2})$/gm,

            // YYYY-MM-DD (HH:mm-HH:mm) ---> (2019-01-07 (17:00-21:00))
            /^\d{4}\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2}).?$/gm,

            // D-MMM HHmm-HHmm ---> (29-jun 1330-2130)
            /^(\d*)-(\D*)(\d{4})-(\d{4}).*?$/gm,

            // YYYY-MM-DD HH:MM YYYY-MM-DD HH:MM ---> (2022-08-25 07:00   2022-08-25 16:00)
            /(\d{4})-(\d{2})-(\d{2}).(\d{2}\:\d{2}).(\d{4})-(\d{2})-(\d{2}).(\d{2}\:\d{2}).?$/gm,
        ];

        let count = 0;

        rxs.forEach((r, i) => {
            const val = r.test(v);

            if (val) {
                count = i + 1;
            }
        });

        switch (parseInt(count)) {
            case 1:
                return getParsedDate1(v);
            case 2:
                return getParsedDate2(v);
            case 3:
                return getParsedDate3(v);
            case 4:
                return getParsedDate4(v);
            case 5:
                return getParsedDate5(v);
            case 6:
                return getParsedDate6(v);
            default:
                return [];
        }
    };

    const parseRawInput = v => {
        const prasedDates = getParsedShiftTimes(v);
        return prasedDates.map(({
            dateSplit,
            startTime,
            endTime,
        }) => {
            console.log('DATESPLIT', dateSplit, startTime, endTime);
            const m = moment(`${moment().format('YYYY')}-${dateSplit[1]}-${dateSplit[0]}`).startOf('day');

            // Adjust for dates that cross over to next year
            if (m.isBefore(moment())) {
                m.add(1, 'year');
            }

            const start = m.clone().add(startTime[0], 'hours').add(startTime[1], 'minutes');

            const end = m.clone();

            // Adjust for dates where the start time is earlier than the end times (shift ends the following day)
            if (endTime[0] < startTime[0]) {
                end.add(1, 'day');
            }

            end.add(endTime[0], 'hours').add(endTime[1], 'minutes');

            let price = 0;
            let shiftBreak = 0;
            const priceObj = customShifts.find(
                s => s.start_time === start.format('HH:mm') && s.end_time === end.format('HH:mm'),
            );

            if (priceObj) {
                price = priceObj.price;
                shiftBreak = priceObj.shift_break;
            }

            return {
                date: start.clone().startOf('day'),
                start,
                end,
                start_time: start.format('HH:mm'),
                end_time: end.format('HH:mm'),
                shift_break: shiftBreak,
                price,
                customShiftName: priceObj ? priceObj.name : '',
                id: uuid.v4(),
                consultant: preselectedConsultant ? preselectedConsultant.id : null,
            };
        });
    };

    return (
        <Popover
            content={(

                <div
                    style={{ width: 600 }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '8px',
                        }}
                    >
                        <CloseOutlined onClick={() => setInputVisible(false)} />
                    </div>
                    <Input.TextArea
                        rows={20}
                        value={rawInput}
                        onChange={e => {
                            setRawInput(e.target.value);
                        }}
                    />
                    <div
                        style={{
                            padding: '10px 0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <div
                            style={{
                                marginRight: 20,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            Replace existing list
                            <Switch
                                checked={overwriteExisting}
                                style={{ marginLeft: 10 }}
                                onChange={() => setOverwriteExisting(!overwriteExisting)}
                            />
                        </div>
                        <Button
                            size='large'
                            type='primary'
                            onClick={() => {
                                try {
                                    const val = rawInput;

                                    let invalid = false;

                                    const dates = parseRawInput(val);

                                    dates.forEach(parsed => {
                                        if (!parsed.start.isValid() || !parsed.end.isValid()) {
                                            invalid = true;
                                        }
                                    });

                                    if (invalid) {
                                        message.error('Hmm. Something is wrong. Cant read the data. Be nice to tech!');
                                    }
                                    else {
                                        addShifts(dates, overwriteExisting);
                                        setInputVisible(false);
                                        setRawInput('');
                                    }
                                }
                                catch (e) {
                                    message.error('Hmm. Something is wrong. Cant read the data. Be nice to tech!');
                                    throw e;
                                }
                            }}
                        >
                            Import
                        </Button>
                    </div>
                </div>
            )}
            trigger='click'
            visible={inputVisible}
            onVisibleChange={handleInputVisible}
        >
            <Button
                icon={<PlusCircleOutlined />}
                size='large'
                style={{ margin: '0 10px' }}
            >
                Add shifts from list
            </Button>
        </Popover>
    );
};

ShiftListImporter.propTypes = {
    addShifts: PropTypes.func.isRequired,
    customShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
    preselectedConsultant: PropTypes.object,
    shouldEmptyRawInput: PropTypes.bool.isRequired,
};

export default ShiftListImporter;
