import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { createLead } from 'actions/leads';
import { Input, Button } from 'antd';
import { createAd } from 'actions/externalAds';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const KEY = 'EXTERNAL_IDS';
const AdsBoard = () => {
  const [searchInput, setSearchInput] = useState('');
  const [ads, setAds] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const dispatch = useDispatch();
  const {isFetching} = useSelector((state) => state.externalAds);

  const onSetExternalIds = (id) => {
    const ids = localStorage.getItem(KEY);
    const currentIds = JSON.parse(ids);
    const filterAdsFromSavedIds = ads.filter((ad) => ad.id !== id)
    setAds(filterAdsFromSavedIds);
    if(currentIds && currentIds.includes(id)) return;
    const arr = ids ? [...JSON.parse(ids), id]: [id];
    localStorage.setItem(KEY, JSON.stringify(arr));
  }

  const onGetContactDetails = (application_contacts) => {
    if (application_contacts.length === 0) {
        return {
            contact_person: 'Not available',
            phone: 'Not available',
            email: 'Not available',
        };
    }
    const {name, description, phone, email} = application_contacts[0];
    const contact_person = name ? name : description;
    return {
        contact_person,
        phone,
        email
    }
  }

  console.log('isFetching', isFetching)

  const onCreateLead = (id) => {
    const ad = ads.find((a) => a.id === id);
    const {employer, application_contacts} = ad;
    const employerName =  employer.name;
    const contactsDetails = onGetContactDetails(application_contacts);
    const form = {
        lead_company:employerName,
        country_code: 'SWE',
        additional: 'From an external ad',
        ...contactsDetails,
    }
    return dispatch(createLead(form))
  }

  const RenderLead = (props) => {
    const {id, name, description, email, phone, employerName} = props
    const leadName = name ? name : description;
    const leadEmail = email ? email : 'No email';
    const leadPhone = phone ? phone : 'No phone number';
    return (<div className='flex border p-2 mb-2 justify-between'>
        <div className='flex flex-col'>
            <span className='font-semibold'>{leadName}</span>
            <span>{employerName}</span>
            <span>{leadEmail}</span>
            <span>{leadPhone}</span>
        </div>
        <div>
            <Button onClick={() => onCreateLead(id)}>Convert to lead</Button>
        </div>
    </div>)
  }

  const onSearch = async () => {
    const result = await axios.get(`https://jobsearch.api.jobtechdev.se/search?q=${searchInput}&offset=0&limit=50`);
    if(result.data) {
        const {hits} = result.data;
        const ids = localStorage.getItem(KEY);
        const currentIds = JSON.parse(ids);
        if(!currentIds) return setAds(hits);
        const filterAdsFromSavedIds = hits.filter((ad) => !currentIds.includes(ad.id))
        setAds(filterAdsFromSavedIds);
    }
  }

  const onSaveExternalAd = (adId) => {
    const ad = ads.find((a) => a.id === adId);
    const {id, headline, salary_type, employer, webpage_url, driving_license_required, application_deadline, application_contacts, description} = ad;
    const employerName =  employer.name;
    const contactsDetails = onGetContactDetails(application_contacts);
    
    const dataToSave = {
        webpage_url:webpage_url,
        salary_description:salary_type.label,
        driver_license:driving_license_required,
        external_id:id,
        title:headline,
        application_deadline,
        work_description:description.text,
        employer_name: employerName,
        ...contactsDetails,
    }
    onSetExternalIds(adId);
    return dispatch(createAd(dataToSave))
  }
  
  const renderAds = (ads) => ads.map(({ id, driving_license_required, salary_type, headline, description, duration, employer, application_contacts, application_deadline }, index) => {
    const toogleText = isOpen[id] ? 'Close description' : 'Open description';
    return (<div key={id} className='flex flex-col mb-8 border-b border-gray-400'>
    <div className='font-semibold cursor-pointer' onClick={() => setIsOpen({ ...isOpen, [id]: !isOpen[id]})}>#{index+1} {headline}</div>
    <div className='italic'>{moment(application_deadline).format('YYYY-MM-DD')}</div>
    <div className='font-semibold'>{employer.name}</div>
    <div>{duration.label}</div>
    {salary_type.label && <div>{salary_type.label}</div>}
    {driving_license_required && <div>Need driver license</div>}
    {application_contacts.length > 0 ? <RenderLead {...{ id, employerName: employer.name, ...application_contacts[0]}}/> : <div className='font-semibold mb-2'>No contact details</div>}
    <div className='flex mb-2 justify-between w-full'>
        <Button onClick={() => setIsOpen({ ...isOpen, [id]: !isOpen[id]})}>{toogleText}</Button>
        <Button onClick={() => onSaveExternalAd(id)}>Save ad</Button>
    </div>
    { isOpen[id] && (<div className='flex flex-col'>
      <p className='mb-2'>{description.text}</p>
      <Button onClick={() => setIsOpen({ ...isOpen, [id]: false})}>Close</Button>
    </div>)}
  </div>)
  })

  const adsResult = useMemo(() => renderAds(ads), [ads, isOpen]);

  return (
    <main className='flex w-full m-8 flex-col'>
      <div style={{maxWidth: 400}} className='flex mx-auto w-full mb-8'>
        <Input onChange={({target}) => setSearchInput(target.value)} value={searchInput} placeholder='Search' className='w-full' />
        <Button onClick={onSearch} >Search</Button>
      </div>
      {ads.length > 0 ? (<div style={{maxWidth: 600}} className='mx-auto w-full'>
        {adsResult}
      </div>): <div className='text-center w-full'>No ads available</div>}
    </main>
  );
};

export default AdsBoard;
