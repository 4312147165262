import React, {
    useEffect,
    useState,
} from 'react';
import {
    Alert,
    Modal,
    Select,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchEmployerProfiles } from '../../request';

import InputModal from '../inputmodal';
import { mapToValueTitle } from '../../utils/nameformatters';
import { calculateSalary } from '../../utils/salary';

const { Option } = Select;

const EditVacancyModal = ({
    countryCode,
    vacancy = {},
    visible,
    setVisibility,
    settings,
    onSave,
    employerId,
}) => {
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState();
    const [selectedOccupation, setSelectedOccupation] = useState(vacancy?.id ? vacancy.occupation_id : 1);
    const [price, setPrice] = useState(vacancy?.id ? vacancy.long_term_details.price : '');
    const [margin, setMargin] = useState(vacancy?.id ? vacancy.long_term_details.margin : '');

    let start; let end; let employment_scope; let last_application_time;

    if (vacancy.id) {
        ({
            start_time: start,
            end_time: end,
            employment_scope,
            last_application_time,
        } = vacancy.long_term_details);
    }
    else {
        start = new Date().getTime() / 1000;
        end = new Date().getTime() / 1000;
        employment_scope = 100;
    }
    const [period] = useState(start && end ? [moment.unix(start), moment.unix(end)] : null);

    useEffect(() => {
        fetchEmployerProfiles(employerId)
            .then(res => setProfiles(res.profiles.filter(profile => !profile.is_long_term)));
    }, []);
    return (
        <Modal
            footer={null}
            title={`${vacancy ? 'Edit' : 'Create'} vacancy`}
            visible={visible}
            width={600}
            onCancel={() => setVisibility(false)}
        >
            {vacancy.id && (vacancy.booking?.id || !!vacancy.applications.length)
                ? (
                    <Alert
                        // eslint-disable-next-line no-nested-ternary
                        description={
                            `There are some ${vacancy.booking?.id ? 'booked shifts' : 'pending applications'} associated`
                        }
                        message='Job profile is in use'
                        showIcon
                        type='warning'
                    />
                )
                : ''}
            <InputModal
                close={() => setVisibility(false)}
                data={vacancy || {}}
                inputs={
                    [{
                        type: 'input',
                        name: 'name',
                        label: 'Name',
                        value: `${vacancy.id ? vacancy.name : ''}`,
                    }, {
                        type: 'select',
                        name: 'jobProfile',
                        label: 'Base job profile',
                        options: profiles.map(profile => ({
                            title: profile.name,
                            value: profile.id,
                        })),
                    }, {
                        type: 'custom',
                        render: () => (
                            <React.Fragment key='profile-info'>
                                <a href='profiles'>Create new job profile</a>
                                <p className='profile-hint'>
                                    {vacancy.id
                                        ? `Original base profile: ${vacancy.name} (leave empty if you want to keep this)`
                                    // eslint-disable-next-line max-len
                                        : 'The job profile data will be copied to the long-term vacancy on creation. The long-term vacancy will NOT update automatically with the profile – to update, edit the long-term vacancy and link the profile again.'}

                                </p>
                            </React.Fragment>
                        ),
                    }, {
                        type: 'dateRange',
                        name: 'period',
                        label: 'Employment period',
                        value: period,
                        isRequired: true,
                        disabledDate: current => current && current < moment().subtract(1, 'day').endOf('day'),
                    }, {
                        type: 'date',
                        name: 'last_application_time',
                        label: 'Last application date',
                        value: last_application_time ? last_application_time * 1000 : null,
                        isRequired: false,
                    }, {
                        type: 'input',
                        inputType: 'number',
                        name: 'price',
                        label: 'Price (in SEK)',
                        value: price,
                        isRequired: true,
                    }, {
                        type: 'input',
                        inputType: 'number',
                        name: 'margin',
                        label: 'Margin (in percent)',
                        value: margin,
                        isRequired: true,
                    }, {
                        type: 'input',
                        inputType: 'number',
                        name: 'salary',
                        label: 'salary (in SEK)',
                        value: calculateSalary(price, margin, countryCode),
                        isRequired: true,
                        isDisabled: true,
                    }, {
                        type: 'input',
                        inputType: 'number',
                        name: 'employment_scope',
                        label: 'Scope of employment (in %)',
                        value: employment_scope,
                        isRequired: true,
                    }, {
                        type: 'select',
                        name: 'occupation_id',
                        label: 'Occupation',
                        options: mapToValueTitle(settings.occupations, 'id', 'name'),
                        value: vacancy.id ? vacancy.occupation_id : 1,
                    }, {
                        type: 'multiselect',
                        name: 'specialization_ids',
                        label: 'Specializations',
                        options: settings.specializations.filter(s => s.occupation_id === selectedOccupation)
                            .map(s => (
                                <Option
                                    key={s.id}
                                    occupation={s.occupation_id}
                                    title={s.name}
                                    value={s.id}
                                >
                                    {s.name}
                                </Option>
                            )),
                        value: vacancy.id ? [...vacancy.specialization_ids] : [],
                    }, {
                        type: 'multiselect',
                        name: 'skills',
                        label: 'Skills',
                        options: settings.skills.filter(s => s.occupation_id === selectedOccupation)
                            .map(s => (
                                <Option
                                    key={s.id}
                                    occupation={s.occupation_id}
                                    title={s.name}
                                    value={s.id}
                                >
                                    {s.name}
                                </Option>
                            )),
                        value: vacancy.id ? [...vacancy.skills] : [],
                    }, {
                        type: 'textarea',
                        name: 'description',
                        label: 'Description',
                        value: vacancy.id ? vacancy.description : '',
                    }, {
                        type: 'textarea',
                        name: 'qualifications',
                        label: 'Qualifications',
                        value: vacancy.id ? vacancy.qualifications : '',
                    }, {
                        type: 'textarea',
                        name: 'additional_info',
                        label: 'Additional info',
                        value: vacancy.id ? vacancy.additional_info : '',
                    }, {
                        type: 'switch',
                        name: 'driver_license',
                        label: 'Driver\'s license',
                        optionLabels: {
                            on: 'Yes',
                            off: 'No',
                        },
                        value: vacancy.id ? vacancy.driver_license : false,
                    }]
                }
                onChange={(formData, _, form) => {
                    if (selectedProfile !== formData.jobProfile) {
                        setSelectedProfile(formData.jobProfile);
                        const profile = profiles.find(p => p.id === formData.jobProfile);
                        if (profile) {
                            form.updateInputsWithValue({ ...profile });
                            setSelectedOccupation(profile.occupation_id);
                        }
                    }
                    else if (selectedOccupation !== formData.occupation_id) {
                        const newSkills = formData.skills.filter(id => {
                            const skill = settings.skills.find(s => s.id === id);
                            return skill.occupation_id === formData.occupation;
                        });
                        const newSspecializations = formData.specialization_ids.filter(id => {
                            const specialization = settings.specializations.find(s => s.id === id);
                            return specialization.occupation_id === formData.occupation;
                        });
                        setSelectedOccupation(formData.occupation_id);
                        form.updateInputsWithValue({
                            skills: newSkills,
                            specialization_ids: newSspecializations,
                        });
                    }
                    else if (price != formData.price) {
                        setPrice(formData.price);
                        form.updateInputsWithValue({
                            salary: calculateSalary(formData.price, margin, countryCode),
                        });
                    }
                    else if (margin != formData.margin) {
                        setMargin(formData.margin);
                        form.updateInputsWithValue({
                            salary: calculateSalary(price, formData.margin, countryCode),
                        });
                    }
                }}
                onSave={onSave}
            />
        </Modal>
    );
};

EditVacancyModal.propTypes = {
    countryCode: PropTypes.string.isRequired,
    vacancy: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        qualifications: PropTypes.string,
        additional_info: PropTypes.string,
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        driver_license: PropTypes.bool,
        employment_scope: PropTypes.number.isRequired,
        hourly_rate: PropTypes.number.isRequired,
        period: PropTypes.shape({
            start_time: PropTypes.number,
            end_time: PropTypes.number,
        }),
        long_term_details: PropTypes.shape({
            start_time: PropTypes.number,
            end_time: PropTypes.number,
            hourly_rate: PropTypes.number,
            employment_scope: PropTypes.number,
        }),
        jobProfile: PropTypes.number,
        occupation_id: PropTypes.number,
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        skills: PropTypes.arrayOf(PropTypes.number),
        booking: PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
        }),
        applications: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
        })),
    }),
    employerId: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        occupations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        specializations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            occupation_id: PropTypes.number,
        })),
        skills: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            occupation_id: PropTypes.number,
        })),
    }),
    visible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
};

export default EditVacancyModal;
