import React from 'react';
import { styles } from './styles.scss';

class Label extends React.Component {
    constructor(props) {
        super(props);
    }

    renderIsRequired() {
        if (this.props.required) {
            return (
                <span className='mp-input__required-indicator'>*</span>
            );
        }
    }

    render() {
        if (this.props.label) {
            return (
                <div
                    className='mp-input__label-wrapper'
                    style={{
                        display: this.props.inline ? 'inline-block' : 'block',
                        marginRight: this.props.inline ? 10 : 0,
                    }}
                >
                    <label className='mp-input__label'>
                        {this.props.label}
                        {this.renderIsRequired()}
                    </label>
                </div>
            );
        }
    }
}

Label.defaultProps = {
    label: '',
    required: false,
    inline: false,
};

export default Label;
