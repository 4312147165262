const initialState = {
    userMarketingSources: [],
    fetchCompleted: false,
    fetchFailed: false,
    fetchOtherCompleted: false,
};

export function userMarketingSources(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_FETCH_USER_MARKETING_SOURCES':

            return {
                ...state,
                marketingSources: action.marketingSources,
                fetchCompleted: true,
            };

        case 'RECEIVE_CREATE_USER_MARKETING_SOURCE':

            return {
                ...state,
                fetchFailed: false,
                fetchCompleted: true,
                marketingSources: [
                    ...state.marketingSources,
                    {
                        ...action.marketingSource,
                        register_times: [],
                    },
                ],
            };

        case 'CREATE_USER_MARKETING_SOURCE_FAILED':

            return {
                ...state,
                fetchFailed: true,
                marketingSources: state.marketingSources,
                errorMessage: action.message,
            };

        case 'USER_MARKETING_SOURCE_INACTIVATED':

            return {
                ...state,
                marketingSources: state.marketingSources.map(option => {
                    if (option.id === action.id) {
                        return {
                            id: option.id,
                            count: option.count,
                            inactive: true,
                            title: option.title,
                        };
                    }

                    return option;
                }),
                fetchCompleted: true,
            };

        case 'RECEIVE_USER_OTHER_MARKETING_SOURCES':

            return {
                ...state,
                fetchOtherCompleted: true,
                otherMarketingSources: action.otherMarketingSources,
            };

        default:
            return state;
    }
}
