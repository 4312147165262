import React from 'react';
import {
    Select,
    DatePicker,
    ConfigProvider,
    Row,
    Col,
    Divider,
    Checkbox,
    Button,
    Radio,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import PropTypes from 'prop-types';
import LabelledSwitch from '../../components/shared/LabelledSwitch';

function LtvFilters({
    setFilters,
    filters,
    occupations,
    employers,
    skills,
    specializations,
    countries,
    counties,
}) {
    const { Option } = Select;
    const resetFilters = () => {
        setFilters({
            skills: [],
            specializations: [],
            timeRange: {
                to: null,
                from: null,
            },
            occupations: [],
            employers: [],
            withApplications: false,
            withBooking: false,
            includeTestAccounts: false,
            countries: [],
            counties: [],
        });
    };
    return (
        <>
            <Divider orientation='left'>Set details filter</Divider>
            <Row
                gutter={[{
                    xs: 8, sm: 16, md: 24, lg: 24,
                }, 15]}
            >
                <Col span={6}>
                    <Select
                        allowClear
                        className='filters-ltvs'
                        defaultValue={[]}
                        mode='multiple'
                        placeholder='Add occupations filter'
                        value={filters.occupations}
                        onChange={val => setFilters(prevState => ({
                            ...prevState,
                            occupations: val,
                        }))}
                    >
                        {occupations.map(occupation => (
                            <Option className='mp-input__options' value={occupation.id}>{occupation.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        allowClear
                        className='filters-ltvs'
                        defaultValue={[]}
                        mode='multiple'
                        optionFilterProp='label'
                        placeholder='Add employer filter'
                        value={filters.employers}
                        onChange={val => setFilters(prevState => ({
                            ...prevState,
                            employers: val,
                        }))}
                    >
                        {employers.map(employer => (
                            <Option className='mp-input__options' value={employer.id}>{employer.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        allowClear
                        className='filters-ltvs'
                        defaultValue={[]}
                        mode='multiple'
                        optionFilterProp='label'
                        placeholder='Add skills filter'
                        value={filters.skills}
                        onChange={val => setFilters(prevState => ({
                            ...prevState,
                            skills: val,
                        }))}
                    >
                        {skills.map(skill => <Option className='mp-input__options' value={skill.name}>{skill.name}</Option>)}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        allowClear
                        className='filters-ltvs'
                        defaultValue={[]}
                        mode='multiple'
                        placeholder='Add specializations filter'
                        value={filters.specializations}
                        onChange={val => setFilters(prevState => ({
                            ...prevState,
                            specializations: val,
                        }))}
                    >
                        {specializations.map(specialization => (
                            <Option className='mp-input__options' value={specialization.name}>{specialization.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Row>
                        <Col span={24}>
                            <Checkbox
                                checked={filters.withBooking}
                                disabled={filters.withApplications}
                                onChange={val => setFilters(prevState => ({
                                    ...prevState,
                                    withBooking: val.target.checked,
                                }))}
                            >
                                Has booking
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox
                                checked={filters.withApplications}
                                disabled={filters.withBooking}
                                onChange={val => setFilters(prevState => ({
                                    ...prevState,
                                    withApplications: val.target.checked,
                                }))}
                            >
                                Has applications
                            </Checkbox>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <Select
                        allowClear
                        className='filters-ltvs'
                        defaultValue={[]}
                        mode='multiple'
                        placeholder='Add country filter'
                        value={filters.countries}
                        onChange={val => setFilters(prevState => ({
                            ...prevState,
                            countries: val,
                            counties: prevState.counties
                                .filter(county => val.includes(counties
                                    .find(c => c.id === county).country_code)),
                        }))}
                    >
                        {countries.map(country => (
                            <Option className='mp-input__options' value={country.value}>{country.name}</Option>
                        ))}
                    </Select>
                    <div style={{ height: 5 }} />
                    <Select
                        allowClear
                        className='filters-ltvs'
                        defaultValue={[]}
                        mode='multiple'
                        placeholder='Add county filter'
                        value={filters.counties}
                        onChange={val => setFilters(prevState => ({
                            ...prevState,
                            counties: val,
                        }))}
                    >
                        {counties.filter(county => (filters.countries.length
                            ? filters.countries.includes(county.country_code)
                            : true))
                            .map(county => (
                                <Option className='mp-input__options' value={county.id}>{county.name}</Option>
                            ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                        }}
                    >
                        <LabelledSwitch
                            className='consultantsfilter__test-accounts'
                            isChecked={filters.includeTestAccounts}
                            onChange={() => setFilters(prevState => ({
                                ...prevState,
                                includeTestAccounts: !prevState.includeTestAccounts,
                            }))}
                        >
                            Include test accounts
                        </LabelledSwitch>
                    </div>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Button danger icon={<ReloadOutlined />} onClick={resetFilters}>
                        Reset filters
                    </Button>
                </Col>
            </Row>
            <Divider orientation='left'>Set date range filters</Divider>
            <Row
                gutter={{
                    xs: 8, sm: 16, md: 24, lg: 24,
                }}
            >
                <ConfigProvider
                    locale={en_GB}
                >
                    <Col span={6}>
                        <Row gutter={[0, 5]}>
                            <Col span={3}>
                                From:
                            </Col>
                            <Col span={21}>
                                <DatePicker
                                    disabledDate={
                                        current => current
                                            && filters.timeRange.to
                                            && current > moment.unix(filters.timeRange.to).endOf('day')
                                    }
                                    size='12'
                                    style={{
                                        height: '32px',
                                    }}
                                    value={filters.timeRange.from ? moment.unix(filters.timeRange.from) : null}
                                    onChange={date => {
                                        setFilters(prevState => ({
                                            ...prevState,
                                            timeRange: {
                                                ...prevState.timeRange,
                                                from: date ? date.unix() : null,
                                            },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col span={3}>
                                To:
                            </Col>
                            <Col span={21}>
                                <DatePicker
                                    disabledDate={
                                        current => current
                                            && filters.timeRange.from
                                            && current < moment.unix(filters.timeRange.from).endOf('day')
                                    }
                                    size='12'
                                    style={{
                                        height: '32px',
                                    }}
                                    value={filters.timeRange.to ? moment.unix(filters.timeRange.to) : null}
                                    onChange={date => {
                                        setFilters(prevState => ({
                                            ...prevState,
                                            timeRange: {
                                                ...prevState.timeRange,
                                                to: date ? date.unix() : null,
                                            },
                                        }));
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ConfigProvider>
                <Col span={6}>
                    <Radio.Group
                        disabled={!filters.timeRange.from && !filters.timeRange.to}
                        options={[
                            {
                                label: 'Overlapping date range',
                                value: 'overlapping',
                            }, {
                                label: 'Inside date range',
                                value: 'inside',
                            },
                        ]}
                        value={filters.timeRangeFilteringMode}
                        onChange={e => {
                            setFilters(prevState => ({
                                ...prevState,
                                timeRangeFilteringMode: e.target.value,
                            }));
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

LtvFilters.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        occupations: PropTypes.arrayOf(PropTypes.number).isRequired,
        employers: PropTypes.arrayOf(PropTypes.number).isRequired,
        skills: PropTypes.arrayOf(PropTypes.string).isRequired,
        specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
        timeRange: PropTypes.shape({
            from: PropTypes.number,
            to: PropTypes.number,
        }).isRequired,
        timeRangeFilteringMode: PropTypes.string.isRequired,
        withBooking: PropTypes.bool.isRequired,
        withApplications: PropTypes.bool.isRequired,
        includeTestAccounts: PropTypes.bool.isRequired,
        countries: PropTypes.arrayOf(PropTypes.string).isRequired,
        counties: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    occupations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    employers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    skills: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    specializations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    countries: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
    counties: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        country_code: PropTypes.string.isRequired,
    })).isRequired,
};

export default LtvFilters;
