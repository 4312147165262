/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateTask } from 'actions/tasks';
import {
    AutoComplete,
    Avatar,
    Card,
    Col,
    DatePicker,
    Form,
    Modal,
    Row,
    Space,
    Timeline,
    Tooltip,
} from 'antd';

import Meta from 'antd/lib/card/Meta';
import TextArea from 'antd/lib/input/TextArea';
import {
    HomeOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { occupationTitle } from 'utils/nameformatters';
import config from 'config';
import { debounce } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TaskAssignedUser from '../taskassigneduser';
import './styles.scss';

const TaskModal = ({
    task,
    visible,
    onCancel,
    updateTask,
}) => {
    const selectedKam = task ? config.KAM.find(kam => kam.value === task.admin_id_assigned) : null;
    const {
        employer, user,
    } = task;
    const updateTaskHandler = requestData => {
        updateTask(task.id, requestData);
    };

    const handleTitleChange = useCallback(debounce(e => {
        updateTaskHandler({ title: e.currentTarget.value });
    }, 1000), []);

    const handleDescriptionChange = useCallback(debounce(e => {
        updateTaskHandler({ description: e.currentTarget.value });
    }, 1000), []);
    return (
        <Modal
            className='taskmodal'
            destroyOnClose
            footer={null}
            open={visible}
            width={1000}
            onCancel={onCancel}
        >
            <Row>
                <Col span={12}>

                    <Form layout='vertical'>
                        <Form.Item label='Task Status'>

                            <AutoComplete
                                defaultValue={task?.status}
                                options={config.taskStatusOptions}
                                style={{
                                    width: 200,
                                }}
                                onSelect={status => {
                                    updateTaskHandler({ status });
                                }}
                            />
                        </Form.Item>

                        <Form.Item label='Title'>
                            <TextArea
                                defaultValue={task.title}
                                placeholder='Task Title'
                                rows={1}
                                onChange={handleTitleChange}
                            />
                        </Form.Item>
                        <Form.Item label='Description'>
                            <TextArea
                                defaultValue={task.description}
                                placeholder='Task description'
                                onChange={handleDescriptionChange}
                            />
                        </Form.Item>
                        <Form.Item label='Assigned Navigator'>
                            <Card
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Meta
                                    avatar={(
                                        <TaskAssignedUser
                                            selectedKam={selectedKam}
                                            onChange={kam => {
                                                updateTaskHandler({
                                                    admin_id_assigned: kam.value,
                                                });
                                            }}
                                        />
                                    )}
                                    title={selectedKam?.title}
                                />
                            </Card>

                        </Form.Item>
                        <Form.Item label='Due Date'>
                            <DatePicker
                                defaultValue={task.due_date_time ? moment.unix(task.due_date_time) : undefined}
                                onChange={date => updateTaskHandler({
                                    due_date_time: date ? date.startOf('day').unix() : '',
                                })}
                            />
                        </Form.Item>
                        <Form.Item label='Planned Date'>
                            <DatePicker
                                defaultValue={task.planned_date_time ? moment.unix(task.planned_date_time) : undefined}
                                onChange={date => updateTaskHandler({
                                    planned_date_time: date ? date.startOf('day').unix() : '',
                                })}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        {user ? (
                            <Card>
                                <Meta
                                    avatar={(
                                        <Link target='_blank' to={`/consultants/${user.id}`}>
                                            <Avatar icon={<UserOutlined />} src={user.avatar} />
                                        </Link>
                                    )}
                                    description={(
                                        <>
                                            <section>
                                                Occupation:
                                                {' '}
                                                {user.occupation_id ? occupationTitle(user.occupation_id) : undefined}
                                            </section>
                                            <section>
                                                Phone:
                                                {' '}
                                                {user.phone}
                                            </section>
                                            <section>
                                                Email:
                                                {' '}
                                                {user.email}
                                            </section>
                                        </>
                                    )}
                                    title={`${user.firstname} ${user.lastname}`}
                                />

                            </Card>
                        ) : <></>}
                        { employer
                            ? (
                                <Card title='Employer'>
                                    <Link to={`/employers/${employer.id}`}>
                                        <Meta
                                            avatar={(
                                                <Avatar
                                                    icon={<HomeOutlined />}
                                                    src={employer.logo_url}
                                                />
                                            )}
                                            // description='This is the description'
                                            title={`${employer.name} ${employer.department}`}
                                        />
                                    </Link>
                                </Card>
                            )
                            : <></>}
                        <Timeline mode='alternate'>
                            {task.activities.map(a => (
                                <Timeline.Item
                                    key={a.id}
                                    label={(
                                        <Tooltip
                                            title={
                                                `${config.KAM.find(kam => (
                                                    kam.value === a.admin_id_created))?.title} ${moment.unix(a.created_time).format('YYYY-MM-DD HH:MM:ss')}`
                                            }
                                        >
                                            {moment.unix(a.created_time).fromNow()}
                                        </Tooltip>
                                    )}
                                    position='left'
                                >
                                    {a.text}
                                </Timeline.Item>
                            ))}
                            {/* <Timeline.Item dot={<></>} position='right'>
                                <Comment
                                    // actions={actions}
                                    author={<a>Han Solo</a>}
                                    avatar={<Avatar alt='Han Solo' src='https://joeschmoe.io/api/v1/random' />}
                                    content={(
                                        <p>
                                            Försökte Ringa Gunilla, men svarade inte, försöker igen imorgon.
                                        </p>
                                    )}
                                    datetime={(
                                        <Tooltip title='2016-11-22 11:22:33'>
                                            <span>8 hours ago</span>
                                        </Tooltip>
                                    )}
                                />

                            </Timeline.Item> */}
                        </Timeline>
                        {/* <TextArea /> */}
                        {/* <Button>Add Comment</Button> */}
                    </Space>
                </Col>
            </Row>
        </Modal>
    );
};
TaskModal.propTypes = {
    task: PropTypes.shape({
        id: PropTypes.number.isRequired,
        admin_id_assigned: PropTypes.number.isRequired,
        admin_id_created: PropTypes.number.isRequired,
        planned_date_time: PropTypes.number.isRequired,
        due_date_time: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstname: PropTypes.string.isRequired,
            lastname: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            occupation_id: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            avatar: PropTypes.string.isRequired,
        }),
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            logo_url: PropTypes.string.isRequired,
            department: PropTypes.string.isRequired,
        }),
        activities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            created_time: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
        })),
    }),
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
};

export default connect(
    null,
    dispatch => bindActionCreators({ updateTask }, dispatch),
)(TaskModal);
