import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    sortBy,
    groupBy,
    map,
} from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchConsultantPayments } from 'actions/consultant';

import { consultantInvoiceStatusTitle } from 'utils/nameformatters';
import {
    Table,
    Col,
    Collapse,
    Spin,
    Empty,
    Statistic,
    Row,
} from 'antd';
import './styles.scss';

const ConsultantSalaries = ({
    consultant,
    payments,
    hasFetchedPayments,
    pendingSalary,
    preliminarySalary,
    // eslint-disable-next-line no-shadow
    fetchConsultantPayments,
}) => {
    useEffect(() => {
        fetchConsultantPayments(consultant.id);
    }, [fetchConsultantPayments, consultant.id]);

    const childTable = data => {
        // Sort payments by date
        const dataSource = sortBy(data, r => r.start_time);

        const columns = [
            {
                title: 'Employer',
                dataIndex: 'employer',
                key: 'employer',
                width: 300,
                render: value => (
                    <Link to={`/employers/${value.id}`}>{value.name}</Link>
                ),
            },
            {
                title: 'Shift ID',
                dataIndex: '',
                key: 'shift_id',
                render: value => (
                    <Link to={`/employers/${value.employer.id}/shifts/${value.shift_id}`}>{value.shift_id}</Link>
                ),
            },
            {
                title: 'Working occasion',
                dataIndex: '',
                key: 'start_time',
                render: value => `${moment.unix(value.start_time).format('DD MMM')} ${moment.unix(value.start_time).format('HH:mm')} - ${moment.unix(value.end_time).format('HH:mm')}`,
            },
            {
                title: 'Total (salary)',
                dataIndex: '',
                key: 'net_amount',
                render: value => (
                    <div>
                        <span>
                            Netto:
                            {' '}
                            {value.net_amount}
                            {' '}
                            SEK
                        </span>
                        <br />
                        <span>
                            Brutto:
                            {' '}
                            {value.gross_amount}
                            {' '}
                            SEK
                        </span>
                        <br />
                        <span>
                            Pension:
                            {' '}
                            {value.pension || 0}
                            {' '}
                            SEK
                        </span>
                    </div>
                ),
            },
            {
                title: 'Total (invoice)',
                dataIndex: 'consultant_fee',
                key: 'consultant_fee',
                render: value => `${value} SEK`,
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                size='middle'
            />
        );
    };

    const renderTable = (data, date) => {
        const columns = [
            {
                title: 'Status',
                dataIndex: '',
                key: 'invoice_status',
                width: 110,
                render: value => {
                    if (value.invoice_type === 2) {
                        return (
                            <div
                                style={{
                                    fontWeight: 500,
                                    color: value.invoice_status === 5 ? '#5fa94e' : '',
                                }}
                            >
                                {consultantInvoiceStatusTitle(value.invoice_status)}
                            </div>
                        );
                    }

                    return (
                        <div
                            style={{
                                fontWeight: 500,
                                color: value.payment_date.length ? '#5fa94e' : '',
                            }}
                        >
                            {value.payment_date.length ? 'Paid' : 'Upcoming' }
                        </div>
                    );
                },
            },
            {
                title: 'Type',
                dataIndex: 'invoice_type',
                key: 'invoice_type',
                width: 90,
                render: value => {
                    const text = value === 1 ? 'Salary' : 'Invoice';

                    return (
                        <span
                            style={{ fontWeight: 700 }}
                        >
                            {text}
                        </span>
                    );
                },
            },
            {
                title: 'Payment date',
                dataIndex: 'payment_date',
                key: 'payment_date',
                width: 170,
            },
            {
                title: 'Planned payment date',
                dataIndex: 'planned_payment_date',
                key: 'planned_payment_date',
                width: 200,
            },
            {
                title: 'Total (salary)',
                dataIndex: '',
                key: 'net_amount',
                render: value => (
                    <div>
                        <span>
                            Netto:
                            {value.net_amount}
                            {' '}
                            SEK
                        </span>
                        <br />
                        <span>
                            Brutto:
                            {value.gross_amount}
                            {' '}
                            SEK
                        </span>
                        <br />
                        <span>
                            Pension:
                            {value.pension || 0}
                            {' '}
                            SEK
                        </span>
                    </div>
                ),
            },
            {
                title: 'Total (invoice)',
                dataIndex: 'consultant_fee',
                key: 'consultant_fee',
                render: value => `${value} SEK`,
            },
        ];

        let header;

        if (date === 'Not paid') {
            header = (
                <div
                    style={{
                        textTransform: 'capitalize', fontSize: 20,
                    }}
                >
                    {data.length}
                    {' '}
                    payments
                </div>
            );
        }
        else {
            header = (
                <div
                    style={{
                        textTransform: 'capitalize', fontSize: 20,
                    }}
                >
                    {moment(date).format('MMMM')}
                    ,
                    {' '}
                    {data.length}
                    {' '}
                    payments
                </div>
            );
        }

        return (
            <Collapse.Panel
                key={date}
                header={header}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    expandedRowRender={record => childTable(record.scheduled)}
                    locale={{ emptyText: 'Nada, Njet, Nothing...' }}
                    pagination={false}
                    scroll={{ x: 1000 }}
                />
            </Collapse.Panel>
        );
    };

    const renderYear = (year, key) => {
        const grouped = groupBy(year, item => {
            if (item.payment_date) {
                return moment(item.payment_date).format('YYYY-MM');
            }

            return 'Not paid';
        });

        return (
            <div>
                <h3
                    style={{ marginTop: 20 }}
                >
                    {key}

                </h3>
                <Collapse accordion>
                    {map(grouped, (item, k) => renderTable(item, k))}
                </Collapse>
            </div>
        );
    };

    const renderContent = () => {
        const groupedByYear = groupBy(payments, payment => {
            if (payment.payment_date) {
                return moment(payment.payment_date).format('YYYY');
            }

            return 'Not paid';
        });

        if (!hasFetchedPayments) {
            return (
                <div
                    style={{ textAlign: 'center' }}
                >
                    <Spin />
                </div>
            );
        }

        return (
            <div
                style={{
                    flexDirection: 'column-reverse',
                    display: 'flex',
                }}
            >
                {map(groupedByYear, (year, key) => renderYear(year, key))}
            </div>
        );
    };

    return (
        <div
            className='content-card-full'
            style={{}}
        >
            <Row>
                <Col span={12}>
                    <Statistic
                        decimalSeparator=','
                        groupSeparator=''
                        precision={2}
                        title={`Planned payments (${consultant.currency})`}
                        value={pendingSalary}
                    />

                </Col>
                <Col span={12}>
                    <Statistic
                        decimalSeparator=','
                        groupSeparator=''
                        precision={2}
                        title={`Waiting for attests or not worked yet: (${consultant.currency})`}
                        value={preliminarySalary}
                    />
                </Col>
            </Row>
            {hasFetchedPayments && !payments.length ? <Empty /> : renderContent()}
        </div>
    );
};

ConsultantSalaries.propTypes = {
    consultant: PropTypes.shape({
        id: PropTypes.number,
        currency: PropTypes.string,
    }),
    payments: PropTypes.arrayOf(PropTypes.shape({})),
    hasFetchedPayments: PropTypes.bool,
    fetchConsultantPayments: PropTypes.func,
    pendingSalary: PropTypes.number,
    preliminarySalary: PropTypes.number,
};

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    payments: state.consultant.payments,
    hasFetchedPayments: state.consultant.hasFetchedPayments,
    pendingSalary: state.consultant.pendingSalary,
    preliminarySalary: state.consultant.preliminarySalary,
});

export default connect(
    state => mapStateToProps(state),
    dispatch => bindActionCreators({ fetchConsultantPayments }, dispatch),
)(ConsultantSalaries);
