import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createJobProfile } from 'actions/job';
import ProfileCard from './profile';
import './styles.scss';

const EmployerProfiles = ({
    profiles,
    settings,
    employerId,
}) => (
    <div className='mpa-employer-tab-content'>
        {profiles.filter(p => p.status === 1 && !p.is_long_term).map(profile => (
            <ProfileCard
                key={profile.id}
                profile={profile}
                settings={settings}
            />
        ))}
        <ProfileCard
            employerId={employerId}
            settings={settings}
        />
    </div>
);

EmployerProfiles.propTypes = {
    profiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        qualifications: PropTypes.string,
        additional_info: PropTypes.string,
        driver_license: PropTypes.bool,
    })).isRequired,
    settings: PropTypes.shape({
        occupations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        skills: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        specializations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }).isRequired,
    employerId: PropTypes.number,
};

const mapStateToProps = state => ({
    profiles: state.employer.profiles.profiles || [],
    settings: state.settings || [],
    employerId: state.employer.employer.id,
});

const mapDispatchToProps = dispatch => bindActionCreators({ createJobProfile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployerProfiles);
