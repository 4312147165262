import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    createJobProfile,
    updateJobProfile,
    deleteJobProfile,
} from 'actions/job';

import EditTrigger from 'components/shared/edittrigger';
import Button from 'components/shared/button';
import modalController from 'utils/modalcontroller';
import ConfirmModal from 'components/confirmmodal';
import {
    Modal,
    Tag,
} from 'antd';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import ConsultantSearchModal from './consultantsearchmodal';
import Booking from './booking';
import EditVacancyModal from '../../../../components/editvacancymodal';

const VacancyCard = ({
    countryCode,
    vacancy,
    settings,
    employerId,
    createLongTermVacancy,
    updateLongTermVacancy,
    deleteLongTermVacancy,
    getFormattedDateWithWeek,
    renderApplications,
    includeTestAccounts,
    employerShifts,
    setEditBookingModalData,
    setShowEditBookingModal,
    showEditBookingModal,
    isFetchingEmployerShifts,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showBookConsultantModal, setShowBookConsultantModal] = useState(false);
    const [showLtvDetailsModal, setShowLtvDetailsModal] = useState(false);

    const location = useLocation();

    const saveVacancy = formData => {
        const newPeriod = formData.period;
        const newEmploymentScope = formData.employment_scope;
        const newPrice = formData.price;
        const newMargin = formData.margin;
        const lastApplicationTime = formData.last_application_time;
        /* eslint-disable no-param-reassign */
        delete formData.hourly_rate;
        delete formData.employment_scope;
        delete formData.jobProfile;
        delete formData.period;
        delete formData.price;
        delete formData.margin;
        delete formData.last_application_time;
        /* eslint-enable no-param-reassign */

        const longTermProfile = {
            ...formData,
            is_long_term: true,
            long_term_details: {
                employment_scope: newEmploymentScope,
                start_time: moment(newPeriod[0]).unix(),
                end_time: moment(newPeriod[1]).unix(),
                price: newPrice,
                margin: newMargin,
                last_application_time: lastApplicationTime ? moment(lastApplicationTime).unix() : null,
            },
        };

        if (vacancy) {
            longTermProfile.id = vacancy.id;
            return updateLongTermVacancy(longTermProfile);
        }

        return createLongTermVacancy(employerId, longTermProfile);
    };

    const triggerDeleteModal = vacancyId => {
        modalController.showModal(ConfirmModal, {
            title: 'Delete long-term vacancy?',
            width: 600,
            props: {
                options: [
                    {
                        type: 'button',
                        className: 'btn-danger',
                        text: 'Delete',
                        action: () => deleteLongTermVacancy(vacancyId),
                    },
                ],
                text: 'Are you sure you want to delete this long-term vacancy? This action can not be undone.',
            },
        });
    };

    const triggerBookConsultantModal = () => {
        setShowBookConsultantModal(true);
    };

    const toggleBookingsModal = useCallback(value => {
        if (!showEditBookingModal || value) {
            setEditBookingModalData({
                bookingId: vacancy.bookings[0].id || null,
                employerId: vacancy.bookings[0].employer.id,
                ltv: vacancy,
            });
        }
        setShowEditBookingModal(value || !showEditBookingModal);
    }, [setEditBookingModalData, setShowEditBookingModal, showEditBookingModal, vacancy]);

    const {
        action = null,
        id = null,
    } = location.state || {
        action: null,
        id: null,
    };

    useEffect(() => {
        if (vacancy) {
            if (action && id === vacancy.id && !isFetchingEmployerShifts && !!vacancy.bookings.length) {
                switch (action) {
                    case 'openeditshiftsmodal':
                        toggleBookingsModal(true);
                        break;
                    case 'openaddshiftsmodal':
                        toggleBookingsModal(true);
                        break;
                    default:
                        break;
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, id, vacancy, isFetchingEmployerShifts]);

    return (
        <>
            {vacancy
                ? (
                    <article key={vacancy.id} className='content-card-centered'>
                        <div className='ltv-detail'>
                            <dl className='ltv-detail-info'>
                                <dt className='ltv-detail-title'>{`Job Id ${vacancy.id}`}</dt>
                                <dd> </dd>

                                <dt className='ltv-detail-title'>Base job profile</dt>
                                <dd>{vacancy.name}</dd>

                                <dt className='ltv-detail-title'>Start time</dt>
                                <dd>
                                    {getFormattedDateWithWeek(vacancy.long_term_details.start_time)}
                                </dd>

                                <dt className='ltv-detail-title'>End time</dt>
                                <dd>
                                    {getFormattedDateWithWeek(vacancy.long_term_details.end_time)}
                                </dd>
                                <dt className='ltv-detail-title'>Last application time</dt>
                                <dd>
                                    {
                                        vacancy.long_term_details.last_application_time
                                            ? getFormattedDateWithWeek(vacancy.long_term_details.last_application_time)
                                            : 'Not set'
                                    }
                                </dd>

                                <dt className='ltv-detail-title'>Price (in SEK)</dt>
                                <dd>{vacancy.long_term_details.price}</dd>
                                <dt className='ltv-detail-title'>Salary (in SEK)</dt>
                                <dd>{vacancy.long_term_details.salary}</dd>
                                <dt className='ltv-detail-title'>Consultant fee (in SEK)</dt>
                                <dd>{vacancy.long_term_details.consultant_fee}</dd>

                                <dt className='ltv-detail-title'>Scope of employment (in %)</dt>
                                <dd>{vacancy.long_term_details.employment_scope}</dd>
                            </dl>
                            <div className='ltv-detail-applications'>
                                {!!vacancy.bookings.length && (
                                    <dt className='ltv-booked-title'>Booked</dt>
                                )}
                                <Booking
                                    employerShifts={employerShifts}
                                    toggleBookingsModal={toggleBookingsModal}
                                    vacancy={vacancy}
                                />
                                {renderApplications(vacancy)}
                            </div>
                        </div>
                        <hr />
                        {!vacancy.bookings.length ? (
                            <div className='ltv-detail-actions'>
                                <EditTrigger
                                    title='Edit long-term vacancy'
                                    onClick={() => setShowModal(true)}
                                />
                                <div>
                                    <Button
                                        className='add-consultant-button'
                                        small
                                        onClick={() => triggerBookConsultantModal(vacancy.id)}
                                    >
                                        Add consultant
                                    </Button>

                                    <Button
                                        className='delete-button'
                                        outlineWarn
                                        small
                                        onClick={() => triggerDeleteModal(vacancy.id)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                                <ConsultantSearchModal
                                    close={() => setShowBookConsultantModal(false)}
                                    includeTestAccounts={includeTestAccounts}
                                    profileId={vacancy.id}
                                    visible={showBookConsultantModal}
                                />
                            </div>

                        ) : (
                            <div className='ltv-detail-actions'>
                                <Button
                                    className='add-consultant-button'
                                    small
                                    onClick={() => setShowLtvDetailsModal(true)}
                                >
                                    Show LTV details
                                </Button>
                            </div>
                        )}
                    </article>
                )
                : (
                    <article className='content-card-centered'>
                        <EditTrigger
                            title='Create new long-term vacancy'
                            onClick={() => setShowModal(true)}
                        />
                    </article>
                )}
            <EditVacancyModal
                countryCode={countryCode}
                employerId={employerId}
                settings={settings}
                setVisibility={setShowModal}
                vacancy={vacancy}
                visible={showModal}
                onSave={saveVacancy}
            />
            {vacancy && (
                <>

                    <Modal
                        title={`${vacancy.name} details`}
                        visible={showLtvDetailsModal}
                        width={600}
                        onCancel={() => setShowLtvDetailsModal(false)}
                        onOk={() => setShowLtvDetailsModal(false)}
                    >
                        <dt className='ltv-detail-title'>Description</dt>
                        <dd>{vacancy.description ? vacancy.description : '-' }</dd>
                        <dt className='ltv-detail-title'>Qualifications</dt>
                        <dd>{vacancy.qualifications ? vacancy.qualifications : '-'}</dd>
                        <dt className='ltv-detail-title'>Skills</dt>
                        <dd>
                            {vacancy.skills.length
                                ? settings.skills.filter(s => vacancy.skills.includes(s.id))
                                    .map(item => <Tag>{item.name}</Tag>)
                                : '-'}
                        </dd>
                        <dt className='ltv-detail-title'>Specialization</dt>
                        <dd>
                            {vacancy.specialization_ids.length
                                ? settings.specializations
                                    .filter(s => vacancy.specialization_ids.includes(s.id))
                                    .map(item => <Tag>{item.name}</Tag>)
                                : '-'}
                        </dd>
                        <dt className='ltv-detail-title'>Driver's licence</dt>
                        <dd>
                            {vacancy.driver_license ? 'Required' : 'Not required'}
                        </dd>
                        <dt className='ltv-detail-title'>Additional info</dt>
                        <dd>
                            {vacancy.additional_info ? vacancy.additional_info : '-'}
                        </dd>
                    </Modal>
                </>
            )}
        </>
    );
};

VacancyCard.propTypes = {
    countryCode: PropTypes.string.isRequired,
    vacancy: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        occupation_id: PropTypes.number,
        description: PropTypes.string,
        qualifications: PropTypes.string,
        additional_info: PropTypes.string,
        driver_license: PropTypes.bool,
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        skills: PropTypes.arrayOf(PropTypes.number),
        bookings: PropTypes.shape({ length: PropTypes.number }),
        drafts: PropTypes.shape({ length: PropTypes.number }),
        pending: PropTypes.shape({ length: PropTypes.number }),
        published: PropTypes.shape({ length: PropTypes.number }),
        long_term_details: PropTypes.shape({
            employment_scope: PropTypes.number,
            start_time: PropTypes.number,
            end_time: PropTypes.number,
            hourly_rate: PropTypes.number,
            salary: PropTypes.number,
            price: PropTypes.number,
            consultant_fee: PropTypes.number,
            last_application_time: PropTypes.number,
        }),
    }),
    employerShifts: PropTypes.arrayOf(PropTypes.shape({})),
    settings: PropTypes.shape({
        occupations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        skills: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        specializations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }).isRequired,
    employerId: PropTypes.number.isRequired,
    createLongTermVacancy: PropTypes.func.isRequired,
    updateLongTermVacancy: PropTypes.func.isRequired,
    deleteLongTermVacancy: PropTypes.func.isRequired,
    getFormattedDateWithWeek: PropTypes.func.isRequired,
    renderApplications: PropTypes.func.isRequired,
    includeTestAccounts: PropTypes.bool.isRequired,
    setEditBookingModalData: PropTypes.func.isRequired,
    setShowEditBookingModal: PropTypes.func.isRequired,
    showEditBookingModal: PropTypes.bool.isRequired,
    isFetchingEmployerShifts: PropTypes.bool.isRequired,

};

const mapStateToProps = state => ({ settings: state.settings || [] });

const mapDispatchToProps = dispatch => ({
    createLongTermVacancy: (employerId, jobProfile) => dispatch(createJobProfile(employerId, jobProfile)),
    updateLongTermVacancy: jobProfile => dispatch(updateJobProfile(jobProfile)),
    deleteLongTermVacancy: profileId => dispatch(deleteJobProfile(profileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacancyCard);
