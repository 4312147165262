import React, {
    useEffect,
    useState,
} from 'react';
import {
    filter,
    sortBy,
    intersection,
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from 'config';

import * as consultantActions from 'actions/consultants';
import * as employerActions from 'actions/employers';

import Map from 'containers/usermap/map';
import ConsultantsFilter from 'containers/consultants/consultantsfilter';
import ConsultantsSidebar from 'containers/consultantssidebar';

import Loader from 'components/shared/loader';
import './styles.scss';
import { getLastActivePeriod } from '../../request';

const UserMap = ({
    consultants,
    employers,
    ready,
    fetchConsultants,
    fetchEmployers,
}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarData, setSidebarData] = useState({});

    useEffect(() => {
        if (!ready) {
            fetchConsultants();
            fetchEmployers();
        }
    }, [ready, fetchConsultants, fetchEmployers]);
    const getEmployerMarkers = () => filter(employers.employers, employer => !employer.test_employer && employer.address);

    const getUserMarkers = () => {
        const users = consultants.filter(consultant => consultant.lat && consultant.lng);

        return users;
    };

    const renderSidebar = () => (
        <ConsultantsSidebar
            consultantId={sidebarData.id}
            open={sidebarOpen}
            onClose={() => {
                setSidebarOpen(false);
            }}
        />
    );
    if (!ready) {
        return (
            <Loader />
        );
    }

    return (
        <main className='user-map'>
            <ConsultantsFilter
                includeEmployerOptions
            />
            <div
                style={{
                    width: 'calc(100vw - 380px)',
                    marginLeft: 300,
                }}
            >
                <Map
                    employerMarkers={getEmployerMarkers()}
                    userMarkers={getUserMarkers()}
                    onConsultantMarkerClick={consultant => {
                        setSidebarData(consultant);
                        setSidebarOpen(true);
                    }}
                />
            </div>
            {renderSidebar()}
        </main>
    );
};

const consultantsByQueryStringSelector = (consultants, query) => {
    if (!query) {
        return consultants;
    }

    const q = query.toLowerCase();
    return filter(consultants, consultant => {
        if ((`${consultant.firstname} ${consultant.lastname}`).toLowerCase().indexOf(q) !== -1) {
            return true;
        }

        if ((consultant.description && consultant.description.toLowerCase().indexOf(q) !== -1)) {
            return true;
        }

        if (consultant.email && consultant.email.indexOf(q) !== -1) {
            return true;
        }

        if (consultant.phone && consultant.phone.indexOf(q) !== -1) {
            return true;
        }

        return false;
    });
};

const consultantsByIncludeTagSelector = (consultants, includeTags) => {
    if (!includeTags.length) {
        return consultants;
    }

    return filter(consultants, consultant => (intersection(includeTags, consultant.tags).length === includeTags.length));
};

const consultantsByExcludeTagSelector = (consultants, excludeTags) => {
    if (!excludeTags.length) {
        return consultants;
    }

    return filter(consultants, consultant => (intersection(excludeTags, consultant.tags).length !== excludeTags.length));
};

const consultantsByOccupationSelector = (consultants, occupation) => {
    if (occupation === config.occupations.OCCUPATION_ALL) {
        return consultants;
    }
    return consultants.filter(consultant => consultant.occupation_id === occupation);
};

const consultantsByTestAccountSelector = (consultants, includeTestAccounts) => {
    if (includeTestAccounts) {
        return consultants;
    }

    return consultants.filter(consultant => consultant.email.indexOf('medpeople') === -1);
};

const consultantsBySkillSelector = (consultants, skills) => {
    if (!skills.length) {
        return consultants;
    }

    return consultants.filter(consultant => intersection(consultant.skills, skills).length > 0);
};

const consultantsBySpecializationSelector = (consultants, specializations) => {
    if (!specializations.length) {
        return consultants;
    }
    return consultants.filter(consultant => intersection(consultant.specialization_ids, specializations).length > 0);
};

const consultantsByIntentionSelector = (consultants, intentionIds) => {
    if (!intentionIds.length) {
        return consultants;
    }
    return consultants.filter(consultant => intersection(consultant.intentions, intentionIds).length > 0);
};

const consultantsByLastActiveSelector = (consultants, lastActive) => {
    if (!lastActive.length) {
        return consultants;
    }
    const periods = lastActive.map(id => getLastActivePeriod(id));
    return consultants.filter(consultant => {
        // console.log(consultant);
        for (let i = 0; i < periods.length; i += 1) {
            if (consultant.last_active > periods[i].gt && consultant.last_active < periods[i].lt) {
                return true;
            }
        }
        return false;
    });
};

const consultantsSelector = state => {
    const { consultants } = state.consultants;
    const { consultantsFilter } = state;
    return sortBy(
        consultantsByLastActiveSelector(
            consultantsByIntentionSelector(
                consultantsBySpecializationSelector(
                    consultantsBySkillSelector(
                        consultantsByExcludeTagSelector(
                            consultantsByIncludeTagSelector(
                                consultantsByOccupationSelector(
                                    consultantsByTestAccountSelector(consultants, consultantsFilter.includeTestAccounts),
                                    consultantsFilter.occupation,
                                ),
                                consultantsFilter.includeTags,
                            ),
                            consultantsFilter.excludeTags,
                        ),
                        state.consultantsFilter.skills,
                    ), state.consultantsFilter.specializations,
                ), consultantsFilter.intentions,
            ), consultantsFilter.lastActive,
        ), 'id',
    );
};

const mapStateToProps = state => {
    if (!(state.consultants.hasFetched && state.employers.hasFetched)) {
        return { ready: false };
    }
    return {
        consultants: state.consultantsFilter.entityTypes.includes('CLINICIANS') ? consultantsSelector(state) : [],
        employers: state.consultantsFilter.entityTypes.includes('EMPLOYERS') ? state.employers : {},
        ready: true,
    };
};

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        ...consultantActions, ...employerActions,
    }, dispatch),
)(UserMap);
