import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Avatar,
    Tag,
} from 'antd';
import {
    nameAsInitials,
    skillTitle,
} from '../../../utils/nameformatters';

function formatTime(unixTimeStamp) {
    if (!unixTimeStamp) return '';
    const dateTime = moment.unix(unixTimeStamp);
    return dateTime.format('MMM YY');
}

function getTime(from, to, current = false) {
    const start = formatTime(from);
    const end = current ? 'Pågående' : formatTime(to);
    if (start && end) {
        return `${start} - ${end}`;
    }
    if (!start && end) return end;

    return start;
}

const SectionListItem = ({
    description = null,
    from = null,
    hasInfoBlock = false,
    rightColumnText = null,
    showMpIcon = false,
    skills = null,
    subtitle = null,
    title = null,
    to = null,
    showIcon = false,
    current = false,
    departmentType,
}) => (
    <li className={`${hasInfoBlock ? 'section-list-item--block' : 'section-list-item'}`}>
        <div className='section-list-item-content'>
            <div className='cv-left-column'>
                {showIcon ? (
                    <Avatar
                        size={40}
                        style={{
                            backgroundColor: '#00ab9b',
                        }}
                    >
                        {nameAsInitials(title)}
                    </Avatar>
                ) : null }

                {showMpIcon && <div className='mp-cv-logo' />}
                <div>
                    {title && <p className='title'>{title}</p>}
                    {departmentType && <p className='type'>{departmentType}</p>}
                    {subtitle && <p className='type'>{subtitle}</p>}
                    {skills && skills.map(
                        skill => <Tag key={skill} color='#333' style={{ marginTop: 5 }}>{skillTitle(skill, 2)}</Tag>,
                    )}

                </div>
            </div>
            <div className='cv-right-column'>
                {rightColumnText
                    ? <p className='type'>{rightColumnText}</p>
                    : <p className='type'>{getTime(from, to, current)}</p>}
            </div>
        </div>
        {description ? (
            <p
                className='description type'
                style={{
                    marginLeft: 50,
                    marginTop: 20,
                    marginBottom: 20,
                    fontSize: 12,
                }}
            >
                {description}
            </p>
        ) : null}
    </li>
);

SectionListItem.propTypes = {
    description: PropTypes.string,
    from: PropTypes.number,
    hasInfoBlock: PropTypes.bool,
    rightColumnText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    showIcon: PropTypes.bool,
    showMpIcon: PropTypes.bool,
    skills: PropTypes.arrayOf(PropTypes.number),
    subtitle: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.number,
    current: PropTypes.bool,
    departmentType: PropTypes.string,
};

export default SectionListItem;
