import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import { Tag } from 'antd';

const MarginConfig = ({
    marginConfig,
    marginOptions,
}) => {
    const configObject = marginOptions.find(option => option.id === marginConfig);
    const name = configObject ? configObject.name : 'BROKEN';
    const color = config.colors.find(c => c.id === marginConfig % config.colors.length).hex;

    return (
        <Tag
            key={marginConfig}
            color={color}
        >
            {
                name
            }
        </Tag>
    );
};

MarginConfig.propTypes = {
    marginOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })),
    marginConfig: PropTypes.number,
};

export default MarginConfig;
