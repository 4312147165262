import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SetTagsOnUsersModal from 'components/settagsonusersmodal';
import {
    Button,
    Modal,
} from 'antd';

const ContactConsultantsModal = ({
    visible,
    close,
}) => {
    const [showContactEmployedConsultants, setShowContactEmployedConsultants] = useState(false);
    const [showContactAppliedConsultants, setShowContactAppliedConsultants] = useState(false);
    const [showCloseConsultants, setShowCloseConsultants] = useState(false);

    return (
        <Modal
            footer={[
                <Button key='regular' onClick={() => setShowContactAppliedConsultants(true)}>Regular</Button>,
                <Button key='employed' onClick={() => setShowContactEmployedConsultants(true)}>Employed</Button>,
                <Button key='close-by' onClick={() => setShowCloseConsultants(true)}>Close Consultants</Button>,
            ]}
            visible={visible}
            onCancel={close}
        >
            Who Would you like to contact?
            <SetTagsOnUsersModal
                close={() => {
                    setShowContactEmployedConsultants(false);
                }}
                userConditions={consultant => !!consultant.added}
                visible={showContactEmployedConsultants}
            />
            <SetTagsOnUsersModal
                close={() => {
                    setShowContactAppliedConsultants(false);
                }}
                userConditions={consultant => !(consultant.added || consultant.introduced)}
                visible={showContactAppliedConsultants}
            />
            <SetTagsOnUsersModal
                close={() => {
                    setShowCloseConsultants(false);
                }}
                refetchConsultants
                showDistance
                visible={showCloseConsultants}
            />
        </Modal>

    );
};
ContactConsultantsModal.propTypes = {
    allConsultants: PropTypes.shape({ collection: PropTypes.arrayOf(PropTypes.shape({
        added: PropTypes.number.isRequired,
        introduced: PropTypes.number.isRequired,
        user: PropTypes.shape({}),
    })) }),
    employer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        address: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        test_employer: PropTypes.bool.isRequired,
    }),
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default ContactConsultantsModal;
