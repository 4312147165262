const initialState = {
    employers: [],
    isFetching: false,
    query: '',
    hasFetched: false,
};

export function employers(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_EMPLOYERS':
            return {
                ...state, isFetching: true,
            };

        case 'RECEIVE_EMPLOYERS':
            return {
                ...state,
                employers: action.employers,
                count: action.count,
                page: action.page,
                totalCount: action.totalCount,
                totalPages: action.totalPages,
                isFetching: false,
                hasFetched: true,
            };

        case 'SET_EMPLOYER_QUERY':
            return {
                ...state,
                query: action.query,
            };

        default:
            return state;
    }
}
