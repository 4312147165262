import { Table } from 'antd';
import { sortBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const ShiftsList = ({
    shifts,
}) => {
    const columns = [
        {
            key: 'date',
            dataIndex: 'date',
            title: 'Date',
            render: date => date,
        },
        {
            key: 'start_time',
            dataIndex: 'start_time',
            title: 'Start',
            render: start_time => moment.unix(start_time).format('HH:mm'),
        },
        {
            key: 'end_time',
            dataIndex: 'end_time',
            title: 'End',
            render: end_time => moment.unix(end_time).format('HH:mm'),
        },
        {
            key: 'shift_break',
            dataIndex: 'shift_break',
            title: 'Break',
            render: shift_break => shift_break,
        },
        {
            key: 'price',
            dataIndex: 'price',
            title: 'Price',
            render: price => price,
        },
        {
            key: 'margin',
            dataIndex: 'margin',
            title: 'Salary margin',
            render: margin => margin,
        },
        {
            key: 'consultant_fee_margin',
            dataIndex: 'consultant_fee_margin',
            title: 'Invoice margin',
            render: consultant_fee_margin => consultant_fee_margin,
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={sortBy(shifts, s => s.date)}
                pagination={false}
                rowClassName={rowShift => {
                    const isValid = shift => shift.start_time && shift.end_time && shift.price;

                    if (!isValid(rowShift)) {
                        return 'mpa-add-shifts-list__error-row--error';
                    }

                    return '';
                }}
                scroll={{ x: 1000 }}
                size='middle'
            />
        </div>
    );
};

ShiftsList.propTypes = {
    shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ShiftsList;
