const initialState = {
    loggedIn: !!localStorage.getItem('mpa-token'),
    token: localStorage.getItem('mpa-token'),
};

export function session(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_TOKEN':
            localStorage.setItem('mpa-token', action.token);
            return {
                ...state,
                loggedIn: true,
                token: action.token,
            };

        case 'RECEIVE_USER':
            return {
                ...state,
                ...action.user,
            };

        default:
            return state;
    }
}
