import * as orderActions from 'actions/orders';
import { Card } from 'antd';
import LoadingScreen from 'components/shared/loadingscreen';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useReducer,
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { orders as reducer } from '../../reducers/orders';
import OrdersList from './OrdersList';

const Orders = ({
    orders,
    isFetching,
    ordersPagination,
    fetchOrders,
    ordersFilter,
    deleteOrder,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        page: 1,
        // query: '',
        // sortField: '',
        // sortOrder: '',
    });
    const history = useHistory();

    useEffect(() => {
        const filters = {
            page: state.page,
            query: state.query,
            sortField: state.sortField,
            sortOrder: state.sortOrder,
            ...ordersFilter,
        };
        fetchOrders(filters);
    }, [state.page, state.query, state.sortField, state.sortOrder, fetchOrders, ordersFilter]);

    const onTableChange = (pagination, _, sorter) => {
        dispatch({
            type: 'SET_PAGE',
            page: pagination.current,
        });
    };
    const viewOrderDetails = record => ({ onClick: e => {
        history.push(`/orders/${record.id}`);
    } });

    const onDelete = async id => {
        await deleteOrder(id);
        // Refresh the orders list after deletion
        const filters = {
            page: state.page,
            query: state.query,
            sortField: state.sortField,
            sortOrder: state.sortOrder,
            ...ordersFilter,
        };
        fetchOrders(filters);
    };

    if (isFetching) {
        return (
            <LoadingScreen
                fullScreen
                text='Loading....'
            />
        );
    }

    return (
        <main className='mpa-calendar row justify-content-between'>
            <div className='col-md-12'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <h2 style={{ margin: 0 }}>Orders</h2>
                </div>
            </div>

            <Card style={{ marginTop: 50 }}>
                <OrdersList
                    ordersList={orders}
                    pagination={ordersPagination}
                    onChange={onTableChange}
                    onDelete={onDelete}
                    onRow={viewOrderDetails}
                />
            </Card>
        </main>
    );
};

Orders.propTypes = {
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
    isFetching: PropTypes.bool.isRequired,
    ordersPagination: PropTypes.shape({
        page: PropTypes.number,
        totalCount: PropTypes.number,
        totalPages: PropTypes.number,
    }).isRequired,
    fetchOrders: PropTypes.func.isRequired,
    ordersFilter: PropTypes.shape({
        page: PropTypes.number,
        query: PropTypes.string,
        sortField: PropTypes.string,
        sortOrder: PropTypes.string,
    }).isRequired,
    deleteOrder: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    orders: state.orders.orders,
    isFetching: state.orders.isFetching,
    ordersPagination: {
        page: state.orders.page,
        totalCount: state.orders.totalCount,
        totalPages: state.orders.totalPages,
    },
    ordersFilter: state.orders.ordersFilter,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        ...orderActions,
    }, dispatch),
)(Orders);
