import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as salaryErrorListActions from 'actions/salaryerrorlist';

import { Table } from 'antd';
import './styles.scss';

class SalaryErrorList extends React.Component {
    componentDidMount() {
        this.props.fetchSalaryErrorList();
    }

    renderTable() {
        if (this.props.salaryErrorList.isFetching) {
            return (
                <div
                    style={{
                        height: 400,
                        margin: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 24,
                    }}
                >
                    <i className='fa fa-circle-o-notch fa-spin' />
                </div>
            );
        }

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: 'Export start time',
            dataIndex: 'fortnox_export_start_time',
            key: 'fortnox_export_start_time',
            render: text => moment.unix(text).format('YYYY-MM-DD HH:mm'),
        }, {
            title: 'Export end time',
            dataIndex: 'fortnox_export_end_time',
            key: 'fortnox_export_end_time',
            render: text => moment.unix(text).format('YYYY-MM-DD HH:mm'),
        }, {
            title: 'Salary row ID',
            dataIndex: 'fortnox_salary_row_id',
            key: 'fortnox_salary_row_id',
        }];

        return (
            <Table
                className='table'
                columns={columns}
                dataSource={this.props.salaryErrorList.salaryErrorList}
                pagination={false}
            />
        );
    }

    render() {
        return (
            <main className='salary-error-list'>
                <h1 className='heading'>Error list</h1>
                {this.renderTable()}
            </main>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(salaryErrorListActions, dispatch),
)(SalaryErrorList);
