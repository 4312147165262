import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import { Tag } from 'antd';

const UserIntentions = ({ intendedUsage }) => (
    <>
        {intendedUsage ? intendedUsage.map(i => (
            <Tag
                key={i}
                color={config.consultantIntent.intended_usage[i].color}
            >
                {config.consultantIntent.intended_usage[i].name}
            </Tag>
        )) : 'Not set'}
    </>
);

UserIntentions.propTypes = { intendedUsage: PropTypes.arrayOf(PropTypes.number) };

export default UserIntentions;
