import React, {
    useEffect,
    useState,
} from 'react';

import PropTypes from 'prop-types';
import {
    filter,
    range,
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Formsy from 'formsy-react';
import {
    withRouter,
    useParams,
    useHistory,
} from 'react-router-dom';
import config from 'config';
import {
    logOutConsultant,
    updateConsultant,
    updateSupplier,
    fetchSupplierByConsultantID,
    unregisterConsultant,
    fetchConsultantActivities,
    resetConsultantPassword,
    banConsultant,
    unBanConsultant
} from 'actions/consultant';

import ConfirmModal from 'components/confirmmodal';
import modalController from 'utils/modalcontroller';
import {
    List,
    Avatar,
    Select,
    Button,
} from 'antd';
import {
    consultantStatusTitle,
    consultantStatusColor,
} from 'utils/nameformatters';
import consultantActivity from 'utils/consultantactivity';

import InputModal from 'components/inputmodal';
import EditTrigger from 'components/shared/edittrigger';
import './styles.scss';

const ConsultantOverview = ({
    consultant,
    activities,
    logOutRequestOngoing,
    isFetchingActivities,
    updateConsultant,
    updateSupplier,
    fetchSupplierByConsultantID,
    logOutConsultant,
    unregisterConsultant,
    banConsultant,
    unBanConsultant,
    fetchConsultantActivities,
    resetConsultantPassword
}) => {
    const history = useHistory();
    const [oneTimeTax, setOneTimeTax] = useState(consultant.one_time_tax);
    const [consultantState, setConsultantState] = useState(consultant.state);
    const [resetPassword, setResetPassword] = useState(false);
    const { id } = useParams();

    const hasInvoiceAsPayment = consultant.salary_type === config.consultantSalaryTypes.INVOICE

    useEffect(() => {
        fetchConsultantActivities(id);
        fetchSupplierByConsultantID(id);
    }, [fetchConsultantActivities, id, hasInvoiceAsPayment]);

    const onSubmit = () => {
        const consultantData = { state: consultantState };
        updateConsultant(id, consultantData);
    };

    const onSubmitOneTimeTax = () => {
        const consultantData = { one_time_tax: oneTimeTax };
        updateConsultant(id, consultantData);
    };

    const onResetPassword = async () => {
        setResetPassword(true)
        await resetConsultantPassword(id)
        setResetPassword(false)
    }

    const unregisterFailedModal = () => {
        modalController.showModal(ConfirmModal, {
            title: 'Consultant can\'t be deleted',
            width: 600,
            props: {
                options: [
                    {
                        type: 'button',
                        className: 'btn-primary',
                        text: 'Ok. I understand.',
                        action: () => {},
                    },
                ],
                text: `${consultant.firstname} ${consultant.lastname} cant be deleted. He/she has bookings in the past or applications in the future.`,
            },

        });
    };

    const renderSalaryDetails = () => (
        <div className='mpa-employer-data-row'>
            <div style={{ fontWeight: 700 }}>Payment info salary</div>
            <div>
                <div>
                    {!hasInvoiceAsPayment && <strong className='text-green-700'>Selected as payment type</strong>}
                </div>
                <div>
                    <div>
                        <strong>
                            Salary interval
                        </strong>
                    </div>
                    <div>{consultant.weekly_salary ? 'Weekly' : 'Monthly'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Clearingnr
                        </strong>
                    </div>
                    <div>{consultant.account_clearing_number || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Account nr
                        </strong>
                    </div>
                    <div>{consultant.account_number || 'N/A'}</div>
                </div>
                {' '}
                <EditTrigger
                    className='hide-in-print'
                    style={{ display: 'inline' }}
                    title='Edit Payment info'
                    onClick={() => {
                        modalController.showModal(InputModal, {
                            title: 'Edit payment info',
                            width: 600,
                            preventOutsideClickClose: true,
                            props: {
                                inputs: [
                                {
                                    type: 'switch',
                                    name: 'weekly_salary',
                                    label: 'Salary Interval',
                                    optionLabels: {
                                        on: 'Weekly',
                                        off: 'Monthly',
                                    },
                                    value: consultant.weekly_salary,
                                },
                                {
                                    type: 'input',
                                    name: 'account_clearing_number',
                                    label: 'Clearingnr',
                                    value: consultant.account_clearing_number,
                                }, {
                                    type: 'input',
                                    name: 'account_number',
                                    label: 'Account number',
                                    value: consultant.account_number,
                                }],
                                onSave: formData => updateConsultant(id, formData),
                            },
                        });
                    }}
                />
            </div>
        </div>
    );

    const renderInvoiceDetails = () => (
        <div className='mpa-employer-data-row'>
            <div style={{ fontWeight: 700 }}>Payment info invoice</div>
            <div>
                <div>
                    {hasInvoiceAsPayment && <strong className='text-green-700'>Selected as payment type</strong>}
                </div>
                <div>
                    <div>
                        <strong>
                            Clearingnr
                        </strong>
                    </div>
                    <div>{consultant.supplier?.account_clearing_number || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Account nr
                        </strong>
                    </div>
                    <div>{consultant.supplier?.account_number || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Bankgiro
                        </strong>
                    </div>
                    <div>{consultant.supplier?.bankgiro || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Postgiro
                        </strong>
                    </div>
                    <div>{consultant.supplier?.postgiro || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Organisation nr
                        </strong>
                    </div>
                    <div>{consultant.supplier?.organization_number || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Organisation name
                        </strong>
                    </div>
                    <div>{consultant.supplier?.organization_name || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Addressline 1
                        </strong>
                    </div>
                    <div>{consultant.supplier?.addressline1 || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Addressline 2
                        </strong>
                    </div>
                    <div>{consultant.supplier?.addressline2 || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        City
                        </strong>
                    </div>
                    <div>{consultant.supplier?.city || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Postalcode
                        </strong>
                    </div>
                    <div>{consultant.supplier?.postalcode || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Email
                        </strong>
                    </div>
                    <div>{consultant.supplier?.email || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Phone
                        </strong>
                    </div>
                    <div>{consultant.supplier?.phone || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                        Fortnox supplier id
                        </strong>
                    </div>
                    <div>{consultant.supplier?.fortnox_supplier_id || 'N/A'}</div>
                </div>
                {' '}
                <EditTrigger
                    className='hide-in-print'
                    style={{ display: 'inline' }}
                    title='Edit Payment info'
                    onClick={() => {
                        modalController.showModal(InputModal, {
                            title: 'Edit payment info',
                            width: 600,
                            preventOutsideClickClose: true,
                            props: {
                                inputs: [
                                {
                                    type: 'input',
                                    name: 'bankgiro',
                                    label: 'Bankgiro',
                                    value: consultant.supplier.bankgiro,
                                },
                                {
                                    type: 'input',
                                    name: 'postgiro',
                                    label: 'Postgiro',
                                    value: consultant.supplier.postgiro,
                                },
                                ,
                                {
                                    type: 'input',
                                    name: 'account_clearing_number',
                                    label: 'Clearingnr',
                                    value: consultant.supplier.account_clearing_number,
                                }, {
                                    type: 'input',
                                    name: 'account_number',
                                    label: 'Account number',
                                    value: consultant.supplier.account_number,
                                },
                                {
                                    type: 'input',
                                    name: 'organization_number',
                                    label: 'Organisation nr',
                                    value: consultant.supplier.organization_number,
                                }, {
                                    type: 'input',
                                    name: 'organization_name',
                                    label: 'Company name',
                                    value: consultant.supplier.organization_name,
                                },
                                {
                                    type: 'input',
                                    name: 'addressline1',
                                    label: 'Addressline 1',
                                    value: consultant.supplier.addressline1,
                                },
                                {
                                    type: 'input',
                                    name: 'addressline2',
                                    label: 'Addressline 2',
                                    value: consultant.supplier.addressline2,
                                },
                                {
                                    type: 'input',
                                    name: 'city',
                                    label: 'City',
                                    value: consultant.supplier.city,
                                },
                                {
                                    type: 'input',
                                    name: 'postalcode',
                                    label: 'Postalcode',
                                    value: consultant.supplier.postalcode,
                                },
                                {
                                    type: 'input',
                                    name: 'email',
                                    label: 'Email',
                                    value: consultant.supplier.email,
                                },
                                {
                                    type: 'input',
                                    name: 'phone',
                                    label: 'Phone',
                                    value: consultant.supplier.phone,
                                },
                                {
                                    type: 'input',
                                    name: 'fortnox_supplier_id',
                                    label: 'Fortnox supplier id',
                                    value: consultant.supplier.fortnox_supplier_id,
                                },
                            
                            ],
                                onSave: formData => updateSupplier({ user_id: id, ...formData }),
                            },
                        });
                    }}
                />
            </div>
        </div>
    );

    const renderSalaryTax = () => {
        const currentConsultantTax = consultant.one_time_tax;
        const standardConfirmationText = `Please confirm that you would like to change ${consultant.firstname}'s tax from ${currentConsultantTax} to ${oneTimeTax}.`;
        return (
            <div className='mpa-employer-data-row'>
                <div style={{ fontWeight: 700 }}>
                    Skatt för lön
                    <strong
                        style={{
                            display: 'block',
                            fontSize: 13,
                            marginBottom: 10,
                        }}
                    >
                        {' '}
                        Nuvarande Skatt:
                        <span
                            style={{ paddingLeft: 5 }}
                        >
                            {consultant.one_time_tax}
                        </span>
                    </strong>
                </div>

                <Formsy
                    className='activate-consultant-form'
                >

                    <div
                        className='activate-consultant-form__section'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <Select
                                emptyValue={30}
                                value={oneTimeTax}
                                onChange={value => {
                                    setOneTimeTax(value);
                                }}
                            >
                                {range(20, 61, 1).map(value => (
                                    <Select.Option value={value}>
                                        {value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Button
                                danger
                                disabled={oneTimeTax === currentConsultantTax}
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    modalController.showModal(ConfirmModal, {
                                        title: 'Change salary tax?',
                                        width: 600,
                                        props: {
                                            options: [
                                                {
                                                    type: 'button',
                                                    className: 'btn-danger',
                                                    text: `Set ${consultant.firstname}'s salary tax to ${oneTimeTax}`,
                                                    action: onSubmitOneTimeTax,
                                                },
                                            ],
                                            text: standardConfirmationText,
                                        },
                                    });
                                }}
                            >
                                Change tax to
                                {' '}
                                <strong>{oneTimeTax}</strong>
                            </Button>
                        </div>
                    </div>
                </Formsy>
            </div>
        );
    };

    const renderAddress = () => (
        <div className='mpa-employer-data-row'>
            <div style={{ fontWeight: 700 }}>Address</div>
            <div>
                <div>
                    <div>
                        <strong>
                            Address line 1
                        </strong>
                    </div>
                    <div>{consultant.addressline1 || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Address line 2
                        </strong>
                    </div>
                    <div>{consultant.addressline2 || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            Postal code
                        </strong>
                    </div>
                    <div>{consultant.postalcode || 'N/A'}</div>
                </div>
                <div>
                    <div>
                        <strong>
                            City
                        </strong>
                    </div>
                    <div>{consultant.city || 'N/A'}</div>
                </div>
                <EditTrigger
                    className='hide-in-print'
                    style={{ display: 'inline' }}
                    title='Edit Address'
                    onClick={() => {
                        modalController.showModal(InputModal, {
                            title: 'Edit consultant address',
                            width: 600,
                            preventOutsideClickClose: true,
                            props: {
                                inputs: [{
                                    type: 'input',
                                    name: 'addressline1',
                                    label: 'Addressline 1',
                                    value: consultant.addressline1,
                                }, {
                                    type: 'input',
                                    name: 'addressline2',
                                    label: 'Addressline 2',
                                    value: consultant.addressline2,
                                }, {
                                    type: 'input',
                                    name: 'postalcode',
                                    label: 'Postal code',
                                    value: consultant.postalcode,
                                }, {
                                    type: 'input',
                                    name: 'city',
                                    label: 'city',
                                    value: consultant.city,
                                }],
                                onSave: formData => updateConsultant(id, formData),
                            },
                        });
                    }}
                />
            </div>
        </div>
    );

    const renderStateToggle = () => {
        const getPendingPeriod = `${moment().diff(moment.unix(consultant.created), 'days')}d`;
        const currentConsultantState = consultantStatusTitle(consultant.state);
        const standardConfirmationText = `Please confirm that you would like to change ${consultant.firstname}'s status from ${currentConsultantState} to ${consultantStatusTitle(consultantState)}.`;
        // Filter out alternative "remove"
        const statusOptions = filter(config.consultantStatus, status => status.value !== 3);
        const { email_verified } = consultant;
        return (
            <div className='mpa-employer-data-row'>
                <div style={{ fontWeight: 700 }}>
                    Konto status
                    <strong
                        style={{
                            display: 'block',
                            fontSize: 13,
                            marginBottom: 10,
                        }}
                    >
                        {' '}
                        Nuvarande status:
                        <span
                            style={{
                                color: consultantStatusColor(currentConsultantState),
                                paddingLeft: 5,
                            }}
                        >
                            {currentConsultantState}
                            {' '}
                            {currentConsultantState.toLowerCase() === 'pending' && getPendingPeriod}
                        </span>
                    </strong>
                    <div
                        style={{
                            fontWeight: 'normal',
                            color: email_verified ? config.statusColors.activated : config.statusColors.pending,
                        }}
                    >
                        {email_verified ? 'Email verified' : 'Obs! Email not verified'}
                    </div>
                </div>
                <Formsy
                    className='activate-consultant-form'
                >

                    <div
                        className='activate-consultant-form__section'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <Select
                                value={consultantState}
                                onChange={value => {
                                    setConsultantState(value);
                                }}
                            >
                                {statusOptions.map(status => (
                                    <Select.Option value={status.value}>
                                        {status.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Button
                                danger
                                disabled={consultant.state === consultantState}
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    modalController.showModal(ConfirmModal, {
                                        title: 'Change status?',
                                        width: 600,
                                        props: {
                                            options: [
                                                {
                                                    type: 'button',
                                                    className: 'btn-danger',
                                                    text: `Set ${consultant.firstname}'s status to ${consultantStatusTitle(consultantState)}`,
                                                    action: onSubmit,
                                                },
                                            ],
                                            text: standardConfirmationText,
                                        },
                                    });
                                }}
                            >
                                Change status to
                                {' '}
                                <strong>{consultantStatusTitle(consultantState)}</strong>
                            </Button>
                        </div>
                    </div>
                </Formsy>
            </div>
        );
    };

    const renderRegistered = () => (

        <div className='mpa-employer-data-row'>
            <div
                style={{ fontWeight: 700 }}
            >
                Registered since
            </div>
            <div>
                {moment.unix(consultant.created).format('D MMM YYYY')}
            </div>
        </div>

    );
    const renderResetPasswordButton = () => (

        <div className='mpa-employer-data-row'>
            <div
                style={{ fontWeight: 700 }}
            >
                Reset password
            </div>
            <div
                style={{ marginTop: 10 }}
            >
                <Button
                    disabled={resetPassword}
                    onClick={() => {
                        onResetPassword();
                    }}
                >
                    {resetPassword ? 'Sending...' : 'Reset password'}
                </Button>
            </div>
        </div>

    );
    const renderSelectedPaymentType = () => (
        <div className='mpa-employer-data-row'>
            
            <div
                style={{ fontWeight: 700 }}
            >
                Selected payment is
            </div>
            <div>
                <div className='text-green-700 mb-2'>{hasInvoiceAsPayment ? 'Invoice' : 'Salary' }</div>
                <EditTrigger
                    className='hide-in-print'
                    style={{ display: 'inline' }}
                    title='Edit Payment Method'
                    onClick={() => {
                        modalController.showModal(InputModal, {
                            title: 'Edit consultant payment method',
                            width: 600,
                            preventOutsideClickClose: true,
                            props: {
                                inputs: [
                                    {
                                        type: 'select',
                                        name: 'salary_type',
                                        label: 'Payment type',
                                        options: Object.entries(config.consultantSalaryTypes).map(([title, value]) => ({
                                            title, value,
                                        })),
                                        value: consultant.salary_type,
                                    },
                                ],
                                onSave: formData => updateConsultant(id, formData),
                            },
                        });
                    }}
                />
            </div>
        </div>
    );



    const renderPreferences = () => {
        const {
            actively_looking,
            current_situation,
            intended_usage,
            previous_staffing,
        } = consultant.user_store;

        return (
            <div className='mpa-employer-data-row'>
                <div
                    style={{ fontWeight: 700 }}
                >
                    Intent
                </div>
                <div>
                    <ul className='consultant__intent-list'>
                        <li className='consultant__intent-list-item'>
                            <div>
                                <strong>Are you actively looking for shifts?</strong>
                            </div>
                            {actively_looking ? config.consultantIntent.actively_looking[actively_looking] : 'Not set'}
                        </li>
                        <li className='consultant__intent-list-item'>
                            <div>
                                <strong>What is your current work situation?</strong>
                            </div>
                            {current_situation ? current_situation.map(i => (
                                <span>
                                    {config.consultantIntent.current_situation[i]}
                                    <br />
                                </span>
                            )) : 'Not set'}
                        </li>
                        <li className='consultant__intent-list-item'>
                            <div>
                                <strong>What do you want to use Medpeople for?</strong>
                            </div>
                            {intended_usage ? intended_usage.map(i => (
                                <span>
                                    {config.consultantIntent.intended_usage[i].name}
                                    <br />
                                </span>
                            )) : 'Not set'}
                        </li>
                        <li className='consultant__intent-list-item'>
                            <div>
                                <strong>Have you worked with staffing before?</strong>
                            </div>
                            {previous_staffing ? config.consultantIntent.previous_staffing[previous_staffing] : 'Not set'}
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const renderLogOutConsultant = () => (
        <div className='mpa-employer-data-row'>
            <div>
                <div style={{ fontWeight: 700 }}>
                    Log out
                </div>
                <div
                    style={{
                        fontSize: 14,
                        color: '#999',
                    }}
                >
                    Log out
                    {' '}
                    {consultant.firstname}
                    {' '}
                    from all active sessions
                </div>
            </div>

            <div
                style={{ marginTop: 10 }}
            >
                <Button
                    danger
                    disabled={logOutRequestOngoing}
                    onClick={() => {
                        logOutConsultant(id);
                    }}
                >
                    Log out
                    {' '}
                    {consultant.firstname}
                </Button>
            </div>
        </div>
    );

    const renderDeleteConsultant = () => (
        <div className='mpa-employer-data-row'>
            <div>
                <div style={{ fontWeight: 700 }}>Delete consultant</div>
                <div
                    style={{
                        fontSize: 14,
                        color: '#999',
                    }}
                >
                    This will remove all of the consultants data from all our systems including removing he/she from Intercom.
                    {' '}
                    <br />
                    (The consultant will be notified via email that this has occured)
                    <br />
                    <strong>This can't be undone!</strong>
                </div>
            </div>
            <div
                style={{ marginTop: 10 }}
            >
                <Button
                    danger
                    disabled={logOutRequestOngoing}
                    onClick={() => {
                        modalController.showModal(ConfirmModal, {
                            title: 'Delete consultant?',
                            width: 600,
                            props: {
                                options: [
                                    {
                                        type: 'button',
                                        className: 'btn-danger',
                                        text: `Delete ${consultant.firstname} ${consultant.lastname}`,
                                        action: () => {
                                            unregisterConsultant(id).then(() => {
                                                history.push('/consultants');
                                            }).catch(() => {
                                                unregisterFailedModal();
                                                close();
                                            });
                                        },
                                    },
                                ],
                                text: `Please confirm that you would like to delete consultant ${consultant.firstname}. This cant be undone.`,
                            },
                        });
                    }}
                >
                    Delete
                    {' '}
                    {consultant.firstname}
                </Button>
            </div>

        </div>
    );
    const renderBanConsultant = () => (
        <div className='mpa-employer-data-row'>
            <div>
                <div style={{ fontWeight: 700 }}>Ban consultant</div>
                <div
                    style={{
                        fontSize: 14,
                        color: '#999',
                    }}
                >
                    <p>(The consultant will be notified via email that this has occured)</p>
                </div>
            </div>
            <div
                style={{ marginTop: 10 }}
            >
                <Button
                    danger
                    disabled={logOutRequestOngoing}
                    onClick={() => {
                        modalController.showModal(InputModal, {
                            title: `${(consultant.banned && consultant.banned.is_banned) ? 'Un-ban' : 'Ban'} ${consultant.firstname} ${consultant.lastname}`,
                            width: 600,
                            props: {
                                inputs: [
                                {
                                    type: 'input',
                                    name: 'reason',
                                    label: 'Reason',
                                }],
                                text: `Please confirm that you would like to ban consultant ${consultant.firstname}. This cant be undone.`,
                                onSave: async ({ reason }) => {
                                    if((consultant.banned && consultant.banned.is_banned)) {
                                        await unBanConsultant(id, reason)
                                        window.location.reload()
                                    } else {
                                        await banConsultant(id, reason)
                                        window.location.reload()
                                    }
                                },
                            },
                        });
                    }}
                >
                    {(consultant.banned && consultant.banned.is_banned) ? 'Un-ban' : 'Ban'}
                    {' '}
                    {consultant.firstname}
                </Button>
            </div>

        </div>
    );

    const renderActivities = () => {
        const icons = [
            'ion-beer',
            'ion-wineglass',
            'ion-coffee',
            'ion-icecream',
            'ion-pizza',
            'ion-umbrella',
            'ion-model-s',
            'ion-android-bar',
            'ion-android-plane',
            'ion-android-boat',
            'ion-planet',
            'ion-mic-b',
            'ion-android-bicycle',
            'ion-android-bus',
        ];
        const randomIcon = icons[Math.floor(Math.random() * icons.length)];
        const emptyContent = (
            <div>
                Nothing here. So here is something else instead.
                <br />
                <i
                    className={`ion ${randomIcon}`}
                    style={{
                        fontSize: 55,
                        marginTop: 30,
                    }}
                />
            </div>
        );

        return (
            <div>
                <div className='timeline-header'>
                    Events
                </div>
                <List
                    className='activities-list'
                    dataSource={activities}
                    itemLayout='horizontal'
                    // loadMore={loadMore}
                    loading={isFetchingActivities}
                    locale={{ emptyText: emptyContent }}
                    renderItem={activity => {
                        const created = moment.unix(activity.created_time);

                        return (

                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={activity.logo_url} />}
                                />
                                <div className='activity-timestamp'>
                                    <span>{(created === moment()) ? 'Idag' : `${created.format('dddd')} ${created.format('D/M')}`}</span>
                                    <span style={{ paddingLeft: 3 }}>{created.format('HH:mm')}</span>
                                </div>
                                <strong className='activity-header'>{consultantActivity(activity).title}</strong>
                                <span className='activity-description'>{consultantActivity(activity).description}</span>
                            </List.Item>
                        );
                    }}
                />
            </div>
        );
    };
    return (
        <div
            className='mp-consultant-overview'
        >
            <div
                className='content-card-full'
                style={{
                    maxWidth: '65%',
                    minWidth: 400,
                    marginRight: 20,
                }}
            >
                
                {renderRegistered()}
                {renderSelectedPaymentType()}
                {renderPreferences()}
                {renderAddress()}
                {renderInvoiceDetails()}
                {renderSalaryDetails()}
                {renderSalaryTax()}

                {renderStateToggle()}
                {renderResetPasswordButton()}
                {renderLogOutConsultant()}
                {renderBanConsultant()}
                {renderDeleteConsultant()}

            </div>
            <div
                className='content-card-full timeline'
                style={{ minWidth: 300 }}
            >
                {renderActivities()}
            </div>
        </div>
    );
};
ConsultantOverview.propTypes = {
    consultant: PropTypes.shape({
        id: PropTypes.number.isRequired,
        created: PropTypes.number.isRequired,
        state: PropTypes.number.isRequired,
        salary_type: PropTypes.number.isRequired,
        one_time_tax: PropTypes.number.isRequired,
        account_clearing_number: PropTypes.string,
        account_number: PropTypes.string,
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        organization_number: PropTypes.string,
        organization_name: PropTypes.string,
        addressline1: PropTypes.string,
        addressline2: PropTypes.string,
        postalcode: PropTypes.number,
        city: PropTypes.string,
        email_verified: PropTypes.bool.isRequired,
        weekly_salary: PropTypes.bool.isRequired,
        user_store: PropTypes.shape({
            actively_looking: PropTypes.number,
            current_situation: PropTypes.number,
            intended_usage: PropTypes.number,
            previous_staffing: PropTypes.number,
        }).isRequired,
    }),
    activities: PropTypes.arrayOf(PropTypes.shape({
        employer_id: PropTypes.number,
        admin_user_id: PropTypes.number,
        created_time: PropTypes.number.isRequired,
    })),
    logOutRequestOngoing: PropTypes.bool.isRequired,
    isFetchingActivities: PropTypes.bool.isRequired,
    updateConsultant: PropTypes.func.isRequired,
    resetConsultantPassword: PropTypes.func.isRequired,
    logOutConsultant: PropTypes.func.isRequired,
    unregisterConsultant: PropTypes.func.isRequired,
    banConsultant: PropTypes.func.isRequired,
    unBanConsultant: PropTypes.func.isRequired,
    fetchConsultantActivities: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    activities: state.consultant.activities,
    logOutRequestOngoing: state.consultant.logOutRequestOngoing,
    isFetchingActivities: state.consultant.isFetchingActivities,
});

export default withRouter(connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        updateConsultant,
        resetConsultantPassword,
        updateSupplier,
        fetchSupplierByConsultantID,
        logOutConsultant,
        unregisterConsultant,
        fetchConsultantActivities,
        banConsultant,
        unBanConsultant,
    }, dispatch),
)(ConsultantOverview));
