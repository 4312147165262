import * as request from 'request';

export function requestFetchJobs() {
    return { type: 'REQUEST_FETCH_JOBS' };
}

export function recieveJobs(resp) {
    return {
        type: 'RECEIVE_JOBS',
        jobs: resp.jobs,
    };
}

export function fetchJobs() {
    return dispatch => {
        dispatch(requestFetchJobs());
        return request.fetchJobs()
            .then(resp => dispatch(recieveJobs(resp)));
    };
}
