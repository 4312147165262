import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import config from 'config';

const EmailVerifSateBadge = ({
    verified,
    styles,
}) => (
    <div
        className='mpa-state-badge'
        style={({
            background: verified ? config.statusColors.activated : config.statusColors.pending,
            minWidth: 80,
            ...styles,
        })}
    >
        {verified ? 'Email verified' : 'Email not verified'}
    </div>
);

EmailVerifSateBadge.propTypes = {
    verified: PropTypes.bool.isRequired,
    styles: PropTypes.shape(),
};

export default EmailVerifSateBadge;
