import React from 'react';
import config from 'config';
import moment from 'moment';
import {
    groupBy,
    filter,
    map,
    chain,
} from 'lodash';
import {
    Select,
    Spin,
    Tabs,
    Popover,
    Tooltip,
} from 'antd';
import {
    LoadingOutlined,
    HeartOutlined,
    MobileOutlined,
    MailOutlined,
    ThunderboltOutlined,
    UserDeleteOutlined,
    UserAddOutlined,
    UserOutlined,
    HomeOutlined,
    RightOutlined,
    LeftOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import * as request from 'request';
import { marketingSourceTitle } from 'utils/nameformatters';
import CustomChart from 'components/customchart';
import SetTagsOnUsersModal from 'components/settagsonusersmodal';
import ChangeTicker from '../changeticker';
import styles from './styles.scss';

export default class SimpleOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            months: [],
            activeTabKey: moment().startOf('month').unix(),
            hasLoadedInitial: false,
            hasLoadedPrevMonth: false,
            prevMonthData: [],
            hasLoadedNextMonth: false,
            nextMonthData: [],
            northStarValue: 0,
            MAUConsultantsTotal: 0,
            MAUEmployersTotal: 0,
            consultantsTotal: 0,
            isUpdating: false,
            showMAUConsultantsInfo: false,
            MAUConsultantsInfo: [],
            selectedMAUSegment: null,
        };
    }

    componentDidMount() {
        this.props.fetchFunnelData(moment().startOf('month').format('YYYYMM'));
        this.getNearbyMonths(moment().startOf('month').unix());
        const currentMonth = moment().startOf('month').unix();
        const months = [currentMonth];
        for (let i = 1; i <= 6; i++) {
            months.push(moment.unix(currentMonth).subtract(i, 'month').unix());
            months.push(moment.unix(currentMonth).add(i, 'month').unix());
        }
        this.setState({ months: months.sort() });
    }

    componentDidUpdate(prevProps, prevState) {
        const { hasLoadedInitial } = this.state;

        if (!hasLoadedInitial) {
            if (prevProps.dashboard.isFetchingFunnelData && !this.props.dashboard.isFetchingFunnelData) {
                this.setState({ hasLoadedInitial: true });
            }
        }

        if (prevProps.dashboard.isFetchingFunnelData && !this.props.dashboard.isFetchingFunnelData) {
            const {
                shifts, employers, consultants, engagement,
            } = this.props.dashboard.funnelData;

            this.setState({
                northStarValue: shifts.bookings.total,
                MAUConsultantsTotal: consultants.bookings.total,
                MAUEmployersTotal: employers.bookings.total,
                isUpdating: false,
                consultantsTotal: consultants.total_users,
            });
        }

        if (this.state.showMAUConsultantsInfo && !prevState.showMAUConsultantsInfo) {
            if (!this.props.MAUConsultantsData || !this.props.MAUConsultantsData.length) {
                this.props.fetchMAUConsultantsData(moment.unix(this.state.activeTabKey).format('YYYYMM'));
            }
        }

        if (!prevProps.dashboard.MAUConsultantsData.length && this.props.dashboard.MAUConsultantsData.length && this.state.selectedMAUSegment) {
            const consultants = this.props.dashboard.MAUConsultantsData;
            let value;

            switch (this.state.selectedMAUSegment) {
                case 'new':
                    value = consultants.filter(consultant => consultant.first);
                    break;

                case 'returning':
                    value = consultants.filter(consultant => consultant.returning);
                    break;

                case 'lost':
                    value = consultants.filter(consultant => consultant.lost);
                    break;

                default:
                    value = [];
                    break;
            }

            this.setState({ MAUConsultantsInfo: value });
        }
    }

    getNearbyMonths(currentMonth) {
        const prevMonth = moment.unix(currentMonth).subtract(1, 'month').format('YYYYMM');
        const nextMonth = moment.unix(currentMonth).add(1, 'month').format('YYYYMM');

        request.fetchFunnelData(prevMonth).then(resp => {
            this.setState({
                prevMonthData: resp.data,
                hasLoadedPrevMonth: true,
            });
        });

        request.fetchFunnelData(nextMonth).then(resp => {
            this.setState({
                nextMonthData: resp.data,
                hasLoadedNextMonth: true,
            });
        });
    }

    renderBox(small = false, label, value) {
        return (
            <div
                style={{
                    backgroundColor: '#000', // '#4e4e4e',
                    width: small ? 150 : 200,
                    height: small ? 150 : 200,
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        fontSize: small ? 12 : 14,
                        fontWeight: '600',
                        // color: '#fff',
                        marginBottom: 4,
                    }}
                >
                    {label}
                </span>
                <strong
                    style={{
                        display: 'flex',
                        fontSize: small ? 30 : 40,
                        lineHeight: small ? '30px' : '40px',
                    }}
                >
                    {value}
                </strong>
            </div>
        );
    }

    onTabChange(value) {
        if (value !== this.state.activeTabKey) {
            this.setState({
                activeTabKey: +value,
                hasLoadedPrevMonth: false,
                prevMonthData: [],
                hasLoadedNextMonth: false,
                nextMonthData: [],
                northStarValue: 0,
                MAUConsultantsTotal: 0,
                MAUEmployersTotal: 0,
                consultantsTotal: 0,
                isUpdating: true,
            });

            this.props.fetchFunnelData(moment.unix(value).format('YYYYMM'));
            this.getNearbyMonths(value);
        }
    }

    renderTotalConsultantFunnel(data, total) {
        const formatedData = chain(data)
            .map((d, key) => ({
                source: key,
                value: d,
            }))
            .orderBy(['value']).reverse()
            .value();

        const dataSource = map(formatedData, data => (
            <div
                key={`${data.source}-${data.value}`}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    fontWeight: '600',
                    fontSize: '12px',
                    borderTop: '1px solid #ccc',
                    paddingTop: 5,
                    marginBottom: 5,
                }}
            >
                <div>
                    {marketingSourceTitle(data.source)}
                    :
                </div>
                <div>
                    (
                    {Math.round((data.value / total) * 100)}
                    %)
                    {data.value}
                    st
                </div>
            </div>
        ));

        const graphData = formatedData.map(d => d.value);
        const labels = formatedData.map(d => marketingSourceTitle(d.source));
        const backgroundColor = formatedData.map(d => '#25252f');

        return (
            <div>
                <strong
                    style={{
                        display: 'flex',
                        marginBottom: 5,
                    }}
                >
                    Marketing sources
                </strong>

                <CustomChart
                    backgroundColor={backgroundColor}
                    data={graphData}
                    labels={labels}
                    // height={300}
                    options={{ legend: { display: false } }}
                    type='bar'
                    width={400}
                />
                {dataSource.map(data => data)}
            </div>
        );
    }

    renderBookedShiftsFunnel() {
        const { shifts } = this.props.dashboard.funnelData;
        const {
            prevMonthData, hasLoadedPrevMonth, northStarValue, hasLoadedNextMonth,
        } = this.state;

        const mainValue = northStarValue || (
            <LoadingOutlined
                style={{
                    fontSize: '40px', paddingTop: 10,
                }}
            />
        );
        let compareValue = 0;

        if (hasLoadedPrevMonth && hasLoadedNextMonth) {
            const monthDiff = shifts.bookings.total - prevMonthData.shifts.bookings.total;
            const diffPercent = monthDiff / shifts.bookings.total * 100;

            compareValue = diffPercent;
        }

        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    width: 300,
                    height: 300,
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <ThunderboltOutlined
                    style={{
                        fontSize: 45,
                        marginBottom: 10,
                        marginTop: -10,
                    }}
                />
                <span
                    style={{
                        display: 'flex',
                        fontSize: 18,
                        fontWeight: '600',
                        marginBottom: 4,
                    }}
                >
                    Booked shifts
                </span>

                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                    }}
                >
                    <strong
                        style={{
                            display: 'flex',
                            fontSize: 70,
                            lineHeight: '70px',
                            // color: '#fff',
                            textAlign: 'center',
                        }}
                    >
                        {mainValue}
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: 18,
                        }}
                    >
                        <ChangeTicker
                            size={18}
                            value={compareValue}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderMAUValues(data, isMAUConsultants = false) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 15,
                    // marginLeft: 15,
                    // marginRight: -15,
                }}
            >
                <a
                    className='minor-value-circle'
                    disabled={!isMAUConsultants || !data.first.total}
                    style={{
                        color: '#1bd21b',
                        borderColor: '#1bd21b',
                    }}
                    onClick={() => {
                        this.setState({
                            showMAUConsultantsInfo: true,
                            selectedMAUSegment: 'new',
                        });
                    }}
                >
                    <Tooltip title='New'>
                        <UserAddOutlined />
                        {data.first.total}
                    </Tooltip>
                </a>
                <a
                    className='minor-value-circle'
                    disabled={!isMAUConsultants || !data.returning.total}
                    style={{
                        color: config.colors[13].hex,
                        borderColor: config.colors[13].hex,
                    }}
                    onClick={() => {
                        this.setState({
                            showMAUConsultantsInfo: true,
                            selectedMAUSegment: 'returning',
                        });
                    }}
                >
                    <Tooltip title='Returning'>
                        <UserOutlined />
                        {data.returning.total}
                    </Tooltip>
                </a>
                <a
                    className='minor-value-circle'
                    disabled={!isMAUConsultants || !data.lost.total}
                    style={{
                        color: '#f37a7a',
                        borderColor: '#f37a7a',
                        marginRight: 0,
                    }}
                    onClick={() => {
                        this.setState({
                            showMAUConsultantsInfo: true,
                            selectedMAUSegment: 'lost',
                        });
                    }}
                >
                    <Tooltip title='Lost'>
                        <UserDeleteOutlined />
                        {data.lost.total}
                    </Tooltip>
                </a>

            </div>
        );
    }

    renderTotal(value, changeValue) {
        const text = value || (
            <LoadingOutlined
                style={{
                    fontSize: '20px', paddingTop: 10,
                }}
                type='loading'
            />
        );

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                }}
            >
                <strong
                    style={{
                        fontSize: 16,
                        color: '#fff',
                        lineHeight: '14px',
                        textAlign: 'center',
                    }}
                >
                    Total
                </strong>
                <strong
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontSize: 55,
                        lineHeight: '55px',
                        color: '#fff',
                        marginRight: 10,
                        marginLeft: 10,
                    }}
                >
                    {text}
                </strong>
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 0,
                    }}
                >
                    <ChangeTicker
                        size={12}
                        value={changeValue}
                    />
                </div>
            </div>
        );
    }

    renderMAUEmployersTypes(data) {
        const graphData = [
            data.bookings.automotive,
            data.bookings.total - data.bookings.automotive,
        ];

        const labels = [
            'Automotive',
            'Maintained',
        ];

        const backgroundColor = [
            'rgb(228, 84, 124)',
            'rgb(138, 132, 251)',
        ];

        return (
            <div>
                <strong
                    style={{
                        display: 'flex',
                        marginBottom: 5,
                    }}
                >
                    Employer types
                </strong>

                <CustomChart
                    backgroundColor={backgroundColor}
                    data={graphData}
                    labels={labels}
                    // height={300}
                    options={{
                        responsive: true,
                        legend: { display: false },
                        scales: { yAxes: [{ ticks: {
                            // stepSize: 1,
                            min: 0 } }] },
                    }}
                    type='bar'
                    width={400}
                />
            </div>
        );
    }

    renderMAUEmployers() {
        const data = this.props.dashboard.funnelData.employers;
        const prevMonthData = this.state.prevMonthData.employers;
        let changeValue = 0;

        if (this.state.hasLoadedPrevMonth) {
            const monthDiff = data.bookings.total - prevMonthData.bookings.total;
            const diffPercent = monthDiff / data.bookings.total * 100;

            changeValue = diffPercent;
        }

        return (
            <div
                className='mau-employers'
                style={{
                    backgroundColor: '#25252f',
                    border: '0px solid #fff',
                    width: 275,
                    height: 275,
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        fontSize: 16,
                        fontWeight: '600',
                        color: '#fff',
                        marginBottom: 5,
                        marginTop: -10,
                        textAlign: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <HomeOutlined
                        style={{
                            fontSize: 30,
                            marginBottom: 5,
                        }}
                    />
                    <Tooltip title='Monthly Active Users'>MAU (Employers)</Tooltip>
                </span>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Popover
                        content={this.renderMAUEmployersTypes(data)}
                    >
                        {this.renderTotal(this.state.MAUEmployersTotal, changeValue)}
                    </Popover>
                </div>
                {this.renderMAUValues(data)}
            </div>
        );
    }

    renderMAUConsultants() {
        const data = this.props.dashboard.funnelData.consultants;
        const prevMonthData = this.state.prevMonthData.consultants;
        let changeValue = 0;

        if (this.state.hasLoadedPrevMonth) {
            const monthDiff = data.bookings.total - prevMonthData.bookings.total;
            const diffPercent = monthDiff / data.bookings.total * 100;

            changeValue = diffPercent;
        }

        return (
            <div
                className='mau-consultants'
                style={{
                    backgroundColor: '#25252f',
                    // border: '1px solid #fff',
                    width: 275,
                    height: 275,
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        fontSize: 16,
                        fontWeight: '600',
                        color: '#fff',
                        marginBottom: 5,
                        marginTop: -10,
                        textAlign: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <UserOutlined
                        style={{
                            fontSize: 30,
                            marginBottom: 5,
                        }}
                    />
                    <Tooltip title='Monthly Active Users'>MAU (Consultants)</Tooltip>
                </span>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Popover
                        content={this.renderTotalConsultantFunnel(data.bookings.sources, data.bookings.total)}
                    >
                        {this.renderTotal(this.state.MAUConsultantsTotal, changeValue)}
                    </Popover>
                </div>
                {this.renderMAUValues(data, true)}
            </div>
        );
    }

    renderTabs() {
        const {
            months, activeTabKey,
        } = this.state;

        return (
            <Tabs
                activeKey={`${activeTabKey}`}
                animated={{
                    inkBar: true,
                    tabPane: false,
                }}
                size='large'
                tabBarGutter={15}
                tabBarStyle={{
                    color: '#fff', // '#25252f',
                    height: 50,
                    borderBottom: 'none',
                    borderTop: 'none',
                    marginTop: 60,
                }}
                tabPosition='bottom'
                onChange={this.onTabChange.bind(this)}
            >
                {months.map(month => (
                    <Tabs.TabPane
                        key={month}
                        tab={
                            (month == moment().startOf('month').unix())
                                ? <strong>{moment.unix(month).format('MMM - YY')}</strong>
                                : <span>{moment.unix(month).format('MMM - YY')}</span>
                        }
                    >
                        {this.renderTabContent()}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        );
    }

    renderFunnelStep(iconName, text, data, percent, showArrow = true) {
        const componentsMap = {
            MobileOutlined,
            MailOutlined,
            HeartOutlined,
        };
        const DynamicIcon = componentsMap[iconName];
        return (
            <div
                style={{
                    textAlign: 'center',
                    width: `${100 / 3}%`,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    <Tooltip title={text}>
                        <DynamicIcon
                            style={{
                                fontSize: 18,
                                color: '#fff',
                            }}
                        />
                    </Tooltip>
                </div>
                <Popover
                    content={this.renderTotalConsultantFunnel(data.sources, data.total)}
                >
                    <p
                        style={{
                            margin: 0,
                            fontSize: 34,
                            lineHeight: '34px',
                            fontWeight: '600',
                            marginBottom: 0,
                            marginTop: 10,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {data.total}
                    </p>
                </Popover>
                <p
                    style={{ fontSize: 14 }}
                >
                    (
                    {percent}
                    %)
                </p>
                {showArrow && (
                    <RightOutlined
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            right: -8,
                            top: 0,
                            bottom: 0,
                            margin: 'auto',
                            fontSize: 12,
                            color: '#fff',
                            alignItems: 'center',
                        }}
                    />
                )}
            </div>
        );
    }

    renderEngagementFunnel() {
        const {
            engagement, consultants,
        } = this.props.dashboard.funnelData;
        const {
            activity, applications, bookings,
        } = engagement;

        const loggedInPercent = Math.round((activity.total / consultants.total_users) * 100) || 0;
        const appliedPercent = Math.round((applications.total / activity.total) * 100) || 0;
        const bookedPercent = Math.round((bookings.total / applications.total) * 100) || 0;

        let usersDiffPercent = 0;

        if (this.state.prevMonthData.consultants) {
            const usersDiff = consultants.total_users - this.state.prevMonthData.consultants.total_users;
            usersDiffPercent = (usersDiff / consultants.total_users) * 100;
        }

        return (
            <div
                className='engagement-funnel'
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 50,
                        marginBottom: 10,
                    }}
                >
                    <p
                        style={{
                            fontSize: 16,
                            color: '#fff',
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 600,
                            lineHeight: '20px',
                            marginBottom: 0,
                            // paddingBottom: 15,
                            marginRight: 15,
                            marginLeft: 15,
                            // borderBottom: '1px solid #fff',
                        }}
                    >
                        <TeamOutlined
                            style={{
                                fontSize: 34,
                                lineHeight: 34,
                                color: '#fff',
                                paddingRight: 10,
                                paddingBottom: 5,
                            }}
                        />
                        <br />
                        Consultant engagement
                    </p>
                </div>
                {this.renderTotal(this.state.consultantsTotal, usersDiffPercent)}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: '#fff',
                        paddingTop: 5,
                        marginTop: 10,
                    }}
                >
                    {this.renderFunnelStep('MobileOutlined', 'Logged in', activity, loggedInPercent)}
                    {this.renderFunnelStep('MailOutlined', 'Applied to job', applications, appliedPercent)}
                    {this.renderFunnelStep('HeartOutlined', 'Got booked', bookings, bookedPercent, false)}
                </div>
            </div>
        );
    }

    renderTabContent() {
        if (!this.state.hasLoadedInitial) {
            return this.renderSpinner();
        }

        const stepBackward = moment.unix(this.state.activeTabKey).subtract(1, 'month').unix();
        const stepForward = moment.unix(this.state.activeTabKey).add(1, 'month').unix();
        const stepBackwardPossible = this.state.months.indexOf(stepBackward) !== -1;
        const stepForwardPossible = this.state.months.indexOf(stepForward) !== -1;

        let prevMonth = 0;
        let nextMonth = 0;

        const {
            hasLoadedPrevMonth, hasLoadedNextMonth, prevMonthData, nextMonthData,
        } = this.state;

        if (hasLoadedPrevMonth && hasLoadedNextMonth) {
            prevMonth = prevMonthData.shifts.bookings.total;
            nextMonth = nextMonthData.shifts.bookings.total;
        }

        const monthIsInFuture = (this.state.activeTabKey > moment().startOf('month').unix());
        const isCurrentMonth = (this.state.activeTabKey === moment().startOf('month').unix());

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginBottom: 20,
                    }}
                >
                    <h1
                        style={{
                            color: '#fff',
                            textTransform: 'capitalize',
                            marginTop: 30,
                            marginBottom: 5,
                        }}
                    >
                        {moment.unix(this.state.activeTabKey).format('MMMM YYYY')}
                    </h1>
                    {monthIsInFuture && <strong style={{ color: '#fff' }}>(Future)</strong>}
                    {isCurrentMonth && <strong style={{ color: '#fff' }}>(Current)</strong>}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <a
                        style={{
                            position: 'absolute',
                            left: 40,
                            top: 0,
                            bottom: 0,
                            margin: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            opacity: (stepBackwardPossible && !this.state.isUpdating) ? 1 : 0.2,
                        }}
                        onClick={() => {
                            if (stepBackwardPossible && !this.state.isUpdating) {
                                this.onTabChange(stepBackward);
                            }
                        }}
                    >
                        <LeftOutlined
                            style={{
                                fontSize: '40px',
                                color: '#fff',
                            }}
                        />
                    </a>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 850,
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                position: 'relative',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 20,
                                paddingBottom: 30,
                            }}
                        >
                            <div
                                style={{
                                    paddingRight: 100,
                                    textAlign: 'center',
                                    color: '#ccc',
                                    width: 200,
                                }}
                            >
                                <strong>Previous</strong>
                                <h1 style={{ color: '#ccc' }}>{prevMonth}</h1>
                            </div>
                            {this.renderBookedShiftsFunnel()}
                            <div
                                style={{
                                    paddingLeft: 100,
                                    textAlign: 'center',
                                    color: '#ccc',
                                    width: 200,
                                }}
                            >
                                <strong>Next</strong>
                                <h1 style={{ color: '#ccc' }}>{nextMonth}</h1>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 0,
                                paddingBottom: 0,
                                justifyContent: 'space-around',
                            }}
                        >
                            {this.renderMAUConsultants('MAU (consultants)', 'consultants', 'user')}
                            {this.renderEngagementFunnel()}
                            {this.renderMAUEmployers('MAU (employers)', 'employers', 'home', true)}
                        </div>
                    </div>
                    <a
                        style={{
                            position: 'absolute',
                            right: 40,
                            top: 0,
                            bottom: 0,
                            margin: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            opacity: (stepForwardPossible && !this.state.isUpdating) ? 1 : 0.2,
                        }}
                        onClick={() => {
                            if (stepForwardPossible && !this.state.isUpdating) {
                                this.onTabChange(stepForward);
                            }
                        }}
                    >
                        <RightOutlined
                            style={{
                                fontSize: '40px',
                                color: '#fff',
                            }}
                        />
                    </a>
                </div>
            </div>
        );
    }

    renderSpinner(width) {
        return (
            <div
                style={{
                    width,
                    height: 400,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Spin size='large' />
            </div>
        );
    }

    renderSidebarContent() {
        const { months } = this.state;

        return (
            <div
                style={{
                    padding: 40,
                    background: '#FFF',
                    borderRadius: '4px 0 0 4px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    width: '100%',
                }}
            >
                <h6 className='mp-dashboard__value-title'>Välj månad</h6>
                <Select
                    defaultValue={months[months.indexOf(moment().startOf('month').unix())]}
                    size='large'
                    style={{
                        marginBottom: 20,
                        width: '100%',
                        fontWeight: '600',
                        fontSize: 20,
                    }}
                    onChange={this.handleChange.bind(this)}
                >
                    {months.map(month => (
                        <Select.Option key={month} value={month}>
                            {moment.unix(month).startOf('month').format('D')}
                            {' '}
                            -
                            {' '}
                            {moment.unix(month).endOf('month').format('D')}
                            {' '}
                            {moment.unix(month).startOf('month').format('MMM YYYY')}
                        </Select.Option>
                    ))}
                </Select>
                <h6 className='mp-dashboard__value-title'>Antal pass</h6>
                <div className='mp-dashboard__value-text' />

                <hr />

            </div>
        );
    }

    render() {
        return (

            <div
                className='simple-overview'
                style={{
                    marginBottom: 40,
                    paddingTop: 60,
                }}
            >
                <div>

                    <div
                        style={{
                            background: '#25252f',
                            padding: '40px 40px 60px 40px',
                            // marginTop: 50,
                            borderRadius: '0 4px 4px 0',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        }}
                    >

                        {this.renderTabs()}
                        <SetTagsOnUsersModal
                            close={() => {
                                this.setState({
                                    showMAUConsultantsInfo: false,
                                    selectedMAUSegment: null,
                                    MAUConsultantsInfo: [],
                                });
                            }}
                            consultants={this.state.MAUConsultantsInfo}
                            visible={this.state.showMAUConsultantsInfo}
                        />
                    </div>
                </div>

            </div>

        );
    }
}
