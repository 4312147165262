import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import FileIcon from './FileIcon';

function Documents({ documents }) {
    return documents.length ? (
        <>
            <h6>
                {documents.length}
                {' '}
                Document
                {documents.length > 1 ? 's' : ''}
            </h6>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',

                }}
            >
                {
                    documents.map((document, index) => (
                        <div
                            key={document.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '0.6rem',
                            }}
                        >
                            <Button
                                key={document.name}
                                ghost
                                icon={<FileIcon fileName={document.name} />}
                                style={{
                                    marginRight: 4, marginBottom: 4,
                                }}
                                type='primary'
                                onClick={() => window.open(document.url)}
                            >
                                {index + 1}
                                .
                                {document.name.split('.').pop()}
                            </Button>
                        </div>
                    ))
                }
            </div>
        </>
    ) : null;
}

Documents.propTypes = {
    documents: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    })).isRequired,
};

export default Documents;
