import React, { useState } from 'react';
import {
    connect,
    useDispatch,
    useSelector,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as employerActions from 'actions/employer';
import './styles.scss';
import {
    Button,
    Empty,
    Radio,
} from 'antd';
import moment from 'moment';
import JobAd from './jobad';
import EditJobAdModal from './editjobadmodal';
import {
    createRecruitment,
    updateRecruitment,
    republishJobPosting
} from '../../../actions/recruitments';
import config from '../../../config';

const Jobads = ({
    employer,
    settings,
}) => {
    const [showCreate, setShowCreate] = useState(false);
    const dispatch = useDispatch();
    const { job_postings: jobPostings } = useSelector(state => state.employer);

    const onSave = (data, editId) => {
        let postData = { ...data };
        const shift_types = data.shift_type_ids || [];

        // Ugly hack because of the way we need to handle three
        // different switch components that add values to one array...
        if (!shift_types.length) {
            settings.job_posting_shift_types.forEach(st => {
                if (postData[`shift_type_${st.name}`]) {
                    shift_types.push(st.id);
                }
                delete postData[`shift_type_${st.name}`];
            });
        }

        postData = {
            ...postData,
            shift_type_ids: shift_types,
            last_application_time: moment(postData.last_application_time).unix(),
        };

        if (!editId) {
            postData.job_posting_status = config.jobPostingStatus.DRAFT;
            return dispatch(createRecruitment(employer.employer.id, postData));
        }

        return dispatch(updateRecruitment(editId, postData));
    };

    const sortBy = (activeList) => (activeList.sort((j1, j2) => {
        const s1 = j1.updated_published_time ? j1.updated_published_time : j1.published_time;
        const s2 = j2.updated_published_time ? j2.updated_published_time : j2.published_time;
        return s2 - s1;

    }))

    const onRepublishJobPosting = (id) => dispatch(republishJobPosting(id))
    const [selectedCategory, setSelectedCategory] = useState(null);
    const activeList = selectedCategory !== null ? jobPostings.filter(p => p.job_posting_status === +selectedCategory) : jobPostings;
    const numDraft = jobPostings.filter(({ job_posting_status }) => job_posting_status === config.jobPostingStatus.DRAFT).length;
    const numPaused = jobPostings.filter(({ job_posting_status }) => job_posting_status === config.jobPostingStatus.PAUSED).length;
    const numPublished = jobPostings.filter(({ job_posting_status }) => job_posting_status === config.jobPostingStatus.PUBLISHED).length;
    const sortJobPosts = activeList.length ? sortBy(activeList) : []
    return (
        <div className='mpa-employer-tab-content'>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: 1200,
                    margin: '0 auto',
                }}
            >
                <Radio.Group
                    buttonStyle='solid'
                    defaultValue={null}
                    size='large'
                    onChange={e => { setSelectedCategory(e.target.value); }}
                >
                    <Radio.Button value={null}>
                        All
                    </Radio.Button>
                    <Radio.Button value={config.jobPostingStatus.PUBLISHED}>
                        Published (
                        {numPublished}
                        )
                    </Radio.Button>
                    <Radio.Button value={config.jobPostingStatus.DRAFT}>
                        Draft  (
                        {numDraft}
                        )
                    </Radio.Button>
                    <Radio.Button value={config.jobPostingStatus.PAUSED}>
                        Paused  (
                        {numPaused}
                        )
                    </Radio.Button>
                </Radio.Group>
                <Button
                    shape='round'
                    size='large'
                    type='primary'
                    onClick={() => { setShowCreate(true); }}
                >
                    Create job ad
                </Button>
            </div>
            {sortJobPosts.length ? (sortJobPosts.map(jobPosting => (
                <JobAd
                    key={jobPosting.id}
                    jobPosting={jobPosting}
                    settings={settings}
                    onEdit={onSave}
                    onRepublishJobPosting={onRepublishJobPosting}
                />
            ))) : <Empty description='No ads in this category' style={{ margin: '80px 0' }} />}
            <EditJobAdModal
                settings={settings}
                setVisibility={val => { setShowCreate(val); }}
                visible={showCreate}
                onSave={data => onSave(data)}
            />
        </div>
    );
};

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...employerActions }, dispatch),
)(Jobads);
