import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'utils/classnames';

export default class ChangeTicker extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        size: PropTypes.number,
    }

    constructor(props) {
        super(props);
    }

    render() {
        const {
            value, size,
        } = this.props;

        if (!value) {
            return (
                <span
                    style={{ float: 'right' }}
                >
                    -
                </span>
            );
        }

        return (

            <span style={{ float: 'right' }}>
                <span
                    style={{
                        color: value > 0 ? '#1bd21b' : '#f37a7a',
                        borderRadius: 4,
                        fontSize: size || 12,
                        fontWeight: 600,
                        display: 'inline-block',
                    }}
                >
                    <i
                        className={classNames({
                            fa: true,
                            'fa-caret-up': value > 0,
                            'fa-caret-down': value < 0,
                        })}
                    />
                    {' '}
                    {Math.abs(value).toFixed(1).toLocaleString('sv-se')}
                    %
                </span>
            </span>

        );
    }
}
