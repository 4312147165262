import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    Table,
    TimePicker,
    Form,
    Select,
    DatePicker,
    InputNumber,
    Input,
    message,
} from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import { copyShiftsToClipboard } from 'utils/copyToClipboard';
import { startEndTimeToDuration } from '../../utils/datetimeformatters';

const BatchUpdateScheduledModal = ({
    consultant,
    scheduled,
    visible,
    close,
    batchUpdateScheduled,
    hasBatchUpdatedScheduled,
}) => {
    const [form] = Form.useForm();
    const employers = scheduled.reduce((list, s) => {
        if (find(list, { id: s.employer.id })) {
            return list;
        }
        list.push(s.employer);
        return list;
    }, []);
    const [afterTime, setAfterTime] = useState(moment());
    const [selectedEmployer, setSelectedEmployer] = useState();
    const [hasStartedBatchUpdate, setHasStartedBatchUpdate] = useState(false);
    const [atLeastOneRequired, setAtLeastOneRequired] = useState(true);
    const requiredChangeFields = ['price', 'salary', 'consultantFee', 'startTime', 'endTime', 'shiftBreak'];
    const handleUpdateFieldChange = () => {
        const fields = form.getFieldsValue();
        const validation = Object.entries(fields).filter(a => {
            if (!requiredChangeFields.includes(a[0])) {
                return false;
            }
            if (a[1]) {
                return true;
            }
            return false;
        });
        setAtLeastOneRequired(validation.length < 1);
    };
    const columns = [{
        title: 'Employer',
        dataIndex: 'employer_name',
        key: 'employer_name',
        filteredValue: [selectedEmployer],
        onFilter: (value, record) => record.employer.id === selectedEmployer,
        render: (text, record) => record.employer.name,
    },
    {
        title: 'Date',
        dataIndex: 'start_time',
        key: 'start_date',
        render: startTime => moment.unix(startTime).format('YYYY-MM-DD'),
    },
    {
        title: 'Time',
        dataIndex: 'start_time',
        key: 'start_time',
        filteredValue: [afterTime],
        onFilter: (_, record) => (afterTime ? record.start_time > afterTime.unix() : true),
        render: (time, record) => `${moment.unix(time).format('HH:mm')} - ${moment.unix(record.end_time).format('HH:mm')}`,
    },
    {
        title: 'Shift Break',
        dataIndex: 'shift_break',
        key: 'shift_break',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
    }, {
        title: 'Salary',
        dataIndex: 'salary',
        key: 'salary',
    }, {
        title: 'Consultant fee',
        dataIndex: 'consultant_fee',
        key: 'consultant_fee',
    }];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            const ids = selectedRows.map(r => r.id);
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedIds(selectedRows.map(r => r.id));
            form.setFieldsValue({ scheduledIds: ids });
        },
        getCheckboxProps: record => ({ name: record.name }),
    };
    useEffect(() => {
        if (hasBatchUpdatedScheduled && hasStartedBatchUpdate) {
            setHasStartedBatchUpdate(false);
            close();
        }
    }, [hasBatchUpdatedScheduled, hasStartedBatchUpdate, close]);

    const getSelectedScheduld = () => scheduled.filter(s => {
        if (s.start_time < afterTime.unix()) {
            return false;
        }
        if (s.employer.id !== selectedEmployer) {
            return false;
        }
        if (!selectedIds.includes(s.id)) {
            return false;
        }

        return true;
    });

    const getScheduledRequestData = (s, values) => {
        const {
            startTime, endTime, price, salary, consultantFee, shiftBreak,
        } = values;
        const scheduledData = { id: s.id };
        if (startTime !== undefined) {
            scheduledData.start_time = moment.unix(s.start_time).tz(s.timezone).set({
                hour: startTime.format('HH'), minute: startTime.format('mm'),
            }).unix();
        }
        if (endTime !== undefined) {
            // Use start time instead of endtime because adding
            // extra day if start time is greater than end time
            const m = moment.unix(s.start_time).tz(s.timezone);
            if (endTime < startTime) {
                m.add(1, 'days');
            }
            scheduledData.end_time = m.set({
                hour: endTime.format('HH'), minute: endTime.format('mm'),
            }).unix();
        }
        if (scheduledData.start_time || scheduledData.end_time) {
            scheduledData.duration = (
                startEndTimeToDuration(
                    scheduledData.start_time || s.start_time,
                    scheduledData.end_time || s.end_time,
                )
            );
        }
        if (price !== undefined) {
            scheduledData.price = price;
        }
        if (salary !== undefined) {
            scheduledData.salary = salary;
        }
        if (consultantFee !== undefined) {
            scheduledData.consultant_fee = consultantFee;
        }
        if (shiftBreak !== undefined) {
            scheduledData.shift_break = shiftBreak;
        }
        return scheduledData;
    };

    const copySelectedShifts = async () => {
        const text = await copyShiftsToClipboard({
            user: consultant,
            employer: find(employers, { id: selectedEmployer }),
            scheduled: scheduled.filter(s => selectedIds.includes(s.id)),
        });
        navigator.clipboard.writeText(text).then(() => {
            message.success('Copied to clipboard');
        });
    };

    return (
        <Modal footer={null} visible={visible} width={1000} onCancel={close}>
            <Form.Item label='Employer'>
                <Select
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    placeholder='Select an employer'
                    showSearch
                    style={{ width: '100%' }}
                    onChange={id => {
                        setSelectedEmployer(id);
                    }}
                >
                    {
                        employers.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                    }
                </Select>

            </Form.Item>
            <Form.Item label='Efter datum'>
                <DatePicker
                    defaultValue={afterTime}
                    format='YYYY-MM-DD'
                    onChange={v => {
                        setAfterTime(v);
                    }}
                />
            </Form.Item>
            <Form
                form={form}
                labelCol={{ span: 4 }}
                layout='horizontal'
                wrapperCol={{ span: 14 }}
                onFinish={values => {
                    const newScheduled = getSelectedScheduld();
                    const requestData = newScheduled.map(s => getScheduledRequestData(s, values));
                    setHasStartedBatchUpdate(true);
                    batchUpdateScheduled(consultant.id, requestData);
                }}
            >

                <Table
                    columns={columns.sort((a, b) => (a.start_tim - b.start_time))}
                    dataSource={scheduled.filter(s => (!s.is_paid))}
                    pagination={false}
                    rowKey='id'
                    rowSelection={rowSelection}
                />

                <Form.Item
                    dependencies={requiredChangeFields}
                    label='Price'
                    name='price'
                    rules={[
                        {
                            required: atLeastOneRequired,
                            message: 'At least one change is required',
                        },
                    ]}
                    onChange={handleUpdateFieldChange}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    dependencies={requiredChangeFields}
                    label='Salary including pension'
                    name='salary'
                    rules={[
                        {
                            required: atLeastOneRequired,
                            message: 'At least one change is required',
                        },
                    ]}
                    onChange={handleUpdateFieldChange}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    dependencies={requiredChangeFields}
                    label='Consultant fee'
                    name='consultantFee'
                    rules={[
                        {
                            required: atLeastOneRequired,
                            message: 'At least one change is required',
                        },
                    ]}
                    onChange={handleUpdateFieldChange}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    dependencies={requiredChangeFields}
                    label='Start Time'
                    name='startTime'
                    rules={[
                        {
                            required: atLeastOneRequired,
                            message: 'At least one change is required',
                        },
                    ]}
                    onChange={handleUpdateFieldChange}
                >
                    <TimePicker format='HH:mm' onChange={handleUpdateFieldChange} />
                </Form.Item>
                <Form.Item
                    dependencies={requiredChangeFields}
                    label='End Time'
                    name='endTime'
                    rules={[
                        {
                            required: atLeastOneRequired,
                            message: 'At least one change is required',
                        },
                    ]}
                    onChange={handleUpdateFieldChange}
                >
                    <TimePicker format='HH:mm' onChange={handleUpdateFieldChange} />
                </Form.Item>
                <Form.Item
                    dependencies={requiredChangeFields}
                    label='Shift break'
                    name='shiftBreak'
                    rules={[
                        {
                            required: atLeastOneRequired,
                            message: 'At least one change is required',
                        },
                    ]}
                    onChange={handleUpdateFieldChange}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    hidden
                    name='scheduledIds'
                    rules={[{
                        required: true, message: 'At least one shift has to be selected',
                    }]}
                >
                    <Input />
                </Form.Item>

                <Button htmlType='submit' type='primary'>
                    Submit
                </Button>
                <Button
                    type='primary'
                    onClick={copySelectedShifts}
                >
                    Copy shifts
                </Button>
            </Form>
        </Modal>
    );
};

BatchUpdateScheduledModal.propTypes = {
    scheduled: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        employer: PropTypes.shape({}),
    })).isRequired,
    consultant: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
    close: PropTypes.func.isRequired,
    hasBatchUpdatedScheduled: PropTypes.bool.isRequired,
    batchUpdateScheduled: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default BatchUpdateScheduledModal;
