import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import activities from './activities';
import activityTypes from './activity_types';
import applications from './applications';
import availableShifts from './available_shifts';
import benefits from './benefits';
import { comments } from './comments';
import consultant from './consultant';
import { consultants } from './consultants';
import { consultantsFilter } from './consultantsfilter';
import createEmployer from './createemployer';
import { dashboard } from './dashboard';
import { employer } from './employer';
import { employer_attests } from './employer_attests';
import { employers } from './employers';
import employersFilter from './employersfilter';
import employerssearch from './employerssearch';
import { externalAds } from './externalAds';
import { faqs } from './faqs';
import invoiceList from './invoicelist';
import { invoices } from './invoices';
import { job } from './job';
import { jobPostingTracking } from './jobPostingTracking';
import { jobs } from './jobs';
import { leads } from './leads';
import { ltvs } from './ltvs';
import { orders } from './orders';
import { procurements } from './procurements';
import { recruitments } from './recruitments';
import { salaryErrorList } from './salaryerrorlist';
import { salaryList } from './salarylist';
import scheduled from './scheduled';
import { session } from './session';
import settings from './settings';
import { shifts } from './shifts';
import { sickLeave } from './sickleave';
import { super_admin } from './super_admin';
import { tags } from './tags';
import { tasks } from './tasks';
import { userMarketingSources } from './usermarketingsources';

const createRootReducer = history => combineReducers({
    router: connectRouter(history),

    /* custom reducers */
    session,
    activityTypes,
    applications,
    availableShifts,
    benefits,
    employers,
    employerssearch,
    employer,
    employersFilter,
    consultants,
    consultantsFilter,
    consultant,
    settings,
    invoices,
    employer_attests,
    activities,
    jobs,
    job,
    comments,
    tags,
    invoiceList,
    salaryList,
    salaryErrorList,
    shifts,
    userMarketingSources,
    faqs,
    sickLeave,
    createEmployer,
    dashboard,
    recruitments,
    scheduled,
    ltvs,
    procurements,
    tasks,
    jobPostingTracking,
    leads,
    externalAds,
    super_admin,
    orders,
});

export default createRootReducer;
