import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    checkConsultantHosp,
    fetchConsultantHosp,
} from 'actions/consultant';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import './styles.scss';
import { LinkOutlined } from '@ant-design/icons';
import config from '../../../config';

const ConsultantHosp = ({
    consultant,
    hospChecks,
    fetchConsultantHosp,
    checkConsultantHosp,
}) => {
    const { id } = useParams();
    useEffect(() => {
        fetchConsultantHosp(id);
    }, [id, fetchConsultantHosp]);

    const checkHosp = () => {
        checkConsultantHosp(id);
    };

    const renderCollection = () => hospChecks.map((check, i) => (
        <article
            key={`check-${i}`}
            className='hospArticle'
        >
            <div className='background-check'>
                <h5>Bakgrundskontroll</h5>
            </div>
            <p>{`Kontrollerad: ${moment.unix(check.created_time).format('YYYY-MM-DD HH:mm')}`}</p>
            <p>{`Namn: ${check.fullname}`}</p>
            <p>{`Domstolshändelser : ${check.legal_count}`}</p>
            <p>
                Behörighet:
                <ul>
                    {check.spec ? check.spec.map((spec, j) => (
                        <li key={`checkSpec-${j}`}>
                            <details>
                                <summary>
                                    <strong>{spec.name}</strong>
                                    {' '}
                                    {spec.competenceText}
                                </summary>
                                <ul>
                                    <li>
                                        Code:
                                        {' '}
                                        {spec.competenceCode}
                                    </li>
                                    <li>
                                        Level:
                                        {' '}
                                        {spec.competenceLevel}
                                    </li>
                                    <li>
                                        Limits:
                                        {' '}
                                        {spec.limits}
                                    </li>
                                    <li>
                                        Registration Date:
                                        {' '}
                                        {spec.registrationDate}
                                    </li>
                                    <li>
                                        Regulations:
                                        {' '}
                                        {spec.regulations}
                                    </li>

                                </ul>
                            </details>
                        </li>
                    )) : 'Inga'}
                </ul>
            </p>
            {check.legal_count > 0 ? (
                <div className='background-check'>
                    <a href='https://nusvar.se/personsok/' rel='noopener noreferrer' target='_blank'>
                        <LinkOutlined />
                        {' '}
                        Kontrollera brottmål
                    </a>
                </div>
            ) : null}
        </article>
    ));

    if (consultant.country_code !== 'SWE') {
        return (
            <section className='content-card-full'>
                Netherlands users does not have a swedish personal number.
            </section>
        );
    }

    return (
        <section className='content-card-full'>
            {renderCollection()}
            <Button
                disabled={consultant.occupation_id === config.occupations.OCCUPATION_USK}
                size='large'
                type='primary'
                onClick={checkHosp}
            >
                Kontrollera HOSP
            </Button>
        </section>
    );
};

ConsultantHosp.propTypes = {
    consultant: PropTypes.shape({
        country_code: PropTypes.string.isRequired,
        occupation_id: PropTypes.number.isRequired,
    }),
    hospChecks: PropTypes.arrayOf(PropTypes.shape({
        fullname: PropTypes.string.isRequired,
        created_time: PropTypes.number.isRequired,
        spec: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
    })),
    checkConsultantHosp: PropTypes.func.isRequired,
    fetchConsultantHosp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    hospChecks: state.consultant.hospChecks,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        checkConsultantHosp,
        fetchConsultantHosp,
    }, dispatch),
)(ConsultantHosp);
