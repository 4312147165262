import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    withRouter,
    useParams,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchSickLeavedConsultant } from 'actions/sickleave';
import { updateBookedShift } from 'actions/shifts';

import {
    Table,
    List,
    Avatar,
    Button,
} from 'antd';

// import './styles.scss';

const ConsultantSickLeave = ({
    sickLeaves,
    isFetchingSickLeavedConsultant,
    isUpdatingBookedShift,
    updateBookedShift,
    fetchSickLeavedConsultant,
}) => {
    const { id } = useParams();
    useEffect(() => {
        if (isUpdatingBookedShift) { return; }
        fetchSickLeavedConsultant(id);
    }, [fetchSickLeavedConsultant, id, isUpdatingBookedShift]);

    const renderChildren = record => {
        const bookings = record.scheduled;
        return (
            <div>
                <h6
                    style={{ marginTop: 20 }}
                >
                    Påverkade bokningar

                </h6>
                <List
                    dataSource={bookings}
                    itemLayout='horizontal'
                    renderItem={item => (

                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={item.employer.logo} />}
                                description={item.employer.department}
                                title={(
                                    <Link to={`/employers/${item.employer.id}/shifts/${item.shift_id}`}>
                                        {item.employer.name}
                                    </Link>
                                )}
                            />
                            <List.Item.Meta
                                description={
                                    `${moment.unix(item.start_time).format('YYYY-MM-DD HH:mm')}-${moment.unix(item.end_time).format('HH:mm')}`
                                }
                            />
                            <List.Item.Meta
                                description={(
                                    <Button
                                        onClick={() => {
                                            updateBookedShift(item.id, { sick_leave: false });
                                        }}
                                    >
                                        {' '}
                                        Återställ pass
                                    </Button>
                                )}
                            />
                        </List.Item>

                    )}
                />
            </div>
        );
    };

    const renderContent = () => {
        const columns = [{
            title: 'Rapporterad',
            key: 'created_time',
            render: text => `${moment.unix(text.created_time).format('YYYY-MM-DD')} kl ${moment.unix(text.created_time).format('HH:mm')}`,
        }, {
            title: 'Varaktighet',
            key: 'end_time',
            render: text => {
                const duration = moment.unix(text.end_time).diff(moment.unix(text.start_time), 'days');
                const durationText = duration > 1 ? 'dagar' : 'dag';
                return `${duration} ${durationText}`;
            },
        }, {
            title: 'Datum',
            key: 'start_time',
            render: text => `${moment.unix(text.start_time).format('YYYY-MM-DD')} - ${moment.unix(text.end_time).format('YYYY-MM-DD')}`,
        },
        // childcare
        {
            title: 'VAB',
            key: 'childcare',
            render: text => {
                if (text.childcare) {
                    return 'Ja';
                }
                return 'Nej';
            },
        }, {
            title: 'Orsak',
            dataIndex: 'reason',
            key: 'reason',
        }];

        return (
            <Table
                columns={columns}
                dataSource={sickLeaves}
                expandedRowRender={renderChildren}
                loading={isFetchingSickLeavedConsultant}
                pagination={(sickLeaves && sickLeaves.length > 30) || false}
            />
        );
    };

    return (
        <div
            className='content-card-full'
        >
            <div>
                {renderContent()}
            </div>
        </div>
    );
};

ConsultantSickLeave.propTypes = {
    sickLeaves: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        scheduled: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            shift_id: PropTypes.number.isRequired,
            start_time: PropTypes.number.isRequired,
            end_time: PropTypes.number.isRequired,
            employer: PropTypes.shape({
                name: PropTypes.string.isRequired,
                department: PropTypes.string.isRequired,
            }).isRequired,
        })),
        created_time: PropTypes.number.isRequired,
        end_time: PropTypes.number.isRequired,
    })),
    isFetchingSickLeavedConsultant: PropTypes.bool.isRequired,
    isUpdatingBookedShift: PropTypes.bool.isRequired,
    fetchSickLeavedConsultant: PropTypes.func,
    updateBookedShift: PropTypes.func,
};

const mapStateToProps = state => ({
    sickLeaves: state.sickLeave.sickLeavedConsultant,
    isFetchingSickLeavedConsultant: state.sickLeave.isFetchingSickLeavedConsultant,
    isUpdatingBookedShift: state.shifts.isUpdatingBookedShift,
});

export default withRouter(connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        fetchSickLeavedConsultant, updateBookedShift,
    }, dispatch),
)(ConsultantSickLeave));
