import React from 'react';
import moment from 'moment';

export default class Loader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let text = 'Pre-loading all the data, go grab a coffee';
        let iconName = 'fa fa-circle-o-notch fa-spin';
        let iconStyle = {};

        if (moment().format('d') == 5) {
            text = 'Highway to the danger zone!';
            iconName = 'ion-plane';
            iconStyle = {
                color: '#000',
                transform: 'rotate(45deg)',
                fontSize: 70,
                marginBottom: 10,
            };
        }
        else if (moment().format('d') == 1) {
            text = "Don't worry. It won't be monday forever.";
        }

        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'rgba(255, 255, 255, 0.75)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 48,
                    zIndex: 3,
                    color: '#5b53f9',
                }}
            >
                <i
                    className={iconName}
                    style={iconStyle}
                />
                <div
                    style={{
                        fontSize: 16,
                        marginTop: 20,
                    }}
                >
                    {text}
                </div>
            </div>
        );
    }
}
