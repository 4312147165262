import React, { useState } from 'react';
import {
    connect,
    useDispatch,
} from 'react-redux';
import {
    EditOutlined,
    IdcardOutlined,
    ScheduleOutlined,
    MedicineBoxOutlined,
    BookOutlined,
    BulbOutlined,
    MessageOutlined,
    GlobalOutlined,
} from '@ant-design/icons';
import config from 'config';
import { getFormattedDate } from 'utils/datetimeformatters';
import {
    getExperienceSubtitle,
    departmentTypeTitle,
    skillTitle,
    emrSystemTitle,
    getWorkTypeTitle,
} from 'utils/nameformatters';

import './styles.scss';
import {
    Button,
    Switch,
} from 'antd';
import PrintableCV from './printablecv';
import { fetchConsultantSSN } from '../../../actions/consultant';

const ConsultantCV = ({
    id,
    firstname,
    lastname,
    description,
    driver_license,
    ecert,
    educations,
    emr_systems,
    experiences,
    hsaid,
    languages,
    references,
    settings,
    occupation,
    big_number,
    wrapperClassName = 'content-card-full hide-in-print',
}) => {
    const dispatch = useDispatch();
    const [hideContactInPrint, setHideContactInPrint] = useState(true);
    const [hideSSNInPrint, setHideSSNInPrint] = useState(false);
    const onExportPDF = async () => {
        const originalTitle = document.title;
        document.title = `${firstname}_${lastname}_medpeople_profil`;
        if (!hideSSNInPrint) {
            await dispatch(fetchConsultantSSN(id));
        }
        window.print();
        document.title = originalTitle;
    };

    return (
        <div>
            <div className='hide-in-print flex flex-row items-center'>
                <Button
                    type='primary'
                    onClick={() => onExportPDF()}
                >
                    Export PDF
                </Button>
                <div className='flex flex-row'>
                    <div className='flex flex-row items-center ml-2'>
                        <Switch
                            checked={hideContactInPrint}
                            onChange={value => setHideContactInPrint(value)}
                        />
                        <div className='ml-2'>Hide contact info / HSA</div>
                    </div>
                    <div className='flex flex-row items-center ml-2'>
                        <Switch
                            checked={hideSSNInPrint}
                            onChange={value => setHideSSNInPrint(value)}
                        />
                        <div className='ml-2'>Hide SSN</div>
                    </div>
                </div>
            </div>
            <PrintableCV
                hideContact={hideContactInPrint}
                hideSSN={hideSSNInPrint}
            />

            <section className={wrapperClassName}>
                <h4 className='cv__section-title'>
                    <EditOutlined />
                    Description
                </h4>
                <p className='cv__section-body cv__section-body--wide'>{description}</p>

                <h4 className='cv__section-title'>
                    <IdcardOutlined />
                    Licenses
                </h4>
                <ul className='cv__section-body'>
                    <li className='cv__list-item'>
                        <span>E-Tjänstekort</span>
                        {ecert ? 'Yes' : 'No'}
                    </li>
                    <li className='cv__list-item'>
                        <span>HSA-id</span>
                        {hsaid || '–'}
                    </li>
                    <li className='cv__list-item'>
                        <span>BIG number</span>
                        {big_number || '–'}
                    </li>
                    <li className='cv__list-item'>
                        <span>Driver's license</span>
                        {driver_license ? 'Yes' : 'No'}
                    </li>
                </ul>

                <h4 className='cv__section-title'>
                    <ScheduleOutlined />
                    Journal Systems
                </h4>
                <ul className='cv__section-body'>
                    {emr_systems.map(systemId => (
                        <li key={systemId}>{settings.emr_systems.find(s => s.id === systemId).name}</li>
                    ))}
                </ul>

                <h4 className='cv__section-title'>
                    <MedicineBoxOutlined />
                    Work Experience
                </h4>
                <ul className='cv__section-body'>
                    {experiences.length ? experiences
                        .sort((a, b) => b.start_time - a.start_time)
                        .map((experience, i) => ([
                            <li key={i} className='cv__experience-item'>
                                <ul className='cv__experience-list'>
                                    <li>
                                        <h4 className='cv__list-item'>
                                            <span>{experience.name}</span>
                                            {`${getFormattedDate(experience.start_time)} – ${!experience.end_time ? 'Current' : getFormattedDate(experience.end_time)}`}
                                        </h4>
                                    </li>
                                    <li className='cv__list-item'>
                                        <span>Department type:</span>
                                        {' '}
                                        {departmentTypeTitle(experience.secondary_department_type_id)}
                                    </li>
                                    <li className='cv__list-item'>
                                        <span>Skills:</span>
                                        {' '}
                                        {experience.skill_ids.length ? experience.skill_ids.map(skillId => skillTitle(skillId, occupation)).join(', ') : 'Not set'}
                                    </li>
                                    <li className='cv__list-item'>
                                        <span>Work type:</span>
                                        {' '}
                                        {experience.work_type ? getWorkTypeTitle(experience.work_type) : 'Not set'}
                                    </li>
                                    <li className='cv__list-item'>
                                        <span>EMR system:</span>
                                        {' '}
                                        {experience.emr_system_id ? emrSystemTitle(experience.emr_system_id) : 'Not set'}
                                    </li>
                                    <li className='cv__list-item'>
                                        <span>Description:</span>
                                        {' '}
                                        {getExperienceSubtitle(experience)}
                                    </li>
                                </ul>
                            </li>,
                        ])) : 'Not added yet'}
                </ul>

                <h4 className='cv__section-title'>
                    <BookOutlined />
                    Specialist Education
                </h4>
                <ul className='cv__section-body'>
                    {educations.length ? educations
                        .filter(e => e.basic === false)
                        .sort((a, b) => b.end_time - a.end_time)
                        .map((education, i) => (
                            <li key={i}>
                                <h5 className='cv__list-item'>
                                    <span>{settings.specializations.find(s => s.id === education.specialization_id).name}</span>
                                    {education.end_time ? getFormattedDate(education.end_time) : 'Pågående'}
                                </h5>
                                <p>{education.name}</p>
                            </li>
                        )) : 'Not added yet'}
                </ul>

                <h4 className='cv__section-title'>
                    <BulbOutlined />
                    Basic Education
                </h4>
                <ul className='cv__section-body'>
                    {educations.length ? educations
                        .filter(e => e.basic === true)
                        .sort((a, b) => b.end_time - a.end_time)
                        .map((education, i) => (
                            <li key={i} className='cv__list-item'>
                                <span>{education.name}</span>
                                {!education.ongoing ? getFormattedDate(education.end_time) : 'Pågående'}
                            </li>
                        ))
                        : 'Not added yet'}
                </ul>

                <h4 className='cv__section-title'>
                    <MessageOutlined />
                    References
                </h4>
                <ul className='cv__section-body'>
                    {references.length ? references.map(reference => (
                        <li key={reference.id} className='cv__list-item cv__list-item--column'>
                            <h5>{reference.name}</h5>
                            <p>
                                <a href={`tel:${reference.phone}`}>{reference.phone}</a>
                                {' '}
                                |
                                {' '}
                                <a href={`mailto:${reference.email}`}>{reference.email}</a>
                            </p>
                            <p className='cv__reference-text'>
                                "
                                {reference.description}
                                "
                            </p>
                        </li>
                    )) : 'Not added yet'}
                </ul>

                <h4 className='cv__section-title'>
                    <GlobalOutlined />
                    Languages
                </h4>
                <ul className='cv__section-body'>
                    {languages.length ? languages
                        .sort((a, b) => a.level - b.level)
                        .map(language => (
                            <li key={language.id} className='cv__list-item'>
                                <span>{settings.languages.find(l => l.id === language.id).name}</span>
                                {config.languageLevels.find(l => l.id === language.level).name}
                            </li>
                        ))
                        : 'Not added yet'}
                </ul>
            </section>
        </div>
    );
};

const mapStateToProps = state => ({
    ...state.consultant.consultant,
    settings: state.settings,
});

export default connect(mapStateToProps)(ConsultantCV);
