const initialState = { invoices: [] };

export function invoices(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_INVOICES':
            return {
                ...state, invoices: action.invoices,
            };

        default:
            return state;
    }
}
