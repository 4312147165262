import React, {
    useEffect,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as salaryListActions from 'actions/salarylist';
import { Link } from 'react-router-dom';
import { reduce } from 'lodash';
import moment from 'moment';

import { humanReadableHours } from 'utils/datetimeformatters';

import {
    Table,
    Button,
} from 'antd';
import './styles.scss';

import config from 'config';

const SalaryDetailsTable = ({ scheduled }) => {
    const columns = [{
        title: 'Datum',
        dataIndex: 'start_time',
        key: 'date',
        render: (text, record) => moment.unix(record.start_time).format('YYYY-MM-DD'),
    }, {
        title: 'Arbetstid',
        dataIndex: 'scheduled',
        key: 'scheduled',
        render: (text, record) => (
            `${moment.unix(record.start_time).format('HH:mm')} - ${moment.unix(record.end_time).format('HH:mm')}`
        ),
    }, {
        title: 'Arbetad tid',
        dataIndex: 'duration',
        key: 'duration',
        render: text => humanReadableHours(text),
    }, {
        title: 'Övertid',
        dataIndex: 'deviation',
        key: 'deviation',
        render: text => humanReadableHours(text),
    }, {
        title: 'Rast',
        dataIndex: 'shift_break',
        key: 'shift_break',
        render: text => humanReadableHours(text),
    }, {
        title: 'Pris',
        dataIndex: 'price',
        key: 'price',
        render: text => `${text}kr`,
    }, {
        title: 'Lön',
        dataIndex: 'salary',
        key: 'salary',
        render: text => `${text}kr`,
    }];

    return (
        <Table
            columns={columns}
            dataSource={scheduled}
            pagination={false}
            rowKey='id'
            size='middle'
        />
    );
};

const SalaryTable = ({
    salaryList, setSelectedRowKeys, setSelectedIds, rowSelection,
}) => {
    const getWeeklySalaryRows = () => data.reduce((filtered, row) => {
        if (row.user.salary_type !== config.consultantSalaryTypes.SALARY) {
            return filtered;
        }
        if (row.user.weekly_salary) {
            filtered.push(row);
        }
        return filtered;
    }, []);
    const getMonthlySalaryRows = () => data.reduce((filtered, row) => {
        if (row.user.salary_type !== config.consultantSalaryTypes.SALARY) {
            return filtered;
        }
        if (!row.user.weekly_salary) {
            filtered.push(row);
        }
        return filtered;
    }, []);
    const getInvoiceRows = () => data.reduce((filtered, row) => {
        if (row.user.salary_type === config.consultantSalaryTypes.INVOICE) {
            filtered.push(row);
        }
        return filtered;
    }, []);

    const data = salaryList.map((item, i) => ({
        ...item,
        key: i + 1,
    }));
    const columns = [
        {
            title: 'Namn',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Link to={`/consultants/${record.user.id}`}>
                    {record.user.name}
                </Link>
            ),
        }, {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (row, record) => record.user.id,
        }, {
            title: 'Pass',
            dataIndex: 'pass',
            key: 'pass',
            render: (row, record) => record.scheduled.length,
        }, {
            title: 'Timmar',
            dataIndex: 'hours',
            key: 'hours',
            render: (row, record) => humanReadableHours(
                reduce(record.scheduled, (hours, r) => hours + ((r.duration - r.shift_break) + r.deviation), 0),
            ),
        }, {
            title: 'Övertid',
            dataIndex: 'deviation',
            key: 'deviation',
            render: (row, record) => humanReadableHours(reduce(record.scheduled, (hours, r) => hours + r.deviation, 0)),
        }, {
            title: 'Rast',
            dataIndex: 'break',
            key: 'break',
            render: (row, record) => humanReadableHours(
                reduce(record.scheduled, (hours, r) => hours + r.shift_break, 0),
            ),
        }, {
            title: 'Total',
            dataIndex: 'total_cost',
            key: 'total_cost',
            render: text => `${text}kr`,
        }, {
            title: 'Bruttolön',
            dataIndex: 'gross_salary',
            key: 'gross_salary',
            render: text => `${text}kr`,
        }, {
            title: 'Nettolön',
            dataIndex: 'net_salary',
            key: 'net_salary',
            render: text => `${text}kr`,
        }, {
            title: 'Arbetsgivaravgift',
            dataIndex: 'payroll_tax',
            key: 'payroll_tax',
            render: text => `${text}kr`,
        }, {
            title: 'Lönetyp',
            dataIndex: ['user', 'weekly_salary'],
            render: (isWeekly, record) => {
                if (record.user.salary_type === config.consultantSalaryTypes.INVOICE) {
                    return 'Faktura';
                }
                return isWeekly ? 'Veckolön' : 'Månadslön';
            },
        },
    ];

    if (salaryList.length) {
        const data = salaryList.map((item, i) => ({
            ...item,
            key: i + 1,
        }));

        return (
            <div>

                <Button
                    onClick={() => {
                        const weeklyRows = getWeeklySalaryRows();
                        setSelectedRowKeys(weeklyRows.map(r => r.key));
                        setSelectedIds(weeklyRows.map(r => r.user.id));
                    }}
                >
                    Markera Veckolön
                </Button>
                <Button
                    onClick={() => {
                        const monthlyRows = getMonthlySalaryRows();
                        setSelectedRowKeys(monthlyRows.map(r => r.key));
                        setSelectedIds(monthlyRows.map(r => r.user.id));
                    }}
                >
                    Markera Månadslön
                </Button>
                <Button
                    onClick={() => {
                        const invoiceRows = getInvoiceRows();
                        setSelectedRowKeys(invoiceRows.map(r => r.key));
                        setSelectedIds(invoiceRows.map(r => r.user.id));
                    }}
                >
                    Markera fakturor
                </Button>
                <Table
                    columns={columns}
                    dataSource={data}
                    expandedRowRender={record => (
                        <SalaryDetailsTable
                            scheduled={record.scheduled}
                            user={record.user}
                        />
                    )}
                    pagination={false}
                    rowSelection={rowSelection}
                />
            </div>
        );
    }
};

const SalaryList = ({
    salaryList,
    isFetching,
    isExporting,
    fetchSalaryList,
    exportSalaryList,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (_, selectedRows) => {
            setSelectedIds(selectedRows.map(r => r.user.id));
            setSelectedRowKeys(selectedRows.map(r => r.key));
        },
    };
    useEffect(() => {
        fetchSalaryList();
    }, [fetchSalaryList]);

    const renderSubmit = () => (

        <div
            style={{
                display: 'flex',
                marginTop: 20,
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}
        >
            <div>
                <Button
                    disabled={!selectedIds.length || isExporting}
                    loading={salaryList.isExporting}
                    size='large'
                    type='primary'
                    onClick={() => {
                        exportSalaryList(selectedIds);
                    }}
                >
                    Skapa
                    {' '}
                    {selectedIds.length}
                    {' '}
                    underlag
                </Button>
            </div>
        </div>

    );

    const renderContent = () => {
        if (isFetching) {
            return (
                <div
                    style={{
                        height: 400,
                        margin: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 24,
                    }}
                >
                    <i className='fa fa-circle-o-notch fa-spin' />
                </div>
            );
        }

        if (!isFetching && !salaryList.length) {
            return (
                <div>
                    Det finns inga löneunderlag att skapa
                </div>
            );
        }

        return (
            <div>
                <h3>Löneexport</h3>
                <div
                    style={{
                        marginTop: 40,
                        background: '#FFF',
                        overflow: 'scroll',
                    }}
                >
                    <SalaryTable
                        rowSelection={rowSelection}
                        salaryList={salaryList}
                        setSelectedIds={setSelectedIds}
                        setSelectedRowKeys={setSelectedRowKeys}
                    />
                </div>
                {renderSubmit()}
            </div>
        );
    };

    return (

        <main className='salary-list'>
            {renderContent()}
        </main>

    );
};

export default connect(
    state => ({
        salaryList: state.salaryList.salaryList,
        isFetching: state.salaryList.isFetching,
        isExporting: state.salaryList.isExporting,
    }),
    dispatch => bindActionCreators(salaryListActions, dispatch),
)(SalaryList);
