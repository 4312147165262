import * as request from 'request';
import { message } from 'antd';

const requestFetchAdminUsers = () => ({ type: 'REQUEST_FETCH_ADMIN_USERS' });
const receiveFetchAdminUser = adminUsers => ({
    type: 'RECEIVE_FETCH_ADMIN_USERS', adminUsers,
});
const requestUpdateAdminUser = () => ({ type: 'REQUEST_UPDATE_ADMIN_USER' });
const receiveUpdateAdminUser = adminUser => ({
    type: 'RECEIVE_UPDATE_ADMIN_USER', adminUser,
});
const requestDeleteAdminUser = () => ({ type: 'REQUEST_DELETE_ADMIN_USER' });
const receiveDeleteAdminUser = adminUser => ({
    type: 'RECEIVE_DELETE_ADMIN_USER', adminUser,
});

export const getAdminUsersV1 = () => async dispatch => {
    dispatch(requestFetchAdminUsers());
    try {
        const res = await request.getAdminUsersV1();
        dispatch(receiveFetchAdminUser(res.collection));
    }
    catch {
        message.error('Failed to fetch admin users.');
    }
};

export const updateAdminUserV1 = (adminId, requestData) => async dispatch => {
    dispatch(requestUpdateAdminUser());
    try {
        const res = await request.updateAdminUserV1(adminId, requestData);
        dispatch(receiveUpdateAdminUser(res));
    }
    catch {
        message.error('Failed to update admin users.');
    }
};

export const deleteAdminUserV1 = (adminId, requestData) => async dispatch => {
    dispatch(requestDeleteAdminUser());
    try {
        const res = await request.deleteAdminUserV1(adminId, requestData);
        dispatch(receiveDeleteAdminUser(res));
    }
    catch {
        message.error('Failed to delete admin users.');
    }
};
