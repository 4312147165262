import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { getMarginOptions } from '../../utils/margins';

const ShiftMarginDropdown = ({
    value,
    onChange,
    size = '',
    placeholder = '',
}) => {
    const marginOptions = getMarginOptions();

    return (
        <div>
            <Select
                dropdownMatchSelectWidth={false}
                size={size}
                value={value || placeholder || 'Standard'}
                onChange={val => onChange(val)}
            >
                {marginOptions.map(option => (
                    <Select.Option
                        key={option.value}
                        value={option.value}
                    >
                        {option.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

ShiftMarginDropdown.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    placeholder: PropTypes.string,
};
export default ShiftMarginDropdown;
