import React from 'react';
import {
    find,
    filter,
} from 'lodash';
import config from 'config';

export function occupationTitle(id) {
    const state = getStore().getState();
    const occupation = find(state.settings.occupations, { id });
    return occupation ? occupation.name : '';
}

export function specializationTitle(id) {
    const state = getStore().getState();
    const specialization = find(state.settings.specializations, { id });
    return specialization ? specialization.name : '';
}

export function skillTitle(skillId, occupationId) {
    const state = getStore().getState();
    const jobSkills = filter(state.settings.skills, { occupation_id: occupationId });
    const skill = find(jobSkills, { id: skillId });
    return skill ? skill.name : '';
}

export function emrSystemTitle(emrId) {
    const state = getStore().getState();
    const emrSystem = find(state.settings.emr_systems, { id: emrId });
    return emrSystem ? emrSystem.name : '';
}

export function consultantInvoiceStatusTitle(invoiceStatus) {
    const status = find(config.consultantInvoiceStatus, { value: invoiceStatus });
    return status ? status.title : 'Unknown';
}

export function experienceTitle(experienceId) {
    const exp = find(config.consultantExperience, { value: +experienceId });
    return exp ? exp.title : 'Unknown';
}

export function employerStatusTitle(statusId) {
    const status = find(config.employerStatus, { value: statusId });
    return status ? status.title : 'Unknown employer status is set';
}

export function consultantStatusTitle(statusId) {
    const status = find(config.consultantStatus, { value: statusId });
    return status ? status.title : 'Unknown consultant status is set';
}

export function statusColor(statusTitle) {
    const color = config.statusColors[statusTitle];
    return color || '#000';
}

export function consultantStatusColor(statusId) {
    const color = find(config.consultantStatus, { value: statusId });
    return color ? color.color : '#000';
}

export function countyName(countyId) {
    const { counties } = getStore().getState().settings;
    const county = find(counties, { id: countyId });
    return county ? county.name : 'Unknown county is set';
}

export function departmentTypeTitle(departmentTypeId) {
    const { settings } = getStore().getState();
    const departmentTypes = settings.secondary_department_types;
    const item = find(departmentTypes, { id: departmentTypeId });
    return item ? item.name : '';
}

export const nl2br = description => description.split('\n').map((item, key) => (
    <span key={key}>
        {item}
        <br />
    </span>
));

export function marketingSourceTitle(id) {
    const state = getStore().getState();

    if (id == 0) {
        return 'Old';
    }

    const marketingSource = state.settings.user_marketing_sources.find(item => item.id == id);
    return marketingSource ? marketingSource.title : '';
}

export function employerTypeTitle(id) {
    switch (id) {
        case 0:
            return 'Not set';
        case 1:
            return 'Public';
        case 2:
            return 'Private';
        default:
            return 'Unknown';
    }
}

export function jobPostingStatus(status) {
    switch (status) {
        case config.jobPostingStatus.DRAFT:
            return 'Draft';
        case config.jobPostingStatus.PUBLISHED:
            return 'Published';
        case config.jobPostingStatus.DELETED:
            return 'Deleted';
        case config.jobPostingStatus.PAUSED:
            return 'Paused';
        case config.jobPostingStatus.BOOKED:
            return 'Hired';
        default:
            return 'Unknown';
    }
}

export const getExperienceSubtitle = ({
    ismedpeople,
    description,
    employers,
    shifts,
}) => {
    if (ismedpeople) {
        if (shifts > 1) {
            return `${shifts} shifts at ${employers} different employers`;
        }

        if (shifts === 1) {
            return `${shifts} shift at one employer`;
        }

        return 'Not set';
    }

    return description;
};

export const getWorkTypeTitle = workTypeId => {
    const workType = find(config.workTypes, { id: workTypeId });
    return workType ? workType.name : 'Unknown';
};

export const mapToValueTitle = (
    options,
    valueName,
    titleName,
) => options.map(option => ({
    value: option[valueName],
    title: option[titleName],
}));

export const ssnFormatter = value => {
    const ssn = value.replace(/\D/g, '');
    switch (ssn.length) {
        case 10:
            return `${ssn.substr(0, 6)}-${ssn.substr(6, 4)}`;
        case 12:
            return `${ssn.substr(0, 8)}-${ssn.substr(8, 4)}`;
        default:
            return value;
    }
};

export const pluralFormatter = (count, singular, plural) => (count === 1 ? singular : plural);

/**
 *
 * Takes a string and returns the initials of the first to words,
 * alternatively, if the name is only one word, returns the first two
 * letters of that word
 * @param {string} name
 */
export function nameAsInitials(name) {
    if (!name) return '';
    if (name.indexOf(' ') !== -1) {
        const split = name.split(' ');
        return split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
    }
    return name.substr(0, 2).toUpperCase();
}

export function engagementTitle(engagementId) {
    const state = getStore().getState();
    console.log('STATE', state);
    const engagement = state.settings.job_posting_engagements.find(e => e.id === engagementId);
    return engagement ? engagement.name : '';
}
