import React from 'react';

import { filter } from 'lodash';

import * as consultantActions from 'actions/consultants';
import * as jobsActions from 'actions/jobs';
import * as employerActions from 'actions/employers';
import * as tagsActions from 'actions/tags';
import * as sickLeaveActions from 'actions/sickleave';
import * as dashboardActions from 'actions/dashboard';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from 'components/shared/loader';
import DashboardHistory from 'containers/HomeDashboard/dashboardhistory';

import './styles.scss';

class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dataFetched: false };
    }

    componentDidMount() {
        if (!this.props.jobs) {
            this.props.fetchJobs();
        }

        if (!this.props.consultants) {
            this.props.fetchConsultants();
        }

        if (!this.props.employers) {
            this.props.fetchEmployers();
        }

        this.props.fetchSickLeavedConsultants();
    }

    render() {
        if (!this.props.ready) {
            return (
                <Loader />
            );
        }

        return (
            <main className='statistics'>
                <div className='content'>

                    <DashboardHistory
                        {...this.props}
                    />

                </div>
            </main>
        );
    }
}

function selectFilteredJobs(state) {
    return filter(state.jobs.jobs, j => !j.employer.test_employer);
}

function selectShifts(jobs) {
    return jobs.reduce((arr, job) => arr.concat(job.shifts.map(shift => ({
        ...shift,
        occupation: job.occupation_id,
        employerId: job.employer.id,
        isBooked: shift.bookings.length > 0,
        introduction: job.introduction,
    }))), []);
}

function mapStateToProps(state) {
    if (!(state.consultants.hasFetched && state.jobs.hasFetched && state.employers.hasFetched)) {
        return { ready: false };
    }

    const jobs = selectFilteredJobs(state);
    const shifts = selectShifts(jobs);

    return {
        ready: true,
        shifts,
        jobs,
        hours: shifts.reduce((t, s) => t + s.duration, 0),
        employers: filter(state.employers.employers, e => !e.test_employer),
        consultants: filter(state.consultants.consultants, c => c.email.indexOf('medpeople') === -1),
        dashboard: state.dashboard,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(
        {
            ...tagsActions,
            ...consultantActions,
            ...jobsActions,
            ...employerActions,
            ...sickLeaveActions,
            ...dashboardActions,
        }, dispatch,
    ),
)(Statistics);
