import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { occupationTitle } from 'utils/nameformatters';
import * as consultantActions from 'actions/consultant';
import * as commentsActions from 'actions/comments';
import Avatar from 'components/shared/avatar';
import TagsList from 'components/tagslist';
import Sidebar from 'components/shared/sidebar';
import config from 'config';
import { Link } from 'react-router-dom';
import StateBadge from 'components/shared/statebadge';
import LastActive from 'components/lastactive';
import ConsultantCV from 'containers/consultant/cv';
import './styles.scss';
import PrintableCV from '../consultant/cv/printablecv';

class ConsultantsSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: 0 };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.consultantId !== nextProps.consultantId) {
            this.props.fetchConsultant(nextProps.consultantId);
            this.props.fetchUserComments(nextProps.consultantId);
        }
    }

    sideBarContent() {
        return (
            <div>
                {this.renderLinks()}
                {this.renderTop()}
                {this.renderContent()}
            </div>
        );
    }

    renderTabBar() {
        const { activeTab } = this.state;
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'center',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                }}
            >
                <div
                    style={{
                        flex: 1,
                        padding: '20px 0',
                        color: activeTab === 0 ? '#666' : '#CCC',
                        borderBottom: activeTab === 0 ? '4px solid #5b53f9' : '4px solid #EEE',
                        cursor: 'pointer',
                        transition: 'all .2s ease',
                    }}
                    onClick={() => {
                        this.setState({ activeTab: 0 });
                    }}
                >
                    Overview
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: '20px 0',
                        color: activeTab === 1 ? '#666' : '#CCC',
                        borderBottom: activeTab === 1 ? '4px solid #5b53f9' : '4px solid #EEE',
                        cursor: 'pointer',
                        transition: 'all .2s ease',
                    }}
                    onClick={() => {
                        this.setState({ activeTab: 1 });
                    }}
                >
                    CV
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: '20px 0',
                        color: activeTab === 2 ? '#666' : '#CCC',
                        borderBottom: activeTab === 2 ? '4px solid #5b53f9' : '4px solid #EEE',
                        cursor: 'pointer',
                        transition: 'all .2s ease',
                    }}
                    onClick={() => {
                        this.setState({ activeTab: 2 });
                    }}
                >
                    Comments
                    {' '}
                    {this.props.comments.comments.length ? `(${this.props.comments.comments.length})` : ''}
                </div>
            </div>
        );
    }

    renderConsultantAddress(consultant) {
        return (
            <div>
                {consultant.addressline1}
                {' '}
                <br />
                {consultant.addressline2}
                {' '}
                {consultant.addressLine2 && <br />}
                {consultant.postalcode}
                {' '}
                <br />
                {consultant.city}
                {' '}
                <br />
            </div>
        );
    }

    renderConsultantSalaryInfo(consultant) {
        return (
            <div>
                Clearing number:
                {' '}
                {consultant.account_clearing_number || 'Not entered'}
                {' '}
                <br />
                Account number:
                {' '}
                {consultant.account_number || 'Not entered'}
            </div>
        );
    }

    renderOverviewContent() {
        const { consultant } = this.props;
        return (
            <div
                style={{
                    padding: 40,
                    paddingTop: 0,
                    flex: 1,
                    minWidth: 500,
                }}
            >
                <div
                    style={{
                        marginTop: 40,
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'right',
                    }}
                >
                    <div style={{ fontWeight: 600 }}>Registered</div>
                    <div>
                        {moment.unix(consultant.created).format('D MMM YYYY')}
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 40,
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'right',
                    }}
                >
                    <div style={{ fontWeight: 600 }}>Last active</div>
                    <div>
                        <LastActive
                            lastActive={consultant.last_active}
                        />
                    </div>
                </div>
                <div
                    style={{
                        borderTop: '1px solid #f3f3f3',
                        marginTop: 20,
                        paddingTop: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'right',
                    }}
                >
                    <div style={{ fontWeight: 600 }}>State</div>
                    <div>
                        <StateBadge
                            created={consultant.created}
                            state={consultant.state}
                        />
                    </div>
                </div>
                <div
                    style={{
                        borderTop: '1px solid #f3f3f3',
                        marginTop: 20,
                        paddingTop: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ fontWeight: 600 }}>Address</div>
                    <div>
                        {this.renderConsultantAddress(consultant)}
                    </div>
                </div>
                <div
                    style={{
                        borderTop: '1px solid #f3f3f3',
                        marginTop: 20,
                        paddingTop: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ fontWeight: 600 }}>Salary info</div>
                    <div>
                        {this.renderConsultantSalaryInfo(consultant)}
                    </div>
                </div>
            </div>
        );
    }

    renderAddComment() {
        return (

            <div>
                <textarea
                    ref='textarea'
                    placeholder='Add a comment...'
                    rows={3}
                    style={{
                        width: '100%',
                        borderRadius: 4,
                        resize: 'none',
                        borderColor: '#EEE',
                        borderBottom: 'none',
                        outline: 'none',
                        padding: 20,
                        borderRadius: '6px 6px 0 0',
                    }}
                />
                <div
                    style={{
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        borderRadius: '0 0 6px 6px',
                    }}
                >
                    <button
                        className='btn btn-primary'
                        style={{
                            borderRadius: '0 0 6px 0',
                            backgroundColor: '#5b53f9',
                            borderColor: '#5b53f9',
                        }}
                        onClick={() => {
                            const content = this.refs.textarea.value;
                            if (!content) {

                            }
                            else {
                                this.props.addUserComment(this.props.consultantId, content);
                                this.refs.textarea.value = '';
                            }
                            //
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>

        );
    }

    renderCommentsList() {
        if (!this.props.comments.comments.length) {
            return (
                <div
                    style={{
                        padding: 40,
                        textAlign: 'center',

                    }}
                >
                    No comments
                </div>
            );
        }

        return [...this.props.comments.comments].reverse().map(comment => (
            <div
                style={{ marginTop: 20 }}
            >
                <div
                    style={{
                        padding: 20,
                        background: '#f9f9f9',
                    }}
                >
                    {comment.comment}
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 5,
                        justifyContent: 'space-between',
                        fontSize: 12,
                        color: '#999',
                    }}
                >
                    <div>{comment.admin_user.username}</div>
                    <div>{moment.unix(comment.created_time).fromNow()}</div>
                </div>
            </div>
        ));
    }

    renderCommentsContent() {
        const { isFetching } = this.props.comments;

        return (
            <div
                style={{
                    padding: 40,
                    flex: 1,
                    minWidth: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                {isFetching && this.renderLoader()}
                {!isFetching && this.renderAddComment()}
                {!isFetching && this.renderCommentsList()}
            </div>
        );
    }

    renderTabsContent() {
        const translation = this.state.activeTab * -500;
        return (
            <div
                style={{
                    width: 500,
                    overflow: 'auto',
                    flex: 1,
                }}
            >
                <div
                    style={{
                        width: 1000,
                        transition: 'transform .2s ease-out',
                        transform: `translateX(${translation}px)`,
                        display: 'flex',
                    }}
                >
                    {this.renderOverviewContent()}
                    <PrintableCV
                        consultant={this.props.consultant}
                    />
                    <ConsultantCV {...this.props.consultant} wrapperClassName='sidebar-cv' />
                    {this.renderCommentsContent()}
                </div>
            </div>
        );
    }

    renderTop() {
        const {
            consultant, open,
        } = this.props;

        return (
            <div
                className='mpa-consultants__sidebar-content'
                style={{
                    position: 'relative',
                    zIndex: 20,
                }}
            >
                <div
                    style={{ flex: 1 }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: 40,
                            paddingBottom: 0,
                        }}
                    >
                        <div
                            style={{ marginRight: 10 }}
                        >
                            <Avatar
                                size={90}
                                url={consultant.avatar}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <h4>
                                {consultant.firstname}
                                {' '}
                                {consultant.lastname}
                                <span
                                    style={{
                                        color: '#999',
                                        fontSize: 14,
                                        float: 'right',
                                    }}
                                >
                                    #
                                    {consultant.id}
                                </span>
                            </h4>
                            <div
                                style={{ color: '#999' }}
                            >
                                {occupationTitle(consultant.occupation)}
                            </div>
                            <div
                                style={{
                                    color: '#999',
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                            >
                                <div>
                                    {consultant.email}
                                </div>
                                <div>
                                    <a href={`tel:${consultant.phone}`}>{consultant.phone}</a>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div
                        style={{
                            padding: 40,
                            paddingTop: 0,
                            paddingBottom: 20,
                        }}
                    >
                        <TagsList
                            consultantId={consultant.id}
                            popoverPlacement='left'
                            tagsList={consultant.tags}
                        />
                    </div>
                    {this.renderTabBar()}
                </div>
            </div>
        );
    }

    renderContent() {
        return (
            <div
                style={{
                    flex: 2,
                    overflow: 'scroll',
                    overflow: 'auto',
                    height: 'calc(100vh - 340px)',
                }}
            >
                {this.renderTabsContent()}
            </div>
        );
    }

    renderLoader() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 32,
                }}
            >
                <span
                    className='fa fa-circle-o-notch fa-spin'
                />
            </div>
        );
    }

    renderLinks() {
        const { consultant } = this.props;

        return (
            <div
                style={{
                    position: 'absolute',
                    top: 20,
                    left: 20,
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <div style={{ display: 'inline-flex' }}>
                    <a
                        className='mpa-intercom-link'
                        href={`http://app.intercom.io/apps/${config.intercomAppId}/users/show?email=${consultant.email}`}
                        target='_blank'
                    >
                        <div className='mpa-intercom-logo' />
                        Open in Intercom
                        {' '}
                        <span className='ion-android-open' />
                    </a>
                </div>
                <div
                    style={{
                        display: 'inline-flex', marginLeft: 20,
                    }}
                >
                    <Link
                        className='mpa-intercom-link'
                        to={`/consultants/${consultant.id}`}

                    >
                        View full details
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const {
            consultant, open, onClose, isFetching,
        } = this.props;

        return (
            <Sidebar
                isLoading={isFetching}
                open={open}
                onClose={onClose}
            >

                {!isFetching ? this.sideBarContent() : null}

            </Sidebar>
        );
    }
}

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    isFetching: state.consultant.isFetching,
    comments: state.comments,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        ...consultantActions, ...commentsActions,
    }, dispatch),
)(ConsultantsSidebar);
