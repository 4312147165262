import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Blurb from '../../components/benefitsModal/Blurb';
import Company from '../../components/benefitsModal/Company';
import './styles.scss';

const BenefitsList = ({
    benefits,
    onChange,
    onRow,
    pagination: {
        page,
        totalCount,
    },
}) => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (_, benefit) => <div className='benefit-list-clickable-item'>{benefit.id}</div>,
            sorter: true,
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            sorter: true,
            render: (_, benefit) => (
                <div className='benefit-list-clickable-item'>
                    <Company logo={benefit.logo} name={benefit.company_name} />
                </div>
            ),
        },
        {
            title: 'Paused',
            dataIndex: 'paused',
            sorter: true,
            render: (_, benefit) => <div className='benefit-list-clickable-item'>{benefit.paused ? 'Paused' : ''}</div>,
        },
        {
            title: 'Text',
            dataIndex: 'text',
            render: (_, benefit) => <div className='benefit-list-clickable-item'>{benefit.text}</div>,
        },
        {
            title: 'Blurb',
            dataIndex: 'blurb',
            render: (_, benefit) => (
                <div className='benefit-list-clickable-item'>
                    <Blurb blurb={benefit.blurb} blurbPrefix={benefit.blurb_prefix} />
                </div>
            ),
        },
        {
            title: 'Code',
            dataIndex: 'promo_code',
            render: (_, benefit) => <div className='benefit-list-clickable-item'>{benefit.promo_code}</div>,
            sorter: true,
        },
        {
            title: 'Link',
            dataIndex: 'link',
            render: (_, benefit) => (benefit.link ? <a href={benefit.link} rel='noreferrer' target='_blank'>#</a> : ''),
        },
    ];

    const rowClassName = record => {
        const {
            start_datetime,
            end_datetime,
            paused,
        } = record;

        const currentTime = moment();
        const hasStarted = !moment(start_datetime).startOf('d').isAfter(currentTime.startOf('d'));
        const hasNotEnded = !moment(end_datetime).endOf('d').isBefore(moment().endOf('d'));

        const isActive = hasStarted && hasNotEnded && !paused;

        return isActive ? 'benefit-list-row-active' : 'benefit-list-row-inactive';
    };

    return !!benefits.length && (
        <Table
            columns={columns}
            dataSource={benefits}
            pagination={{
                position: 'both',
                current: page,
                pageSize: 50,
                total: totalCount,
            }}
            rowClassName={record => rowClassName(record)}
            rowKey='id'
            size='large'
            onChange={onChange}
            onRow={onRow}
        />
    );
};

BenefitsList.propTypes = {
    benefits: PropTypes.arrayOf(PropTypes.shape({
        attachment: PropTypes.string,
        blurb: PropTypes.string.isRequired,
        blurb_prefix: PropTypes.string,
        company_name: PropTypes.string.isRequired,
        country_code: PropTypes.string.isRequired,
        end_datetime: PropTypes.string,
        end_time: PropTypes.number,
        start_datetime: PropTypes.string.isRequired,
        start_time: PropTypes.number,
        hide_link: PropTypes.bool,
        id: PropTypes.number.isRequired,
        link: PropTypes.string,
        logo: PropTypes.string.isRequired,
        paused: PropTypes.bool,
        promo_code: PropTypes.string,
        text: PropTypes.string.isRequired,
        timezone: PropTypes.string.isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    onRow: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        page: PropTypes.number.isRequired,
        totalCount: PropTypes.number.isRequired,
    }),
};
export default BenefitsList;
