import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputModal from 'components/inputmodal';
import * as employerActions from 'actions/employer';
import {
    Button,
    Modal,
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import './styles.scss';
import config from 'config';
import UserCredentials from './UserCredentials';

const ChangeCredentials = ({
    employer,
    employerAddNewCredential,
    updateEmployerCredential,
    deleteEmployerCredential,
}) => {
    const employerId = employer.employer.id;

    const [formData, setFormData] = useState({});
    const [createNewCredential, setCreateNewCredential] = useState(false);

    const handleNewCredential = () => setCreateNewCredential(true);

    return (
        <div>
            <div className='mpa-users-top'>
                <Button
                    className='mpa-employer-add-new'
                    icon={<UserAddOutlined />}
                    size='large'
                    style={{ lineHeight: '24px' }}
                    onClick={handleNewCredential}
                >
                    Add new credential
                </Button>
            </div>
            <div>
                <Modal
                    cancelText='Cancel'
                    okText='Save'
                    title='Add new credential'
                    visible={createNewCredential}
                    onCancel={() => {
                        setCreateNewCredential(false);
                    }}
                    onOk={() => {
                        employerAddNewCredential(employerId, formData).then(() => {
                            setCreateNewCredential(false);
                        });
                    }}
                >
                    <InputModal
                        hideButton
                        inputs={[
                            {
                                type: 'input',
                                name: 'name',
                                label: 'name',
                            },
                            {
                                type: 'input',
                                name: 'email',
                                label: 'email',
                                inputType: 'email',
                                validations: { matchRegexp: config.regexp.email },
                                validationErrors: { matchRegexp: 'Not a valid email' },
                            },
                            {
                                type: 'input',
                                name: 'username',
                                label: 'username',
                            },

                        ]}
                        onChange={form => {
                            setFormData(form);
                        }}
                    />
                </Modal>

                <div
                    className='mpa-employer-tab-content'
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {employer.users.map((user => (

                        <UserCredentials
                            key={user.id}
                            deleteEmployerCredential={deleteEmployerCredential}
                            employer={employer}
                            updateEmployerCredential={updateEmployerCredential}
                            user={user}

                        />
                    )))}
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = state => ({ employer: state.employer });

const mapDispatchToProps = dispatch => ({
    employerAddNewCredential:
        (employerId, formData) => dispatch(employerActions.employerAddNewCredential(employerId, formData)),
    updateEmployerCredential:
        (id, formData) => dispatch(employerActions.updateEmployerCredential(id, formData)),
    deleteEmployerCredential: id => dispatch(employerActions.deleteEmployerCredential(id)),
});

ChangeCredentials.propTypes = {
    employer: PropTypes.shape({
        username: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        users: PropTypes.string,
        employer: PropTypes.string,
    }).isRequired,
    employerAddNewCredential: PropTypes.func.isRequired,
    updateEmployerCredential: PropTypes.func.isRequired,
    deleteEmployerCredential: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCredentials);
