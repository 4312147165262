const initialState = {
    isFetchingFunnelData: false,
    isFetchingMAUConsultantsData: false,
    MAUConsultantsData: [],
    funnelData: [],
};

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_FUNNEL_DATA':

            return {
                ...state, isFetchingFunnelData: true,
            };

        case 'RECEIVE_FETCH_FUNNEL_DATA':

            return {
                ...state,
                isFetchingFunnelData: false,
                funnelData: action.funnel,
            };

        case 'RESET_FUNNEL_DATA':

            return {
                ...state,
                isFetchingFunnelData: false,
                funnelData: [],
            };

        case 'REQUEST_FETCH_MAU_CONSULTANTS_DATA':

            return {
                ...state,
                isFetchingMAUConsultantsData: true,
                MAUConsultantsData: [],
            };

        case 'RECEIVE_FETCH_MAU_CONSULTANTS_DATA':

            return {
                ...state,
                isFetchingMAUConsultantsData: false,
                MAUConsultantsData: action.data,
            };

        case 'RESET_MAU_CONSULTANTS_DATA':

            return {
                ...state,
                isFetchingMAUConsultantsData: false,
                MAUConsultantsData: [],
            };

        default:

            return state;
    }
}
