import * as Icon from '@ant-design/icons';
// import * as Ui from 'components/shared/ui';
import {
    Avatar,
    Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { nameAsInitials } from 'utils/nameformatters';

const CvExperienceRow = ({
    employerName,
    subtitle,
    employerLogo,
    showMpIcon = false,
    rating,
    departmentType,
    userFirstName,
}) => {
    const getAvatar = () => {
        if (showMpIcon) {
            return (
                <div className='mp-cv-logo' />
            );
        }
        if (employerLogo) {
            return (
                <Avatar
                    size={40}
                    src={employerLogo}
                    style={{
                        minWidth: 40,
                    }}
                />
            );
        }

        return (
            <div className='employer-icon'>
                {nameAsInitials(employerName)}
            </div>
        );
    };
    return (
        <li className='section-list-item'>
            <div className='cv-left-column'>
                {getAvatar()}
                <div>

                    <strong>
                        {employerName}
                    </strong>
                    {departmentType ? <p className='type'>{departmentType}</p> : <></>}
                    <p className='type'>
                        {subtitle}
                    </p>
                </div>
            </div>
            <div className='cv-right-column'>
                {rating && rating.score ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        <p className='type'>
                            <strong>
                                Uppdragsgivarens omdöme om
                                {' '}
                                {userFirstName}
                            </strong>
                        </p>
                        <Tag
                            color='#ffbc00'
                            icon={<Icon.StarFilled />}
                        >
                            {' '}
                            {rating.score}
                        </Tag>
                        {rating.review ? (
                            <p className='type'>
                                "
                                {rating.review}
                                "
                            </p>
                        ) : null}
                    </div>
                ) : null}

            </div>
        </li>
    );
};

CvExperienceRow.propTypes = {
    employerName: PropTypes.string,
    subtitle: PropTypes.string,
    employerLogo: PropTypes.string,
    showMpIcon: PropTypes.bool,
    departmentType: PropTypes.string,
    rating: PropTypes.number,
    userFirstName: PropTypes.string.isRequired,
};

export default CvExperienceRow;
