import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const SelectFilter = ({
    id,
    label,
    placeholder,
    current,
    options,
    onChange,
}) => {
    const filterFunction = (input, option) => option.children.toLowerCase().includes(input.toLowerCase());

    return (
        <form className='consultantsfilter__form-section'>
            <label
                className='consultantsfilter__label'
                htmlFor={id}
            >
                {label}
                <Select
                    filterOption={filterFunction}
                    mode='multiple'
                    placeholder={placeholder}
                    size='large'
                    value={current}
                    onChange={value => onChange(value)}
                >
                    {options.map(({
                        id: optionId,
                        name,
                    }) => (
                        <Select.Option key={optionId} value={optionId}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </label>
        </form>
    );
};

SelectFilter.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    current: PropTypes.arrayOf(PropTypes.number),
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectFilter;
