import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/shared/button';
import '../styles.scss';
import ConsultantPreview from '../../../../components/shared/consultantpreview';

function Booking({
    vacancy,
    employerShifts,
    toggleBookingsModal,
}) {
    if (vacancy.bookings.length) {
        return (
            <div>
                {vacancy.bookings.map(application => (
                    <div key={`applicationmodal${application.id}`} className='ltv-detail-application'>
                        <ConsultantPreview
                            subTitle={`${application.shifts.length} shifts`}
                            user={application.user}
                        />
                        <div>
                            <Button
                                disabled={!!application.shifts.length && !employerShifts.length}
                                loading={!!application.shifts.length && !employerShifts.length}
                                onClick={() => {
                                    toggleBookingsModal();
                                }}
                            >
                                {application.shifts.length ? 'Edit shifts' : 'Add shifts'}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    return null;
}

Booking.propTypes = {
    vacancy: PropTypes.shape({
        id: PropTypes.number.isRequired,
        bookings: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            shifts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
            })).isRequired,
        })).isRequired,
    }).isRequired,
    employerShifts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
    })).isRequired,
    toggleBookingsModal: PropTypes.func.isRequired,
};

export default Booking;
