import React, {
    useEffect,
    useState,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { omit } from 'lodash';
import CheckRow from '../../../components/shared/checkrow/index.js';
import ModalFooter from './ModalFooter';

function CopyEmployer({
    employer: procurementEmployer,
    setCopiedEmployerData,
    onSubmit,
    setIsModalVisible,
}) {
    const {
        employer,
        profiles,
    } = procurementEmployer;

    const [copyData, setCopyData] = useState({
        shouldCopyDescription: true,
        shouldCopyInfo: true,
        shouldCopyInvoiceAddress: true,
        shouldCopyContacts: true,
        shouldCopyLogo: true,
        shouldCopyCover: true,
        shouldCopyCustomShifts: true,
        shouldCopyProfiles: true,
        selectedCustomShifts: [],
        selectedProfiles: [],
    });

    const updateCopyData = (key, data) => {
        setCopyData(prevState => ({
            ...prevState,
            [key]: data,
        }));
    };

    const handleOk = useCallback(() => {
        const data = {
            email: employer.email,
            phone: employer.phone,
            account_email: employer.account_email,
            account_phone: employer.account_phone,
            account_name: employer.account_name,
            account_title: employer.account_title,
            emr_system: employer.emr_system,
            county_id: employer.county_id,
            automotive: employer.automotive ? 'automotive' : 'maintained',
            secondary_department_type_id: employer.secondary_department_type_id,
            employer_type: employer.employer_type,
            isprotected: employer.isprotected,
            address: {
                addressline1: employer.address.addressline1,
                addressline2: employer.address.addressline2,
                postalcode: employer.address.postalcode,
                city: employer.address.city,
                country_code: employer.address.country_code,
            },
        };

        const {
            shouldCopyDescription,
            shouldCopyInvoiceAddress,
            shouldCopyContacts,
            shouldCopyLogo,
            shouldCopyCover,
            shouldCopyCustomShifts,
            shouldCopyProfiles,
            selectedCustomShifts,
            selectedProfiles,
        } = copyData;

        if (shouldCopyInvoiceAddress) {
            data.organization_number = employer.organization_number;
            data.invoice_address = (employer.invoice_address ? {
                addressline1: employer.invoice_address.addressline1,
                addressline2: employer.invoice_address.addressline2,
                postalcode: employer.invoice_address.postalcode,
                city: employer.invoice_address.city,
                name: employer.invoice_address.name,
                reference: employer.invoice_address.reference,
                country_code: employer.address.country_code,
            } : {});
        }

        if (shouldCopyDescription) {
            data.description = employer.description;
        }

        if (shouldCopyContacts) {
            data.shouldCopyContacts = true;
            data.contacts = employer.contacts.map(contact => omit(contact, ['id', 'avatar']));
        }

        if (shouldCopyLogo) {
            data.shouldCopyLogo = true;
            data.logo_url = employer.logo_url;
        }

        if (shouldCopyCover) {
            data.shouldCopyCover = true;
            data.cover_url = employer.cover_url;
        }

        if (shouldCopyCustomShifts) {
            data.shouldCopyCustomShifts = true;
            data.customShifts = selectedCustomShifts;
        }

        if (shouldCopyProfiles) {
            data.shouldCopyProfiles = true;
            data.profiles = selectedProfiles;
        }

        setCopiedEmployerData(data);
    }, [copyData, employer, setCopiedEmployerData]);

    useEffect(() => {
        handleOk();
    }, [copyData, handleOk]);

    useEffect(() => {
        updateCopyData('selectedCustomShifts', employer.settings.custom_shifts.map(s => s.id));
        updateCopyData('selectedProfiles', profiles.profiles.map(p => p.id));
    }, [profiles, employer]);

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
    }, {
        title: 'Start time',
        dataIndex: 'start_time',
    }, {
        title: 'End time',
        dataIndex: 'end_time',
    }, {
        title: 'Price',
        dataIndex: 'price',
    }, {
        title: 'Break',
        dataIndex: 'shift_break',
    }];

    return (
        <>
            <CheckRow
                checked={copyData.shouldCopyInfo}
                disabled
                title='Info'
                onChange={() => updateCopyData('shouldCopyInfo', !copyData.shouldCopyInfo)}
            />
            <CheckRow
                checked={copyData.shouldCopyDescription}
                title='Description'
                onChange={() => updateCopyData('shouldCopyDescription', !copyData.shouldCopyDescription)}
            />
            <CheckRow
                checked={copyData.shouldCopyInvoiceAddress}
                title='Invoice address'
                onChange={() => updateCopyData('shouldCopyInvoiceAddress', !copyData.shouldCopyInvoiceAddress)}
            />
            <CheckRow
                checked={copyData.shouldCopyContacts}
                title='Contacts'
                onChange={() => updateCopyData('shouldCopyContacts', !copyData.shouldCopyContacts)}
            />
            <CheckRow
                checked={copyData.shouldCopyLogo}
                title='Logo'
                onChange={() => updateCopyData('shouldCopyLogo', !copyData.shouldCopyLogo)}
            />
            <CheckRow
                checked={copyData.shouldCopyCover}
                title='Cover'
                onChange={() => updateCopyData('shouldCopyCover', !copyData.shouldCopyCover)}
            />
            <CheckRow
                checked={copyData.shouldCopyCustomShifts}
                title='Working hours'
                onChange={() => updateCopyData('selectedCustomShifts', !copyData.selectedCustomShifts)}
            />
            {copyData.shouldCopyCustomShifts && (
                <Table
                    columns={columns}
                    dataSource={employer.settings.custom_shifts.map(s => ({
                        ...s,
                        key: s.id,
                    }))}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: copyData.selectedCustomShifts,
                        onChange: selectedRowKeys => updateCopyData('selectedCustomShifts', selectedRowKeys),
                    }}
                    size='small'
                />
            )}
            <CheckRow
                checked={copyData.shouldCopyProfiles}
                title='Profiles'
                onChange={() => updateCopyData('shouldCopyProfiles', !copyData.shouldCopyProfiles)}
            />
            {copyData.shouldCopyProfiles && (
                <Table
                    columns={columns}
                    dataSource={profiles.profiles.map(p => ({
                        ...p,
                        key: p.id,
                    }))}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: copyData.selectedProfiles,
                        onChange: selectedRowKeys => updateCopyData('selectedProfiles', selectedRowKeys),
                    }}
                    size='small'
                />
            )}
            <ModalFooter
                cancelAction={() => setIsModalVisible(false)}
                cancelText='Cancel'
                okAction={() => onSubmit()}
                okText='Copy'
            />
        </>
    );
}

CopyEmployer.propTypes = {
    setCopiedEmployerData: PropTypes.func.isRequired,
    employer: PropTypes.shape({
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            settings: PropTypes.shape({
                custom_shifts: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                })).isRequired,
            }).isRequired,
            description: PropTypes.string,
            contacts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string.isRequired,
                avatar: PropTypes.string,
            })).isRequired,
            logo_url: PropTypes.string,
            cover_url: PropTypes.string,
            invoice_address: PropTypes.shape({
                addressline1: PropTypes.string.isRequired,
                addressline2: PropTypes.string,
                postalcode: PropTypes.string.isRequired,
                city: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                reference: PropTypes.string,
                country_code: PropTypes.string.isRequired,
            }),
            address: PropTypes.shape({
                addressline1: PropTypes.string.isRequired,
                addressline2: PropTypes.string,
                postalcode: PropTypes.string.isRequired,
                city: PropTypes.string.isRequired,
                reference: PropTypes.string,
                country_code: PropTypes.string.isRequired,
            }).isRequired,
            organization_number: PropTypes.string,
            vat_number: PropTypes.string,
            email: PropTypes.string,
            phone: PropTypes.string,
            account_email: PropTypes.string,
            account_phone: PropTypes.string,
            account_name: PropTypes.string,
            account_title: PropTypes.string,
            emr_system: PropTypes.number,
            county_id: PropTypes.number,
            automotive: PropTypes.bool,
            secondary_department_type_id: PropTypes.number,
            employer_type: PropTypes.number,
            isprotected: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    profiles: PropTypes.shape({
        profiles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
    }),
    onSubmit: PropTypes.func.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
};

export default CopyEmployer;
