import * as employerActions from 'actions/employer';
import {
    Button,
    message,
    Popconfirm,
    Select,
} from 'antd';
import InputModal from 'components/inputmodal';
import LastActive from 'components/lastactive';
import EditTrigger from 'components/shared/edittrigger';
import Formsy from 'formsy-react';
import { find } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as request from 'request';
import modalController from 'utils/modalcontroller';
import {
    countyName,
    employerStatusTitle,
    emrSystemTitle,
    nl2br,
    statusColor,
} from 'utils/nameformatters';
// import Select from 'components/shared/select';
import Comments from 'components/comments';
import Switch from 'components/shared/switch';
import config from 'config';
import './styles.scss';

const { Option } = Select;

class Overview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formIsValid: false,
            employerStatus: props.employer.employer.state,
            marginConfigValue: props.employer.employer.margin_config_id,
            allSegmentsAreSet: false,
        };

        this.onSubmitStatus = this.onSubmitStatus.bind(this);
        this.onSubmitMarginConfigValue = this.onSubmitMarginConfigValue.bind(this);
    }

    onSubmitStatus() {
        // const formValues = this.refs.activateEmployerForm.getModel();
        const newEmployerStatus = this.state.employerStatus;
        const { id } = this.props.match.params;
        const statusIdForActivated = 1;

        if (newEmployerStatus === statusIdForActivated) {
            this.props.updateEmployer(id, { state: newEmployerStatus })
                .then(request.activateEmployer(id));
        }
        else {
            this.props.updateEmployer(id, { state: newEmployerStatus });
        }

        this.setState({ formHasChanged: false });
    }

    onSubmitMarginConfigValue() {
        const { marginConfigValue } = this.state;
        const { id } = this.props.match.params;
        const { margin_config_id } = this.props.employer.employer;

        if (marginConfigValue !== margin_config_id) {
            this.props.updateEmployer(id, { margin_config_id: marginConfigValue })
                .then(message.success('Employers margin was successfully changed'));
        }
    }

    renderDetails() {
        const { employer } = this.props.employer;
        const { county_id: countyId } = employer;
        const { counties } = this.props.settings;

        const formatedCounties = counties.map(county => ({
            title: county.name,
            value: county.id,
        }));

        return (
            <div>
                <div
                    style={{
                        borderBottom: '1px solid #EEE',
                        padding: '40px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Journalsystem
                    </div>

                    <div>
                        {emrSystemTitle(employer.emr_system)}
                    </div>

                </div>

                <div
                    style={{
                        borderBottom: '1px solid #EEE',
                        padding: '40px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >

                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Address
                    </div>
                    <div>
                        {employer.address && (
                            <>
                                <div>
                                    {employer.address.addressline1}
                                </div>
                                <div>
                                    {employer.address.addressline2}
                                </div>
                                <div>
                                    {employer.address.postalcode}
                                    {' '}
                                    {employer.address.city}
                                </div>
                            </>
                        )}
                        <div
                            style={{ marginTop: 5 }}
                        >
                            <strong>
                                County (Län):
                            </strong>
                            <br />
                            {countyId ? countyName(countyId) : 'Not set'}
                        </div>

                        <EditTrigger
                            title='Edit address'
                            onClick={e => {
                                modalController.showModal(InputModal, {
                                    title: 'Edit address',
                                    width: 600,
                                    props: {
                                        inputs: [{
                                            type: 'input',
                                            name: 'addressline1',
                                            label: 'Address',
                                            value: employer.address ? employer.address.addressline1 : '',
                                        }, {
                                            type: 'input',
                                            name: 'addressline2',
                                            value: employer.address ? employer.address.addressline2 : '',
                                        }, {
                                            type: 'input',
                                            name: 'postalcode',
                                            label: 'Postal code',
                                            value: employer.address ? employer.address.postalcode : '',
                                        }, {
                                            type: 'input',
                                            name: 'city',
                                            label: 'City',
                                            value: employer.address ? employer.address.city : '',
                                        }, {
                                            type: 'select',
                                            name: 'county_id',
                                            label: 'County (Län)',
                                            options: formatedCounties,
                                            value: countyId,
                                        }],
                                        onSave: formData => this.props.updateEmployer(employer.id, {
                                            county_id: formData.county_id,
                                            address: formData,
                                        }),
                                    },
                                });
                            }}
                        />
                    </div>

                </div>

                <div
                    style={{
                        borderBottom: '1px solid #EEE',
                        padding: '40px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >

                    <div
                        style={{ fontWeight: 600 }}
                    >
                        Created
                    </div>
                    <div>
                        {moment.unix(employer.created_time).format('D MMM YYYY')}
                    </div>
                </div>

                <div
                    style={{
                        borderBottom: '1px solid #EEE',
                        padding: '40px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >

                    <div
                        style={{ fontWeight: 600 }}
                    >
                        Last log in
                    </div>
                    <LastActive
                        lastActive={employer.last_active}
                    />
                </div>

            </div>

        );
    }

    renderDescription() {
        const { employer } = this.props.employer;

        return (
            <div
                style={{
                    borderBottom: '1px solid #EEE',
                    paddingBottom: 40,
                    marginBottom: 40,
                    position: 'relative',
                }}
            >
                <div
                    style={{ fontWeight: 700 }}
                >
                    Profile text
                </div>
                <p
                    style={{ color: '#999' }}
                >
                    {employer.description ? nl2br(employer.description) : 'No description'}
                </p>
                <div>
                    <EditTrigger
                        title='Edit profile text'
                        onClick={() => {
                            modalController.showModal(InputModal, {
                                title: 'Edit profile text',
                                width: 600,
                                preventOutsideClickClose: true,
                                props: {
                                    inputs: [{
                                        type: 'textarea',
                                        name: 'description',
                                        label: 'Profile text',
                                        value: employer.description,
                                    }],
                                    onSave: formData => this.props.updateEmployer(employer.id, formData),
                                },
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderSegmentation() {
        const { employer } = this.props.employer;
        const { segmentation } = employer;

        let content = null;

        if (!segmentation) {
            content = (
                <div>
                    <div>
                        Not set
                    </div>
                </div>
            );
        }
        else {
            content = (
                <div>
                    <div
                        style={{
                            display: 'flex', justifyContent: 'space-between',
                        }}
                    >
                        <span>Tempo:</span>
                        {' '}
                        <strong>
                            {segmentation.tempo}
                            /6
                        </strong>
                    </div>
                    <div
                        style={{
                            display: 'flex', justifyContent: 'space-between',
                        }}
                    >
                        <span>High tech equipment:</span>
                        {' '}
                        <strong>
                            {segmentation.modern_equipment}
                            /6
                        </strong>
                    </div>
                    <div
                        style={{
                            display: 'flex', justifyContent: 'space-between',
                        }}
                    >
                        <span>Independence:</span>
                        {' '}
                        <strong>
                            {segmentation.independent_work}
                            /6
                        </strong>
                    </div>
                    <div
                        style={{
                            display: 'flex', justifyContent: 'space-between',
                        }}
                    >
                        <span>Variation in tasks:</span>
                        {' '}
                        <strong>
                            {segmentation.variety}
                            /6
                        </strong>
                    </div>
                </div>
            );
        }

        const options = [1, 2, 3, 4, 5, 6].map(nr => ({
            title: nr,
            value: nr,
        }));

        return (
            <div className='mpa-employer-data-row'>

                <div
                    style={{ fontWeight: 700 }}
                >
                    Segmentation
                </div>
                <div
                    style={{ minWidth: 200 }}
                >
                    {content}
                    <EditTrigger
                        title='Edit segmentation'
                        onClick={e => {
                            modalController.showModal(InputModal, {
                                title: 'Edit segmentation',
                                width: 600,
                                props: {
                                    isDisabled: this.state.allSegmentsAreSet,
                                    inputs: [{
                                        type: 'select',
                                        name: 'tempo',
                                        label: 'Tempo',
                                        options,
                                        required: true,
                                        value: segmentation ? segmentation.tempo : null,
                                    },
                                    {
                                        type: 'select',
                                        name: 'modern_equipment',
                                        label: 'High tech equipment',
                                        options,
                                        required: true,
                                        value: segmentation ? segmentation.modern_equipment : null,
                                    },
                                    {
                                        type: 'select',
                                        name: 'independent_work',
                                        label: 'Independance',
                                        options,
                                        required: true,
                                        value: segmentation ? segmentation.independent_work : null,
                                    },
                                    {
                                        type: 'select',
                                        name: 'variety',
                                        label: 'Variation in tasks',
                                        options,
                                        required: true,
                                        value: segmentation ? segmentation.variety : null,
                                    }],
                                    onChange: formData => {
                                        const {
                                            tempo, modern_equipment, variety, independent_work,
                                        } = formData;

                                        if (!tempo || !modern_equipment || !variety || !independent_work) {
                                            this.setState({ allSegmentsAreSet: false });
                                        }
                                        else {
                                            this.setState({ allSegmentsAreSet: true });
                                        }
                                    },
                                    onSave: formData => this.props.updateEmployer(employer.id, { segmentation: formData }),
                                },
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderAddress() {
        const { employer } = this.props.employer;
        const { county_id: countyId } = employer;
        const { counties } = this.props.settings;

        const formatedCounties = counties.map(county => ({
            title: county.name,
            value: county.id,
        }));

        return (
            <div className='mpa-employer-data-row'>

                <div
                    style={{ fontWeight: 700 }}
                >
                    Address
                </div>

                <div>
                    {employer.address && (
                        <>
                            <div>
                                {employer.address.addressline1}
                            </div>
                            <div>
                                {employer.address.addressline2}
                            </div>
                            <div>
                                {employer.address.postalcode}
                                {' '}
                                {employer.address.city}
                            </div>
                        </>
                    )}

                    <div
                        style={{ marginTop: 5 }}
                    >
                        <strong>
                            County (Län):
                        </strong>
                        <br />
                        {countyId ? countyName(countyId) : 'Not set'}
                    </div>

                    {employer.address && (
                        <div style={{ marginTop: 5 }}>
                            <strong>Country:</strong>
                            <br />
                            {employer.address.country ? employer.address.country : 'Not set'}
                        </div>
                    )}
                    <EditTrigger
                        title='Edit address'
                        onClick={e => {
                            modalController.showModal(InputModal, {
                                title: 'Edit address',
                                width: 600,
                                props: {
                                    inputs: [{
                                        type: 'input',
                                        name: 'addressline1',
                                        label: 'Address',
                                        value: employer.address ? employer.address.addressline1 : '',
                                    }, {
                                        type: 'input',
                                        name: 'addressline2',
                                        value: employer.address ? employer.address.addressline2 : '',
                                    }, {
                                        type: 'input',
                                        name: 'postalcode',
                                        label: 'Postal code',
                                        value: employer.address ? employer.address.postalcode : '',
                                    }, {
                                        type: 'input',
                                        name: 'city',
                                        label: 'City',
                                        value: employer.address ? employer.address.city : '',
                                    }, {
                                        type: 'select',
                                        name: 'country_code',
                                        label: 'Country',
                                        options: config.countries.map(c => ({
                                            id: c.id,
                                            value: c.value,
                                            title: c.name,
                                        })),
                                        value: employer.address ? employer.address.country_code : '',
                                    }, {
                                        type: 'select',
                                        name: 'county_id',
                                        label: 'County (Län)',
                                        options: formatedCounties,
                                        value: countyId,
                                    }],
                                    onSave: formData => this.props.updateEmployer(employer.id, {
                                        county_id: formData.county_id,
                                        address: formData,
                                    }),
                                },
                            });
                        }}
                    />
                </div>

            </div>
        );
    }

    renderEmrSystem() {
        const { employer } = this.props.employer;

        return (
            <div className='mpa-employer-data-row'>

                <div
                    style={{ fontWeight: 700 }}
                >
                    Journalsystem
                </div>

                <div>
                    {emrSystemTitle(employer.emr_system)}
                </div>

            </div>
        );
    }

    renderCreated() {
        const { employer } = this.props.employer;

        return (
            <div className='mpa-employer-data-row'>

                <div
                    style={{ fontWeight: 700 }}
                >
                    Created
                </div>

                <div>
                    {moment.unix(employer.created_time).format('D MMM YYYY')}
                </div>

            </div>
        );
    }

    renderLastActive() {
        const { employer } = this.props.employer;

        return (
            <div className='mpa-employer-data-row'>

                <div
                    style={{ fontWeight: 700 }}
                >
                    Last log in
                </div>

                <div>
                    <LastActive
                        lastActive={employer.last_active}
                    />
                </div>

            </div>
        );
    }

    renderEmployerActivation() {
        const {
            state: employerState, name,
        } = this.props.employer.employer;
        const currentStatusTitle = employerStatusTitle(employerState);
        const standardConfirmationText = `Please confirm that you would like to change ${name}'s status from ${currentStatusTitle} to ${employerStatusTitle(this.state.employerStatus)}.`;
        // const activationConfirmationText = `${standardConfirmationText} This will reset current password if customer has one and an activation mail will be sent to employers mail.`;

        return (
            <div
                style={{
                    padding: '60px 0',
                    borderBottom: '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}
            >

                <div
                    style={{ paddingRight: 50 }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Status
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        The account status determins who has access to the account
                    </p>
                    <strong
                        style={{
                            display: 'block',
                            fontSize: 13,
                            marginTop: -10,
                            marginBottom: 10,
                        }}
                    >
                        {' '}
                        Current status:
                        <span
                            style={{ color: statusColor(currentStatusTitle.toLowerCase()) }}
                        >
                            {' '}
                            {currentStatusTitle}

                        </span>
                    </strong>
                </div>

                <div>
                    <Formsy
                        ref='activateEmployerForm'
                        className='activate-employer-form'
                        onChange={() => {
                            const formValues = this.refs.activateEmployerForm.getModel();

                            this.setState({
                                employerStatus: formValues.employerStatus,
                                formHasChanged: true,
                            });
                        }}
                    >

                        <div
                            className='activate-employer-form__section'
                            style={{ width: 240 }}
                        >
                            <div>
                                <div
                                    style={{ marginRight: 5 }}
                                >
                                    <Select
                                        ref='employerStatus'
                                        size='large'
                                        style={{ marginBottom: 10 }}
                                        value={this.state.employerStatus}
                                        onChange={employerStatus => {
                                            this.setState({ employerStatus });
                                        }}
                                    >

                                        {config.employerStatus.map(status => (
                                            <Select.Option
                                                key={status.value}
                                                disabled={status.value === 0}
                                                value={status.value}
                                            >
                                                {status.title}
                                            </Select.Option>
                                        ))}
                                    </Select>

                                </div>
                                <div>
                                    <Popconfirm
                                        cancelText='Cancel'
                                        okText='Yes, change status'
                                        // onCancel={cancel}
                                        title={`Are you sure change the employers status to ${employerStatusTitle(this.state.employerStatus).toLowerCase()}?`}
                                        onConfirm={this.onSubmitStatus}
                                    >
                                        <Button
                                            disabled={this.state.employerStatus === employerState}
                                            size='large'
                                            style={{
                                                background: statusColor(employerStatusTitle(this.state.employerStatus).toLowerCase()),
                                                border: 'none',
                                                color: '#fff',
                                                // padding: '18px 20px',
                                                // margin: 0,
                                                opacity: this.state.employerStatus === employerState ? 0.3 : 1,
                                            }}
                                            type='primary'
                                            onClick={() => {

                                                // modalController.showModal(ConfirmModal, {
                                                //     title: 'Change status?',
                                                //     width: 600,
                                                //     props: {
                                                //         options: [
                                                //             {
                                                //                 type: 'button',
                                                //                 className: 'btn-danger',
                                                //                 text: `Change ${name}'s status to ${employerStatusTitle(this.state.employerStatus)}`,
                                                //                 action: this.onSubmitStatus,
                                                //             },
                                                //         ],
                                                //         text: this.state.employerStatus === 1 ? activationConfirmationText : standardConfirmationText,
                                                //     }
                                                // });

                                            }}
                                        >
                                            Change status to:
                                            {' '}
                                            <strong style={{ paddingLeft: 5 }}>{employerStatusTitle(this.state.employerStatus)}</strong>
                                        </Button>
                                    </Popconfirm>
                                </div>
                            </div>
                            <div
                                // className="col-sm-12"
                                style={{
                                    marginTop: 20,
                                    paddingLeft: 0,
                                }}
                            >
                                <p className='activate-employer-form__section__status-description'>
                                    <strong>
                                        Status description for "
                                        {employerStatusTitle(this.state.employerStatus)}
                                        "
                                    </strong>
                                    <br />
                                    <span
                                        style={{
                                            fontSize: 14, color: '#999',
                                        }}
                                    >
                                        {config.employerStatus[this.state.employerStatus].explanation}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Formsy>
                </div>

            </div>
        );
    }

    renderLogoutCustomer() {
        const {
            employer, requestLogOutEmployer,
        } = this.props.employer;

        return (
            <div
                style={{
                    padding: '60px 0',
                    // border: '1px solid #EEE',
                    // borderLeft: 'none',
                    // borderRight: 'none',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Log out
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        Log out
                        {' '}
                        {employer.name}
                        {' '}
                        from all active sessions
                    </p>
                </div>

                <div>
                    <button
                        className='btn btn-danger'
                        disabled={requestLogOutEmployer}
                        onClick={() => {
                            this.props.logOutEmployer(employer.id);
                        }}
                    >
                        Log out
                        {' '}
                        {employer.name}
                    </button>
                </div>
            </div>
        );
    }

    renderTestAccountSwitch() {
        const { employer } = this.props.employer;

        return (
            <div
                style={{
                    padding: '0 0 60px',
                    borderBottom: '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{ maxWidth: '50%' }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Account type
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        Test account is never visible in the app. All accounts created with a @medpeople-email will automatically be set to be a test account.
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Test

                    </div>

                    <Formsy
                        style={{
                            margin: 0,
                            flex: 1,
                            width: 75,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <Switch
                            name='test'
                            style={{ top: 0 }}
                            value={!employer.test_employer}
                            onChange={value => this.props.updateEmployer(employer.id, { test_employer: !employer.test_employer })}
                        />
                    </Formsy>

                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Live
                    </div>
                </div>
            </div>
        );
    }

    renderAutoAttestSwitch() {
        const { employer } = this.props.employer;

        return (
            <div
                style={{
                    padding: '60px 0 60px',
                    borderBottom: '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{ maxWidth: '50%' }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Auto attest
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        Choose manual or auto attest
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Manual attest

                    </div>

                    <Formsy
                        style={{
                            margin: 0,
                            flex: 1,
                            width: 75,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <Switch
                            name='test'
                            style={{ top: 0 }}
                            value={!!employer.auto_attest}
                            onChange={() => this.props.updateEmployer(employer.id,
                                { auto_attest: !employer.auto_attest })}
                        />
                    </Formsy>

                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Auto attest
                    </div>
                </div>
            </div>
        );
    }

    renderMonthlyInvoiceSwitch() {
        const { employer } = this.props.employer;

        return (
            <div
                style={{
                    padding: '60px 0 60px',
                    borderBottom: '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{ maxWidth: '50%' }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Monthly Invoice
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        Choose weekly or monthly invoice
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Weekly Invoice

                    </div>

                    <Formsy
                        style={{
                            margin: 0,
                            flex: 1,
                            width: 75,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <Switch
                            name='test'
                            style={{ top: 0 }}
                            value={!!employer.invoice_monthly}
                            onChange={() => this.props.updateEmployer(employer.id,
                                { invoice_monthly: !employer.invoice_monthly })}
                        />
                    </Formsy>

                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Monthly Invoice
                    </div>
                </div>
            </div>
        );
    }

    renderEmployerMargin() {
        const marginConfig = find(this.props.settings.marginOptions, { id: this.state.marginConfigValue });
        return (
            <div
                style={{
                    padding: '40px 0 60px',
                    borderBottom: '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{ maxWidth: '50%' }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Employer margin
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        Edit the employers margin
                    </p>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'flex-end',
                    }}
                >
                    <Select
                        ref='marginSelector'
                        dropdownMatchSelectWidth={false}
                        size='large'
                        value={this.state.marginConfigValue}
                        onChange={marginConfigValue => {
                            this.setState({ marginConfigValue });
                        }}
                    >
                        {this.props.settings.marginOptions.map(option => (
                            <Select.Option
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                                {' '}
                                (
                                {option.occupations.map((occupation, i) => {
                                    let title;

                                    switch (occupation.id) {
                                        case 1:
                                            title = 'USK';
                                            break;

                                        case 2:
                                            title = 'SSK';
                                            break;

                                        case 3:
                                            title = 'LÄK';
                                            break;

                                        default:
                                            break;
                                    }

                                    if (i + 1 === option.occupations.length) {
                                        return `${title} ${occupation.salary_margin}%`;
                                    }

                                    return `${title} ${occupation.salary_margin}%, `;
                                })}
                                )
                            </Select.Option>
                        ))}
                    </Select>
                    <Popconfirm
                        cancelText='No'
                        okText='Yes'
                        // onCancel={cancel}
                        title='Are you sure change the employers margin?'
                        onConfirm={this.onSubmitMarginConfigValue}
                    >
                        <Button
                            disabled={this.state.marginConfigValue === this.props.employer.employer.margin_config_id}
                            size='large'
                            style={{
                                marginTop: 10,
                                border: 'none',
                            }}
                            type='primary'
                            onClick={() => {}}
                        >
                            Change margin to:
                            {' '}
                            <strong style={{ paddingLeft: 5 }}>{marginConfig ? marginConfig.name : 'BROKEN'}</strong>
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    }

    renderComments() {
        return (
            <div
                style={{
                    marginTop: 40,
                    marginLeft: 20,
                    width: 400,
                    minWidth: 400,
                    background: '#FFF',
                    borderLeft: '1px solid #EEE',
                    overflow: 'auto',
                }}
            >
                <Comments
                    {...this.props}
                    entityId={this.props.match.params.id}
                    type='employer'
                />
            </div>
        );
    }

    renderSalaryShownSwitch() {
        const { employer } = this.props.employer;

        return (
            <div
                style={{
                    padding: '60px 0 60px',
                    borderBottom: '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{ maxWidth: '50%' }}
                >
                    <div
                        style={{ fontWeight: 700 }}
                    >
                        Show salary to consultants
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        Choose if you want to show salary to consultants or not
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Hide salary

                    </div>

                    <Formsy
                        style={{
                            margin: 0,
                            flex: 1,
                            width: 75,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <Switch
                            name='showSalary'
                            style={{ top: 0 }}
                            value={!employer.is_salary_hidden}
                            onChange={() => this.props.updateEmployer(employer.id,
                                { is_salary_hidden: !employer.is_salary_hidden })}
                        />
                    </Formsy>

                    <div
                        style={{
                            fontWeight: 700,
                            background: '#EEE',
                            borderRadius: 4,
                            padding: '5px 10px',
                            fontSize: 14,
                        }}
                    >
                        Show salary
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    margin: '40px auto',
                    position: 'relative',
                    display: 'flex',
                    maxWidth: 1200,
                }}
            >
                <div className='content-card-centered'>

                    {this.renderDescription()}
                    {this.renderSegmentation()}
                    {this.renderAddress()}
                    {this.renderEmrSystem()}
                    {this.renderCreated()}
                    {this.renderLastActive()}
                    {this.renderTestAccountSwitch()}
                    {this.renderEmployerMargin()}
                    {this.renderEmployerActivation()}
                    {this.renderAutoAttestSwitch()}
                    {this.renderMonthlyInvoiceSwitch()}
                    {this.renderSalaryShownSwitch()}
                    {this.renderLogoutCustomer()}

                </div>
                {this.renderComments()}
            </div>
        );
    }
}

export default withRouter(connect(
    state => state,
    dispatch => bindActionCreators({ ...employerActions }, dispatch),
)(Overview));
