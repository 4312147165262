import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Timeline from '../timeline';

import './styles.scss';

const MonthOverview = ({ activities }) => (
    <div className='month-overview'>
        <div className='month-overview__header'>
            <h3>Events</h3>

            <Link to='/timeline'>View more</Link>
        </div>

        <div className='month-overview__content'>
            <Timeline events={activities} />
        </div>
    </div>
);

MonthOverview.propTypes = { activities: PropTypes.shape({}).isRequired };

export default MonthOverview;
