import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Space,
} from 'antd';

const ModalFooter = ({
    okText = 'Ok',
    okAction = () => null,
    isOkSubmit = false,
    cancelText = 'Cancel',
    cancelAction = () => null,
}) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        }}
    >
        <Space>
            <Button onClick={cancelAction}>{cancelText}</Button>
            <Button htmlType={isOkSubmit ? 'submit' : null} type='primary' onClick={okAction}>{okText}</Button>
        </Space>
    </div>
);

ModalFooter.propTypes = {
    okText: PropTypes.string,
    okAction: PropTypes.func,
    isOkSubmit: PropTypes.bool,
    cancelText: PropTypes.string,
    cancelAction: PropTypes.func,
};

export default ModalFooter;
