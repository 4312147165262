import * as request from 'request';
import { message } from 'antd';

export function receiveInvoiceList(resp) {
    return {
        type: 'RECEIVE_INVOICE_LIST',
        invoiceList: resp.employers,
    };
}

export function requestFetchInvoiceList() {
    return { type: 'REQUEST_FETCH_INVOICE_LIST' };
}

export function fetchInvoiceList() {
    return dispatch => {
        dispatch(requestFetchInvoiceList());

        return request.fetchInvoiceList()
            .then(resp => dispatch(receiveInvoiceList(resp)));
    };
}

export function requestExportInvoiceList() {
    return { type: 'REQUEST_EXPORT_INVOICE_LIST' };
}

export function receiveExportInvoiceList(employerIds) {
    return {

        type: 'RECEIVE_EXPORT_INVOICE_LIST',
        employerIds,

    };
}

export function exportInvoiceList(employerIds) {
    return dispatch => {
        dispatch(requestExportInvoiceList);

        return request.exportInvoiceList(employerIds).then(() => {
            dispatch(receiveExportInvoiceList(employerIds));
            message.success(`${employerIds.length} fakturaexporter genomfördes`);
        });
    };
}
