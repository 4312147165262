import React, { useState } from 'react';
import moment from 'moment';
import {
    Avatar,
    Button,
    Card,
    Col,
    Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import config from '../../../config';
import RejectJobApplicationModal from './rejectjobapplicationmodal';
import AcceptJobApplicationModal from './acceptjobapplicationmodal';

const JobAdApplication = ({
    application,
    onRejectApplication,
    onAcceptApplication,
}) => {
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);

    const getBadgeText = () => {
        switch (application.application_status) {
            case config.jobPostingApplicationStatus.APPLIED:
                return 'New';
            case config.jobPostingApplicationStatus.ACCEPTED:
                return 'Accepted';
            case config.jobPostingApplicationStatus.REJECTED:
                return 'Rejected';
            case config.jobPostingApplicationStatus.USER_REMOVED:
                return 'User removed';
            case config.jobPostingApplicationStatus.ADMIN_REMOVED:
                return 'Admin removed';
            default:
                return 'Unknown';
        }
    };

    return (
        <Col span={8}>
            <Card
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Tag
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}
                >
                    {moment.unix(application.created_time).fromNow()}
                </Tag>
                <Tag
                    style={{
                        position: 'absolute',
                        left: 10,
                        top: 10,
                    }}
                >
                    {getBadgeText()}
                </Tag>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 20,
                        marginBottom: 10,
                    }}
                >
                    <Avatar
                        size={60}
                        src={application.user.avatar}
                    />
                </div>
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Link to={`/consultants/${application.user.id}`}>
                        {application.user.firstname}
                        {' '}
                        {application.user.lastname}
                    </Link>
                </div>
                {application.application_status === config.jobPostingApplicationStatus.APPLIED ? (
                    <div
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Button
                            shape='round'
                            style={{
                                marginRight: 5,
                            }}
                            type='primary'
                            onClick={() => setShowAcceptModal(true)}
                        >
                            Accept
                        </Button>
                        <Button
                            ghost
                            shape='round'
                            type='danger'
                            onClick={() => setShowRejectModal(true)}
                        >
                            Reject
                        </Button>
                    </div>
                ) : null}
            </Card>
            <RejectJobApplicationModal
                applicant={application.user}
                setVisibility={val => setShowRejectModal(val)}
                visible={showRejectModal}
                onConfirm={reasonId => {
                    onRejectApplication(application.id, reasonId)
                        .then(() => setShowRejectModal(false));
                }}
            />
            <AcceptJobApplicationModal
                applicant={application.user}
                setVisibility={val => setShowAcceptModal(val)}
                visible={showAcceptModal}
                onConfirm={() => {
                    onAcceptApplication(application.id)
                        .then(() => setShowAcceptModal(false));
                }}
            />
        </Col>
    );
};

export default JobAdApplication;
