const GOALS = {
    '2017-05': 33,
    '2017-06': 44,
    '2017-07': 55,
    '2017-08': 74,
    '2017-09': 92,
    '2017-10': 111,
    '2017-11': 146,
    '2017-12': 184,
    '2018-01': 60,
    '2018-02': 66,
    '2018-03': 77,
    '2018-04': 111,
    '2018-05': 133,
    '2018-06': 200,
    '2018-07': 311,
    '2018-08': 288,
    '2018-09': 266,
    '2018-10': 244,
    '2018-11': 222,
    '2018-12': 222,
    '2019-01': 222,
    '2019-02': 222,
    '2019-03': 333,
    '2019-04': 333,
    '2019-05': 488,
    '2019-06': 888,
    '2019-07': 1111,
    '2019-08': 1000,
    '2019-09': 666,
    '2019-10': 555,
    '2019-11': 555,
    '2019-12': 555,
    '2020-01': 650,
    '2020-02': 650,
    '2020-03': 500,
    '2020-04': 600,
    '2020-05': 950,
    '2020-06': 1150,
    '2020-07': 1800,
    '2020-08': 1500,
    '2020-09': 2000,
    '2020-10': 2300,
    '2020-11': 2350,
    '2020-12': 2500,
};

export default GOALS;
