import React, {
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    message,
    Modal,
} from 'antd';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { TrophyOutlined } from '@ant-design/icons';
import LoadingScreen from 'components/shared/loadingscreen';
import CreateEmployer from './CreateEmployer';
import HandleProfile from './HandleProfile';
import * as employerActions from '../../../../actions/employer';
import * as createEmployerActions from '../../../../actions/createemployer';
import { createJobProfile } from '../../../../actions/job';
import { getUser } from '../../../../actions/session';

const HandleOrderModal = ({
    visible,
    onCancel,
    order,
    modalStep,
    setModalStep,
    selectedEmployer,
    handleEmployerCreationSuccess,
    handleProfileCreationSuccess,
}) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.session);
    const settings = useSelector(state => state.settings);

    const [title, setTitle] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingText, setIsFetchingText] = useState('');
    const [newEmployerId, setNewEmployerId] = useState(null);

    useEffect(() => {
        if (selectedEmployer?.value) {
            setNewEmployerId(selectedEmployer.value);
        }
    }, [selectedEmployer]);

    const [newProfile, setNewProfile] = useState({
        id: -1,
        additional_info: '',
        description: '',
        driver_license: false,
        name: '',
        occupation_id: 1,
        specialization_ids: [],
        price: 0,
        roles: order?.roles || [],
    });

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    // Set initial profile data from order
    useEffect(() => {
        if (order) {
            setNewProfile({
                id: -1,
                additional_info: '',
                description: order.description || '',
                driver_license: order.driver_license || false,
                name: order?.roles[0]?.name || '',
                occupation_id: order.occupation_id || 1,
                specialization_ids: order.specialization_ids || [],
                is_long_term: true,
                employment_scope: order.employment_scope,
                end_time: order.end_time,
                hourly_rate: order.price,
                start_time: order.start_time,
                roles: order.roles || [],
            });
        }
    }, [order]);

    // Generate random email for new employer
    const generateRandomMedpeopleEmail = (length, email) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        const splitedEmail = email.split('@');
        return {
            email: `${splitedEmail[0]}+${result}@${splitedEmail[1]}`,
            randomString: result,
        };
    };

    const randomPassword = length => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const onSubmitNewEmployer = async data => {
        setIsFetching(true);
        try {
            console.log('Creating employer with data:', data);

            const newEmployer = await dispatch(createEmployerActions.requestCustomCreateEmployer({
                ...data,
                create_shifts: true,
                create_profiles: false,
                automotive: data?.automotive === 'automotive',
            }));

            console.log('New employer created:', newEmployer);

            if (!newEmployer) {
                throw new Error('No employer data received from creation');
            }

            await dispatch(employerActions.updateEmployerStatus(newEmployer.id, 1));
            console.log('Employer status updated for ID:', newEmployer.id);

            const employerData = {
                id: newEmployer.id,
                value: newEmployer.id,
                label: newEmployer.name || data.name,
            };
            console.log('Setting selected employer:', employerData);

            handleEmployerCreationSuccess(employerData);
            onCancel();
        }
        catch (error) {
            console.error('Error creating employer:', error);
            message.error(`Failed to create employer: ${error.message}`);
        }
        finally {
            setIsFetching(false);
        }
    };

    const onSubmitProfile = async () => {
        setIsFetching(true);
        try {
            const formData = { ...newProfile };
            delete formData.id;
            delete formData.is_long_term;

            if (!newEmployerId) {
                throw new Error('No employer ID found');
            }

            const newP = await dispatch(createJobProfile(newEmployerId, formData));
            if (newP?.job?.id) {
                setModalStep('done');
                setTitle('Success!');
            }
            handleProfileCreationSuccess({
                id: newP?.job?.id, name: newP?.job?.name,
            });
        }
        catch (error) {
            console.error('Error creating profile:', error);
        }
        finally {
            setIsFetching(false);
        }
    };

    const renderContent = () => {
        if (isFetching && isFetchingText) {
            return (
                <LoadingScreen
                    style={{ height: 500 }}
                    text={isFetchingText}
                />
            );
        }

        switch (modalStep) {
            case 'createEmployer':
                return (
                    <CreateEmployer
                        requestOngoing={isFetching}
                        setIsModalVisible={onCancel}
                        setRequestOngoing={setIsFetching}
                        validateUsername={username => dispatch(createEmployerActions.validateUsername(username))}
                        onSubmit={onSubmitNewEmployer}
                    />
                );
            case 'createProfile':
                return (
                    <HandleProfile
                        employer={{ id: newEmployerId }}
                        order={order}
                        profile={newProfile}
                        role={order?.roles || []}
                        setIsModalVisible={onCancel}
                        setProfile={setNewProfile}
                        settings={settings}
                        onSubmit={onSubmitProfile}
                    />
                );
            case 'done':
                return (
                    <div
                        style={{
                            width: '100%',
                            padding: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            color: '#52c41a',
                        }}
                    >
                        <TrophyOutlined
                            style={{
                                fontSize: 48, marginBottom: 16,
                            }}
                        />
                        <h4>Successfully Created!</h4>
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (visible) {
            if (modalStep === 'createEmployer') {
                const password = randomPassword(8);
                const email = currentUser.email ? generateRandomMedpeopleEmail(5, currentUser.email) : '';

                dispatch(createEmployerActions.setCreateEmployerFormData({
                    email: email.email,
                    name: order?.customer_name || '',
                    username: currentUser.username + email.randomString,
                    password,
                    password_retype: password,
                    contact_email: email.email,
                    contact_name: currentUser.username,
                    account_email: email.email,
                    account_name: currentUser.username,
                    account_title: 'Autogenerated account',
                    account_phone: '',
                    address: {
                        addressline2: order?.location?.administrative_area || '',
                        postalcode: order?.location?.post_code || '',
                        city: order?.location?.city || '',
                    },
                }));
                setTitle('Create New Employer');
            }
        }
    }, [modalStep, visible, currentUser, order, dispatch]);

    return (
        <Modal
            destroyOnClose
            footer={null}
            title={title}
            visible={visible}
            width={800}
            onCancel={onCancel}
        >
            {renderContent()}
        </Modal>
    );
};

HandleOrderModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    order: PropTypes.shape({
        id: PropTypes.number,
        customer_name: PropTypes.string,
        description: PropTypes.string,
        driver_license: PropTypes.bool,
        occupation_id: PropTypes.number,
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        employment_scope: PropTypes.number,
        start_time: PropTypes.number,
        end_time: PropTypes.number,
        price: PropTypes.number,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            periods: PropTypes.arrayOf(PropTypes.shape({
                start_time: PropTypes.number,
                end_time: PropTypes.number,
            })),
            shifts: PropTypes.arrayOf(PropTypes.shape({
                end_time: PropTypes.string,
                head_count: PropTypes.number,
                id: PropTypes.number,
                is_canceled: PropTypes.bool,
                is_expired: PropTypes.bool,
                start_time: PropTypes.string,
            })),
            skills: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                skill_name: PropTypes.string,
                skill_type: PropTypes.string,
            })),
        })),
    }),
    modalStep: PropTypes.string.isRequired,
    setModalStep: PropTypes.func.isRequired,
    selectedEmployer: PropTypes.shape({
        value: PropTypes.number,
    }),
    handleEmployerCreationSuccess: PropTypes.func.isRequired,
};

export default HandleOrderModal;
