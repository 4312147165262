const initialState = {
    tasks: [],
};

export const tasks = (state = initialState, action) => {
    switch (action.type) {
        case 'RECEIVE_FETCH_TASKS':
            return {
                ...state,
                tasks: action.tasks,
                isFetching: false,
                hasFetched: true,
            };

        case 'REQUEST_TASKS':
            return {
                ...state,
                isFetching: true,
            };

        case 'RECEIVE_CREATE_TASKS':
            return { tasks: [...state.tasks, action.task] };

        case 'RECEIVE_UPDATE_TASK':
            return { 
                isFetching: false,
                hasFetched: true, 
                tasks: state.tasks.map(task => {
                    if (task.id === action.task.id) {
                        return action.task;
                    }
                return task;
            }) };

        default:
            return state;
    }
};
