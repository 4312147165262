const getEventsByTypeGroup = (type, data, types) => {
  let events = []
  for(const [key, value] of Object.entries(types)) {
    const items = Object.values(value)[0];
    if (items && key !== 'selected') {
      const ev = items.reduce((acc, current) => {
        const eventName = current.split('::')[0];
        const eventList = data[eventName];
        if (eventList) {
          acc = [...acc, ...eventList.filter(({ group }) => Array.isArray(group) ? group.includes(type) : group === type)]
        }
        return acc;
      }, [])
      events = [...events, ...ev]
    }
  }
  return events;
}

export default getEventsByTypeGroup;
