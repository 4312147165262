import React, { useState } from 'react';
import {
    Pagination,
    Button,
    DatePicker,
    Checkbox,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const LeadsEvents = ({
    selectedLeadTasks, data, onChangePage, onConvertToEmployer, onAddTask, onSelectedLeadTasks,
}) => {
    const [isTaskOpen, setIsTaskOpen] = useState({});
    const [tempInput, setTempInput] = useState();
    const [dueDate, setDueDate] = useState();
    const [plannedDate, setPlannedDate] = useState();
    const [hasError, setHasError] = useState(false);

    const onToggleTask = id => {
        setIsTaskOpen({ [id]: !isTaskOpen[id] });
        setTempInput('');
    };

    const onAddTaskHandler = id => {
        if (tempInput && dueDate && plannedDate) {
            const item = {
                title: tempInput,
                admin_id_assigned: null,
                due_date_time: dueDate,
                planned_date_time: plannedDate,
            };
            onAddTask(item, { employer_lead_id: id });
            setTempInput('');
            setHasError(false);
        }
        else {
            setHasError(true);
        }
    };
    if (!data || !Array.isArray(data.collection) || data.collection.length === 0) {
        return <div className='flex my-4 text-lg'>No leads found.</div>;
    }
    const renderLeads = (data, onConvertToEmployer) => (data.map(leads => {
        const {
            id, lead_company, lead_contact_name, created_time, phone, email, additional, converted, employer_id,
        } = leads;
        const taskChecked = selectedLeadTasks.some(({ employer_lead_id }) => employer_lead_id === id);
        return (
            <div key={id} className='flex p-2 bg-gray-50 mb-2 flex-col'>
                <div className='flex'>
                    <div className='flex flex-col flex-1'>
                        <span className='text-xs'>{moment.unix(created_time).format('YYYY-MM-DD')}</span>
                        <span className='font-semibold'>{lead_company}</span>
                        <span className='text-xs'>{lead_contact_name}</span>
                        <span className='text-xs'>
                            Phone:
                            {phone}
                        </span>
                        {email && (
                            <span className='text-xs'>
                                Email:
                                {email}
                            </span>
                        )}
                        {additional && <div className='text-xs'>{additional}</div>}
                    </div>
                    <div>

                        {converted === 0 && <Button onClick={() => onConvertToEmployer(id)}>Convert</Button>}
                        {converted === 1 && <a href={`/employers/${employer_id}`}>Add details</a>}
                    </div>
                </div>
                <div className='mt-2'>
                    <Button onClick={() => onToggleTask(id)}>
                        <i aria-hidden='true' className='fa fa-list-ol' />
                    </Button>
                    <Checkbox
                        checked={taskChecked}
                        style={{ marginLeft: '4px' }}
                        onChange={e => { onSelectedLeadTasks({ employer_lead_id: id }); }}
                    />
                </div>
                { isTaskOpen[id] && (
                    <div className='flex flex-col bg-gray-100 border-b border-white'>
                        <div className='py-2 font-semibold border-white border-t'>
                            <div className='px-6'>Create a Task</div>
                        </div>
                        <div className='flex flex-col items-end px-6 pb-2'>
                            <TextArea allowClear value={tempInput} onChange={e => setTempInput(e.target.value)} />
                            <div className='flex my-2'>
                                <DatePicker placeholder='Select planned date' onChange={d => setPlannedDate(moment(d).unix())} />
                                <DatePicker placeholder='Select due date' onChange={d => setDueDate(moment(d).unix())} />
                                <Button className='ml-2' onClick={() => onAddTaskHandler(id)}>
                                    <i aria-hidden='true' className='fa fa-plus mr-1' />
                                    Add task
                                </Button>
                            </div>
                        </div>
                        { hasError && <div className='flex flex-col items-end px-6 pb-4 text-red-600'>Enter all fields</div> }
                    </div>
                )}
            </div>
        );
    }));
    return (
        <div>
            {renderLeads(data.collection, onConvertToEmployer)}
            <div className='flex items-center'>
                <Pagination current={data.page} total={data.totalPages} onChange={onChangePage} />
                <div className='ml-2 text-xs'>
                    Number of leads (
                    {data.count}
                    )
                </div>
            </div>
        </div>
    );
};

export default LeadsEvents;
