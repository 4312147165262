import {
    filter,
    map,
} from 'lodash';
import config from '../config';

const initialState = {
    isFetching: true,
    employer: {},
    hasFetchedInvoiceEmployer: false,
    invoiceEmployer: {},
    allConsultants: {},
    profiles: { profiles: [] },
    job_postings: [],
    profilesFetched: false,
    job_postingsFetched: false,
    requestLogOutEmployer: false,
    allConsultantsFetched: false,
    usersFetched: false,
    users: [],
};

export function employer(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_EMPLOYER_PROFILES':
            return {
                ...state,
                profiles: action.profiles,
                profilesFetched: true,
            };

        case 'RECEIVE_EMPLOYER_USERS':
            return {
                ...state,
                users: action.users,
                usersFetched: true,
            };

        case 'RECEIVE_EMPLOYER_JOBADS':
            return {
                ...state,
                job_postings: action.job_postings,
                job_postingsFetched: true,
            };

        case 'REQUEST_REPUBLISH_JOB_POSTING':
            return {
                ...state,
                isFetching: true,
            };

        case 'UPDATE_REPUBLISH_JOB_POSTING_SUCCESS':
            const updatedList = state.job_postings.filter(jobPost => jobPost.id !== action.updatedJobPost.id);
            return {
                ...state,
                job_postings: [
                    action.updatedJobPost,
                    ...updatedList,
                ],
            };

        case 'RECEIVE_EMPLOYERS_ALL_CONSULTANTS':
            return {
                ...state,
                allConsultants: action.allConsultants,
                allConsultantsFetched: true,
            };

        case 'REQUEST_FETCH_EMPLOYER':
            return {
                ...state, isFetching: true,
            };

        case 'RECEIVE_EMPLOYER':
            return {
                ...state,
                employer: action.employer,
                isFetching: false,
            };

        case 'RECEIVE_INVOICE_EMPLOYER':
            return {
                ...state,
                invoiceEmployer: action.employer,
                hasFetchedInvoiceEmployer: true,
            };

        case 'CLEAR_INVOICE_EMPLOYER':
            return {
                ...state,
                invoiceEmployer: null,
                hasFetchedInvoiceEmployer: false,
            };

        case 'RECEIVE_UPDATE_EMPLOYER':
            return {
                ...state,
                employer: {
                    ...state.employer,
                    ...action.data,
                },
                hasFetchedInvoiceEmployer: false,
            };

        case 'REQUEST_UPDATE_EMPLOYER_CONTACT':
            return { ...state };

        case 'RECEIVE_UPDATE_EMPLOYER_CONTACT':
            return {
                ...state,
                employer: {
                    ...state.employer,
                    contacts: map(state.employer.contacts, contact => {
                        if (contact.id === action.updateContactId) {
                            return {
                                ...contact,
                                ...action.updateData,
                            };
                        }

                        return contact;
                    }),
                },
            };

        case 'REQUEST_CREATE_EMPLOYER_CONTACT':
            return { ...state };

        case 'RECEIVE_CREATE_EMPLOYER_CONTACT':
            return {
                ...state,
                employer: {
                    ...state.employer, contacts: [...(state.employer?.contacts || []), action.newContact.contact],
                },
            };

        case 'RECEIVE_DELETE_EMPLOYER_CONTACT':
            return {
                ...state,
                employer: {
                    ...state.employer, contacts: filter(state.employer.contacts, contact => contact.id !== action.contactId),
                },
            };

        case 'REQUEST_UPDATE_EMPLOYER_CREDENTIAL':
            return { ...state };

        case 'RECEIVE_UPDATE_EMPLOYER_CREDENTIAL':
            return {
                ...state,
                users: state.users.map(user => {
                    if (user.id === action.updateUserId) {
                        return {
                            ...user,
                            ...action.updateData,

                        };
                    }

                    return user;
                }),
            };

        case 'REQUEST_EMPLOYER_ADD_NEW_CREDENTIAL':
            return { ...state };

        case 'RECEIVE_EMPLOYER_ADD_NEW_CREDENTIAL': {
            return {
                ...state,
                users: [...state.users, action.newCredential],
            };
        }

        case 'RECEIVE_DELETE_EMPLOYER_CREDENTIAL': {
            return {
                ...state,
                users: filter(state.users, user => user.id !== action.id),
            };
        }

        case 'RECEIVE_UPLOAD_AVATAR':
            return {
                ...state,
                employer: {
                    ...state.employer, logo_url: action.logoUrl,
                },
            };

        case 'RECEIVE_UPDATE_CUSTOM_SHIFT':
            return {
                ...state,
                employer: {
                    ...state.employer,
                    settings: {
                        ...state.employer.settings,
                        custom_shifts: state.employer.settings.custom_shifts.map(shift => {
                            if (shift.id === action.id) {
                                return {
                                    ...shift,
                                    ...action.data,
                                };
                            }

                            return shift;
                        }),
                    },
                },
            };

        case 'RECEIVE_CREATE_CUSTOM_SHIFT':
            return {
                ...state,
                employer: {
                    ...state.employer,
                    settings: {
                        ...state.employer.settings,
                        custom_shifts: state.employer.settings.custom_shifts.concat([action.data]),
                    },
                },
            };

        case 'RECEIVE_UPDATE_JOB_PROFILE':

            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    profiles: state.profiles.profiles.map(profile => {
                        if (profile.id === action.profileId) {
                            return {
                                ...profile,
                                ...action.data,

                            };
                        }

                        return profile;
                    }),
                },
            };

        case 'REQUEST_LOGOUT_EMPLOYER':
            return {
                ...state, requestLogOutEmployer: true,
            };

        case 'RECEIVE_LOGOUT_EMPLOYER':
            return {
                ...state, requestLogOutEmployer: false,
            };

        case 'RECEIVE_UPLOAD_COVER':
            return {
                ...state,
                employer: {
                    ...state.employer, cover_url: action.coverUrl,
                },
            };

        case 'CREATE_JOB_SUCCESS':
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    profiles: [
                        ...state.profiles.profiles,
                        action.job,
                    ],
                },
            };

        case 'DELETE_JOB_SUCCESS':
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    profiles: state.profiles.profiles.filter(p => p.id !== action.jobId),
                },
            };

        case 'UPDATE_JOB_SUCCESS':
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    profiles: [
                        ...state.profiles.profiles.map(profile => {
                            if (profile.id === action.job.id) {
                                return action.job;
                            }
                            return profile;
                        }),
                    ],
                },
            };

            /* case 'CREATE_RECRUITMENT_SUCCESS':
            return {
                ...state,
                job_postings: [
                    ...state.job_postings,
                    action.data,
                ],
            }; */

        case 'UPDATE_RECRUITMENT_SUCCESS':
            return {
                ...state,
                isUpdating: false,
                job_postings: state.job_postings.map(j => {
                    if (j.id === action.id) {
                        return {
                            ...j,
                            ...action.data,
                        };
                    }
                    return j;
                }),
            };

        case 'REJECT_RECRUITMENT_APPLICATION_SUCCESS':
            return {
                ...state,
                job_postings: state.job_postings.map(jobPosting => ({
                    ...jobPosting,
                    applications: jobPosting.applications.map(application => {
                        if (application.id === action.id) {
                            return {
                                ...application,
                                application_status: config.jobPostingApplicationStatus.REJECTED,
                            };
                        }
                        return application;
                    }),
                })),
            };

        case 'ACCEPT_RECRUITMENT_APPLICATION_SUCCESS':
            return {
                ...state,
                job_postings: state.job_postings.map(jobPosting => ({
                    ...jobPosting,
                    applications: jobPosting.applications.map(application => {
                        if (application.id === action.id) {
                            return {
                                ...application,
                                application_status: config.jobPostingApplicationStatus.ACCEPTED,
                            };
                        }
                        return application;
                    }),
                })),
            };

        case 'CREATE_EMPLOYER_SUCCESS':
            return {
                ...state,
                employer: action.payload?.employer || action.payload,
                isFetching: false,
            };

        case 'REQUEST_CREATE_EMPLOYER':
            return {
                ...state,
                isFetching: true,
            };

        case 'CREATE_EMPLOYER_ERROR':
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };

        default:
            return state;
    }
}
