import React from 'react';
import moment from 'moment';
// import classNames from 'utils/classnames';
import {
    consultantStatusTitle,
    consultantStatusColor,
} from 'utils/nameformatters';
import './styles.scss';

export default class StateBadge extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            state, created, styles,
        } = this.props;
        const title = consultantStatusTitle(state);
        const pendingPeriod = `${moment().diff(moment.unix(created), 'days')}d`;

        return (
            <div
                className='mpa-state-badge'
                style={({
                    background: consultantStatusColor(state),
                    ...styles,
                })}
            >
                {title}
                {' '}
                {title.toLowerCase() === 'pending' && pendingPeriod}
            </div>
        );
    }
}
