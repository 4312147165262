import React from 'react';
import {
    sortBy,
    groupBy,
    map,
    without,
    filter,
} from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as consultantActions from 'actions/consultant';
import {
    Table,
    Collapse,
    Spin,
    Empty,
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';

class ConsultantContracts extends React.Component {
    componentDidMount() {
        this.props.fetchConsultantContracts(this.props.consultant.consultant.id);
    }

    childTable(data) {
        // Sort payments by date
        const dataSource = sortBy(data, data => data.id);

        const columns = [
            {
                title: 'Shift ID',
                dataIndex: '',
                key: 'shift_id',
                render: (value, row) => (
                    <Link to={`/employers/${value.employer.id}/shifts/${value.shift_id}`}>{value.shift_id}</Link>
                ),
            },
            {
                title: 'Working occasion',
                dataIndex: '',
                key: 'start_time',
                render: (value, row) => `${moment.unix(value.start_time).format('DD MMM')} ${moment.unix(value.start_time).format('HH:mm')} - ${moment.unix(value.end_time).format('HH:mm')}`,
            },
            {
                title: 'Salary',
                dataIndex: '',
                key: 'salary',
                render: value => (
                    <div>
                        {value.salary}
                        {' '}
                        SEK/h
                        <br />
                        Total:
                        {' '}
                        {value.salary * (value.duration - value.shift_break)}
                        {' '}
                        SEK
                    </div>
                ),
            },
            {
                title: 'Invoice',
                dataIndex: '',
                key: 'consultant_fee',
                render: value => (
                    <div>
                        {value.consultant_fee}
                        {' '}
                        SEK/h
                        <br />
                        Total:
                        {' '}
                        {value.consultant_fee * (value.duration - value.shift_break)}
                        {' '}
                        SEK
                    </div>
                ),
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                size='middle'
            />
        );
    }

    render2ndLevel(data) {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Created',
                dataIndex: 'created_time',
                key: 'created_time',
                render: value => moment.unix(value).format('YYYY-MM-DD'),
            },
            {
                title: 'Contract',
                dataIndex: 'contract_url',
                key: 'contract_url',
                render: value => (
                    <a href={value} target='_blank'>See contract</a>
                ),
            },
            {
                title: 'Status',
                dataIndex: '',
                key: 'status',
                render: value => {
                    if (value.sign_time) {
                        return 'Signed';
                    }

                    return 'Unsigned';
                },
            },
            {
                title: 'Signed',
                dataIndex: 'sign_time',
                key: 'sign_time',
                render: value => {
                    if (value) {
                        return (
                            <div>
                                kl
                                {' '}
                                {moment.unix(value).format('HH:mm')}
                                <br />
                                {moment.unix(value).format('YYYY-MM-DD')}
                            </div>
                        );
                    }

                    return '-';
                },
            },
        ];

        if (data.children && data.children.length) {
            const test = map(data.scheduled, item => ({
                ...item,
                employer: data.employer,
            }));

            return (
                <div>
                    {this.childTable(test)}
                    <h2
                        style={{
                            marginTop: 40,
                            textAlign: 'center',
                        }}
                    >
                        Earlier versions of contract (
                        {data.children.length}
                        )

                    </h2>
                    <Table
                        columns={columns}
                        dataSource={data.children}
                        expandedRowRender={record => {
                            const content = map(record.scheduled, item => ({
                                ...item,
                                employer: record.employer,
                            }));

                            return this.childTable(content);
                        }}
                        pagination={false}
                        rowStyle={{}}
                        style={{ marginBottom: 40 }}
                    />
                </div>
            );
        }

        const content = map(data.scheduled, item => ({
            ...item,
            employer: data.employer,
        }));

        return this.childTable(content);
    }

    renderTable(data, date) {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Created',
                dataIndex: 'created_time',
                key: 'created_time',
                render: value => moment.unix(value).format('YYYY-MM-DD'),
            },
            {
                title: 'Contract',
                dataIndex: 'contract_url',
                key: 'contract_url',
                render: value => (
                    <a href={value} target='_blank'>See contract</a>
                ),
            },
            {
                title: 'Status',
                dataIndex: '',
                key: 'status',
                render: value => {
                    if (value.sign_time) {
                        return 'Signed';
                    }

                    return 'Unsigned';
                },
            },
            {
                title: 'Signed',
                dataIndex: 'sign_time',
                key: 'sign_time',
                render: value => {
                    if (value) {
                        return (
                            <div>
                                kl
                                {' '}
                                {moment.unix(value).format('HH:mm')}
                                <br />
                                {moment.unix(value).format('YYYY-MM-DD')}
                            </div>
                        );
                    }

                    return '-';
                },
            },
            {
                title: 'Contract period',
                dataIndex: 'period',
                key: 'period',
                width: 200,
                render: value => `${moment.unix(value.start_time).format('YYYY-MM-DD')} - ${moment.unix(value.end_time).format('YYYY-MM-DD')}`,
            },
            {
                title: 'Employer',
                dataIndex: 'employer',
                key: 'employer',
                width: 400,
                render: value => (
                    <Link to={`/employers/${value.id}`}>
                        {value.name}
                    </Link>
                ),
            },
            {
                title: 'Visible',
                dataIndex: 'hidden',
                key: 'hidden',
                render: (_, r) => (
                    r.period.hidden ? (
                        <CloseOutlined
                            onClick={() => {
                                this.props.updateBookingPeriod(r.booking_period_id, { hidden: false });
                            }}
                        />
                    ) : (
                        <CheckOutlined
                            onClick={() => {
                                this.props.updateBookingPeriod(r.booking_period_id, { hidden: true });
                            }}
                        />
                    )
                ),
            },
        ];

        const header = (
            <div
                style={{
                    textTransform: 'capitalize', fontSize: 20,
                }}
            >
                {moment(date).format('MMMM')}
                ,
                {' '}
                {data.length}
                {' '}
                contract
            </div>
        );

        return (
            <Collapse.Panel
                key={date}
                header={header}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    expandedRowRender={record => this.render2ndLevel(record)}
                    locale={{ emptyText: 'Nada, Njet, Nothing...' }}
                    pagination={false}
                    scroll={{ x: 1200 }}
                />
            </Collapse.Panel>
        );
    }

    renderYear(year, key) {
        const grouped = groupBy(year, item => moment.unix(item.created_time).format('YYYY-MM'));

        return (
            <div>
                <h3
                    style={{ marginTop: 20 }}
                >
                    {key}

                </h3>
                <Collapse accordion>
                    {map(grouped, (item, key) => this.renderTable(item, key))}
                </Collapse>
            </div>
        );
    }

    renderContent() {
        const { hasFetchedContracts } = this.props.consultant;
        const { contracts } = this.props.consultant;
        const sortedContracts = sortBy(contracts, ['created_time', 'booking_period_id', 'revision']).reverse();
        const contractsGroupedById = groupBy(sortedContracts, 'booking_period_id');

        // Add "isDepricated" as a parameter on all contracts that are older revisions
        const contractsWithDepricationStatus = filter(sortedContracts, contract => {
            const revisions = contractsGroupedById[contract.booking_period_id];

            // there are more than 1 revision
            if (revisions.length > 1) {
                for (let i = 0; i < revisions.length; i++) {
                    const revision = revisions[i];

                    if (revision.id > contract.id) {
                        return false;
                    }

                    contract.children = without(revisions, contract);
                    return true;
                }
            }

            return true;
        });

        const groupedByYear = groupBy(contractsWithDepricationStatus, contract => moment.unix(contract.created_time).format('YYYY'));

        if (!hasFetchedContracts) {
            return (
                <div
                    style={{ textAlign: 'center' }}
                >
                    <Spin />
                </div>
            );
        }

        return (
            <div
                style={{
                    flexDirection: 'column-reverse',
                    display: 'flex',
                }}
            >
                {map(groupedByYear, (year, key) => this.renderYear(year, key))}
            </div>
        );
    }

    render() {
        const { contracts } = this.props.consultant;

        if (!contracts.length) {
            return (
                <div className='content-card-full'>
                    <Empty />
                </div>
            );
        }

        return (
            <div className='content-card-full'>
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...consultantActions }, dispatch),
)(ConsultantContracts);
