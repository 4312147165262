import moment from 'moment';
import {
    groupBy,
    sortBy,
} from 'lodash';

export const selectActivitiesByDays = state => groupBy(
    sortBy(state.activities.activities,
        e => e.created_time).reverse(),
    e => moment.unix(e.created_time).startOf('day').format('dddd'),
);

export default { selectActivitiesByDays };
