import React from 'react';
import {
    Modal,
    Checkbox,
    Table,
} from 'antd';

import { omit } from 'lodash';
import { occupationTitle } from 'utils/nameformatters';

export default class CopyEmployerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCopyDescription: true,
            shouldCopyInfo: true,
            shouldCopyInvoiceAddress: true,
            shouldCopyContacts: true,
            shouldCopyLogo: true,
            shouldCopyCover: true,
            shouldCopyCustomShifts: true,
            shouldCopyProfiles: true,
            selectedCustomShifts: props.employer.settings.custom_shifts.map(s => s.id),
            selectedProfiles: props.profiles.map(p => p.id),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.profiles.length !== this.props.profiles.length) {
            this.setState({ selectedProfiles: nextProps.profiles.map(p => p.id) });
        }
    }

    renderCheckRow(key, title, disabled) {
        return (
            <div
                style={{
                    padding: 5,
                    cursor: disabled ? 'auto' : 'pointer',
                }}
                onClick={() => {
                    if (!disabled) {
                        this.setState({ [key]: !this.state[key] });
                    }
                }}
            >
                <Checkbox
                    checked={this.state[key]}
                    disabled={disabled}
                    style={{ marginRight: 5 }}
                />
                {title}
            </div>
        );
    }

    handleOk = () => {
        const { employer } = this.props;

        const data = {
            email: employer.email,
            phone: employer.phone,
            account_email: employer.account_email,
            account_phone: employer.account_phone,
            account_name: employer.account_name,
            account_title: employer.account_title,
            emr_system: employer.emr_system,
            county_id: employer.county_id,
            automotive: employer.automotive ? 'automotive' : 'maintained',
            secondary_department_type_id: employer.secondary_department_type_id,
            employer_type: employer.employer_type,
            isprotected: employer.isprotected,
            address: {
                addressline1: employer.address.addressline1,
                addressline2: employer.address.addressline2,
                postalcode: employer.address.postalcode,
                city: employer.address.city,
                country_code: employer.address.country_code,
            },
        };

        if (this.state.shouldCopyInvoiceAddress) {
            data.organization_number = employer.organization_number;

            data.invoice_address = (employer.invoice_address ? {
                addressline1: employer.invoice_address.addressline1,
                addressline2: employer.invoice_address.addressline2,
                postalcode: employer.invoice_address.postalcode,
                city: employer.invoice_address.city,
                name: employer.invoice_address.name,
                reference: employer.invoice_address.reference,
                country_code: employer.address.country_code,
            } : {});
        }

        if (this.state.shouldCopyDescription) {
            data.description = employer.description;
        }

        if (this.state.shouldCopyContacts) {
            data.shouldCopyContacts = true;
            data.contacts = employer.contacts.map(contact => omit(contact, ['id', 'avatar']));
        }

        if (this.state.shouldCopyLogo) {
            data.shouldCopyLogo = true;
            data.logo_url = employer.logo_url;
        }

        if (this.state.shouldCopyCover) {
            data.shouldCopyCover = true;
            data.cover_url = employer.cover_url;
        }

        if (this.state.shouldCopyCustomShifts) {
            data.shouldCopyCustomShifts = true;
            data.customShifts = this.state.selectedCustomShifts;
        }

        if (this.state.shouldCopyProfiles) {
            data.shouldCopyProfiles = true;
            data.profiles = this.state.selectedProfiles;
        }

        this.props.setCreateEmployerFormData(data);
        this.props.history.push('/createemployer');
    }

    handleCancel = () => {
        this.props.close();
    }

    onSelectCustomShift = selectedCustomShifts => {
        this.setState({ selectedCustomShifts });
    }

    renderCustomShifts() {
        if (!this.state.shouldCopyCustomShifts) {
            return null;
        }

        const rowSelection = {
            selectedRowKeys: this.state.selectedCustomShifts,
            onChange: this.onSelectCustomShift,
        };

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
        }, {
            title: 'Start time',
            dataIndex: 'start_time',
        }, {
            title: 'End time',
            dataIndex: 'end_time',
        }, {
            title: 'Price',
            dataIndex: 'price',
        }, {
            title: 'Break',
            dataIndex: 'shift_break',
        }];

        return (
            <Table
                columns={columns}
                dataSource={this.props.employer.settings.custom_shifts.map(s => ({
                    ...s,
                    key: s.id,
                }))}
                pagination={false}
                rowSelection={rowSelection}
                size='small'
            />
        );
    }

    onSelectProfile = selectedProfiles => {
        this.setState({ selectedProfiles });
    }

    renderProfiles() {
        if (!this.state.shouldCopyProfiles) {
            return null;
        }

        const rowSelection = {
            selectedRowKeys: this.state.selectedProfiles,
            onChange: this.onSelectProfile,
        };

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
        }, {
            title: 'Occupation',
            dataIndex: 'occupation_id',
            render: occupationId => occupationTitle(occupationId),
        }];

        return (
            <Table
                columns={columns}
                dataSource={this.props.profiles.map(p => ({
                    ...p,
                    key: p.id,
                }))}
                pagination={false}
                rowSelection={rowSelection}
                size='small'
            />
        );
    }

    render() {
        return (
            <Modal
                cancelText='Avbryt'
                okText='Fortsätt'
                title='Kopiera uppdragsgivare'
                visible={this.props.visible}
                width={760}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
            >
                {this.renderCheckRow('shouldCopyInfo', 'Kopiera info', true)}
                {this.renderCheckRow('shouldCopyDescription', 'Kopiera beskrivning')}
                {this.renderCheckRow('shouldCopyInvoiceAddress', 'Kopiera fakturaadress')}
                {this.renderCheckRow('shouldCopyContacts', 'Kopiera kontaktpersoner')}
                {this.renderCheckRow('shouldCopyLogo', 'Kopiera logo')}
                {this.renderCheckRow('shouldCopyCover', 'Kopiera omslagsbild')}
                {this.renderCheckRow('shouldCopyCustomShifts', 'Kopiera arbetstider')}
                {this.renderCustomShifts()}
                {this.renderCheckRow('shouldCopyProfiles', 'Kopiera profiler')}
                {this.renderProfiles()}

            </Modal>
        );
    }
}
