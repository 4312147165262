import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    groupBy,
    isEmpty,
} from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    Radio,
    Collapse,
} from 'antd';

import { occupationTitle } from 'utils/nameformatters';
import LoadingScreen from 'components/shared/loadingscreen';
import EmployerShiftSidebar from '../employershiftsidebar';
import EmployerShiftsMonth from './employershiftsmonth';
import useEmployerOccupationsIds from '../../../../hooks/useEmployerOccupationsIds';

const EmployerShiftsItems = ({
    fetchEmployerShifts,
    assignConsultantToShift,
    employer,
    history,
    match,
    removeEmployerShift,
    shifts,
    occupationsCountMap,
    settings: { occupations },
}) => {
    const employerOccupations = useEmployerOccupationsIds();
    const defaultOccupationOpt = employerOccupations.length ? employerOccupations[0] : null;

    const [occupationFilter, setOccupationFilter] = useState(defaultOccupationOpt);
    const [activeKey, setActiveKey] = useState(null);

    useEffect(() => {
        const scrollToOpenTab = () => {
            const openTab = document.querySelector('.ant-collapse-header[aria-expanded="true"]');
            const activeRow = document.querySelector('.table-row-shift--active');

            let scrollTo;

            if (activeRow) {
                scrollTo = (activeRow);
            }
            else if (!activeRow && openTab) {
                scrollTo = (openTab);
            }
            else {
                return;
            }

            const scrollPosition = scrollTo.getBoundingClientRect();

            if (scrollTo) {
                window.scrollTo(0, (scrollPosition.y - 200));
            }
        };

        scrollToOpenTab();
    }, []);

    const closeSidebar = () => {
        const month = match.params.monthId || activeKey;
        history.push(`/employers/${match.params.id}/shifts/${month || ''}`);
    };

    const renderMonths = useCallback(allShifts => {
        const grouped = groupBy(allShifts, shift => moment.unix(shift.start_time).startOf('day').startOf('month').unix());
        const fallbackText = `No shifts for${occupationTitle(occupationFilter)}`;
        let content;

        if (employer.profilesFetched) {
            if (!isEmpty(grouped)) {
                content = (
                    <Collapse
                        accordion
                        activeKey={match.params.monthId}
                        style={{ marginTop: 40 }}
                        onChange={newActiveKey => {
                            setActiveKey(newActiveKey);

                            if (newActiveKey || match.params.monthId) {
                                history.push(`/employers/${match.params.id}/shifts/${newActiveKey}`);
                            }
                            else {
                                history.push(`/employers/${match.params.id}/shifts`);
                            }
                        }}
                    >
                        {Object.keys(grouped).reverse().map((key, i) => {
                            const days = grouped[key];

                            return (
                                <Collapse.Panel
                                    key={key}
                                    header={`${moment.unix(key).format('MMMM YYYY')} (${days.length})`}
                                    style={{
                                        fontSize: 20,
                                        fontWeight: '600',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    <EmployerShiftsMonth
                                        activeKey={key}
                                        activeShiftId={parseInt(match.params.shiftId, 10)}
                                        dateKey={i}
                                        days={days}
                                        employer={employer}
                                        history={history}
                                        match={match}
                                        removeEmployerShift={removeEmployerShift}
                                    />
                                </Collapse.Panel>
                            );
                        })}
                    </Collapse>
                );
            }
            else {
                content = (
                    <h5
                        style={{
                            display: 'flex',
                            marginTop: 20,
                        }}
                    >
                        {fallbackText}
                    </h5>
                );
            }
        }
        else {
            content = (
                <LoadingScreen
                    fullScreen
                    text='¡Caca bueno!'
                />
            );
        }

        return content;
    }, [employer, history, match, occupationFilter, removeEmployerShift]);

    return (
        <section
            style={{
                paddingTop: 20,
                paddingBottom: 100,
            }}
        >
            <Radio.Group
                defaultValue={defaultOccupationOpt}
                size='large'
                onChange={e => {
                    setOccupationFilter(parseInt(e.target.value, 10));
                }}
            >
                {employerOccupations.map(occupationId => {
                    const occupation = occupations.find(occ => occ.id === occupationId);
                    return (
                        <Radio.Button
                            key={occupationId}
                            value={occupationId}
                        >
                            {`${occupation.name} (${occupationsCountMap[occupationId]})`}
                        </Radio.Button>
                    );
                })}
            </Radio.Group>
            {shifts.employerShifts && renderMonths(shifts.employerShifts)}
            <EmployerShiftSidebar
                assignConsultantToShift={assignConsultantToShift}
                fetchEmployerShifts={fetchEmployerShifts}
                open={match.params.shiftId && match.params.shiftId.length}
                shiftId={match.params.shiftId}
                onClose={closeSidebar}
            />
        </section>
    );
};

const mapStateToProps = state => {
    const occupationsCountMap = state.settings.occupations.reduce((countMap, occ) => {
        const countMapper = countMap;
        const occupationShifts = state.shifts.employerShifts.filter(s => s.profile.occupation_id === occ.id);
        countMapper[occ.id] = occupationShifts.length;

        return countMapper;
    }, {});

    return {
        occupationsCountMap,
        ...state,
    };
};

EmployerShiftsItems.propTypes = {
    assignConsultantToShift: PropTypes.func.isRequired,
    employer: PropTypes.shape({ profilesFetched: PropTypes.bool }),
    fetchEmployerShifts: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func }),
    match: PropTypes.shape({ params: PropTypes.shape({
        id: PropTypes.number,
        monthId: PropTypes.number,
        shiftId: PropTypes.arrayOf(PropTypes.number),
    }) }),
    occupationsCountMap: PropTypes.shape({}).isRequired,
    settings: PropTypes.shape({ occupations: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        shortname: PropTypes.string,
    })) }).isRequired,
    removeEmployerShift: PropTypes.func.isRequired,
    shifts: PropTypes.shape({ employerShifts: PropTypes.arrayOf(PropTypes.shape({ profile: PropTypes.shape({
        occupation_id: PropTypes.number.isRequired,
        start_time: PropTypes.number.isRequired,
    }) })) }).isRequired,
};

export default withRouter(connect(mapStateToProps)(EmployerShiftsItems));
