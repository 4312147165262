import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const SelectOrderId = ({
    orderIds,
    selectedOrderId,
    setOrderId,
}) => {
    let a;
    return (
        <div>
            <div>
                <strong>
                    Edit existing order:
                </strong>
            </div>
            <Select
                allowClear
                showSearch
                size='large'
                style={{
                    minWidth: 200,
                    marginBottom: 20,
                }}
                value={selectedOrderId || 0}
                onChange={value => {
                    setOrderId(value);
                }}
            >
                <Select.Option disabled value={0}>
                    Select Order ID
                </Select.Option>
                {orderIds.map(value => (
                    <Select.Option value={value}>{value}</Select.Option>
                ))}
            </Select>
        </div>
    );
};

SelectOrderId.propTypes = {
    orderIds: PropTypes.arrayOf(PropTypes.string),
    selectedOrderId: PropTypes.string,
    setOrderId: PropTypes.func.isRequired,
};

export default SelectOrderId;
