const getQueryString = (queries) => {
  if (!queries || !Object.keys(queries).length) return [];
  return Object.keys(queries).reduce((acc, current) => {
    const types = queries[current]
    if (!types) return acc;
    const queryList = types.map((qs) => {
      const [v, b] = qs.split('::')
      if(v === "true" || b === "Only show students" || v === "only_show_students_graduates") return true
      if(v === "false" || b === "Dont show students") return false
      return v
    });
    acc = {
      ...acc, ...{
        [current]: queryList
      }
    }
    return acc;
  }, {})
};

export default getQueryString;
