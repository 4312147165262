const initialState = { job: [] };

export function job(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_JOB':
            return {
                ...state, job: action.job,
            };

        default:
            return state;
    }
}
