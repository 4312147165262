import React, { useMemo } from 'react';
import { Checkbox } from 'antd';

const CheckboxGroup = ({ data, onSetChange, selected }) => {
  const selectedEvents = selected && selected['events'] || [];
  const onChangeHandler = (item) => {
    onSetChange(item);
  }
  const renderCheckboxGroup = useMemo(() => data.map((item, index) => {
    const value = `${item.value}::${item.label}`
    const checked = selectedEvents.includes(value)
    return (
      <Checkbox key={`${item.value}-${index}`} value={value} checked={checked} onChange={(e) => onChangeHandler(value)}>{item.label}</Checkbox>
    )
  }), [data]);


  return (
      <span>{ renderCheckboxGroup }</span>
  );
}

export default CheckboxGroup;

