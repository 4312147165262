import moment from 'moment';
import uuid from 'node-uuid';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';
import ShiftsList from './shiftList';
import './styles.scss';

const Shifts = ({
    shiftList, selectedProfile,
}) => {
    const [shifts, setShifts] = useState([]);

    useEffect(() => {
        const formattedShifts = shiftList.map(shift => ({
            ...shift,
            id: shift.id || uuid.v4(),
            profile_id: selectedProfile?.id,
            outOfRange: moment(shift.start_time).isBefore(moment(), 'day'),
        }));
        setShifts(formattedShifts);
    }, [shiftList]);

    return (
        <ShiftsList shifts={shifts} />
    );
};

Shifts.propTypes = {
    shiftList: PropTypes.arrayOf(PropTypes.shape({
        shifts: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            start_time: PropTypes.string,
            end_time: PropTypes.string,
        })),
    })),
    selectedProfile: PropTypes.shape({
        id: PropTypes.number,
    }),
};

export default Shifts;
