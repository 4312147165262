import React, { useState } from 'react';
import {
    Modal,
    Button,
    Form,
    Switch,
    Upload,
} from 'antd';
import {
    SaveOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import FormInput from '../shared/formInput';
import {
    requestAdminUserFileUploadURL,
    uploadFileToBucket,
} from '../../actions/uploads';

import './UploadsModal.scss';

const UploadsModal = ({
    modalConfig,
    onClose,
    onSave,
}) => {
    const {
        mode,
        upload,
        isVisible,
        file_type,
    } = modalConfig;

    const [uploadInProgress, setUploadInprogress] = useState(false);
    const userVisibility = upload ? upload.user_visible : false;
    const [userVisible, setUserVisible] = useState(userVisibility);
    const employerVisibility = upload ? upload.employer_visible : true;
    const [employerVisible, setEmployerVisible] = useState(employerVisibility);
    const [isValid, setIsValid] = useState(upload && !!upload.name);

    const [form] = Form.useForm();

    const fileGenericError = 'Something went wrong. Please try again.';

    const uploadFile = options => {
        const {
            onSuccess,
            onError,
            file,
        } = options;
        const {
            type,
            size,
        } = file;

        const { userId } = modalConfig;
        const data = form.getFieldsValue();

        requestAdminUserFileUploadURL(userId, data, size, type)
            .then(response => {
                const blobFile = file;
                const formData = new FormData();
                formData.append('body', blobFile);
                form.setFieldsValue({
                    ...data,
                    uploadUrl: response.upload_url,
                    id: response.upload_id,
                });

                uploadFileToBucket(blobFile, response.upload_url, 'PUT')
                    .then(() => {
                        onSuccess();
                        setUploadInprogress(false);
                    })
                    .catch(error => {
                        setUploadInprogress(false);
                        onError();
                        const displayedError = error.responseJSON ? error.responseJSON.message : fileGenericError;
                        form.setFields([{
                            name: 'uploadUrl',
                            errors: [displayedError],
                        }]);
                    });
            })
            .catch(error => {
                setUploadInprogress(false);
                onError();
                const displayedError = error.responseJSON ? error.responseJSON.message : fileGenericError;
                form.setFields([{
                    name: 'uploadUrl',
                    errors: [displayedError],
                }]);
            });
    };

    const validateMessages = { required: '${label} is required' };

    const saveFile = data => {
        const completeData = {
            ...data,
            user_visible: userVisible,
            employer_visible: employerVisible,
        };
        onSave(completeData);
    };

    return (
        <Modal
            className='uploads-modal'
            footer={null}
            title={mode === 'edit' ? `Update ${upload.name}` : 'New file upload'}
            visible={isVisible}
            width='50%'
            onCancel={onClose}
        >
            <Form
                className='form'
                form={form}
                initialValues={{
                    ...upload,
                    file_type,
                }}
                labelCol={{ span: 8 }}
                name='upload-form'
                validateMessages={validateMessages}
                wrapperCol={{ span: 16 }}
                onChange={() => {
                    const nameExists = form.getFieldValue('name');
                    setIsValid(nameExists);
                }}
                onFinish={saveFile}
            >

                <FormInput
                    formDataKey='id'
                    type='hidden'
                />

                <FormInput
                    formDataKey='file_type'
                    type='hidden'
                />

                <FormInput
                    formDataKey='name'
                    label='Name'
                    rules={[{ required: true }]}
                />

                <FormInput
                    formDataKey='description'
                    label='Description'
                />

                <Form.Item
                    label='User visible'
                    name='user_visible'
                >
                    <Switch
                        checked={userVisible}
                        onChange={() => setUserVisible(!userVisible)}
                    />
                </Form.Item>

                <Form.Item
                    label='Employer visible'
                    name='employer_visible'
                >
                    <Switch
                        checked={employerVisible}
                        onChange={() => setEmployerVisible(!employerVisible)}
                    />
                </Form.Item>

                <Form.Item
                    label={mode === 'edit' ? 'Change upload file' : 'Upload new file'}
                    name='uploadUrl'
                    rules={[{ required: mode !== 'edit' }]}
                >
                    <Upload
                        customRequest={uploadFile}
                    >
                        <Button
                            disabled={!isValid}
                            ghost
                            icon={<UploadOutlined />}
                            title={isValid ? 'Upload file' : 'Please add a name first'}
                            type='primary'
                        >
                            {mode === 'edit' ? 'Change upload file' : 'Upload new file'}
                        </Button>
                    </Upload>
                    {mode === 'edit' && <a href={upload.url} rel='noreferrer' target='_blank'>Preview exisiting file</a>}
                </Form.Item>

                <footer className='footer'>
                    <Button
                        danger
                        style={{ marginRight: 5 }}
                        type='ghost'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        htmlType='submit'
                        icon={<SaveOutlined />}
                        loading={uploadInProgress}
                        type='primary'
                    >
                        Save
                    </Button>
                </footer>
            </Form>
        </Modal>
    );
};

UploadsModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    modalConfig: PropTypes.shape({
        file_type: PropTypes.string,
        isVisible: PropTypes.bool.isRequired,
        mode: PropTypes.string.isRequired,
        upload: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            user_visible: PropTypes.bool,
            employer_visible: PropTypes.bool,
            id: PropTypes.number,
            url: PropTypes.string,
        }),
        userId: PropTypes.number.isRequired,
    }).isRequired,
};

export default UploadsModal;
