import React from 'react';
import Ink from 'react-ink';
import { find } from 'lodash';
import classNames from 'utils/classnames';
import { withFormsy } from 'formsy-react';
import SelectOptions from './selectoptions';
import './styles.scss';

class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = { open: false };

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    close() {
        this.setState({ open: false });
    }

    open() {
        if (this.props.disabled) {
            return;
        }
        this.setState({ open: true });
    }

    onSelect(value) {
        this.props.setValue(value);
        this.props.onSelect && this.props.onSelect(value);
        this.close();
    }

    renderOptions() {
        return (
            <SelectOptions
                close={this.close}
                filter={this.props.filter}
                options={this.props.options}
                value={this.props.value}
                onSelect={this.onSelect}
            />
        );
    }

    renderValue() {
        const { value } = this.props;
        let displayValue;
        if (value !== null) {
            const valueTitle = find(this.props.options, { value });

            displayValue = valueTitle ? valueTitle.title : this.props.emptyValue;
        }
        return displayValue || this.props.emptyValue;
    }

    render() {
        return (
            <div
                className='mp-select'
            >
                <div
                    className={classNames({
                        'mp-select__trigger': true,
                        'mp-select__trigger--open': this.state.open,
                        'mp-select__trigger--disabled': this.props.disabled,

                        [this.props.className]: true,
                    })}
                    onClick={this.open}
                >
                    <Ink />
                    {this.renderValue()}
                </div>
                {this.state.open && this.renderOptions()}
            </div>
        );
    }
}

Select.defaultProps = {
    className: '',
    disabled: false,
    required: false,
    validations: {},
    validationErrors: {},
    label: '',
    block: false,
    errorMessageStyle: 'hint', // 'hint' or 'error' is available
};

export default withFormsy(Select);
