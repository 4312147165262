import {
    CheckOutlined,
    CopyOutlined,
    SaveOutlined,
    SendOutlined,
    SyncOutlined,
    EditOutlined,
} from '@ant-design/icons';
import {
    fetchOrder,
    updateShifts,
    updateOrder,
} from 'actions/orders';

import {
    Button,
    Divider,
    Form,
    Input,
    Spin,
    Tag,
    List,
    Typography,
    Space,
    Select,
    Modal,
} from 'antd';
import FormInput from 'components/shared/formInput';
import LoadingScreen from 'components/shared/loadingscreen';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useParams,
    useHistory,
} from 'react-router-dom';
import {
    fetchEmployerProfiles,
    createMultipleShifts,
} from 'actions/employer';
import {
    startEndTimeToDuration,
    startEndStringTimesToMoment,
} from 'utils/datetimeformatters';
import camelToFlat from 'utils/camelToFlat';
import EmployerSearchList from 'components/employersearch';
import { fetchEmployersV3 } from '../../../../request';
import Shifts from './components/shifts';
import './styles.scss';
import OrderDetailsCard from '../../components/OrdeDetailsCard';
import HandleOrderModal from '../../modals/HandleOrderModal/index';
import EditShiftsModal from '../../components/EditShiftsModal';

const Order = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const order = useSelector(state => state.orders.order);
    const [shifts, setShifts] = useState([]);

    const emptyFormData = {
        customer_name: '',
        department_name: '',
        is_posted: false,
        roles: [],
    };

    const [form] = Form.useForm();
    const [employerOptions, setEmployerOptions] = useState([]);
    const [onSelectedEmployer, setOnSelectedEmployer] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [inputValue, setInputValue] = useState(order?.customer_name || '');
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [selectedEmployerId, setSelectedEmployerId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalStep, setModalStep] = useState('createEmployer');
    const [employerProfiles, setEmployerProfiles] = useState([]);
    const [loadingProfiles, setLoadingProfiles] = useState(false);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isEditShiftsModalVisible, setIsEditShiftsModalVisible] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [editMode, setEditMode] = useState('edit'); // Add this state

    useEffect(() => {
        if (order?.roles) {
            setShifts(order.roles[0].shifts);
        }
    }, [order]);

    const searchEmployer = useCallback(debounce(val => {
        setIsFetching(true);
        if (!val) {
            return;
        }
        fetchEmployersV3(val)
            .then(res => {
                const employerList = res.collection.map(employer => ({
                    id: employer.id,
                    value: employer.id,
                    label: employer.name,
                }));
                // If searching based on order.customer_name, try to find exact match
                const matchingEmployer = employerList.find(emp => emp.label.toLowerCase() === val.toLowerCase());
                if (matchingEmployer) {
                    setSelectedEmployerId(matchingEmployer.id);
                    setSelectedEmployer(matchingEmployer);
                }
                if (employerList.length === 0) {
                    setEmployerOptions([{
                        value: -1, label: `Create new customer: "${val}"`,
                    }]);
                }
                else {
                    setEmployerOptions(employerList);
                }

                setIsFetching(false);
            })
            .catch(() => {
                setEmployerOptions([{
                    value: -1, label: `Create new customer: "${val}"`,
                }]);
                setIsFetching(false);
            });
    }, 500), []);

    useEffect(() => {
        if (selectedEmployerId && selectedEmployerId !== -1) {
            setLoadingProfiles(true);
            dispatch(fetchEmployerProfiles(selectedEmployerId)).then(res => {
                const profiles = res?.profiles?.profiles || [];
                setEmployerProfiles(profiles);
                setLoadingProfiles(false);

                // If we have a selected profile ID, make sure it's still valid
                if (selectedProfileId) {
                    const profile = profiles.find(p => p.id === selectedProfileId);
                    if (!profile) {
                        setSelectedProfileId(null);
                        setSelectedProfile(null);
                    }
                }
            });
        }
    }, [selectedEmployerId]);

    useEffect(() => {
        if (id) {
            setIsFetching(true);
            dispatch(fetchOrder(id)).then(() => setIsFetching(false));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (order) {
            form.setFieldsValue(order);
        }
        if (order?.customer_name) {
            setInputValue(order?.customer_name);
            searchEmployer(order?.customer_name);
        }
    }, [order, form]);

    const antIcon = (
        <SyncOutlined
            spin
            style={{
                fontSize: 20,
            }}
        />
    );

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setModalStep('checkEmployer');
    };

    const handleEmployerCreation = () => {
        setIsModalVisible(true);
        setModalStep('createEmployer');
    };
    const onEmployerButtonClick = copy => {
        setSelectedEmployer(copy ? {
            ...onSelectedEmployer, copy,
        } : onSelectedEmployer);
        setIsModalVisible(true);
    };

    const handleCreateShifts = () => {
        setEditMode('create');
        setIsEditShiftsModalVisible(true);
    };

    const handleEditShifts = () => {
        setEditMode('edit');
        setIsEditShiftsModalVisible(true);
    };

    const handleEditShiftsCancel = () => {
        setIsEditShiftsModalVisible(false);
        setEditMode('edit');
    };

    const handleEmployerSelection = (value, employerData) => {
        if (value === -1) {
            handleEmployerCreation();
            return;
        }

        setSelectedEmployerId(value);
        // Use the employer data passed from the component if available, otherwise find it in employerOptions
        const employer = employerData || employerOptions.find(emp => emp.id === value);
        setSelectedEmployer(employer);
        setFormValid(!!employer);

        // Update the form with the selected employer
        form.setFieldsValue({
            employer: value,
            customer_name: employer?.name || employer?.label,
        });
    };

    const handleProfileCreation = () => {
        setModalStep('createProfile');
        setIsModalVisible(true);
    };

    const handleProfileSelection = profileId => {
        setSelectedProfileId(profileId);
        const profile = employerProfiles.find(p => p.id === profileId);
        if (profile) {
            setSelectedProfile(profile);
            setFormValid(!!selectedEmployerId);
        }
    };

    const handleSaveAllShifts = async createdShifts => {
        try {
            setIsFetching(true);

            const currentEmployer = selectedEmployer;
            const currentProfile = selectedProfile;

            await dispatch(updateShifts(id, createdShifts));
            setShifts(createdShifts);
            await dispatch(fetchOrder(id));

            setSelectedEmployer(currentEmployer);
            setSelectedEmployerId(currentEmployer?.id);
            setSelectedProfile(currentProfile);
            setSelectedProfileId(currentProfile?.id);

            setIsEditShiftsModalVisible(false);
        }
        catch (error) {
            console.error('Error saving shifts:', error);
            Modal.error({
                title: 'Error',
                content: 'Failed to save shifts. Please try again.',
            });
        }
        finally {
            setIsFetching(false);
        }
    };

    const getNewShiftsFormatted = () => shifts.map(s => {
        const {
            date,
            start_time,
            end_time,
            shift_break,
            price,
            margin,
            consultant_fee_margin,
            last_application_datetime,
            is_price_hidden,
        } = s;
        const duration = startEndTimeToDuration(start_time, end_time);

        return {
            start_time,
            end_time,
            duration,
            price,
            shift_break: parseFloat(shift_break),
            margin,
            consultant_fee_margin,
            profile_id: selectedProfile.id,
            consultant: null,
            status: 1,
            uuid: id,
            last_application_datetime,
            is_price_hidden,
        };
    });

    const handlePublishOrder = async () => {
        if (!selectedEmployerId || !selectedProfile) {
            Modal.error({
                title: 'Validation Error',
                content: 'Please select both an employer and a profile before publishing.',
            });
            return;
        }

        if (!shifts.length) {
            Modal.error({
                title: 'Validation Error',
                content: 'Please add at least one shift before publishing.',
            });
            return;
        }

        try {
            const shiftData = getNewShiftsFormatted();

            await dispatch(createMultipleShifts(selectedEmployerId, shiftData, true));

            // now update the order is_posted to true
            await dispatch(updateOrder(id, { is_posted: true }));
            await dispatch(fetchOrder(id));

            history.push('/orders');
        }
        catch (error) {
            console.error('Error publishing order:', error);
            Modal.error({
                title: 'Error',
                content: 'Failed to publish order. Please try again.',
            });
        }
    };

    const handleEmployerCreationSuccess = async newEmployer => {
        setSelectedEmployerId(newEmployer.id);
        setSelectedEmployer(newEmployer);
        form.setFieldsValue({
            employer: newEmployer.id,
            customer_name: newEmployer.name,
        });

        setEmployerOptions(prev => [...prev, {
            id: newEmployer.id, value: newEmployer.id, label: newEmployer.name,
        }]);
    };

    const handleProfileCreationSuccess = async newProfileObj => {
        const newProfile = {
            id: newProfileObj.id, name: newProfileObj.name,
        };
        setEmployerProfiles(prev => [...prev, newProfile]);
        setSelectedProfileId(newProfile.id);
        setSelectedProfile(newProfile);
    };

    if (isFetching && !order) {
        return (
            <LoadingScreen
                fullScreen
                text='Loading....'
            />
        );
    }

    return (
        <main className='order-view row justify-content-between'>
            <div className='col-md-12'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <h2 style={{ margin: 0 }}>
                        Order details for
                        {' '}
                        {order?.customer_name}

                    </h2>
                </div>
            </div>
            {order
            && (

                <Form
                    className='form'
                    form={form}
                    initialValues={{ start_time: moment() }}
                    labelCol={{ span: 4 }}
                    name='order-form'
                    // validateMessages={validateMessages}
                    wrapperCol={{ span: 20 }}
                >
                    { order.id
                && (
                    <FormInput
                        formDataKey='id'
                        rules={[{ required: true }]}
                        type='hidden'
                    />
                )}
                    <OrderDetailsCard order={order} />

                    <Divider />

                    <Typography.Title level={3}>Create new post based on order</Typography.Title>

                    <div className='form-sub-heading'>
                        Customer details
                    </div>
                    <div>
                        <span style={{ fontStyle: 'italic' }}>
                            Create a new customer if you don't see them in the list.
                        </span>
                    </div>

                    <Form.Item label='Select customer / create new' name='employer' rules={[{ required: true }]}>
                        <EmployerSearchList
                            employers={employerOptions}
                            placeholder='Search for an customer'
                            value={selectedEmployerId}
                            onChange={handleEmployerSelection}
                        />
                        <Spin
                            indicator={antIcon}
                            spinning={isFetching}
                            style={{
                                position: 'absolute',
                                right: 35,
                                top: 5,
                            }}
                        />
                        <Button onClick={() => handleEmployerCreation()}>Add customer</Button>

                        {onSelectedEmployer?.value > -1 && (
                            <div>
                                <Button
                                    disabled={!onSelectedEmployer}
                                    icon={<CheckOutlined />}
                                    style={{ marginRight: 5 }}
                                    onClick={() => onEmployerButtonClick(false)}
                                >
                                    Match employer
                                </Button>
                                <Button
                                    disabled={!onSelectedEmployer}
                                    icon={<CopyOutlined />}
                                    onClick={() => onEmployerButtonClick(true)}
                                >
                                    Copy employer

                                </Button>
                            </div>
                        )}
                    </Form.Item>

                    <Divider />

                    <div className='form-sub-heading'>
                        Profile details
                    </div>
                    <div>
                        <span style={{ fontStyle: 'italic' }}>
                            Note: If you create a new employer, you will need to create a profile for them as well.
                        </span>
                    </div>
                    <Form.Item label='Select profile' name='profile' rules={[{ required: true }]}>
                        {selectedEmployerId && selectedEmployerId !== -1 && (
                            <Select
                                loading={loadingProfiles}
                                placeholder='Select existing profile'
                                style={{ width: '100%' }}
                                value={selectedProfileId}
                                onChange={handleProfileSelection}
                            >
                                {employerProfiles ? employerProfiles.map(profile => (
                                    <Select.Option key={profile.id} value={profile.id}>
                                        {profile.id}
                                        {': '}
                                        {profile.name}
                                        {' - '}
                                        {profile.is_long_term ? '[LTV profile]' : '[Shifts profile]'}
                                    </Select.Option>
                                )) : null}
                            </Select>
                        )}

                        <Button onClick={handleProfileCreation}>
                            Create Profile
                        </Button>

                        <List
                            dataSource={order?.roles}
                            renderItem={role => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={(
                                            <Space direction='vertical' style={{ width: '100%' }}>
                                                <Typography.Text strong>{role.name}</Typography.Text>
                                                <div>
                                                    {role?.skills?.map(skill => (
                                                        <Tag key={skill.id} color='blue' style={{ marginBottom: '8px' }}>
                                                            {camelToFlat(skill.skill_type)}
                                                            :
                                                            {' '}
                                                            {skill.skill_name}
                                                        </Tag>
                                                    ))}
                                                </div>
                                            </Space>
                                        )}
                                    />

                                </List.Item>
                            )}
                        />
                    </Form.Item>

                    <Divider />
                    <div
                        className='form-sub-heading'
                        style={{
                            display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                        }}
                    >
                        <span>Shift details</span>
                        <Space>
                            {shifts.length === 0 && (
                                <Button
                                    icon={<SaveOutlined />}
                                    type='primary'
                                    onClick={handleCreateShifts}
                                >
                                    Create shifts
                                </Button>
                            )}
                            <Button
                                icon={<EditOutlined />}
                                style={{ marginRight: 5 }}
                                type='primary'
                                onClick={handleEditShifts}
                            >
                                Edit shifts
                            </Button>
                        </Space>
                    </div>

                    <Shifts
                        selectedProfile={selectedProfile}
                        shiftList={shifts}
                    />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            disabled={!selectedEmployerId || !selectedProfile || !shifts.length}
                            icon={<SendOutlined />}
                            style={{
                                color: 'green',
                                borderColor: 'green',
                            }}
                            type='ghost'
                            onClick={() => handlePublishOrder()}
                        >
                            Publish
                        </Button>
                    </div>
                </Form>
            )}
            <HandleOrderModal
                handleEmployerCreationSuccess={handleEmployerCreationSuccess}
                handleProfileCreationSuccess={handleProfileCreationSuccess}
                modalStep={modalStep}
                order={order}
                selectedEmployer={selectedEmployer}
                setModalStep={setModalStep}
                visible={isModalVisible}
                onCancel={handleModalCancel}
            />
            <EditShiftsModal
                mode={editMode}
                profiles={employerProfiles}
                selectedProfileId={selectedProfile?.id}
                shifts={shifts}
                visible={isEditShiftsModalVisible}
                onCancel={handleEditShiftsCancel}
                onSave={handleSaveAllShifts}
            />
        </main>

    );
};

export default Order;
