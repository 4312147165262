import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userMarketingSourcesActions from 'actions/usermarketingsources';
import InputModal from 'components/inputmodal';
import modalController from 'utils/modalcontroller';
import Table from 'components/shared/table';
import Button from 'components/shared/button';
import config from 'config';
import {
    Form,
    Select,
} from 'antd';
import './styles.scss';
import FormItem from 'antd/lib/form/FormItem';
import MarketingSourceCharts from './marketingsourcecharts';

class UserMarketingSources extends React.Component {
    constructor(props) {
        super(props);

        this.state = { selectedCountry: 'SWE' };

        this.renderTable = this.renderTable.bind(this);
    }

    componentDidMount() {
        this.props.fetchUserMarketingSources();
        this.props.fetchUserOtherMarketingSources();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userMarketingSources.fetchFailed) {
            alert(nextProps.userMarketingSources.errorMessage);
        }
    }

    renderTable() {
        const { marketingSources } = this.props.userMarketingSources;
        const countryFilteredMarketingSources = marketingSources.filter(source => source.country_code === this.state.selectedCountry || !source.country_code);

        return (
            <Table
                headers={[
                    'ID',
                    'Marketing source',
                    'Registered users',
                    'Active',
                    'Deactivate',
                ]}
                renderRow={row => (
                    <tr>
                        <td>{row.id}</td>
                        <td>{row.title}</td>
                        <td>{row.count}</td>
                        <td>{row.inactive ? 'No' : 'Yes'}</td>
                        <td>
                            {row.id !== -1
                                    && (
                                        <Button
                                            disabled={row.inactive}
                                            outlineWarn
                                            onClick={() => {
                                                this.props.inactivateUserMarketingSource(row.id);
                                            }}
                                        >
                                            Deactivate
                                        </Button>
                                    )}
                        </td>
                    </tr>
                )}
                rows={countryFilteredMarketingSources}
                style={{ marginTop: 40 }}
                title='Selectable marketing sources'
            />
        );
    }

    renderOtherSources() {
        // Let's disable this for now since the UI for this hasn't been available in
        // the app for more than a year at this point

        return null;

        /*
        const {
            collection, total_count: totalCount,
        } = this.props.userMarketingSources.otherMarketingSources;

        return (

            <Table
                renderRow={row => (
                    <tr>
                        <td
                            style={{
                                paddingTop: 10,
                                paddingBottom: 5,
                            }}

                        >
                            "
                            {row.text}
                            "
                        </td>
                    </tr>
                )}
                rows={collection}
                style={{ marginTop: 80 }}
                title={`Free text answers consultants have given under "Other" (total: ${totalCount})`}
            />
        ); */
    }

    render() {
        const {
            fetchCompleted, marketingSources, otherMarketingSources,
        } = this.props.userMarketingSources;

        if (fetchCompleted) {
            const countryFilteredMarketingSources = marketingSources.filter(source => source.country_code === this.state.selectedCountry || !source.country_code);

            return (

                <main className='user-marketing-sources'>
                    <h2 className='heading'>
                        Registered users per marketing source
                    </h2>
                    <Form>
                        <FormItem
                            label='Country'
                        >
                            <Select
                                defaultValue={this.state.selectedCountry}
                                onSelect={selectedCountry => {
                                    this.setState({ selectedCountry });
                                }}
                            >
                                {config.countries.map(country => (
                                    <Select.Option
                                        key={country.value}
                                        value={country.value}
                                    >
                                        {country.engName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Form>
                    <MarketingSourceCharts
                        key={this.state.selectedCountry}
                        options={countryFilteredMarketingSources}
                    />
                    {this.renderTable()}
                    <Button
                        style={{ marginTop: 25 }}
                        onClick={() => {
                            modalController.showModal(InputModal, {
                                title: 'Add marketing source',
                                width: 600,
                                props: {
                                    inputs: [
                                        {
                                            type: 'input',
                                            name: 'title',
                                            label: 'New marketing source',
                                        },
                                        {
                                            type: 'select',
                                            name: 'countryCode',
                                            label: 'Country',
                                            value: this.state.selectedCountry,
                                            options: config.countries.map(country => ({
                                                value: country.value,
                                                title: country.engName,
                                            })),
                                        },
                                    ],
                                    onSave: formData => {
                                        this.props.addNewUserMarketingSource(formData.title, formData.countryCode)
                                            .then(() => modalController.closeModal());
                                    },
                                },
                            });
                        }}
                    >
                        Add new
                    </Button>
                    {otherMarketingSources && this.renderOtherSources()}
                </main>

            );
        }

        return (
            <main className='user-marketing-sources -loading'>
                <i className='fa fa-circle-o-notch fa-spin' />
            </main>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(userMarketingSourcesActions, dispatch),
)(UserMarketingSources);
