import React, {
    useState,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import InputModal from 'components/inputmodal';
import { Modal } from 'antd';
import './styles.scss';
import EditTrigger from 'components/shared/edittrigger';
import DeleteTrigger from 'components/shared/deletetrigger';

const UserCredentials = ({
    user, updateEmployerCredential, deleteEmployerCredential,
}) => {
    const formRef = useRef(null);

    const { confirm } = Modal;

    const [updateCredential, setUpdateCredential] = useState(false);
    const [formData, setFormData] = useState({});
    const handleUpdateCredential = () => setUpdateCredential(true);

    function handleDeleteCredential() {
        confirm({
            title: 'Do you Want to delete this user?',
            content: 'Please confirm that you will remove this user',
            onOk() {
                deleteEmployerCredential(user.id);
            },
            onCancel() {

            },
        });
    }

    return (

        <div
            className='content-card-inline col-sm-4'
            style={{ maxWidth: 'calc(33.33333% - 10px)' }}
        >
            <div
                style={{
                    fontWeight: 600, fontSize: 18,
                }}
            >
                {user.username}

                <EditTrigger
                    style={{
                        display: 'inline-block',
                        paddingLeft: 5,
                    }}
                    title=''
                    onClick={handleUpdateCredential}
                >
                    Update
                </EditTrigger>

                <Modal
                    cancelText='Cancel'
                    okText='Save'
                    title='Update credential'
                    visible={updateCredential}
                    onCancel={() => {
                        setUpdateCredential(false);
                        setFormData({});
                    }}

                    onOk={() => {
                        updateEmployerCredential(user.id, formData).then(() => {
                            setUpdateCredential(false);
                            setFormData({});
                        });
                    }}
                >
                    <InputModal
                        ref={formRef}
                        hideButton
                        inputs={[
                            {
                                type: 'input',
                                name: 'name',
                                label: 'Change name',
                                value: user.name,
                            },
                            {
                                type: 'input',
                                name: 'username',
                                label: 'Change username',
                                value: user.username,
                            },
                            {
                                type: 'input',
                                name: 'password',
                                label: 'password',
                            },

                        ]}
                        onChange={data => {
                            setFormData(data);
                        }}
                    />
                </Modal>

                <DeleteTrigger
                    style={{
                        display: 'inline-block',
                        paddingLeft: 5,
                    }}
                    onClick={handleDeleteCredential}
                />

            </div>
            <div>
                {user.name}
            </div>
            <div>
                <i className='fa fa-envelope' />
                <a href={`mailto:${user.account_email}`}>
                    {' '}
                    { user.account_email}
                </a>
            </div>
        </div>

    );
};

UserCredentials.propTypes = {
    user: PropTypes.shape({
        username: PropTypes.string,
        account_email: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    updateEmployerCredential: PropTypes.func.isRequired,
    deleteEmployerCredential: PropTypes.func.isRequired,
};

export default UserCredentials;
