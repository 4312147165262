import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sortBy } from 'lodash';

import LabelledSwitch from 'components/shared/LabelledSwitch';
import * as consultantsFilterActions from 'actions/consultantsfilter';
import config from 'config';

import { Checkbox } from 'antd';
import SelectFilter from './SelectFilter';
import StudentFilter from './StudentFilter';
import OccupationFilter from './OccupationFilter';
import LastActiveFilter from './LastActiveFilter';

import './styles.scss';

const ConsultantsFilter = ({
    consultantsFilter: {
        query = '',
        includeTestAccounts,
        includeTopConsultants,
        includeTags,
        intentions,
        excludeTags,
        occupation: filteredOccupation,
        student: filteredStudent,
        skills,
        specializations,
        lastActive,
        entityTypes,
    },
    hidden,
    filterToggleIncludeTestAccounts,
    filterToggleIncludeTopConsultants,
    includeEmployerOptions,
    filterSetOccupation,
    filterSetStudent,
    filterToggleLastActive,
    tags,
    settings,
    consultantsFilterSetIntentions,
    consultantsFilterSetSpecializations,
    consultantsFilterSetSkills,
    consultantsFilterSetIncludeTags,
    consultantsFilterSetExcludeTags,
    setFilterEntityTypes,
}) => {
    const classes = `consultants-filter 
        ${query ? 'consultants-filter--query' : ''}  
        ${hidden ? 'consultants-filter--hidden' : ''}`;

    // Prepare filter options

    const tagOptions = sortBy(tags.tags, t => t.tag).map(({
        id, tag,
    }) => ({
        id, name: tag,
    }));

    const skillOptions = sortBy(
        settings?.skills?.filter(skill => (filteredOccupation === config.occupations.OCCUPATION_ALL
            ? true
            : filteredOccupation === skill.occupation_id)), skill => skill.occupation_id,
    );
    const specializationOptions = sortBy(
        settings?.specializations?.filter(specialization => (filteredOccupation === config.occupations.OCCUPATION_ALL
            ? true
            : filteredOccupation === specialization.occupation_id)), specialization => specialization.occupation_id,
    );

    const occupationOptions = [
        {
            id: config.occupations.OCCUPATION_ALL,
            name: 'All',
        },
        ...settings.occupations,
    ];

    const studentOptions = [
        {
            id: config.students.STUDENT_ALL,
            name: 'All',
        },
        {
            id: config.students.STUDENT_ONLY,
            name: 'Show only students',
        },
        {
            id: config.students.STUDENT_NONE,
            name: 'Don’t show students',
        },
    ];

    return (
        <div className={classes}>
            <h3>Filter</h3>

            {/* Test accounts filter */}
            <LabelledSwitch
                className='consultantsfilter__test-accounts'
                isChecked={includeTestAccounts}
                onChange={filterToggleIncludeTestAccounts}
            >
                Show test accounts
            </LabelledSwitch>

            {/* show top consultants */}
            <LabelledSwitch
                className='consultantsfilter__top-accounts'
                isChecked={includeTopConsultants}
                onChange={filterToggleIncludeTopConsultants}
            >
                Show Top Consultants
            </LabelledSwitch>

            {/* Student filter */}
            <StudentFilter
                includeEmployerOptions={includeEmployerOptions}
                students={studentOptions}
                value={filteredStudent}
                onChange={filterSetStudent}
            />

            {/* Occupation filter */}
            <OccupationFilter
                includeEmployerOptions={includeEmployerOptions}
                occupations={occupationOptions}
                value={filteredOccupation}
                onChange={filterSetOccupation}
            />

            {/* Entity types filters */}

            { includeEmployerOptions ? (
                <Checkbox.Group value={entityTypes} onChange={e => setFilterEntityTypes(e)}>
                    <Checkbox value='CLINICIANS'>Clinicians</Checkbox>
                    <Checkbox value='EMPLOYERS'>Employers</Checkbox>
                </Checkbox.Group>
            ) : <></> }

            {/* Specialization filter */}
            <SelectFilter
                current={specializations}
                id='specializations-select'
                label='Filter by specialist education:'
                options={specializationOptions}
                placeholder='Add specialization'
                onChange={consultantsFilterSetSpecializations}
            />

            {/* Skill filter */}
            <SelectFilter
                current={skills}
                id='skills-filter'
                label='Filter by skill:'
                options={skillOptions}
                placeholder='Add skill'
                onChange={consultantsFilterSetSkills}
            />

            {/* Intentions filter */}
            <SelectFilter
                current={intentions}
                id='intentions-filter'
                label='Filter by intention:'
                options={settings.intentions}
                placeholder='Add intention'
                onChange={consultantsFilterSetIntentions}
            />

            {/* Include tags filter */}
            <SelectFilter
                current={includeTags}
                id='include-tags-filter'
                label='Include tags:'
                options={tagOptions}
                placeholder='Add tag'
                onChange={consultantsFilterSetIncludeTags}
            />

            {/* Exclude tags filter */}
            <SelectFilter
                current={excludeTags}
                id='exclude-tags-filter'
                label='Exclude tags:'
                options={tagOptions}
                placeholder='Add tag'
                onChange={consultantsFilterSetExcludeTags}
            />

            {/* Last active filter */}
            <LastActiveFilter
                activeFilters={lastActive}
                filters={config.lastActive}
                onChange={filterToggleLastActive}
            />
        </div>
    );
};

ConsultantsFilter.propTypes = {
    consultantsFilter: PropTypes.shape({
        query: PropTypes.string,
        includeTestAccounts: PropTypes.bool,
        includeTopConsultants: PropTypes.bool,
        includeTags: PropTypes.arrayOf(PropTypes.number),
        intentions: PropTypes.arrayOf(PropTypes.number),
        excludeTags: PropTypes.arrayOf(PropTypes.number),
        occupation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        skills: PropTypes.arrayOf(PropTypes.number),
        specializations: PropTypes.arrayOf(PropTypes.number),
        lastActive: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    hidden: PropTypes.string,
    settings: PropTypes.shape({
        specializations: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            id: PropTypes.number,
            name: PropTypes.string,
            occupation_id: PropTypes.number,
        })),
        skills: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            id: PropTypes.number,
            name: PropTypes.string,
            occupation_id: PropTypes.number,
        })),
        intentions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        occupations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            shortname: PropTypes.string,
            enabled: PropTypes.bool,
        })),
    }).isRequired,
    includeEmployerOptions: PropTypes.bool,
    tags: PropTypes.shape({
        hasFetched: PropTypes.bool,
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            tag: PropTypes.string,
        })),
    }).isRequired,
    filterToggleLastActive: PropTypes.func.isRequired,
    filterToggleIncludeTestAccounts: PropTypes.func.isRequired,
    filterToggleIncludeTopConsultants: PropTypes.func.isRequired,
    filterSetOccupation: PropTypes.func.isRequired,
    filterSetStudent: PropTypes.func.isRequired,
    consultantsFilterSetSpecializations: PropTypes.func.isRequired,
    consultantsFilterSetSkills: PropTypes.func.isRequired,
    consultantsFilterSetIntentions: PropTypes.func.isRequired,
    consultantsFilterSetIncludeTags: PropTypes.func.isRequired,
    consultantsFilterSetExcludeTags: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    tags: state.tags,
    consultantsFilter: state.consultantsFilter,
    settings: state.settings,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(consultantsFilterActions, dispatch),

)(ConsultantsFilter);
