import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchApplication,
    removeShiftsFromBookedApplication,
    resetApplication,
} from 'actions/applications';
import { removeEmployerShifts } from 'actions/employer';
import { Link } from 'react-router-dom';
import { humanReadableHours } from 'utils/datetimeformatters';
import { countyName } from 'utils/nameformatters';
import moment from 'moment';
import {
    Modal,
    Table,
    Button,
    Spin,
    Popconfirm,
    Avatar,
    Switch,
} from 'antd';

import { without } from 'lodash';
import usePrevious from 'hooks/usePrevious';

const EditBookingModal = ({
    applications,
    data,
    shifts,
    visible,
    fetchApplication,
    removeEmployerShifts,
    removeShiftsFromBookedApplication,
    resetApplication,
    close,
}) => {
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [removeShifts, setRemoveShifts] = useState([]);

    useEffect(() => {
        fetchApplication(data.bookingId);
        return () => { resetApplication(); };
    }, []);

    const previousRemovingShiftsFromBookedApplication = usePrevious(applications.removingShiftsFromBookedApplication);
    const previousIsDeletingShifts = usePrevious(shifts.isDeletingShifts);
    const shiftsHaveBeenRemovedFromBooking = (
        previousRemovingShiftsFromBookedApplication && !applications.removingShiftsFromBookedApplication
    );
    if (shiftsHaveBeenRemovedFromBooking) {
        if (removeShifts.length) {
            removeEmployerShifts(applications.application.employer.id, removeShifts);
        }
    }
    const hasDeletedEmptyShifts = (previousIsDeletingShifts && !shifts.isDeletingShifts);
    if (hasDeletedEmptyShifts && removeShifts.length) {
        setRemoveShifts([]);
    }

    const onSelectChange = (selectedRowKeys, items) => {
        setSelectedRowsKeys(selectedRowKeys);
        setSelectedItems(items);
    };

    const onSwitchChange = (checked, event) => {
        const shiftId = event.target.id;
        if (checked && shiftId) {
            return setRemoveShifts(previous => [...previous, +shiftId]);
        }
        return setRemoveShifts(previous => without(previous, +shiftId));
    };

    const renderTop = () => {
        const { application } = applications;
        const shiftIds = selectedItems.map(item => item.shift_id);

        let confirmText = `Are you sure you want to remove ${shiftIds.length} shifts from this booking?`;

        if (removeShifts.length) {
            // eslint-disable-next-line max-len
            confirmText = `${confirmText.substring(0, confirmText.length - 1)} and also delete ${removeShifts.length} shifts entirely?`;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 10,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        paddingBottom: 15,
                    }}
                >
                    <Popconfirm
                        cancelText='No'
                        okText='Yes'
                        // onCancel={cancel}
                        title={confirmText}
                        onConfirm={() => {
                            removeShiftsFromBookedApplication(
                                data.bookingId, shiftIds,
                            );
                            setSelectedRowsKeys([]);
                            setSelectedItems([]);
                        }}
                    >
                        <Button
                            disabled={!selectedItems.length}
                            style={{
                                marginLeft: 10,
                                textTransform: 'capitalize',
                            }}
                            type='primary'
                        >
                            Remove
                        </Button>
                    </Popconfirm>
                    <strong style={{ marginLeft: 10 }}>
                        {selectedItems.length}
                        {' '}
                        shifts selected
                    </strong>
                </div>

                <Link
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingBottom: 15,
                    }}
                    to={`/employers/${application.employer.id}`}
                >
                    <Avatar size={60} src={application.employer.avatar} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 10,
                        }}
                    >
                        <strong style={{ fontSize: 18 }}>
                            {application.employer.name}
                            {' '}
                        </strong>
                        <span style={{ color: '#999' }}>
                            {application.employer.address.city}
                            ,
                            {' '}
                            {countyName(application.employer.county_id)}
                        </span>
                    </div>
                </Link>
            </div>
        );
    };

    const renderTable = () => {
        const {
            applicationIsFetching,
            application,
            removingShiftsFromBookedApplication,
        } = applications;
        const { isDeletingShifts } = shifts;

        const columns = [
            {
                title: 'Delete shift?',
                dataIndex: '',
                key: 'delete',
                render: (text, record) => {
                    const disabled = (record.user_invoice_id || record.invoice_id) || selectedItems.indexOf(record) === -1;
                    return (
                        <Switch
                            checked={removeShifts.indexOf(record.shift_id) !== -1}
                            disabled={disabled}
                            id={record.shift_id}
                            onChange={onSwitchChange}
                        />
                    );
                },
            },
            {
                title: 'Shift ID',
                dataIndex: '',
                key: 'id',
                render: (_, record) => (
                    <Link
                        to={`/employers/${application.employer.id}/shifts/${moment.unix(record.start_time).startOf('month').unix()}/${record.shift_id}`}
                    >
                        {record.shift_id}
                    </Link>
                ),
            },
            {
                title: 'Date',
                dataIndex: 'start_time',
                key: 'date',
                render: text => moment.unix(text).format('YYYY-MM-DD'),
            },
            {
                title: 'Time',
                dataIndex: '',
                key: 'time',
                render: shift => (
                    `${moment.unix(shift.start_time).format('HH:mm')} - ${moment.unix(shift.end_time).format('HH:mm')}`
                ),
            },
            {
                title: 'Break',
                dataIndex: 'shift_break',
                key: 'break',
                render: shift_break => humanReadableHours(shift_break),
            },
            {
                title: 'Salary',
                dataIndex: '',
                key: 'salary',
                render: shift => (
                    <div>
                        {Math.round(shift.duration * shift.salary)}
                        {' '}
                        /
                        {Math.round(shift.duration * shift.price)}
                    </div>
                ),
            },
            {
                title: 'Payment status',
                dataIndex: '',
                key: 'payment_status',
                render: shift => {
                    if (shift.user_invoice_id || shift.invoice_id) {
                        return <strong style={{ color: '#d9534f' }}>PAID</strong>;
                    }

                    return <strong style={{}}>NOT PAID</strong>;
                },
            },

        ];

        const rowSelection = {
            selectedRowsKeys,
            onChange: onSelectChange,
            getCheckboxProps: record => ({ disabled: record.user_invoice_id || record.invoice_id }),
        };

        return (
            <Table
                columns={columns}
                dataSource={application.scheduled}
                loading={applicationIsFetching || removingShiftsFromBookedApplication || isDeletingShifts}
                pagination={false}
                rowKey={record => record.id}
                rowSelection={rowSelection}
            />
        );
    };

    const renderContent = () => {
        const {
            applicationIsFetching, application,
        } = applications;

        if (applicationIsFetching || !application) {
            return (
                <div
                    style={{ textAlign: 'center' }}
                >
                    <Spin />
                </div>
            );
        }

        return (
            <div>
                {renderTop()}
                {renderTable()}
            </div>
        );
    };

    return (
        <Modal
            footer={null}
            title='Show / edit booking'
            visible={visible}
            width={900}
            onCancel={close}
        >
            {renderContent()}
        </Modal>
    );
};

EditBookingModal.propTypes = {
    applications: PropTypes.shape({
        application: PropTypes.shape({
            employer: PropTypes.shape({
                id: PropTypes.number,
                avatar: PropTypes.string,
                name: PropTypes.string,
                address: PropTypes.shape({
                    city: PropTypes.string,
                }),
                county_id: PropTypes.number,

            }),
            scheduled: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        applicationIsFetching: PropTypes.bool,
        removingShiftsFromBookedApplication: PropTypes.bool,
    }),
    shifts: PropTypes.shape({ isDeletingShifts: PropTypes.bool }),
    data: PropTypes.shape({ bookingId: PropTypes.number }),
    visible: PropTypes.bool,
    fetchApplication: PropTypes.func.isRequired,
    removeEmployerShifts: PropTypes.func.isRequired,
    removeShiftsFromBookedApplication: PropTypes.func.isRequired,
    resetApplication: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default connect(
    state => state,
    dispatch => bindActionCreators(
        {
            removeEmployerShifts,
            fetchApplication,
            removeShiftsFromBookedApplication,
            resetApplication,
        },
        dispatch,
    ),
)(EditBookingModal);
