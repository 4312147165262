import React, {
    useEffect,
    useState,
} from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import LoadingScreen from 'components/shared/loadingscreen';
import {
    fetchBenefits,
    createBenefit,
    updateBenefit,
    requestFetchBenefits,
    sortBenefits,
} from '../../actions/benefits';
import BenefitsList from './BenefitsList';
import Button from '../../components/shared/button';
import BenefitsModal from '../../components/benefitsModal';

const Benefits = () => {
    const dispatch = useDispatch();
    const {
        benefits,
        isFetching,
    } = useSelector(state => state.benefits);

    const totalCount = benefits.length;
    const [listParams, setListParams] = useState({
        page: 1,
        sortField: 'company',
        direction: 'asc',
    });

    const [benefitFilters, setBenefitFilters] = useState({ all: true });
    const [modalConfig, setModalConfig] = useState({
        isVisible: false,
        benefit: null,
        mode: 'new',
    });

    useEffect(() => {
        dispatch(fetchBenefits(benefitFilters));
    }, [dispatch, benefitFilters]);

    useEffect(() => {
        dispatch(sortBenefits(listParams));
    }, [listParams, dispatch]);

    const onSave = data => {
        dispatch(requestFetchBenefits());
        const action = () => (data.promotion_id ? updateBenefit(data) : createBenefit(data));
        action().then(() => {
            dispatch(fetchBenefits(benefitFilters));
            setModalConfig({
                mode: 'new',
                isVisible: false,
                benefit: null,
            });
        }).catch(err => { console.log(err); });
    };

    const onTableChange = (pagination, _, sorter) => {
        const {
            sortField,
            sortOrder,
        } = listParams;
        if (sortField !== sorter.field || sortOrder !== sorter.order) {
            setListParams({
                page: 1,
                sortField: sorter.field,
                sortOrder: sorter.order,
            });
        }
        else {
            setListParams({
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
            });
        }
    };

    const initTableRow = record => ({ onClick: e => {
        if (e.target.toString() === '[object HTMLDivElement]') {
            const { isVisible } = modalConfig;
            setModalConfig({
                mode: record ? 'edit' : 'new',
                isVisible: !isVisible,
                benefit: record,
            });
        }
    } });

    if (isFetching) {
        return (
            <LoadingScreen
                fullScreen
                text='Here we are now, entertain us'
            />
        );
    }

    return (
        <main className='mpa-calendar row justify-content-between'>
            <div className='col-md-12'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <h2 style={{ margin: 0 }}>Benefits</h2>
                    <Button
                        style={{ margin: 0 }}
                        onClick={() => setModalConfig({
                            mode: 'new',
                            isVisible: true,
                            benefit: null,
                        })}
                    >
                        Add benefit
                    </Button>
                </div>
            </div>
            <div className='col-md-12 content-card-full' style={{ marginTop: 50 }}>
                <BenefitsList
                    benefits={benefits}
                    pagination={{
                        page: listParams.page,
                        totalCount,
                        totalPages: Math.ceil(totalCount / listParams.page),
                    }}
                    onChange={onTableChange}
                    onRow={initTableRow}
                />
            </div>
            {modalConfig.isVisible && (
                <BenefitsModal
                    modalConfig={modalConfig}
                    onClose={() => setModalConfig({
                        benefit: null,
                        mode: 'new',
                        isVisible: false,
                    })}
                    onSave={onSave}
                />
            )}
        </main>
    );
};

export default Benefits;
