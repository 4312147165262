import React from 'react';
import { Modal } from 'antd';

const AcceptJobApplicationModal = ({
    onConfirm,
    visible,
    setVisibility,
    applicant,
}) => (
    <Modal
        okText='Accept'
        okType='primary'
        title='Accept applicant'
        visible={visible}
        onCancel={() => setVisibility(false)}
        onOk={onConfirm}
    >
        <p>
            Do you want to accept
            {' '}
            <strong>
                {applicant.firstname}
                {' '}
                {applicant.lastname}
                {' '}
            </strong>
            for this recruitment?
        </p>
    </Modal>
);

export default AcceptJobApplicationModal;
