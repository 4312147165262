import * as request from 'request';
import { message } from 'antd';

const receiveAdminUserFileUploads = uploads => ({
    type: 'RECEIVE_FETCH_CONSULTANT_UPLOADS',
    uploads,
});

const receiveAdminUserFileUpload = upload => ({
    type: 'RECEIVE_UPDATE_CONSULTANT_UPLOAD',
    upload,
});

const receiveRemoveUserFileUpload = uploadId => ({
    type: 'RECEIVE_REMOVE_CONSULTANT_UPLOAD',
    uploadId,
});

export const fetchAdminUserFileUploads = userId => async dispatch => {
    try {
        const resp = await request.fetchAdminUserFileUploads(userId);
        dispatch(receiveAdminUserFileUploads(resp.collection));
    }
    catch (e) {
        message.error('Failed to fetch consultant uploads');
        throw e;
    }
};

export const updateAdminUserFileUpload = (formData, userId) => async dispatch => {
    try {
        const upload = await request.updateAdminUserFileUpload(formData, userId);
        dispatch(receiveAdminUserFileUpload(upload));
    }
    catch (error) {
        message.error('Failed to update consultant upload');
        throw error;
    }
};

export const deleteAdminUserFileUpload = (userId, uploadId) => async dispatch => {
    try {
        await request.deleteAdminUserFileUpload(userId, uploadId);
        dispatch(receiveRemoveUserFileUpload(uploadId));
    }
    catch (error) {
        message.error('Failed to delete upload');
        throw error;
    }
};

export const requestAdminUserFileUploadURL = (userId, formData, contentLength, contentType) => request.requestAdminUserFileUploadURL(userId, formData, contentLength, contentType);
export const uploadFileToBucket = (blobFile, uploadUrl, method) => request.uploadFileToBucket(blobFile, uploadUrl, method);
