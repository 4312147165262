import React from 'react';
import ReactSlider from 'react-slider';
import './styles.scss';

export default class Slider extends React.Component {
    static defaultProps = {
        min: 0,
        max: 100,
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactSlider
                className='mp-slider'
                defaultValue={0}
                max={this.props.max}
                min={this.props.min}
                withBars
                onChange={value => {
                    this.props.onChange && this.props.onChange(value);
                }}
            />
        );
    }
}
