import * as employerActions from 'actions/employer';
import {
    Table,
    Tag,
    Tooltip,
} from 'antd';
import Avatar from 'components/shared/avatar';
import config from 'config';
import {
    find,
    sortBy,
} from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import classNames from 'utils/classnames';
import { humanReadableHours } from 'utils/datetimeformatters';
import { skillTitle } from 'utils/nameformatters';

import './styles.scss';

class EmployerShiftsMonth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeletedMessage: false,
            collapsed: moment.unix(this.props.dateKey).isBefore(moment().startOf('day').startOf('month')),
        };

        this.removeShift = this.removeShift.bind(this);
    }

    toggleCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed,
            showRemoveShiftModal: false,
        });
    }

    removeShift(employerId, shiftId) {
        return this.props.removeEmployerShift(employerId, shiftId);
    }

    renderAvatars(consultants) {
        const avatars = consultants.map(consultant => {
            const user = find(this.props.employer.allConsultants.collection, c => c.user.id == consultant.user.id);

            return (
                <div className='mpa-employer-shift__booking' style={{ zIndex: 20 }}>
                    <Avatar
                        gender={user ? user.user.gender : 1}
                        size={30}
                        style={{ marginRight: 3 }}
                        url={user ? user.user.avatar : null}
                    />
                </div>
            );
        });

        return (
            <div style={{ display: 'flex' }}>
                {avatars}
            </div>
        );
    }

    render() {
        const {
            days,
        } = this.props;
        const { profiles } = this.props.employer.profiles;
        const { shiftStatus } = config;
        const columns = [{
            title: 'Week',
            name: 'week',
            key: 'date',
            dataIndex: 'start_time',
            render: text => (
                <div>
                    <strong
                        style={{
                            textTransform: 'lowercase', opacity: 0.8,
                        }}
                    >
                        v.
                        {moment.unix(text).isoWeek()}
                        {' '}

                    </strong>
                </div>
            ),
        },
        {
            title: 'Date',
            name: 'date',
            key: 'start_time',
            dataIndex: 'start_time',
            render: start_time => moment.unix(start_time).format('YYYY-MM-DD ddd'),
        },
        {
            title: 'Profile',
            name: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: profile => {
                const profileInfo = find(profiles, { id: profile.id });
                let skills = '';

                if (profileInfo) {
                    const skillList = profile.skills.map(skill => {
                        if (!skills.length) {
                            skills = `${skillTitle(skill, profileInfo.occupation_id)}`;
                        }
                        else {
                            skills = `${skills}, ${skillTitle(skill, profileInfo.occupation_id)}`;
                        }
                    });
                }

                return (
                    <Tooltip
                        mouseEnterDelay={0.1}
                        overlayStyle={{ maxWidth: 250 }}
                        placement='bottom'
                        title={(
                            <span>
                                Körkort efterfrågas:
                                {' '}
                                {profileInfo && profileInfo.driver_license ? 'Ja' : 'Nej'}
                                <br />
                                {skills && `Efterfrågad kunskap: ${skills}`}
                            </span>
                        )}
                        trigger={['hover']}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <small>
                                {profileInfo && profileInfo.name}
                            </small>
                            {(profileInfo && profileInfo.is_long_term) ? <Tag style={{ marginLeft: 5 }}>LTV</Tag> : null}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Duration',
            name: 'startTime',
            key: 'startTime',
            dataIndex: 'start_time',
            render: (start_time, record) => (
                <div>
                    {moment.unix(start_time).format('HH:mm')}
                    {' '}
                    -
                    {moment.unix(record.end_time).format('HH:mm')}
                    <br />
                    (
                    {humanReadableHours(record.duration)}
                    )
                </div>
            ),
        },
        {
            title: 'Break',
            name: 'break',
            key: 'shiftBreak',
            dataIndex: 'shift_break',
            render: (shift_break, record) => humanReadableHours(shift_break),
        },
        {
            title: 'Last application date',
            name: 'lastApplicationDate',
            key: 'lastApplicationDate',
            dataIndex: 'last_application_datetime',
            render: (last_application_datetime, record) => moment.unix(last_application_datetime).format('YYYY-MM-DD'),
        },
        {
            title: 'Price',
            name: 'price',
            key: 'price',
            dataIndex: 'price',
            render: (price, record) => price * (record.duration - record.shift_break),

        },
        {
            title: 'Compensation',
            name: 'salary',
            key: 'salary',
            dataIndex: 'salary',
            render: (salary, record) => (
                <div>
                    <div>
                        Lön:
                        {' '}
                        {salary * (record.duration - record.shift_break)}
                    </div>
                    <div>
                        Pension:
                        {' '}
                        {record.scheduled.length ? (
                            record.scheduled[0].has_pension ? (record.scheduled[0].pension * (record.scheduled[0].duration - record.scheduled[0].shift_break)) : '-'
                        ) : (
                            record.pension ? (record.pension * (record.duration - record.shift_break)) : '-'
                        )}

                    </div>
                    <div>
                        Egen:
                        {' '}
                        {record.consultant_fee * (record.duration - record.shift_break)}
                    </div>
                </div>
            ),
        },
        {
            title: 'Applications',
            name: 'applications',
            key: 'applications',
            dataIndex: 'applications',
            render: (applications, record) => (applications.length ? this.renderAvatars(applications) : '-'),
        },
        {
            title: 'Bookings',
            name: 'bookings',
            key: 'bookings',
            dataIndex: 'scheduled',
            render: (scheduled, record) => (scheduled.length ? this.renderAvatars(scheduled) : '-'),
        },
        {
            title: 'Shift ID',
            name: 'id',
            key: 'id',
            dataIndex: 'id',
        }];

        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={sortBy(days, 'start_time')}
                    pagination={false}
                    rowClassName={(record, index) => classNames({
                        'table-row2': true,
                        'table-row-shift': true,
                        'table-row-shift--active': this.props.activeShiftId === record.id,
                        // 'table-row-grouped': (day.length > 1) && (index !== 0),
                        'table-row--canceled_by_employer': record.shift_status === shiftStatus.CANCEL_BY_EMPLOYER,
                        'table-row--canceled_by_admin': record.shift_status === shiftStatus.CANCEL_BY_ADMIN,
                        'table-row--booked': record.scheduled.length && record.shift_status !== shiftStatus.SICK_LEAVE && !record.scheduled[0].is_paid,
                        'table-row--paid': record.scheduled.length && record.scheduled[0].is_paid,
                        'table-row--consultant-is-sick': record.shift_status == shiftStatus.SICK_LEAVE,
                    })}
                    scroll={{ x: 1200 }}
                    size='middle'
                    style={{ padding: 10 }}
                    onRow={record => ({ onClick: () => {
                        if (parseInt(this.props.match.params.shiftId, 10) === record.id) {
                            this.props.history.push(`/employers/${this.props.match.params.id}/shifts/${this.props.activeKey}`);
                        }
                        else {
                            this.props.history.push(`/employers/${this.props.match.params.id}/shifts/${this.props.activeKey}/${record.id}`);
                        }
                    } })}
                />

            </div>

        );
    }
}

export default withRouter(connect(
    state => state,
    dispatch => bindActionCreators({ ...employerActions }, dispatch),
)(EmployerShiftsMonth));
