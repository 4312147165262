const initialState = {
    allowed_countries: [],
    occupations: [],
    skills: [],
    emr_systems: [],
    user_marketing_sources: [],
    intentions: [],
    counties: [],
    secondary_department_types: [],
    job_posting_shift_types: [],
    products: [],
    job_posting_salaries: [],
    job_posting_reject_reasons: [],
    cancellation_reasons: [],
    user_state: [{
        value: 1, name: 'Pending',
    }, {
        value: 2, name: 'Activated',
    }, {
        value: 3, name: 'Removed',
    }],
    marginOptions: [],
    reject_reasons: [],
    hasFetchedSettings: false,
};

const settings = (state = initialState, action) => {
    switch (action.type) {
        case 'RECEIVE_FETCH_SETTINGS':
            return {
                ...state,
                allowed_countries: action.settings.allowed_countries,
                languages: action.settings.languages,
                products: action.settings.products,
                occupations: action.settings.occupations,
                skills: action.settings.skills,
                specializations: action.settings.specializations,
                emr_systems: action.settings.emr_systems,
                intentions: action.settings.intentions,
                counties: action.settings.counties,
                user_marketing_sources: action.settings.user_marketing_sources,
                secondary_department_types: action.settings.secondary_department_types,
                reject_reasons: action.settings.reject_reasons,
                job_posting_engagements: action.settings.job_posting_engagements,
                job_posting_shift_types: action.settings.job_posting_shift_types,
                job_posting_salaries: action.settings.job_posting_salaries,
                job_posting_reject_reasons: action.settings.job_posting_reject_reasons,
                cancellation_reasons: action.settings.cancellation_reasons,
                hasFetchedSettings: true,
            };

        case 'RECEIVE_FETCH_EMPLOYER_MARGINS':
            return {
                ...state, marginOptions: action.marginOptions,
            };

        default:
            return state;
    }
};

export default settings;
