import * as request from 'request';

export function receiveToken(token) {
    return {
        type: 'RECEIVE_TOKEN',
        token,
    };
}

export function receiveUser(user) {
    return {
        type: 'RECEIVE_USER',
        user,
    };
}

export function login(username, password) {
    return dispatch => request.login(username, password)
        .then(response => dispatch(receiveToken(response.token)));
}

export function getUser() {
    return dispatch => request.getUser()
        .then(response => {
            dispatch(receiveUser(response));
        });
}
