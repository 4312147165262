import * as request from 'request';
import { message } from 'antd';

export const receiveEmployerAttests = resp => ({
    type: 'RECEIVE_EMPLOYER_ATTESTS',
    employer_attests: resp.collection,
});

const requestBatchReportScheduled = () => ({ type: 'REQUEST_BATCH_REPORT_SCHEDULED' });
const receieveBatchReportScheduled = () => ({ type: 'RECEIVE_BATCH_REPORT_SCHEDULED' });

export const fetchEmployerAttests = () => dispatch => request.fetchEmployerAttests()
    .then(resp => dispatch(receiveEmployerAttests(resp)));

export const batchReportScheduled = scheduledData => async dispatch => {
    dispatch(requestBatchReportScheduled());
    try {
        await request.batchUpdateScheduled(scheduledData);
        dispatch(fetchEmployerAttests());
        message.success('Batch report was completed');

        dispatch(receieveBatchReportScheduled());
    }
    catch (e) {
        message.error('Failed to report scheduled');
        throw e;
    }
};
