import moment from 'moment-timezone';

// Expects date to be moment and time to be 'HH:mm'
function timeStringToMoment(date, time) {
    const momentTime = date.clone();
    momentTime.startOf('day').hour(time.substr(0, 2)).minute(time.substr(3, 2));
    return momentTime;
}

export function startEndStringTimesToMoment(date, start, end) {
    const startTime = timeStringToMoment(date, start);
    const endTime = timeStringToMoment(date, end);
    if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
    }

    return {
        endTime,
        startTime,
    };
}

export function unixToTimeString(unixDate) {
    const date = moment.unix(unixDate);
    return date.format('HH:mm');
}

export function startEndTimeToDuration(startTime, endTime) {
    const start = moment(startTime * 1000).tz('Europe/Stockholm');
    const end = moment(endTime * 1000).tz('Europe/Stockholm');
    if (end.isBefore(start)) {
        end.add(1, 'day');
    }

    const DURATION = Math.abs(start.tz('Europe/Stockholm').diff(end.tz('Europe/Stockholm'), 'minutes') / 60);
    return DURATION;
}

export function humanReadableHours(hours) {
    if (hours === 0 || hours === null) {
        return '0 min';
    }
    const prefix = hours >= 0 ? '' : '-';
    hours = Math.abs(hours);
    const remainder = hours % 1;
    const h = hours >= 1 ? `${Math.floor(hours)} tim ` : '';
    const m = remainder ? `${(Math.round(remainder * 60))} min` : '';
    return prefix + h + m;
}

export const getFormattedDate = unixTimestamp => {
    const date = new Date(unixTimestamp * 1000);
    const formatOptions = {
        month: 'short', year: '2-digit',
    };

    return date.toLocaleString('en-GB', formatOptions);
};

export const getFormattedDateWithWeek = unixTimestamp => {
    const date = new Date(unixTimestamp * 1000);
    const formatOptions = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
    };

    const weekNumber = moment.unix(unixTimestamp).isoWeek();
    return `${date.toLocaleDateString('en-GB', formatOptions)} (v. ${weekNumber})`;
};

export function humanizedDurationFromNow(ts) {
    const createdTime = moment.unix(ts);
    return moment.duration(moment().diff(createdTime)).locale('sv').humanize();
}
