import React from 'react';
import './styles.scss';

export default class DeleteTrigger extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <a
                className='mpa-delete-trigger'
                href='#'
                style={({ ...this.props.style })}
                onClick={e => {
                    e.preventDefault();
                    this.props.onClick();
                }}
            >
                <span className='mpa-delete-trigger__icon'>
                    <span className='ion-trash-b' />
                </span>
                {' '}
                {this.props.title}
            </a>

        );
    }
}
