import React from 'react';
import { styles } from './styles.scss';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { marginTop: 100 };
        this.onContainerClick = this.onContainerClick.bind(this);
        this.onContentClick = this.onContentClick.bind(this);
    }

    componentDidMount() {
        const modalHeight = this.refs.modal.clientHeight;
        const windowHeight = window.innerHeight;
        // let marginTop = (windowHeight - modalHeight) / 2;
        /* this.setState({
            marginTop: marginTop,
        }); */
    }

    onContainerClick() {
        if (!this.props.preventOutsideClickClose && !this.props.preventClose) {
            this.props.close();
        }
    }

    onContentClick(event) {
        event.stopPropagation();
    }

    renderTitle() {
        if (this.props.hideTitle) { return; }
        return (
            <div
                className='mp-modal__title'
            >
                {this.props.title}
                {(!this.props.preventClose)
                    ? (
                        <div
                            className='mp-modal__close'
                            onClick={this.props.close}
                        >
                            <i className='ion-close-round' />
                        </div>
                    )
                    : null}
            </div>
        );
    }

    render() {
        return (
            <div
                className='mp-modal__container'
                onClick={this.onContainerClick}
            >
                <div
                    ref='modal'
                    className='mp-modal'
                    style={
                        ({
                            width: this.props.width,
                            margin: `${this.state.marginTop}px auto`,
                            ...this.props.style,
                        })
                    }
                    onClick={this.onContentClick}
                >
                    {this.renderTitle()}
                    {this.props.children}
                </div>
            </div>
        );
    }
}
