import React, { useMemo } from 'react';
import { Radio } from 'antd';

const RadioGroup = ({ data, onSetChange, value, className='' }) => {
  const renderRadioGroup = useMemo(() => data.map((item) =>  (<Radio key={item.value} value={item.value}>{item.label}</Radio>)), [data]);
  
  const onChangeHandler = (e) => {
    onSetChange(e.target.value);
  }
  return (
    <div className={className}>
      <Radio.Group onChange={onChangeHandler} value={value} defaultValue={value}>
        { renderRadioGroup }
      </Radio.Group>
    </div>
  );
}

export default RadioGroup;

