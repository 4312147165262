import React, {
    useState,
    useEffect,
} from 'react';
import {
    Modal,
    Form,
    Input,
    Button,
    Checkbox,
} from 'antd';
import PropTypes from 'prop-types';

const EditAdminUserModal = ({
    visible, setVisibility, user, onSave,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        }
    }, [user]);

    const handleSave = () => {
        form.validateFields()
            .then(values => {
                onSave(values);
                setVisibility(false);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            footer={[
                <Button key='back' onClick={() => setVisibility(false)}>
                    Cancel
                </Button>,
                <Button key='submit' type='primary' onClick={handleSave}>
                    Save
                </Button>,
            ]}
            title='Edit Admin User'
            visible={visible}
            onCancel={() => setVisibility(false)}
        >
            <Form form={form} layout='vertical'>
                <Form.Item
                    label='User Name'
                    name='username'
                    rules={[{
                        required: true, message: 'Please input the user name!',
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Email'
                    name='email'
                    rules={[{
                        required: true, message: 'Please input the email!',
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Super Admin'
                    name='super_admin_user'
                    valuePropName='checked'
                >
                    <Checkbox>Is Super Admin</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};

EditAdminUserModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
    user: PropTypes.object,
    onSave: PropTypes.func.isRequired,
};

export default EditAdminUserModal;
