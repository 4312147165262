import React, {
    useState,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import { withFormsy } from 'formsy-react';
import Label from 'components/shared/label';
import './styles.scss';

const Input = ({
    autoFocus,
    block,
    errorMessage,
    reformatOnBlur,
    value,
    label,
    errorMessageStyle,
    wrapperClass,
    wrapperStyle,
    disabled,
    name,
    className,
    placeholder,
    type,
    onBlur,
    onChange,
    onFocus,
    setValue,
    inline,
    required,
}) => {
    const [hasFocused, setHasFocused] = useState(false);
    const [hasError, setHasError] = useState(false);
    const inputEl = useRef(null);

    const changeValue = event => {
        const newValue = event.currentTarget.value;
        setValue(newValue);
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnBlur = () => {
        const error = errorMessage;

        if (error) {
            setHasError(true);
        }

        if (reformatOnBlur) {
            setValue(reformatOnBlur(value));
        }
        if (onBlur) {
            onBlur();
        }
    };

    const handleOnFocus = () => {
        setHasFocused(true);
        setHasError(false);
        if (onFocus) {
            onFocus();
        }
    };

    const renderError = () => {
        const error = errorMessage;
        if (error && hasFocused) {
            return (
                <div className='mp-input__error'>
                    {error}
                </div>
            );
        }
        return <></>;
    };

    const renderLabel = () => {
        if (label) {
            return (
                <Label
                    {...{
                        inline,
                        label,
                        required,
                    }}
                />
            );
        }
        return <></>;
    };

    const focus = () => {
        inputEl.focus();
    };
    if (autoFocus) {
        focus();
    }

    return (
        <div
            className={classNames({
                'mp-input__wrapper': true,
                'mp-input__wrapper--block': block,
                'mp-input__wrapper--error': hasError,
                [`mp-input__wrapper-error-message-style-${errorMessageStyle}`]: true,
                [wrapperClass]: true,
            })}
            style={wrapperStyle}
        >
            {renderLabel()}
            <input
                ref={inputEl}
                className={classNames({
                    'mp-input': true,
                    [className]: true,
                })}
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                type={type}
                value={value}
                onBlur={handleOnBlur}
                onChange={changeValue}
                onFocus={handleOnFocus}
            />
            {renderError()}
        </div>
    );
};

Input.propTypes = {
    autoFocus: PropTypes.bool,
    block: PropTypes.bool,
    errorMessage: PropTypes.string,
    reformatOnBlur: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    errorMessageStyle: PropTypes.string,
    wrapperClass: PropTypes.string,
    wrapperStyle: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    setValue: PropTypes.func,
    inline: PropTypes.string,
    required: PropTypes.bool,
};

Input.defaultProps = {
    className: '',
    wrapperClass: '',
    type: 'text',
    defaultValue: '',
    placeholder: '',
    required: false,
    validations: {},
    validationErrors: {},
    label: '',
    block: false,
    onBlur: null,
    onFocus: null,
    wrapperStyle: {},
    errorMessageStyle: 'hint', // 'hint' or 'error' is available
};

export default withFormsy(Input);
