import * as request from 'request';

export function receiveSickLeavedConsultants(resp) {
    return {
        type: 'RECEIVE_SICK_LEAVED_CONSULTANTS',
        collection: resp.collection,
    };
}

export function fetchSickLeavedConsultants() {
    return dispatch => request.fetchSickLeavedConsultants()
        .then(resp => dispatch(receiveSickLeavedConsultants(resp)));
}

export function requestSickLeavedConsultant() {
    return { type: 'REQUEST_SICK_LEAVED_CONSULTANT' };
}

export function receiveSickLeavedConsultant(resp) {
    return {
        type: 'RECEIVE_SICK_LEAVED_CONSULTANT',
        sickLeavedConsultant: resp.collection,
    };
}

export function fetchSickLeavedConsultant(consultantId) {
    return dispatch => {
        dispatch(requestSickLeavedConsultant());

        return request.fetchSickLeavedConsultant(consultantId)
            .then(resp => dispatch(receiveSickLeavedConsultant(resp)));
    };
}
