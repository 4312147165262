import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { occupationTitle } from 'utils/nameformatters';
import Avatar from 'components/shared/avatar';
import TagsList from 'components/tagslist';

const Consultant = ({ consultant: {
    avatar,
    firstname,
    id,
    lastname,
    occupation,
    phone,
    tags,
    student,
    graduation_time,
} }) => (
    <>
        <div className='consultant'>
            <Avatar className='consultant__avatar' size={45} url={avatar} />
            <div>
                <Link className='consultant__name' to={`/consultants/${id}`}>
                    {firstname && lastname ? `${firstname} ${lastname}` : '–'}
                </Link>
                <div className='consultant__occupation'>
                    {occupationTitle(occupation)}
                    {student && <span className='consultant__occupation--student'>(Student graduation date {moment.unix(graduation_time).format('YYYY-MM-DD')})</span>}
                </div>
                <a className='consultant__phone' href={`tel:${phone}`}>{phone}</a>
            </div>
        </div>

        <TagsList consultantId={id} small tagsList={tags} />
    </>
);

Consultant.propTypes = { consultant: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    occupation: PropTypes.number,
    phone: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.number),
}) };

export default Consultant;
