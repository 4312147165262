import {
    message,
    notification,
} from 'antd';
import { groupBy } from 'lodash';
import * as request from 'request';

const receiveEmployerProfiles = resp => ({
    type: 'RECEIVE_EMPLOYER_PROFILES',
    profiles: resp,
});

export const fetchEmployerProfiles = employerId => dispatch => request.fetchEmployerProfiles(employerId)
    .then(resp => dispatch(receiveEmployerProfiles(resp)));

export const receiveEmployerUsers = resp => ({
    type: 'RECEIVE_EMPLOYER_USERS',
    users: resp.collection,
});

export const fetchEmployerUsers = employerId => dispatch => request.fetchEmployerUsers(employerId)
    .then(resp => dispatch(receiveEmployerUsers(resp)));

export const receiveEmployerJobads = resp => ({
    type: 'RECEIVE_EMPLOYER_JOBADS',
    job_postings: resp.collection,
});

export const fetchEmployerJobads = employerId => dispatch => request.fetchEmployerJobads(employerId)
    .then(resp => dispatch(receiveEmployerJobads(resp)));

export const receiveEmployer = resp => ({
    type: 'RECEIVE_EMPLOYER',
    employer: resp.employer,
});

const requestFetchEmployer = () => ({ type: 'c' });

export const fetchEmployer = employerId => dispatch => {
    dispatch(requestFetchEmployer());

    return request.fetchEmployer(employerId)
        .then(resp => dispatch(receiveEmployer(resp)));
};

const receiveInvoiceEmployer = resp => ({
    type: 'RECEIVE_INVOICE_EMPLOYER',
    employer: resp.employer,
});

export const clearInvoiceEmployer = () => ({ type: 'CLEAR_INVOICE_EMPLOYER' });

export const fetchInvoiceEmployer = employerId => dispatch => request.fetchEmployer(employerId)
    .then(resp => dispatch(receiveInvoiceEmployer(resp)));

export const requestUpdateEmployer = () => ({ type: 'REQUEST_UPDATE_EMPLOYER' });

export const receiveUpdateEmployer = data => ({
    type: 'RECEIVE_UPDATE_EMPLOYER',
    data,
});

export const updateEmployer = (employerId, data) => dispatch => request.updateEmployer(employerId, data)
    .then(() => dispatch(receiveUpdateEmployer(data)));

export const updateEmployerStatus = (employerId, status) => dispatch => {
    dispatch(updateEmployer(employerId, { state: status })).then(res => {
        request.activateEmployer(employerId).then(resp => resp);
    });
};

export const requestUpdateCustomShift = () => ({ type: 'REQUEST_UPDATE_CUSTOM_SHIFT' });

export const receiveUpdateCustomShift = (employerId, id, data) => ({
    type: 'RECEIVE_UPDATE_CUSTOM_SHIFT',
    id,
    employerId,
    data,
});

export const updateCustomShift = (employerId, id, data) => dispatch => {
    dispatch(requestUpdateCustomShift());

    return request.updateCustomShift(employerId, id, data)
        .then(() => {
            dispatch(receiveUpdateCustomShift(employerId, id, data));
            message.success('Working hours have been updated');
        });
};

export const requestCreateCustomShift = () => ({ type: 'REQUEST_CREATE_CUSTOM_SHIFT' });

export const receiveCreateCustomShift = (employerId, data) => ({
    type: 'RECEIVE_CREATE_CUSTOM_SHIFT',
    employerId,
    data,
});

export const createCustomShift = (employerId, data) => dispatch => {
    dispatch(requestCreateCustomShift());

    return request.createCustomShift(employerId, data)
        .then(resp => {
            dispatch(receiveCreateCustomShift(employerId, resp.shift));
            message.success('New working hours have been created');
        },
        error => {
            notification.error({
                message: 'Failed to create shifts',
                description: `Error messages: "${error.responseJSON.message}"`,
            });
        });
};

export const requestUpdateJobProfile = () => ({ type: 'REQUEST_UPDATE_JOB_PROFILE' });

export const receiveUpdateJobProfile = (profileId, data) => ({
    type: 'RECEIVE_UPDATE_JOB_PROFILE',
    profileId,
    data,
});

export const updateJobProfile = (profileId, data) => dispatch => {
    dispatch(requestUpdateJobProfile());

    return request.updateJobProfile(profileId, data)
        .then(() => dispatch(receiveUpdateJobProfile(profileId, data)));
};

export const recieveEmployersAllConsultants = resp => ({
    type: 'RECEIVE_EMPLOYERS_ALL_CONSULTANTS',
    allConsultants: resp,
});

export const fetchEmployersAllConsultants = id => dispatch => request.fetchEmployersAllConsultants(id)
    .then(resp => dispatch(recieveEmployersAllConsultants(resp)));

export const requestDeleteEmployerShift = () => ({ type: 'REQUEST_DELETE_EMPLOYER_SHIFT' });

export const receiveDeleteEmployerShift = shiftId => ({
    type: 'RECEIVE_DELETE_EMPLOYER_SHIFT',
    shift: shiftId,
});

export const removeEmployerShift = (employerId, shiftId) => dispatch => {
    dispatch(requestDeleteEmployerShift());

    return request.employerDeleteShifts(employerId, { shift_ids: [shiftId] }).then(() => {
        message.success(`Shift: ${shiftId} has been removed`);
        dispatch(receiveDeleteEmployerShift(shiftId));
    }, error => {
        message.error(error.responseJSON.message);
    });
};

export const requestDeleteEmployerShifts = () => ({ type: 'REQUEST_DELETE_EMPLOYER_SHIFTS' });

export const receiveDeleteEmployerShifts = shifts => ({
    type: 'RECEIVE_DELETE_EMPLOYER_SHIFTS',
    shifts,
});

export const removeEmployerShifts = (employerId, shifts) => dispatch => {
    dispatch(requestDeleteEmployerShifts());

    return request.employerDeleteShifts(employerId, { shift_ids: shifts }).then(() => {
        message.success(`${shifts.length} shifts have been removed`);
        dispatch(receiveDeleteEmployerShifts(shifts));
    }, error => {
        message.error(error.responseJSON.message);
    });
};

export const requestUpdateEmployerContact = () => ({ type: 'REQUEST_UPDATE_EMPLOYER_CONTACT' });

export const receiveUpdateEmployerContact = (id, data) => ({
    type: 'RECEIVE_UPDATE_EMPLOYER_CONTACT',
    updateContactId: id,
    updateData: data,
});

export const updateEmployerContact = (employerId, contactId, data) => dispatch => {
    dispatch(requestUpdateEmployerContact());

    return request.updateEmployerContact(employerId, contactId, data)
        .then(() => {
            message.success('Contactperson has been updated');
            dispatch(receiveUpdateEmployerContact(contactId, data));
        });
};

export const requestDeleteEmployerContact = () => ({ type: 'REQUEST_DELETE_EMPLOYER_CONTACT' });

export const receiveDeleteEmployerContact = contactId => ({
    type: 'RECEIVE_DELETE_EMPLOYER_CONTACT',
    contactId,
});

export const deleteEmployerContact = (employerId, contactId) => dispatch => {
    dispatch(requestDeleteEmployerContact());

    return request.deleteEmployerContact(employerId, contactId)
        .then(() => {
            message.success('Contactperson have been removed');
            dispatch(receiveDeleteEmployerContact(contactId));
        });
};

export const requestCreateEmployerContact = () => ({ type: 'REQUEST_CREATE_EMPLOYER_CONTACT' });

export const receiveCreateEmployerContact = newContact => ({
    type: 'RECEIVE_CREATE_EMPLOYER_CONTACT',
    newContact,
});

export const createEmployerContact = (employerId, data) => dispatch => {
    dispatch(requestCreateEmployerContact());

    return request.createEmployerContact(employerId, data)
        .then(resp => {
            dispatch(receiveCreateEmployerContact(resp.contact));
        });
};

export const requestUpdateEmployerCredential = () => ({ type: 'REQUEST_UPDATE_EMPLOYER_CREDENTIAL' });

export const receiveUpdateEmployerCredential = (id, data) => ({
    type: 'RECEIVE_UPDATE_EMPLOYER_CREDENTIAL',
    updateUserId: id,
    updateData: data,
});

export const updateEmployerCredential = (id, data) => {
    const requestData = {
        employer_user_id: id,
        password: data.password,
        name: data.name,
        username: data.username,
    };

    return dispatch => {
        dispatch(requestUpdateEmployerCredential());

        return request.updateEmployerCredential(id, requestData)
            .then(() => {
                message.success('Credential has been updated');
                dispatch(receiveUpdateEmployerCredential(id, requestData));
            }, error => {
                message.error(error.responseJSON.message);
            });
    };
};

export const requestDeleteEmployerCredential = () => ({ type: 'REQUEST_DELETE_EMPLOYER_CREDENTIAL' });

export const receiveDeleteEmployerCredential = id => ({
    type: 'RECEIVE_DELETE_EMPLOYER_CREDENTIAL',
    id,
});

export const deleteEmployerCredential = id => dispatch => {
    dispatch(requestDeleteEmployerCredential());

    return request.deleteEmployerCredential(id)
        .then(() => {
            message.success('Credential have been removed');
            dispatch(receiveDeleteEmployerCredential(id));
        }, error => {
            message.error(error.responseJSON.message);
        });
};

export const requestEmployerAddNewCredential = () => ({ type: 'REQUEST_EMPLOYER_ADD_NEW_CREDENTIAL' });

export const receiveEmployerAddNewCredential = newCredential => ({
    type: 'RECEIVE_EMPLOYER_ADD_NEW_CREDENTIAL',
    newCredential,
});

export const employerAddNewCredential = (employerId, data) => {
    const requestData = {
        employer_id: employerId,
        name: data.name,
        account_email: data.email,
        username: data.username,
    };

    return dispatch => {
        dispatch(requestEmployerAddNewCredential());

        return request.employerAddNewCredential(employerId, requestData)
            .then(user => {
                message.success('New Credential has been created');
                dispatch(receiveEmployerAddNewCredential(user));
            }, error => {
                message.error(error.responseJSON.message);
            });
    };
};

export const requestLogOutEmployer = () => ({ type: 'REQUEST_LOGOUT_EMPLOYER' });

export const receiveLogOutEmployer = () => ({ type: 'RECEIVE_LOGOUT_EMPLOYER' });

export const logOutEmployer = employerId => dispatch => {
    dispatch(requestLogOutEmployer());

    return request.logOutEmployer(employerId)
        .then(() => dispatch(receiveLogOutEmployer()));
};

const requestUploadAvatar = () => ({ type: 'REQUEST_UPLOAD_AVATAR' });

const receiveUploadAvatar = logoUrl => ({
    type: 'RECEIVE_UPLOAD_AVATAR',
    logoUrl,
});

export const uploadAvatar = (imageData, employerId) => {
    const contentLength = imageData.length;

    return dispatch => {
        dispatch(requestUploadAvatar());
        return request.requestUploadAvatarURL(contentLength, employerId)
            .then(urlResp => {
                const avatarUrl = urlResp.avatar_url;
                return request.uploadAvatar(urlResp.upload_url, contentLength, imageData)
                    .then(() => request.verifyImageUpload(urlResp.upload_id, employerId)
                        .then(() => request.setAvatar(urlResp.upload_id, employerId).then(() => {
                            dispatch(receiveUploadAvatar(avatarUrl));
                        })));
            });
    };
};

const requestUploadCover = () => ({ type: 'REQUEST_UPLOAD_COVER' });

const receiveUploadCover = coverUrl => ({
    type: 'RECEIVE_UPLOAD_COVER',
    coverUrl,
});

export const uploadCover = (imageData, employerId) => {
    const contentLength = imageData.length;

    return dispatch => {
        dispatch(requestUploadCover());
        return request.requestUploadCoverURL(contentLength, employerId)
            .then(urlResp => {
                const coverUrl = urlResp.avatar_url;
                return request.uploadCover(urlResp.upload_url, contentLength, imageData)

                    .then(() => request.verifyImageUpload(urlResp.upload_id, employerId)
                        .then(() => request.setCover(urlResp.upload_id, employerId).then(() => {
                            dispatch(receiveUploadCover(coverUrl));
                        })));
            });
    };
};

export const requestCheckShifts = () => ({ type: 'REQUEST_CHECK_SHIFTS' });

export const receiveCheckShifts = () => ({ type: 'RECEIVE_CHECK_SHIFTS' });

export const checkShifts = (employerId, consultantId, shiftsData) => dispatch => {
    dispatch(requestCheckShifts());

    const checkingShifts = shiftsData.map(shift => ({
        user_id: Number(consultantId),
        ...shift,
    }));

    return request.employerCheckShift(employerId, { shifts: checkingShifts })
        .then(resp => {
            dispatch(receiveCheckShifts());
            return resp;
        });
};

// eslint-disable-next-line arrow-body-style
export const createShift = (employerId, shiftData, notifications, orderId) => () => {
    // Comment line added for readability
    return request.employerCreateShift(employerId, {
        shifts: [shiftData],
        notifications,
        order_id: orderId, // Max 40 characters

    })
        .then(resp => resp)
        .catch(e => console.error('error creating shift', e));
};

export const requestCreateShifts = () => ({ type: 'REQUEST_CREATE_SHIFTS' });

export const receiveCreateShifts = () => ({ type: 'RECEIVE_CREATE_SHIFTS' });

export const createMultipleShifts = (employerId, shifts, notifications, orderId) => dispatch => {
    dispatch(requestCreateShifts());

    const req = request.employerCreateShift(employerId, {
        shifts,
        notifications,
        order_id: orderId,
    });

    req.then(() => {
        dispatch(receiveCreateShifts());
        message.success('Ah yeah! Shifts successfully published');
    });

    return req;
};

export const createAndAddMultipleShiftsToConsultant = (employerId, shifts, consultant, orderId) => () => {
    const shiftsGroupedByProfile = groupBy(shifts, 'profile_id');
    Promise.all(Object.keys(shiftsGroupedByProfile).map(async profileId => {
        try {
            const batchedShiftsToCreate = shiftsGroupedByProfile[profileId];

            const shiftResp = await request.employerCreateShift(employerId, {
                shifts: batchedShiftsToCreate,
                order_id: orderId,
            });
            const createdShifts = shiftResp.shifts;
            await request.createBookingForProfile(
                profileId,
                consultant,
                createdShifts.map(s => s.id),
            );
            message.success('Shifts created');
        }
        catch (e) {
            message.error('Failed to create pre booked shifts');
            throw e;
        }
    }));
};

export const cancelConsultantFromSchedule = data => () => new Promise((resolve, reject) => {
    request.cancelConsultantFromSchedule(data)
        .then(() => {
            message.success(`Schedule ${data.schedule_id} has been removed`);
            resolve();
        })
        .catch(error => {
            message.success(error.responseJSON.message, 5);
        });
});

export const assignConsultantToShift = (shiftId, profileId, consultantId, applicationStatus) => () => new Promise((resolve, reject) => {
    request.createBookingForProfile(profileId, consultantId, [shiftId], applicationStatus)
        .then(() => {
            message.success(`Shift ${shiftId} has been added`);
            resolve();
        })
        .catch(error => {
            message.error(error.responseJSON.message, 5);
            reject();
        });
});

export const createAndAddMultipleShiftsToLtv = (employerId, shifts, applicationId) => async () => {
    const shiftsGroupedByProfile = groupBy(shifts, 'profile_id');
    Promise.all(Object.keys(shiftsGroupedByProfile).map(async profileId => {
        try {
            const batchedShiftsToCreate = shiftsGroupedByProfile[profileId];

            const shiftResp = await request.employerCreateShift(employerId, { shifts: batchedShiftsToCreate });
            const createdShifts = shiftResp.shifts;
            await request.applicationAddShift(applicationId, {
                applicationId,
                shifts: createdShifts.map(s => s.id),
            });
            message.success('Shifts created');
        }
        catch (e) {
            message.error('Failed to create shifts');
            throw e;
        }
    }));
};

export const createAndAddShiftToConsultant = (profile_id, employer_id, start_time, duration, price, consultant_id, shift_break, margin, last_application_datetime, is_price_hidden) => dispatch => new Promise((resolve, reject) => {
    // 1 Create shift for employer
    request.employerCreateShift(employer_id, { shifts: [{
        status: 1,
        profile_id,
        employer_id,
        start_time,
        duration,
        price,
        shift_break,
        margin,
        last_application_datetime,
        is_price_hidden,
    }] }).then(resp => {
        const { shifts } = resp;

        // 2 Create a new booking with the consultant you want to use to replace
        // Needs user id and profile ID
        // 3 Add the shift we want the new consultant
        // to be booked on to the booking we just created
        // Needs the shift ID and the profile ID
        request.createBookingForProfile(profile_id, consultant_id, [shifts[0].id])
            .then(() => {
                resolve();
                message.success('Shifts created');
            }, error => {
                message.error(error.responseJSON.message, 5);
                reject();
            });
    });
});

export const requestReplaceShift = () => ({ type: 'REQUEST_REPLACE_SHIFT' });

export const receiveReplaceShift = () => ({ type: 'RECEIVE_REPLACE_SHIFT' });

export const replaceShift = (employerId, shiftIdToDelete, newShift) => async dispatch => {
    dispatch(requestReplaceShift());
    // Add new shift
    return dispatch(createShift(employerId, newShift, { notifications: true })).then(createdShift => {
        // And delete the old shift afterwards to not delete it if the creation fails
        dispatch(removeEmployerShift(employerId, shiftIdToDelete))
            .then(() => {
                dispatch(receiveReplaceShift());
            })
            .catch(e => console.error('there was an error replacing shift', e));
        return createdShift;
    });
};

export const replaceBookedShift = (bookingsIds, shiftIdToDelete, newShiftData, employerId) => async dispatch => {
    // 1 Remove booking from shift
    await Promise.all(
        bookingsIds.map(bookingId => request.applicationRemoveShifts(bookingId, { shift_ids: [shiftIdToDelete] })),
    );

    // 2 Replace shift
    const response = await dispatch(replaceShift(employerId, shiftIdToDelete, {
        ...newShiftData,
        status: 1, // we set it to published so it doesn't fail when booking the consultant back
    }));
    message.success('Shift has been successfully edited');

    return Promise.all(
        // 3 Add application to shift
        newShiftData.applicationsSummary.map(application => (
            dispatch(assignConsultantToShift(
                response.shifts[0].id,
                newShiftData.profile_id,
                application.consultantId,
                application.status,
            )))),
    );
};
