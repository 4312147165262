import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import { occupationTitle } from 'utils/nameformatters';
import './styles.scss';

import { fetchConsultants } from 'actions/consultants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    debounce,
    find,
} from 'lodash';
import {
    AutoComplete,
    Avatar,
} from 'antd';

const ReplaceConsultantModal = ({
    shift,
    employer,
    user,
    onClose,
    onConfirmReplace,
    fetchConsultants,
    consultants,
}) => {
    const [success, setSuccess] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedConsultant, setSelectedConsultant] = useState();
    useEffect(() => {
        if (isSearching) {
            setSearchResults(consultants);
        }
    }, [setSearchResults, consultants, isSearching]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const doSearch = useCallback(debounce(query => {
        setSelectedConsultant(null);
        setIsSearching(true);
        fetchConsultants({
            query,
            occupation: user.occupation,
        });
    }, 500), []);

    const renderSelectedConsultant = () => {
        if (!selectedConsultant) {
            return '';
        }

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '20px 0',
                }}
            >
                <div
                    style={{
                        width: 70,
                        height: 70,
                        borderRadius: 70,
                        background: '#EEE',
                        backgroundImage: `url(${selectedConsultant.avatar})`,
                        backgroundSize: 'cover',
                        marginRight: 20,
                    }}
                />
                <div>
                    <div
                        style={{ fontWeight: 600 }}
                    >
                        {selectedConsultant.firstname}
                        {' '}
                        {selectedConsultant.lastname}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        <div>
                            {occupationTitle(selectedConsultant.occupation)}
                        </div>
                        <div>
                            {selectedConsultant.email}
                        </div>
                        <div>
                            {selectedConsultant.phone}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOriginalConsultant = () => (
        <div className='mpa-replace-consultant__shift'>
            <div>
                {employer.name}
            </div>
            <div>
                {moment.unix(shift.start_time).format('dddd YYYY-MM-DD')}
            </div>
            <div>
                {moment.unix(shift.start_time).format('HH:mm')}
                {' '}
                -
                {moment.unix(shift.end_time).format('HH:mm')}
            </div>
            <div>
                {shift.price * shift.duration}
                {' '}
                kr
            </div>
        </div>
    );

    const renderOriginalBooking = () => (
        <div>
            <h5>Bokad konsult:</h5>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '20px 0',
                }}
            >
                <div
                    style={{
                        width: 70,
                        height: 70,
                        borderRadius: 70,
                        background: '#EEE',
                        backgroundImage: `url(${user.avatar})`,
                        backgroundSize: 'cover',
                        marginRight: 20,
                    }}
                />
                <div>
                    <div
                        style={{ fontWeight: 600 }}
                    >
                        {user.firstname}
                        {' '}
                        {user.lastname}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        <div>
                            {occupationTitle(user.occupation)}
                        </div>
                        <div>
                            {user.email}
                        </div>
                        <div>
                            {user.phone}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderSearchInput = () => {
        const children = searchResults.map(consultant => (
            <AutoComplete.Option
                key={consultant.id}
                value={String(consultant.id)}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px 0',
                    }}
                >
                    <div>
                        <Avatar
                            src={consultant.avatar}
                            style={{ marginRight: 10 }}
                        >
                            {!consultant.avatar ? (consultant.firstname || '')[0] + (consultant.lastname || '')[0] : ''}
                        </Avatar>
                    </div>
                    <div>
                        <div
                            style={{ fontWeight: '600' }}
                        >
                            {consultant.firstname}
                            {' '}
                            {consultant.lastname}
                            {' '}
                            (ID
                            {' '}
                            {consultant.id}
                            )
                        </div>
                        <div>
                            {consultant.email}
                        </div>
                    </div>
                </div>
            </AutoComplete.Option>
        ));
        return (
            <AutoComplete
                placeholder='Sök konsult, namn eller ID...'
                style={{ width: '100%' }}
                onSearch={doSearch}
                onSelect={consultant => {
                    setSelectedConsultant(find(searchResults, { id: +consultant }));
                }}
            >
                { children }
            </AutoComplete>
        );
    };

    const renderReplacementSearch = () => (
        <div>
            <h5>Ersättande konsult:</h5>
            {renderSearchInput()}
            {renderSelectedConsultant()}
        </div>
    );

    const renderActions = () => {
        if (!selectedConsultant) {
            return '';
        }

        return (
            <div className='mpa-replace-consultant__actions'>
                <button
                    className='btn btn-danger btn-lg'
                    onClick={() => {
                        onConfirmReplace(selectedConsultant.id)
                            .then(() => {
                                setSuccess(true);
                            });
                    }}
                >
                    Ersätt
                    {' '}
                    {user.firstname}
                    {' '}
                    med
                    {' '}
                    {selectedConsultant.firstname}
                </button>
            </div>
        );
    };

    const renderSuccess = () => {
        const date = moment.unix(shift.start_time).format('D/M');
        const startTime = moment.unix(shift.start_time).format('HH:mm');
        const endTime = moment.unix(shift.end_time).format('HH:mm');
        return (
            <div className='mpa-replace-consultant__success'>
                <h2>Klart</h2>
                <div style={{ margin: '10px 0' }}>
                    <strong>
                        {selectedConsultant.firstname}
                        {' '}
                        {selectedConsultant.lastname}
                    </strong>
                    {' '}
                    är nu inbokad på
                    {' '}
                    <strong>{employer.name}</strong>
                    {' '}
                    den
                    {' '}
                    <strong>
                        {date}
                        {' '}
                        kl
                        {' '}
                        {startTime}
                        {' '}
                        -
                        {' '}
                        {endTime}
                    </strong>
                    .
                </div>
                <div>
                    {user.firstname}
                    {' '}
                    {user.lastname}
                    s bokning för det aktuella passet har tagits bort.
                </div>
                <div style={{ marginTop: 20 }}>
                    <button
                        className='btn btn-primary'
                        onClick={onClose}
                    >
                        <i className='fa fa close' />
                        {' '}
                        Stäng
                    </button>
                </div>
            </div>
        );
    };

    const renderContent = () => (
        <div className='mpa-replace-consultant'>
            <div className='mpa-replace-consultant__warning'>
                <div className='mpa-replace-consultant__warning-icon'>
                    <i className='fa fa-warning' />
                </div>
                <div className='mpa-replace-consultant__warning-content'>
                    <h2>Warning! Do not touch this stuff unless you know exactly what you are doing!</h2>
                    <p>
                        This action will permanently alter existing production booking data.
                        You should only use the replace consultant tool after talking to BOTH consultants AND the
                        employer and all parties are in agreement about the replacement.
                    </p>
                </div>
            </div>
            <div className='mpa-replace-consultant__content'>
                {renderOriginalConsultant()}
                {renderOriginalBooking()}
                {renderReplacementSearch()}
            </div>
            {renderActions()}
        </div>
    );

    return success ? renderSuccess() : renderContent();
};
export default connect(
    null,
    dispatch => bindActionCreators({
        fetchConsultants,
    }, dispatch),
)(ReplaceConsultantModal);
