import * as request from 'request';

/** FAQs * */

export function requestFetchFaqs() {
    return { type: 'REQUEST_FETCH_FAQS' };
}

export function receiveFetchFaqs(faqs) {
    return {
        type: 'RECEIVE_FETCH_FAQS',
        faqs,
    };
}

export function fetchFaqs() {
    return dispatch => {
        dispatch(requestFetchFaqs());

        request.fetchFaqs().then(resp => {
            dispatch(receiveFetchFaqs(resp.collection));
        });
    };
}

/** FAQ Categories * */

export function requestFetchFaqCategories() {
    return { type: 'REQUEST_FETCH_FAQ_CATEGORIES' };
}

export function receiveFetchFaqCategories(faqCategories) {
    return {
        type: 'RECEIVE_FETCH_FAQ_CATEGORIES',
        faqCategories,
    };
}

export function fetchFaqCategories() {
    return dispatch => {
        dispatch(requestFetchFaqCategories());

        request.fetchFaqCategories().then(resp => {
            dispatch(receiveFetchFaqCategories(resp.collection));
        });
    };
}

export function requestAddFaqCategory() {
    return { type: 'REQUEST_ADD_FAQ_CATEGORY' };
}

export function receiveAddFaqCategory(resp) {
    const {
        name,
        id,
        order,
    } = resp;

    const faq = {
        name,
        id,
        order,
        questions: [],
    };

    return {
        type: 'RECEIVE_ADD_FAQ_CATEGORY',
        faq,
    };
}

export function addFaqCategory(data) {
    return dispatch => {
        dispatch(requestAddFaqCategory(data));

        request.createFaqCategory(data).then(resp => {
            dispatch(receiveAddFaqCategory(resp));
        });
    };
}

export function requestUpdateFaqCategory() {
    return { type: 'REQUEST_UPDATE_FAQ_CATEGORY' };
}

export function receiveUpdateFaqCategory(id, faq) {
    return {
        type: 'RECEIVE_UPDATE_FAQ_CATEGORY',
        faq,
        id,
    };
}

export function updateFaqCategory(id, data) {
    return dispatch => {
        dispatch(requestUpdateFaqCategory());

        request.updateFaqCategory(id, data).then(resp => {
            dispatch(receiveUpdateFaqCategory(id, data));
        });
    };
}

function requestAddFaq() {
    return { type: 'REQUEST_ADD_FAQ' };
}

function receiveAddFaq(faq) {
    return {
        type: 'RECEIVE_ADD_FAQ',
        faq,
    };
}

export function addFaq(data) {
    return dispatch => {
        dispatch(requestAddFaq());

        return request.createFaq(data).then(resp => {
            dispatch(receiveAddFaq(resp));
        });
    };
}

export function requestUpdateFaq() {
    return { type: 'REQUEST_UPDATE_FAQ' };
}

export function receiveUpdateFaq(id, data, faqCategoryId) {
    return {
        type: 'RECEIVE_UPDATE_FAQ',
        id,
        data,
        faqCategoryId,
    };
}

export function updateFaq(id, data, faqCategoryId) {
    return dispatch => {
        dispatch(requestUpdateFaq());

        return request.updateFaq(id, data).then(() => {
            dispatch(receiveUpdateFaq(id, data, faqCategoryId));
        });
    };
}

export function requestRemoveFaq() {
    return { type: 'REQUEST_REMOVE_FAQ' };
}

export function receiveRemoveFaq(id, faqCategoryId) {
    return {
        type: 'RECEIVE_REMOVE_FAQ',
        id,
        faqCategoryId,
    };
}

export function removeFaq(id, faqCategoryId) {
    return dispatch => {
        dispatch(requestRemoveFaq());

        return request.removeFaq(id).then(() => {
            dispatch(receiveRemoveFaq(id, faqCategoryId));
        });
    };
}
