import React from 'react';
import PropTypes from 'prop-types';

const Blurb = ({
    blurb,
    blurbPrefix,
}) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#288373',
            width: 70,
            height: 70,
            borderRadius: 35,
            color: 'white',
            transform: 'rotate(-35deg)',
            textAlign: 'center',
            overflow: 'hidden',
            padding: 3,
        }}
    >
        {blurbPrefix && <div style={{ fontSize: 10 }}>{blurbPrefix}</div>}
        <div
            style={{
                fontSize: 14,
                whiteSpace: 'pre-line',
            }}
        >
            {blurb}
        </div>
    </div>
);

Blurb.propTypes = {
    blurb: PropTypes.string.isRequired,
    blurbPrefix: PropTypes.string,
};

export default Blurb;
