import {
    without,
    includes,
} from 'lodash';
import config from 'config';

const initialState = {

    includeTestAccounts: false,
    occupation: config.occupations.OCCUPATION_ALL,
    student: config.students.STUDENT_ALL,
    includeTags: [],
    excludeTags: [],
    skills: [],
    intentions: [],
    specializations: [],
    lastActive: [],
    entityTypes: ['CLINICIANS', 'EMPLOYERS'],
};

export function consultantsFilter(state = initialState, action) {
    switch (action.type) {
        case 'TOGGLE_INCLUDE_TEST_ACCOUNTS':

            return {
                ...state,
                includeTestAccounts: !state.includeTestAccounts,
                selectedPresetId: null,
            };

        case 'TOGGLE_INCLUDE_TOP_CONSULTANTS':
            return {
                ...state,
                includeTopConsultants: !state.includeTopConsultants,
                selectedPresetId: null,
            };

        case 'ADD_INCLUDE_TAG':

            return {
                ...state,
                includeTags: [...state.includeTags, action.tagId],
                selectedPresetId: null,
            };

        case 'REMOVE_INCLUDE_TAG':

            return {
                ...state,
                includeTags: without(state.includeTags, action.tagId),
                selectedPresetId: null,
            };

        case 'ADD_EXCLUDE_TAG':

            return {
                ...state,
                excludeTags: [...state.excludeTags, action.tagId],
                selectedPresetId: null,
            };

        case 'REMOVE_EXCLUDE_TAG':

            return {
                ...state,
                excludeTags: without(state.excludeTags, action.tagId),
                selectedPresetId: null,
            };

        case 'SET_OCCUPATION_FILTER':

            return {
                ...state,
                occupation: action.occupation,
                selectedPresetId: null,
            };
        case 'SET_STUDENT_FILTER':

            return {
                ...state,
                student: action.student,
                selectedPresetId: null,
            };

        case 'SET_PRESET':

            return {
                ...state,
                ...action.preset.filter,
                selectedPresetId: action.preset.id,
            };

        case 'TOGGLE_LAST_ACTIVE_ID':

            return {
                ...state,
                lastActive: includes(state.lastActive, action.id) ? without(state.lastActive, action.id) : [...state.lastActive, action.id],
            };

        case 'CONSULTANTS_FILTER_SET_INTENTIONS':
            return {
                ...state,
                intentions: action.intentionIds,
            };

        case 'CONSULTANTS_FILTER_SET_SKILLS':
            return {
                ...state,
                skills: action.skillIds,
            };

        case 'CONSULTANTS_FILTER_SET_SPECIALIZATIONS':
            return {
                ...state,
                specializations: action.specializationIds,
            };

        case 'CONSULTANTS_FILTER_SET_INCLUDE_TAGS':
            return {
                ...state,
                includeTags: action.includeTagIds,
            };

        case 'CONSULTANTS_FILTER_SET_EXCLUDE_TAGS':
            return {
                ...state,
                excludeTags: action.excludeTagIds,
            };

        case 'CONSULTANTS_FILTER_SET_ENTITY_TYPES':
            return {
                ...state,
                entityTypes: action.entityTypes,
            };

        default:

            return state;
    }
}
