import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

export default class CustomChart extends React.Component {
    static propTypes = {
        labels: PropTypes.array,
        data: PropTypes.array,
        backgroundColor: PropTypes.array,
        type: PropTypes.string,
        options: PropTypes.object,
        height: PropTypes.number,
        width: PropTypes.number,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            labels, data, backgroundColor, type, options,
        } = this.props;

        const ctx = this.refs.chart.getContext('2d');
        this.chart = new Chart(ctx, this.chartData(labels, data, backgroundColor, type, options));
    }

    componentDidUpdate() {
        const {
            labels, data, backgroundColor, type, options,
        } = this.props;

        const newData = this.chartData(labels, data, backgroundColor, type, options);

        this.chart.config = newData;
        this.chart.update();
    }

    chartData(labels, data, backgroundColor, type, options) {
        return {
            type,
            data: {
                labels,
                datasets: [{
                    borderWidth: 0,
                    labels,
                    data,
                    backgroundColor,
                }],
            },
            options,
        };
    }

    render() {
        const {
            height, width,
        } = this.props;

        return (
            <div
                style={{
                    display: 'flex',
                    width,
                    minHeight: height,
                }}
            >
                <canvas ref='chart' />
            </div>
        );
    }
}
