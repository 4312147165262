const initialState = {
    employers: [],
    totalCount: 0,
    isFetching: false,
    query: '',
    hasFetched: false,
    people: [],
};

const employerssearch = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_SEARCH_EMPLOYERS':
            return {
                ...state, isFetching: true,
            };

        case 'RECEIVE_SEARCH_EMPLOYERS':
            return {
                ...state,
                employers: action.employers,
                totalCount: action.totalCount,
                isFetching: false,
                hasFetched: true,
            };

        case 'RECEIVE_SEARCH_CONTACTS':
            return {
                ...state,
                people: action.contacts,
            };

        default:
            return state;
    }
};
export default employerssearch;
