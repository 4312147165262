import ReactDOM from 'react-dom';
import React from 'react';
import Modal from 'components/modal';

class ModalController {
    closeModal() {
        ReactDOM.unmountComponentAtNode(this._container);
        document.body.removeChild(this._container);
        document.body.style.overflow = 'auto';
        this._container = null;
    }

    getContainer() {
        return this._container;
    }

    createContainer() {
        const container = document.createElement('div');
        container.className = 'mp-modal-wrapper';
        document.body.appendChild(container);
        document.body.style.overflow = 'hidden';
        return container;
    }

    renderModal(ModalContentClass, options = {}) {
        const container = this.getContainer();
        ReactDOM.render(<Modal
            close={this.closeModal.bind(this)}
            hideTitle={options.hideTitle}
            preventClose={options.preventClose}
            preventOutsideClickClose={options.preventOutsideClickClose}
            style={options.style}
            title={options.title}
            width={options.width || 960}
        >
            <ModalContentClass
                close={this.closeModal.bind(this)}
                {...options.props}
            />
        </Modal>, container);
    }

    showModal(ModalContentClass, options = {}) {
        if (!this.getContainer()) {
            this._container = this.createContainer();
        }

        this.renderModal(ModalContentClass, options);
    }

    replaceModal(ModalContentClass, options = {}) {
        const newContainer = this.createContainer();
        newContainer.className = 'mp-modal-wrapper mp-modal-wrapper--in';

        const oldContainer = this.getContainer();

        this._container = newContainer;
        setTimeout(() => {
            oldContainer.className = 'mp-modal-wrapper mp-modal-wrapper--out';
            newContainer.className = 'mp-modal-wrapper';
            this.renderModal(ModalContentClass, options);

            setTimeout(() => {
                ReactDOM.unmountComponentAtNode(oldContainer);
                document.body.removeChild(oldContainer);
            }, 2000);
        }, 20);
    }
}

const modalController = new ModalController();

export default modalController;
