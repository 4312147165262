import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    Button,
    Form,
    Modal,
    InputNumber,
    Checkbox,
    Tag,
} from 'antd';
import {
    filter,
    sortBy,
} from 'lodash';
import moment from 'moment';
import { isHoliday } from 'utils/holidays';
import { useDispatch } from 'react-redux';
import ShiftMarginDropdown from './createshiftslistmodal/shiftmargindropdown';
import {
    fetchEmployerShifts,
    updateMultipleShifts,
} from '../actions/shifts';

const EditMarginsModal = ({
    employerShifts,
    close,
    visible,
    employerId,
}) => {
    const [price, setPrice] = useState();
    const [priceDisabled, setPriceDisabled] = useState(true);
    const [success, setSuccess] = useState(false);
    const [margin, setMargin] = useState(null);
    const [invoiceMargin, setInvoiceMargin] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        // Reset state every time the modal opens
        if (visible) {
            setSuccess(false);
            setMargin(null);
            setInvoiceMargin(null);
            setSelectedRowKeys([]);
            setSelectedIds([]);
            form.resetFields();
        }
    }, [visible, form]);

    const onClose = () => {
        close();
        if (success) {
            dispatch(fetchEmployerShifts(employerId));
        }
    };

    if (!employerShifts) {
        // return null;
    }

    const columns = [{
        title: 'Week',
        dataIndex: 'week',
        key: 'week',
        render: (text, record) => `w.${moment.unix(record.start_time).isoWeek()}`,
    }, {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => {
            const m = moment.unix(record.start_time);
            const dateText = m.format('YYYY-MM-DD');
            const weekday = m.format('ddd');
            const isWeekend = m.isoWeekday() >= 6;
            const holiday = isHoliday(dateText);
            return (
                <>
                    {dateText}
                    <Tag
                        color={(isWeekend || holiday) ? 'red' : null}
                        style={{ marginLeft: 5 }}
                    >
                        {weekday}

                    </Tag>
                </>
            );
        },
    }, {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (text, record) => `${moment.unix(record.start_time).format('HH:mm')} - ${moment.unix(record.end_time).format('HH:mm')}`,
    }, {
        title: 'Break',
        dataIndex: 'shift_break',
        key: 'shift_break',
    }, {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
    }, {
        title: 'Salary margin',
        dataIndex: 'margin',
        key: 'margin',
        render: text => `${text}%`,
    }, {
        title: 'Invoice margin',
        dataIndex: 'consultant_fee_margin',
        key: 'consultant_fee_margin',
        render: text => `${text}%`,
    }, {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    }];

    const filteredShifts = filter(employerShifts, s => s.start_time > moment().format('X') && !s.applications.length && !s.scheduled.length);

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedIds(selectedRows.map(r => r.id));
        },
        getCheckboxProps: record => ({ name: record.name }),
    };

    const validateForm = () => {
        if ((margin && !invoiceMargin) || (!margin && invoiceMargin)) {
            return false;
        }
        if (!(margin || invoiceMargin || (!priceDisabled && price))) {
            return false;
        }
        return true;
    };

    return (
        <Modal
            footer={null}
            title='Bulk edit shifts'
            visible={visible}
            width='95%'
            onCancel={() => {
                onClose();
            }}
        >
            {success ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '40px 0',
                    }}
                >

                    <h2>
                        The shifts have been updated
                    </h2>
                    <Table
                        columns={[{
                            title: 'Shift id',
                            dataIndex: ['value', 'id'],
                        }, {
                            title: 'New price',
                            dataIndex: ['value', 'price'],
                            render: (val => `${val}`),
                        }, {
                            title: 'New salary',
                            dataIndex: ['value', 'salary'],
                            render: (val => `${val}`),
                        }, {
                            title: 'New consultant fee',
                            dataIndex: ['value', 'consultant_fee'],
                            render: (val => `${val}`),
                        }, {
                            title: 'New margin',
                            dataIndex: ['value', 'margin'],
                            render: (val => `${val}%`),
                        }, {
                            title: 'New invoice margin',
                            dataIndex: ['value', 'consultant_fee_margin'],
                            render: (val => `${val}%`),
                        }, {
                            title: 'Status',
                            dataIndex: 'status',
                            render: status => (status === 'fulfilled' ? 'Success' : 'Error: This shift was NOT updated'),
                        }]}
                        dataSource={success}
                        pagination={false}
                    />

                    <div>

                        <Button
                            ghost
                            size='large'
                            style={{ marginTop: 20 }}
                            type='primary'
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Close
                        </Button>

                    </div>
                </div>
            ) : (
                <div>
                    <Table
                        columns={columns}
                        dataSource={sortBy(filteredShifts, 'start_time')}
                        pagination={false}
                        rowKey={record => record.id}
                        rowSelection={rowSelection}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 20,
                        }}
                    >
                        <Form
                            form={form}
                            layout='inline'
                        >

                            <Form.Item label='Price'>
                                <Checkbox
                                    style={{ marginRight: '4px' }}
                                    onChange={e => { setPriceDisabled(!e.target.checked); }}
                                />
                                <InputNumber disabled={priceDisabled} onChange={value => setPrice(value)} />
                            </Form.Item>
                            <Form.Item
                                label='Margin'
                                name='Salary margin'
                                required={invoiceMargin}
                            >
                                <ShiftMarginDropdown
                                    placeholder='Select margin'
                                    size='large'
                                    value={margin}
                                    onChange={value => setMargin(value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Invoice margin'
                                name='consultant_fee_margin'
                                required={margin}
                            >
                                <ShiftMarginDropdown
                                    placeholder='Select margin'
                                    size='large'
                                    value={invoiceMargin}
                                    onChange={value => setInvoiceMargin(value)}
                                />
                            </Form.Item>
                        </Form>
                        <Button
                            disabled={!selectedIds.length || !validateForm()}
                            size='large'
                            type='primary'
                            onClick={() => {
                                const data = selectedIds.map(id => ({
                                    id,
                                    margin,
                                    consultant_fee_margin: invoiceMargin,
                                    price,
                                }));
                                dispatch(updateMultipleShifts(data))
                                    .then(results => {
                                        setSuccess(results);
                                    });
                            }}
                        >
                            Update all selected
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

EditMarginsModal.propTypes = {
    employerShifts: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })),
    close: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    employerId: PropTypes.number.isRequired,
};

export default EditMarginsModal;
