import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import {
    Modal,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
    Dropdown,
    Menu,
    Button,
    DatePicker,
    TimePicker,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const FormInput = ({
    input,
    children,
    ...props
}) => {
    const basicRules = {};
    if (input.required) {
        basicRules.required = true;
    }
    if (input.valueType) {
        basicRules.type = input.valueType;
    }
    else if (input.type === 'number') {
        basicRules.type = 'number';
    }
    else if (input.type === 'date') {
        basicRules.type = 'date';
    }
    return (
        <Form.Item
            disabled={input.disabled}
            id={input.name}
            label={input.label}
            name={input.name}
            rules={[basicRules]}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            {children}
        </Form.Item>
    );
};

const InputPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    valueType: PropTypes.string,
};

FormInput.propTypes = {
    input: PropTypes.shape(InputPropTypes).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

const AntdInputModal = ({
    visible = false,
    inputs,
    onSave,
    onCancel,
    onChange,
    children,
    className,
}) => {
    const [form] = Form.useForm();
    const renderInputs = () => inputs.map(input => {
        switch (input.type) {
            case 'input': {
                let menu = null;
                let dropdown = null;
                if (input.options) {
                    menu = (
                        <Menu
                            onClick={({ key }) => {
                                const option = input.options.find(o => o.title === key);
                                const values = option.fields.reduce((obj, current) => ({
                                    ...obj,
                                    [current.name]: current.value,
                                }), {});
                                form.setFieldsValue(values);
                            }}
                        >
                            {input.options.map(option => (
                                <Menu.Item key={option.title}>
                                    {option.title}
                                </Menu.Item>
                            ))}
                        </Menu>
                    );
                    dropdown = (
                        <Dropdown
                            overlay={menu}
                            placement='bottomRight'
                            trigger={['click']}
                        >
                            <Button
                                className='dropdown-button'
                                icon={<CaretDownOutlined />}
                            />
                        </Dropdown>
                    );
                }
                return (
                    <div
                        key={input.name}
                        className='input-container'
                    >
                        <FormInput
                            className='field'
                            input={input}
                        >
                            <Input />
                        </FormInput>
                        {input.options && dropdown}
                    </div>
                );
            }
            case 'textarea':
                return (
                    <FormInput key={input.name} input={input}>
                        <Input.TextArea />
                    </FormInput>
                );
            case 'number':
                return (
                    <FormInput key={input.name} input={input}>
                        <InputNumber />
                    </FormInput>
                );
            case 'switch':
                return (
                    <FormInput
                        key={input.name}
                        input={input}
                        valuePropName='checked'
                    >
                        <Switch />
                    </FormInput>
                );

            case 'select':
            case 'multiselect':
                return (
                    <FormInput key={input.name} input={input}>
                        <Select disabled={input.disabled} mode={input.type === 'multiselect' ? 'multiple' : undefined}>
                            {input.options.map(opt => (
                                <Select.Option
                                    key={opt.id || opt.value}
                                    value={opt.value}
                                >
                                    {opt.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormInput>
                );

            case 'date':
                return (
                    <FormInput key={input.name} input={input}>
                        <DatePicker />
                    </FormInput>
                );
            case 'dateRange':
                return (
                    <FormInput key={input.name} input={input}>
                        <DatePicker.RangePicker />
                    </FormInput>
                );

            case 'time':
                return (
                    <FormInput key={input.name} input={input}>
                        <TimePicker />
                    </FormInput>
                );
            case 'timeRange':
                return (
                    <FormInput key={input.name} input={input}>
                        <TimePicker.RangePicker />
                    </FormInput>
                );
            case 'custom':
                return input.render();

            default:
                return null;
        }
    });

    const initialValues = inputs.reduce((obj, current) => ({
        ...obj,
        [current.name]: current.value,
    }), {});

    return (
        <Modal
            className={className}
            visible={visible}
            onCancel={onCancel}
            onOk={() => {
                form.validateFields().then(values => {
                    onSave(values);
                });
            }}
        >
            {visible && (
                <>
                    <Form
                        form={form}
                        initialValues={initialValues}
                        layout='vertical'
                        onValuesChange={onChange}
                    >
                        {renderInputs()}
                    </Form>
                    {children}
                </>
            )}
        </Modal>
    );
};

AntdInputModal.propTypes = {
    visible: PropTypes.bool,
    inputs: PropTypes.arrayOf(PropTypes.shape(InputPropTypes)).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    children: PropTypes.node,
};

export default AntdInputModal;
