import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as commentsActions from 'actions/comments';
import { Badge } from 'antd';
import { postTask } from 'request';
import { CheckCircleOutlined } from '@ant-design/icons';
import AddComment from './addcomment';
import Taskmodal from '../../containers/tasks/taskmodal';

const ConsultantComments = ({
    comments,
    entityId,
    fetchUserComments,
    fetchEmployerComments,
    type,
    addUserComment,
    addEmployerComment,
}) => {
    const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
    const [selectedTask, setSelectedTask] = useState();
    useEffect(() => {
        if (type === 'consultant') {
            fetchUserComments(entityId);
        }
        if (type === 'employer') {
            fetchEmployerComments(entityId);
        }
    }, []);

    const onAddComment = async ({
        content, admin_id_assigned, dueDateTime, plannedDateTime,
    }) => {
        let taskId;
        if (admin_id_assigned || dueDateTime || plannedDateTime) {
            const taskResponse = await postTask({
                title: content,
                admin_id_assigned,
                due_date_time: dueDateTime ? dueDateTime.startOf('day').unix() : undefined,
                planned_date_time: plannedDateTime ? plannedDateTime.startOf('day').unix() : undefined,
                user_id: type === 'consultant' ? entityId : undefined,
                employer_id: type === 'employer' ? entityId : undefined,
            });
            taskId = taskResponse.task.id;
        }
        if (type === 'consultant') {
            return addUserComment(entityId, content, taskId);
        } if (type === 'employer') {
            return addEmployerComment(entityId, content, taskId);
        }
        return null;
    };

    const renderCommentsList = () => {
        if (!comments.length) {
            return (
                <div
                    style={{
                        color: '#999',
                        fontStyle: 'italic',
                        marginBottom: 20,
                    }}
                >
                    Inga kommentarer
                </div>
            );
        }

        return (
            <div>
                {comments.map(comment => (

                    <div
                        key={comment.id}
                        style={{ marginTop: 20 }}
                    >
                        <Badge
                            count={comment.task ? (
                                <CheckCircleOutlined
                                    style={{ color: '#FF4F70' }}
                                    onClick={() => { setSelectedTask(comment.task); setEditTaskModalVisible(true); }}
                                />
                            ) : <></>}
                        >
                            <div
                                style={{
                                    padding: 20,
                                    background: '#f9f9f9',
                                }}
                            >
                                {comment.comment}
                            </div>
                        </Badge>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 5,
                                justifyContent: 'space-between',
                                fontSize: 12,
                                color: '#999',
                            }}
                        >
                            <div>{comment.admin_user.username}</div>
                            <div>{moment.unix(comment.created_time).fromNow()}</div>
                        </div>
                    </div>

                ))}

            </div>
        );
    };

    return (
        <div
            style={{
                width: 400,
                padding: 40,
                flex: 1,
            }}
        >
            {renderCommentsList()}
            <AddComment onSubmit={onAddComment} />
            {selectedTask ? (
                <Taskmodal
                    task={selectedTask}
                    visible={editTaskModalVisible}
                    onCancel={() => setEditTaskModalVisible(false)}
                />
            ) : <></>}
        </div>
    );
};

ConsultantComments.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        admin_id: PropTypes.number,
    })),
    entityId: PropTypes.string.isRequired,
    fetchUserComments: PropTypes.func.isRequired,
    fetchEmployerComments: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    addUserComment: PropTypes.func.isRequired,
    addEmployerComment: PropTypes.func.isRequired,
};

export default connect(
    state => ({
        comments: state.comments.comments,
    }),
    dispatch => bindActionCreators({ ...commentsActions }, dispatch),
)(ConsultantComments);
