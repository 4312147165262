import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Button from 'components/shared/button';
import {
    fetchLtvApplications,
    removeShiftsFromBookedApplication,
    removeShiftsFromBookedApplicationAndDeleteShifts,
    confirmLtvApplication,
} from 'actions/applications';
import { getFormattedDateWithWeek } from 'utils/datetimeformatters';
import {
    Avatar,
    Modal,
} from 'antd';
import './styles.scss';
import { Link } from 'react-router-dom';
import CreateShiftsListModal from 'components/createshiftslistmodal';

import {
    removeEmployerShifts,
    fetchEmployersAllConsultants,
    createMultipleShifts,
    createAndAddMultipleShiftsToConsultant,
    createAndAddMultipleShiftsToLtv,
} from 'actions/employer';
import { fetchConsultants } from 'actions/consultants';
import { fetchEmployerShifts } from 'actions/shifts';
import LtvApplicationModal from 'containers/applications/ltvapplicationmodal';
import { fetchEmployerConsultant } from 'request';
import VacancyCard from './vacancy';

const EmployerLongTermVacancies = ({
    employerId,
    ltvProfiles,
    jobProfiles,
    fetchApplications,
    allProfiles,
    consultants,
    employer,
    settings,
    isFetchingEmployerShifts,
    removeEmployerShifts,
    fetchEmployerShifts,
    createMultipleShifts,
    fetchConsultants,
    confirmLtvApplication,
    createAndAddMultipleShiftsToConsultant,
    createAndAddMultipleShiftsToLtv,
    employerShifts,
    isDeletingShifts,
    isCreatingShifts,
    removeShiftsFromBookedApplication,
    removeShiftsFromBookedApplicationAndDeleteShifts,
}) => {
    useEffect(() => {
        fetchEmployerShifts(employerId);
    }, [employerId, fetchEmployerShifts]);

    const [showShiftsListModal, setShowShiftsListModal] = useState(false);
    const [modalData, setModalData] = useState({
        profileId: null,
        consultant: null,
        applicationId: null,
    });
    const [applicationModalData, setApplicationModalData] = useState();

    const [showEditBookingModal, setShowEditBookingModal] = useState(false);
    const [editBookingModalData, setEditBookingModalData] = useState({
        bookingId: null,
        employerId: null,
    });

    useEffect(() => {
        fetchApplications();
    }, [fetchApplications]);

    const renderShiftsListModal = () => (
        <Modal
            footer={null}
            title='Skapa/Ta bort pass från lista'
            visible={showShiftsListModal}
            width='80%'
            onCancel={() => {
                setShowShiftsListModal(false);
            }}
            onOk={() => {
                setShowShiftsListModal(false);
            }}
        >
            <CreateShiftsListModal
                close={() => {
                    setShowShiftsListModal(false);
                }}
                confirmApplication={confirmLtvApplication}
                consultants={consultants}
                createAndAddMultipleShiftsToConsultant={createAndAddMultipleShiftsToConsultant}
                createAndAddMultipleShiftsToLtv={createAndAddMultipleShiftsToLtv}
                createMultipleShifts={createMultipleShifts}
                customShifts={employer.settings.custom_shifts}
                deleteShifts={removeEmployerShifts}
                employerId={employerId}
                employerShifts={employerShifts}
                fetchConsultants={fetchConsultants}
                isCreatingShifts={isCreatingShifts}
                isDeletingShifts={isDeletingShifts}
                preselectedApplicationId={modalData.applicationId}
                preselectedConsultant={modalData.consultant}
                preselectedProfileId={modalData.profileId}
                profiles={allProfiles}
                removeShiftsFromBookedApplication={removeShiftsFromBookedApplication}
                removeShiftsFromBookedApplicationAndDeleteShifts={removeShiftsFromBookedApplicationAndDeleteShifts}
            />
        </Modal>
    );

    const renderEditBookingModal = () => (
        <Modal
            footer={null}
            title='Skapa/Ta bort pass från lista'
            visible={showEditBookingModal}
            width='80%'
            onCancel={() => {
                setShowEditBookingModal(false);
            }}
            onOk={() => {
                setShowEditBookingModal(false);
            }}
        >
            <CreateShiftsListModal
                close={() => {
                    setShowEditBookingModal(false);
                }}
                confirmApplication={confirmLtvApplication}
                consultants={consultants}
                createAndAddMultipleShiftsToConsultant={createAndAddMultipleShiftsToConsultant}
                createAndAddMultipleShiftsToLtv={createAndAddMultipleShiftsToLtv}
                createMultipleShifts={createMultipleShifts}
                customShifts={employer.settings.custom_shifts}
                deleteShifts={removeEmployerShifts}
                employerId={employerId}
                employerShifts={employerShifts}
                fetchConsultants={fetchConsultants}
                isCreatingShifts={isCreatingShifts}
                isDeletingShifts={isDeletingShifts}
                preselectedApplicationId={
                    editBookingModalData.ltv.bookings.length
                    && editBookingModalData.ltv.bookings[0].id
                }
                preselectedConsultant={editBookingModalData.ltv.bookings[0].user}
                preselectedLtv={editBookingModalData.ltv}
                preselectedProfileId={editBookingModalData.ltv.bookings[0].job.id}
                profiles={allProfiles}
                removeShiftsFromBookedApplication={removeShiftsFromBookedApplication}
                removeShiftsFromBookedApplicationAndDeleteShifts={removeShiftsFromBookedApplicationAndDeleteShifts}
            />
        </Modal>
    );

    const renderApplicationModal = () => (

        <LtvApplicationModal
            adminComment={applicationModalData.adminComment}
            applicationId={applicationModalData.id}
            close={() => {
                setApplicationModalData();
            }}
            consultant={applicationModalData.user}
            employer={applicationModalData.employer}
            employerId={applicationModalData.employer.id}
            isLtv
            ltvApplicationModalData={applicationModalData}
            preventConfirmation={applicationModalData.isBooked}
            userId={applicationModalData.user.id}
        />
    );

    const renderApplications = vacancy => {
        if (vacancy.applications.length) {
            return (
                <div>
                    <dt className='ltv-detail-title'>Applications</dt>
                    {vacancy.applications.map(application => (
                        <div className='ltv-detail-application'>
                            <div className='ltv-detail-consultant'>
                                <Avatar
                                    size='large'
                                    src={application.user.avatar}
                                    style={{ marginRight: 10 }}
                                >
                                    {
                                        !application.user.avatar
                                            ? application.user.firstname[0] + application.user.lastname[0]
                                            : ''
                                    }
                                </Avatar>
                                <div>
                                    <Link to={`/consultants/${application.user.id}`}>
                                        {application.user.firstname}
                                        {' '}
                                        {application.user.lastname}
                                    </Link>
                                    <div>
                                        {moment.unix(application.created_time).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button
                                    onClick={async () => {
                                        const extraData = await fetchEmployerConsultant(
                                            application.employer.id,
                                            application.user.id,
                                        );
                                        setApplicationModalData({
                                            ...application,
                                            adminComment: extraData.employer_consultant.admin_comment,
                                            isBooked: vacancy.bookings.length,
                                        });
                                    }}
                                >
                                    {vacancy.bookings.length ? 'Deny' : 'View details / Book'}
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } if (!vacancy.bookings.length) {
            return (
                <div>
                    <dt className='ltv-detail-title'>Applications</dt>

                    <div>
                        No applications yet
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <section>
            {ltvProfiles.map(vacancy => (
                <VacancyCard
                    countryCode={employer.country_code}
                    employerId={employerId}
                    employerShifts={employerShifts}
                    getFormattedDateWithWeek={getFormattedDateWithWeek}
                    includeTestAccounts={employer.test_employer}
                    isFetchingEmployerShifts={isFetchingEmployerShifts}
                    jobProfiles={jobProfiles}
                    renderApplications={renderApplications}
                    setEditBookingModalData={setEditBookingModalData}
                    setShowEditBookingModal={setShowEditBookingModal}
                    settings={settings}
                    showEditBookingModal={showEditBookingModal}
                    vacancy={vacancy}
                />
            ))}
            <VacancyCard
                countryCode={employer.country_code}
                employerId={employerId}
                employerShifts={employerShifts}
                getFormattedDateWithWeek={getFormattedDateWithWeek}
                includeTestAccounts={employer.test_employer}
                isFetchingEmployerShifts={isFetchingEmployerShifts}
                jobProfiles={jobProfiles}
                renderApplications={renderApplications}
                setEditBookingModalData={setEditBookingModalData}
                setShowEditBookingModal={setShowEditBookingModal}
                settings={settings}
                showEditBookingModal={showEditBookingModal}
            />
            {renderShiftsListModal()}
            {applicationModalData ? renderApplicationModal() : <></>}
            {showEditBookingModal && renderEditBookingModal()}
            {isFetchingEmployerShifts && (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 30,
                        paddingTop: 40,
                        paddinBottom: 40,
                    }}
                >
                    <i className='fa fa-circle-o-notch fa-spin' />
                </div>
            )}
        </section>
    );
};

EmployerLongTermVacancies.propTypes = {
    employerId: PropTypes.number.isRequired,
    employer: PropTypes.shape({
        country_code: PropTypes.string.isRequired,
        test_employer: PropTypes.bool.isRequired,
        settings: PropTypes.shape({
            custom_shifts: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
    }).isRequired,
    employerShifts: PropTypes.arrayOf(PropTypes.shape({})),
    ltvProfiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })).isRequired,
    jobProfiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })).isRequired,
    fetchApplications: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        specializations: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            id: PropTypes.number,
            name: PropTypes.string,
            occupation_id: PropTypes.number,
        })),
        skills: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            id: PropTypes.number,
            name: PropTypes.string,
            occupation_id: PropTypes.number,
        })),
        intentions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }).isRequired,
};

const getProfiles = state => {
    const { profiles } = state.employer.profiles;
    const { ltvApplications } = state.applications;

    if (!profiles) {
        return [];
    }

    return profiles.filter(profile => profile.is_long_term && profile.status !== 2)
        .map(profile => {
            const applications = ltvApplications
                .filter(application => application.job.id === profile.id && application.status === 'PENDING');
            const bookings = ltvApplications
                .filter(application => application.job.id === profile.id && application.status === 'CONFIRMED');
            return {
                ...profile,
                applications,
                bookings,
            };
        });
};
const mapStateToProps = state => ({
    employerId: state.employer.employer.id,
    ltvProfiles: getProfiles(state),
    jobProfiles: state.employer.profiles.profiles.filter(profile => !profile.is_long_term && profile.status !== 2),
    allProfiles: state.employer.profiles.profiles,
    employer: state.employer.employer,
    employerShifts: state.shifts.employerShifts,
    consultants: state.consultants.consultants,
    consultantsFetching: state.consultants.consultantsFetching,
    allConsultants: state.employer.allConsultants,
    isFetchingEmployerShifts: state.shifts.isFetchingEmployerShifts,
    isDeletingShifts: state.shifts.isDeletingShifts,
    isCreatingShifts: state.shifts.isCreatingShifts,
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    fetchApplications: () => dispatch(fetchLtvApplications()),
    ...bindActionCreators({
        fetchEmployerShifts,
        removeEmployerShifts,
        fetchEmployersAllConsultants,
        createMultipleShifts,
        fetchConsultants,
        createAndAddMultipleShiftsToConsultant,
        createAndAddMultipleShiftsToLtv,
        removeShiftsFromBookedApplication,
        removeShiftsFromBookedApplicationAndDeleteShifts,
    }, dispatch),
    confirmLtvApplication: applicationId => dispatch(confirmLtvApplication(applicationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerLongTermVacancies);
