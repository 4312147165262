import { filter } from 'lodash';

const initialState = {

    faqs: [],
    faqCategories: [],
    faqsIsFetching: true,
    faqCategoriesIsFetching: true,
    faqCategoryIsSaving: false,
    faqIsSaving: false,
    isRemovingFaq: false,
};

export function faqs(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_FAQS':
            return {
                ...state,
                faqsIsFetching: true,
            };

        case 'RECEIVE_FETCH_FAQS':
            return {
                ...state,
                faqsIsFetching: false,
                faqs: action.faqs,
            };

        case 'REQUEST_ADD_FAQ_CATEGORY':
        case 'REQUEST_UPDATE_FAQ_CATEGORY':
            return {
                ...state,
                faqCategoryIsSaving: true,
            };

        case 'RECEIVE_ADD_FAQ_CATEGORY':
            return {
                ...state,
                faqCategoryIsSaving: false,
                faqs: state.faqs.concat([action.faq]),
            };

        case 'RECEIVE_UPDATE_FAQ_CATEGORY':
            return {
                ...state,
                faqCategoryIsSaving: false,
                faqs: state.faqs.map(faq => {
                    if (faq.id === action.id) {
                        return {
                            ...faq,
                            ...action.faq,
                        };
                    }

                    return faq;
                }),
            };

        case 'REQUEST_ADD_FAQ':
        case 'REQUEST_UPADTE_FAQ':
            return {
                ...state,
                faqIsSaving: true,
            };

        case 'RECEIVE_ADD_FAQ':
            return {
                ...state,
                faqIsSaving: false,
                faqs: state.faqs.map(faqCategory => {
                    if (faqCategory.id === action.faq.category_id) {
                        return {
                            ...faqCategory,
                            questions: faqCategory.questions.concat([action.faq]),
                        };
                    }

                    return faqCategory;
                }),
            };

        case 'REQUEST_UPDATE_FAQ':
            return {
                ...state,
                faqIsUpdating: true,
            };

        case 'RECEIVE_UPDATE_FAQ':
            return {
                ...state,
                faqIsUpdating: false,
                faqs: state.faqs.map(faqCategory => {
                    const originalCategoryId = action.faqCategoryId;
                    const newCategoryId = action.data.category_id;

                    if (originalCategoryId === newCategoryId) {
                        // Faq doesn't change category
                        if (faqCategory.id === originalCategoryId) {
                            return {
                                ...faqCategory,
                                questions: faqCategory.questions.map(faq => {
                                    if (faq.id === action.id) {
                                        return {
                                            ...faq,
                                            ...action.data,
                                        };
                                    }

                                    return faq;
                                }),
                            };
                        }

                        return faqCategory;
                    }

                    // Faq is moved to another category

                    if (faqCategory.id === newCategoryId) {
                        return {
                            ...faqCategory,
                            questions: faqCategory.questions.concat([{
                                ...action.data,
                                category_id: newCategoryId,
                                id: action.id,
                            }]),
                        };
                    }

                    return {
                        ...faqCategory,
                        questions: filter(faqCategory.questions, q => q.id !== action.id),
                    };
                }),
            };

        case 'REQUEST_REMOVE_FAQ':
            return {
                ...state,
                isRemovingFaq: true,
            };

        case 'RECEIVE_REMOVE_FAQ':
            return {
                ...state,
                isRemovingFaq: false,
                faqs: state.faqs.map(faqCategory => {
                    if (faqCategory.id === action.faqCategoryId) {
                        return {
                            ...faqCategory,
                            questions: filter(faqCategory.questions, q => q.id !== action.id),
                        };
                    }

                    return faqCategory;
                }),
            };

        default:
            return state;
    }
}
