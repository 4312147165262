import {
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

const useEmployerOccupationsIds = () => {
    const [occupationIds, setOccupationIds] = useState([]);

    const profiles = useSelector(state => state.employer.profiles.profiles);

    useEffect(() => {
        const profilesOccupations = profiles.map(profile => profile.occupation_id);
        setOccupationIds([...new Set(profilesOccupations)]);
    }, [profiles]);

    return occupationIds;
};

export default useEmployerOccupationsIds;
