import React, {
    useEffect,
    useState,
} from 'react';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as recruitmentActions from 'actions/recruitments';
import LoadingScreen from 'components/shared/loadingscreen';
import {
    occupationTitle,
    specializationTitle,
    jobPostingStatus,
} from 'utils/nameformatters';
import * as employerActions from 'actions/employer';
import './styles.scss';
import Avatar from 'components/shared/avatar';
import {
    Table,
    Switch,
    Tag,
    Popover,
} from 'antd';
import config from 'config';
import PropTypes from 'prop-types';

const Recruitments = ({
    recruitments,
    fetchRecruitments,
}) => {
    const [includeTestAccounts, setIncludeTestAccounts] = useState(false);
    const [includeRejectedApplications, setIncludeRejectedApplications] = useState(false);
    useEffect(() => {
        fetchRecruitments();
    }, [fetchRecruitments]);

    const toggleTestAccounts = () => setIncludeTestAccounts(state => (!state));
    const toggleRejectedApplications = () => setIncludeRejectedApplications(state => (!state));

    const excludeApplicationStatuses = [config.jobPostingApplicationStatus.USER_REMOVED];
    if (!includeRejectedApplications) {
        excludeApplicationStatuses.push(config.jobPostingApplicationStatus.REJECTED);
    }

    const renderAvatars = applications => {
        const avatars = applications.map(application => (
            <Popover
                content={(
                    <div className='recruitments-text'>
                        {`${application.user.firstname} ${application.user.lastname}`}
                    </div>
                )}
            >
                <Link to={`/consultants/${application.user.id}`}>
                    <div className='recruitments-avatar-view'>
                        <Avatar
                            className='recruitments-avatar'
                            size={30}
                            url={application.user.avatar}
                        />

                    </div>

                </Link>
            </Popover>

        ));

        return (
            <div className='recruitments-avatars'>
                {avatars}
            </div>
        );
    };

    const renderRecruitments = jobPostings => {
        const jobAds = jobPostings;
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
            }, {
                title: 'Occupation',
                dataIndex: 'occupation',
                key: 'occupation',
                render: (text, record) => {
                    const title = record.title || occupationTitle(record.occupation_id);
                    const occupation = occupationTitle(record.occupation_id);
                    const specialization = record.specialization_ids.length
                        ? specializationTitle(record.specialization_ids[0])
                        : null;
                    return (
                        <div>
                            <div className='font-bold'>{title}</div>
                            <div className='text-sm opacity-70'>
                                {occupation}
                            </div>
                            <div className='text-sm opacity-70'>
                                {specialization}
                            </div>
                        </div>
                    );
                },
                sorter: (a, b) => a.occupation_id - b.occupation_id,
            }, {
                title: 'Employer',
                dataIndex: 'employer',
                key: 'employer',
                render: (text, record) => {
                    const { employer } = record;
                    return (
                        <Link to={`/employers/${employer.id}`}>
                            {employer.name}
                        </Link>
                    );
                },
                sorter: (a, b) => (a.employer.name > b.employer.name ? 1 : -1),
            }, {
                title: 'Type',
                dataIndex: 'automotive',
                key: 'automotive',
                render: (text, record) => {
                    const label = record.employer.automotive ? 'Automotive' : 'Maintained';
                    const color = record.employer.automotive ? 'blue' : 'magenta';
                    return (
                        <Tag
                            color={color}
                        >
                            {label}
                        </Tag>
                    );
                },
                sorter: (a, b) => a.employer.automotive !== b.employer.automotive,
            }, {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => (
                    <div>{jobPostingStatus(record.job_posting_status)}</div>
                ),
                sorter: (a, b) => a.job_posting_status - b.job_posting_status,
            }, {
                title: 'Created time',
                dataIndex: 'createdtime',
                key: 'createdtime',
                render: (text, record) => (
                    <div>{moment.unix(record.created_time).format('D MMM')}</div>
                ),
                sorter: (a, b) => a.created_time - b.created_time,
            }, {
                title: 'Published time',
                dataIndex: 'publishedtime',
                key: 'publishedtime',
                render: (text, record) => {
                    if (record.job_posting_status === 0) {
                        return (
                            <div> This is not published yet. </div>
                        );
                    }

                    return (
                        <div>
                            {moment.unix(record.published_time).format('D MMM')}
                        </div>
                    );
                },
                sorter: (a, b) => a.published_time - b.published_time,
            }, {
                title: 'Applications',
                name: 'applications',
                key: 'applications',
                dataIndex: 'applications',
                render: (applications, record) => {
                    const activeApplications = applications.filter(
                        a => !excludeApplicationStatuses.includes(a.application_status),
                    );
                    if (activeApplications.length >= 1) {
                        return activeApplications.length ? renderAvatars(activeApplications) : '-';
                    } if (record.job_posting_status >= 0) {
                        return (
                            <div> No applications yet. </div>
                        );
                    }
                    return <></>;
                },
                sorter: (a, b) => a.job_posting_applications_count - b.job_posting_applications_count,
            }, {
                title: 'Last application time',
                dataIndex: 'lastapplicationtime',
                key: 'lastapplicationtime',

                render: (text, record) => {
                    if (record.job_posting_status === 0) {
                        return (
                            <div> This is not published yet. </div>
                        );
                    }
                    return (
                        <div>{moment.unix(record.last_application_time).format('D MMM')}</div>
                    );
                },
                sorter: (a, b) => a.last_application_time - b.last_application_time,
            }, {
                title: 'Details',
                dataIndex: 'details',
                key: 'details',
                render: (text, record) => {
                    const { employer } = record;
                    return (
                        <Link to={`/employers/${employer.id}/job-ads`}>
                            Details
                        </Link>
                    );
                },
            }];

        return (
            <Table
                className='recruitments-view-background'
                columns={columns}
                dataSource={jobAds}
                pagination={false}
                rowClassName={row => {
                    if (
                        row.job_posting_status === config.jobPostingStatus.PUBLISHED
                        && row.last_application_time < moment().add(7, 'days').unix()) {
                        return 'expiration-warning';
                    } return '';
                }}
                rowKey='id'
            />
        );
    };

    const renderTestAccountFilter = () => (
        <label className='toggle-test-accounts'>
            <Switch
                checked={includeTestAccounts}
                style={{ marginRight: 5 }}
                onChange={toggleTestAccounts}
            />
            Include test accounts
        </label>
    );

    const renderIncludeRejectedApplicationsFilter = () => (
        <label className='toggle-test-accounts'>
            <Switch
                checked={includeRejectedApplications}
                style={{ marginRight: 5 }}
                onChange={toggleRejectedApplications}
            />
            Include rejected applications
        </label>
    );

    if (!recruitments.hasFetched) {
        return (
            <LoadingScreen
                fullScreen
                text='Eat your greens'
            />
        );
    }

    let jobPostings = recruitments.job_postings;

    if (!includeTestAccounts) {
        jobPostings = filter(jobPostings, a => !a.employer.test_employer);
    }

    return (
        <main className='mpa-recruitments'>
            <h3>
                Job postings
            </h3>
            {renderTestAccountFilter()}
            {renderIncludeRejectedApplicationsFilter()}
            {renderRecruitments(jobPostings)}
        </main>
    );
};

Recruitments.propTypes = {
    recruitments: PropTypes.arrayOf(PropTypes.shape(
        {
            id: PropTypes.number.isRequired,
            employer: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                automotive: PropTypes.bool.isRequired,
            }).isRequired,
            job_posting_status: PropTypes.number.isRequired,
            created_time: PropTypes.number.isRequired,
            published_time: PropTypes.number.isRequired,
            last_application_time: PropTypes.number.isRequired,
            job_posting_applications_count: PropTypes.number.isRequired,
        },
    )).isRequired,
    fetchRecruitments: PropTypes.func.isRequired,
};

export default connect(
    state => ({
        ...state,
        recruitments: {
            ...state.recruitments,
            job_postings: state.recruitments.job_postings.filter(p => p.created_time > moment('2022-12-01').unix()),
        },
    }),
    dispatch => bindActionCreators({
        ...employerActions, ...recruitmentActions,
    }, dispatch),
)(Recruitments);
