const initialState = {
    isFetching: true,
    comments: [],
};

export function comments(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_USER_COMMENTS':
        case 'REQUEST_FETCH_EMPLOYER_COMMENTS':
            return {
                ...state,
                isFetching: true,
                comments: [],
            };

        case 'RECEIVE_FETCH_USER_COMMENTS':
        case 'RECEIVE_FETCH_EMPLOYER_COMMENTS':
            return {
                ...state,
                isFetching: false,
                comments: action.comments,
            };

        case 'RECEIVE_ADD_USER_COMMENT':
        case 'RECEIVE_ADD_EMPLOYER_COMMENT':
            return {
                ...state, comments: state.comments.concat([action.comment]),
            };

        case 'RECEIVE_UPDATE_TASK':
            return {
                ...state,
                comments: state.comments.map(c => {
                    if (c.task_id !== action.task.id) {
                        return c;
                    }
                    return {
                        ...c, task: action.task,
                    };
                }),
            };

        default:
            return state;
    }
}
