import * as consultantsActions from 'actions/consultants';
import * as employerActions from 'actions/employer';
import * as shiftsActions from 'actions/shifts';
import {
    Avatar,
    Button,
    Divider,
    Drawer,
    Input,
    Modal,
    Popover,
    Radio,
} from 'antd';
import InputModal from 'components/inputmodal';
import EditTrigger from 'components/shared/edittrigger';
import config from 'config';
import {
    debounce,
    find,
    map,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { humanReadableHours } from 'utils/datetimeformatters';
import modalController from 'utils/modalcontroller';
import {
    occupationTitle,
    skillTitle,
} from 'utils/nameformatters';

import { CloseOutlined } from '@ant-design/icons';
import ReplaceConsultantPopover from '../employershiftsitems/employershiftsmonth/replaceconsultantpopover';
import EditShiftModal from './editshiftmodal';

class EmployerShiftSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAssignConsultantModal: false,
            searchQuery: '',
            selectedConsultant: null,
            searchResults: [],
            showEditShiftModal: false,
            warningActivated: false,
            cancellationReasonId: null,

        };

        this.debouncedSearch = debounce(this.doSearch.bind(this), 500);
    }

    componentWillMount() {
        if (this.props.shiftId) {
            this.props.fetchShift(this.props.shiftId);
        }
    }

    componentDidUpdate(prevProps) {
        const shiftIdChanged = (this.props.shiftId !== prevProps.shiftId);

        if (shiftIdChanged && this.props.shiftId) {
            this.props.fetchShift(this.props.shiftId);
        }

        if (prevProps.consultants.length !== this.props.consultants.length) {
            this.setState({ searchResults: this.props.consultants });
        }
    }

    renderLoader() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 32,
                }}
            >
                <span
                    className='fa fa-circle-o-notch fa-spin'
                />
            </div>
        );
    }

    doSearch() {
        const query = this.state.searchQuery;

        const { test_employer } = this.props.employer.employer;

        if (query.length < 3) {
            return this.setState({ searchResults: [] });
        }

        this.props.fetchConsultants({
            query,
            page: 0,
            includeTestAccounts: test_employer,
        });
    }

    startSearch(e) {
        this.setState({
            searchQuery: e.target.value,
            selectedConsultant: null,
        });
        this.debouncedSearch();
    }

    onSubmit() {
        this.props.assignConsultantToShift(this.props.shiftId, this.props.shift.profile.id, this.state.selectedConsultant.id).then(() => {
            this.setState({ showAssignConsultantModal: false });
        });
    }

    renderSalaryInfo() {
        const {
            shift,
            updateShift,
        } = this.props;
        const { shiftStatus } = config;
        const { occupation_id } = shift.profile;
        const shiftIsBooked = shift.shift_status === shiftStatus.BOOKED;
        const booking = shift.scheduled[0];
        const bookedConsultantIsSick = shift.shift_status === shiftStatus.SICK_LEAVE;
        const marginOptions = this.props.settings.marginOptions.map(option => {
            const occupation = option.occupations.find(occupation => occupation.id === occupation_id);
            return {
                value: occupation,
                title: `salary: ${occupation.salary_margin}% consultant fee: ${occupation.consultant_fee_margin}%`,
            };
        });
        const salary = shiftIsBooked ? booking.salary : shift.salary;
        const shiftBreak = shiftIsBooked ? booking.shift_break : shift.shift_break;
        const price = shiftIsBooked ? booking.price : shift.price;
        const consultantPrice = shiftIsBooked ? booking.consultant_fee : shift.consultant_fee;
        const shiftDuration = (shift.duration - shiftBreak);
        const pension = shiftIsBooked ? booking.pension : shift.pension;
        const totalPension = pension * shiftDuration;
        return (
            <div>
                <div>
                    <strong>Price:</strong>
                    <p>
                        {price}
                        {' '}
                        SEK/h (Total
                        {' '}
                        {price * shiftDuration}
                        {' '}
                        SEK)
                        {(shiftIsBooked && !booking.is_paid)
                            ? (
                                <EditTrigger
                                    style={{
                                        marginTop: 0,
                                        display: 'inline-block',
                                        paddingLeft: 10,
                                    }}
                                    title=''
                                    onClick={() => {
                                        this.setState({});

                                        modalController.showModal(InputModal, {
                                            title: `Edit price on shift ID: ${shift.id}`,
                                            width: 600,
                                            style: { overflow: 'visible' },
                                            props: {
                                                inputs: [
                                                    {
                                                        type: 'input',
                                                        name: 'price',
                                                        label: 'Price for customer to pay for this shift',
                                                        value: price,
                                                    },
                                                    {
                                                        type: 'switch',
                                                        name: 'updateSalary',
                                                        label: 'Update salary',
                                                        optionLabels: {
                                                            on: 'Yes',
                                                            off: 'No',
                                                        },
                                                        value: true,
                                                    },
                                                ],
                                                onSave: formData => {
                                                    const result = {
                                                        price: +formData.price,
                                                        update_salary: formData.updateSalary,
                                                    };

                                                    if (result.price !== price) {
                                                        return this.props.updateBookedShift(shift.scheduled[0].id, result);
                                                    }
                                                },
                                            },
                                        });
                                    }}
                                />
                            )
                            : null}
                    </p>
                </div>
                <div>
                    {!(bookedConsultantIsSick || (booking && booking.is_paid)) && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <strong>Margin:</strong>
                            <EditTrigger
                                style={{
                                    marginTop: 0,
                                    display: 'inline-block',
                                    paddingRight: 10,
                                }}
                                title='Set margin'
                                onClick={() => {
                                    modalController.showModal(InputModal, {
                                        title: `Set margin on shift #${shift.id}`,
                                        width: 600,
                                        style: { overflow: 'visible' },
                                        props: {
                                            inputs: [
                                                {
                                                    type: 'select',
                                                    name: 'shift_margin',
                                                    label: 'Set shift margin',
                                                    options: marginOptions,
                                                },
                                            ],
                                            onSave: formData => {
                                                const newFormData = { ...formData };
                                                newFormData.margin = newFormData.shift_margin.salary_margin;
                                                newFormData.consultant_fee_margin = newFormData.shift_margin.consultant_fee_margin;
                                                delete newFormData.shift_margin;
                                                return updateShift(shift.id, newFormData);
                                            },
                                        },
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <hr />
                <h6
                    style={{ paddingBottom: 5 }}
                >
                    Salary info
                </h6>
                <div>
                    <strong>Salary:</strong>
                    <p>
                        {salary}
                        {' '}
                        SEK/h (Totalt
                        {' '}
                        {salary * shiftDuration}
                        {' '}
                        SEK)
                    </p>
                </div>
                <div>
                    <strong>Extra salary (by adjusted margin):</strong>
                    <p
                        style={{
                            opacity: !shift.extra_salary ? 0.3 : 1,
                            color: shift.extra_salary ? '#36d671' : '',
                        }}
                    >
                        {shift.extra_salary}
                        {' '}
                        SEK/h (Totalt
                        {' '}
                        {shift.extra_salary * shiftDuration}
                        {' '}
                        SEK)
                    </p>
                </div>
                <div>
                    <strong>Pension:</strong>
                    <p>
                        {shift.scheduled.length < 1 || (shift.scheduled.length && shift.scheduled[0].has_pension) ? (
                            <>
                                {pension}
                                {' '}
                                SEK/h (Totalt
                                {' '}
                                {totalPension}
                                {' '}
                                SEK)
                            </>
                        ) : '-'}

                    </p>
                </div>
                <div>
                    <strong>Our margin</strong>
                    <p
                        style={{ marginBottom: 0 }}
                    >
                        <p>
                            Salary:
                            {shift.margin}
                            %
                        </p>
                    </p>
                </div>
                <hr />
                <h6
                    style={{ paddingBottom: 5 }}
                >
                    Invoice info
                </h6>
                <div>
                    <strong>Compensation:</strong>
                    <p>
                        {consultantPrice}
                        {' '}
                        SEK/h (Total
                        {' '}
                        {consultantPrice * shiftDuration}
                        {' '}
                        SEK)
                    </p>
                </div>
                <div>
                    <strong>Extra compensation (by adjusted margin):</strong>
                    <p
                        style={{
                            opacity: !shift.extra_consultant_fee ? 0.3 : 1,
                            color: shift.extra_consultant_fee ? '#36d671' : '',
                        }}
                    >
                        {shift.extra_consultant_fee}
                        {' '}
                        SEK/h (Totalt
                        {' '}
                        {shift.extra_consultant_fee * shiftDuration}
                        {' '}
                        SEK)
                    </p>
                </div>
                <div>
                    <strong>Our margin:</strong>
                    <p>
                        <span>
                            Invoice:
                            {' '}
                            {shift.consultant_fee_margin}
                            %
                        </span>
                    </p>
                </div>
                <div>
                    <strong>Salary shown on app:</strong>
                    <p>
                        {shift.is_price_hidden ? 'No' : 'Yes'}
                    </p>
                </div>
            </div>
        );
    }

    renderTop() {
        const { shift } = this.props;
        const { shiftStatus } = config;
        const bookedConsultantIsSick = shift.shift_status === shiftStatus.SICK_LEAVE;
        const shiftStatusRemovedByEmployer = shift.shift_status === shiftStatus.CANCEL_BY_EMPLOYER;
        const shiftStatusRemovedByAdmin = shift.shift_status === shiftStatus.CANCEL_BY_ADMIN;
        const isBooked = shift.shift_status === shiftStatus.BOOKED;
        const isPaid = isBooked && shift.scheduled[0].is_paid;

        let style;
        let statusText;

        if (bookedConsultantIsSick) {
            statusText = 'Sick submitted';
            style = { color: '#d9534f' };
        }
        else if (isBooked && !isPaid) {
            statusText = 'Booked';
            style = {
                color: '#36d671',
                textDecoration: 'underline',
            };
        }
        else if (isBooked && isPaid) {
            statusText = 'Paid';
            style = {
                color: '#228B22',
                textDecoration: 'underline',
            };
        }
        else if (shift.shift_status === 3) {
            statusText = 'Removed';
            style = { color: '#d9534f' };
        }
        else if (shift.shift_status === 0) {
            statusText = 'Draft (not published)';
            style = { color: '#d9534f' };
        }
        else if (shiftStatusRemovedByEmployer) {
            statusText = 'Employer cancelled';
            style = { color: '#d9534f' };
        }
        else if (shiftStatusRemovedByAdmin) {
            statusText = 'Medpeople cancelled';
            style = { color: '#d9534f' };
        }
        else {
            statusText = 'Not booked';
            style = { color: '#000' };
        }

        return (
            <div>
                {shift.introduction
                    && (
                        <p
                            style={{ textDecoration: 'underline' }}
                        >
                            <strong>This is a introduction shift</strong>
                        </p>
                    )}
                <h4
                    style={{ marginBottom: 20 }}
                >
                    Status:
                    <span
                        style={({
                            marginLeft: 10,
                            ...style,
                        })}
                    >
                        {statusText}
                    </span>
                </h4>
                <h2
                    style={{ display: 'flex' }}
                >
                    {moment.unix(shift.start_time).format('HH:mm')}
                    {' '}
                    -
                    {moment.unix(shift.end_time).format('HH:mm')}
                    <span
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 14,
                            fontWeight: '600',
                            lineHeight: '17px',
                            marginLeft: 15,
                            marginTop: 1,
                        }}
                    >
                        <span>
                            Break:
                            {humanReadableHours(shift.shift_break)}
                        </span>
                        <span>
                            Total:
                            {humanReadableHours(shift.duration - shift.shift_break)}
                        </span>
                    </span>
                </h2>
                <p
                    style={{
                        fontSize: 26,
                        lineHeight: '30px',
                        color: '#999',
                        fontWeight: '600',
                        marginBottom: 5,
                    }}
                >
                    {moment.unix(shift.start_time).format('DD/MM')}
                    {' '}
                    -
                    {moment.unix(shift.end_time).format('DD/MM')}
                </p>
                <p
                    style={{
                        fontSize: 14,
                        lineHeight: '30px',
                    }}
                >
                    <div>
                        <strong>Last application date:</strong>
                        {'  '}
                        {moment.unix(shift.last_application_datetime).format('YYYY-MM-DD')}
                    </div>

                </p>
                <p
                    style={{
                        fontSize: 14,
                        lineHeight: '18px',
                    }}
                >
                    <div>
                        <strong>App URL: </strong>
                        {' '}
                        https://medpeople.app.link/medpeople://jobDetails/
                        {shift.profile.id}
                    </div>
                    <div>
                        <strong>ID: </strong>
                        {' '}
                        #
                        {shift.id}
                    </div>
                    <div
                        style={{ opacity: shift.order_id ? 1 : 0.2 }}
                    >
                        <strong>Order ID: </strong>
                        {' '}
                        #
                        {shift.order_id}
                    </div>
                </p>
            </div>
        );
    }

    renderinfo() {
        const { shift } = this.props;

        return (
            <div>
                <p
                    style={{
                        fontSize: 18,
                        marginTop: 10,
                    }}
                >
                    {occupationTitle(shift.profile.occupation_id)}
                </p>
                <p style={{ margin: 0 }}>
                    <strong>Specialist Eduction: </strong>
                    {shift.profile.specialization_ids.length ? shift.profile.specialization_ids.map((specializationId, index) => (
                        `${index === 0 ? '' : ', '}${this.props.settings.specializations.find(s => s.id === specializationId).name}`
                    )) : 'Not specified'}
                </p>
                <strong>Skills: </strong>
                {shift.profile.skills.length ? shift.profile.skills.map((skill, i) => {
                    if (shift.profile.skills.length === i + 1) {
                        return skillTitle(skill, shift.profile.occupation_id);
                    }

                    return ` ${skillTitle(skill, shift.profile.occupation_id)}, `;
                }) : 'Ej specificerat'}

                <p>
                    <strong>Driver license mandatory: </strong>
                    {' '}
                    {shift.profile.driver_license ? 'Ja' : 'Nej'}
                </p>
            </div>
        );
    }

    removeShift(employerId, shiftId) {
        return this.props.removeEmployerShift(employerId, shiftId);
    }

    showRemoveShiftModal(shift) {
        const { confirm } = Modal;

        const date = moment.unix(shift.start_time).format('YYYY-MM-DD');
        const starts = moment.unix(shift.start_time).format('HH:mm');
        const ends = moment.unix(shift.end_time).format('HH:mm');
        const { employer } = this.props;
        const content = (
            <div>
                <div
                    style={{ fontWeight: 600 }}
                >
                    {employer.name}
                </div>
                <div>
                    <strong>
                        Shift:
                        {shift.id}
                    </strong>
                </div>
                <div>
                    {date}
                </div>
                <div>
                    {starts}
                    {' '}
                    -
                    {ends}
                </div>
            </div>
        );

        return confirm({
            title: 'Are you sure you want to remove the shift?',
            content,
            okText: 'Yes, remove shift',
            okType: 'danger',
            cancelText: 'Abort',
            maskClosable: true,
            zIndex: 99999,
            onOk: () => {
                this.props.onClose();
                this.removeShift(employer.employer.id, shift.id);
            },
        });
    }

    renderDeleteUnbookedShift() {
        const { shift } = this.props;
        const { shiftStatus } = config;
        const isBooked = shift.shift_status === shiftStatus.BOOKED;

        if (isBooked) {
            return null;
        }

        return (
            <div
                style={{ marginTop: 20 }}
            >
                <Button
                    danger
                    onClick={() => {
                        this.showRemoveShiftModal(shift);
                    }}
                >
                    Remove unbooked shift
                </Button>
            </div>
        );
    }

    renderAssignConsultantToUnbookedShift() {
        const { shift } = this.props;
        const { shiftStatus } = config;
        const isBooked = shift.shift_status === shiftStatus.BOOKED;

        if (isBooked) {
            return null;
        }

        return (
            <div
                style={{ marginTop: 10 }}
            >
                <Button
                    danger
                    onClick={() => {
                        this.setState({ showAssignConsultantModal: true });
                    }}
                >
                    Add consultant
                </Button>
            </div>
        );
    }

    renderConsultantRow({
        avatar,
        email,
        firstname,
        id,
        lastname,
    }) {
        const avatarPlaceholder = (firstname && lastname) ? firstname[0] + lastname[0] : '?';

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 0',
                }}
            >
                <div>
                    <Avatar
                        size='large'
                        src={avatar}
                        style={{ marginRight: 10 }}
                    >
                        {avatar ? '' : avatarPlaceholder}
                    </Avatar>
                </div>
                <div>
                    <div
                        style={{ fontWeight: '600' }}
                    >
                        {`${firstname} ${lastname} (ID ${id})`}
                    </div>
                    <div>
                        {email}
                    </div>
                </div>
            </div>
        );
    }

    renderConsultantSearchResults() {
        if (this.state.searchResults.length && !this.state.selectedConsultant) {
            return (
                <div
                    style={{ marginTop: 15 }}
                >
                    {this.state.searchResults.map(consultant => (
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.setState({
                                    searchResults: [],
                                    searchQuery: '',
                                    selectedConsultant: consultant,
                                });
                            }}
                        >
                            {this.renderConsultantRow(consultant)}
                        </div>
                    ))}
                </div>
            );
        }
    }

    renderSelectedConsultant() {
        if (this.state.selectedConsultant) {
            return (
                <div
                    style={{
                        padding: 20,
                        background: '#FAFAFC',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                        }}
                    >
                        <Button
                            danger
                            icon={<CloseOutlined />}
                            shape='circle'
                            size='small'
                            onClick={() => {
                                this.setState({ selectedConsultant: null });
                            }}
                        />
                    </div>
                    {this.renderConsultantRow(this.state.selectedConsultant)}
                </div>
            );
        }
    }

    renderAssignConsultantModal() {
        return (
            <Modal
                footer={null}
                title='Add consultant'
                visible={this.state.showAssignConsultantModal}
                width={1024}
                onCancel={() => this.setState({
                    showAssignConsultantModal: false,
                    searchQuery: '',
                    searchResults: [],
                    selectedConsultant: null,
                })}
            >
                <Divider orientation='left'>
                    <h5 style={{ margin: 0 }}>
                        Consultant
                    </h5>
                </Divider>
                <Input
                    placeholder='Search consultant, name or ID...'
                    size='large'
                    style={{ display: this.state.selectedConsultant ? 'none' : 'block' }}
                    value={this.state.searchQuery}
                    onChange={this.startSearch.bind(this)}
                />
                {this.renderSelectedConsultant()}
                {this.renderConsultantSearchResults()}
                <Button
                    disabled={!this.state.selectedConsultant}
                    size='large'
                    style={{ marginTop: 10 }}
                    type='primary'
                    onClick={this.onSubmit.bind(this)}
                >
                    Add consultant
                </Button>
            </Modal>
        );
    }

    renderApplicantAvatars() {
        const {
            shift,
            employer,
        } = this.props;
        const { shiftStatus } = config;
        const hasShiftBeenPublished = shift.shift_status === shiftStatus.CANCEL_BY_ADMIN || shiftStatus.CANCEL_BY_EMPLOYER;
        const hasApplicants = shift.scheduled && shift.scheduled.length && (shift.shift_status === shiftStatus.PUBLISHED || hasShiftBeenPublished);
        const isBooked = shift.shift_status === shiftStatus.BOOKED;
        const consultantIsRegisteredSick = isBooked && shift.scheduled[0].sick_leave;
        const shiftIsPaid = isBooked && shift.scheduled[0].is_paid;
        const consultants = employer.allConsultants.collection;
        if (!hasApplicants) {
            return null;
        }

        const applications = hasApplicants ? shift.scheduled : [];
        const applicants = applications.map(application => {
            const applicant = find(consultants, c => c.user.id === application.user.id);
            return {
                ...applicant,
                application_id: application.application_id,
            };
        });

        const avatars = applicants.map(applicant => {
            const { user } = applicant;
            const content = (
                <div>
                    <div>
                        <strong>
                            {user.firstname}
                            {' '}
                            {user.lastname}
                        </strong>
                    </div>
                    <div>
                        {user.phone}
                    </div>
                    <div>
                        {user.email}
                    </div>
                    <div>
                        <Link>
                            Booking ID:
                            {' '}
                            {applicant.application_id}
                        </Link>
                    </div>
                    <div>
                        Created:
                        {' '}
                        {moment.unix(shift.published_time).format('YYYY-MM-DD, HH:mm')}
                    </div>
                    {(!consultantIsRegisteredSick && !shiftIsPaid)
                        && (
                            <ReplaceConsultantPopover
                                applicant={applicant}
                                fetchEmployerShifts={this.props.fetchEmployerShifts}
                                isApplication
                                isBooking={null}
                                shift={shift}
                            />
                        )}
                </div>
            );

            return (
                <Popover content={content} placement='leftBottom' trigger='hover' zIndex={2001}>
                    <Link
                        style={{
                            marginBottom: 5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        to={`/consultants/${user.id}`}
                    >
                        <Avatar
                            gender={user.gender}
                            size={30}
                            src={user.avatar}
                            style={{ marginRight: 5 }}
                        />
                        {user.firstname}
                        {' '}
                        {user.lastname}
                    </Link>
                </Popover>
            );
        });

        return (
            <div>
                <p
                    style={{ marginTop: 20 }}
                >
                    <strong>
                        Applications:
                    </strong>
                </p>
                {avatars}
            </div>
        );
    }

    renderBookedAvatar() {
        const { shiftStatus } = config;
        const {
            employer,
            fetchEmployerShifts,
            shift,
        } = this.props;
        const isBooked = shift.shift_status === shiftStatus.BOOKED;
        const consultantIsRegisteredSick = isBooked && shift.scheduled[0].sick_leave;
        const shiftIsPaid = isBooked && shift.scheduled[0].is_paid;
        const consultants = employer.allConsultants.collection;

        if (!isBooked) {
            return null;
        }

        const applicants = map(shift.scheduled, application => {
            const applicant = find(consultants, c => c.user.id === application.user.id);
            return {
                ...applicant,
                application_id: application.application_id,
            };
        });

        const avatars = applicants.map(applicant => {
            const { user } = applicant;

            if (!user) {
                return;
            }

            const content = (
                <div>
                    <div>
                        <strong>
                            {user.firstname}
                            {' '}
                            {user.lastname}
                        </strong>
                    </div>
                    <div>
                        {user.phone}
                    </div>
                    <div>
                        {user.email}
                    </div>
                    <div>
                        <Link>
                            Booking ID:
                            {' '}
                            {applicant.application_id}
                        </Link>
                    </div>
                    <div>
                        Created:
                        {' '}
                        {moment.unix(shift.published_time).format('YYYY-MM-DD, HH:mm')}
                    </div>
                    {(!consultantIsRegisteredSick && !shiftIsPaid)
                        && (
                            <ReplaceConsultantPopover
                                applicant={applicant}
                                fetchEmployerShifts={fetchEmployerShifts}
                                isBooking={isBooked}
                                shift={shift}
                            />
                        )}
                </div>
            );

            return (
                <Popover content={content} placement='leftBottom' zIndex={2001}>
                    <div>
                        <Link to={`/consultants/${user.id}`}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    paddingBottom: 0,
                                }}
                            >
                                <div
                                    style={{ marginRight: 10 }}
                                >
                                    <Avatar
                                        size={70}
                                        src={user.avatar}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h4>
                                        {user.firstname}
                                        {' '}
                                        {user.lastname}
                                    </h4>
                                    <div
                                        style={{ color: '#999' }}
                                    >
                                        {occupationTitle(user.occupation)}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </Popover>
            );
        });

        return (
            <div>
                <p
                    style={{ marginTop: 20 }}
                >
                    <strong>
                        Bookings:
                    </strong>
                </p>
                {avatars}
            </div>
        );
    }

    renderContent() {
        const {
            shift, settings, onClose,
        } = this.props;
        const { shiftStatus } = config;
        const hasApplicants = shift.scheduled && !!shift.scheduled.length && shift.shift_status === shiftStatus.PUBLISHED;
        const isBooked = shift.shift_status === shiftStatus.BOOKED;
        const shiftStatusRemoved = shift.shift_status === shiftStatus.DELETED;
        const shiftStatusRemovedByEmployer = shift.shift_status === shiftStatus.CANCEL_BY_EMPLOYER;
        const shiftStatusRemovedByAdmin = shift.shift_status === shiftStatus.CANCEL_BY_ADMIN;

        if (shiftStatusRemovedByEmployer || shiftStatusRemovedByAdmin) {
            return (
                <div
                    style={{
                        pointerEvents: shiftStatusRemoved ? 'none' : '',
                        opacity: shiftStatusRemoved ? 0.5 : 1,
                        padding: 40,
                    }}
                >
                    {this.renderTop()}
                    da
                    {this.renderinfo()}
                    {this.renderApplicantAvatars()}
                </div>
            );
        }

        const onCancelWarning = () => {
            this.setState({
                warningActivated: true,
            });
        };

        const onSendCancelWarning = () => {
            this.props.cancelConsultantFromSchedule(
                {
                    user_id: shift.scheduled[0].user.id,
                    schedule_id: shift.scheduled[0].id,
                    cancel_reason_id: this.state.cancellationReasonId,
                },
            );
            onClose();
            setTimeout(() => {
                window.location.reload();
            }, 500);
        };
        return (
            <div
                style={{
                    pointerEvents: shiftStatusRemoved ? 'none' : '',
                    opacity: shiftStatusRemoved ? 0.5 : 1,
                    padding: 40,
                }}
            >
                {this.renderTop()}
                {this.renderinfo()}
                {this.renderSalaryInfo()}
                {isBooked && this.renderBookedAvatar()}
                {hasApplicants && this.renderApplicantAvatars()}
                <div className='mt-4'>
                    <Button
                        className='mr-2'
                        danger
                        onClick={() => { this.setState({ showEditShiftModal: true }); }}
                    >
                        Edit shift
                    </Button>
                    { shift.scheduled?.length > 0 && (
                        <Button
                            danger
                            onClick={onCancelWarning}
                        >
                            Cancel shift
                        </Button>
                    )}
                    {this.state.warningActivated && (
                        <div>
                            <div className='font-semibold my-2'>Vänligen ange skäl till cancellering</div>
                            <ul>
                                {settings.cancellation_reasons.map(r => (
                                    <li
                                        key={r.id}
                                        className='mb-1 p-0'
                                        onClick={() => {
                                            this.setState({
                                                cancellationReasonId: r.id,
                                            });
                                        }}
                                    >
                                        <Radio key={r.message} checked={r.id === this.state.cancellationReasonId} value={r.id}>{r.message}</Radio>
                                    </li>
                                ))}
                            </ul>
                            <div>
                                <Button
                                    disabled={this.state.cancellationReasonId === null}
                                    onClick={onSendCancelWarning}
                                >
                                    Send cancelled passes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                {!hasApplicants && this.renderDeleteUnbookedShift()}
                {this.renderAssignConsultantToUnbookedShift()}
                {this.renderAssignConsultantModal()}

            </div>
        );
    }

    render() {
        const {
            fetchEmployerShifts,
            checkShifts,
            open,
            onClose,
            isFetchingShift,
            shift,
            employer: {
                employer,
                profiles: { profiles },
            },
            replaceShift,
            replaceBookedShift,
        } = this.props;

        const { showEditShiftModal } = this.state;
        const showDrawer = typeof open !== 'undefined';

        return (
            <Drawer
                isLoading={isFetchingShift && shift}
                maskClosable
                placement='right'
                style={{
                    overflow: 'visible',
                    padding: 0,
                }}
                visible={showDrawer}
                width={400}
                onClose={onClose}
            >
                {!!shift && this.renderContent()}
                {showEditShiftModal && (
                    <EditShiftModal
                        checkShifts={checkShifts}
                        close={() => this.setState({ showEditShiftModal: false })}
                        customShifts={employer.settings.custom_shifts}
                        profiles={profiles}
                        saveShift={shiftData => {
                            if (shift.scheduled && shift.scheduled.length) {
                                const applicationsIds = shift.scheduled.map(sch => sch.application_id);
                                replaceBookedShift(applicationsIds, shift.id, shiftData, employer.id)
                                    .then(() => fetchEmployerShifts(employer.id));
                                // eslint-disable-next-line brace-style
                            } else {
                                replaceShift(employer.id, shift.id, shiftData)
                                    .then(() => fetchEmployerShifts(employer.id));
                            }
                            onClose();
                        }}
                        shift={shift}
                        visible={showEditShiftModal}
                    />
                )}
            </Drawer>
        );
    }
}

EmployerShiftSidebar.propTypes = {
    checkShifts: PropTypes.func.isRequired,
    fetchEmployerShifts: PropTypes.func.isRequired,
    updateBookedShift: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isFetchingShift: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    shift: PropTypes.shape({
        start_time: PropTypes.number,
        end_time: PropTypes.number,
        id: PropTypes.number,
        pension: PropTypes.number,
        duration: PropTypes.number,
        consultant_fee: PropTypes.number,
        scheduled: PropTypes.arrayOf(PropTypes.shape({
            consultant_fee: PropTypes.number,
            duration: PropTypes.number,
            application_id: PropTypes.number,
            is_paid: PropTypes.bool,
            pension: PropTypes.number,
            has_pension: PropTypes.bool,
        })),
        shift_status: PropTypes.number,
        margin: PropTypes.number.isRequired,
        consultant_fee_margin: PropTypes.number.isRequired,
        last_application_datetime: PropTypes.number,
        is_price_hidden: PropTypes.bool,
    }).isRequired,
    replaceShift: PropTypes.func.isRequired,
    replaceBookedShift: PropTypes.func.isRequired,
    updateShift: PropTypes.func.isRequired,
    employer: PropTypes.shape({
        employer: PropTypes.shape({
            id: PropTypes.number,
            // eslint-disable-next-line object-curly-newline
            settings: PropTypes.shape({
                custom_shifts: PropTypes.arrayOf(PropTypes.shape({
                    start_time: PropTypes.string,
                    end_time: PropTypes.string,
                    price: PropTypes.number,
                    shift_break: PropTypes.number,
                })).isRequired,
                // eslint-disable-next-line object-curly-newline
            }).isRequired,
        }).isRequired,
        profiles: PropTypes.shape({ profiles: PropTypes.arrayOf(PropTypes.shape({})) }),
    }).isRequired,
    last_application_datetime: PropTypes.string.isRequired,
    is_price_hidden: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    consultants: state.consultants.consultants,
    shift: state.shifts.shift,
    employer: state.employer,
    shiftHasBeenUpdated: state.shifts.shiftHasBeenUpdated,
    isFetchingShift: state.shifts.isFetchingShift,

    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    checkShifts: bindActionCreators(employerActions.checkShifts, dispatch),
    replaceShift: bindActionCreators(employerActions.replaceShift, dispatch),
    replaceBookedShift: bindActionCreators(employerActions.replaceBookedShift, dispatch),
    fetchConsultants: bindActionCreators(consultantsActions.fetchConsultants, dispatch),
    fetchShift: bindActionCreators(shiftsActions.fetchShift, dispatch),
    updateShift: bindActionCreators(shiftsActions.updateShift, dispatch),
    updateBookedShift: bindActionCreators(shiftsActions.updateBookedShift, dispatch),
    fetchEmployerShifts: bindActionCreators(shiftsActions.fetchEmployerShifts, dispatch),
    removeEmployerShift: bindActionCreators(employerActions.removeEmployerShift, dispatch),
    assignConsultantToShift: bindActionCreators(employerActions.assignConsultantToShift, dispatch),
    cancelConsultantFromSchedule: bindActionCreators(employerActions.cancelConsultantFromSchedule, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerShiftSidebar);
