import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as employerActions from 'actions/employer';
import EditTrigger from 'components/shared/edittrigger';
import InputModal from 'components/inputmodal';

import {
    Button,
    Modal,
    Table,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

class CustomShifts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCreateCustomShiftModal: false,
            showEditCustomShiftModal: false,
            editModalShift: null,
            formData: {},
            error: '',
        };
    }

    onClose() {
        this.setState({
            showCreateCustomShiftModal: false,
            showEditCustomShiftModal: false,
            editModalShift: null,
            formData: {},
        });
    }

    validateForm = data => {
        const keys = Object.keys(data);
        return !!keys.length && keys.reduce((acc, key) => {
            if (!data[key] && data[key] !== 0) {
                return false;
            }
            return acc;
        }, true);
    }

    renderEditCustomShiftModal() {
        const shift = this.state.editModalShift;
        const { employer } = this.props.employer;
        const content = !shift ? null : (
            <InputModal
                ref='editCustomShift'
                hideButton
                inputs={
                    [{
                        type: 'input',
                        name: 'name',
                        label: 'Name',
                        value: shift.name,
                        required: true,
                    }, {
                        type: 'input',
                        name: 'start_time',
                        label: 'Start time',
                        value: shift.start_time,
                        required: true,
                        inputType: 'time',
                    }, {
                        type: 'input',
                        name: 'end_time',
                        label: 'End time',
                        value: shift.end_time,
                        required: true,
                        inputType: 'time',
                    }, {
                        type: 'input',
                        name: 'shift_break',
                        label: 'Break',
                        value: shift.shift_break,
                        required: true,
                        inputType: 'number',
                    }, {
                        type: 'input',
                        name: 'price',
                        label: 'Price',
                        value: shift.price,
                        required: true,
                        inputType: 'number',
                    }]
                }
                onChange={formData => {
                    this.setState({ formData });
                }}
            />
        );

        if (this.state.showEditCustomShiftModal) {
            return (
                <Modal
                    okButtonProps={{ disabled: !this.validateForm(this.state.formData) }}
                    title='Ändra pass'
                    visible={this.state.showEditCustomShiftModal}
                    onCancel={() => {
                        this.onClose();
                    }}
                    onOk={() => {
                        const { formData } = this.state;
                        if (this.validateForm(formData)) {
                            this.props.updateCustomShift(employer.id, shift.id, {
                                employer_id: employer.id,
                                custom_shift_id: shift.id,
                                ...formData,
                            }).then(() => {
                                this.setState({ showEditCustomShiftModal: false });
                            });
                        }
                        else {
                            this.setState({ error: 'Alla fält måste fyllas i' });
                        }
                    }}

                >
                    {content}
                </Modal>
            );
        }
        return null;
    }

    renderTable() {
        const { employer } = this.props.employer;
        const customShifts = employer.settings.custom_shifts;

        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: 'Namn',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Start tid',
            dataIndex: 'start_time',
            key: 'startTime',
        }, {
            title: 'Slut tid',
            dataIndex: 'end_time',
            key: 'endTime',
        }, {
            title: 'Pris',
            dataIndex: 'price',
            key: 'price',
        }, {
            title: 'Rast',
            dataIndex: 'shift_break',
            key: 'shiftBreak',
        }, {
            title: 'Åtgärd',
            key: 'action',
            render: (shift, record) => (
                <div>
                    <EditTrigger
                        style={{ display: 'inline' }}
                        title='Edit shift'
                        onClick={() => {
                            this.setState({
                                showEditCustomShiftModal: true,
                                editModalShift: shift,
                            });
                        }}
                    />
                </div>
            ),
        }];

        return (
            <Table
                className='table-background'
                columns={columns}
                dataSource={customShifts}
                pagination={false}
            />
        );
    }

    renderCreateCustomShiftModalContent() {
        if (this.state.showCreateCustomShiftModal) {
            return (
                <InputModal
                    ref='createCustomShift'
                    hideButton
                    inputs={[{
                        type: 'input',
                        name: 'name',
                        label: 'Name',
                        value: '',
                        required: true,
                    }, {
                        type: 'input',
                        name: 'start_time',
                        label: 'Start time',
                        value: '',
                        required: true,
                        inputType: 'time',
                    }, {
                        type: 'input',
                        name: 'end_time',
                        label: 'End time',
                        value: '',
                        required: true,
                        inputType: 'time',
                    }, {
                        type: 'input',
                        name: 'shift_break',
                        label: 'Break (in hours)',
                        value: 0.5,
                        required: true,
                        inputType: 'number',
                    }, {
                        type: 'input',
                        name: 'price',
                        label: 'Price',
                        value: '',
                        required: true,
                        inputType: 'number',
                    }]}
                    onChange={formData => {
                        this.setState({ formData });
                    }}
                />
            );
        }
        return null;
    }

    renderCreateCustomShiftModal() {
        const { employer } = this.props.employer;

        return (
            <Modal
                cancelText='Avbryt'
                okButtonProps={{ disabled: !this.validateForm(this.state.formData) }}
                okText='Spara'
                title='Ska nytt pass'
                visible={this.state.showCreateCustomShiftModal}
                onCancel={() => {
                    this.onClose();
                }}
                onOk={() => {
                    const { formData } = this.state;
                    if (this.validateForm(formData)) {
                        this.props.createCustomShift(employer.id, formData).then(() => {
                            this.onClose();
                        });
                    }
                    else {
                        this.setState(prevState => ({
                            ...prevState,
                            error: 'Alla fält måste fyllas i',
                        }));
                    }
                }}
            >
                <div>Alla fält måste fyllas i</div>
                {this.renderCreateCustomShiftModalContent()}

            </Modal>
        );
    }

    render() {
        return (
            <div className='mpa-employer-tab-content'>
                <div
                    style={{
                        textAlign: 'right',
                        marginBottom: 20,
                    }}
                >
                    <Button
                        icon={<PlusCircleOutlined />}
                        size='large'
                        onClick={() => {
                            this.setState({ showCreateCustomShiftModal: true });
                        }}
                    >
                        Create custom shift
                    </Button>
                    {this.renderCreateCustomShiftModal()}
                    {this.renderEditCustomShiftModal()}
                </div>
                {this.renderTable()}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...employerActions }, dispatch),
)(CustomShifts);
