import React from 'react';
import PropTypes from 'prop-types';
import {
    FileWordOutlined,
    FilePdfOutlined,
    FileUnknownOutlined,
} from '@ant-design/icons';

function FileIcon({ fileName }) {
    const fileExtension = fileName.split('.').pop();
    switch (fileExtension) {
        case 'doc':
        case 'docx':
            return <FileWordOutlined />;
        case 'pdf':
            return <FilePdfOutlined />;
        default:
            return <FileUnknownOutlined />;
    }
}

FileIcon.propTypes = {
    fileName: PropTypes.string.isRequired,
};

export default FileIcon;
