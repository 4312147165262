import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Avatar } from 'antd';
import * as Icon from '@ant-design/icons';
import { uniq } from 'lodash';
import {
    departmentTypeTitle,
    emrSystemTitle,
    engagementTitle,
    occupationTitle,
    specializationTitle,
} from '../../../utils/nameformatters';
import {
    humanizedDurationFromNow,
    getFormattedDate,
} from '../../../utils/datetimeformatters';
import SectionListItem from '../../../components/shared/sectionlistitem';
import CvExperienceRow from '../../../components/shared/cvexperiencerow';
import config from '../../../config';

const PrintableCVHighlights = ({
    consultant,
}) => {
    const {
        avatar,
        educations = [],
        employers = [],
    } = consultant;
    const numEmployers = employers.length;
    const numWorkedShifts = employers.reduce((num, emp) => num + emp.worked_shifts, 0);
    const registrationYear = moment.unix(consultant.created_time).format('YYYY');

    return (
        <section
            className='profile-wrapper printable-cv-section'
            style={{
                display: 'flex',
                flexDirection: 'row',
                background: '#FEF5F1',
                padding: 30,
                marginTop: 0,
                borderRadius: 6,
            }}
        >
            <div
                style={{
                    marginRight: 20,
                }}
            >
                <Avatar
                    size={110}
                    src={avatar}
                />
            </div>
            <div>
                <div>
                    <h3
                        className='text-2xl'
                        style={{
                            margin: 0,
                        }}
                    >
                        {consultant.firstname}
                        {' '}
                        {consultant.lastname}
                    </h3>
                    <div>
                        {educations.length
                            ? (educations.map(edu => {
                                if (!edu.end_time) { return null; }
                                const educationTime = moment.unix(edu.end_time);
                                const educationYear = educationTime.format('YYYY');
                                const eduTitle = edu.basic ? occupationTitle(consultant.occupation) : specializationTitle(edu.specialization_id);

                                return (
                                    <div>
                                        <strong>{eduTitle}</strong>
                                        {' '}
                                        sedan
                                        {' '}
                                        {educationYear}
                                        {' '}
                                        (
                                        {humanizedDurationFromNow(edu.end_time)}
                                        )

                                    </div>
                                );
                            })) : (<strong>{occupationTitle(consultant.occupation)}</strong>)}
                    </div>
                    <hr
                        style={{
                            margin: '10px 0',
                        }}
                    />
                    <div className='mp-cv-medpeople-experience'>
                        <div className='mp-cv-logo' />
                        <div>
                            <div>
                                På Medpeople sedan
                                {' '}
                                {registrationYear}
                                (
                                {humanizedDurationFromNow(consultant.created_time)}
                                )
                            </div>
                            {numWorkedShifts ? (
                                <div>
                                    {numWorkedShifts}
                                    {' '}
                                    pass hos
                                    {' '}
                                    {numEmployers}
                                    {' '}
                                    uppdragsgivare
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const PrintableCVContact = ({
    consultant,
}) => (
    <div
        className='cv-contact'
        style={{
            display: 'flex',
            marginTop: 20,
        }}
    >
        <div
            className='cv-contact__item'
            style={{
                background: '#FEF5F1',
                padding: 20,
                borderRadius: 6,
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Icon.PhoneOutlined
                style={{
                    background: '#333',
                    color: 'white',
                    borderRadius: 15,
                    fontSize: 16,
                    width: 30,
                    height: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 5,
                }}
            />
            {consultant.phone}
        </div>
        <div
            className='cv-contact__item'
            style={{
                background: '#FEF5F1',
                padding: 20,
                borderRadius: 6,
                marginLeft: 20,
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Icon.MailOutlined
                style={{
                    background: '#333',
                    color: 'white',
                    borderRadius: 15,
                    fontSize: 14,
                    width: 30,
                    height: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 5,
                }}
            />
            {consultant.email}
        </div>
    </div>
);

const PrintableCVDescription = ({
    consultant,
}) => (
    <section
        className='printable-cv-section'
    >
        <h5>Presentation</h5>
        <div
            className='printable-cv-description'
            style={{
            }}
        >
            {consultant.description}
        </div>
    </section>
);

const PrintableCVLicenses = ({
    consultant,
    hideContact,
    hideSSN,
    ssn,
}) => {
    const {
        ecert,
        hsaid,
        big_number,
        driver_license,
        employers = [],
        experiences = [],
    } = consultant;

    const emrSystems = [];
    [...experiences, ...employers].forEach(experience => {
        if (experience.emr_system_id) {
            emrSystems.push(emrSystemTitle(experience.emr_system_id));
        }
    });

    return (
        <section
            className='printable-cv-section'
            style={{
                marginTop: 30,
            }}
        >
            <h5>
                Behörigheter
            </h5>
            <ul className='cv__section-body section-list'>
                {!hideSSN
                && (
                    <SectionListItem
                        rightColumnText={ssn || ''}
                        title='Personnummer'
                    />
                )}
                <SectionListItem
                    rightColumnText={ecert ? 'Ja' : 'Nej'}
                    title='E-tjänstekort'
                />
                {!hideContact
                && (
                    <SectionListItem
                        rightColumnText={hsaid || ''}
                        title='HSA-id'
                    />
                )}
                {big_number ? (
                    <SectionListItem
                        rightColumnText={big_number}
                        title='BIG number'
                    />
                ) : null}
                <SectionListItem
                    rightColumnText={driver_license ? 'Ja' : 'Nej'}
                    title='Körkort'
                />
                {emrSystems.length ? (
                    <SectionListItem
                        rightColumnText={(
                            uniq(emrSystems).map(emr => (
                                <div>
                                    {emr}
                                </div>
                            ))
                        )}
                        title='Journalsystem'
                    />
                ) : null }
            </ul>
        </section>
    );
};

const PrintableCVMedpeopleExperience = ({
    employers = [],
    userFirstName,
    userRegisterTime,
}) => (
    <>
        <SectionListItem
            key='medpeople'
            from={userRegisterTime}
            hasInfoBlock
            showMpIcon
            subtitle='Bemanning'
            title='Medpeople'
        />
        <li>
            <ul
                className='cv-mp-experiences section-list'
            >
                {employers.map(employer => (
                    <CvExperienceRow
                        key={employer.name}
                        departmentType={
                            employer.department_type_id ? departmentTypeTitle(employer.department_type_id) : undefined
                        }
                        employerLogo={employer.logo}
                        employerName={employer.name}
                        rating={employer.received_rating}
                        subtitle={`${employer.worked_shifts} pass`}
                        userFirstName={userFirstName}
                    />
                ))}
            </ul>
        </li>
    </>
);

const PrintableCVExperiences = ({
    consultant,
}) => {
    const {
        experiences = [],
        employers = [],
        firstname,
        created_time,
    } = consultant;
    return (
        <section className='printable-cv-section'>
            <h5>
                Vårderfarenheter
            </h5>
            <ul className='section-list'>
                <PrintableCVMedpeopleExperience
                    employers={employers}
                    userFirstName={firstname}
                    userRegisterTime={created_time}
                />

                {experiences.length
                    ? experiences
                        .sort((a, b) => b.start_time - a.start_time)
                        .map(experience => (
                            <SectionListItem
                                key={experience.name}
                                current={experience.current}
                                departmentType={
                                    experience.secondary_department_type_id ? departmentTypeTitle(experience.secondary_department_type_id) : undefined
                                }
                                description={experience.description}
                                from={experience.start_time}
                                hasInfoBlock
                                showIcon
                                skills={experience.skill_ids}
                                subtitle={engagementTitle(experience.work_type)}
                                title={experience.name}
                                to={experience.end_time}
                            />
                        ))
                    : null }
            </ul>
        </section>
    );
};

const PrintableCVSpecialistEducation = ({
    consultant,
    settings,
}) => {
    const {
        educations = [],
    } = consultant;

    const filteredEducations = educations
        .filter(e => e.basic === false)
        .sort((a, b) => b.end_time - a.end_time);

    if (!filteredEducations.length) {
        return null;
    }

    return (
        <section className='printable-cv-section'>
            <h5>
                Specialistutbildning
            </h5>
            <ul className='cv__section-body section-list'>
                {filteredEducations.map((education, i) => (
                    <SectionListItem
                        rightColumnText={education.end_time ? getFormattedDate(education.end_time) : 'Pågående'}
                        subtitle={education.name}
                        title={settings.specializations.find(s => s.id === education.specialization_id).name}
                    />
                ))}
            </ul>
        </section>
    );
};

const PrintableCVBasicEducation = ({
    consultant,
    settings,
}) => {
    const {
        educations = [],
    } = consultant;
    return (
        <section className='printable-cv-section'>
            <h5>
                Grundutbildning
            </h5>
            <ul className='cv__section-body section-list'>
                {educations.length ? educations
                    .filter(e => e.basic === true)
                    .sort((a, b) => b.end_time - a.end_time)
                    .map((education, i) => (
                        <SectionListItem
                            rightColumnText={education.end_time ? getFormattedDate(education.end_time) : 'Pågående'}
                            subtitle={education.name}
                            title={occupationTitle(consultant.occupation)}
                        />
                    ))
                    : <PrintableCVEmptySection /> }
            </ul>
        </section>
    );
};

const PrintableCVReferences = ({
    consultant,
}) => {
    const {
        references = [],
    } = consultant;
    return (
        <section className='printable-cv-section'>
            <h5>
                Referenser
            </h5>
            <ul className='cv__section-body'>
                {references.length ? references.map(reference => (
                    <SectionListItem
                        key={reference.id}
                        rightColumnText={(
                            <div>
                                <div>
                                    Tel:
                                    {' '}
                                    {reference.phone}
                                </div>
                                <div>
                                    E-post:
                                    {' '}
                                    {reference.email}
                                </div>
                            </div>
                        )}
                        subtitle={reference.description}
                        title={reference.name}
                    />
                )) : <PrintableCVEmptySection /> }
            </ul>
        </section>
    );
};

const PrintableCVLanguages = ({
    consultant,
    settings,
}) => {
    const {
        languages = [],
    } = consultant;
    return (
        <section className='printable-cv-section'>
            <h5>
                Språk
            </h5>
            <ul className='cv__section-body section-list'>
                {languages.length ? languages
                    .sort((a, b) => a.level - b.level)
                    .map(language => (
                        <SectionListItem
                            rightColumnText={config.languageLevels.find(l => l.id === language.level).name}
                            title={settings.languages.find(l => l.id === language.id).name}
                        />
                    ))
                    : <PrintableCVEmptySection />}
            </ul>
        </section>
    );
};

const PrintableCVEmptySection = () => (
    <div className='printable-cv-empty-section'>
        Ej tillagt än
    </div>
);

const PrintableCV = ({
    consultant,
    ssn,
    settings,
    hideContact = true,
    hideSSN = false,
}) => (
    <div className='printable-cv print-only'>
        <PrintableCVHighlights consultant={consultant} />
        {!hideContact && <PrintableCVContact consultant={consultant} />}
        <PrintableCVDescription consultant={consultant} />
        <PrintableCVLicenses
            consultant={consultant}
            hideContact={hideContact}
            hideSSN={hideSSN}
            ssn={ssn}
        />
        <PrintableCVExperiences consultant={consultant} />
        <PrintableCVSpecialistEducation consultant={consultant} settings={settings} />
        <PrintableCVBasicEducation consultant={consultant} settings={settings} />
        <PrintableCVReferences consultant={consultant} />
        <PrintableCVLanguages consultant={consultant} settings={settings} />
    </div>
);

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    ssn: state.consultant.ssn,
    settings: state.settings,
});

export default connect(mapStateToProps)(PrintableCV);
