import * as request from 'request';

export function requestFetchActivities() {
    return { type: 'REQUEST_FETCH_ACTIVITIES' };
}

export function recieveActivities(resp) {
    return {
        type: 'RECEIVE_ACTIVITIES',
        activities: resp.collection,
    };
}

export function fetchActivities(types) {
    return dispatch => {
        dispatch(requestFetchActivities());
        return request.fetchActivities(types)
            .then(resp => dispatch(recieveActivities(resp)));
    };
}
