import { humanReadableHours } from 'utils/datetimeformatters';

const config = {
    api_url: process.env.API_URL,
    google_maps_api_key: 'AIzaSyAGxpZSkU5o2zEIQ5Fd4u5hWpOUFLpslO4',
    intercomAppId: 'tr1zrh6t',
    occupations: {
        OCCUPATION_ALL: 'ALL',
        OCCUPATION_NONE: 'NONE',
        // added to the sake of checking to disable verifiera
        OCCUPATION_USK: 1,
    },
    students: {
        STUDENT_ALL: 'All',
        STUDENT_NONE: false,
        STUDENT_ONLY: true,
    },
    employerTypes: {
        ALL: 0,
        OCCUPATION_DOC: 3,
        OCCUPATION_SSK: 2,
        OCCUPATION_USK: 1,
    },
    statusColors: {
        pending: '#ffd400',
        activated: '#36d671',
        removed: '#d9534f',
        banned: '#000',
        visible: '#ffa500',
    },
    shiftBreakInvervals: (() => {
        const breakAlternatives = [];
        const maxLengthOfBreakInMinutes = 140;

        for (let i = 0; i < maxLengthOfBreakInMinutes; i += 5) {
            breakAlternatives.push({
                title: humanReadableHours((i / 60)),
                value: Number((i / 60).toFixed(2)), // Match format with what comes from BE
            });
        }

        return breakAlternatives;
    })(),
    countries: [{
        id: 0,
        value: 'SWE',
        name: 'Sverige',
        engName: 'Sweden',
    }, {
        id: 1,
        value: 'NLD',
        name: 'Nederländerna',
        engName: 'Netherlands',
    }],
    employerStatus: [
        {
            value: 0,
            title: 'Pending',
            explanation: 'Employer is created but not visible in app. Switch to "Activate" to hand over the account to the employer or "Visible" to make it visible in app.',
        },
        {
            value: 1,
            title: 'Activated',
            explanation: 'Employer is created and visible in app and handed over to the employer for full control',
        },
        {
            value: 2,
            title: 'Removed',
            explanation: 'Employer has no access to account. Employer is not visible in app.',
        },
        {
            value: 3,
            title: 'Banned',
            explanation: 'Employer has no access to account. Employer is not visible in app.',
        },
        {
            value: 4,
            title: 'Visible',
            explanation: 'Employer is created and visible in app. But not activated. And not available for customer to login',
        },
        {
            value: 5,
            title: 'Hidden',
            explanation: 'Employer is hidden in app',
        },
    ],
    KAM: [
        {
            value: 0,
            title: '-',
            initials: '-',
            active: true,
        },
        {
            value: 26,
            title: 'Emma',
            initials: 'EL',
            avatarColor: '#C7F7ED',
            active: false,
        },
        {
            value: 14,
            title: 'Thomas',
            initials: 'TH',
            avatarColor: '#C7F7ED',
            active: true,
        },
        {
            value: 24,
            title: 'Linda',
            initials: 'LL',
            avatarColor: '#C7F7ED',
            active: false,
        },
        {
            value: 18,
            title: 'Karin',
            initials: 'KS',
            avatarColor: '#C7F7ED',
            active: false,
        },
        {
            value: 19,
            title: 'Niklas Carlsson',
            initials: 'NC',
            avatarColor: '#C7F7ED',
            active: false,
        },
        {
            value: 45,
            title: 'Erika',
            initials: 'EG',
            avatarColor: '#C7F7ED',
            active: true,
        },
        {
            value: 40,
            title: 'Mia',
            initials: 'MB',
            avatarColor: '#FF4F70',
            active: true,
        },
        {
            value: 41,
            title: 'Sebastian',
            initials: 'SG',
            avatarColor: '#00AB9B',
            active: true,
        },
        {
            value: 7,
            title: 'Niclas',
            initials: 'NN',
            avatarColor: '#FEB80E',
            active: true,
        }, {
            value: 1,
            title: 'Emil',
            initials: 'EB',
            avatarColor: '#FFAAAA',
            active: true,
        }, {
            value: 17,
            title: 'Kave',
            initials: 'KS',
            avatarColor: '#7b32a8',
            active: true,
        },
    ],
    taskStatusOptions: [{
        label: 'OPEN', value: 'OPEN', color: '#FFAAAA',
    }, {
        label: 'ONGOING', value: 'ONGOING', color: '#C7F7ED',
    }, {
        label: 'COMPLETED', value: 'COMPLETED', color: '#00AB9B',
    }, {
        label: 'CLOSED', value: 'CLOSED', color: '#7b32a8',
    }],
    consultantSalaryTypes: {
        SALARY: 1,
        INVOICE: 2,
    },
    consultantStatus: [
        {
            value: 1,
            title: 'Pending',
            color: '#ffd400',
        },
        {
            value: 2,
            title: 'Activated',
            color: '#36d671',
        },
        {
            value: 3,
            title: 'Removed',
            color: '#d9534f',
        },
        {
            value: 4,
            title: 'Banned',
            color: '#000',
        },
        {
            value: 5,
            title: 'Temp banned',
            color: '#ccc',
        },
    ],
    consultantInvoiceStatus: [
        {
            value: 1,
            title: 'Mottagen',
        },
        {
            value: 2,
            title: 'Bokföring godkänd',
        },
        {
            value: 3,
            title: 'Bokförd',
        },
        {
            value: 4,
            title: 'Godkänd för betalning',
        },
        {
            value: 5,
            title: 'Betald',
        },
    ],
    isProtected: [
        {
            id: 1,
            value: false,
            title: 'Not protected',
        },
        {
            id: 2,
            value: true,
            title: 'Protected',
        },
    ],
    consultantExperience: [
        {
            value: 1,
            title: 'Mindre än ett års erfarenhet',
        },
        {
            value: 2,
            title: '1 - 2 års erfarenhet',
        },
        {
            value: 3,
            title: '2 - 5 års erfarenhet',
        },
        {
            value: 4,
            title: '5 - 10 års erfarenhet',
        },
        {
            value: 5,
            title: 'Mer än 10 års erfarenhet',
        },
    ],
    margins: {
        lowest: 1,
        highest: 40,
    },
    colors: [
        {
            id: 0,
            hex: '#ef5350', // red
        },
        {
            id: 1,
            hex: '#ec407a', // pink
        },
        {
            id: 2,
            hex: '#ab47bc', // purple light
        },
        {
            id: 3,
            hex: '#7e57c2', // purple dark
        },
        {
            id: 4,
            hex: '#5c6bc0', // blue
        },
        {
            id: 5,
            hex: '#42a5f5', // sky blue
        },
        {
            id: 6,
            hex: '#29b6f6', // light blue
        },
        {
            id: 7,
            hex: '#26c6da', // teal
        },
        {
            id: 8,
            hex: '#26a69a', // green (jade)
        },
        {
            id: 9,
            hex: '#66bb6a', // green
        },
        {
            id: 10,
            hex: '#9ccc65', // green/yellow
        },
        {
            id: 11,
            hex: '#d4e157', // yellow
        },
        {
            id: 12,
            hex: '#ffee58', // light yellow
        },
        {
            id: 13,
            hex: '#ffca28', // gold
        },
        {
            id: 14,
            hex: '#ffa726', // orange
        },
    ],
    languageLevels: [
        {
            id: 1,
            name: 'Modersmål / Flytande',
        }, {
            id: 2,
            name: 'Avancerad',
        }, {
            id: 3,
            name: 'Grundläggande kunskap',
        },
    ],
    lastActive: [
        {
            id: 1,
            minDays: 0,
            maxDays: 14,
            color: 'green',
            name: 'Less than 14 days',
        },
        {
            id: 2,
            minDays: 14,
            maxDays: 30,
            color: 'gold',
            name: '14 - 30 days',
        },
        {
            id: 3,
            minDays: 30,
            maxDays: 60,
            color: 'volcano',
            name: '30 - 60 days',
        },
        {
            id: 4,
            minDays: 60,
            maxDays: Infinity,
            color: 'red',
            name: 'More than 60 days',
        },
    ],
    workTypes: [{
        id: 1,
        name: 'Full time',
    }, {
        id: 2,
        name: 'Part time',
    }, {
        id: 3,
        name: 'Staffing',
    }],
    shiftStatus: {
        DRAFT: 0,
        PUBLISHED: 1,
        BOOKED: 2,
        DELETED: 3,
        SICK_LEAVE: 4,
        CANCEL_BY_EMPLOYER: 5,
        CANCEL_BY_ADMIN: 6,
    },
    jobStatus: {
        DRAFT: 0,
        PUBLISHED: 1,
        DELETED: 2,
        PAUSED: 3,
        BOOKED: 4,
    },
    jobPostingStatus: {
        DRAFT: 0,
        PUBLISHED: 1,
        DELETED: 2,
        PAUSED: 3,
        BOOKED: 4,
    },
    jobPostingApplicationStatus: {
        APPLIED: 1,
        ACCEPTED: 2,
        REJECTED: 3,
        USER_REMOVED: 4,
        ADMIN_REMOVED: 5,
    },
    jobPostingExperience: [
        {
            value: 1,
            title: '0 - 3 år',
        },
        {
            value: 2,
            title: '3 - 6 år',
        },
        {
            value: 3,
            title: 'mer än 6 år',
        },
    ],
    consultantIntent: {
        intended_usage: {
            1: {
                name: "I'm looking for a new job",
                color: 'gold',
            },
            2: {
                name: 'I want to work part time with staffing',
                color: 'volcano',
            },
            3: {
                name: 'I want to work full time with staffing',
                color: 'magenta',
            },
            4: {
                name: 'I want to take extra shifts now and then',
                color: 'red',
            },
            5: {
                name: "I'm just curious",
                color: 'cyan',
            },
            6: {
                name: 'Other',
                color: 'blue',
            },
        },
        actively_looking: {
            1: 'Yes',
            2: 'No',
            3: "I don't know",
        },
        current_situation: {
            1: 'I am employed in the helathcare sector',
            2: 'I work part time within the health care sector',
            3: 'I work outside the healthcare sector',
            4: "I'm studying",
            5: 'Other',
        },
        previous_staffing: {
            1: 'Yes',
            2: 'No',
        },
    },
    regexp: {
        // At least 8 characters and 1 number is needed and special letters are ok
        password: /^(?=.*[A-Za-z]*)(?=.*\d)[[A-ZÅÄÖa-zåäö\d _+.,!@#$%^&*();\/|<>"']{8,}$/,
        // Valid email
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    adminComments: [{
        value: '',
        display: 'Clear comment',
        colorId: 0,
        icon: 'DeleteOutlined',
    }, {
        value: 'WAITING_IVO',
        display: 'Wainting ivo / hosp',
        colorId: 1,
        icon: 'MedicineBoxOutlined',
    },
    {
        value: 'PRESENTED',
        display: 'Presented',
        colorId: 9,
        icon: 'CommentOutlined',
    },
    {
        value: 'SENT',
        display: 'Sent',
        colorId: 14,
        icon: 'MailOutlined',
    },
    {
        value: 'WAITING_ANSWER_FROM_CONSULTANT',
        display: 'Wainting answer from consultant',
        colorId: 4,
        icon: 'ClockCircleOutlined',
    },
    {
        value: 'SEECOMMENT',
        display: 'See comment',
        colorId: 13,
        icon: 'EditOutlined',
    }],
    uploadFileTypes: {
        IVO: 'IVO',
        AVATAR: 'AVATAR',
        CV: 'CV',
        PASSPORT: 'PASSPORT',
        WAGE_TAX: 'WAGE_TAX',
    },
    employmentFees: {
        SWE: 0.3142,
        NLD: 0.59,
    },
};

export default config;
