import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Event from './Event';

const SickLeaveEvent = ({ event: {
    user: consultant,
    meta_data: {
        start_time: startTime,
        end_time: endTime,
    },
} }) => {
    const duration = moment.unix(endTime).diff(moment.unix(startTime), 'days');
    const durationText = duration > 1 ? 'dagar' : 'dag';

    if (!consultant) return null;

    return (
        <Event
            avatar={consultant.avatar}
            details={`${moment.unix(startTime).format('YYYY-MM-DD')} 
                - ${moment.unix(endTime).format('YYYY-MM-DD')}`}
            header={(
                <>
                    <Link to={`/consultants/${consultant.id}`}>
                        {`${consultant.firstname} ${consultant.lastname}`}
                    </Link>
                    {' took sick leave'}
                    <span className='timeline__event-sickleave'>
                        {`${duration} ${durationText}`}
                    </span>
                </>
            )}
        />
    );
};

SickLeaveEvent.propTypes = { event: PropTypes.shape({
    user: PropTypes.shape({
        id: PropTypes.number,
        avatar: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
    }),
    meta_data: PropTypes.shape({
        start_time: PropTypes.number,
        end_time: PropTypes.number,
    }),
}).isRequired };

export default SickLeaveEvent;
