import * as request from 'request';

export function requestFetchFunnelData() {
    return { type: 'REQUEST_FETCH_FUNNEL_DATA' };
}

export function receiveFetchFunnelData(funnel) {
    return {
        type: 'RECEIVE_FETCH_FUNNEL_DATA',
        funnel,
    };
}

export function fetchFunnelData(month) {
    return dispatch => {
        dispatch(requestFetchFunnelData());

        return request.fetchFunnelData(month)
            .then(resp => dispatch(receiveFetchFunnelData(resp.data)));
    };
}

export function resetFunnelData() {
    return { type: 'RESET_FUNNEL_DATA' };
}

export function requestFetchMAUConsultantsData() {
    return { type: 'REQUEST_FETCH_MAU_CONSULTANTS_DATA' };
}

export function receiveFetchMAUConsultantsData(data) {
    return {
        type: 'RECEIVE_FETCH_MAU_CONSULTANTS_DATA',
        data,
    };
}

export function fetchMAUConsultantsData(month) {
    return dispatch => {
        dispatch(requestFetchMAUConsultantsData());

        return request.fetchMAUConsultantsData(month)
            .then(resp => dispatch(receiveFetchMAUConsultantsData(resp.collection)));
    };
}

export function resetMAUConsultantsData() {
    return { type: 'RESET_MAU_CONSULTANTS_DATA' };
}
