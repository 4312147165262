import React, {useState} from 'react';
import { occupationTitle } from 'utils/nameformatters';
import moment from 'moment';
import { Pagination, Button, DatePicker, Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Avatar from 'components/shared/avatar';
import getSickLeaveColor from 'utils/sickLeaveColor';
import {renderJobs} from './JobTrackingEvents'

const ConsultantEvents = ({ selectedConsultantTasks, data, onChangePage, intentionData, onSelectJobPosting, dataConsultant, onAddTask, onSelectedConsultantTasks }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isTaskOpen, setIsTaskOpen] = useState({});
    const [tempInput, setTempInput] = useState();
    const [dueDate, setDueDate] = useState();
    const [plannedDate, setPlannedDate] = useState();
    const [hasError, setHasError] = useState(false);
    
    const onToggleTask = (id) => {
      setIsTaskOpen({ [id]: !isTaskOpen[id] })
      setTempInput('')
    }
    
    const onAddTaskHandler = (id) => {
      if (tempInput && dueDate && plannedDate) {
        const item = {
          title: tempInput,
          admin_id_assigned: null,
          due_date_time: dueDate,
          planned_date_time: plannedDate,
        }
        onAddTask(item, { user_id: id });
        setTempInput('');
        setHasError(false)
      } else {
        setHasError(true)
      }
    }

    const onSelectJobPostingHandler = (userId) => {
      onSelectJobPosting(userId)
      setIsOpen(!isOpen)
    }

    if(!data.consultants) return null;

    const getIntentions = (intentions) => {
      const intentionElements = intentions.map((n, index) => {
        const userIntention = intentionData.find(({ id }) => id === n);
        const intentionName = userIntention ? userIntention.name : ''
        return (<span className='text-xs mr-1 p-1 bg-gray-100' key={`${n}${index}`}>{intentionName}</span>)
      });
      return intentionElements
    }

    const renderConsultants = () => (data.consultants.map((consultant, index) => {
      const { id, job_posting_tracking, availability, fullname, phone, graduation_time, student, avatar, occupation, intentions, occasions_sick_leave_by_percent, childcare_by_percent } = consultant;
      const graduationTime = student ? moment.unix(graduation_time).format('YYYY-MM-DD') : null;
      const intentionElements = intentions.length > 0 ? getIntentions(intentions) : null;
      const hasLoadedJobPostings = (dataConsultant.consultant?.jobPostings && dataConsultant.consultant?.jobPostings.userId === id)
      const taskChecked = selectedConsultantTasks.some(({ user_id }) => user_id === id)
      return (
        <div key={`${id}-${index}`} className="flex my-2 p-2 bg-white">
          <Avatar
            size={30}
            url={avatar}
          />
          <div className='flex flex-col ml-2 flex-1'>
            <div className='flex flex-col'>
              <span className='font-semibold'>{fullname}</span>
              <span className='text-xs'>{phone}</span>
            </div>
            {graduationTime ? <div className='text-xs mt-2'>Student graduation date: {graduationTime}</div> : null}
            {occupation ? <div className='flex'>
              <span className='text-xs mr-2'>{occupationTitle(occupation)}</span>
              <span className='text-xs'>(#{id})</span>
            </div> : null}
            { intentions.length > 0 && <div className='mt-2'>{ intentionElements }</div>}
            { occasions_sick_leave_by_percent >= 10 && <div className='mt-2 text-xs flex items-end'><span className={`px-1 rounded mr-1 ${getSickLeaveColor(occasions_sick_leave_by_percent)}`}>{occasions_sick_leave_by_percent}%</span> sick leave</div>}
            { childcare_by_percent >= 10 && <div className='mt-2 text-xs flex items-end'><span className={`px-1 rounded mr-1 ${getSickLeaveColor(childcare_by_percent)}`}>{childcare_by_percent}%</span> childcare</div>}
            { (availability && availability.length > 0) && <div className='mt-2 text-xs'>
              <h5>Avialable dates:</h5>
              {availability.map((available) => <span className='mr-1 px-1 rounded bg-gray-200'>{available.date}</span>)}
              </div>}
              {job_posting_tracking.length > 0 && (
                  <div className='flex mt-2 text-xs cursor-pointer justify-between align-middle'>
                    <span className='hover:text-gray-500' onClick={() => onSelectJobPostingHandler(id)}>
                      Number of job posting visits: {job_posting_tracking.length}
                    </span>
                    {hasLoadedJobPostings && isOpen && <span className='hover:text-gray-500' onClick={() => setIsOpen(false) }>X</span>}
                  </div>
                )}
              {hasLoadedJobPostings && isOpen && (
                <div className='text-xs'>
                  {renderJobs(dataConsultant.consultant?.jobPostings.data)}
                </div>
                )}
                <div className='mt-2'>
                  <Button onClick={() => onToggleTask(id)}>
                    <i className="fa fa-list-ol" aria-hidden="true" />
                  </Button>
                  <Checkbox
                      style={{ marginLeft: '4px' }}
                      onChange={e => { onSelectedConsultantTasks({ user_id: id }); }}
                      checked={taskChecked}
                  />
                </div>
                { isTaskOpen[id] && (<div className='flex flex-col bg-gray-100 border-b border-white'>
                  <div className='py-2 font-semibold border-white border-t'>
                    <div className='px-6'>Create a Task</div>
                  </div>
                  <div className='flex flex-col items-end px-6 pb-2'>
                    <TextArea value={tempInput} allowClear onChange={(e) => setTempInput(e.target.value)}/>
                    <div className='flex my-2'>
                      <DatePicker placeholder="Select planned date" onChange={(d) => setPlannedDate(moment(d).unix())} />
                      <DatePicker placeholder="Select due date" onChange={(d) => setDueDate(moment(d).unix())} />
                      <Button className="ml-2" onClick={() => onAddTaskHandler(id)}>
                        <i className="fa fa-plus mr-1" aria-hidden="true" />Add task
                      </Button>
                    </div>
                  </div>
                  { hasError && <div className='flex flex-col items-end px-6 pb-4 text-red-600'>Enter all fields</div> }
                  </div>)
                }
          </div>
        </div>
      )
    }));
    if(data.consultants.length === 0) {
      return <div className='flex my-4 text-lg'>No clinicians found.</div>
    }
    return (
      <div>      
        {renderConsultants()}
        <div className='flex items-center'>
          <Pagination current={data.page} onChange={onChangePage} total={data.totalPages} />
          <div className='ml-2 text-xs'>Number of clinicians ({data.count})</div>
        </div>
      </div>
    );
};

export default ConsultantEvents;
