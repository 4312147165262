import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchTasks } from 'actions/tasks';
import {
    Avatar,
    Table,
    Tag,
    Tooltip,
} from 'antd';
import moment from 'moment';

import config from 'config';

import TaskModal from './taskmodal';

const taskFilters = localStorage.getItem('MPAtaskFilters');

const TaskList = ({ tasks }) => {
    const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
    const [selectedTask, setSelectedTask] = useState();
    const today = moment().startOf('day').unix().toString();
    let statusFilter; let
        kamFilter;
    try {
        const filtersParsed = JSON.parse(taskFilters);
        statusFilter = filtersParsed?.status;
        kamFilter = filtersParsed?.admin_id_assigned;
    }
    catch (e) {
        // Empty catch, just for parsing filter stored in localstorage
    }

    return (
        <>
            <Table
                columns={[{
                    title: 'Task id',
                    dataIndex: 'id',
                }, {
                    title: 'Title',
                    dataIndex: 'title',
                }, {
                    title: 'Status',
                    dataIndex: 'status',
                    filters: config.taskStatusOptions.map(t => ({
                        text: t.label, value: t.value,
                    })),
                    defaultFilteredValue: statusFilter || ['OPEN', 'ONGOING'],
                    onFilter: (value, record) => record.status === value,
                    render: text => <Tag color={config.taskStatusOptions.find(o => (o.value === text)).color}>{text}</Tag>,

                }, {
                    title: 'Nav',
                    dataIndex: 'admin_id_assigned',
                    defaultFilteredValue: kamFilter ? kamFilter.map(k => String(k)) : [],
                    render: (_, r) => {
                        const kam = config.KAM.find(k => k.value === r.admin_id_assigned);
                        return (
                            <Tooltip title={kam?.title}>
                                <Avatar style={{ backgroundColor: kam?.avatarColor }}>
                                    {kam?.initials}
                                </Avatar>
                            </Tooltip>
                        );
                    },
                    filters: config.KAM.filter(k => k.active).map(k => ({
                        text: k.title, value: k.value,
                    })),
                    onFilter: (value, record) => record.admin_id_assigned === value,
                }, {
                    title: 'Due Date',
                    dataIndex: 'due_date_time',
                    render: text => (text ? moment.unix(text).format('YYYY-MM-DD') : undefined),
                }, {
                    title: 'Planned Date',
                    dataIndex: 'planned_date_time',
                    defaultSortOrder: 'ascend',
                    sorter: (a, b, sortOrder) => {
                        if (!a.planned_date_time) {
                            return sortOrder === 'ascend' ? 1 : -1;
                        }
                        if (!b.planned_date_time) {
                            return sortOrder === 'ascend' ? -1 : 1;
                        }
                        return a.planned_date_time - b.planned_date_time;
                    },
                    filters: [{
                        text: 'Hide Future', value: today,
                    }],
                    // defaultFilteredValue: [today], TODO: Error
                    onFilter: (value, record) => {
                        if (!record.planned_date_time) {
                            return true;
                        }
                        return record.planned_date_time <= value;
                    },
                    render: text => (text ? moment.unix(text).format('YYYY-MM-DD') : undefined),
                }]}
                dataSource={tasks}
                pagination={{ pageSize: 100 }}
                rowKey='id'
                onChange={(_, filterValues) => {
                    localStorage.setItem('MPAtaskFilters', JSON.stringify(filterValues));
                }}
                onRow={r => ({
                    onClick: () => {
                        setSelectedTask(r);
                        setEditTaskModalVisible(true);
                    },
                })}

            />
            {selectedTask ? (
                <TaskModal
                    task={selectedTask}
                    visible={editTaskModalVisible}
                    onCancel={() => setEditTaskModalVisible(false)}
                />
            ) : <></>}
        </>
    );
};
TaskList.propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        admin_id_assigned: PropTypes.number.isRequired,
        due_date_time: PropTypes.number,
        planned_date_time: PropTypes.number,
        title: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    })),
};

const Tasks = ({
    tasks, fetchTasks,
}) => {
    useEffect(() => {
        fetchTasks();
    }, []);
    return <main className='mpa-ltvs'><TaskList tasks={tasks} /></main>;
};
Tasks.propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
    })),
    fetchTasks: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ tasks: state.tasks.tasks });

export default connect(
    state => mapStateToProps(state),
    dispatch => bindActionCreators({ fetchTasks }, dispatch),
)(Tasks);
