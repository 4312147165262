import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Tag,
} from 'antd';

function VacancyModal({
    vacancy,
    setShowLtvDetailsModal,
    settings,
    visible,
}) {
    return (
        <Modal
            title={`${vacancy.name} details`}
            visible={visible}
            width={600}
            onCancel={() => setShowLtvDetailsModal(false)}
            onOk={() => setShowLtvDetailsModal(false)}
        >
            <dt className='ltv-detail-title'>Description</dt>
            <dd>{vacancy.description ? vacancy.description : '-'}</dd>
            <dt className='ltv-detail-title'>Qualifications</dt>
            <dd>{vacancy.qualifications ? vacancy.qualifications : '-'}</dd>
            <dt className='ltv-detail-title'>Skills</dt>
            <dd>
                {vacancy.skills.length
                    ? settings.skills
                        .filter(s => vacancy.skills.includes(s.id))
                        .map(item => <Tag id={item.name}>{item.name}</Tag>)
                    : '-'}
            </dd>
            <dt className='ltv-detail-title'>Specialization</dt>
            <dd>
                {vacancy.specialization_ids.length
                    ? settings.specializations
                        .filter(s => vacancy.specialization_ids.includes(s.id))
                        .map(item => <Tag>{item.name}</Tag>)
                    : '-'}
            </dd>
            <dt className='ltv-detail-title'>Driver's licence</dt>
            <dd>
                {vacancy.driver_license ? 'Required' : 'Not required'}
            </dd>
            <dt className='ltv-detail-title'>Additional info</dt>
            <dd>
                {vacancy.additional_info ? vacancy.additional_info : '-'}
            </dd>
        </Modal>
    );
}

VacancyModal.propTypes = {
    vacancy: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        qualifications: PropTypes.string,
        skills: PropTypes.arrayOf(PropTypes.number),
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        driver_license: PropTypes.bool,
        additional_info: PropTypes.string,
    }).isRequired,
    setShowLtvDetailsModal: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        skills: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        specializations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    visible: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({}),
    ]).isRequired,
};

export default VacancyModal;
