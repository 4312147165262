import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Table,
    Avatar,
    Pagination,
} from 'antd';

import {
    departmentTypeTitle,
    employerTypeTitle,
} from 'utils/nameformatters';
import MarginConfig from 'components/marginconfig';
import LastActive from 'components/lastactive';

const EmployerSearchResult = ({
    employersHasFetched,
    isFetching,
    employers,
    people,
    employersFilterSetSort,
    page,
    setPage,
    totalCount,
    query,
    marginOptions,
}) => {
    const changeHandler = (pagination, filters, sorter) => {
        const order = sorter.order === 'ascend' ? 'asc' : 'desc';
        const sort = [{ [sorter.field]: order }];
        employersFilterSetSort(sort);
    };
    const renderEmployers = () => {
        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 70,
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            render: (text, record) => (
                <div
                    key={record.id}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Avatar
                        size='large'
                        src={record.logo_url}
                        style={{
                            display: 'flex',
                            minWidth: 40,
                            marginRight: 5,
                        }}
                    >
                        {!record.logo_url && record.name ? record.name[0] : ''}
                    </Avatar>
                    <div
                        style={{ flexShrink: 1 }}
                    >
                        <Link to={`/employers/${record.id}`}>
                            {record.name}
                        </Link>
                        <div
                            style={{
                                fontSize: 12,
                                color: '#AAA',
                            }}
                        >
                            {record.department}
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'employer_type',
            key: 'employer_type',
            render: (text, record) => {
                let employerBgColor;
                let employerTextColor;

                switch (record.employer_type) {
                    case 0:
                        employerBgColor = '#fff';
                        employerTextColor = '#000';
                        break;

                    case 1:
                        employerBgColor = '#4B4BE2';
                        employerTextColor = '#fff';
                        break;

                    case 2:
                        employerBgColor = '#E03B9A';
                        employerTextColor = '#fff';
                        break;

                    default:
                        employerBgColor = '#fff';
                        employerTextColor = '#000';
                        break;
                }

                return (
                    <span
                        style={{
                            background: employerBgColor,
                            color: employerTextColor,
                            fontWeight: '600',
                            fontSize: 12,
                            marginRight: 9,
                            marginTop: 15,
                            padding: '6px 10px',
                            borderRadius: 4,
                            marginBottom: 3,
                        }}
                    >
                        {employerTypeTitle(text)}
                    </span>
                );
            },
        },
        {
            title: 'Department type',
            dataIndex: 'secondary_department_type_id',
            key: 'department_type',
            render: text => <strong>{departmentTypeTitle(text)}</strong> || '-',
        }, {
            title: 'Shifts',
            dataIndex: 'published_shifts',
            key: 'published_shifts',
            width: 160,
            render: (text, record) => record.published_shifts || '-',
            sorter: (a, b) => {
                const aShifts = a.published_shifts || 0;
                const bShifts = b.published_shifts || 0;
                return aShifts - bShifts;
            },
        },
        {
            title: 'Margin config',
            dataIndex: 'margin_config_id',
            key: 'margin_config_id',
            render: (_, employer) => (
                <MarginConfig
                    marginConfig={employer.margin_config_id}
                    marginOptions={marginOptions}
                />
            ),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Last active',
            dataIndex: 'last_active',
            key: 'last_active',
            render: text => (
                <LastActive
                    lastActive={text}
                />
            ),
            sorter: (a, b) => a.last_active - b.last_active,
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Registered',
            dataIndex: 'created_time',
            key: 'created_time',
            render: text => moment.unix(text).format('D MMM'),
        }];

        return (
            <div
                style={{ background: '#FFF' }}
            >

                <Table
                    columns={columns}
                    dataSource={employers}
                    loading={!employersHasFetched || isFetching}
                    pagination={false}
                    rowKey='id'
                    scroll={{ x: 1500 }}
                    size='large'
                    onChange={changeHandler}
                />
                <Pagination
                    current={page}
                    pageSize={20}
                    showSizeChanger={false}
                    style={{
                        textAlign: 'center', marginTop: '5px',
                    }}
                    total={totalCount}
                    onChange={e => {
                        setPage(e);
                    }}
                />
            </div>
        );
    };

    const renderPeople = () => {
        if (!query) {
            return null;
        }

        return (
            <div
                style={{ marginTop: 30 }}
            >
                <h3
                    style={{
                        paddingBottom: 30,
                        marginBottom: 30,
                        borderBottom: '1px solid #EEE',
                    }}
                >
                    People (
                    {people.length}
                    )
                </h3>
                {people.map(person => (
                    <div
                        style={{ marginBottom: 40 }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{ marginRight: 10 }}
                            >
                                <Avatar
                                    size={100}
                                    src={person.employer.logo_url}
                                />
                            </div>
                            <div>
                                <h5
                                    style={{ margin: 0 }}
                                >
                                    <Link to={`/employers/${person.employer.id}`}>
                                        {person.name}
                                    </Link>
                                </h5>
                                <div>
                                    <strong>{person.employer.account_title}</strong>
                                </div>
                                <div>
                                    {person.employer.name}
                                    ,
                                    {person.employer.address.city}
                                </div>
                                <div>
                                    {person.phone}
                                </div>
                                <div>
                                    {person.email}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    return (
        <div
            style={{
                overflow: 'auto',
                width: '100%',
            }}
        >
            {renderEmployers()}
            {renderPeople()}
        </div>
    );
};

EmployerSearchResult.propTypes = {
    employersHasFetched: PropTypes.bool,
    isFetching: PropTypes.bool,
    employers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        department: PropTypes.string,
        logo_url: PropTypes.string.isRequired,
        margin_config_id: PropTypes.number.isRequired,
        last_active: PropTypes.number.isRequired,
        published_shifts: PropTypes.number.isRequired,
        created_time: PropTypes.number.isRequired,
    })),
    people: PropTypes.arrayOf(PropTypes.shape({
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            logo_url: PropTypes.string.isRequired,
            address: PropTypes.shape({ city: PropTypes.string.isRequired }),
            account_title: PropTypes.string,
        }),
        name: PropTypes.string.isRequired,
        phone: PropTypes.string,
        email: PropTypes.string,
    })),
    employersFilterSetSort: PropTypes.func,
    page: PropTypes.number,
    setPage: PropTypes.func,
    totalCount: PropTypes.number,
    query: PropTypes.string,
    marginOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployerSearchResult;
