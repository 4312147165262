import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';

const OccupationFilter = ({
    occupations,
    onChange,
    value,
}) => (
    <section className='occupation-filter'>
        <h4 className='heading'>Occupation:</h4>

        {occupations.map(occupation => (
            <label
                key={occupation.id}
                htmlFor={occupation.id}
            >
                <input
                    checked={occupation.id === value}
                    className='input'
                    defaultChecked={occupation.id === config.occupations.OCCUPATION_ALL}
                    id={occupation.id}
                    name='occupation'
                    type='radio'
                    onChange={() => onChange(occupation.id)}
                />
                {occupation.name}
            </label>
        ))}
    </section>
);

OccupationFilter.propTypes = {
    occupations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};

export default OccupationFilter;
