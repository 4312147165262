const initialState = {
  responseAddingAd: {},
  isFetching: false,
  hasFetched: true,
};

export function externalAds(state = initialState, action) {
  switch (action.type) {
      case 'REQUEST_CREATE_AD':
          return {
              ...state, 
              isFetching: true,
              hasFetched: false,
          };

      case 'RECEIVE_RESPONSE_AD':
          return {
              ...state,
              responseAddingAd: action.data,
              isFetching: false,
              hasFetched: true,
          };

      default:
          return state;
  }
}
