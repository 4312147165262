import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as jobsActions from 'actions/jobs';
import * as activitiesActions from 'actions/activities';
import * as dashboardActions from 'actions/dashboard';
import Loader from 'components/shared/loader';
import { selectActivitiesByDays } from 'selectors';

import MonthOverview from './monthoverview';
import SimpleOverview from './simpleoverview';

import './styles.scss';

const HomeDashboard = props => {
    const {
        ready,
        activities,
        fetchActivities,
    } = props;

    useEffect(() => {
        fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ready) {
        return <Loader />;
    }
    return (
        <main className='home-dashboard'>
            <div className='content'>

                <SimpleOverview {...props} />

                <MonthOverview activities={activities} />
            </div>
        </main>
    );
};

HomeDashboard.propTypes = {
    ready: PropTypes.bool.isRequired,
    activities: PropTypes.shape({}),
    fetchActivities: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        ready: true,
        activitiesObjs: state.activities.activities,
        activities: selectActivitiesByDays(state),
        dashboard: state.dashboard,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(
        {
            ...activitiesActions,
            ...jobsActions,
            ...dashboardActions,
        }, dispatch,
    ),
)(HomeDashboard);
