import React from 'react';
import PropTypes from 'prop-types';
import {
    MinusCircleTwoTone,
    PlusCircleTwoTone,
} from '@ant-design/icons';

function ExpandIcon({
    expanded,
    onExpand,
    record,
}) {
    const hasBookings = record && (record.booking?.id || !!record.applications.length);
    if (hasBookings) {
        return expanded ? (
            <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
        ) : (
            <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
        );
    }
    return <div />;
}

ExpandIcon.propTypes = {
    expanded: PropTypes.bool,
    onExpand: PropTypes.func,
    record: PropTypes.shape({
        booking: PropTypes.shape({
            id: PropTypes.number,
        }),
        applications: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default ExpandIcon;
