import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    map,
    sortBy,
} from 'lodash';

import PublishEvent from './PublishEvent';
import BookingEvent from './BookingEvent';
import SickLeaveEvent from './SickLeaveEvent';

import './styles.scss';

const Timeline = ({ events }) => (
    <div className='mp-dashboard__timeline'>
        {map(events, (daylyEvents, day) => {
            const m = moment.unix(daylyEvents[0].created_time);
            const isToday = m.isSame(moment(), 'day');

            const getEventComponent = event => {
                switch (event.meta_data.type) {
                    case 'publish_shifts':
                        return PublishEvent;
                    case 'booking_confirmed':
                        return BookingEvent;
                    case 'sick_leave':
                        return SickLeaveEvent;
                    default:
                        return null;
                }
            };

            return (
                <div key={day}>
                    <div className='timeline__event-day'>
                        {isToday
                            ? 'Today'
                            : `${day.toUpperCase()} ${m.format('D/M')}`}
                    </div>
                    {sortBy(daylyEvents, 'created_time').reverse()
                        .map((event, i) => {
                            const EventComponent = getEventComponent(event);
                            return EventComponent && <EventComponent key={i} event={event} />;
                        })}
                </div>
            );
        })}
    </div>
);

Timeline.propTypes = { events: PropTypes.shape({}) };

export default Timeline;
