const filterAndMerged = (obj, item) => {
  let newObj = {};
  for (const [key, arr] of Object.entries(obj)) {
    const filteredArr = arr.filter(str => {
      const [a1, a2] = str.split('::');
      const [b1, b2] = item.split('::');
      return str !== item || a1 !== b1 || a2 !== b2;
    });
    const newArr = filteredArr.length ? { [key]: filteredArr } : {};
    newObj = {
      ...newObj,
      ...newArr
    }
  }
  return newObj
}

const RemoveItemFromFilter = (GENERAL, type, selectedActivityTypes, key, item) => {
  const existingGeneralTypes = selectedActivityTypes[GENERAL] || {}
  const { change, not } = key in existingGeneralTypes ? { change: GENERAL, not: type} : { change: type, not: GENERAL};
  const changedFilter = filterAndMerged(selectedActivityTypes[change], item);
  const changedArr = Object.keys(changedFilter).length ? { [change]: changedFilter } : {};
  const notChangedArr = selectedActivityTypes[not] ? { [not]: selectedActivityTypes[not] } : {};
  return {
    ...notChangedArr,
    ...changedArr
  };
};

export default RemoveItemFromFilter;
