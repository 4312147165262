import React from 'react';
import Chart from 'chart.js';

import config from 'config';

export default class ConsultantChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dataFetched: false };
    }

    componentDidMount() {
        const consultantCtx = this.refs.chart.getContext('2d');
        this.chart = new Chart(consultantCtx, {
            type: 'line',
            data: this.getChartData(),
            options: { scales: {
                xAxes: [{ gridLines: { display: false } }],
                yAxes: [{
                    id: 'A',
                    type: 'linear',
                    gridLines: { display: false },
                    ticks: { beginAtZero: true },
                }, {
                    id: 'B',
                    type: 'linear',
                    position: 'right',
                    gridLines: { display: false },
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 10,
                    },
                }],
            } },
        });
    }

    getChartData() {
        return {
            labels: this.props.data.map(d => d.month),
            datasets: [{
                yAxisID: 'A',
                label: 'Konsulter',
                data: this.props.data.map(d => d.totalConsultants),
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: config.colors[7].hex,
                borderWidth: 2,
                pointRadius: 2,
                lineTension: 0.1,

            }, {
                yAxisID: 'B',
                label: 'Aktiveringsgrad',
                data: this.props.data.map(d => ((d.totalActiveConsultants.length / (d.totalConsultants * 0.8)) * 100).toFixed(1)),
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: config.colors[1].hex,
                borderWidth: 2,
                pointRadius: 2,
                lineTension: 0.1,

            }, {
                yAxisID: 'B',
                label: 'Aktiveringsgrad per månad',
                data: this.props.data.map(d => ((d.activeConsultants / (d.totalConsultants * 0.8)) * 100).toFixed(1)),
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: config.colors[2].hex,
                borderWidth: 2,
                pointRadius: 2,
                lineTension: 0.1,

            }],
        };
    }

    getActivityChartData() {
        return {
            labels: this.props.data.map(d => d.month),
            datasets: [{
                label: 'Aktiveringsgrad',
                data: this.props.data.map(d => ((d.totalActiveConsultants.length / (d.totalConsultants * 0.8)) * 100).toFixed(1)),
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: config.colors[4].hex,
                borderWidth: 2,
                pointRadius: 2,
                lineTension: 0.3,

            }],
        };
    }

    render() {
        return (
            <canvas
                ref='chart'
                id='chart'
            />
        );
    }
}
