import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as faqsActions from 'actions/faqs';

import {
    find,
    isNull,
    sortBy,
} from 'lodash';

import {
    Button,
    Modal,
    Menu,
    Card,
    Spin,
    Badge,
    Collapse,
    Popconfirm,
    message,
} from 'antd';
import {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import InputModal from 'components/inputmodal';

import './styles.scss';

const FAQS = [{
    id: 1,
    question: 'Lorem ipsum dolor ist amet',
    answer: 'Lorem ipsum dolor sit amet, eam eu similique forensibus efficiendi, in erant utroque vis. Has id lucilius intellegat. Ad luptatum mandamus reprehendunt per. Reque altera admodum his ut, has saepe conclusionemque te. Ut errem recusabo adversarium mei, no vide porro zril mea. Eirmod option atomorum ex eum, audire veritus pri id, tritani tibique voluptaria eu nam. ',
}, {
    id: 2,
    question: 'Lorem ipsum dolor ist amet',
    answer: 'Lorem ipsum dolor sit amet, eam eu similique forensibus efficiendi, in erant utroque vis. Has id lucilius intellegat. Ad luptatum mandamus reprehendunt per. Reque altera admodum his ut, has saepe conclusionemque te. Ut errem recusabo adversarium mei, no vide porro zril mea. Eirmod option atomorum ex eum, audire veritus pri id, tritani tibique voluptaria eu nam. ',
}];

class Faqs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddFaqModal: false,
            showAddCategoryModal: false,
            activeSectionId: null,
            isEditingCategory: false,
            isEditingFaq: false,
            editFaq: null,
        };

        this.openAddFaqModal = this.openAddFaqModal.bind(this);
        this.closeAddFaqModal = this.closeAddFaqModal.bind(this);
        this.onSubmitAddFaq = this.onSubmitAddFaq.bind(this);
        this.openAddCategoryModal = this.openAddCategoryModal.bind(this);
        this.closeAddCategoryModal = this.closeAddCategoryModal.bind(this);
        this.onSubmitAddCategory = this.onSubmitAddCategory.bind(this);
    }

    componentDidMount() {
        this.props.fetchFaqs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFetching && !this.props.isFetching) {
            this.setState({ activeSectionId: this.props.faqs.faqs[0].id });
        }

        if (prevProps.categorySaving && !this.categorySaving) {
            if (this.state.isEditingCategory) {
                message.success('Kategorin uppdaterades');
            }
            else {
                message.success('Kategorin lades till');
            }

            this.closeAddCategoryModal();
        }

        if (prevProps.faqSaving && !this.props.faqSaving) {
            message.success('Frågan lades till');
            this.closeAddFaqModal();
        }

        if (prevProps.faqIsUpdating && !this.props.faqsIsUpdating) {
            message.success('Frågan uppdaterades');
            this.closeAddFaqModal();
        }

        if (prevProps.faqIsRemoving && !this.props.faqIsRemoving) {
            message.success('Frågan togs bort');
        }
    }

    openAddFaqModal(e, isEditing = false, editFaq = null) {
        this.setState({
            showAddFaqModal: true,
            isEditingFaq: isEditing,
            editFaq,
        });
    }

    closeAddFaqModal() {
        this.setState({
            showAddFaqModal: false,
            isEditingFaq: false,
            editFaq: null,
        });

        this.refs.addFaq.refs.form.reset();
    }

    onSubmitAddFaq() {
        const formData = this.refs.addFaq.refs.form.getModel();
        const data = {
            ...formData,
            order: parseInt(formData.order),
        };

        if (this.state.isEditingFaq) {
            this.props.updateFaq(this.state.editFaq.id, data, this.state.activeSectionId);
        }
        else {
            this.props.addFaq(data);
        }
    }

    renderAddFaqModal() {
        const editing = this.state.isEditingFaq;

        let question = '';
        let answer = '';
        let order = 0;
        let category = this.state.activeSectionId;
        let title = 'Lägg till fråga';
        let saveButtonTitle = 'Lägg till';

        if (editing) {
            const faq = this.state.editFaq;
            question = faq.question;
            answer = faq.answer;
            order = faq.order;
            category = faq.category_id;
            title = 'Redigera fråga';
            saveButtonTitle = 'Spara';
        }

        const loading = this.props.faqSaving;

        return (
            <Modal
                footer={[
                    <Button
                        key='back'
                        size='large'
                        onClick={this.closeAddFaqModal}
                    >
                        Avbryt
                    </Button>,
                    <Button
                        key='submit'
                        loading={loading}
                        size='large'
                        type='primary'
                        onClick={this.onSubmitAddFaq}
                    >
                        {saveButtonTitle}
                    </Button>,
                ]}
                title={title}
                visible={this.state.showAddFaqModal}
                onCancel={this.closeAddFaqModal}
                onOk={this.onSubmitAddFaq}
            >
                <InputModal
                    ref='addFaq'
                    hideButton
                    inputs={[
                        {
                            type: 'select',
                            name: 'category_id',
                            label: 'Kategori',
                            value: category,
                            options: this.props.faqs.faqs.map((faq, i) => ({
                                value: faq.id,
                                title: faq.name,
                            })),
                        },
                        {
                            type: 'input',
                            name: 'question',
                            label: 'Fråga',
                            value: question,
                        },
                        {
                            type: 'textarea',
                            name: 'answer',
                            label: 'Svar',
                            value: answer,
                        },
                        {
                            type: 'input',
                            name: 'order',
                            label: 'Order',
                            value: order,
                        },
                    ]}
                />
            </Modal>
        );
    }

    openAddCategoryModal(e, isEditing = false) {
        this.setState({
            showAddCategoryModal: true,
            isEditingCategory: isEditing,
        });
    }

    closeAddCategoryModal() {
        this.setState({
            showAddCategoryModal: false,
            isEditingCategory: false,
        });
    }

    onSubmitAddCategory() {
        const formData = this.refs.addCategory.refs.form.getModel();
        const data = {
            ...formData,
            order: parseInt(formData.order),
        };

        if (this.state.isEditingCategory) {
            this.props.updateFaqCategory(this.state.activeSectionId, data);
        }
        else {
            this.props.addFaqCategory(data);
        }
    }

    renderAddCategoryModal() {
        const editing = this.state.isEditingCategory;

        let name = '';
        let order = '';
        let title = 'Lägg till kategori';
        let saveButtonTitle = 'Lägg till';

        if (editing) {
            const faq = find(this.props.faqs.faqs, { id: this.state.activeSectionId });
            name = faq.name;
            order = faq.order;
            title = 'Redigera kategori';
            saveButtonTitle = 'Spara';
        }

        const loading = this.props.categorySaving;

        return (
            <Modal
                footer={[
                    <Button
                        key='back'
                        size='large'
                        onClick={this.closeAddCategoryModal}
                    >
                        Avbryt
                    </Button>,
                    <Button
                        key='submit'
                        loading={loading}
                        size='large'
                        type='primary'
                        onClick={this.onSubmitAddCategory}
                    >
                        {saveButtonTitle}
                    </Button>,
                ]}
                title={title}
                visible={this.state.showAddCategoryModal}
                onCancel={this.closeAddCategoryModal}
                onOk={this.onSubmitAddCategory}
            >
                <InputModal
                    ref='addCategory'
                    hideButton
                    inputs={[
                        {
                            type: 'input',
                            name: 'name',
                            label: 'Namn',
                            value: name,
                        },
                        {
                            type: 'input',
                            name: 'order',
                            label: 'Order',
                            value: order,
                        },
                    ]}
                />
            </Modal>
        );
    }

    renderCategories() {
        return (
            <Card
                // title="Kategorier"
                bodyStyle={{ padding: 0 }}

                title={(
                    <Button
                        icon={<PlusCircleOutlined />}
                        size='large'
                        type='primary'
                        onClick={this.openAddCategoryModal}
                    >
                        Lägg till kategori
                    </Button>
                )}
            >
                <Menu
                    selectedKeys={[this.state.activeSectionId ? this.state.activeSectionId.toString() : '']}
                    size='large'
                    style={{
                        borderRight: 'none',
                        width: 240,
                    }}
                >
                    {sortBy(this.props.faqs.faqs, 'order').map(faq => (
                        <Menu.Item
                            key={faq.id}
                            onClick={() => {
                                this.setState({ activeSectionId: faq.id });
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    {faq.name}
                                </div>
                                <div>
                                    <Badge
                                        count={faq.questions.length}
                                        style={{
                                            backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset',
                                        }}
                                    />
                                </div>
                            </div>
                        </Menu.Item>
                    ))}
                </Menu>
            </Card>
        );
    }

    renderFaqs() {
        if (isNull(this.state.activeSectionId)) {
            return null;
        }

        const faq = find(this.props.faqs.faqs, { id: this.state.activeSectionId });

        return (
            <Card
                // title={faq.name}
                style={{
                    marginLeft: 10,
                    flex: 1,
                }}
                title={(
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <h4
                                style={{ margin: 0 }}
                            >
                                {faq.name}
                            </h4>
                        </div>
                        <div>
                            <Button
                                icon={<EditOutlined />}
                                size='large'
                                style={{ marginRight: 10 }}
                                onClick={this.openAddCategoryModal.bind(this, true)}
                            >
                                Redigera kategori
                            </Button>
                            <Button
                                icon={<PlusCircleOutlined />}
                                size='large'
                                type='primary'
                                onClick={e => {
                                    this.openAddFaqModal(e, false, null);
                                }}
                            >
                                Lägg till fråga
                            </Button>
                        </div>
                    </div>
                )}
            >
                <Collapse
                    // bordered={false}
                    key={faq.id}
                >
                    {faq.questions.map((question, i) => (
                        <Collapse.Panel
                            key={i}
                            header={(
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        {question.question}
                                    </div>
                                    <div
                                        style={{ marginRight: 20 }}
                                    >

                                        <Button
                                            // type="primary"
                                            icon={<EditOutlined />}
                                            // shape="circle"
                                            size='small'
                                            onClick={e => {
                                                e.stopPropagation();
                                                this.openAddFaqModal(e, true, question);
                                            }}
                                        >
                                            Redigera
                                        </Button>

                                        <Popconfirm
                                            cancelText='Avbryt'
                                            okText='Ta bort'
                                            okType='danger'
                                            // onCancel={cancel}
                                            placement='topRight'
                                            title='Är du säker på att du vill ta bort den här frågan?'
                                            onConfirm={() => {
                                                this.props.removeFaq(question.id, question.category_id);
                                            }}
                                        >
                                            <Button
                                                danger
                                                ghost
                                                icon={<DeleteOutlined />}
                                                size='small'
                                                style={{ marginLeft: 10 }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Ta bort
                                            </Button>
                                        </Popconfirm>

                                    </div>
                                </div>
                            )}
                        >
                            {question.answer}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Card>
        );
    }

    renderSpinner() {
        return (
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Spin
                    size='large'
                />
            </div>
        );
    }

    renderFaqsContent() {
        return (
            <div
                style={{ display: 'flex' }}
            >
                {this.renderCategories()}
                {this.renderFaqs()}

                {this.renderAddFaqModal()}
                {this.renderAddCategoryModal()}
            </div>
        );
    }

    renderContent() {
        if (this.props.isFetching) {
            return this.renderSpinner();
        }

        return this.renderFaqsContent();
    }

    render() {
        return (
            <main className='faq'>
                <h2 className='heading'>
                    Frågor och svar
                </h2>
                {this.renderContent()}
            </main>
        );
    }
}

const mapStateToProps = state => ({
    isFetching: state.faqs.faqsIsFetching,
    categorySaving: state.faqs.faqCategoryIsSaving,
    faqSaving: state.faqs.faqIsSaving,
    faqIsUpdating: state.faqs.faqIsUpdating,
    faqIsRemoving: state.faqs.isRemovingFaq,
    faqs: state.faqs,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(faqsActions, dispatch),
)(Faqs);
