import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Button } from 'antd';
import ProfileForm from 'containers/orders/components/Profile';
import { useSelector } from 'react-redux';
import ModalFooter from './ModalFooter';

const HandleProfile = ({
    profile,
    employer,
    setProfile,
    onSubmit,
    setIsModalVisible,
    profilesAreEqual,
}) => {
    const {
        orders,
        settings,
    } = useSelector(state => state);
    const employerProfiles = employer?.profiles?.profiles?.length ? employer.profiles.profiles : [];
    const matchedProfiles = employerProfiles.filter(p => {
        const isSameDescription = profile.description === p.description;
        const isSameDriverLicense = profile.driver_license === p.driver_license;
        const isSameOccupation = profile.occupation_id === p.occupation_id;
        const isSameSpecializations = isEqual(profile.specialization_ids, p.specialization_ids);

        return isSameDescription
            && isSameDriverLicense
            && isSameOccupation
            && isSameSpecializations
            && !profile.is_long_term
            && !p.is_long_term;
    });

    return (
        <>
            { matchedProfiles.length > 0 && (
                <>
                    <div className='mpa-profile-warning'>
                        <p>
                            {matchedProfiles.length > 1 ? 'Those profiles ' : 'This profile '}
                            matches with the procurement profile
                        </p>
                        <ul>
                            {matchedProfiles.map(p => (
                                <li key={`matchedProfileListItem${p.id}`}>
                                    {p.name}
                                    {' '}
                                    <Button
                                        type={p.id === profile.id ? 'primary' : 'dashed'}
                                        onClick={(() => setProfile(p))}
                                    >
                                        Select
                                    </Button>
                                </li>
                            ))}
                        </ul>

                    </div>
                </>
            )}
            <ProfileForm
                countryCode={employer.country_code}
                order={orders.order}
                profile={profile}
                settings={settings}
                onChange={setProfile}
            />
            <ModalFooter
                cancelAction={() => setIsModalVisible(false)}
                cancelText='Cancel'
                okAction={() => onSubmit()}
                okText={profilesAreEqual ? 'Use profile' : 'Save new profile'}
            />
        </>
    );
};
HandleProfile.propTypes = {
    profile: PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.number,
        is_long_term: PropTypes.bool,
        occupation_id: PropTypes.number,
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        driver_license: PropTypes.bool,
    }).isRequired,
    employer: PropTypes.shape({
        country_code: PropTypes.string,
        profiles: PropTypes.shape({
            profiles: PropTypes.arrayOf(PropTypes.shape({
                description: PropTypes.string,
                id: PropTypes.number,
                is_long_term: PropTypes.bool,
                occupation_id: PropTypes.number,
                specialization_ids: PropTypes.arrayOf(PropTypes.number),
                driver_license: PropTypes.bool,
            })),
        }).isRequired,
    }).isRequired,
    settings: PropTypes.shape({}).isRequired,
    setProfile: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    profilesAreEqual: PropTypes.bool.isRequired,
};
export default HandleProfile;
