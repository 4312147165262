import {
    Empty,
    Table,
    Tag,
    Button,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import './styles.scss';
import { getFormattedDateWithWeek } from 'utils/datetimeformatters';

const OrdersList = ({
    ordersList,
    onChange,
    onRow,
    pagination: {
        page,
        totalCount,
    },
    onDelete,
}) => {
    const orders = ordersList;
    const createCustomerName = (name, department) => `${name}, ${department}`;

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (_, order) => (
                <div key={`order-${order.id}`} className='order-list-clickable-item'>
                    {order.id}
                </div>
            ),
        },
        {
            title: 'Customer',
            dataIndex: 'data',
            key: 'data',
            render: (_, order) => (
                <div key={`customer-${order.id}`} className='order-list-clickable-item'>
                    {createCustomerName(order.customer_name, order.department_name)}
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Published',
            dataIndex: 'is_posted',
            key: 'is_posted',
            render: (_, order) => (
                <div key={`published-${order.id}`} className='benefit-list-clickable-item'>
                    {order.is_posted ? <Tag color='green'>YES</Tag> : <Tag color='red'>NO</Tag>}
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (_, order) => {
                const headCount = order?.roles[0]?.shifts[0]?.head_count || order?.roles[0]?.periods[0]?.head_count || 1;

                return (
                    <div key={`role-${order.id}`} className='benefit-list-clickable-item'>
                        {order?.roles[0]?.name || 'N/A'}
                        {' '}
                        {`x${headCount}`}
                    </div>
                );
            },
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, order) => {
                const shiftsCount = order?.roles[0]?.shifts?.length || 0;
                const periodsCount = order?.roles[0]?.periods?.length || 0;

                return (
                    <div key={`type-${order.id}`} className='benefit-list-clickable-item'>
                        {shiftsCount > 0 && (
                            <Tag color='blue'>
                                Shift x
                                {shiftsCount}
                            </Tag>
                        )}
                        {periodsCount > 0 && (
                            <Tag color='purple'>
                                LTV x
                                {periodsCount}
                            </Tag>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Due Date',
            dataIndex: 'order_due_date_datetime',
            key: 'order_due_date_datetime',
            render: (_, order) => (
                <div key={`due-date-${order.id}`} className='benefit-list-clickable-item'>
                    {getFormattedDateWithWeek(order.order_due_date_datetime)}
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, order) => (
                <Button
                    danger
                    size='small'
                    type='default'
                    onClick={e => {
                        e.stopPropagation();
                        onDelete(order.id);
                    }}
                >
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    const rowClassName = record => {
        const {
            order_creation_datetime,
            order_due_date_datetime,
            paused,
        } = record;

        const currentTime = moment();
        const hasStarted = !moment(order_creation_datetime).startOf('d').isAfter(currentTime.startOf('d'));
        const hasNotEnded = !moment(order_due_date_datetime).endOf('d').isBefore(moment().endOf('d'));

        const isActive = hasStarted && hasNotEnded && !paused;

        return isActive ? 'benefit-list-row-active' : 'benefit-list-row-inactive';
    };

    return (
        orders.length ? (
            <Table
                bordered
                columns={columns}
                dataSource={orders}
                pagination={{
                    position: 'both',
                    current: page,
                    pageSize: 20,
                    total: totalCount,
                }}
                rowClassName={rowClassName}
                rowKey='id'
                size='large'
                onChange={onChange}
                onRow={onRow}
            />
        ) : (
            <Empty description='No orders found, please export orders from Globus' />
        )
    );
};

OrdersList.propTypes = {
    ordersList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        customer_name: PropTypes.string,
        department_name: PropTypes.string,
        is_posted: PropTypes.bool,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            shifts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                start_datetime: PropTypes.string,
                end_datetime: PropTypes.string,
                is_posted: PropTypes.bool,
            })),
        })),
        order_due_date_datetime: PropTypes.number,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    onRow: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        page: PropTypes.number,
        totalCount: PropTypes.number,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default OrdersList;
