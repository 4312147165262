import React from 'react';
import { Tag} from 'antd';

const filterText = (id, tagName) => {
  if (tagName === 'Start date' || tagName === 'End date') return `${tagName} / ${id}`
  return tagName
}
const TagGroup = ({ selectedActivityTypes, onRemoveTag }) => {
  const renderSelectedTypes = Object.keys(selectedActivityTypes).map((parentName) => {
    const options = selectedActivityTypes[parentName]
    const items = options.map((item) => {
      const [id, tagName] = item.split('::')
      return (<Tag key={`${parentName}-${id}`} closable onClose={() => onRemoveTag(parentName, item)}>
        <span className='selected-label'>{filterText(id, tagName)}</span>
        </Tag>)
    });
    return items
  })
  return (
    <div>
      {renderSelectedTypes}
    </div>
  );
}

export default TagGroup;
