import React from 'react';
import {
    Link,
    withRouter,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as employerActions from 'actions/employer';
import * as jobsActions from 'actions/jobs';
import * as consultantActions from 'actions/consultants';
import LoadingScreen from 'components/shared/loadingscreen';
import { Menu } from 'antd';
import EmployerTop from './employertop';
import './styles.scss';

class Employer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requestForEmployersOngoing: true,
            month: moment().startOf('day').startOf('month'),
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        this.props.fetchEmployerProfiles(id);
        this.props.fetchEmployerUsers(id);
        this.props.fetchEmployerJobads(id);
        this.props.fetchEmployer(id).then(resp => {
            this.setState({ requestForEmployersOngoing: false });
        });
    }

    renderEmployerContent() {
        const tabsConfig = {
            urlPrefix: `/employers/${this.props.match.params.id}`,
            tabs: [{
                title: 'Overview',
                urlSegment: '',
            }, {
                title: 'Shifts',
                urlSegment: '/shifts',
            }, {
                title: 'Profiles',
                urlSegment: '/profiles',
            }, {
                title: 'Work hours',
                urlSegment: '/custom-shifts',
            }, {
                title: 'Invoice address',
                urlSegment: '/invoice-address',
            }, {
                title: 'Contact persons',
                urlSegment: '/contacts',
            }, {
                title: 'Change credentials',
                urlSegment: '/change-credentials',
            }, {
                title: 'Job ads',
                urlSegment: '/job-ads',
            }, {
                title: 'Long term vacancies',
                urlSegment: '/long-term-vacancies',
            },
            // todo: complitly remove link, component and clean router
            // {
            //     title: 'Scheduling',
            //     urlSegment: '/scheduling',
            // }
            ],
        };

        const { employer } = this.props.employer;

        if (this.state.requestForEmployersOngoing) {
            return (
                <LoadingScreen
                    fullScreen
                    text='Digga digitalt!'
                />
            );
        }

        return (
            <>
                <div style={{ position: 'relative' }}>
                    <EmployerTop
                        history={this.props.history}
                    />
                </div>

                {/* <TabBar tabsConfig={tabsConfig}/> */}
                <div
                    style={{}}
                >
                    <Menu
                        mode='horizontal'
                        selectedKeys={[window.location.href]}
                        style={{ paddingLeft: 80 }}
                        onClick={() => {

                        }}
                    >
                        {tabsConfig.tabs.map(c => (
                            <Menu.Item key={window.location.origin + tabsConfig.urlPrefix + c.urlSegment}>
                                <Link
                                    to={tabsConfig.urlPrefix + c.urlSegment}
                                />
                                {c.title}
                            </Menu.Item>
                        ))}
                    </Menu>
                </div>
                <div style={{ overflow: 'scroll' }}>
                    {this.props.children}
                </div>

            </>
        );
    }

    render() {
        return (
            <main className='mpa-update-employer'>
                {this.renderEmployerContent()}
            </main>
        );
    }
}

export default withRouter(connect(
    state => state,
    dispatch => bindActionCreators({
        ...employerActions, ...jobsActions, ...consultantActions,
    }, dispatch),
)(Employer));
