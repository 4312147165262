import React from 'react';
import PropTypes from 'prop-types';
import {
    AutoComplete,
    Avatar,
} from 'antd';

const ConsultantSelector = ({
    consultants,
    onSearch,
    onSelect,
}) => {
    const children = consultants.map(consultant => (
        <AutoComplete.Option
            key={consultant.id}
            value={String(consultant.id)}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 0',
                }}
            >
                <div>
                    <Avatar
                        size='large'
                        src={consultant.avatar}
                        style={{ marginRight: 10 }}
                    />
                </div>
                <div>
                    <div
                        style={{ fontWeight: '600' }}
                    >
                        {consultant.firstname}
                        {' '}
                        {consultant.lastname}
                        {' '}
                        (ID
                        {' '}
                        {consultant.id}
                        )
                    </div>
                    <div>
                        {consultant.email}
                    </div>
                </div>
            </div>
        </AutoComplete.Option>
    ));

    return (
        <AutoComplete
            dropdownMatchSelectWidth={false}
            placeholder='Search consultant name or ID...'
            style={{
                marginBottom: 20,
                width: 250,
            }}
            onSearch={onSearch}
            onSelect={onSelect}
        >
            {children}
        </AutoComplete>
    );
};

ConsultantSelector.propTypes = {
    consultants: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSearch: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default ConsultantSelector;
