import * as request from 'request';

export function receiveSalaryErrorList(resp) {
    return {
        type: 'RECEIVE_SALARY_ERROR_LIST',
        collection: resp.collection,
    };
}

export function fetchSalaryErrorList() {
    return dispatch => request.fetchSalaryErrorList()
        .then(resp => dispatch(receiveSalaryErrorList(resp)));
}
