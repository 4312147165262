import React, {
    useEffect,
    useState,
} from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    EyeOutlined,
    EyeInvisibleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {
    Table,
    Button,
    Divider,
} from 'antd';
import moment from 'moment';
import InfoRow from './InfoRow';
import {
    fetchProcurements,
    updateProcurement,
} from '../../actions/procurements';
import LoadingScreen from '../../components/shared/loadingscreen';
import './styles.scss';
import HandleProcurementModal from './handleprocurementmodal';
import Filters from './Filters';

const Procurements = () => {
    const dispatch = useDispatch();
    const {
        procurements,
        isFetching,
        updatingProcurement,
    } = useSelector(state => state.procurements);
    const {
        occupations,
        specializations,
        counties,
        skills,
    } = useSelector(state => state.settings);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [selectedProcurement, setSelectedProcurement] = useState(null);
    const [handleProcurementStep, setHandleProcurementStep] = useState('checkEmployer');
    const [filters, setFilters] = useState({
        hasEmployer: false,
        hasJob: false,
        isHidden: true,
        last_application_time: Date.now() / 1000,
    });

    useEffect(() => {
        dispatch(fetchProcurements());
    }, []);

    const replaceSwedishChar = str => str.replace(/å/g, 'a').replace(/ä/g, 'a').replace(/ö/g, 'o');

    const procurementOccupation = procurement => occupations.find(occupation => occupation.id === procurement.occupation_id);
    const procurementSpecializations = procurement => procurement.specializations
        .map(specializationId => specializationId.split('_')[1]);

    const mpSpecializations = procurement => specializations.filter(
        specialization => procurementSpecializations(procurement)
            .includes(replaceSwedishChar(specialization.name.replace(' ', '')).toUpperCase())
            && specialization.occupation_id === procurement.occupation_id,
    );

    const duration = (start, end) => moment.unix(end).diff(moment.unix(start), 'days');

    const grossProfit = procurement => {
        const {
            employment_scope,
            price,
            start_time,
            end_time,
        } = procurement;
        const jobDuration = duration(start_time, end_time);
        const numberOfHoursPerDay = 8 * employment_scope / 100;
        return jobDuration * numberOfHoursPerDay * price * 0.18;
    };

    const columns = [
        {
            title: '',
            render: (text, record) => (updatingProcurement !== record.id ? (
                <Button
                    danger={record.hidden}
                    icon={record.hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    shape='circle'
                    onClick={() => dispatch(updateProcurement(record.id, { hidden: !record.hidden }))}
                />
            ) : <Button icon={<SyncOutlined spin />} />)
            ,
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Title',
            render: (text, record) => (
                <>
                    <div className='procurements-info-title'>{record.simple_title}</div>
                    <div className='procurements-info-title-small'>{record.procurement_title}</div>
                    <p>{record.name}</p>
                </>
            ),
            sorter: (a, b) => (a.simple_title > b.simple_title ? 1 : -1),
        },
        {
            title: 'Start / end',
            render: (text, record) => (
                `${moment.unix(record.start_time).format('YYYY-MM-DD')} / ${moment.unix(record.end_time).format('YYYY-MM-DD')}`
            ),
            sorter: (a, b) => (a.start_time > b.start_time ? 1 : -1),
        },
        {
            title: 'Durartion',
            render: (text, record) => (
                `${duration(record.start_time, record.end_time)} days`
            ),
            sorter: (a, b) => (duration(a.start_time, a.end_time) > duration(b.start_time, b.end_time) ? 1 : -1),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            sorter: (a, b) => (a.price > b.price ? 1 : -1),
        },
        {
            title: 'Scope',
            dataIndex: 'employment_scope',
            key: 'employment_scope',
        },
        {
            title: 'Gross profit',
            render: (text, record) => `${grossProfit(record).toLocaleString()} Kr`,
            sorter: (a, b) => (grossProfit(a) > grossProfit(b) ? 1 : -1),
        },
    ];

    if (isFetching) {
        return (
            <LoadingScreen
                fullScreen
                text={(
                    <>
                        Fais péter la thune!!!
                        <br />
                        💰💰💰
                    </>
                )}
            />
        );
    }
    return (
        <main className='mpa-ltvs'>
            <Filters filters={filters} setFilters={setFilters} />
            <Divider />
            <h3>
                Procurements
            </h3>
            {procurements.length && (
                <Table
                    className='procurement-info'
                    columns={columns}
                    dataSource={procurements.filter(procurement => {
                        const {
                            hasEmployer,
                            hasJob,
                            isHidden,
                            last_application_time,
                        } = filters;
                        if (hasEmployer && procurement?.employer?.id) {
                            return false;
                        }
                        if (hasJob && procurement.job_id) {
                            return false;
                        }
                        if (isHidden && procurement.hidden) {
                            return false;
                        }
                        if (last_application_time && last_application_time > procurement.last_application_time) {
                            return false;
                        }
                        return true;
                    })}
                    expandable={{
                        expandedRowRender: record => (
                            <InfoRow
                                counties={counties}
                                mpSpecializations={mpSpecializations}
                                procurement={record}
                                procurementOccupation={procurementOccupation}
                                setIsModalVisible={setIsModalVisible}
                                setSelectedEmployer={setSelectedEmployer}
                                setSelectedProcurement={setSelectedProcurement}
                            />
                        ),
                        expandedRowKeys: procurements.map(p => p.id),
                        expandIcon: () => <></>,

                        defaultExpandAllRows: true,
                        expandedRowClassName: () => 'procurements-info-info-row',
                    }}
                    pagination={false}
                    rowKey='id'
                />
            )}
            <HandleProcurementModal
                cancelText='Avbryt'
                employer={selectedEmployer}
                mpSpecializations={mpSpecializations}
                okText='Fortsätt'
                procurement={selectedProcurement}
                procurementOccupation={procurementOccupation}
                setIsModalVisible={setIsModalVisible}
                setStep={setHandleProcurementStep}
                settings={{
                    occupations,
                    specializations,
                    skills,
                }}
                step={handleProcurementStep}
                title='Kopiera uppdragsgivare'
                visible={isModalVisible}
                width={960}
                onCancel={setIsModalVisible}
            />
        </main>
    );
};

export default Procurements;
