import {
    filter,
    reduce,
    uniq,
    chain,
    find,
    first,
    last,
} from 'lodash';
import config from 'config';
import moment from 'moment';

/**
 *
 * The DataSet class takes an arbitrary number of shift objects and
 * performs various calculations on them providing aggregate data points
 *
 *
 */
export default class DataSet {
    constructor({
        shifts, jobs, consultants,
    }) {
        this.shifts = shifts;
        this.jobs = jobs;
        this.consultants = filter(consultants, c => c.occupation !== config.occupations.OCCUPATION_USK);
        this._cache = {};
        this._comparator = null;
    }

    /**
     *
     * Returns a cached result for a function call.
     * If no chached result exists, the callback function
     * will be called and the result will be cached for the
     * lifetime of the class instance.
     *
     */
    _getCachedResult(key, fn) {
        if (this._cache[key]) {
            return this._cache[key];
        }

        const res = fn.call(this);
        this._cache[key] = res;
        return this._cache[key];
    }

    /**
     *
     * Wraps a function callback and caches its result.
     * When called a second time with the same key,
     * _withCache will return a cached result of the
     * callback
     *
     */
    _withCache(key, fn) {
        if (this._comparator) {
            const val = this._getCachedResult(key, fn);
            const compareVal = fn.call(this._comparator);
            const change = val - compareVal;

            this._comparator = null;
            return (change / val) * 100;
        }

        return this._getCachedResult(key, fn);
    }

    /**
     *
     * Sets a temporary comparator class instance.
     * Send in another DataSet instance as the
     * comparator argument and call any of the calculator
     * methods on the returned instance to get a percentage
     * difference between the return value of that method
     * in the first and second set
     *
     */
    compare(comparator) {
        this._comparator = comparator;
        return this;
    }

    /**
    *
    * Returns the price of a single shift, excluding break
    *
    */
    getShiftPrice(shift) {
        return (shift.price * shift.duration) - (shift.price * shift.shift_break);
    }

    /**
    *
    * Returns the generated revenue for a single shift, compensating for the fact that
    * our margins changed on 2018-06-01
    *
    */
    getDateAdjustedShiftRevenue(shift) {
        const price = this.getShiftPrice(shift);
        const cutoff = moment('2018-06-01').startOf('day').unix();

        if (shift.start_time > cutoff) {
            return price * 0.17;
        }

        return price * 0.14;
    }

    /**
     *
     * Returns the total number of shifts in the set
     *
     */
    getTotalShifts() {
        return this._withCache('shifts', function () {
            return this.shifts.length;
        });
    }

    /**
     *
     * Returns the number of booked shifts in the set
     *
     */
    getBookedShifts() {
        return this._withCache('bookedShifts', function () {
            return filter(this.shifts, s => s.isBooked).length;
        });
    }

    /**
     *
     * Returns the value of all booked shifts that haven't
     * yet started
     *
     */
    getFutureTurnover() {
        return this._withCache('futureTurnover', function () {
            return chain(this.shifts)
                .filter(s => s.isBooked && s.start_time > moment().unix())
                .reduce((sum, s) => sum + this.getShiftPrice(s), 0)
                .value();
        });
    }

    /**
     *
     * Returns the booked shifts in the set as a percentage value
     * of the total
     *
     */
    getBookingPercentage() {
        return this._withCache('bookingPercentage', function () {
            return this.getBookedShifts() / this.shifts.length * 100;
        });
    }

    /**
     *
     * Returns the SEK value of all shifts in the set
     *
     */
    getValue() {
        return this._withCache('value', function () {
            return reduce(this.shifts, (val, s) => val + this.getShiftPrice(s), 0);
        });
    }

    /**
     *
     * Returns the SEK gross value of all booked shifts in the set
     *
     */
    getTurnover() {
        return this._withCache('turnover', function () {
            return chain(this.shifts)
                .filter(s => s.isBooked)
                .reduce((val, s) => val + this.getShiftPrice(s), 0)
                .value();
        });
    }

    /**
     *
     * Returns the SEK net value of all booked shifts in the set
     *
     */
    getRevenue() {
        return this._withCache('revenue', function () {
            const val = chain(this.shifts)
                .filter(s => s.isBooked)
                .reduce((sum, s) => sum + this.getDateAdjustedShiftRevenue(s), 0)
                .value();

            return val;
        });
    }

    /**
     *
     * Internal cacheable wrapper returns all shifts in the set
     * that haven't started yet
     *
     */
    _getAvailableShifts() {
        return this._withCache('_availableShifts', function () {
            return chain(this.shifts)
                .filter(s => moment.unix(s.start_time).isAfter(moment()) && !s.isBooked)
                .value();
        });
    }

    /**
     *
     * Returns the gross value of all shifts in the set that
     * haven't started yet
     *
     */
    getAvailableValue() {
        return this._withCache('available', function () {
            return this._getAvailableShifts().reduce((val, s) => val + this.getShiftPrice(s), 0);
        });
    }

    /**
     *
     * Returns the number of shifts in the set that haven't
     * started yet
     *
     */
    getAvailableShifts() {
        return this._withCache('availableShifts', function () {
            return this._getAvailableShifts().length;
        });
    }

    /**
     *
     * Returns the amount of missed revenue in the shift
     *
     */
    getMissedValue() {
        return this._withCache('missed', function () {
            return chain(this.shifts)
                .filter(s => moment.unix(s.end_time).isBefore(moment()) && !s.isBooked)
                .reduce((sum, s) => sum + this.getShiftPrice(s), 0)
                .value();
        });
    }

    /**
     *
     * Returns the number of shifts in the set that have
     * passed without being booked
     *
     */
    getMissedShifts() {
        return this._withCache('missed', function () {
            return chain(this.shifts)
                .filter(s => moment.unix(s.end_time).isBefore(moment()) && !s.isBooked)
                .value()
                .length;
        });
    }

    /**
     *
     * Returns a sorted array of start_time timestamps of all shifts in the set
     *
     */
    getShiftTimestamps() {
        return this._withCache('shiftTimestamps', () => {
            const timestamps = uniq(this.shifts.map(s => s.start_time)).sort();

            return timestamps;
        });
    }

    /**
     *
     * Returns the start_time of first shift chronologically in the set
     *
     */
    getFirstDateInPeriod() {
        return this._withCache('firstInPeriod', () => {
            const timestamps = this.getShiftTimestamps();
            return first(timestamps);
        });
    }

    /**
     *
     * Returns the start_time of first shift chronologically in the set
     *
     */
    getLastDateInPeriod() {
        const timestamps = this.getShiftTimestamps();
        return last(timestamps);
    }

    /**
     *
     * Returns an array of unique IDs for all consultants that
     * are booked on shifts in the set
     *
     */
    getUniqueConsultants() {
        return this._withCache('consultants', function () {
            const firstInPeriod = this.getFirstDateInPeriod();
            const lastInPeriod = this.getLastDateInPeriod();

            return uniq(reduce(this.jobs, (arr, job) => {
                if (job.bookings.length) {
                    const bookings = job.bookings.reduce((arr, booking) => {
                        const ids = [];

                        booking.shifts.forEach(shiftId => {
                            const shift = find(job.shifts, { id: shiftId });

                            if (shift && shift.start_time >= firstInPeriod && shift.start_time <= lastInPeriod) {
                                ids.push(booking.user.id);
                            }
                        });

                        return arr.concat(ids);
                    }, []);

                    return arr.concat(bookings);
                }

                return arr;
            }, []));
        });
    }

    /**
     *
     * Returns the number of unique active consultants in the period
     * as a percentage of the total number of consultants in the period
     *
     */
    getUniqueConsultantsPercentage() {
        return this._withCache('consultantPercentage', () => {
            const total = this.getTotalConsultantsAtPeriodEnd();
            const active = this.getUniqueConsultants();
            return (active.length / total * 100).toFixed(2);
        });
    }

    /*
    *
    * Returns the total number of registered consultants by the last date in the period
    *
    */
    getTotalConsultantsAtPeriodEnd() {
        return this._withCache('consultantsInPeriod', () => {
            const lastInPeriod = this.getLastDateInPeriod();
            return filter(this.consultants, c => c.created <= lastInPeriod).length;
        });
    }

    /**
     *
     * Returns an array of unique IDs for all employers
     * that have shifts in the set
     *
     */
    getUniqueEmployers() {
        return this._withCache('employers', function () {
            return uniq(this.shifts.map(shift => shift.employerId));
        });
    }

    /**
     *
     * Returns an array of unique IDs for all employers
     * that have booked shifts in the set
     *
     */
    getUniqueBookedEmployers() {
        return this._withCache('bookedEmployers', function () {
            const filteredShifts = filter(this.shifts, s => s.isBooked);
            return uniq(filteredShifts.map(shift => shift.employerId));
        });
    }

    getAverageTurnoverPerEmployer(days) {
        const cutoff = moment().subtract(days, 'days');

        const shifts = filter(this.shifts, s => s.isBooked && moment.unix(s.start_time).isAfter(cutoff) && moment.unix(s.start_time).isBefore(moment()));

        const employers = uniq(shifts.map(s => s.employerId));
        const turnover = shifts.reduce((sum, s) => sum + this.getShiftPrice(s), 0);

        return turnover / employers.length;

        return chain(this.shifts)
            .groupBy('employerId')
            .map(shifts => {
                const filtered = filter(shifts, s => s.isBooked, // && moment.unix(s.start_time).isAfter(moment('2017-11-30')) && moment.unix(s.start_time).isBefore(moment('2017-12-01'));
                    // return s.isBooked && moment.unix(s.start_time).isAfter(cutoff) && moment.unix(s.start_time).isBefore(moment());

                );

                return reduce(filtered, (sum, s) => sum + (this.getShiftPrice(s)), 0);
            })
            .mean()
            .value();
    }

    getActiveEmployersInPeriod(days) {

    }

    /**
     *
     * Returns detailed data on each employer in the set
     * id: The id of the employer
     * shifts: The number of shifts in the set belonging to the employer
     * bookedShifts: The number of booked shifts in the set belonging to the employer
     * missedShifts: The number of missed shifts in the set belonging to the employer
     * bookingPercentage: The booking percentage of the shifts in the set belonging to the employer
     * value: The gross value of all shifts in the set belonging to the employer
     * bookedValue: The gross value of all booked shifts in the set belonging to the employer
     *
     *
     */
    getShiftsPerEmployer() {
        return this._withCache('shiftsPerEmployer', function () {
            return this.getUniqueEmployers().map(employerId => {
                const employerShifts = filter(this.shifts, s => s.employerId === employerId);
                const bookedShifts = filter(employerShifts, s => s.isBooked);
                const missedShifts = filter(employerShifts, s => moment.unix(s.start_time).isBefore(moment()) && !s.isBooked);

                return {

                    id: employerId,
                    shifts: employerShifts.length,
                    bookedShifts: bookedShifts.length,
                    missedShifts: missedShifts.length,
                    bookingPercentage: bookedShifts.length / employerShifts.length * 100,
                    missedValue: reduce(missedShifts, (sum, s) => sum + this.getShiftPrice(s), 0),
                    value: reduce(employerShifts, (sum, s) => sum + this.getShiftPrice(s), 0),
                    bookedValue: reduce(bookedShifts, (sum, s) => sum + this.getShiftPrice(s), 0),

                };
            });
        });
    }

    /**
     *
     * Returns the total number of hours, booked and unbooked, on all shifts in the set
     *
     */
    getTotalHours() {
        return this._withCache('hours', function () {
            return reduce(this.shifts, (val, s) => val + s.duration, 0);
        });
    }

    /**
     *
     * Returns the total number of booked hours in the set
     *
     */
    getBookedHours() {
        return this._withCache('bookedHours', function () {
            return chain(this.shifts)
                .filter(s => s.isBooked)
                .reduce((val, s) => val + s.duration, 0)
                .value();
        });
    }

    /**
     *
     * Returns the number of booked hours divided by the number of
     * unique consultants in the set
     *
     */
    getHoursPerConsultant() {
        return this._withCache('hoursPerConsultant', function () {
            return (this.getBookedHours() / this.getUniqueConsultants().length);
        });
    }

    /**
     *
     * Returns the average value of the attribute specified by the
     * parameter key on all shifts in the set
     *
     */
    _getAverage(key) {
        return chain(this.shifts)
            .filter(s => !s.introduction && s[key] > 0)
            .map(s => s[key])
            .uniq()
            .mean()
            .value();
    }

    /**
     *
     * Returns the average price for all shifts in the set
     *
     * * */
    getAveragePrice() {
        return this._withCache('averagePrice', function () {
            return this._getAverage('price');
        });
    }

    /**
     *
     * Returns the average salary for all shifts in the set
     *
     */
    getAverageSalary() {
        return this._withCache('averageSalary', function () {
            return this._getAverage('salary');
        });
    }
}
