import * as request from 'request';

export function requestFetchBenefits() {
    return { type: 'REQUEST_FETCH_BENEFITS' };
}

export function receiveBenefits(resp) {
    return {
        type: 'RECEIVE_BENEFITS',
        payload: resp.items,
    };
}

export function fetchBenefits(args) {
    return dispatch => request.fetchBenefits(args)
        .then(resp => {
            dispatch(receiveBenefits(resp));
        });
}

export function sortBenefits(args) {
    return {
        type: 'SORT_BENEFITS',
        payload: args,
    };
}

export const createBenefit = benefit => request.addBenefit(benefit);

export const updateBenefit = benefit => request.updateBenefit(benefit);
