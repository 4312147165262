import React, {useState} from 'react';
import Avatar from 'components/shared/avatar';
import moment from 'moment';
import { Pagination, Button, DatePicker, Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const EmployerEvents = ({ data, onChangePage, onAddTask, onSelectedEmployerTasks, selectedEmployerTasks }) => {
  const [isTaskOpen, setIsTaskOpen] = useState({});
  const [tempInput, setTempInput] = useState();
  const [dueDate, setDueDate] = useState();
  const [plannedDate, setPlannedDate] = useState();
  const [hasError, setHasError] = useState(false);
  
  const onToggleTask = (id) => {
    setIsTaskOpen({ [id]: !isTaskOpen[id] })
    setTempInput('')
  }
  
  const onAddTaskHandler = (id) => {
    if (tempInput && dueDate && plannedDate) {
      const item = {
        title: tempInput,
        due_date_time: dueDate,
        planned_date_time: plannedDate,
      }
      onAddTask(item, { employer_id: id });
      setTempInput('');
      setHasError(false)
    } else {
      setHasError(true)
    }
  }
  const renderEmployers = () => (data.employers.map((employer) => {
    const { id, name, avatar } = employer
    const taskChecked = selectedEmployerTasks.some(({ employer_id }) => employer_id === id)
    return (
      <div key={id} className="flex bg-white flex-col my-2 p-2">
        <div className='flex items-center my-2 p-2 justify-between w-full'>
          <div className='flex items-center'>
            <Avatar
              size={30}
              url={avatar}
            />
            
            <div className='ml-2'>{name}</div>
          </div>
          <div>
          <Checkbox
            style={{ marginRight: '4px' }}
            onChange={e => { onSelectedEmployerTasks({ employer_id: id }); }}
            checked={taskChecked}
          />
          <Button onClick={() => onToggleTask(id)}>
            <i className="fa fa-list-ol" aria-hidden="true" />
          </Button>
          </div>
        </div>
        { isTaskOpen[id] && (<div className='flex flex-col bg-gray-100 border-b border-white'>
          <div className='py-2 font-semibold border-white border-t'>
            <div className='px-6'>Create a Task</div>
          </div>
          <div className='flex flex-col items-end px-6 pb-2'>
            <TextArea value={tempInput} allowClear onChange={(e) => setTempInput(e.target.value)}/>
            <div className='flex my-2'>
              <DatePicker placeholder="Select planned date" onChange={(d) => setPlannedDate(moment(d).unix())} />
              <DatePicker placeholder="Select due date" onChange={(d) => setDueDate(moment(d).unix())} />
              <Button className="ml-2" onClick={() => onAddTaskHandler(id)}>
                <i className="fa fa-plus mr-1" aria-hidden="true" />Add task
              </Button>
            </div>
          </div>
          { hasError && <div className='flex flex-col items-end px-6 pb-4 text-red-600'>Enter all fields</div> }
          </div>)
        }
      </div>
    )
  }));
  if(data.employers.length === 0) {
    return <div className='flex my-4 text-lg'>No clinics found.</div>
  }
  return (
    <div>      
      {renderEmployers()}
      <div className='flex items-center'>
        <Pagination current={data.page} onChange={onChangePage} total={data.totalPages} />
        <div className='ml-2 text-xs'>Number of clinics ({data.count})</div>
      </div>
    </div>
  );
};

export default EmployerEvents;
