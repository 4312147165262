import React from 'react';
import PropTypes from 'prop-types';

const InfoAvailable = ({ state }) => (
    <span
        className={state ? 'ion-checkmark-circled' : 'ion-close-circled'}
        style={{ color: state ? '#71e0aa' : '#d9534f' }}
    />
);

InfoAvailable.propTypes = { state: PropTypes.bool };

export default InfoAvailable;
