import React, {
    useCallback,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { CloseCircleFilled } from '@ant-design/icons';

import { Input } from 'antd';

const initialStyling = { padding: '12px 40px', 'font-size': '22px' }

const SearchBar = ({
    onChange,
    placeHolder,
    defaultValue,
    containerStyle = {},
    inputStyle=initialStyling
}) => {
    const inputRef = useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeHandler = debounce(() => onChange(inputRef.current.input.value), 500);
    return (
        <div className='search-bar' style={containerStyle}>
            <Input
                ref={inputRef}
                allowClear={{ clearIcon: <CloseCircleFilled className='search-bar__clear' /> }}
                className='search-bar__input'
                defaultValue={defaultValue || undefined}
                placeholder={placeHolder}
                onChange={changeHandler}
                style={inputStyle}
            />
        </div>
    );
};

SearchBar.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};

export default SearchBar;
