const initialState = {

    includeTestAccounts: false,
    counties: [],
    countryCode: 'SWE',
    marginConfigs: [],
    departmentTypes: [],
    employerMaintainingType: 'ALL',
    employerType: 'ALL',
    query: '',
    sort: null,

};

const employersFilter = (state = initialState, action) => {
    switch (action.type) {
        case 'EMPLOYERS_FILTER_TOGGLE_INCLUDE_TEST_ACCOUNTS':

            return {
                ...state,
                includeTestAccounts: !state.includeTestAccounts,
            };
        case 'EMPLOYERS_FILTER_TOGGLE_INCLUDE_TOP_CONSULTANTS':

            return {
                ...state,
                includeTopConsultants: !state.includeTopAccounts,
            };

        case 'EMPLOYERS_FILTER_SET_COUNTRIES':
            return {
                ...state,
                countryCode: action.countryCodes,
            };

        case 'EMPLOYERS_FILTER_SET_COUNTIES':

            return {
                ...state,
                counties: action.countyIds,
            };

        case 'EMPLOYERS_FILTER_SET_DEPARTMENT_TYPES':

            return {
                ...state,
                departmentTypes: action.departmentTypeIds,
            };

        case 'EMPLOYERS_FILTER_SET_QUERY':
            return {
                ...state,
                query: action.query,
            };

        case 'EMPLOYERS_FILTER_SET_MAINTAINING_TYPE':
            return {
                ...state,
                employerMaintainingType: action.employerMaintainingType,
            };

        case 'EMPLOYERS_FILTER_SET_TYPE':
            return {
                ...state,
                employerType: action.employerType,
            };

        case 'EMPLOYERS_FILTER_SET_MARGIN_CONFIGS':
            return {
                ...state,
                marginConfigs: action.marginConfigs,
            };

        case 'EMPLOYERS_FILTER_SET_SORT':
            return {
                ...state,
                sort: action.sort,
            };

        default:

            return state;
    }
};
export default employersFilter;
