import React, { useState } from 'react';
import {
    Switch,
    Select,
} from 'antd';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import './styles.scss';

const EmployerFilter = ({
    employersFilter,
    toggleTestAccounts,
    setMaintainingType,
    numAutomotive,
    numMaintained,
    setEmployerType,
    numPublic,
    numPrivate,
    setCounties,
    setCountries,
    counties,
    countries,
    marginConfigs,
    setDepartmentTypes,
    secondaryDepartmentTypes,
    setMarginConfigs,
}) => {
    const [countryCode, setCountryCode] = useState(employersFilter.countryCode);
    const filteredCounties = counties ? counties.filter(county => countryCode.includes(county.country_code)) : [];
    const filteredDepartmentTypes = secondaryDepartmentTypes ? secondaryDepartmentTypes.filter(type => countryCode.includes(type.country_code)) : [];
    const filterFunction = (input, option) => option.children.toLowerCase().includes(input.toLowerCase());

    return (
        <div
            className='mpa-employer-filter'
            style={{
                flex: 1,
                width: 300,
            }}
        >
            <div
                style={{ padding: 30 }}
            >
                <h3
                    style={{
                        paddingBottom: 30,
                        margin: 0,
                        marginBottom: 30,
                        borderBottom: '1px solid #EEE',
                    }}
                >
                    Filter
                </h3>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Switch
                        checked={employersFilter.includeTestAccounts}
                        style={{ marginRight: 5 }}
                        onChange={toggleTestAccounts}
                    />
                    Include test accounts
                </div>

                <div
                    style={{ marginTop: 20 }}
                >
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        Maintaining type
                    </div>
                    <div>
                        <label htmlFor='all-maintaining-type'>
                            <input
                                checked={employersFilter.employerMaintainingType === 'ALL'}
                                id='all-maintaining-type'
                                name='all'
                                type='radio'
                                onChange={() => {
                                    setMaintainingType('ALL');
                                }}
                            />
                            All
                            {(numAutomotive + numMaintained) > 0 ? ` (${numAutomotive + numMaintained})` : ''}
                        </label>
                    </div>
                    <div>
                        <label htmlFor='automative-maintaining-type'>
                            <input
                                checked={employersFilter.employerMaintainingType === 'AUTOMOTIVE'}
                                id='automotive-maintaining-type'
                                name='automotive'
                                type='radio'
                                onChange={() => {
                                    setMaintainingType('AUTOMOTIVE');
                                }}
                            />
                            Automotive
                            {numAutomotive ? ` (${numAutomotive})` : ''}
                        </label>
                    </div>
                    <div>
                        <label htmlFor='maintained-maintaining-type'>
                            <input
                                checked={employersFilter.employerMaintainingType === 'MAINTAINED'}
                                id='maintained-maintaining-type'
                                name='maintained'
                                style={{ marginRight: '3px' }}
                                type='radio'
                                onChange={() => {
                                    setMaintainingType('MAINTAINED');
                                }}
                            />
                            Maintained
                            {numMaintained ? ` (${numMaintained})` : ''}
                        </label>
                    </div>
                </div>

                <div
                    style={{ marginTop: 20 }}
                >
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        Employer type
                    </div>
                    <div>
                        <label htmlFor='all-employer-type'>
                            <input
                                checked={employersFilter.employerType === 'ALL'}
                                id='all-employer-type'
                                name='all-employer-type'
                                type='radio'
                                onChange={() => {
                                    setEmployerType('ALL');
                                }}
                            />
                            All
                            {(numPublic + numPrivate) > 0 ? (
                                ` (${numPublic + numPrivate})`
                            ) : ''}
                        </label>
                    </div>
                    <div>
                        <label htmlFor='public-employer-type'>
                            <input
                                checked={employersFilter.employerType === 'PUBLIC'}
                                id='public-employer-type'
                                name='public'
                                type='radio'
                                onChange={() => {
                                    setEmployerType('PUBLIC');
                                }}
                            />
                            Public
                            {numPublic ? ` (${numPublic}) ` : ''}

                        </label>
                    </div>
                    <div>
                        <label htmlFor='private-employer-type'>
                            <input
                                checked={employersFilter.employerType === 'PRIVATE'}
                                id='private-employer-type'
                                name='private'
                                type='radio'
                                onChange={() => {
                                    setEmployerType('PRIVATE');
                                }}
                            />
                            Private
                            {numPrivate ? ` (${numPrivate})` : ''}
                        </label>
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <p style={{ marginBottom: 5 }}>
                        Country
                    </p>
                    <Select
                        allowClear
                        defaultValue={countries.find(country => country.value === employersFilter.countryCode).value}
                        filterOption={filterFunction}
                        size='large'
                        style={{ width: '100%' }}
                        onClear={() => {
                            setCountries(countries.map(country => country.value));
                            setCountryCode(countries.map(country => country.value));
                        }}
                        onSelect={val => {
                            setCountries(val);
                            setCountryCode(val);
                        }}
                    >
                        {countries.map(country => (
                            <Select.Option key={country.id} value={country.value}>
                                {country.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div style={{ marginTop: 20 }}>
                    <p style={{ marginBottom: 5 }}>
                        Counties
                    </p>
                    <Select
                        filterOption={filterFunction}
                        mode='multiple'
                        placeholder='Add county'
                        size='large'
                        style={{ width: '100%' }}
                        onChange={val => {
                            setCounties(val);
                        }}
                    >
                        {filteredCounties.map(county => (
                            <Select.Option key={county.id} value={county.id}>
                                {county.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div style={{ marginTop: 20 }}>
                    <p style={{ marginBottom: 5 }}>
                        Department types
                    </p>
                    <Select
                        filterOption={filterFunction}
                        mode='multiple'
                        placeholder='Add department type'
                        size='large'
                        style={{ width: '100%' }}
                        onChange={val => {
                            setDepartmentTypes(val);
                        }}
                    >
                        {sortBy(filteredDepartmentTypes, 'name').map(d => (
                            <Select.Option key={d.id} value={d.id}>
                                {d.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                {marginConfigs
                    ? (
                        <div style={{ marginTop: 20 }}>
                            <p style={{ marginBottom: 5 }}>
                                Margin configurations
                            </p>
                            <Select
                                filterOption={filterFunction}
                                mode='multiple'
                                placeholder='Add margin configuration'
                                size='large'
                                style={{ width: '100%' }}
                                onChange={val => {
                                    setMarginConfigs(val);
                                }}
                            >
                                {marginConfigs.map(d => (
                                    <Select.Option key={d.id} value={d.id}>
                                        {d.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    )
                    : '' }

            </div>

        </div>
    );
};

EmployerFilter.propTypes = {
    employersFilter: PropTypes.shape({
        includeTestAccounts: PropTypes.bool,
        employerMaintainingType: PropTypes.string,
        employerType: PropTypes.string,
        countryCode: PropTypes.string,
        counties: PropTypes.arrayOf(PropTypes.oneOfType(
            [
                PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                }),
                PropTypes.number,
            ],
        )),
        departmentTypes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        marginConfigs: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }),
    toggleTestAccounts: PropTypes.func,
    setMaintainingType: PropTypes.func,
    numAutomotive: PropTypes.number,
    numMaintained: PropTypes.number,
    setEmployerType: PropTypes.func,
    numPublic: PropTypes.number,
    numPrivate: PropTypes.number,
    setCounties: PropTypes.func,
    setCountries: PropTypes.func,
    counties: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    countries: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        name: PropTypes.string,
    })),
    marginConfigs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    setDepartmentTypes: PropTypes.func,
    secondaryDepartmentTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    setMarginConfigs: PropTypes.func,
};

export default EmployerFilter;
