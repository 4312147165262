import React from 'react';
import PropTypes from 'prop-types';

const CopyButton = ({ onClick }) => (
    <a
        href='#'
        style={{
            display: 'block',
            marginTop: 5,
            fontSize: 10,
        }}
        onClick={e => {
            e.preventDefault();
            onClick();
        }}
    >
        <i className='fa fa-copy' />
        {' '}
        Copy to all
    </a>
);

CopyButton.propTypes = { onClick: PropTypes.func.isRequired };
export default CopyButton;
