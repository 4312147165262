import {
    createStore,
    applyMiddleware,
    compose,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();

const middleware = [
    thunkMiddleware,
    routerMiddleware(history), // for dispatching history actions
];

if (process.env.NODE_ENV !== 'production') {
    const { createLogger } = require('redux-logger');
    middleware.push(
        createLogger({
            // More options are available, see https://github.com/LogRocket/redux-logger
            collapsed: true, // the log group should be collapsed
            colors: { title: () => { '#666'; } },
            diff: true, // show diff between states
        }),
    );
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        initialState,
        composeEnhancers(applyMiddleware(...middleware)),
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers/index').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
