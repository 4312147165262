const initialState = {
    isFetching: true,
    adminUsers: [],
};

export function super_admin(state = initialState, action) {
    switch (action.type) {
        // create admin users
        case 'REQUEST_CREATE_ADMIN_USERS':
            return {
                ...state,
                isFetching: true,
            };
        // fetch admin users
        case 'REQUEST_FETCH_ADMIN_USERS':
            return {
                ...state,
                isFetching: true,
            };
        // update admin users
        case 'REQUEST_UPDATE_ADMIN_USERS':
            return {
                ...state,
                isFetching: true,
            };
        // delete admin user (soft delete)
        case 'REQUEST_DELETE_ADMIN_USER':
            return {
                ...state,
                isFetching: true,
            };
        // receive admin user after update
        case 'RECEIVE_UPDATE_ADMIN_USER':
            return {
                ...state,
                isFetching: false,
                adminUsers: state.adminUsers.map(
                    item => (action.adminUser.id === item.id ? action.adminUser : item),
                ),
            };
        // receive admin users
        case 'RECEIVE_FETCH_ADMIN_USERS':
            return {
                ...state,
                isFetching: false,
                adminUsers: action.adminUsers,
            };
        // delete admin users (soft delete)
        case 'RECEIVE_DELETE_ADMIN_USER':
            return {
                ...state,
                isFetching: false,
                adminUsers: state.adminUsers.map(
                    item => (action.adminUser.id === item.id ? action.adminUser : item),
                ),
            };
        default:
            return state;
    }
}
