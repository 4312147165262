/* eslint-disable max-len */
import moment from 'moment';
import {
    occupationTitle,
    specializationTitle,
} from 'utils/nameformatters';

export default function consultantActivity(activity) {
    const content = activity.meta_data;
    const employerName = content.employer ? content.employer.name : 'namn saknas';

    const occupationName = jobPosting => {
        const {
            occupation_id: id,
            occupation,
        } = jobPosting;

        if (id) {
            return occupationTitle(id);
        }

        return occupation.name || '';
    };

    const specializationName = ids => (ids && ids.length ? specializationTitle(ids[0]) : '');
    const formatDate = date => moment.unix(date).format('YYYY-MM-DD');

    const durationText = () => {
        const duration = moment.unix(content.end_time).diff(moment.unix(content.start_time), 'days') || 1;
        return `${duration} ${duration === 1 ? 'dag' : 'dagar'}`;
    };

    switch (content.type) {
        case 'sick_leave':
            return {
                title: 'Sjukskrivning',
                description: `(${durationText()}) ${formatDate(content.start_time)} - ${formatDate(content.end_time)}`,
            };

        case 'sick_leave_update':
            return {
                title: 'Sjukskrivning ändrades till: ',
                description: `(${durationText()}) ${formatDate(content.start_time)} - ${formatDate(content.end_time)}`,
            };

        case 'booking_confirmed':
            return {
                title: `Blev bokad av ${employerName}`,
                description: `(${content.shift_count} pass) ${formatDate(content.start_time)} - ${formatDate(content.end_time)}`,
            };

        case 'application':
            return {
                title: `Ansökan till ${employerName}`,
                description: `(${content.shift_count} pass) ${formatDate(content.start_time)} - ${formatDate(activity.meta_data.end_time)}`,
            };

        case 'job_ad_application':

            return {
                title: `MedHire ansökan till ${employerName}`,
                description: `${occupationName(content.job_posting)} ${specializationName(content.job_posting.specialization_ids)}`,
            };

        default:
            return {
                title: activity.meta_data.type,
                description: 'Beskrivning saknas',
            };
    }
}
