import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    useParams,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from 'config';
import moment from 'moment';
import {
    fetchConsultant,
    fetchConsultantBookings,
    fetchConsultantSSN,
    updateConsultant,
} from 'actions/consultant';
import TabBar from 'components/shared/tabbar';
import { occupationTitle } from 'utils/nameformatters';
import LoadingScreen from 'components/shared/loadingscreen';
import Avatar from 'components/shared/avatar';
import TagsList from 'components/tagslist';
import StateBadge from 'components/shared/statebadge';
import Comments from 'components/comments';
import LastActive from 'components/lastactive';
import { Button } from 'antd';
import InputModal from 'components/inputmodal';
import EditTrigger from 'components/shared/edittrigger';
import modalController from 'utils/modalcontroller';
import getSickLeaveColor from 'utils/sickLeaveColor';

import EmailVerifSateBadge from 'components/shared/emailverifstatebadge';
import UserBannedBaged from 'components/shared/userbannedbadge';
import './styles.scss';

const Consultant = ({
    consultant,
    children,
    ssn,
    isFetching,
    isFetchingSSN,
    isFetchingApplications,
    fetchConsultant,
    fetchConsultantBookings,
    fetchConsultantSSN,
    updateConsultant,
}) => {
    const { id } = useParams();
    useEffect(() => {
        fetchConsultant(id);
    }, [fetchConsultant, id]);

    useEffect(() => {
        fetchConsultantBookings(id);
    }, [fetchConsultantBookings, id]);

    const renderConsultantState = () => {
        const {
            state, created,
        } = consultant;

        return (
            <StateBadge
                created={created}
                state={state}
                styles={{ fontSize: 14 }}
            />
        );
    };

    const renderConsultantContact = () => {
        const {
            email, phone,
        } = consultant;
        return (
            <div className='consultant-contact'>
                <div>{email}</div>
                <div>{phone}</div>
            </div>
        );
    };

    const renderMainContent = () => {
        const tabsConfig = {
            urlPrefix: `/consultants/${consultant.id}`,
            tabs: [
                {
                    id: 1,
                    title: 'Overview',
                    urlSegment: '',
                },
                {
                    id: 2,
                    title: 'CV',
                    urlSegment: '/cv',
                },
                {
                    id: 3,
                    title: 'Applications',
                    urlSegment: '/applications',
                },
                {
                    id: 4,
                    title: 'Bookings',
                    urlSegment: '/bookings',
                },
                {
                    id: 5,
                    title: 'Time reports',
                    urlSegment: '/time-reports',
                },
                {
                    id: 6,
                    title: 'Salaries',
                    urlSegment: '/salaries',
                },
                {
                    id: 7,
                    title: 'Contracts',
                    urlSegment: '/contracts',
                },
                {
                    id: 8,
                    title: 'HOSP',
                    urlSegment: '/hosp',
                },
                {
                    id: 9,
                    title: 'Sickleave',
                    urlSegment: '/sickleave',
                },
                {
                    id: 10,
                    title: 'Employers',
                    urlSegment: '/employers',
                },
                {
                    id: 11,
                    title: 'Uploads',
                    urlSegment: '/uploads',
                },
                {
                    id: 12,
                    title: 'Wage tax',
                    urlSegment: '/wagetax',
                    disabled: consultant.country_code !== 'NLD',
                },
            ],

        };

        if (isFetching || isFetchingApplications) {
            return (
                <LoadingScreen
                    fullScreen
                    text={'Anyone named "Nilsson" is a hipster'}
                />
            );
        }

        return (
            <div key={consultant.id} className='mp-consultant' style={{ position: 'relative' }}>
                <div
                    className='consultant-header'
                    style={{
                        background: '#fff',
                        borderBottom: '1px solid #eee',
                        padding: '40px 80px',
                        paddingTop: 80,
                        position: 'relative',
                    }}
                >
                    <div
                        className='hide-in-print'
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                    >
                        <div style={{ display: 'inline-flex' }}>
                            <a
                                className='mpa-intercom-link'
                                href={`http://app.intercom.com/apps/${config.intercomAppId}/users/show?email=${consultant.email}`}
                                rel='noreferrer'
                                target='_blank'
                            >
                                <div className='mpa-intercom-logo' />
                                Open in Intercom
                                {' '}
                                <span className='ion-android-open' />
                            </a>
                        </div>
                        <div
                            style={{
                                display: 'inline-flex', marginLeft: 20,
                            }}
                        >
                            <a
                                className='mpa-linkedin-link'
                                href={`https://www.linkedin.com/search/results/all/?keywords=${consultant.firstname}%20${consultant.lastname}`}
                                rel='noreferrer'
                                target='_blank'
                            >
                                <i className='ion ion-social-linkedin mpa-linkedin-logo' />
                                Search
                                {' '}
                                {consultant.firstname}
                                {' '}
                                on LinkedIn
                                {' '}
                                <span className='ion-android-open' />
                            </a>
                        </div>
                        <div
                            style={{
                                display: 'inline-flex', marginLeft: 20,
                            }}
                        >
                            <a
                                className='mpa-intercom-link'
                                href={`https://www.google.se/search?q=${consultant.firstname}+${consultant.lastname}`}
                                rel='noreferrer'
                                target='_blank'
                            >
                                <div className='mpa-google-logo' />
                                Search
                                {' '}
                                {consultant.firstname}
                                {' '}
                                on Google
                                {' '}
                                <span className='ion-android-open' />
                            </a>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{ marginRight: 20 }}
                        >
                            <Avatar
                                gender={consultant.gender}
                                size={200}
                                style={{
                                    border: '5px solid #FFF',
                                    boxShadow: '0 1px 2px rgba(0,0,0,0.3)',
                                }}
                                url={consultant.avatar}
                            />
                        </div>
                        <div>
                            <h2
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    {consultant.firstname}
                                    {' '}
                                    {consultant.lastname}
                                    {' '}
                                    <EditTrigger
                                        className='hide-in-print'
                                        style={{ display: 'inline' }}
                                        title=''
                                        onClick={() => {
                                            modalController.showModal(InputModal, {
                                                title: 'Edit consultant',
                                                width: 600,
                                                preventOutsideClickClose: true,
                                                props: {
                                                    inputs: [{
                                                        type: 'input',
                                                        name: 'firstname',
                                                        label: 'Firstname',
                                                        value: consultant.firstname,
                                                    }, {
                                                        type: 'input',
                                                        name: 'lastname',
                                                        label: 'Lastname',
                                                        value: consultant.lastname,
                                                    }, {
                                                        type: 'input',
                                                        name: 'phone',
                                                        label: 'Phone',
                                                        value: consultant.phone,
                                                    }, {
                                                        type: 'input',
                                                        name: 'hsaid',
                                                        label: 'HSAID',
                                                        value: consultant.hsaid,
                                                    }, {
                                                        type: 'textarea',
                                                        name: 'description',
                                                        label: 'Description',
                                                        value: consultant.description,
                                                    }, {
                                                        type: 'switch',
                                                        name: 'driver_license',
                                                        label: 'Driver\'s license',
                                                        optionLabels: {
                                                            on: 'Yes',
                                                            off: 'No',
                                                        },
                                                        value: consultant.driver_license,
                                                    }],
                                                    onSave: formData => updateConsultant(id, formData),
                                                },
                                            });
                                        }}
                                    />
                                    {(!consultant.firstname && !consultant.lastname) && '-'}
                                    { consultant.sick_leave_percent >= 10 && <div className='mt-2 text-xs'>Sick leave: <span className={`p-1 rounded ${getSickLeaveColor(consultant.sick_leave_percent)}`}>{consultant.sick_leave_percent}%</span></div>}
                                    { consultant.childcare_by_percent >= 10 && <div className='mt-2 text-xs'>Childcare: <span className={`p-1 rounded ${getSickLeaveColor(consultant.childcare_by_percent)}`}>{consultant.childcare_by_percent}%</span></div>}
                                </div>
                                <div
                                    className='hide-in-print'
                                    style={{ marginLeft: 10 }}
                                >
                                    {renderConsultantState()}
                                </div>
                                <div
                                    className='hide-in-print'
                                    style={{ marginLeft: 5 }}
                                >
                                    <EmailVerifSateBadge styles={{ fontSize: 14 }} verified={consultant.email_verified} />
                                </div>
                                {consultant.banned &&  <div
                                    className='hide-in-print'
                                    style={{ marginLeft: 5 }}
                                ><UserBannedBaged banned={consultant.banned} /></div>}
                                <div
                                    className='hide-in-print'
                                    style={{ marginLeft: 5 }}
                                >
                                    <LastActive
                                        lastActive={consultant.last_active}
                                    />
                                </div>

                            </h2>
                            <div className='hide-in-print' style={{ marginTop: 3 }}>
                                {occupationTitle(consultant.occupation)}
                                <span
                                    style={{
                                        color: '#999',
                                        fontSize: 16,
                                        marginLeft: 10,
                                    }}
                                >
                                    (#
                                    {consultant.id}
                                    )
                                </span>
                                {consultant.student && <span className='consultant__occupation--student'>(Student graduation date {moment.unix(consultant.graduation_time).format('YYYY-MM-DD')})</span>}
                            </div>
                            <div
                                style={{
                                    color: '#999',
                                    marginTop: 5,
                                }}
                            >
                                {renderConsultantContact()}
                            </div>
                            <div
                                style={{
                                    color: '#999',
                                    marginTop: 5,
                                }}
                            >
                                {ssn || (
                                    <Button
                                        className='hide-in-print'
                                        disabled={isFetchingSSN}
                                        onClick={() => fetchConsultantSSN(id)}
                                    >
                                        Show SSN
                                    </Button>
                                )}
                            </div>
                            <div className='hide-in-print'>
                                <TagsList
                                    consultantId={consultant.id}
                                    tagsList={consultant.tags}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className='hide-in-print'
                    style={{
                        position: 'sticky',
                        top: 0,
                        background: '#FFF',
                        zIndex: 20,
                    }}
                >
                    <TabBar tabsConfig={tabsConfig} />
                </div>
                <div className='mpa-update-employer row'>
                    <div className='col-md-12'>
                        <div
                            className='consultant-content'
                            style={{ padding: '40px 80px 40px' }}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderComments = () => (
        <div
            className='hide-in-print'
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                width: 400,
                background: '#FFF',
                borderLeft: '1px solid #EEE',
                overflow: 'auto',
            }}
        >
            <Comments
                entityId={id}
                type='consultant'
            />
        </div>
    );
    return (
        <main className='consultant-container'>
            {renderMainContent()}
            {renderComments()}
        </main>
    );
};

Consultant.propTypes = {
    consultant: PropTypes.shape({
        id: PropTypes.number,
        created: PropTypes.number,
        last_active: PropTypes.number,
        state: PropTypes.number,
        salary_type: PropTypes.number,
        gender: PropTypes.number,
        occupation: PropTypes.number,
        avatar: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        description: PropTypes.string,
        hsaid: PropTypes.string,
        driver_license: PropTypes.bool,
        tags: PropTypes.arrayOf(PropTypes.number),
        email_verified: PropTypes.bool,
    }),
    ssn: PropTypes.number,
    children: PropTypes.shape({}),
    isFetching: PropTypes.bool.isRequired,
    isFetchingSSN: PropTypes.bool.isRequired,
    isFetchingApplications: PropTypes.bool.isRequired,
    fetchConsultant: PropTypes.func.isRequired,
    fetchConsultantBookings: PropTypes.func.isRequired,
    fetchConsultantSSN: PropTypes.func.isRequired,
    updateConsultant: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    ssn: state.consultant.ssn,
    isFetching: state.consultant.isFetching,
    isFetchingSSN: state.consultant.isFetchingSSN,
    isFetchingApplications: state.consultant.isFetchingApplications,
});
export default withRouter(connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        fetchConsultant,
        fetchConsultantBookings,
        fetchConsultantSSN,
        updateConsultant,
    }, dispatch),
)(Consultant));
