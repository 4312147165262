import React from 'react';
import moment from 'moment';
import {
    filter,
    groupBy,
    map,
    sortBy,
    sumBy,
    chain,
    times,
    memoize,
} from 'lodash';
import {
    Select,
    Tooltip,
} from 'antd';
import classNames from 'utils/classnames';
import Table from 'components/shared/table';
import GOALS from '../goals';
import ChangeTicker from '../changeticker';
import DataSet from '../dataset';

const getShiftsByMonth = memoize((shifts, origShifts, jobs, consultants, month, groupBy) => {
    const dataSet = new DataSet({
        shifts, jobs, consultants,
    });
    const goal = GOALS[moment(month).format('YYYY-MM')];
    const goalPercentage = (dataSet.getTurnover() / goal) * 100;
    const goalDiff = dataSet.getValue() - goal;
    const goalRemainder = goal - dataSet.getTurnover();
    const endOfPeriod = moment(month).endOf(groupBy).unix();
    const startOfPeriod = moment(month).startOf(groupBy).unix();

    return {
        month,
        shifts: dataSet.getTotalShifts(),
        booked: dataSet.getBookedShifts(),
        value: dataSet.getValue(),
        turnover: dataSet.getTurnover(),
        revenue: dataSet.getRevenue(),
        missed: dataSet.getMissedValue(),
        availableShifts: dataSet.getAvailableShifts(),
        available: dataSet.getAvailableValue(),
        bookingPercentage: dataSet.getBookingPercentage(),
        employers: dataSet.getUniqueEmployers().length,
        registeredConsultants: dataSet.getTotalConsultantsAtPeriodEnd(),
        consultants: dataSet.getUniqueConsultants().length,
        consultantsPercentage: dataSet.getUniqueConsultantsPercentage(),
        hours: dataSet.getTotalHours(),
        hoursPerConsultant: dataSet.getHoursPerConsultant(),
        bookedHours: dataSet.getBookedHours(),
        averageSalary: dataSet.getAverageSalary(),
        averagePrice: dataSet.getAveragePrice(),
        goal,
        goalPercentage,
        goalRemainder,
        goalDiff,
        published: origShifts.filter(s => s.published_time > startOfPeriod && s.published_time < endOfPeriod).length,
        publishedValue: chain(origShifts)
            .filter(s => s.published_time > startOfPeriod && s.published_time < endOfPeriod)
            .sumBy('price')
            .value(),
    };
}, (...args) => args[4] + args[5]);

const getTotals = memoize((props, state, date, mainOccupationFilter) => {
    const startCutoff = moment(date).startOf('year').unix();
    const endCutoff = moment(date).endOf('year').unix();

    let origShifts = filter(props.shifts, s => s.start_time > startCutoff && s.start_time < endCutoff);

    let origJobs = props.jobs;

    if (state.mainOccupationFilter !== 0) {
        origShifts = filter(origShifts, s => s.occupation === state.mainOccupationFilter);
        origJobs = filter(origJobs, j => j.occupation_id === state.mainOccupationFilter);
    }

    const dataSet = new DataSet({
        shifts: origShifts, jobs: origJobs, consultants: props.consultants,
    });

    return {

        shifts: dataSet.getTotalShifts(),
        booked: dataSet.getBookedShifts(),
        value: dataSet.getValue(),
        turnover: dataSet.getTurnover(),
        bookingPercentage: dataSet.getBookingPercentage(),
        availableShifts: dataSet.getAvailableShifts(),
        available: dataSet.getAvailableValue(),
        missed: dataSet.getMissedValue(),
        revenue: dataSet.getRevenue(),
        registeredConsultants: dataSet.getTotalConsultantsAtPeriodEnd(),
        consultants: dataSet.getUniqueConsultants().length,
        consultantsPercentage: dataSet.getUniqueConsultantsPercentage(),
        employers: dataSet.getUniqueEmployers().length,
        hours: dataSet.getTotalHours(),
        bookedHours: dataSet.getBookedHours(),
        hoursPerConsultant: dataSet.getHoursPerConsultant(),
        price: dataSet.getAveragePrice(),
        salary: dataSet.getAverageSalary(),

    };
}, (...args) => args[2] + args[3]);

const getShiftsGroupedByMonth = memoize((shifts, stateGroupBy) => groupBy(shifts, shift => moment.unix(shift.start_time).startOf('day').startOf(stateGroupBy).format('YYYY-MM-DD')), (...args) => args[1]);

const getMonthlyData = memoize((props, state, stateGroupBy, mainOccupationFilter, stateRange) => {
    let { shifts } = props;
    let origShifts = shifts;

    if (state.mainOccupationFilter) {
        shifts = filter(shifts, s => s.occupation === state.mainOccupationFilter);
        origShifts = shifts;
    }

    const shiftsByMonth = {};

    const { range } = state;

    const startDate = moment().startOf('day').startOf(state.groupBy).subtract(3, state.groupBy);

    if (range < 0) {
        startDate.subtract(Math.abs(range), state.groupBy);
    }
    else if (range > 0) {
        startDate.add(Math.abs(range), state.groupBy);
    }

    times(8, () => {
        const monthDate = startDate.format('YYYY-MM-DD');
        if (!shiftsByMonth[monthDate]) {
            shiftsByMonth[monthDate] = [];
        }
        startDate.add(1, state.groupBy);
    });

    const shiftsGroupedByMonth = getShiftsGroupedByMonth(shifts, state.groupBy);

    map(shiftsByMonth, (s, key) => {
        shiftsByMonth[key] = shiftsGroupedByMonth[key] || [];
    });

    const mapp = map(shiftsByMonth, (shifts, month) => getShiftsByMonth(shifts, origShifts, props.jobs, props.consultants, month, state.groupBy));

    return mapp;
}, (...args) => args[2] + args[3] + args[4]);

export default class DashboardHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            mainOccupationFilter: 0,
            groupBy: 'month',
            range: 0,
            totalsYear: '2019-01-01',
        };
    }

    decreaseRange() {
        this.setState({ range: this.state.range - 1 });
    }

    increaseRange() {
        this.setState({ range: this.state.range + 1 });
    }

    getTotals() {
        return getTotals(this.props, this.state, this.state.totalsYear);
    }

    getMonthlyData() {
        return getMonthlyData(this.props, this.state, this.state.groupBy, this.state.mainOccupationFilter, this.state.range);
    }

    renderChangeTicker(value) {
        return (
            <ChangeTicker
                value={value}
            />
        );
    }

    renderTotalsTable(legend, months) {
        const totals = this.getTotals();

        const totalShifts = sumBy(months, 'shifts');
        const totalBookings = sumBy(months, 'booked');

        let values = [
            totals.shifts,
            totals.booked,
            `${totals.value.toLocaleString('sv-se')}kr`,
            `${totals.turnover.toLocaleString('sv-se')}kr`,
            `${totals.bookingPercentage.toLocaleString('sv-se')}%`,
            totals.availableShifts,
            `${totals.available.toLocaleString('sv-se')}kr`,
            `${totals.missed.toLocaleString('sv-se')}kr`,
            `${totals.revenue.toLocaleString('sv-se')}kr`,
            '-',
            '-',
            '-', // Goal
            '-', // Goal remainder
            '-', // goal percentage
            '-', // Goal diff
            totals.registeredConsultants,
            totals.consultants,
            `${totals.consultantsPercentage}%`,
            totals.employers,
            totals.hours.toLocaleString('sv-se'),
            totals.bookedHours.toLocaleString('sv-se'),
            totals.hoursPerConsultant.toLocaleString('sv-se'),
            `${totals.price.toLocaleString('sv-se')}kr`,
            `${totals.salary.toLocaleString('sv-se')}kr`,
        ];

        if (this.state.groupBy === 'week') {
            values = values.slice(0, 11).concat(values.slice(14));
        }

        return (
            <div>
                <div
                    style={{
                        padding: '0 10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: 60,
                    }}
                >
                    <div>
                        TOTALS
                    </div>
                    <div>
                        <Select
                            size='small'
                            style={{ width: 100 }}
                            value={this.state.totalsYear}
                            onChange={val => {
                                this.setState({ totalsYear: val });
                            }}
                        >
                            {[0, 1, 2, 3].map(num => {
                                const m = moment(new Date('2017-01-01')).startOf('year').add(num, 'years');

                                return (

                                    <Select.Option
                                        value={m.format('YYYY-MM-DD')}
                                    >
                                        {m.format('YYYY')}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <Table
                    renderRow={(row, i) => (
                        <tr>
                            <td
                                style={{
                                    color: '#FFF',
                                    borderBottomColor: 'rgba(255, 255, 255, 0.05)',
                                    padding: 20,
                                    fontSize: 14,
                                    fontWeight: '600',
                                }}
                            >
                                {row}
                            </td>
                        </tr>
                    )}
                    rows={values}
                    style={{
                        boxShadow: 'none', background: 'none', padding: 0,
                    }}
                />
            </div>
        );
    }

    compareToPreviousMonth(index, key, val, months) {
        if (index === 0) {
            return '';
        }

        const prevVal = months[index - 1][key];
        const change = val - prevVal;
        return (change / prevVal) * 100;
    }

    renderTableSummary() {
        const months = sortBy(this.getMonthlyData(), 'month');

        const headers = months.map(month => (this.state.groupBy === 'month' ? moment(month.month).format('MMM YY').toUpperCase() : moment(month.month).format('v.w YYYY').toUpperCase()));

        const legend = [{
            label: 'Tillängliga pass',
            key: 'shifts',
            format: true,
            explanation: 'Antalet bokningsbara pass under perioden',
        }, {
            label: 'Bokade pass',
            key: 'booked',
            format: true,
            explanation: 'Antalet bokade pass under perioden',
        }, {
            label: 'Värde',
            key: 'value',
            postfix: 'kr',
            format: true,
            explanation: 'Totalpriset för alla bokningsbara pass under perioden',
        }, {
            label: 'Bokningsvärde',
            key: 'turnover',
            postfix: 'kr',
            format: true,
            explanation: 'Totalpriset för alla bokade pass under perioden',
        }, {
            label: 'Bokningsgrad',
            key: 'bookingPercentage',
            postfix: '%',
            format: true,
            explanation: 'Andel bokade pass',
        }, {
            label: 'Tillgängliga pass',
            key: 'availableShifts',
            hideMeta: true,
            explanation: 'Antal pass under perioden som fortfarande är bokningsbara',
        }, {
            label: 'Tillgängligt värde',
            key: 'available',
            postfix: 'kr',
            format: true,
            hideMeta: true,
            explanation: 'Totalpriset för alla pass som finns kvar att boka under perioden',
        }, {
            label: 'Missat',
            key: 'missed',
            postfix: 'kr',
            format: true,
            hideMeta: true,
            explanation: 'Totalpriset för alla pass som inte blev bokade under perioden',
        }, {
            label: 'Intäkt',
            key: 'revenue',
            postfix: 'kr',
            format: true,
            explanation: 'Den totala intäkten för perioden baserat på 14% marginal',
        }, {
            label: 'Nya publiceringar',
            key: 'published',
            explanation: 'Hur många nya pass som publicerades under perioden',
        }, {
            label: 'Nypublicerat värde',
            key: 'publishedValue',
            format: true,
            postfix: 'kr',
            explanation: 'Värdet för samtliga pass som publicerats under perioden',
        }, {
            label: 'Mål',
            key: 'goal',
            postfix: 'kr',
            format: true,
            hideMeta: true,
            hideInWeekMode: true,
            explanation: 'Periodens försäljningsmål',
        }, {
            label: 'Procent av mål',
            key: 'goalPercentage',
            postfix: '%',
            hideMeta: true,
            format: true,
            hideInWeekMode: true,
            explanation: 'Andel av periodens försäljningsmål som uppnåtts',
        }, {
            label: 'Saknas för mål',
            key: 'goalRemainder',
            postfix: 'kr',
            format: true,
            hideMeta: true,
            hideInWeekMode: true,
            explanation: 'Avståndet mellan periodens försäljningsmål och det totala värdet för alla publicerade pass i perioden ',
        }, {
            label: 'Diff',
            key: 'goalDiff',
            postfix: 'kr',
            format: true,
            hideMeta: true,
            hideInWeekMode: true,
            explanation: 'Avståndet mellan periodens försäljningsmål och det totala värdet för alla publicerade pass i perioden ',
        }, {
            label: 'Antal konsulter',
            key: 'registeredConsultants',
            explanation: 'Totalt antal registrerade konsulter vid periodens slut',
        }, {
            label: 'Aktiva konsulter',
            key: 'consultants',
            explanation: 'Antal unika konsulter som bokades under perioden',
        }, {
            label: 'Aktiveringsgrad',
            key: 'consultantsPercentage',
            explanation: '% av totala antalet konsulter som arbetade under perioden',
            format: true,
            postfix: '%',
        }, {
            label: 'Aktiva uppdragsg.',
            key: 'employers',
            explanation: 'Antal unika uppdragsgivare som publicerat pass under perioden',
        }, {
            label: 'Timmar',
            key: 'hours',
            format: true,
            explanation: 'Antalet timmar som publicerats under perioden',
        }, {
            label: 'Bokade timmar',
            key: 'bookedHours',
            format: true,
            explanation: 'Antalet timmar som bokats under perioden',
        }, {
            label: 'Timmar p. konsult',
            key: 'hoursPerConsultant',
            format: true,
            explanation: 'Genomsnittligt antal bokade timmar per aktiv konsult under perioden',
        }, {
            label: 'Pris',
            key: 'averagePrice',
            postfix: 'kr',
            format: true,
            explanation: 'Genomsnittspris per timme under perioden med inskolningspass borträknade',
        }, {
            label: 'Lön',
            key: 'averageSalary',
            postfix: 'kr',
            format: true,
            hideMeta: true,
            explanation: 'Genomsnittslön per timme under perioden med inskolningspass borträknade',
        }];

        return (
            <div
                style={{
                    marginTop: 0,
                    position: 'relative',
                    height: 1480,
                    overflow: 'hidden',
                    borderLeft: '1px solid #EEE',
                    borderBottom: '1px solid #EEE',
                }}
            >

                <div
                    className='mp-new-dashboard__totals-table'
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        width: 200,
                        zIndex: 2,
                        background: '#25252f',
                        color: '#FFF',
                        boxShadow: '2px 0 20px rgba(0,0,0,0.2) inset',
                    }}
                >

                    {this.renderTotalsTable(legend, months)}

                </div>

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        width: 210,
                        zIndex: 2,
                        background: '#FFF',
                        color: '#666',
                        borderRight: '1px solid #EEE',
                    // display: 'none'
                    }}
                >
                    <Table
                        headers={['PERIOD']}
                        renderRow={(row, i) => {
                            if (row.hideInWeekMode && this.state.groupBy === 'week') {
                                return '';
                            }

                            return (
                                <tr>
                                    <td
                                        style={{
                                            borderBottomColor: 'rgba(0,0,0,0.05)',
                                            fontSize: 14,
                                            padding: 20,
                                            fontWeight: '600',
                                            // textAlign: 'right',

                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span>
                                                {row.label}
                                            </span>

                                            <Tooltip
                                                mouseEnterDelay={0.1}
                                                overlayStyle={{ maxWidth: 250 }}
                                                placement='right'
                                                title={(
                                                    <span>
                                                        {row.explanation}
                                                    </span>
                                                )}
                                                trigger={['hover']}
                                            >
                                                <i
                                                    className='fa fa-question-circle'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        }}
                        rows={legend}
                        style={{
                            boxShadow: 'none', background: 'none', paddingLeft: 0, paddingRight: 0,
                        }}
                    />

                </div>

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    // paddingRight: 200,
                    }}
                >
                    <div
                        style={{
                            height: 800,
                            minWidth: 200,
                            maxWidth: 200,
                            display: 'none',
                            background: 'red',
                            flex: 1,
                        }}
                    />
                    <div
                        className='mp-new-dashboard__overview-table'
                        style={{ flex: 1 }}
                    >
                        <Table
                            className='mp-new-dashboard__overview-table'
                            headers={headers}
                            renderRow={(legend, i) => {
                                const { key } = legend;

                                if (legend.hideInWeekMode && this.state.groupBy === 'week') {
                                    return '';
                                }

                                return (

                                    <tr>

                                        {months.map((m, i) => {
                                            const isCurrentMonth = moment(m.month).isSame(moment(), this.state.groupBy);
                                            const isAfterCurrentMonth = moment(m.month).isAfter(moment().endOf(this.state.groupBy));
                                            const prevMonthVal = this.compareToPreviousMonth(i, key, m[key], months);

                                            return (

                                                <td
                                                    style={{
                                                        background: isCurrentMonth ? '#f8f8ff' : '#FFF',
                                                        fontSize: 14,
                                                        color: '#777',
                                                        fontWeight: 700,
                                                        width: 180,
                                                        minWidth: '180px!important',
                                                        maxWidth: 180,
                                                        borderRight: '1px solid #EEE',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            flexDirection: 'row-reverse',
                                                            padding: '0 20px',
                                                            minWidth: 180,
                                                        }}
                                                    >
                                                        {isNaN(m[key]) || !m[key]
                                                            ? '-'
                                                            : legend.format && m[key] ? m[key].toLocaleString('sv-se') : m[key]}

                                                        {legend.postfix && !isNaN(m[key]) && m[key] ? legend.postfix : ''}

                                                        {isAfterCurrentMonth || legend.hideMeta
                                                            ? ''
                                                            : this.renderChangeTicker(prevMonthVal)}
                                                    </div>

                                                </td>

                                            );
                                        })}

                                    </tr>

                                );
                            }}
                            rows={legend}
                            style={{
                                boxShadow: 'none', padding: 0,
                            }}
                        />
                    </div>

                    <div
                        style={{
                            height: 800,
                            minWidth: 200,
                            maxWidth: 200,
                            background: 'red',
                            flex: 1,
                            // display: 'none'
                        }}
                    />

                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div
                    style={{ padding: '40px 0 20px 40px' }}
                >
                    <h3>
                        Statistics
                    </h3>
                </div>
                <div
                    style={{
                        margin: '0 0 20px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        borderRadius: 4,
                        background: '#FFF',
                    }}
                >
                    <div
                        style={{
                            padding: '13px 20px',
                            background: '#FFF',
                            position: 'sticky',
                            top: 0,
                            zIndex: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #EEE',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                aria-label='Basic example'
                                role='group'
                                style={{ display: 'flex' }}
                            >
                                <div
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: 16,
                                        border: '1px solid #EEE',
                                        boxShadow: '0 1px 5px rgba(0,0,0,0.1)',
                                        marginRight: 5,
                                    }}
                                    onClick={() => {
                                        this.decreaseRange();
                                    }}
                                >
                                    <i className='fa fa-chevron-left' />
                                </div>
                                <div
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: 16,
                                        border: '1px solid #EEE',
                                        boxShadow: '0 1px 5px rgba(0,0,0,0.1)',
                                        marginRight: 20,
                                    }}
                                    onClick={() => {
                                        this.increaseRange();
                                    }}
                                >
                                    <i className='fa fa-chevron-right' />
                                </div>
                            </div>
                        </div>
                        <div aria-label='Basic example' className='btn-group' role='group'>
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-secondary': this.state.mainOccupationFilter !== 0,
                                    'btn-primary': this.state.mainOccupationFilter === 0,
                                })}
                                type='button'
                                onClick={() => {
                                    this.setState({ mainOccupationFilter: 0 });
                                }}
                            >
                                Alla
                            </button>
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-secondary': this.state.mainOccupationFilter !== 2,
                                    'btn-primary': this.state.mainOccupationFilter === 2,
                                })}
                                type='button'
                                onClick={() => {
                                    this.setState({ mainOccupationFilter: 2 });
                                }}
                            >
                                SSK
                            </button>
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-secondary': this.state.mainOccupationFilter !== 3,
                                    'btn-primary': this.state.mainOccupationFilter === 3,
                                })}
                                type='button'
                                onClick={() => {
                                    this.setState({ mainOccupationFilter: 3 });
                                }}
                            >
                                LÄK
                            </button>
                        </div>

                        <div aria-label='Basic example' className='btn-group' role='group'>
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-secondary': this.state.groupBy !== 'month',
                                    'btn-primary': this.state.groupBy === 'month',
                                })}
                                type='button'
                                onClick={() => {
                                    this.setState({ groupBy: 'month' });
                                }}
                            >
                                Månadsvis
                            </button>
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-secondary': this.state.groupBy !== 'week',
                                    'btn-primary': this.state.groupBy === 'week',
                                })}
                                type='button'
                                onClick={() => {
                                    this.setState({ groupBy: 'week' });
                                }}
                            >
                                Veckovis
                            </button>
                        </div>
                    </div>
                    {this.renderTableSummary()}
                </div>
            </div>
        );
    }
}
