import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import Routes from './routes';
import 'assets/favicon.ico';
import 'containers/app/styles/antdtheme.less'; // override variables here

const store = configureStore();

window.getStore = () => store;

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ConfigProvider
                locale={en_GB}
            >
                <Routes />
            </ConfigProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);

if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept();
}
