import React from 'react';
import {
    map,
    filter,
} from 'lodash';
import { Link } from 'react-router-dom';
import Avatar from 'components/shared/avatar';
import { occupationTitle } from 'utils/nameformatters';
import config from 'config';

import { Collapse } from 'antd';

import './styles.scss';

export default class AllConsultants extends React.Component {
    constructor(props) {
        super(props);

        this.state = { collapsed: false };
    }

    toggleCollapsed() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    renderConsultant(consultant) {
        let color = '#d9534f';

        if (consultant.user.state === 1) {
            color = '#5b53f9';
        }
        else if (consultant.user.state === 2) {
            color = '#71e0aa';
        }

        return (

            <li
                key={consultant.id}
                className='all-consultants-list__item col-sm-4'
            >
                <div className='all-consultants-list__item__preview'>
                    <Link to={`/consultants/${consultant.user.id}`}>
                        <Avatar
                            className='all-consultants-list__item__avatar'
                            gender={consultant.user.gender}
                            size={54}
                            url={consultant.user.avatar}
                        >

                            <div
                                className='all-consultants-list__item__status'
                                style={{ backgroundColor: color }}
                            />

                        </Avatar>
                    </Link>
                    <div className='all-consultants-list__item__text'>
                        <Link to={`/consultants/${consultant.user.id}`}>
                            <span className='all-consultants-list__item__text__name'>
                                {consultant.user.firstname}
                                {' '}
                                {consultant.user.lastname}
                            </span>
                        </Link>
                        <div>{consultant.user.phone}</div>
                        <a href={`mailto:${consultant.user.email}`}>{consultant.user.email}</a>
                        <div>{occupationTitle(consultant.user.occupation)}</div>
                        <div>
                            Rating by employer:
                            <strong>{consultant.user.rating.user_rating ? consultant.user.rating.user_rating : 'not set'}</strong>
                        </div>
                        <a
                            className='mp-intercom-link'
                            href={`http://app.intercom.io/apps/${config.intercomAppId}/users/show?email=${consultant.user.email}`}
                            target='_blank'
                        >
                            Open in Intercom
                            {' '}
                            <span className='ion-android-open' />
                        </a>
                    </div>
                </div>

            </li>

        );
    }

    renderConsultants() {
        const consultants = filter(this.props.allConsultants.collection, consultant => (consultant.added ? consultant : false));

        if (consultants) {
            return (
                <Collapse style={{ marginTop: 20 }}>
                    <Collapse.Panel
                        key='previousConsultants'
                        header={`Employed consultants (${consultants.length})`}
                        style={{
                            fontSize: 20,
                            fontWeight: '600',
                            // borderBottom: 'none',
                        }}
                    >
                        <ul className='all-consultants-list' style={{ paddingBottom: 20 }}>

                            {map(consultants, consultant => {
                                if (consultant.added) {
                                    return this.renderConsultant(consultant);
                                }
                            })}

                        </ul>
                    </Collapse.Panel>
                </Collapse>
            );
        }
    }

    render() {
        return (
            <div>
                {(this.props.allConsultants.collection && this.props.allConsultants.collection.length)
                    ? this.renderConsultants(this.props.allConsultants)
                    : ''}
            </div>
        );
    }
}
