import React, { useState } from 'react';
import { Drawer, Button, DatePicker, Pagination, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const TaskListModal = ({ items, onChangePage, data, onClose, keyName, onSaveComment, onAddTask, onGetComments, onSendCallList }) => {
  const { totalCount, totalPages, page } = data
  const [listName, setListName] = useState('');
  const [isLogListOpen, setIsLogListOpen] = useState({});
  const [isTaskOpen, setIsTaskOpen] = useState({});
  const [tempInput, setTempInput] = useState();
  const [userData, setUserData] = useState([...items]);
  const [userId, setUserId] = useState();
  const [dueDate, setDueDate] = useState();
  const [plannedDate, setPlannedDate] = useState();
  const [textIsSent, setTextIsSent] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSendTagList = () => {
    if(listName.length > 3) {
      onSendCallList(listName);
      setTextIsSent(true)
      setListName('')
    }
  }

  const addComment = (id, item) => {
    const index = userData.findIndex(u => u.id === id);
    const user = userData.splice(index, 1)[0];
    const { comments } = user;
    const newUser = {
      ...user,
      comments: [
        ...comments,
        item
      ]
    }
    setUserData([
      ...userData.slice(0, index),
      newUser,
      ...userData.slice(index)
    ])
    onSaveComment(id, item);
  }

  const onAddTaskHandler = (id) => {
    if (tempInput && dueDate && plannedDate) {
      const index = userData.findIndex(u => u.id === id);
      const user = userData.splice(index, 1)[0];
      const { tasks } = user;
      const item = {
        title: tempInput,
        admin_id_assigned: null,
        due_date_time: dueDate,
        planned_date_time: plannedDate,
      }
      const newUser = {
        ...user,
        tasks: [
          ...tasks,
          item
        ]
      }

      setUserData([
        ...userData.slice(0, index),
        newUser,
        ...userData.slice(index)
      ])
      const typeId = keyName === 'fullname' ? { user_id: id } : { employer_id: id }
      onAddTask(item, typeId);
      setTempInput('');
      setHasError(false)
    } else {
      setHasError(true)
    }
  } 

  const onToggleTask = (id) => {
    setIsTaskOpen({ [id]: !isTaskOpen[id] })
    setIsLogListOpen({ [id]: false })
    setUserId(id)
    setTempInput('')
  }

  const onToggleTimeLog = (id) => {
    setIsTaskOpen({[id]: false})
    setIsLogListOpen({ [id]: !isLogListOpen[id] })
    setUserId(id)
    if(!isLogListOpen[id]) {
      onGetComments(id)
    }
  }

  const onPhoneLog = (id, success) => {
    const log = {
      comment: success,
      created_datetime: new Date(),
      type: 'log'
    }
    addComment(id, log);
  }

  const renderLogList = (log) => (
    <div className='flex items-center border-t py-1 border-white' key={log.created_datetime}>
      <div className='ml-8'>
        <span className='mr-2'>
          { log.comment === '1' ? <i className="fa fa-phone" aria-hidden="true" /> : <i className="fa fa-ban" aria-hidden="true" /> }
        </span>
        <span className='mr-2'>{ moment(log.created_datetime).format('YYYY-MM-DD HH:mm:ss') }</span>
      </div>
    </div>
  )

  const renderList = userData.map((item) => {
    const { id, phone, comments, tasks } = item;
    const sortedTasks = tasks.sort((a,b) => a.due_date_time - b.due_date_time)
    const userLogs = comments ? comments.filter((comment) => comment.type === 'log') : []
    return (
      <div key={id} className='border-b border-white'>
        <div className='flex items-center py-4 px-2 border-b border-white'>
          <div className='flex flex-col justify-center items-center'>
            <Button onClick={() => onPhoneLog(id, "1")}>
              <i className="fa fa-phone" aria-hidden="true" />
            </Button>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <Button onClick={() => onPhoneLog(id, "0")}>
              <i className="fa fa-ban" aria-hidden="true" />
            </Button>
          </div>
          <div className='flex flex-col ml-4 flex-1'>
            <span className='font-semibold'>{item[keyName]}</span>
            <span className='text-xs'>Phone: {phone}</span>
          </div>
          <Button onClick={() => onToggleTask(id)}><i className="fa fa-list-ol" aria-hidden="true" /></Button>
        </div>
        { userLogs?.length > 0 && (
            <div className='cursor-pointer' onClick={() => onToggleTimeLog(id)}>
              <div className='ml-6 py-2 font-semibold'>Phone log</div>
              <div>{ renderLogList(userLogs[userLogs.length - 1])}</div>
            </div>
          )
        }
        { isLogListOpen[id] && 
          (<div className='flex flex-col mb-6'>
            { [...userLogs.slice(0, -1)].reverse().map(renderLogList) }
          </div>)
        }
        { isTaskOpen[id] && (<div className='flex flex-col bg-gray-100 border-b border-white'>
            <div className='py-2 font-semibold border-white border-t'>
              <div className='px-6'>Create a Task</div>
            </div>
            <div className='flex flex-col items-end px-6 pb-2'>
              <TextArea value={tempInput} allowClear onChange={(e) => setTempInput(e.target.value)}/>
              <div className='flex my-2'>
                <DatePicker placeholder="Select planned date" onChange={(d) => setPlannedDate(moment(d).unix())} />
                <DatePicker placeholder="Select due date" onChange={(d) => setDueDate(moment(d).unix())} />
                <Button className="ml-2" onClick={() => onAddTaskHandler(id)}>
                  <i className="fa fa-plus mr-1" aria-hidden="true" />Add task
                </Button>
              </div>
            </div>
            { hasError && <div className='flex flex-col items-end px-6 pb-4 text-red-600'>
              Enter all fields
            </div>}
          </div>)}
        {
          (isTaskOpen[id] && id === userId) && (
            <div className='flex flex-col [&>*:nth-child(even)]:bg-gray-200'>
              {sortedTasks.map(((task) => (
                <div key={`${id}-${task.created_time}`} className='flex items-center justify-between py-4  border-b border-white'>
                  <div className='flex px-6 flex-col'>
                    <div>
                      <span className='font-semibold mr-1'>Created:</span>
                      <span>{ moment.unix(task.created_time).format('YYYY-MM-DD') }</span>
                    </div>
                    <div className="my-2">{task.title}</div>
                    <div className='flex mr-2'>
                      <div className='mr-4'>
                        <span className='font-semibold mr-1'>Planned:</span>
                        <span>{ moment.unix(task.planned_date_time).format('YYYY-MM-DD') }</span>
                      </div>
                      <div>
                        <span className='font-semibold mr-1'>Due:</span>
                        <span>{ moment.unix(task.due_date_time).format('YYYY-MM-DD') }</span>
                      </div>
                    </div>
                  </div>
                </div>
              )))}
            </div>
          )
        }
      </div>
    )
  });

  return (
    <Drawer open={true} placement='right' title='List and Tasks' width={600} onClose={onClose}>
      <div className='[&>*:nth-child(even)]:bg-gray-200 [&>*:nth-child(odd)]:bg-gray-100'>
        {renderList}
      </div>
      <div className='flex items-center'>
          <Pagination current={page} onChange={onChangePage} total={totalPages} />
          <div className='ml-2 text-xs'>Total pages ({totalPages}) and {totalCount} records</div>
        </div>
      { onSendCallList && <div className='flex my-4 flex-col'>
        <div className='flex justify-center'>
          <Input onChange={(e) => setListName(e.target.value)} value={listName} placeholder='Tag name' />
          <Button onClick={() => onSendTagList()} disabled={listName.length < 3} >
            <i className="fa fa-paper-plane-o mr-2" aria-hidden="true" /><span>Send</span>
          </Button>
        </div>
        {textIsSent && <div className='w-full text-center my-4 font-semibold'>List has been sent</div>}
      </div>}
    </Drawer>
  );
};

export default TaskListModal;
