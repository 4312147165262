import React from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';

import ActivityBoard from 'containers/ActivityBoard';
import AdsBoard from 'containers/AdsBoard';
import HomeDashboard from 'containers/HomeDashboard';
import App from 'containers/app';
import Employers from 'containers/employers';

import CreateEmployer from 'containers/CreateEmployer';
import Employer from 'containers/employer';
import EmployerChangeCredentials from 'containers/employer/changecredentials';
import EmployerContacts from 'containers/employer/contacts';
import EmployerCustomShifts from 'containers/employer/customshifts';
import EmployerInvoiceAddress from 'containers/employer/invoiceaddress';
import EmployerJobads from 'containers/employer/jobads';
import EmployerLongTermVacancies from 'containers/employer/longtermvacancies';
import EmployerOverview from 'containers/employer/overview';
import EmployerProfiles from 'containers/employer/profiles';
import EmployerShifts from 'containers/employer/shifts';

import Consultant from 'containers/consultant';
import ConsultantEmployers from 'containers/consultant/ConsultantEmployers/ConsultantEmployers';
import UploadsTable from 'containers/consultant/UploadsTable/UploadsTable';
import ConsultantApplications from 'containers/consultant/applications';
import ConsultantBookings from 'containers/consultant/bookings';
import ConsultantContracts from 'containers/consultant/contracts';
import ConsultantCV from 'containers/consultant/cv';
import ConsultantOverview from 'containers/consultant/overview';
import ConsultantSalaries from 'containers/consultant/salaries';
import ConsultantSickLeave from 'containers/consultant/sickleave';
import ConsultantTimeReports from 'containers/consultant/timereports';
import ConsultantWageTaxDeclarations from 'containers/consultant/wagetaxdeclarations';
import Consultants from 'containers/consultants';

import EmployerAttests from 'containers/EmployerAttests';
import Applications from 'containers/applications';
import Calendar from 'containers/calendar';
import Invoices from 'containers/invoices';
import ShiftsCalendar from 'containers/shifts_calendar';
import UserMap from 'containers/usermap';

import ConsultantHosp from 'containers/consultant/hosp';
import Faqs from 'containers/faqs';
import InvoiceList from 'containers/invoicelist';
import Recruitments from 'containers/recruitments';
import Retention from 'containers/retention';
import SalaryErrorList from 'containers/salaryerrorlist';
import SalaryList from 'containers/salarylist';
import Statistics from 'containers/statistics';
import Tags from 'containers/tags';
import TimelinePage from 'containers/timeline';
import UserMarketingSources from 'containers/usermarketingsources';

import AdminUsers from 'containers/adminUsers';
import Benefits from 'containers/benefits';
import Orders from 'containers/orders';
import Order from 'containers/orders/pages/OrderDetails';
import Ltvs from './containers/ltvs';
import Procurements from './containers/procurements';
import Tasks from './containers/tasks';

const Routes = () => (
    <App>
        <Switch>
            <Route component={HomeDashboard} exact path='/' />
            <Route component={ActivityBoard} exact path='/activity-board' />
            <Route component={AdsBoard} exact path='/ads-board' />
            <Route component={Employers} exact path='/employers' />

            <Route path='/employers/:id'>
                <Employer>
                    <Switch>
                        <Route component={EmployerOverview} exact path='/employers/:id' />
                        <Route component={EmployerShifts} exact path='/employers/:id/shifts' />
                        <Route component={EmployerShifts} exact path='/employers/:id/shifts/:monthId/:shiftId?' />
                        <Route component={EmployerProfiles} exact path='/employers/:id/profiles' />
                        <Route component={EmployerCustomShifts} exact path='/employers/:id/custom-shifts' />
                        <Route component={EmployerInvoiceAddress} exact path='/employers/:id/invoice-address' />
                        <Route component={EmployerContacts} exact path='/employers/:id/contacts' />
                        <Route component={EmployerChangeCredentials} exact path='/employers/:id/change-credentials' />
                        <Route component={EmployerJobads} exact path='/employers/:id/job-ads' />
                        <Route component={EmployerLongTermVacancies} exact path='/employers/:id/long-term-vacancies' />
                        {/*
                        Todo: remove component
                        <Route component={EmployerScheduling} exact path='/employers/:id/scheduling' /> */ }
                    </Switch>
                </Employer>
            </Route>

            <Route component={CreateEmployer} exact path='/createemployer' />
            <Route component={Consultants} exact path='/consultants' />

            <Route path='/consultants/:id'>
                <Consultant>
                    <Switch>
                        <Route component={ConsultantOverview} exact path='/consultants/:id' />
                        <Route component={ConsultantOverview} exact path='/consultants/:id/overview' />
                        <Route component={ConsultantCV} exact path='/consultants/:id/cv' />
                        <Route component={ConsultantApplications} exact path='/consultants/:id/applications' />
                        <Route component={ConsultantBookings} exact path='/consultants/:id/bookings' />
                        <Route component={ConsultantTimeReports} exact path='/consultants/:id/time-reports' />
                        <Route component={ConsultantSalaries} exact path='/consultants/:id/salaries' />
                        <Route component={ConsultantContracts} exact path='/consultants/:id/contracts' />
                        <Route component={ConsultantHosp} exact path='/consultants/:id/hosp' />
                        <Route component={ConsultantSickLeave} exact path='/consultants/:id/sickleave' />
                        <Route component={ConsultantEmployers} exact path='/consultants/:id/employers' />
                        <Route component={UploadsTable} exact path='/consultants/:id/uploads' />
                        <Route component={ConsultantWageTaxDeclarations} exact path='/consultants/:id/wagetax' />
                    </Switch>
                </Consultant>
            </Route>

            <Route component={Invoices} exact path='/invoices' />
            <Route component={EmployerAttests} exact path='/employer_attests' />
            <Route component={Calendar} exact path='/calendar' />
            <Route component={ShiftsCalendar} exact path='/shifts_calendar' />
            <Route component={Applications} exact path='/applications' />
            <Route component={UserMap} exact path='/usermap' />
            <Route component={Tags} exact path='/tags' />
            <Route component={InvoiceList} exact path='/invoicelist' />
            <Route component={SalaryList} exact path='/salarylist' />
            <Route component={SalaryErrorList} exact path='/salaryerrorlist' />
            <Route component={UserMarketingSources} exact path='/usermarketingsources' />
            <Route component={Faqs} exact path='/faqs' />
            <Route component={Retention} exact path='/retention' />
            <Route component={Statistics} exact path='/statistics' />
            <Route component={TimelinePage} exact path='/timeline' />
            <Route component={Recruitments} exact path='/recruitments' />
            <Route component={Benefits} exact path='/benefits' />
            <Route component={Ltvs} exact path='/ltvs' />
            <Route component={Procurements} exact path='/procurements' />
            <Route component={Tasks} exact path='/tasks' />
            <Route component={AdminUsers} exact path='/admin_users' />

            <Route component={Orders} exact path='/orders' />

            <Route path='/orders/:id'>
                <Order>
                    <Switch>
                        <Route component={Order} exact path='/orders/:id' />
                    </Switch>
                </Order>
            </Route>
        </Switch>
    </App>
);

export default Routes;
