import * as request from 'request';

export function requestFetchActivityTypes() {
    return { type: 'REQUEST_ACTIVITY_TYPES' };
}

export function recieveActivityTypes(resp) {
    return {
        type: 'RECEIVE_ACTIVITY_TYPES',
        activityTypes: resp.types,
    };
}

export function fetchActivityTypes() {
    return dispatch => {
        dispatch(requestFetchActivityTypes());
        return request.fetchActivityTypes()
            .then(resp => dispatch(recieveActivityTypes(resp)));
    };
}
