import React from 'react';
import PropTypes from 'prop-types';
import {
    filter,
    sortBy,
} from 'lodash';
import onClickOutside from 'react-onclickoutside';
import stringToColor from 'utils/stringtocolor';
import classNames from 'utils/classnames';

class AddTag extends React.Component {
    static propTypes = {

        availableTags: PropTypes.array.isRequired,
        addedTags: PropTypes.array.isRequired,
        close: PropTypes.func.isRequired,

    }

    constructor(props) {
        super(props);
        this.state = {
            matches: props.availableTags,
            query: '',
            selectedIndex: false,
            hasPerfectMatch: false,
            offset: 0,
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.refs.input.focus();
        document.addEventListener('keydown', this.handleKeyDown);

        const rect = this.refs.element.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const availableSpace = windowWidth - rect.left;

        if (availableSpace < rect.width) {
            const offset = rect.width - availableSpace;
            this.setState({ offset });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleClickOutside() {
        this.props.close();
    }

    handleKeyDown(e) {
        const { keyCode } = e;

        switch (e.keyCode) {
            case 40:
                // Down arrow
                this.incrementSelectedIndex();
                break;

            case 38:
                // Up arrow
                this.decrementSelectedIndex();
                break;

            case 13:
                // Enter key
                this.keyboardSubmit();
                break;
        }
    }

    incrementSelectedIndex() {
        if (!this.state.query || this.state.selectedIndex === 'create') {
            return;
        }

        let index;
        if (this.state.selectedIndex === false) {
            index = 0;
        }
        else if (this.state.selectedIndex + 1 === this.state.matches.length) {
            index = 'create';
        }
        else {
            index = this.state.selectedIndex + 1;
        }

        this.setState({ selectedIndex: index });
    }

    decrementSelectedIndex() {
        if (!this.state.query || this.state.selectedIndex === 0) {
            return;
        }

        let index;
        if (this.state.selectedIndex === 'create') {
            index = this.state.matches.length - 1;
        }
        else {
            index = Math.max(this.state.selectedIndex - 1, 0);
        }

        this.setState({ selectedIndex: index });
    }

    reset() {
        this.refs.input.value = '';
        this.setState({
            matches: this.props.availableTags,
            query: '',
            selectedIndex: false,
        });
    }

    createAndAdd() {
        const title = this.state.query;
        this.props.createAndAddTag(title);
        this.reset();
    }

    onSubmit(tagId) {
        this.props.addTag(tagId);
        this.reset();
    }

    keyboardSubmit() {
        if (this.state.selectedIndex === 'create') {
            this.createAndAdd();
            return;
        }
        const selectedTag = this.state.matches[this.state.selectedIndex];
        this.onSubmit(selectedTag.id);
    }

    clickSubmit(id) {
        if (id === 'create') {
            this.createAndAdd();
            return;
        }
        this.onSubmit(id);
    }

    setMatches(query) {
        const matches = this.getQueryMatches(query);
        const hasPerfectMatch = this.hasPerfectMatch(query);

        let selectedIndex = 0;
        if (query && !matches.length) {
            selectedIndex = 'create';
        }
        this.setState({
            selectedIndex,
            matches,
            hasPerfectMatch,
            query,
        });
    }

    hasPerfectMatch(query) {
        return filter(this.props.availableTags, t => t.tag.toLowerCase() === query.toLowerCase()).length;
    }

    getQueryMatches(query) {
        if (!query) {
            return this.props.availableTags;
        }

        return filter(this.props.availableTags, tag => {
            const name = tag.tag;
            return name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
    }

    renderMatches() {
        return sortBy([...this.state.matches], t => t.tag.toLowerCase()).map((tag, i) => (
            <div
                key={tag.id}
                className={classNames({
                    'mpa-tags-list__match': true,
                    'mpa-tags-list__match--selected': i === this.state.selectedIndex,
                })}
                style={{
                    borderLeftWidth: 20,
                    borderLeftStyle: 'solid',
                    borderLeftColor: stringToColor(tag.tag),
                }}
                onClick={() => {
                    this.clickSubmit(tag.id);
                }}
                onScroll={e => {
                    e.stopPropagation();
                }}
            >
                {tag.tag}
            </div>
        ));
    }

    renderCreateRow() {
        if (!this.state.query || this.state.hasPerfectMatch) {
            return null;
        }

        return (
            <div
                className={classNames({
                    'mpa-tags-list__create': true,
                    'mpa-tags-list__create--selected': this.state.selectedIndex === 'create',
                })}
                onClick={() => {
                    this.clickSubmit('create');
                }}
            >
                <span
                    className='ion-android-add-circle'
                    style={{ marginRight: 5 }}
                />
                Create tag "
                {this.state.query}
                "
            </div>
        );
    }

    render() {
        return (
            <div
                ref='element'
                className='mpa-tags-list__add-tag'
                style={{ left: this.state.offset ? this.state.offset * -1 : 0 }}
            >
                <input
                    ref='input'
                    className='form-control'
                    placeholder='Add or create tag...'
                    onChange={e => {
                        if (!e.currentTarget.value) {
                            this.reset();
                        }
                        else {
                            this.setMatches(e.currentTarget.value);
                        }
                    }}
                />
                <div className='mpa-tags-list__matches'>
                    {this.renderMatches()}
                </div>
                {this.renderCreateRow()}
            </div>
        );
    }
}

export default onClickOutside(AddTag);
