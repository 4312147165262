import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    map,
    groupBy,
    sortBy,
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { humanReadableHours } from 'utils/datetimeformatters';
import {
    fetchConsultantScheduled,
    batchUpdateScheduled,
    fetchConsultantLtvApplications,
} from 'actions/consultant';

import {
    Table,
    Collapse,
    Button,
} from 'antd';

import Loader from 'components/shared/loader';
import BatchUpdateScheduledModal from 'components/batchupdatescheduledmodal';
import { ConsultantLtvApplications } from './LtvApplications';

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    ltvApplications: state.consultant.ltvApplications,
    scheduled: state.consultant.scheduled.filter(
        s => (s.status === 'PENDING'),
    ).sort((a, b) => a.start_time - b.start_time),
    hasFetchedScheduled: state.consultant.hasFetchedScheduled,
    hasFetchedLtvApplications: state.consultant.hasFetchedLtvApplications,
    hasBatchUpdatedScheduled: state.consultant.hasBatchUpdatedScheduled,
});

const ConsultantApplications = ({
    scheduled,
    hasFetchedScheduled,
    consultant,
    ltvApplications,
    batchUpdateScheduled,
    fetchConsultantScheduled,
    hasBatchUpdatedScheduled,
    hasFetchedLtvApplications,
    fetchConsultantLtvApplications,
}) => {
    const [batchUpdateScheduledModalVisible, setBatchUpdateScheduledModalVisible] = useState(false);
    useEffect(() => {
        if (!hasFetchedScheduled) {
            fetchConsultantScheduled(consultant.id);
        }
    }, [consultant, hasFetchedScheduled, fetchConsultantScheduled]);
    useEffect(() => {
        if (!hasFetchedLtvApplications) {
            fetchConsultantLtvApplications(consultant.id);
        }
    }, [consultant, hasFetchedLtvApplications, fetchConsultantLtvApplications]);
    const renderTable = (data, date) => {
        const columns = [
            {
                title: 'Employer',
                dataIndex: '',
                key: 'employer',
                width: 250,
                render: (_, record) => (
                    <Link to={`/employers/${record.employer.id}`}>
                        {record.employer.name}
                    </Link>
                ),
            },
            {
                title: 'Shift ID',
                dataIndex: 'id',
                key: 'shift_id',
            },
            {
                title: 'Date',
                dataIndex: 'start_time',
                key: 'start_date',
                render: start_time => moment.unix(start_time).format('YYYY-MM-DD'),
            },
            {
                title: 'Time',
                dataIndex: 'start_time',
                key: 'start_time',
                render: (start_time, record) => `${moment.unix(start_time).format('HH:mm')} - ${moment.unix(record.end_time).format('HH:mm')}`,
            },
            {
                title: 'Break',
                dataIndex: 'shift_break',
                key: 'break',
                render: shift_break => humanReadableHours(shift_break),
            },
            {
                title: 'Salary',
                dataIndex: 'salary',
                key: 'salary',
                render: (_, record) => (
                    <div>
                        Salary:
                        {' '}
                        {Math.round(record.duration * record.salary)}
                        kr
                        {' '}
                        <br />
                        Invoice:
                        {' '}
                        {Math.round(record.duration * record.consultant_fee)}
                        kr
                    </div>
                ),
            },
            {
                title: 'Applied at',
                dataIndex: 'created_time',
                key: 'created_time',
                render: createdTime => moment.unix(createdTime).format('YYYY-MM-DD HH:mm'),
            },

        ];

        const header = (
            <div
                style={{
                    textTransform: 'capitalize', fontSize: 20,
                }}
            >
                {moment(date).format('MMMM')}
                ,
                {' '}
                {data.length}
                {' '}
                pass
            </div>
        );

        return (
            <Collapse.Panel
                key={date}
                header={header}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowKey='id'
                    scroll={{ x: 1100 }}
                />
            </Collapse.Panel>
        );
    };

    const renderYear = (year, key) => {
        const grouped = groupBy(year, item => moment.unix(item.start_time).format('YYYY-MM'));
        return (
            <div key={key}>
                <h3
                    style={{ marginTop: 20 }}
                >
                    {key}

                </h3>
                <BatchUpdateScheduledModal
                    batchUpdateScheduled={batchUpdateScheduled}
                    close={() => { setBatchUpdateScheduledModalVisible(false); }}
                    consultant={consultant}
                    hasBatchUpdatedScheduled={hasBatchUpdatedScheduled}
                    scheduled={scheduled}
                    visible={batchUpdateScheduledModalVisible}
                />
                <Collapse accordion>
                    {map(grouped, (item, key) => renderTable(item, key))}
                </Collapse>
            </div>
        );
    };

    if (!hasFetchedScheduled) {
        return (
            <Loader />
        );
    }

    const data = sortBy(scheduled, 'start_time').reverse();

    const groupedByYear = groupBy(data, item => moment.unix(item.start_time).format('YYYY'));

    const content = (
        <div
            style={{
                flexDirection: 'column-reverse',
                display: 'flex',
            }}
        >
            {map(groupedByYear, (year, key) => renderYear(year, key))}
        </div>
    );

    return (

        <div className='content-card-full'>
            <Button
                size='large'
                onClick={() => { setBatchUpdateScheduledModalVisible(true); }}
            >
                <i className='fa fa-pencil' style={{ paddingRight: 5 }} />
                Batch update applications
            </Button>
            {content}
            {<ConsultantLtvApplications applications={ltvApplications} />}
        </div>
    );
};
ConsultantApplications.propTypes = {
    scheduled: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        employer: PropTypes.shape({}),
    })).isRequired,
    ltvApplications: PropTypes.arrayOf(PropTypes.shape({
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        start_time: PropTypes.number.isRequired,
        created_time: PropTypes.number.isRequired,
        end_time: PropTypes.number,
    })),
    hasFetchedScheduled: PropTypes.bool.isRequired,
    consultant: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
    batchUpdateScheduled: PropTypes.func.isRequired,
    fetchConsultantScheduled: PropTypes.func.isRequired,
    fetchConsultantLtvApplications: PropTypes.func.isRequired,
    hasBatchUpdatedScheduled: PropTypes.bool.isRequired,
    hasFetchedLtvApplications: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        batchUpdateScheduled,
        fetchConsultantScheduled,
        fetchConsultantLtvApplications,
    }, dispatch),
)(ConsultantApplications);
