import * as request from 'request';
import { message } from 'antd';

const requestSearchEmployers = () => ({ type: 'REQUEST_SEARCH_EMPLOYERS' });

const receiveEmployers = resp => ({
    type: 'RECEIVE_SEARCH_EMPLOYERS',
    employers: resp.collection,
    totalCount: resp.total_count,
});

export const searchEmployer = (
    testEmployer,
    employerType,
    automotive,
    departmentTypeIds,
    countyIds,
    countries,
    marginConfigs,
    name,
    page,
    sort,
) => async dispatch => {
    dispatch(requestSearchEmployers());
    try {
        const resp = await request.fetchEmployersV2(
            testEmployer,
            employerType,
            automotive,
            departmentTypeIds,
            countyIds,
            countries,
            marginConfigs,
            name,
            page,
            sort,
        );
        dispatch(receiveEmployers(resp));
    }
    catch (e) {
        message.error('Failed to search for employers');
        throw e;
    }
};

const receiveSearchContacts = resp => ({
    type: 'RECEIVE_SEARCH_CONTACTS',
    contacts: resp.collection,
    totalCount: resp.total_count,
});

export const searchContacts = query => async dispatch => {
    try {
        const resp = await request.fetchContacts(
            query,
        );
        dispatch(receiveSearchContacts(resp));
    }
    catch (e) {
        message.error('Failed to search for employers');
        throw e;
    }
};
