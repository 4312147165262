import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'components/shared/avatar';
import { occupationTitle } from 'utils/nameformatters';

export default class UserInfoPopover extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { user } = this.props;

        return (
            <div
                className='mpa-map-marker__info'
                style={{ display: 'flex' }}
            >
                <div>
                    <Avatar
                        gender={user.gender}
                        size={80}
                        style={{ marginRight: 10 }}
                        url={user.avatar}
                    />
                </div>
                <div>
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                        }}
                    >
                        {user.firstname}
                        {' '}
                        {user.lastname}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        {occupationTitle(user.occupation)}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            marginTop: 5,
                            color: '#999',
                        }}
                    >
                        <div>{user.addressline1}</div>
                        <div>{user.addressline2}</div>
                        <div>
                            {user.postalcode}
                            {' '}
                            {user.city}
                        </div>
                    </div>
                    <div>
                        <button
                            className='btn btn-primary btn-block'
                            style={{ marginTop: 10 }}
                            onClick={() => {
                                this.props.onClick && this.props.onClick();
                            }}
                        >
                            Show profile
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
