import {
    ApiOutlined,
    BankOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    ContainerOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    DesktopOutlined,
    DollarCircleOutlined,
    EnvironmentOutlined,
    ExceptionOutlined,
    FileAddOutlined,
    FilterOutlined,
    HeartOutlined,
    HomeOutlined,
    HourglassOutlined,
    InfoCircleOutlined,
    MedicineBoxOutlined,
    NodeIndexOutlined,
    ProjectOutlined,
    SolutionOutlined,
    TableOutlined,
    TeamOutlined,
    UserOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import {
    Badge,
    Menu,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    Link,
    withRouter,
} from 'react-router-dom';
import './medpeople_white.png';
import './styles.scss';

const NavSideBar = ({
    tasks, session, location,
}) => {
    const myTasks = tasks.tasks.filter(t => t.admin_id_assigned === session.id && t.status === 'OPEN');
    const links = [
        {
            title: 'Dashboard',
            url: '/',
            icon: 'DashboardOutlined',
        },
        {
            title: 'Activity board',
            url: '/activity-board',
            icon: 'DesktopOutlined',
        },
        {
            title: 'Ads board',
            url: '/ads-board',
            icon: 'ProjectOutlined',
        },
        {
            title: 'Tasks',
            url: '/tasks',
            badge: myTasks.length,
            icon: 'CheckCircleOutlined',
        },
        {
            title: 'Kalender',
            url: '/calendar',
            icon: 'CalendarOutlined',
        }, {
            title: 'Shifts Calendar',
            url: '/shifts_calendar',
            icon: 'ContainerOutlined',
        }, {
            title: 'Recruitment',
            url: '/recruitments',
            icon: 'MedicineBoxOutlined',
        }, {
            title: 'Orders',
            url: '/orders',
            icon: 'FileAddOutlined',
        }, {
            title: 'Uppdragsgivare',
            url: '/employers',
            icon: 'BankOutlined',
        }, {
            title: 'Konsulter',
            url: '/consultants',
            icon: 'UserOutlined',
        },
        {
            title: 'Karta',
            url: '/usermap',
            icon: 'EnvironmentOutlined',
        },
        {
            title: 'Attester',
            url: '/employer_attests',
            icon: 'ClockCircleOutlined',
        },
        {
            title: 'Ansökningar',
            url: '/applications',
            icon: 'HourglassOutlined',
        },
        {
            title: 'LTVS',
            url: '/ltvs',
            icon: 'NodeIndexOutlined',
        },
        // {
        //     title: 'Procurements',
        //     url: '/procurements',
        //     icon: 'ApiOutlined',
        // },
        {
            title: 'Fakturaexport',
            url: '/invoicelist',
            icon: 'SolutionOutlined',
        },
        {
            title: 'Löneexport',
            url: '/salarylist',
            icon: 'WalletOutlined',
        },
        {
            title: 'Lönefel',
            url: '/salaryerrorlist',
            icon: 'ExceptionOutlined',
        },
        {
            title: 'Marknad',
            url: '/usermarketingsources',
            icon: 'FilterOutlined',
        },
        // {
        //     title: 'Retention',
        //     url: '/retention',
        //     icon: 'TableOutlined',
        // },
        {
            title: 'Frågor och svar',
            url: '/faqs',
            icon: 'InfoCircleOutlined',
        },
        // }, {
        //     title: 'Statistics',
        //     url: '/statistics',
        //     icon: 'DatabaseOutlined',
        // },
        // {
        //     title: 'Benefits',
        //     url: '/benefits',
        //     icon: 'DollarCircleOutlined',
        // },
        {
            title: 'Admin Users',
            url: '/admin_users',
            icon: 'TeamOutlined',
        },
    ];

    const renderItem = item => {
        const componentsMap = {
            DashboardOutlined,
            DesktopOutlined,
            CalendarOutlined,
            ContainerOutlined,
            MedicineBoxOutlined,
            HomeOutlined,
            UserOutlined,
            EnvironmentOutlined,
            ClockCircleOutlined,
            HourglassOutlined,
            SolutionOutlined,
            WalletOutlined,
            ExceptionOutlined,
            FilterOutlined,
            TableOutlined,
            InfoCircleOutlined,
            DatabaseOutlined,
            DollarCircleOutlined,
            NodeIndexOutlined,
            ApiOutlined,
            CheckCircleOutlined,
            TeamOutlined,
            HeartOutlined,
            ProjectOutlined,
            BankOutlined,
            FileAddOutlined,
        };
        const DynamicIcon = componentsMap[item.icon];
        return (
            <>

                <Menu.Item
                    key={item.url}
                    icon={(item.badge
                        ? (
                            <Badge
                                className='mpa-nav-sidebar__badge'
                                count={item.badge}
                            >
                                <DynamicIcon />
                            </Badge>
                        ) : <DynamicIcon />
                    )}
                >

                    <Link to={item.url}>{item.title}</Link>

                </Menu.Item>
            </>
        );
    };

    const theme = JSON.parse(process.env.IS_DEV) ? 'dark' : 'light';

    return (
        <Menu
            selectedKeys={[location.pathname]}
            theme={theme}
        >
            {links.map(renderItem)}
        </Menu>
    );
};

NavSideBar.propTypes = {
    session: PropTypes.shape({ id: PropTypes.number }).isRequired,
    tasks: PropTypes.shape({ tasks: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default connect(
    state => state,
)(withRouter(NavSideBar));
