import * as request from 'request';
import { omit } from 'lodash';
import { message } from 'antd';

export function requestUpdateShift() {
    return { type: 'REQUEST_UPDATE_SHIFT' };
}

export function receiveUpdateShift(updatedShift) {
    return {
        type: 'RECEIVE_UPDATE_SHIFT',
        shift: updatedShift,
    };
}

export function updateShift(shiftId, requestData) {
    return dispatch => {
        dispatch(requestUpdateShift());
        return request.updateShift(shiftId, requestData)
            .then(resp => {
                message.success(`Pass ID: ${shiftId} har uppdaterats`);
                dispatch(receiveUpdateShift(resp));
            }, error => {
                message.error(error.responseJSON.message);
            });
    };
}

export function updateMultipleShifts(shifts) {
    return dispatch => {
        const promises = [];
        shifts.forEach(s => {
            promises.push(request.updateShift(s.id, omit(s, 'id')));
        });
        return Promise.allSettled(promises);
    };
}

export function requestUpdateBookedShift() {
    return { type: 'REQUEST_UPDATE_BOOKED_SHIFT' };
}

export function receiveUpdateBookedShift(shift) {
    return {
        type: 'RECEIVE_UPDATE_BOOKED_SHIFT',
        shift,
    };
}

export function updateBookedShift(bookedConsultantId, requestData) {
    return dispatch => {
        dispatch(requestUpdateBookedShift());
        return request.updateBookedShift(bookedConsultantId, requestData)
            .then(resp => {
                message.success(`Pass ID: ${bookedConsultantId} har uppdaterats`);
                dispatch(receiveUpdateBookedShift(resp));
            }, error => {
                message.error(error.responseJSON.message);
            });
    };
}

export function requestFetchShift() {
    return { type: 'REQUEST_FETCH_SHIFT' };
}

export function receiveFetchShift(shift) {
    return {
        type: 'RECEIVE_FETCH_SHIFT',
        shift,
    };
}

export function fetchShift(shiftId) {
    return dispatch => {
        dispatch(requestFetchShift());
        return request.fetchShift(shiftId)
            .then(resp => dispatch(receiveFetchShift(resp)));
    };
}

export function requestFetchEmployerShifts() {
    return { type: 'REQUEST_FETCH_EMPLOYER_SHIFTS' };
}

export function receiveFetchEmployerShifts(resp) {
    return {
        type: 'RECEIVE_FETCH_EMPLOYER_SHIFTS',
        shifts: resp.collection,
    };
}

export function fetchEmployerShifts(employerId) {
    return dispatch => {
        dispatch(requestFetchEmployerShifts());
        return request.fetchEmployerShifts(employerId)
            .then(resp => dispatch(receiveFetchEmployerShifts(resp)));
    };
}
