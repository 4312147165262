import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    CalendarOutlined,
    ScheduleOutlined,
} from '@ant-design/icons';
import {
    Avatar,
    Button,
    DatePicker,
    Tooltip,
} from 'antd';
import './styles.scss';

import TaskAssignedUser from 'containers/tasks/taskassigneduser';

const AddComment = ({ onSubmit }) => {
    const [content, setContent] = useState('');
    const [taskDueDate, setTaskDueDate] = useState();
    const [taskPlannedDate, setTaskPlannedDate] = useState();

    const [selectedKam, setSelectedKam] = useState();
    const [dueDateVisible, setDueDateVisible] = useState(false);
    const [plannedDateVisible, setPlannedDateVisible] = useState(false);

    const onSubmitHandler = () => {
        onSubmit({
            content,
            admin_id_assigned: selectedKam?.value,
            dueDateTime: taskDueDate,
            plannedDateTime: taskPlannedDate,
        }).then(() => {
            setContent('');
        });
    };

    return (

        <div
            className='addComment'
            style={{
                marginTop: 20, position: 'relative',
            }}
        >
            <textarea
                placeholder='Lägg till en kommentar...'
                rows={3}
                style={{
                    width: '100%',
                    resize: 'none',
                    borderColor: '#EEE',
                    borderBottom: 'none',
                    outline: 'none',
                    padding: 20,
                    borderRadius: '6px 6px 0 0',
                }}
                value={content}
                onChange={e => {
                    setContent(e.currentTarget.value);
                }}
            />
            <div
                style={{
                    backgroundColor: '#f9f9f9',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderRadius: '0 0 6px 6px',
                }}
            >
                <Tooltip title='Add kam'>
                    <TaskAssignedUser
                        onChange={kam => {
                            setSelectedKam(kam);
                        }}
                    />
                </Tooltip>
                <Tooltip title='Add due date'>
                    <Avatar
                        gap={4}
                        icon={!taskDueDate ? <CalendarOutlined /> : undefined}
                        onClick={() => setDueDateVisible(true)}
                    >
                        {taskDueDate ? (
                            taskDueDate.format('MMM-DD')
                        ) : undefined}
                    </Avatar>
                </Tooltip>
                <Tooltip title='Add planned date'>
                    <Avatar
                        gap={4}
                        icon={!taskPlannedDate ? <ScheduleOutlined /> : undefined}
                        onClick={() => setPlannedDateVisible(true)}
                    >
                        {taskPlannedDate ? (
                            taskPlannedDate.format('MMM-DD')
                        ) : undefined}
                    </Avatar>
                </Tooltip>
                {dueDateVisible ? (
                    <span
                        style={{
                            position: 'absolute', top: '50px', width: '120px',
                        }}
                    >
                        <DatePicker
                            autoFocus
                            getPopupContainer={trigger => trigger.parentElement}
                            open
                            value={taskDueDate}
                            onBlur={() => { setDueDateVisible(false); }}
                            onChange={v => {
                                setTaskDueDate(v);
                                setDueDateVisible(false);
                            }}
                        />
                    </span>
                ) : <></>}
                {plannedDateVisible ? (
                    <span
                        style={{
                            position: 'absolute', top: '50px', width: '120px',
                        }}
                    >
                        <DatePicker
                            autoFocus
                            getPopupContainer={trigger => trigger.parentElement}
                            open
                            value={taskPlannedDate}
                            onBlur={() => { setPlannedDateVisible(false); }}
                            onChange={v => {
                                setTaskPlannedDate(v);
                                setPlannedDateVisible(false);
                            }}
                        />
                    </span>
                ) : <></>}
                <Button
                    className='btn btn-primary'
                    disabled={!content}
                    type='primary'
                    onClick={onSubmitHandler}
                >
                    Spara
                </Button>

            </div>
        </div>

    );
};

AddComment.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
export default AddComment;
