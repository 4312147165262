const initialState = {
    sickLeavedConsultants: [],
    isFetchingSickLeavedConsultants: false,
    sickLeavedConsultant: null,
};

export function sickLeave(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_SICK_LEAVED_CONSULTANTS':
            return {
                isFetchingSickLeavedConsultants: true,
                sickLeavedConsultants: [],
            };

        case 'RECEIVE_SICK_LEAVED_CONSULTANTS':
            return {
                isFetchingSickLeavedConsultants: false,
                sickLeavedConsultants: action.collection,
            };

        case 'REQUEST_SICK_LEAVED_CONSULTANT':
            return {
                isFetchingSickLeavedConsultant: true,
                sickLeavedConsultant: null,
            };

        case 'RECEIVE_SICK_LEAVED_CONSULTANT':
            return {
                isFetchingSickLeavedConsultant: false,
                sickLeavedConsultant: action.sickLeavedConsultant,
            };

        default:
            return state;
    }
}
