import React from 'react';
import Cropper from 'react-cropper';
import Slider from 'components/shared/slider';
import 'cropperjs/dist/cropper.css';
import './styles.scss';

export default class CropImageModal extends React.Component {
    constructor(props) {
        super(props);

        this.onSave = this.onSave.bind(this);
    }

    onSave() {
        const image = this.refs.cropper.cropper.getCroppedCanvas({
            width: this.props.imageWidth,
            height: this.props.imageHeight,
        }).toDataURL();

        this.props.onSave(image);
        this.props.close();
    }

    render() {
        const {
            title,
            imageUrl,
            imageHeight,
            imageWidth,
            round,
            buttonText,
        } = this.props;

        return (
            <div className='mpa-drop-image-modal'>
                {title}

                <div
                    className='cropper-wrapper'
                    style={{
                        width: imageWidth,
                        height: imageHeight,
                        borderRadius: round ? imageWidth : '',
                        overflow: 'hidden',
                        margin: '0px auto',
                        marginTop: 50,
                        marginBottom: 50,
                    }}
                >
                    <Cropper
                        ref='cropper'
                        aspectRatio={imageWidth / imageHeight}
                        autoCrop
                        background={false}
                        crop={this.onCrop}
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        dragMode='move'
                        guides={false}
                        minCropBoxHeight={round ? imageHeight : imageWidth * 0.8}
                        minCropBoxWidth={round ? imageWidth : imageWidth * 0.8}
                        src={imageUrl}
                        style={{
                            height: imageHeight,
                            width: imageWidth,
                        }}
                        viewMode={3}
                    />
                </div>

                <div
                    style={{
                        textAlign: 'center',
                        margin: '20px auto 40px auto',
                        maxWidth: 300,
                    }}
                >
                    <Slider
                        max={100}
                        min={0}
                        step={1}
                        onChange={value => {
                            if (!this.initialZoomLevel) {
                                const canvasData = this.refs.cropper.cropper.getCanvasData();
                                const zoomRatio = canvasData.width / canvasData.naturalWidth;
                                this.initialZoomLevel = zoomRatio;
                            }

                            const zoomVal = (value / 100);

                            this.refs.cropper.cropper.zoomTo(zoomVal + this.initialZoomLevel);
                        }}
                    />

                    <button
                        className='btn btn-primary'
                        style={{ marginTop: 10 }}
                        onClick={() => {
                            this.onSave();
                        }}
                    >
                        {buttonText}
                    </button>
                </div>

            </div>
        );
    }
}
