import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import {
    Card,
    Select,
    Divider,
    Form,
    Spin,
    Alert,
} from 'antd';
import {
    orderBy,
    debounce,
} from 'lodash';
import { useSelector } from 'react-redux';
import config from '../../../config';
import FormInput from '../../CreateEmployer/FormInput';
import CopyProgress from '../../CreateEmployer/CopyProgress';
import CopyImages from '../../CreateEmployer/CopyImages';
import ModalFooter from './ModalFooter';

import '../../CreateEmployer/styles.scss';

const { Option } = Select;

function CreateEmployer({
    validateUsername,
    requestOngoing,
    setRequestOngoing,
    setIsModalVisible,
    onSubmit,
}) {
    const {
        createEmployer,
        settings,
    } = useSelector(state => state);

    const {
        counties,
        secondary_department_types: secondaryDepartmentTypes,
        emr_systems: emrSystems,
    } = settings;
    const {
        formData,
        isCopying,
        shouldCopyContacts,
        contacts,
        shouldCopyLogo,
        logo_url: logoUrl,
        shouldCopyCover,
        cover_url: coverUrl,
        shouldCopyCustomShifts,
        customShifts,
        shouldCopyProfiles,
        profiles,
        copyCoverSuccess,
        copyProfilesSuccess,
        copyContactsSuccess,
        copyLogoSuccess,
        copyCustomShiftsSuccess,
        errorMessage,
        usernameAvailable,
    } = createEmployer;
    const [form] = Form.useForm();

    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [currentCountryCode, setCurrentCountryCode] = useState(formData.address.country_code || 'SWE');

    const filteredCounties = counties.filter(
        county => county.country_code === currentCountryCode,
    );
    const filteredDepartmentTypes = secondaryDepartmentTypes.filter(
        type => type.country_code === currentCountryCode,
    );
    const filterOptions = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    useEffect(() => {
        Object.keys(formData).forEach(key => {
            const value = formData[key];
            if (value || typeof value === 'boolean') {
                form.setFieldsValue({ [key]: value });
            }
        });
    }, [formData, form]);

    useEffect(() => {
        if (errorMessage) {
            setRequestOngoing(false);
            setShouldBlockNavigation(true);
        }
    }, [errorMessage, setRequestOngoing]);

    useEffect(() => {
        const errorMessageIfExists = !usernameAvailable
            ? ['Username unavailable']
            : [];
        form.setFields([
            {
                name: 'username',
                errors: errorMessageIfExists,
            },
        ]);
    }, [usernameAvailable, form]);

    const copyInProgress = () => [
        shouldCopyContacts,
        shouldCopyLogo,
        shouldCopyCover,
        shouldCopyCustomShifts,
        shouldCopyProfiles,
    ].some(Boolean);

    const checkUsernameAvailability = ev => {
        const { target: { value } } = ev;
        const username = value;
        if (username.length) validateUsername(username);
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required',
        types: {
            email: 'Please enter a valid email address',
            name: 'Please enter a valid name',
        },
    };
    /* eslint-enable no-template-curly-in-string */

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleCheckUserNameAvailability = useCallback(debounce(checkUsernameAvailability, 500), []);

    return (
        <main className='create-employer'>
            <Prompt
                message='You have unsaved changes, are you sure you want to leave?'
                when={shouldBlockNavigation}
            />
            <Card>
                {requestOngoing && <Spin className='spinner' />}

                {shouldCopyCover || shouldCopyLogo ? (
                    <CopyImages
                        coverUrl={coverUrl}
                        logoUrl={logoUrl}
                        shouldCopyCover={shouldCopyCover}
                        shouldCopyLogo={shouldCopyLogo}
                    />
                ) : null}
                <Form
                    className='form'
                    form={form}
                    name='nest-messages'
                    validateMessages={validateMessages}
                    onChange={() => {
                        setShouldBlockNavigation(true);
                    }}
                    onFinish={onSubmit}
                    // onValuesChange={(changedValues, allValues) => {
                    //     setCreateEmployerData(allValues);
                    // }}
                >
                    <Divider orientation='left'>
                        <h2 className='form-heading'>Account Details</h2>
                    </Divider>

                    <FormInput
                        formDataKey='username'
                        label='Username'
                        rules={[{ required: true }]}
                        onChange={handleCheckUserNameAvailability}
                    />

                    <FormInput
                        formDataKey='description'
                        hidden
                        label='Description'
                        noStyle
                    />

                    <FormInput
                        formDataKey='password'
                        hasFeedback
                        isInline
                        label='Password'
                        rules={[{ required: true }]}
                        type='password'
                    />

                    <FormInput
                        dependencies={['password']}
                        formDataKey='password_retype'
                        hasFeedback
                        isInline
                        label='Confirm password'
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Lösenorden stämmer inte överens!'));
                                },
                            }),
                        ]}
                        type='password'
                    />

                    <FormInput
                        formDataKey='email'
                        isInline
                        label='Email'
                        rules={[{ type: 'email' },
                            { required: true },
                        ]}
                        type='email'
                    />
                    <FormInput
                        formDataKey='phone'
                        isInline
                        label='Phone number'
                    />

                    <FormInput
                        formDataKey='name'
                        label='Display name'
                        rules={[{ type: 'name' },
                            { required: true },
                        ]}
                    />

                    <Form.Item
                        className='form-item -inline'
                        label='Secondary department id'
                        name='secondary_department_type_id'
                        rules={[{ required: true }]}
                    >
                        <Select
                            filterOption={filterOptions}
                            optionFilterProp='label'
                            placeholder='Select department type'
                            showSearch
                            size='large'
                        >

                            {orderBy(filteredDepartmentTypes, 'name').map(({
                                id,
                                name,
                            }) => (<Option key={id} value={id}>{name}</Option>))}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        className='form-item -inline'
                        label='Emr system'
                        name='emr_system'
                        rules={[{ required: true }]}
                    >
                        <Select
                            filterOption={filterOptions}
                            optionFilterProp='label'
                            placeholder='Select an emr system'
                            showSearch
                            size='large'
                        >
                            {orderBy(emrSystems, 'name').map(
                                emrSystem => <Option key={emrSystem.id} value={emrSystem.id}>{emrSystem.name}</Option>,
                            )}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        className='form-item -inline'
                        label='Maintenance'
                        name='automotive'
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder='Choose maintenance'
                            size='large'
                        >

                            {[{
                                name: 'Automotive',
                                value: 'automotive',
                                id: 1,
                            }, {
                                name: 'Maintained',
                                value: 'maintained',
                                id: 2,
                            }].map(a => <Option key={a.id} value={a.value}>{a.name}</Option>)}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        className='form-item -inline'
                        label='Employer type'
                        name='employer_type'
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder='Choose employer type'
                            size='large'
                        >
                            {[{
                                name: 'Public',
                                value: 1,
                                id: 1,
                            },
                            {
                                name: 'Private',
                                value: 2,
                                id: 2,
                            }].map(a => <Option key={a.id} value={a.value}>{a.name}</Option>)}

                        </Select>
                    </Form.Item>
                    <Form.Item
                        className='form-item -inline'
                        label='Sales Person'
                        name='admin_user_id'
                    >
                        <Select
                            placeholder='Choose sales person'
                            size='large'
                        >
                            {config.KAM.map(a => <Option key={a.value} value={a.value}>{a.title}</Option>)}

                        </Select>
                    </Form.Item>
                    <Form.Item
                        className='form-item -inline'
                        label='Protected'
                        name='isprotected'
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder='Choose if employer is protected'
                            size='large'
                        >
                            {config.isProtected.map(a => <Option key={a.id} value={a.value}>{a.title}</Option>)}

                        </Select>
                    </Form.Item>

                    <Divider orientation='left'>
                        <h2 className='form-heading'>Address</h2>
                    </Divider>

                    <FormInput
                        formDataKey={['address', 'addressline1']}
                        label='Address'
                        rules={[{ required: true }]}
                    />
                    <FormInput
                        formDataKey={['address', 'addressline2']}
                    />

                    <FormInput
                        formDataKey={['address', 'postalcode']}
                        isInline
                        label='Postal code'
                        rules={[
                            { required: true },
                        ]}
                    />

                    <FormInput
                        formDataKey={['address', 'city']}
                        isInline
                        label='City'
                        rules={[{ required: true }]}
                    />

                    <Form.Item
                        className='form-item -inline'
                        label='Country'
                        name={['address', 'country_code']}
                    >
                        <Select
                            placeholder='Select a country'
                            size='large'
                            onChange={value => {
                                setCurrentCountryCode(value);
                                form.setFieldsValue({
                                    secondary_department_type_id: null,
                                    county_id: null,
                                });
                            }}
                        >
                            {config.countries.map(({
                                id,
                                value,
                                name,
                            }) => (<Option key={id} value={value}>{name}</Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        className='form-item -inline'
                        label='County'
                        name='county_id'
                        rules={[{ required: true }]}
                    >
                        <Select
                            filterOption={filterOptions}
                            optionFilterProp='label'
                            placeholder='Select a county'
                            showSearch
                            size='large'
                        >
                            {orderBy(filteredCounties, 'name')
                                .map(({
                                    id,
                                    name,
                                }) => (<Option key={id} value={id}>{name}</Option>))}

                        </Select>
                    </Form.Item>

                    <Divider
                        orientation='left'
                    >
                        <h2 className='form-heading'>Invoice address</h2>
                    </Divider>

                    <FormInput
                        formDataKey={['invoice_address', 'name']}
                        isInline
                        label='Name for invoice'
                    />

                    <FormInput
                        formDataKey='organization_number'
                        isInline
                        label='Organization number'
                        rules={[{ required: true }]}
                    />

                    <FormInput
                        formDataKey={['invoice_address', 'department']}
                        isInline
                        label='Department'
                    />

                    <FormInput
                        formDataKey={['invoice_address', 'reference']}
                        isInline
                        label='Reference'
                    />

                    <FormInput
                        formDataKey={['invoice_address', 'addressline1']}
                        label='Address'
                        rules={[{ required: true }]}
                    />

                    <FormInput
                        formDataKey={['invoice_address', 'addressline2']}
                    />

                    <FormInput
                        formDataKey={['invoice_address', 'postalcode']}
                        isInline
                        label='Postal code'
                        rules={[
                            { required: true },
                        ]}
                    />

                    <FormInput
                        formDataKey={['invoice_address', 'city']}
                        isInline
                        label='City'
                        rules={[{ required: true }]}
                    />

                    <Form.Item
                        className='form-item -inline'
                        label='Country'
                        name={['invoice_address', 'country_code']}
                    >
                        <Select
                            placeholder='Select a country'
                            size='large'
                        >
                            {config.countries.map(({
                                id,
                                value,
                                name,
                            }) => (<Option key={id} value={value}>{name}</Option>))}
                        </Select>
                    </Form.Item>

                    <Divider orientation='left'>
                        <h2 className='form-heading'>Contact Person</h2>
                    </Divider>

                    <FormInput
                        formDataKey='account_name'
                        isInline
                        label='Contact name'
                    />
                    <FormInput
                        formDataKey='account_title'
                        isInline
                        label='Title'
                    />
                    <FormInput
                        formDataKey='account_email'
                        isInline
                        label='Contact email'
                        rules={[{ type: 'email' }, { required: true }]}
                        type='email'
                    />
                    <FormInput
                        formDataKey='account_phone'
                        isInline
                        label='Phone number'
                    />
                    <ModalFooter
                        cancelAction={() => setIsModalVisible(false)}
                        cancelText='Cancel'
                        isOkSubmit
                        okText='Save'
                    />
                </Form>
                {requestOngoing && copyInProgress
                    ? (
                        <CopyProgress
                            copyItems={[
                                {
                                    copy: shouldCopyContacts,
                                    text: 'Copying contactperson',
                                    done: copyContactsSuccess,
                                },
                                {
                                    copy: shouldCopyLogo,
                                    text: 'Copying logo',
                                    done: copyLogoSuccess,
                                },
                                {
                                    copy: shouldCopyCover,
                                    text: 'Copying cover image',
                                    done: copyCoverSuccess,
                                },
                                {
                                    copy: shouldCopyCustomShifts,
                                    text: 'Copying working hours',
                                    done: copyCustomShiftsSuccess,
                                },
                                {
                                    copy: shouldCopyProfiles,
                                    text: 'Copying profile',
                                    done: copyProfilesSuccess,
                                },
                            ]}
                        />
                    ) : null}
            </Card>
            {errorMessage ? (
                <Alert
                    className='alert'
                    closable
                    message={errorMessage}
                    showIcon
                    type='error'
                />
            ) : null}

        </main>
    );
}

CreateEmployer.propTypes = {
    setRequestOngoing: PropTypes.func.isRequired,
    requestOngoing: PropTypes.bool.isRequired,
    validateUsername: PropTypes.func.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CreateEmployer;
