const initialState = {
    isFetching: true,
    orders: [],
    page: 0,
    totalCount: 0,
    totalPages: 0,
    query: '',
    sortField: '',
    sortOrder: '',
};

export function orders(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_ORDERS':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_FETCH_ORDERS':
            return {
                ...state,
                isFetching: false,
                orders: action.orders,
                page: action.page,
                totalCount: action.totalCount,
                totalPages: action.totalPages,
            };
        case 'REQUEST_FETCH_ORDER':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_FETCH_ORDER':
            return {
                ...state,
                isFetching: false,
                order: action.order,
            };
        case 'SET_PAGE':
            return {
                ...state,
                page: action.page,
            };
        case 'REQUEST_UPDATE_ORDER':
            return {
                ...state,
                isUpdating: true,
            };
        case 'RECEIVE_UPDATE_ORDER':
            return {
                ...state,
                isUpdating: false,
                order: action.order,
            };
        case 'DELETE_ORDER':
            return {
                ...state,
                orders: state.orders.filter(order => order.id !== action.id),
            };
        case 'SET_PAGE_SORTING': {
            const isStartSorting = (!state.sortField && action.sortField);
            const isModifySorting = (state.sortField
                        && (action.sortField !== state.sortField || action.sortOrder !== state.sortOrder));

            return {
                ...state,
                sortField: action.sortField,
                sortOrder: action.sortOrder,
                // reset page on sorting start or on sorting criteria change
                page: isStartSorting || isModifySorting ? 0 : action.page,
            };
        }
        default:
            return state;
    }
}
