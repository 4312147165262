import React, { useEffect, useState, useMemo } from 'react';
import Avatar from 'components/shared/avatar';
import { updateTask } from 'actions/tasks';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Dropdown, Space } from 'antd';
import moment from 'moment';

const DESCENDING = 1
const ASCENDING = 0

const STATUS_COLORS = {
  ALL: { 
    color: 'bg-white',
    name: 'All status'
  },
  OPEN: {
    name: 'Open',
    color: 'bg-sky-200'
  },
  ONGOING: {
    name: 'Ongoing',
    color: 'bg-orange-200'
  },
  COMPLETED: {
    name: 'Completed',
    color: 'bg-green-200'
  },
  CLOSED: {
    name: 'Closed',
    color: 'bg-red-200'
  },
}


const TaskList = ({ onClick, selectedConsultantTasks, selectedEmployerTasks, selectedLeadTasks, onClearAllTasks }) => {
  const [sortOrder, setSortOrder] = useState(DESCENDING);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(Object.keys(STATUS_COLORS)[1]);
  const [selectedAdminUser, setSelectedAdminUser] = useState(-1);
  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state);

  useEffect(() => {
    if(tasks.tasks) {
      const admins = tasks.tasks.reduce((acc, { admin_username, admin_id_created}) => {
        if(acc.find(({ key }) => key === admin_id_created)) return acc;
        acc = [...acc, {
          key: admin_id_created,
          label: <span className='text-xs p-1' onClick={() => setSelectedAdminUser(admin_id_created)}>{admin_username}</span>
        }]
        return acc;
      }, [{ key: 'ALL', label: <span className='text-xs p-1' onClick={() => setSelectedAdminUser(-1)}>All admins</span> }])
      setAdminUsers(admins);
      setSortedTasks(tasks.tasks);
    }
  }, [tasks])

  if (!tasks.hasFetched) return null;

  const currentTimeStamp = moment().unix();

  const onUpdate = (id, status) => {
    dispatch(updateTask(id, { status }));
  }

  const renderStatus = (status, id) => {
    const items = Object.keys(STATUS_COLORS).map((key) => {
      if(key === 'ALL') return null;
      const {color, name} = STATUS_COLORS[key] 
      return {
        key,
        label: <span className={`text-xs p-1 ${color}`} onClick={() => onUpdate(id, key)}>{name}</span>
      }
    });
    const {color, name} = STATUS_COLORS[status]
    return (<Dropdown
        menu={{ items }}
        key="Kapp"
        trigger={['click']}
        className="cursor-pointer"
        style={{ width: 'auto' }}
      >
        <Space onClick={(e) => e.preventDefault()} className={`rounded text-xs p-1 ${color}`}>
          {name}
        </Space>
      </Dropdown>
    )
  }

  const filterOnStatus = () => {
    const items = Object.keys(STATUS_COLORS).map((key) => {
      const { color, name } = STATUS_COLORS[key] 
      return {
        key,
        label: <span className={`text-xs p-1 ${color}`} onClick={() => setSelectedStatus(key)}>{name}</span>
      }
    });

    const { color, name } = STATUS_COLORS[selectedStatus];
    return (<Dropdown
        menu={{ items }}
        key="status"
        trigger={['click']}
        className="cursor-pointer"
        style={{ width: 'auto' }}
      >
        <Space onClick={(e) => e.preventDefault()} className={`rounded text-xs p-1 ${color}`}>
          {name}
        </Space>
      </Dropdown>
    )
  }

  const filterOnAdminUsers = (obj) => {
    return (<Dropdown
      menu={{ items: adminUsers }}
      key="adminUsers"
      trigger={['click']}
      className="cursor-pointer ml-1"
      style={{ width: 'auto' }}
    >
      <Space onClick={(e) => e.preventDefault()} className='rounded text-xs p-1 bg-orange-400'>
        Admins
      </Space>
    </Dropdown>
  )
  }

  const sortTaskByPlannedDate = (a, b) => {
    if(sortOrder === DESCENDING) {
      setSortOrder(ASCENDING)
      return a.planned_date_time - b.planned_date_time
    }
    setSortOrder(DESCENDING)
    return b.planned_date_time - a.planned_date_time
  }

  const sortTaskByDueDate = (a, b) => {
    if(sortOrder === DESCENDING) {
      setSortOrder(ASCENDING)
      return a.due_date_time - b.due_date_time
    }
    setSortOrder(DESCENDING)
    return b.due_date_time - a.due_date_time
  }

  const onSortByPlannedDate = () => {
    setSortedTasks([...tasks.tasks.sort(sortTaskByPlannedDate)])
  }

  const onSortByDueDate = () => {
    setSortedTasks([...tasks.tasks.sort(sortTaskByDueDate)])
  }

  const filterTasksExis = (selectedConsultantTasks.length > 0 || selectedEmployerTasks.length > 0 || selectedLeadTasks.length > 0)

  const filterByDates = (acc, task) => {
    const plannedDateIsPassed = moment(task.planned_date_time).isBefore(currentTimeStamp);
    const dueDateIsPassed = moment(task.due_date_time).isAfter(currentTimeStamp);

    if(task.status !== selectedStatus && selectedStatus !== Object.keys(STATUS_COLORS)[0]) return acc;
    if(selectedAdminUser !== -1 && task.admin_id_created !== selectedAdminUser) return acc;

    if(filterTasksExis) {
      if(task.user && (selectedEmployerTasks.length > 0 && selectedLeadTasks.length > 0) && selectedConsultantTasks.length === 0) return acc;
      if(task.employer && (selectedConsultantTasks.length > 0 && selectedLeadTasks.length > 0) && selectedEmployerTasks.length === 0) return acc;
      if(task.lead && (selectedConsultantTasks.length > 0 && selectedEmployerTasks.length > 0) && selectedLeadTasks.length === 0) return acc;
      if(task.user) {
        const userExist = selectedConsultantTasks.some(({ user_id }) => user_id === task.user.id)
        if(!userExist) return acc;
      }
      if(task.employer) {
        const employerExist = selectedEmployerTasks.some(({ employer_id }) => employer_id === task.employer.id)
        if(!employerExist) return acc;
      }
      if(task.employer_lead_id) {
        const leadExist = selectedLeadTasks.some(({ employer_lead_id }) => employer_lead_id === task.employer_lead_id)
        if(!leadExist) return acc;
      }
    }

    if(plannedDateIsPassed) {
      acc = [...acc,       
        task = {
          ...task,
          isOverDue: (!dueDateIsPassed && task.status === 'CLOSED')
        }
      ]
    }
    return acc;
  }
  

  const renderTasks = sortedTasks.reduce(filterByDates, []).map((task) => {
    const { title, user, id, planned_date_time, due_date_time, status, type, admin_username, employer } = task;
    const { avatar, firstname, lastname, phone } = user || {}
    const plannedDate = planned_date_time ? moment.unix(planned_date_time).format('YYYY-MM-DD') : null;
    const dueDate = due_date_time ? moment.unix(due_date_time).format('YYYY-MM-DD') : null;
    const bgColor = task.isOverDue ? 'bg-red-400' : 'bg-white'
    
    return (
      <div key={id} className={`flex items-start my-2 p-2 ${bgColor}`}>
        <div className='mr-2'>
          <Avatar
          size={30}
          url={avatar}
        />
        </div>
        <div className='flex flex-col flex-1'>
          <div className='font-semibold mb-1 cursor-pointer' onClick={() => onClick(task)}>{title}</div>
          { user && <div className='flex'>
            <span className='text-xs mr-1'>User id #{user.id}</span>
            <span className='text-xs mr-1'>{phone}</span>
            <span className='text-xs mr-1'>{firstname}</span>
            <span className='text-xs'>{lastname}</span>
          </div>}
          {employer && <span className='text-xs mr-1'>Employer id #{employer.id}</span>}
          {plannedDate && <div className='text-xs'>
            <span className='font-semibold mr-1'>Planned date:</span>{plannedDate}
          </div>}
          {dueDate && <div className='text-xs'>
            <span className='font-semibold mr-1'>Due date:</span>{dueDate}
          </div>}
          {admin_username && <div className='text-xs'>
            <span className='font-semibold mr-1'>Creator:</span>{admin_username}
          </div>}
        </div>
        <div className='flex flex-col'>
          {renderStatus(status, id)}
          <div>{ type === 'GRADUATE' && <div className='text-xs p-1 mt-1 rounded bg-blue-200'>Graduate</div>}</div>
        </div>
      </div>
    )
  });
  const renderFilter = useMemo(filterOnStatus, [selectedStatus])
  const renderAdminUsers = useMemo(filterOnAdminUsers, [adminUsers])
  
  return (
      <div>
        <div className='flex'>
          <Button type='primary' onClick={onSortByPlannedDate} className='mr-1'>Sort by planning date</Button>
          <Button className='mr-1' type='primary' onClick={onSortByDueDate}>Sort by due date</Button>
          {renderFilter}
          {renderAdminUsers}
          {filterTasksExis && <Button className='ml-1' onClick={onClearAllTasks}>Show all tasks</Button>}
        </div>
        {renderTasks}
      </div>
  );
};

export default TaskList;
