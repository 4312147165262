const initialState = {
    salaryErrorList: [],
    isFetching: true,
};

export function salaryErrorList(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_SALARY_ERROR_LIST':
            return {
                ...state,
                salaryErrorList: action.collection,
                isFetching: false,
            };

        default:
            return state;
    }
}
