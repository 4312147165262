import React from 'react';
import {
    Form,
    Input,
} from 'antd';
import PropTypes from 'prop-types';

const FormInput = ({
    label,
    type = 'text',
    hidden = false,
    noStyle = false,
    formDataKey,
    isInline = false,
    onChange,
    rules,
    dependencies,
    hasFeedback,
}) => (
    <Form.Item
        className={isInline ? 'form-item -inline' : 'form-item'}
        dependencies={dependencies}
        fieldKey={formDataKey}
        hasFeedback={hasFeedback}
        label={label}
        name={formDataKey}
        noStyle={noStyle}
        rules={rules}
    >
        <Input
            hidden={hidden}
            size='large'
            type={type}
            onChange={e => {
                e.persist();
                return onChange && onChange(e);
            }}
        />
    </Form.Item>
);

FormInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    formDataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isInline: PropTypes.bool,
    rules: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
        message: PropTypes.string,
        required: PropTypes.bool,
    }),
    PropTypes.func,
    ])),
    dependencies: PropTypes.arrayOf(PropTypes.string),
    hasFeedback: PropTypes.bool,
    onChange: PropTypes.func,
};

export default FormInput;
