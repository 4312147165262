import { filter } from 'lodash';

const initialState = {
    isUpdatingShift: false,
    shiftHasBeenUpdated: false,
    isFetchingShift: false,
    isRegisteringSick: false,
    isFetchingEmployerShifts: false,
    deletingShift: false,
    isDeletingShifts: false,
    isCreatingShifts: false,
    isUpdatingBookedShift: false,
    bookedShiftHasBeenUpdated: false,
    removingShiftFromApplication: false,
    employerShifts: [],
};

export function shifts(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_UPDATE_SHIFT':
            return {
                ...state,
                isUpdatingShift: true,
                shiftHasBeenUpdated: false,
            };

        case 'RECEIVE_UPDATE_SHIFT':
            return {
                ...state,
                isUpdatingShift: false,
                shiftHasBeenUpdated: true,
                shift: {
                    ...state.shift,
                    ...action.shift,
                },
            };

        case 'REQUEST_UPDATE_BOOKED_SHIFT':
            return {
                ...state,
                isUpdatingBookedShift: true,
                bookedShiftHasBeenUpdated: false,
            };

        case 'RECEIVE_UPDATE_BOOKED_SHIFT':
            return {
                ...state,
                isUpdatingBookedShift: false,
                bookedShiftHasBeenUpdated: true,
                shift: {
                    ...state.shift,
                    scheduled: [
                        action.shift,
                    ],
                },
            };

        case 'REQUEST_FETCH_SHIFT':
            return {
                ...state, isFetchingShift: true,
            };

        case 'RECEIVE_FETCH_SHIFT':
            return {
                ...state,
                isFetchingShift: false,
                shift: action.shift,
            };

        case 'REQUEST_DELETE_EMPLOYER_SHIFT':
            return {
                ...state,
                employerShifts: filter(state.employerShifts, shift => shift.id !== action.shift),
                deletingShift: true,
            };

        case 'RECEIVE_DELETE_EMPLOYER_SHIFT':
            return {
                ...state,
                employerShifts: filter(state.employerShifts, shift => shift.id !== action.shift),
                deletingShift: false,
            };

        case 'REQUEST_DELETE_EMPLOYER_SHIFTS':
            return {
                ...state, isDeletingShifts: true,
            };

        case 'RECEIVE_DELETE_EMPLOYER_SHIFTS':
            return {
                ...state,
                employerShifts: filter(state.employerShifts, shift => action.shifts.indexOf(shift.id) === -1),
                isDeletingShifts: false,
            };

        case 'REQUEST_FETCH_EMPLOYER_SHIFTS':
            return {
                ...state, isFetchingEmployerShifts: true,
            };

        case 'RECEIVE_FETCH_EMPLOYER_SHIFTS':
            return {
                ...state,
                isFetchingEmployerShifts: false,
                employerShifts: action.shifts,
            };

        case 'REQUEST_CREATE_SHIFTS':
            return {
                ...state,
                isCreatingShifts: true,
            };

        case 'RECEIVE_CREATE_SHIFTS':
            return {
                ...state, isCreatingShifts: false,
            };

        case 'REQUEST_REMOVE_SHIFT_FROM_CONSULTANT_APPLICATION':
            return {
                ...state, removingShiftFromApplication: true,
            };

        case 'REMOVE_SHIFT_FROM_CONSULTANT_APPLICATION_SUCCESS':
            return {
                ...state,
                employerShifts: state.employerShifts.map(shift => {
                    if (shift.id === action.shiftId) {
                        return {
                            ...shift,
                            scheduled: shift.scheduled.filter(application => application.application_id !== action.applicationId),
                        };
                    }

                    return shift;
                }),
                removingShiftFromApplication: false,
            };

        default:
            return state;
    }
}
