import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    useParams,
    withRouter,
} from 'react-router-dom';
import {
    checkShifts,
    removeEmployerShifts,
    fetchEmployersAllConsultants,
    createMultipleShifts,
    createAndAddMultipleShiftsToConsultant,
} from 'actions/employer';
import { fetchConsultants } from 'actions/consultants';
import { removeShiftsFromBookedApplicationAndDeleteShifts } from 'actions/applications';
import { fetchEmployerShifts } from 'actions/shifts';
import CreateShiftsListModal from 'components/createshiftslistmodal';

import {
    Modal,
    Button,
} from 'antd';
import Loader from 'components/shared/loader';
import DeleteUnbookedShiftsModal from 'components/deleteunbookedshiftsmodal';
import EditMarginsModal from 'components/editmarginsmodal';

import EmployerShiftsItems from './employershiftsitems';
import AllConsultants from './allconsultants';

import ContactConsultantsModal from './contactconsultantsmodal';

function mapStateToProps(state) {
    const ready = (!state.shifts.isFetchingEmployerShifts && state.employer.profilesFetched && state.employer.allConsultantsFetched);

    return {
        ready,
        employer: state.employer.employer,
        profiles: state.employer.profiles,
        employerShifts: state.shifts.employerShifts,
        consultants: state.consultants.consultants,
        allConsultants: state.employer.allConsultants,
        isFetchingEmployerShifts: state.shifts.isFetchingEmployerShifts,
        isDeletingShifts: state.shifts.isDeletingShifts,
        isCreatingShifts: state.shifts.isCreatingShifts,
    };
}

const EmployerShifts = ({
    ready,
    consultants,
    allConsultants,
    checkShifts,
    employer,
    isFetchingEmployerShifts,
    removeEmployerShifts,
    fetchEmployersAllConsultants,
    fetchEmployerShifts,
    profiles,
    createMultipleShifts,
    fetchConsultants,
    createAndAddMultipleShiftsToConsultant,
    employerShifts,
    removeShiftsFromBookedApplicationAndDeleteShifts,
    isDeletingShifts,
    isCreatingShifts,
}) => {
    const [showShiftsListModal, setShowShiftsListModal] = useState(false);
    const [showDeleteShiftsModal, setShowDeleteShiftsModal] = useState(false);
    const [showEditMarginsModal, setShowEditMarginsModal] = useState(false);
    const [contactModalVisible, setContactModalVisible] = useState(false);

    const { id } = useParams();
    useEffect(() => {
        fetchEmployersAllConsultants(id);
    }, [id, fetchEmployersAllConsultants]);

    useEffect(() => {
        fetchEmployerShifts(id);
    }, [id, fetchEmployerShifts]);

    const createShiftListModalRef = useRef(null);

    useEffect(() => {
        if (!showShiftsListModal && createShiftListModalRef.current) {
            createShiftListModalRef.current.resetState();
        }
    }, [showShiftsListModal, fetchEmployerShifts, id]);

    const renderShiftsListModal = () => (
        <Modal
            footer={null}
            title='Skapa/Ta bort pass från lista'
            visible={showShiftsListModal}
            width='80%'
            onCancel={() => {
                setShowShiftsListModal(false);
            }}
            onOk={() => {
                setShowShiftsListModal(false);
            }}
        >
            <CreateShiftsListModal
                ref={createShiftListModalRef}
                checkShifts={checkShifts}
                close={() => {
                    setShowShiftsListModal(false);
                }}
                consultants={consultants}
                createAndAddMultipleShiftsToConsultant={createAndAddMultipleShiftsToConsultant}
                createMultipleShifts={createMultipleShifts}
                customShifts={employer.settings.custom_shifts}
                deleteShifts={removeEmployerShifts}
                employerConsultants={allConsultants.collection.map(c => c.user)}
                employerId={id}
                employerShifts={employerShifts}
                fetchConsultants={fetchConsultants}
                isCreatingShifts={isCreatingShifts}
                isDeletingShifts={isDeletingShifts}
                profiles={profiles.profiles.filter(profile => !profile.is_long_term && profile.status !== 2)}
                refreshCreatedShiftsList={() => fetchEmployerShifts(id)}
                removeShiftsFromBookedApplicationAndDeleteShifts={removeShiftsFromBookedApplicationAndDeleteShifts}
                test_account={employer.test_employer}
            />
        </Modal>
    );

    const renderDeleteShiftsModal = () => (
        <Modal
            footer={null}
            title='Delete shifts'
            visible={showDeleteShiftsModal}
            width='95%'
            onCancel={() => {
                setShowDeleteShiftsModal(false);
            }}
            onOk={() => {
                setShowDeleteShiftsModal(false);
            }}
        >
            <DeleteUnbookedShiftsModal
                close={() => {
                    setShowDeleteShiftsModal(false);
                }}
                employer={employer}
                employerShifts={employerShifts}
                removeEmployerShifts={removeEmployerShifts}
                visible={showDeleteShiftsModal}
            />
        </Modal>
    );

    const renderEditMarginsModal = () => (
        <EditMarginsModal
            close={() => {
                setShowEditMarginsModal(false);
            }}
            employer={employer}
            employerId={employer.id}
            employerShifts={employerShifts}
            visible={showEditMarginsModal}
        />
    );

    if (!ready) {
        return (
            <Loader />
        );
    }

    return (
        <div>
            <div style={{ textAlign: 'right' }}>
                <div
                    style={{
                        // padding: '20px',
                        paddingRight: 0,
                        marginRight: 40,
                        marginTop: 40,
                    }}
                >

                    <Button
                        size='large'
                        style={{ marginRight: 10 }}
                        onClick={() => { setContactModalVisible(true); }}
                    >
                        <i className='fa fa-users' style={{ paddingRight: 5 }} />
                        {' '}
                        Contact consultants
                    </Button>
                    <Button
                        size='large'
                        style={{
                            margin: 0,
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            setShowDeleteShiftsModal(true);
                        }}
                    >
                        <i className='fa fa-trash' style={{ paddingRight: 5 }} />
                        {' '}
                        Delete shifts
                    </Button>

                    <Button
                        size='large'
                        style={{
                            margin: 0,
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            setShowEditMarginsModal(true);
                        }}
                    >
                        <i className='fa fa-pencil' style={{ paddingRight: 5 }} />
                        {' '}
                        Bulk edit shifts
                    </Button>

                    <Button
                        size='large'
                        style={{
                            margin: 0,
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            setShowShiftsListModal(true);
                        }}
                    >
                        <i className='fa fa-bars' style={{ paddingRight: 5 }} />
                        {' '}
                        Create/Remove shifts from list
                    </Button>

                </div>
            </div>
            <div
                style={{ margin: '0 40px 40px' }}
            >
                <AllConsultants
                    allConsultants={allConsultants}
                    id={id}
                />
                {!isFetchingEmployerShifts
                    ? (
                        <EmployerShiftsItems
                            employer={employer}
                        />
                    )
                    : (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 30,
                                paddingTop: 40,
                                paddinBottom: 40,
                            }}
                        >
                            <i className='fa fa-circle-o-notch fa-spin' />
                        </div>
                    )}
            </div>
            {renderShiftsListModal()}
            {renderDeleteShiftsModal()}
            {renderEditMarginsModal()}
            <ContactConsultantsModal
                allConsultants={allConsultants}
                close={() => { setContactModalVisible(false); }}
                consultants={consultants}
                employer={employer}
                fetchConsultants={fetchConsultants}
                profiles={profiles}
                visible={contactModalVisible}
            />
        </div>
    );
};

EmployerShifts.propTypes = {
    ready: PropTypes.bool.isRequired,
    consultants: PropTypes.arrayOf(PropTypes.shape({})),
    allConsultants: PropTypes.shape({ collection: PropTypes.arrayOf(PropTypes.shape({
        added: PropTypes.number.isRequired,
        introduced: PropTypes.number.isRequired,
        user: PropTypes.shape({}),
    })) }),
    employer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        address: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        test_employer: PropTypes.bool.isRequired,
        settings: PropTypes.shape({
            custom_shifts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequire,
                name: PropTypes.string.isRequired,
            }).isRequired),
        }),
    }),
    isFetchingEmployerShifts: PropTypes.bool.isRequired,
    isDeletingShifts: PropTypes.bool.isRequired,
    removeEmployerShifts: PropTypes.func.isRequired,
    fetchEmployersAllConsultants: PropTypes.func.isRequired,
    fetchEmployerShifts: PropTypes.func.isRequired,
    profiles: PropTypes.shape({ profiles: PropTypes.arrayOf(PropTypes.shape({})) }),
    createMultipleShifts: PropTypes.func.isRequired,
    fetchConsultants: PropTypes.func.isRequired,
    createAndAddMultipleShiftsToConsultant: PropTypes.func.isRequired,
    employerShifts: PropTypes.arrayOf(PropTypes.shape({})),
    isCreatingShifts: PropTypes.bool.isRequired,
};

export default withRouter(connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        checkShifts,
        fetchEmployerShifts,
        removeEmployerShifts,
        fetchEmployersAllConsultants,
        createMultipleShifts,
        fetchConsultants,
        createAndAddMultipleShiftsToConsultant,
        removeShiftsFromBookedApplicationAndDeleteShifts,
    }, dispatch),
)(EmployerShifts));
