const initialState = {
    shifts: [],
    isFetching: false,
    hasFetched: false,
};

const availableShifts = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_SHIFTS':
            return {
                ...state, isFetching: true,
            };

        case 'RECEIVE_FETCH_SHIFTS':
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                shifts: action.shifts,
            };

        default:
            return { ...state };
    }
};

export default availableShifts;
