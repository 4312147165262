import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    batchReportScheduled,
    fetchEmployerAttests,
} from 'actions/employer_attests';

import AttestGroups from './attestgroups';

import './styles.scss';

const EmployerAttests = ({
    employer_attests,
    isUpdatingScheduled,
    fetchEmployerAttests,
    batchReportScheduled,
}) => {
    useEffect(() => { fetchEmployerAttests(); }, []);
    return (
        <main className='employer-attests'>
            <h1 className='heading'>Attests</h1>
            <AttestGroups
                batchReportScheduled={batchReportScheduled}
                employerAttests={employer_attests}
                isUpdatingScheduled={isUpdatingScheduled}
            />
        </main>
    );
};
EmployerAttests.propTypes = {
    employer_attests: PropTypes.arrayOf(PropTypes.object),
    fetchEmployerAttests: PropTypes.func,
    batchReportScheduled: PropTypes.func,
};

export default connect(
    state => ({
        employer_attests: state.employer_attests.employer_attests,
        isUpdatingScheduled: state.employer_attests.isUpdatingScheduled,
    }),
    dispatch => bindActionCreators({
        batchReportScheduled,
        fetchEmployerAttests,
    }, dispatch),
)(EmployerAttests);
