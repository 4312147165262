import {
    find,
    indexOf,
    without,
} from 'lodash';

const initialState = {
    consultants: [],
    count: 0,
    page: 0,
    totalCount: 0,
    totalPages: 0,
    consultantsFetching: false,
    hasFetched: false,
    removeConsultantFromBookedShiftSuccess: false,
    settingIntercomTag: false,
    receiveConsultantsCallList: false,
    isFetching: false,
};

const addTagToConsultant = (state, action) => {
    const {
        tagId,
        consultantId,
    } = action;
    const consultant = find(state.consultants, { id: consultantId });
    const index = indexOf(state.consultants, consultant);
    if (!consultant) {
        return { ...state };
    }
    return {
        ...state,
        consultants: [
            ...state.consultants.slice(0, index),
            {
                ...consultant,
                tags: [...consultant.tags, tagId],
            },
            ...state.consultants.slice(index + 1),
        ],
    };
};

const removeTagFromConsultant = (state, action) => {
    const {
        tagId,
        consultantId,
    } = action;
    const consultant = find(state.consultants, { id: consultantId });
    const index = indexOf(state.consultants, consultant);
    if (!consultant) {
        return { ...state };
    }
    return {
        ...state,
        consultants: [
            ...state.consultants.slice(0, index),
            {
                ...consultant,
                tags: without(consultant.tags, tagId),
            },
            ...state.consultants.slice(index + 1),
        ],
    };
};

export const consultants = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_CONSULTANTS':
            return {
                ...state,
                hasFetched: false,
                isFetching: true,
                consultantsFetching: true,
            };
        case 'RECEIVE_CONSULTANTS_CALL_LIST':
            return {
                ...state,
                receiveConsultantsCallList: true,
            };

        case 'RECEIVE_CONSULTANTS':
            return {
                ...state,
                consultants: action.consultants,
                count: action.count,
                page: action.page,
                totalCount: action.totalCount,
                totalPages: action.totalPages,
                consultantsFetching: false,
                hasFetched: true,
                isFetching: false,
            };

        case 'RECEIVE_ADD_TAG_TO_CONSULTANT':
            return addTagToConsultant(state, action);

        case 'RECEIVE_REMOVE_TAG_FROM_CONSULTANT':
            return removeTagFromConsultant(state, action);

        case 'REQUEST_REMOVE_CONSULTANT_FROM_BOOKED_SHIFT':
            return {
                ...state,
                removeConsultantFromBookedShiftSuccess: false,
            };

        case 'REMOVE_CONSULTANT_FROM_BOOKED_SHIFT_FAILED':
            return {
                ...state,
                removeConsultantFromBookedShiftSuccess: false,
            };

        case 'REMOVE_CONSULTANT_FROM_BOOKED_SHIFT_SUCCESS':
            return {
                ...state,
                removeConsultantFromBookedShiftSuccess: true,
            };

        case 'REQUEST_SET_TAG_ON_USERS_IN_INTERCOM':
            return {
                ...state,
                settingIntercomTag: true,
            };
        case 'SET_TAG_ON_USERS_IN_INTERCOM_SUCCESS':
        case 'SET_TAG_ON_USERS_IN_INTERCOM_FAIL':
            return {
                ...state,
                settingIntercomTag: false,
            };
        default:
            return state;
    }
};
