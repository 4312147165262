import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function index({
    text,
    fullScreen,
}) {
    return (
        <div
            className='mpa-error-screen'
            style={{
                height: fullScreen ? '100vh' : 'auto',
                flexDirection: 'column',
                display: 'flex',
            }}
        >
            <div>
                {text
                    ? (
                        <h1
                            className='error-text'
                            style={{
                                textAlign: 'center',
                                width: 450,
                            }}
                        >
                            {text}
                        </h1>
                    )
                    : null}
            </div>
        </div>
    );
}

index.propTypes = {
    text: PropTypes.string,
    fullScreen: PropTypes.bool,
};

export default index;
