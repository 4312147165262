import React from 'react';
import {
    Link,
    withRouter,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as employerActions from 'actions/employer';
import classNames from 'utils/classnames';

import './styles.scss';

const TabBar = ({
    location,
    tabsConfig,
}) => {
    const currentPath = location.pathname;
    const tabs = tabsConfig;

    return (
        <div className='mpa-tabs'>
            <div className='mpa-tabs__list'>
                {tabs.tabs.map(tab => (
                    <Link
                        key={tab.id}
                        className={classNames({
                            'mpa-tabs__tab mpa-tabs__tab': true,
                            'mpa-tabs__tab mpa-tabs__tab--active': currentPath === tabs.urlPrefix + tab.urlSegment,
                        })}
                        disabled={tab.disabled}
                        to={!tab.disabled && tabs.urlPrefix + tab.urlSegment}
                    >
                        {tab.title}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default withRouter(connect(
    state => state,
    dispatch => bindActionCreators({ ...employerActions }, dispatch),
)(TabBar));
