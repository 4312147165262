import * as request from 'request';

export function receiveFetchTags(tags) {
    return {
        type: 'RECEIVE_FETCH_TAGS',
        tags,
    };
}

export function fetchTags() {
    return dispatch => request.fetchTags()
        .then(resp => {
            dispatch(receiveFetchTags(resp.collection));
        });
}

export function requestCreateTag() {
    return { type: 'REQUEST_CREATE_TAG' };
}

export function receiveCreateTag(tag) {
    return {
        type: 'RECEIVE_CREATE_TAG',
        tag,
    };
}

export function createTag(tagName) {
    return dispatch => {
        dispatch(requestCreateTag());
        return request.createTag(tagName)
            .then(resp => {
                dispatch(receiveCreateTag(resp.tag));
            });
    };
}

export function receiveAddTagToConsultant(consultantId, tagId) {
    return {
        type: 'RECEIVE_ADD_TAG_TO_CONSULTANT',
        tagId,
        consultantId,
    };
}

export function addTagToConsultant(consultantId, tagId) {
    return dispatch => {
        request.addTagToConsultant(consultantId, tagId)
            .then(() => dispatch(receiveAddTagToConsultant(consultantId, tagId)));
    };
}

export function receiveRemoveTagFromConsultant(consultantId, tagId) {
    return {
        type: 'RECEIVE_REMOVE_TAG_FROM_CONSULTANT',
        tagId,
        consultantId,
    };
}

export function removeTagFromConsultant(consultantId, tagId) {
    return dispatch => {
        request.removeTagFromConsultant(consultantId, tagId)
            .then(() => dispatch(receiveRemoveTagFromConsultant(consultantId, tagId)));
    };
}

export function createAndAddTag(consultantId, tagName) {
    return dispatch => request.createTag(tagName)
        .then(resp => {
            dispatch(receiveCreateTag(resp.tag));
            dispatch(addTagToConsultant(consultantId, resp.tag.id));
        });
}
