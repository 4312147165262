import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    employerStatusTitle,
    departmentTypeTitle,
    employerTypeTitle,
} from 'utils/nameformatters';
import * as employerActions from 'actions/employer';
import * as createEmployerActions from 'actions/createemployer';
import Map from 'containers/usermap/map';
import EditTrigger from 'components/shared/edittrigger';
import InputModal from 'components/inputmodal';
import CropImageModal from 'components/cropimagemodal';
import modalController from 'utils/modalcontroller';
import config from 'config';
import CopyEmployerModal from 'containers/employer/copyemployermodal';
import {
    Button,
    Tooltip,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { mapStyle } from '../map-style';

import './styles.scss';

class EmployerTop extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showCopyEmployerModal: false };
    }

    handleCoverUpload() {
        const { files } = this.refs.coverpicker;

        this._startedProcessing = true;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const imageType = /^image\//;

            if (!imageType.test(file.type)) {
                continue;
            }

            const reader = new FileReader();

            reader.onload = e => {
                modalController.showModal(CropImageModal, {
                    title: 'Crop cover image',
                    width: 740,
                    props: {
                        imageWidth: 640,
                        imageHeight: 430,
                        round: false,
                        imageUrl: e.target.result,
                        buttonText: 'Save cover photo',
                        onSave: image => {
                            this.props.uploadCover(image, this.props.employer.employer.id);
                        },
                    },
                });
            };

            reader.readAsDataURL(file);
        }
    }

    handleAvatarUpload(a, b, c) {
        const { files } = this.refs.filepicker;

        this._startedProcessing = true;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const imageType = /^image\//;

            if (!imageType.test(file.type)) {
                continue;
            }

            // console.log(file);

            const reader = new FileReader();

            reader.onload = e => {
                // console.log('READ FILE::', e.target.result);

                modalController.showModal(CropImageModal, {
                    title: 'Crop avatar',
                    width: 600,
                    props: {
                        imageWidth: 400,
                        imageHeight: 400,
                        round: true,
                        imageUrl: e.target.result,
                        buttonText: 'Save avatar',
                        onSave: image => {
                            this.props.uploadAvatar(image, this.props.employer.employer.id);
                        },
                    },
                });
            };

            reader.readAsDataURL(file);
        }
    }

    renderAvatar() {
        const { employer } = this.props.employer;

        return (
            <div
                style={{
                // marginBottom: 40,
                }}
            >
                <div
                    style={{
                        width: 120,
                        height: 120,
                        borderRadius: 120,
                        background: '#EEE',
                        // margin: '0 auto 10px',
                        marginRight: 20,
                        backgroundImage: `url(${employer.logo_url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <div
                    style={{
                        color: '#FFF',
                        fontSize: 14,
                        textAlign: 'center',
                        padding: '2px 5px',
                        display: 'block',
                        margin: '10px auto 0',
                        marginLeft: -5,
                        width: 130,
                        background: 'rgba(255, 255, 255, 0.2)',
                        borderRadius: 4,
                        cursor: 'pointer',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <i className='fa fa-upload' />
                    {' '}
                    Upload logo
                    <input
                        ref='filepicker'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            opacity: 0,
                        }}
                        type='file'
                        onChange={this.handleAvatarUpload.bind(this)}
                    />
                </div>
            </div>
        );
    }

    renderCoverImage() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 20,
                    right: 40,
                    bottom: 20,
                    width: 360,
                    background: 'white',
                    zIndex: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: `url(${this.props.employer.employer.cover_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 5,
                }}
            >
                <input
                    ref='coverpicker'
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        width: '100%',
                        height: '100%',
                        background: '#fff',
                        zIndex: 500,
                        cursor: 'pointer',
                    }}
                    type='file'
                    onChange={this.handleCoverUpload.bind(this)}
                />
                {this.props.employer.employer.cover_url
                    ? ''
                    : (
                        <div
                            style={{
                                fontSize: 18,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <i className='fa fa-camera' />
                            <div>
                                Upload cover image
                            </div>
                        </div>
                    )}
            </div>
        );
    }

    renderMap() {
        const { employer } = this.props.employer;
        const { address } = employer;
        if (!address) return null;
        return (
            <div>
                <Map
                    defaultZoom={13}
                    disableDefaultUI
                    employerMarkers={[employer]}
                    lat={address.lat}
                    lng={address.lng}
                    mapStyle={mapStyle}
                />
            </div>
        );
    }

    renderEditEmployerInfo() {
        const { settings } = this.props;
        const { emr_systems } = settings;
        const { employer } = this.props.employer;
        const kams = config.KAM.filter(k => k.active || employer.admin_user_id == k.value);

        return (
            <EditTrigger
                style={{
                    marginRight: 10,
                    marginTop: 5,
                    marginLeft: -37,
                    color: '#FFF',
                }}
                title=''
                onClick={() => {
                    // const formatedPrimaryDepartment = settings.primary_department_types.map((item) => {

                    //     return {
                    //         title: item.name,
                    //         value: item.id,
                    //     };

                    // });

                    const formatedSecondaryDepartment = settings.secondary_department_types.map(item => ({
                        title: item.name,
                        value: item.id,
                    }));

                    modalController.showModal(InputModal, {
                        title: 'Edit employer name',
                        width: 600,
                        props: {
                            inputs: [
                                {
                                    type: 'input',
                                    name: 'name',
                                    label: 'Name',
                                    value: employer.name,
                                },
                                {
                                    type: 'input',
                                    name: 'department',
                                    label: 'Department',
                                    value: employer.department,
                                },
                                {
                                    type: 'input',
                                    name: 'account_name',
                                    label: 'Administrator name',
                                    value: employer.account_name,
                                },
                                {
                                    type: 'input',
                                    name: 'account_title',
                                    label: 'Administrator title',
                                    value: employer.account_title,
                                },
                                {
                                    type: 'input',
                                    name: 'account_email',
                                    label: 'Email',
                                    value: employer.account_email,
                                },
                                {
                                    type: 'input',
                                    name: 'account_phone',
                                    label: 'Phone',
                                    value: employer.account_phone,
                                },
                                // {
                                //     type: 'select',
                                //     name: 'primary_department_type_id',
                                //     label: 'Departmeny category',
                                //     options: formatedPrimaryDepartment,
                                //     value: employer.primary_department_type_id,
                                // },
                                {
                                    type: 'select',
                                    name: 'secondary_department_type_id',
                                    label: 'Department type',
                                    options: formatedSecondaryDepartment,
                                    value: employer.secondary_department_type_id,
                                }, {
                                    type: 'select',
                                    name: 'automotive',
                                    label: 'Maintainer (Automotive or Maintained)',
                                    options: [{
                                        title: 'Automotive',
                                        value: 'automotive',
                                    }, {
                                        title: 'Maintained',
                                        value: 'maintained',
                                    }],
                                    value: employer.automotive ? 'automotive' : 'maintained',
                                }, {
                                    type: 'select',
                                    name: 'employer_type',
                                    label: 'Employer type (Public or Private)',
                                    options: [{
                                        title: 'Public',
                                        value: 1,
                                    }, {
                                        title: 'Private',
                                        value: 2,
                                    }],
                                    value: employer.employer_type,
                                }, {
                                    type: 'select',
                                    name: 'isprotected',
                                    label: 'Protected',
                                    options: [{
                                        title: 'Not protected',
                                        value: false,
                                    }, {
                                        title: 'Protected',
                                        value: true,
                                    }],
                                    value: employer.isprotected || false,
                                }, {
                                    type: 'select',
                                    name: 'admin_user_id',
                                    label: 'Sales Person',
                                    options: kams,
                                    value: employer.admin_user_id,
                                }, {
                                    type: 'select',
                                    name: 'emr_system',
                                    label: 'Emr system',
                                    options: emr_systems.map(e => ({
                                        title: e.name, value: e.id,
                                    })),
                                    value: employer.emr_system,
                                },
                            ],
                            onSave: formData => this.props.updateEmployer(employer.id, {
                                ...formData,
                                automotive: formData.automotive === 'automotive',
                            }),
                        },
                    });
                }}
            />
        );
    }

    render() {
        const { employer } = this.props.employer;
        let employerBgColor;
        let employerTextColor;

        switch (employer.employer_type) {
            case 0:
                employerBgColor = '#fff';
                employerTextColor = '#000';
                break;

            case 1:
                employerBgColor = '#4B4BE2';
                employerTextColor = '#fff';
                break;

            case 2:
                employerBgColor = '#E03B9A';
                employerTextColor = '#fff';
                break;

            default:
                break;
        }

        return (
            <div className='mpa-employer-top'>
                {this.renderCoverImage()}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    {this.renderMap()}
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0))',
                        pointerEvents: 'none',
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 100,
                        position: 'relative',
                    }}
                >
                    <div>
                        {this.renderAvatar()}
                    </div>
                    <div style={{ color: '#FFF' }}>
                        <h2
                            className='mpa-employer-top__title'
                            style={{
                                color: '#fff',
                                maxWidth: 500,
                            }}
                        >
                            {this.renderEditEmployerInfo()}
                            {employer.name}
                        </h2>
                        <div>
                            {employer.department}
                        </div>
                        <div className='mpa-employer-top__employer-department-types'>
                            {/* <span className="mpa-employer-top__employer-department-types__type primary">{departmentTypeTitle(employer.primary_department_type_id, true)}</span> */}
                            <span className='mpa-employer-top__employer-department-types__type secondary'>{departmentTypeTitle(employer.secondary_department_type_id)}</span>
                        </div>

                        <div>
                            <div>
                                <strong
                                    style={{ fontSize: 18 }}
                                >
                                    {employer.account_name}
                                </strong>
                                <span
                                    style={{ marginLeft: 5 }}
                                >
                                    {employer.account_title ? `(${employer.account_title})` : ''}
                                </span>
                            </div>
                            <div
                                style={{ marginBottom: 5 }}
                            >
                                <a
                                    href={`mailto:${employer.account_email}`}
                                    style={{
                                        color: '#fff',
                                        textDecoration: 'underline',
                                        paddingRight: 5,
                                    }}
                                >
                                    {employer.account_email}
                                </a>

                                <span>
                                    -
                                    {' '}
                                    {employer.username}
                                </span>
                            </div>
                            <div>
                                <a
                                    href={`tel:${employer.account_phone}`}
                                    style={{
                                        color: '#fff',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {employer.account_phone}
                                </a>
                            </div>

                            <CopyEmployerModal
                                close={() => {
                                    this.setState({ showCopyEmployerModal: false });
                                }}
                                employer={this.props.employer.employer}
                                history={this.props.history}
                                profiles={this.props.employer.profiles.profiles.filter(profile => (
                                    profile.status === 1 && !profile.is_long_term))}
                                setCreateEmployerFormData={this.props.setCreateEmployerFormData}
                                visible={this.state.showCopyEmployerModal}
                            />
                            <div
                                style={{
                                    marginTop: 15,
                                    marginBottom: 5,
                                }}
                            >
                                <Tooltip
                                    mouseEnterDelay={0.1}
                                    overlayStyle={{ maxWidth: 250 }}
                                    placement='bottom'
                                    title={(
                                        <span>
                                            {config.employerStatus[employer.state].explanation}
                                        </span>
                                    )}
                                    trigger={['hover']}
                                >
                                    <span
                                        className={
                                            `mpa-employer-top__employer-status 
                                            mpa-employer-top__employer-status--is-${employerStatusTitle(employer.state).toLowerCase()}`
                                        }
                                    >
                                        {employerStatusTitle(employer.state)}
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    mouseEnterDelay={0.1}
                                    overlayStyle={{ maxWidth: 250 }}
                                    placement='bottom'
                                    title={(
                                        <span>
                                            {employer.automotive ? 'This employer is maintaining their own account' : 'We maintain this account on behalf of the employer'}
                                        </span>
                                    )}
                                    trigger={['hover']}
                                >
                                    <span
                                        className='mpa-employer-top__employer-status mpa-employer-top__employer-automotive-status'
                                    >
                                        {employer.automotive ? 'Automotive' : 'Maintained'}
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    mouseEnterDelay={0.1}
                                    overlayStyle={{ maxWidth: 250 }}
                                    placement='bottom'
                                    title={(
                                        <span>
                                            {!employer.employer_type ? 'The employer type is not set' : null}
                                            {employer.employer_type === 1 ? 'The employer is public' : null}
                                            {employer.employer_type === 2 ? 'The employer is private' : null}
                                        </span>
                                    )}
                                    trigger={['hover']}
                                >
                                    <span
                                        className='mpa-employer-top__employer-type'
                                        style={{
                                            background: employerBgColor,
                                            color: employerTextColor,
                                        }}
                                    >
                                        {employerTypeTitle(employer.employer_type)}
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    mouseEnterDelay={0.1}
                                    overlayStyle={{ maxWidth: 250 }}
                                    placement='bottom'
                                    title={(
                                        <span>
                                            {employer.isprotected ? 'Department details hidden from guests' : 'Department details are visible for guests'}
                                        </span>
                                    )}
                                    trigger={['hover']}
                                >
                                    <span
                                        className='mpa-employer-top__employer-status mpa-employer-top__employer-automotive-status'
                                    >
                                        {employer.isprotected ? 'Protected' : 'Not protected'}
                                    </span>
                                </Tooltip>
                                <strong />

                            </div>
                            <Button
                                icon={<CopyOutlined />}
                                style={{ marginTop: 10 }}
                                onClick={() => {
                                    this.setState({ showCopyEmployerModal: true });
                                }}
                            >
                                Kopiera uppdragsgivare
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({
        ...employerActions, ...createEmployerActions,
    }, dispatch),
)(EmployerTop);
