import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from 'config';
import { history } from 'store/configureStore';
import {
    searchContacts,
    searchEmployer,
} from 'actions/employerssearch';
import {
    employersFilterToggleIncludeTestAccounts,
    employersFilterSetQuery,
    employersFilterSetCounties,
    employersFilterSetCountries,
    employersFilterSetDepartmentTypes,
    employersFilterSetEmployerType,
    employersFilterSetMaintainingType,
    employersFilterSetMarginConfigs,
    employersFilterSetSort,
} from 'actions/employersfilter';

import './styles.scss';

import { Button } from 'antd';
import EmployerFilter from 'components/employerfilter';
import EmployerSearchResult from './searchresult';
import SearchBar from '../../components/searchbar';

const Employers = ({
    employers,
    totalCount,
    counties,
    employersFilter,
    people,
    numMaintained,
    numAutomotive,
    numPrivate,
    numPublic,
    secondaryDepartmentTypes,
    employersHasFetched,
    isFetching,
    marginOptions,
    location,
    // funcs
    employersFilterToggleIncludeTestAccounts,
    employersFilterSetQuery,
    employersFilterSetCountries,
    employersFilterSetCounties,
    employersFilterSetDepartmentTypes,
    employersFilterSetEmployerType,
    employersFilterSetMaintainingType,
    employersFilterSetMarginConfigs,
    searchEmployer,
    searchContacts,
    employersFilterSetSort,
}) => {
    const query = new URLSearchParams(location.search).get('query');

    const [page, setPage] = useState(1);
    const toggleTestAccounts = () => {
        employersFilterToggleIncludeTestAccounts();
    };

    useEffect(() => {
        searchEmployer(
            employersFilter.includeTestAccounts,

            {
                ALL: undefined,
                PUBLIC: 1,
                PRIVATE: 2,
            }[employersFilter.employerType],
            {
                ALL: undefined,
                AUTOMOTIVE: true,
                MAINTAINED: false,
            }[employersFilter.employerMaintainingType],
            employersFilter.departmentTypes,
            employersFilter.counties,
            employersFilter.countryCode,
            employersFilter.marginConfigs,
            query,
            page,
            employersFilter.sort,
        );
    }, [employersFilter, page, searchEmployer, query]);

    useEffect(() => {
        if (query?.length > 3) {
            searchContacts(query);
        }
    }, [query, searchContacts]);

    const renderTop = () => (
        <div
            style={{
                paddingBottom: 30,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #EEE',
            }}
        >
            <div>
                <h3
                    style={{ margin: 0 }}
                >
                    Employers (
                    {totalCount}
                    )
                </h3>
            </div>
            <Link to='/createemployer'>
                <Button
                    type='primary'
                >
                    <i
                        className='fa fa-plus-circle'
                        style={{ marginRight: 5 }}
                    />
                    Create employer
                </Button>
            </Link>
        </div>
    );

    return (
        <main
            className='mpa-employers'
        >

            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    height: 'calc(100vh - 65px)',
                    background: '#FFF',
                    paddingTop: 60,
                }}
            >
                <div
                    style={{
                        width: 300,
                        borderRight: '1px solid #eee',
                    }}
                >
                    <SearchBar
                        defaultValue={query}
                        placeHolder='Search employers...'
                        onChange={value => {
                            history.push(`/employers/?query=${value}`);
                            employersFilterSetQuery(value);
                        }}
                    />
                    <EmployerFilter
                        counties={counties}
                        countries={config.countries}
                        employersFilter={employersFilter}
                        marginConfigs={marginOptions}
                        numAutomotive={numAutomotive}
                        numMaintained={numMaintained}
                        numPrivate={numPrivate}
                        numPublic={numPublic}
                        secondaryDepartmentTypes={secondaryDepartmentTypes}
                        setCounties={employersFilterSetCounties}
                        setCountries={employersFilterSetCountries}
                        setDepartmentTypes={employersFilterSetDepartmentTypes}
                        setEmployerType={employersFilterSetEmployerType}
                        setMaintainingType={employersFilterSetMaintainingType}
                        setMarginConfigs={employersFilterSetMarginConfigs}
                        toggleTestAccounts={toggleTestAccounts}
                    />
                </div>
                <div
                    style={{
                        flex: 1,
                        height: 'calc(100vh - 65px)',
                        padding: 30,
                        backgroundColor: '#fff',
                        overflowX: 'hidden',
                    }}
                >
                    {renderTop(employers)}
                    <EmployerSearchResult
                        employers={employers}
                        employersFilterSetSort={employersFilterSetSort}
                        employersHasFetched={employersHasFetched}
                        isFetching={isFetching}
                        marginOptions={marginOptions}
                        page={page}
                        people={people}
                        query={query}
                        setPage={setPage}
                        totalCount={totalCount}
                    />
                </div>
            </div>
        </main>
    );
};

Employers.propTypes = {
    employers: PropTypes.arrayOf(PropTypes.shape({})),
    employersFilter: PropTypes.shape({
        employerType: PropTypes.string.isRequired,
        employerMaintainingType: PropTypes.string.isRequired,
        includeTestAccounts: PropTypes.bool.isRequired,
        departmentTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
        counties: PropTypes.arrayOf(PropTypes.number).isRequired,
        countryCode: PropTypes.string.isRequired,
        marginConfigs: PropTypes.arrayOf(PropTypes.number).isRequired,
        query: PropTypes.string.isRequired,
        sort: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    totalCount: PropTypes.number,
    counties: PropTypes.arrayOf(PropTypes.shape({})),
    people: PropTypes.arrayOf(PropTypes.shape({})),
    numMaintained: PropTypes.number,
    numAutomotive: PropTypes.number,
    numPrivate: PropTypes.number,
    numPublic: PropTypes.number,
    secondaryDepartmentTypes: PropTypes.arrayOf(PropTypes.shape({})),
    employersHasFetched: PropTypes.bool,
    isFetching: PropTypes.bool,
    // funcs
    employersFilterToggleIncludeTestAccounts: PropTypes.func,
    employersFilterSetQuery: PropTypes.func,
    employersFilterSetCounties: PropTypes.func,
    employersFilterSetDepartmentTypes: PropTypes.func,
    employersFilterSetEmployerType: PropTypes.func,
    employersFilterSetMaintainingType: PropTypes.func,
    employersFilterSetMarginConfigs: PropTypes.func,
    searchEmployer: PropTypes.func,
    searchContacts: PropTypes.func,
    marginOptions: PropTypes.arrayOf(PropTypes.shape({})),
    employersFilterSetSort: PropTypes.func,
    location: PropTypes.shape({
        get: PropTypes.func,
    }),
};

const mapStateToProps = state => ({
    counties: state.settings.counties,
    employers: state.employerssearch.employers,
    totalCount: state.employerssearch.totalCount,
    numAutomotive: null,
    numMaintained: null,
    numPublic: null,
    numPrivate: null,
    employersHasFetched: state.employerssearch.hasFetched,
    isFetching: state.employerssearch.isFetching,
    employersFilter: state.employersFilter,
    people: state.employerssearch.people,
    secondaryDepartmentTypes: state.settings.secondary_department_types,
    marginOptions: state.settings.marginOptions,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        employersFilterToggleIncludeTestAccounts,
        employersFilterSetQuery,
        employersFilterSetCounties,
        employersFilterSetCountries,
        employersFilterSetDepartmentTypes,
        employersFilterSetEmployerType,
        employersFilterSetMaintainingType,
        employersFilterSetMarginConfigs,
        employersFilterSetSort,
        searchEmployer,
        searchContacts,
    }, dispatch),
)(Employers);
