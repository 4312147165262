const initialState = {
    benefits: [],
    isFetching: false,
    hasFetched: false,
};

export default function benefits(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_BENEFITS':
            return {
                ...state,
                isFetching: true,
            };

        case 'RECEIVE_BENEFITS':
            return {
                ...state,
                benefits: action.payload.sort((a, b) => b.id - a.id),
                isFetching: false,
                hasFetched: true,
            };

        case 'RECIEVE_NEW_BENEFIT':
            // eslint-disable-next-line no-case-declarations
            const promotions = state.benefits;
            promotions.push(action);
            return {
                ...state,
                benefits: promotions.sort((a, b) => b.id - a.id),
                isFetching: false,
                hasFetched: true,
            };

        case 'SORT_BENEFITS':
            // eslint-disable-next-line no-case-declarations
            const args = action.payload;
            // eslint-disable-next-line no-case-declarations
            const sortedBenefits = state.benefits.sort((a, b) => {
                switch (typeof a[args.sortField]) {
                    case 'string':
                        if (args.sortOrder === 'ascend') {
                            return a[args.sortField].localeCompare(b[args.sortField]);
                        } if (typeof args.sortOrder === 'undefined') {
                            return b.id - a.id;
                        }
                        return b[args.sortField].localeCompare(a[args.sortField]);
                    case 'number':
                        if (args.sortOrder === 'ascend') {
                            return a[args.sortField] - b[args.sortField];
                        } if (typeof args.sortOrder === 'undefined') {
                            return b.id - a.id;
                        }
                        return b[args.sortField] - a[args.sortField];
                    case 'boolean':
                        if (args.sortOrder === 'ascend') {
                            return (a[args.sortField] === b[args.sortField]) ? 0 : a[args.sortField] ? -1 : 1;
                        } if (typeof args.sortOrder === 'undefined') {
                            return b.id - a.id;
                        }
                        return (a[args.sortField] === b[args.sortField]) ? 0 : a[args.sortField] ? 1 : -1;
                    default:
                        return b.id - a.id;
                }
            });

            return {
                ...state,
                benefits: sortedBenefits,
            };

        default:
            return state;
    }
}
