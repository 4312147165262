import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Select,
} from 'antd';
import moment from 'moment';
import InputModal from '../inputmodal';
import { mapToValueTitle } from '../../utils/nameformatters';
import { calculateSalary } from '../../utils/salary';

const { Option } = Select;

function Profile({
    profile,
    settings,
    onChange,
    countryCode,
    procurement,
}) {
    const [selectedOccupation, setSelectedOccupation] = useState(profile ? profile.occupation_id : 1);
    const [ltvInputs, setLtvInputs] = useState([]);
    const {
        additional_info = '',
        bookings = { length: 0 },
        description = '',
        drafts = { length: 0 },
        driver_license = false,
        id,
        is_long_term = false,
        name = '',
        occupation_id = 2,
        pending = { length: 0 },
        published = { length: 0 },
        qualifications = '',
        skills = [],
        specialization_ids = procurement?.specialization_ids ? procurement?.specialization_ids : [],
        employment_scope = 100,
        end_time,
        start_time,
        price = procurement?.price,
        margin,
        salary,
    } = profile;
    const period = start_time && end_time ? [moment.unix(start_time), moment.unix(end_time)] : null;
    const [formPrice, setPrice] = useState(price);
    const [formMargin, setMargin] = useState(margin);
    useEffect(() => {
        const extraInputs = is_long_term ? [
            {
                type: 'dateRange',
                name: 'period',
                label: 'Employment period',
                value: period,
                isRequired: true,
                disabledDate: current => current && current < moment().subtract(1, 'day').endOf('day'),
            }, {
                type: 'date',
                name: 'last_application_time',
                label: 'Last application date',
                isRequired: false,
            }, {
                type: 'input',
                inputType: 'number',
                name: 'price',
                label: 'Price (in SEK)',
                value: formPrice,
                isRequired: true,
            }, {
                type: 'input',
                inputType: 'number',
                name: 'margin',
                label: 'Margin (in percent)',
                value: margin,
                isRequired: true,
            }, {
                type: 'input',
                inputType: 'number',
                name: 'salary',
                label: 'salary (in SEK)',
                value: salary,
                isRequired: true,
                isDisabled: true,
            }, {
                type: 'input',
                inputType: 'number',
                name: 'employment_scope',
                label: 'Scope of employment (in %)',
                value: employment_scope,
                isRequired: true,
            },
        ] : [];
        setLtvInputs(extraInputs);
    }, [employment_scope, is_long_term]);

    return (
        <>
            {profile && (bookings.length || drafts.length || pending.length || published.length)
                ? (
                    <Alert
                        // eslint-disable-next-line no-nested-ternary
                        description={`There are some ${bookings.length ? 'booked' : pending.length ? 'pending' : published.length ? 'published' : 'drafted'} shifts associated`}
                        message='Job profile is in use'
                        showIcon
                        type='warning'
                    />
                )
                : ''}
            <InputModal
                close={() => null}
                data={profile || {}}
                hideButton
                inputs={
                    [{
                        type: 'switch',
                        name: 'is_long_term',
                        label: 'Is LTV?',
                        optionLabels: {
                            on: 'Yes',
                            off: 'No',
                        },
                        value: is_long_term,
                    },
                    {
                        type: 'input',
                        name: 'name',
                        label: 'Name',
                        value: profile ? name : '',
                    },
                    ...ltvInputs,
                    {
                        type: 'select',
                        name: 'occupation_id',
                        label: 'Occupation',
                        options: mapToValueTitle(settings.occupations, 'id', 'name'),
                        value: profile ? occupation_id : 1,
                    }, {
                        type: 'multiselect',
                        name: 'specialization_ids',
                        label: 'Specializations',
                        options: settings.specializations.filter(s => s.occupation_id === selectedOccupation)
                            .map(s => (
                                <Option
                                    key={s.id}
                                    occupation={s.occupation_id}
                                    title={s.name}
                                    value={s.id}
                                >
                                    {s.name}
                                </Option>
                            )),
                        value: profile ? [...specialization_ids] : specialization_ids,
                    }, {
                        type: 'multiselect',
                        name: 'skills',
                        label: 'Skills',
                        options: settings.skills.filter(s => s.occupation_id === selectedOccupation)
                            .map(s => (
                                <Option
                                    key={s.id}
                                    occupation={s.occupation_id}
                                    title={s.name}
                                    value={s.id}
                                >
                                    {s.name}
                                </Option>
                            )),
                        value: profile ? [...skills] : [],
                    }, {
                        type: 'textarea',
                        name: 'description',
                        label: 'Description',
                        value: profile ? description : '',
                    }, {
                        type: 'textarea',
                        name: 'qualifications',
                        label: 'Qualifications',
                        value: profile ? qualifications : '',
                    }, {
                        type: 'textarea',
                        name: 'additional_info',
                        label: 'Additional info',
                        value: profile ? additional_info : '',
                    }, {
                        type: 'switch',
                        name: 'driver_license',
                        label: 'Driver\'s license',
                        optionLabels: {
                            on: 'Yes',
                            off: 'No',
                        },
                        value: profile ? driver_license : false,
                    },
                    {
                        type: 'hidden',
                        name: 'id',
                        value: id,
                    }]
                }
                onChange={(formData, _, form) => {
                    if (selectedOccupation !== formData.occupation_id) {
                        const newSkills = formData.skills.filter(id => {
                            const skill = settings.skills.find(s => s.id === id);
                            return skill.occupation_id === formData.occupation;
                        });
                        const newSspecializations = formData.specialization_ids.filter(id => {
                            const specialization = settings.specializations.find(s => s.id === id);
                            return specialization.occupation_id === formData.occupation;
                        });
                        setSelectedOccupation(formData.occupation_id); // do not rely on this call for this function!
                        form.updateInputsWithValue({
                            skills: newSkills,
                            specialization_ids: newSspecializations,
                        });
                    }
                    else if (formPrice != formData.price) {
                        setPrice(formData.price);
                        form.updateInputsWithValue({
                            salary: calculateSalary(formData.price, formMargin, countryCode),
                        });
                    }
                    else if (formMargin != formData.margin) {
                        setMargin(formData.margin);
                        form.updateInputsWithValue({
                            salary: calculateSalary(formPrice, formData.margin, countryCode),
                        });
                    }
                    onChange(formData);
                }}
                onSave={() => null}

            />
        </>
    );
}

Profile.propTypes = {
    profile: PropTypes.shape({
        additional_info: PropTypes.string,
        bookings: PropTypes.shape({
            length: PropTypes.number,
        }),
        color: PropTypes.number,
        currency: PropTypes.string,
        description: PropTypes.string,
        drafts: PropTypes.shape({
            length: PropTypes.number,
        }),
        driver_license: PropTypes.bool,
        id: PropTypes.number,
        introduction: PropTypes.bool,
        introduction_rules: PropTypes.shape({
            min: PropTypes.number,
        }),
        is_long_term: PropTypes.bool,
        name: PropTypes.string,
        occupation_id: PropTypes.number,
        pending: PropTypes.shape({
            length: PropTypes.number,
        }),
        published: PropTypes.shape({
            length: PropTypes.number,
        }),
        qualifications: PropTypes.string,
        routine_ids: PropTypes.arrayOf(PropTypes.number),
        skills: PropTypes.arrayOf(PropTypes.number),
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        status: PropTypes.number,
        timezone: PropTypes.string,
        employment_scope: PropTypes.number,
        end_time: PropTypes.number,
        start_time: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        occupations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        skills: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            occupation_id: PropTypes.number,
        })).isRequired,
        specializations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            occupation_id: PropTypes.number.isRequired,
        })).isRequired,
    }).isRequired,
};

export default Profile;
