import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Progress } from 'antd';

const LTVInfo = ({
    ltv,
    capacity,
}) => {
    const { long_term_details: details } = ltv;
    const start = moment.unix(details.start_time).format('YYYY-MM-DD');
    const end = moment.unix(details.end_time).format('YYYY-MM-DD');
    const scope = details.employment_scope;
    return (
        <div
            style={{
                display: 'flex',
                marginBottom: 20,
            }}
        >
            <div>
                <h6 style={{ marginBottom: 0 }}>
                    LTV
                </h6>
                <div>
                    {start}
                    {' '}
                    -
                    {' '}
                    {end}
                </div>
                <div>
                    {scope}
                    % / ~
                    {' '}
                    {details.hourly_rate}
                    SEK/h
                </div>
            </div>
            <div
                style={{ paddingLeft: 20 }}
            >
                <h6 style={{ marginBottom: 0 }}>Capacity</h6>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{ marginRight: 20 }}
                    >
                        <div>
                            {capacity.allowance}
                            {' '}
                            hours in contract
                        </div>
                        <div>
                            {capacity.used}
                            {' '}
                            hours used
                        </div>
                    </div>

                </div>
            </div>
            <Progress
                percent={capacity.percent}
                strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                }}
                type='circle'
                width={60}
            />
        </div>

    );
};

LTVInfo.propTypes = {
    ltv: PropTypes.shape({ long_term_details: PropTypes.shape({
        employment_scope: PropTypes.number,
        start_time: PropTypes.number,
        end_time: PropTypes.number,

    }) }).isRequired,
    capacity: PropTypes.shape({
        allowance: PropTypes.number,
        percent: PropTypes.number,
        used: PropTypes.number,
    }).isRequired,
};

export default LTVInfo;
