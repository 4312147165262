import React from 'react';

class ClusterMarker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                style={{
                    width: 30,
                    height: 30,
                    backgroundColor: '#5c3696',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop: -18,
                    marginLeft: -18,
                    borderRadius: 50,
                    borderWidth: 3,
                    borderColor: '#FFF',
                    zIndex: 30,
                }}
            >
                {this.props.numPoints}
            </div>
        );
    }
}

export default ClusterMarker;
