import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'components/shared/avatar';

export default class EmployerInfoPopover extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { employer } = this.props;
        return (
            <div
                className='mpa-map-marker__info'
                style={{ display: 'flex' }}
            >
                <Avatar
                    size={80}
                    style={{ marginRight: 10 }}
                    url={employer.logo_url}
                />
                <div>
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                        }}
                    >
                        {employer.name}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            color: '#999',
                        }}
                    >
                        {employer.department}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            marginTop: 5,
                            color: '#999',
                        }}
                    >
                        <div>{employer.address.addressline1}</div>
                        <div>{employer.address.addressline2}</div>
                        <div>
                            {employer.address.postalcode}
                            {' '}
                            {employer.address.city}
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Link to={`/employers/${employer.id}`}>
                            <button className='btn btn-primary btn-block'>
                                Show profile
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
