import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Switch,
    Input,
    Popconfirm,
} from 'antd';
import ShiftDatePicker from './shiftdatepicker';
import ShiftListImporter from './shiftlistimporter';

const Toolbar = ({
    addShifts,
    customShifts = [],
    onSubmit,
    isValid,
    sendNotifications,
    setSendNotifications,
    isLtv = false,
    selectedOrderId = null,
    newOrderId = null,
    setNewOrderId,
    orderIdError,
    loading,
    disabledDate = null,
    validDateRange = null,
    shouldEmptyRawInput,
}) => {
    let submit = (
        <Button
            disabled={loading || !isValid}
            loading={loading}
            size='large'
            type='primary'
            onClick={onSubmit}
        >
            Submit
        </Button>
    );

    if (!selectedOrderId && !newOrderId && !isLtv) {
        submit = (
            <Popconfirm
                cancelText='No'
                okText='Publish'
                title='Publish without order id?'
                onConfirm={onSubmit}
            >
                <Button
                    disabled={loading || !isValid}
                    loading={loading}
                    size='large'
                    type='primary'
                >
                    Submit
                </Button>
            </Popconfirm>
        );
    }

    return (
        <div
            style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div>
                <ShiftDatePicker
                    addShifts={addShifts}
                    disabledDate={disabledDate}
                    validDateRange={validDateRange}
                />
                <ShiftListImporter
                    addShifts={addShifts}
                    customShifts={customShifts}
                    shouldEmptyRawInput={shouldEmptyRawInput}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                {!isLtv && (
                    <div>
                        <div
                            style={{
                                width: 140,
                                marginRight: 40,
                            }}
                        >
                            <div>
                                <small><strong>Send notifications?</strong></small>
                            </div>
                            <div>
                                <small>A "new published shifts" push will be sent to all consultants</small>
                            </div>
                        </div>
                        <Switch
                            checked={sendNotifications}
                            style={{ marginTop: 5 }}
                            onChange={() => {
                                setSendNotifications(!sendNotifications);
                            }}
                        />
                    </div>
                )}
                {!isLtv && (
                    <div
                        style={{
                            maxWidth: 140,
                            marginRight: 40,
                        }}
                    >
                        <div>
                            <small><strong>Order ID:</strong></small>
                        </div>
                        {selectedOrderId || (
                            <div>
                                <Input
                                    className={orderIdError ? 'mpa-add-shifts-list__input-error' : ''}
                                    style={{ marginBottom: 5 }}
                                    value={newOrderId}
                                    onChange={e => {
                                        setNewOrderId(e.target.value);
                                    }}
                                />
                                {orderIdError && (
                                    <small
                                        style={{ color: 'red' }}
                                    >
                                        ID Order already exists
                                    </small>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {submit}
            </div>
        </div>
    );
};

Toolbar.propTypes = {
    addShifts: PropTypes.func.isRequired,
    customShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    sendNotifications: PropTypes.bool.isRequired,
    setSendNotifications: PropTypes.func.isRequired,
    isLtv: PropTypes.bool,
    selectedOrderId: PropTypes.string,
    newOrderId: PropTypes.string,
    setNewOrderId: PropTypes.func.isRequired,
    orderIdError: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    disabledDate: PropTypes.func,
    validDateRange: PropTypes.arrayOf(PropTypes.object),
    shouldEmptyRawInput: PropTypes.bool.isRequired,
};

export default Toolbar;
