import * as request from 'request';
import { message } from 'antd';

export function requestFetchApplications() {
    return { type: 'REQUEST_FETCH_APPLICATIONS' };
}

export function receiveApplications(resp) {
    return {
        type: 'RECEIVE_APPLICATIONS',
        applications: resp.collection,
    };
}

export function fetchApplications() {
    return dispatch => {
        dispatch(requestFetchApplications());
        return request.fetchApplications()
            .then(resp => dispatch(receiveApplications(resp)));
    };
}

export function requestFetchApplication() {
    return { type: 'REQUEST_FETCH_APPLICATION' };
}

export function receiveApplication(resp) {
    return {
        type: 'RECEIVE_APPLICATION',
        application: resp.application,
    };
}

export function fetchApplication(applicationId) {
    return dispatch => {
        dispatch(requestFetchApplication());
        return request.fetchApplication(applicationId)
            .then(resp => dispatch(receiveApplication(resp)));
    };
}

export function requestResetApplication() {
    return { type: 'RESET_APPLICATION' };
}

export function resetApplication() {
    return dispatch => {
        dispatch(requestResetApplication());
    };
}

export function requestRemoveShiftsFromBookedApplication() {
    return { type: 'REQUEST_REMOVE_SHIFTS_FROM_BOOKED_APPLICATION' };
}

export function removeShiftsFromBookedApplicationSuccess(shiftIds, applicationId) {
    return {
        type: 'REMOVE_SHIFTS_FROM_BOOKED_APPLICATION_SUCCESS',
        shiftIds,
        applicationId,
    };
}

export function removeShiftsFromBookedApplication(applicationId, shiftIds) {
    return dispatch => {
        dispatch(requestRemoveShiftsFromBookedApplication());

        return request.applicationRemoveShifts(applicationId, { shift_ids: shiftIds }).then(() => {
            message.success(`${shiftIds.length} shifts have been removed from booking`);
            dispatch(removeShiftsFromBookedApplicationSuccess(shiftIds, applicationId));
        }, error => {
            message.error(error.responseJSON.message);
        });
    };
}

const requestConfirmApplication = () => ({ type: 'REQUEST_CONFIRM_APPLICATION' });

const confirmApplicationSuccess = scheduled => ({
    type: 'CONFIRM_APPLICATION_SUCCESS',
    scheduled,
});

export const confirmApplication = (userId, employerId, scheduled) => async dispatch => {
    dispatch(requestConfirmApplication());
    try {
        await request.confirmApplication(userId, employerId, scheduled);
        message.success('The application was approved');
        dispatch(confirmApplicationSuccess(scheduled));
    }
    catch (e) {
        message.error('Failed to approve applications');
        throw e;
    }
};

const requestCommentApplications = () => ({ type: 'REQUEST_COMMENT_APPLICATIONS' });
const confirmCommentApplicationsSuccess = () => ({ type: 'COMMENT_APPLICATIONS_SUCCESS' });

export const commentApplications = (ids, comment) => async dispatch => {
    dispatch(requestCommentApplications());
    const scheduled = ids.map(id => ({
        id, comment,
    }));
    try {
        await request.batchUpdateScheduled(scheduled);
        message.success('The comments have been saved');
        dispatch(confirmCommentApplicationsSuccess());
        dispatch(fetchApplications());
    }
    catch (e) {
        message.error('Failed to approve applications');
        throw e;
    }
};

const requestDenyApplication = () => ({ type: 'REQUEST_DENY_APPLICATION' });

const denyApplicationSuccess = scheduled => ({
    type: 'DENY_APPLICATION_SUCCESS',
    scheduled,
});

export const denyApplication = (employerId, scheduled, deleteShifts = false) => async dispatch => {
    dispatch(requestDenyApplication());
    try {
        await request.denyApplication(employerId, scheduled, deleteShifts);
        message.success('The application was denied');
        dispatch(denyApplicationSuccess(scheduled));
    }
    catch (e) {
        message.error('Failed to delete applications');
        throw e;
    }
};

export const setHasPatchedApplicaton = hasPatchedApplication => ({
    type: 'SET_HAS_PATCHED_APPLICATION',
    hasPatchedApplication,
});
export function removeShiftsFromBookedApplicationAndDeleteShifts(applicationId, shiftIds, employerId) {
    return dispatch => {
        dispatch(requestRemoveShiftsFromBookedApplication());
        return request.applicationRemoveShifts(applicationId, { shift_ids: shiftIds }).then(() => {
            message.success(`${shiftIds.length} shifts have been removed from booking`);
            request.employerDeleteShifts(employerId, { shift_ids: shiftIds }).then(() => {
                message.success(`${shiftIds.length} shifts have been removed from the employer`);
                dispatch(removeShiftsFromBookedApplicationSuccess(shiftIds, applicationId));
            }, error => {
                message.error(error.responseJSON.message);
            });
        }, error => {
            message.error(error.responseJSON.message);
        });
    };
}

export function requestFetchLtvApplications() {
    return { type: 'REQUEST_FETCH_LTV_APPLICATIONS' };
}

export function receiveLtvApplications(resp) {
    return {
        type: 'RECEIVE_LTV_APPLICATIONS',
        applications: resp.collection,
    };
}

export function fetchLtvApplications() {
    return dispatch => {
        dispatch(requestFetchLtvApplications());
        return request.fetchLtvApplications()
            .then(resp => dispatch(receiveLtvApplications(resp)));
    };
}

export function requestConfirmLtvApplication(applicationId) {
    return {
        type: 'REQUEST_CONFIRM_LTV_APPLICATION',
        applicationId,
    };
}

export function confirmLtvApplicationSuccess(applicationId) {
    return {
        type: 'CONFIRM_LTV_APPLICATION_SUCCESS',
        applicationId,
    };
}

export function confirmLtvApplication(applicationId) {
    return dispatch => {
        dispatch(requestConfirmLtvApplication(applicationId));

        return request.confirmLtvApplication(applicationId).then(() => {
            message.success('The application was approved');

            dispatch(confirmLtvApplicationSuccess(applicationId));
        });
    };
}

export function requestDenyLtvApplication(applicationId) {
    return {
        type: 'REQUEST_DENY_LTV_APPLICATION',
        applicationId,
    };
}

export function denyLtvApplicationSuccess(applicationId) {
    return {
        type: 'DENY_LTV_APPLICATION_SUCCESS',
        applicationId,
    };
}

export function denyLtvApplication(applicationId, reasonId) {
    return dispatch => {
        dispatch(requestDenyLtvApplication(applicationId));

        return request.denyLtvApplication(applicationId, reasonId).then(() => {
            message.success('The application was denied');

            dispatch(denyLtvApplicationSuccess(applicationId));
        });
    };
}
