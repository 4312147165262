import React from 'react';
import { Select, Checkbox } from 'antd';

const SelectList = ({ data, onChange, selected, updateKey, className='' }) => {
  if(data.length === 0) return null;

  const { value, label } = data
  const selectedKeys = selected && selected[value] || [];

  const items = data.options.reduce((acc, item, index) => {
    const keyValue = `${item.value}::${item.label}`
    const checked = selectedKeys.includes(keyValue);
    const selectedKey = (updateKey && selected) ? selected[updateKey] : '';
    
    if(selectedKey && item.options) {
      const selectedOption = selectedKey.map(key => key.split('::')[0]);
      console.log(selectedOption, item.options[updateKey])
      if(!selectedOption.includes(item.options[updateKey])) return acc;
    }

    acc = [...acc, 
      ({
        key: index,
        label: <Checkbox key={item.value} checked={checked} onChange={() => onChange(value, keyValue)}>{item.label}</Checkbox>
      })
    ]
    return acc
  }, [])

  return (<div className={className}><Select
    showSearch
    placeholder={`Select ${label}`}
    style={{ width: 180 }}
    optionFilterProp="children"
    onChange={onChange}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
    options={items}
    
  /></div>)
}
export default SelectList;
