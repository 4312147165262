import React from 'react';
import { Modal } from 'antd';
import InputModal from '../inputmodal';

const LeadsModal = ({
    setVisibility,
    onSave,
    visible
}) => {
    return (
        <Modal
            title="Create lead"
            width={600}
            open={visible}
            onCancel={() => setVisibility(false)}
        >
            <InputModal
                close={() => setVisibility(false)}
                inputs={
                    [{
                        type: 'input',
                        name: 'lead_contact_name',
                        label: 'Lead name',
                        isRequired: true,
                    },{
                        type: 'input',
                        name: 'contact_person',
                        label: 'Contact person',
                        isRequired: true,
                    },{
                        type: 'input',
                        name: 'phone',
                        label: 'Phone',
                        isRequired: true,
                    }, {
                        type: 'input',
                        name: 'email',
                        label: 'Email',
                        isRequired: true,
                    },
                    {
                        type: 'textarea',
                        name: 'additional',
                        label: 'Additional',
                    },
                    {
                        type: 'hidden',
                        name: 'country_code',
                        value: 'SWE'
                    }
                ]
                }
                onSave={onSave}
            />
        </Modal>
    );
};

LeadsModal.propTypes = {};

export default LeadsModal;
