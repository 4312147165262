import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

function index({
    name, value,
}) {
    return (
        <input
            key={name}
            name={name}
            type='hidden'
            value={value}
        />
    );
}

index.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default withFormsy(index);
