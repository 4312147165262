import React, {
    useState,
    useEffect,
} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    getAdminUsersV1,
    updateAdminUserV1,
    deleteAdminUserV1,
} from 'actions/super_admin';
import {
    Form,
    Input,
    Table,
    Tag,
    Button,
    Space,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import EditAdminUserModal from 'components/editadminusermodal';
import DeleteAdminUserModal from 'components/deleteadminusermodal';

const AdminUsers = ({
    adminUsers, isFetching, getAdminUsersV1, updateAdminUserV1, deleteAdminUserV1,
}) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showEditAdminUserModal, setShowEditAdminUserModal] = useState(false);
    const [showDeleteAdminUserModal, setShowDeleteAdminUserModal] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [currentAdminUser, setCurrentAdminUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState({
        superAdmin: null, // null, true, or false
        deleted: false,
    });

    useEffect(() => {
        getAdminUsersV1();
    }, [getAdminUsersV1]);

    useEffect(() => {
        if (adminUsers) {
            const formattedData = adminUsers.map((user, index) => ({
                ...user, key: index.toString(),
            }));
            setData(formattedData);
            setFilteredData(formattedData);
        }
    }, [adminUsers]);

    useEffect(() => {
        applyFiltersAndSearch();
    }, [data, searchQuery, filters]);

    const isEditing = record => record.key === editingKey;

    const edit = record => {
        form.setFieldsValue({
            id: '', username: '', email: '', super_admin_user: '', ...record,
        });
        setEditingKey(record.key);
        setCurrentAdminUser(record);
        setShowEditAdminUserModal(true);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async key => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item, ...row,
                });
                setData(newData);
                setEditingKey('');
                updateAdminUserV1(item.id, row);
            }
            else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        }
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleDelete = id => {
        const newData = data.map(user => {
            if (user.id === id) {
                return {
                    ...user, deleted: true,
                };
            }
            return user;
        });

        // update the state with the new data
        setData(newData);
        setFilteredData(newData.filter(user => {
            // applying the same filters as before to maintain consistency in the displayed data
            let passesFilter = true;
            if (filters.superAdmin !== null) {
                passesFilter = user.super_admin_user === filters.superAdmin;
            }
            if (searchQuery) {
                passesFilter = passesFilter && (user.username.toLowerCase().includes(searchQuery.toLowerCase()) || user.email.toLowerCase().includes(searchQuery.toLowerCase()));
            }
            if (filters.deleted !== null) {
                passesFilter = passesFilter && user.deleted === filters.deleted;
            }
            return passesFilter && !user.deleted;
        }));

        // call the delete API with payload
        deleteAdminUserV1(id, { deleted: true });
    };

    const applyFiltersAndSearch = () => {
        let filtered = [...data];

        // Apply super admin filter
        if (filters.superAdmin !== null) {
            filtered = filtered.filter(user => user.super_admin_user === filters.superAdmin);
        }

        // Apply deleted filter
        if (filters.deleted !== null) {
            filtered = filtered.filter(user => user.deleted === filters.deleted);
        }

        // Apply search query
        if (searchQuery) {
            filtered = filtered.filter(user => user.username.toLowerCase().includes(searchQuery.toLowerCase())
                || user.email.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        setFilteredData(filtered);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Super Admin',
            dataIndex: 'super_admin_user',
            key: 'super_admin_user',
            render: (_, value) => {
                if (value.super_admin_user === true) {
                    return <Tag color='green'>YES</Tag>;
                } return <Tag color='red'>NO</Tag>;
            },
            filters: [
                {
                    text: 'YES', value: true,
                },
                {
                    text: 'NO', value: false,
                },
            ],
            onFilter: (value, record) => record.super_admin_user === value,
        },
        {
            title: 'Active',
            dataIndex: 'deleted',
            key: 'deleted',
            render: (_, value) => {
                if (value.deleted === false) {
                    return <Tag color='green'>YES</Tag>;
                } return <Tag color='red'>NO</Tag>;
            },
            filters: [
                {
                    text: 'YES', value: false,
                },
                {
                    text: 'NO', value: true,
                },
            ],
            onFilter: (value, record) => record.deleted === value,

        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => (
                <span>
                    <Button
                        disabled={editingKey !== '' && editingKey !== record.key}
                        icon={<EditOutlined />}
                        onClick={() => edit(record)}
                    />
                    <Button
                        danger
                        disabled={!!editingKey && editingKey !== record.key || record.deleted}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            setCurrentAdminUser(record);
                            setShowDeleteAdminUserModal(true);
                        }}
                    />
                </span>
            ),
        },
    ];

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        });
    };

    const handleResetFilters = () => {
        setSearchQuery('');
        setFilters({
            superAdmin: null, deleted: false,
        });
    };

    const handleCreateUser = () => {
        setCurrentAdminUser(null);
        setShowEditAdminUserModal(true);
    };

    return (
        <main className='flex w-full m-8 flex-col'>
            <Space
                style={{
                    marginBottom: 16, justifyContent: 'space-between', width: '100%',
                }}
            >
                <div>
                    <Input
                        placeholder='Search by Username or Email'
                        style={{ width: 200 }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <Button
                        type={filters.superAdmin === true ? 'primary' : 'default'}
                        onClick={() => handleFilterChange('superAdmin', true)}
                    >
                        Super Admins
                    </Button>
                    <Button
                        type={filters.superAdmin === false ? 'primary' : 'default'}
                        onClick={() => handleFilterChange('superAdmin', false)}
                    >
                        Non-Super Admins
                    </Button>
                    <Button
                        type={filters.deleted === true ? 'primary' : 'default'}
                        onClick={() => handleFilterChange('deleted', true)}
                    >
                        Deleted Admin Users
                    </Button>
                    <Button
                        danger
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            handleResetFilters();
                        }}
                    >
                        Reset filters
                    </Button>
                </div>

            </Space>
            <Form component={false} form={form}>
                <Table
                    bordered
                    columns={columns}
                    dataSource={filteredData}
                    loading={isFetching}
                    pagination={{ onChange: cancel }}
                    rowClassName='editable-row'
                />
            </Form>
            <EditAdminUserModal
                setVisibility={visible => {
                    setShowEditAdminUserModal(visible);
                    if (!visible) cancel();
                }}
                user={currentAdminUser}
                visible={showEditAdminUserModal}
                onSave={values => {
                    updateAdminUserV1(currentAdminUser.id, values);
                    setData(data.map(item => (item.key === currentAdminUser.key ? {
                        ...item, ...values,
                    } : item)));
                    cancel();
                }}
            />
            <DeleteAdminUserModal
                setVisibility={setShowDeleteAdminUserModal}
                user={currentAdminUser}
                visible={showDeleteAdminUserModal}
                onDelete={handleDelete}
            />
        </main>
    );
};

const mapStateToProps = state => ({
    adminUsers: state.super_admin.adminUsers,
    isFetching: state.super_admin.isFetching,
    // currentUser: state.super_admin.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getAdminUsersV1,
    updateAdminUserV1,
    deleteAdminUserV1,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
