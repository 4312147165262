import React, { useState } from 'react';
import './styles.scss';
import {
    Col,
    Row,
    Empty,
    Radio,
} from 'antd';
import config from '../../../config';
import JobAdApplication from './jobadapplication';

const JobAdApplications = ({
    applications,
    onAcceptApplication,
    onRejectApplication,
}) => {
    const [selectedCategory, setSelectedCategory] = useState(1);

    const rejectedApplications = applications.filter(a => a.application_status === config.jobPostingApplicationStatus.REJECTED);
    const acceptedApplications = applications.filter(a => a.application_status === config.jobPostingApplicationStatus.ACCEPTED);
    const newApplications = applications.filter(a => a.application_status === config.jobPostingApplicationStatus.APPLIED);
    const deletedApplications = applications.filter(a => a.application_status === config.jobPostingApplicationStatus.USER_REMOVED || a.application_status === config.jobPostingApplicationStatus.ADMIN_REMOVED);
    let activeList;

    switch (selectedCategory) {
        case 1:
            activeList = newApplications;
            break;
        case 2:
            activeList = acceptedApplications;
            break;
        case 3:
            activeList = rejectedApplications;
            break;
        case 4:
            activeList = deletedApplications;
            break;
        default:
            activeList = [];
    }

    return (
        <div>
            <h4
                style={{
                    marginTop: 20,
                }}
            >
                Applications
            </h4>
            <div
                style={{
                    padding: 20,
                    borderRadius: 16,
                    background: '#fafafa',
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Radio.Group
                            buttonStyle='solid'
                            defaultValue={1}
                            onChange={e => setSelectedCategory(e.target.value)}
                        >
                            <Radio.Button value={1}>
                                New (
                                {newApplications.length}
                                )
                            </Radio.Button>
                            <Radio.Button value={2}>
                                Accepted (
                                {acceptedApplications.length}
                                )
                            </Radio.Button>
                            <Radio.Button value={3}>
                                Rejected (
                                {rejectedApplications.length}
                                )
                            </Radio.Button>
                            <Radio.Button value={4}>
                                Deleted (
                                {deletedApplications.length}
                                )
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                    {activeList.length ? (activeList.reverse().map(application => (
                        <JobAdApplication
                            application={application}
                            onAcceptApplication={onAcceptApplication}
                            onRejectApplication={onRejectApplication}
                        />
                    ))) : (
                        <Col span={24}>
                            <Empty
                                description='No applicants'
                                style={{
                                    margin: '40px 0 20',
                                }}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default JobAdApplications;
