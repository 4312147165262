import * as request from 'request';
import {
    createEmployerContact,
    uploadAvatar,
    uploadCover,
    updateCustomShift,
    createCustomShift,
    fetchEmployer,
    fetchEmployerProfiles,
} from 'actions/employer';
import { history } from '../store/configureStore';

export function setCreateEmployerFormData(data) {
    return {
        type: 'SET_CREATE_EMPLOYER_FORM_DATA',
        data,
    };
}

export function resetCreateEmployerForm() {
    return { type: 'RESET_CREATE_EMPLOYER_FORM' };
}

function _requestCreateEmployer() {
    return { type: 'REQUEST_CREATE_EMPLOYER' };
}

function createEmployerSuccess(resp) {
    return {
        type: 'CREATE_EMPLOYER_SUCCESS',
        successId: resp.employer.id,
    };
}

function createEmployerError(errorMessage) {
    return {
        type: 'CREATE_EMPLOYER_ERROR',
        errorMessage,
    };
}

function requestCopyContacts() {
    return { type: 'REQUEST_COPY_CONTACTS' };
}

function requestCreateContact() {
    return { type: 'REQUEST_CREATE_CONTACT' };
}

function createContactSuccess() {
    return { type: 'CREATE_CONTACT_SUCCESS' };
}

function copyContactsSuccess() {
    return { type: 'COPY_CONTACTS_SUCCESS' };
}

function requestCopyLogo() {
    return { type: 'REQUEST_COPY_LOGO' };
}

function copyLogoSuccess() {
    return { type: 'COPY_LOGO_SUCCESS' };
}

function requestCopyCover() {
    return { type: 'REQUEST_COPY_COVER' };
}

function copyCoverSuccess() {
    return { type: 'COPY_COVER_SUCCESS' };
}

function requestCopyCustomShifts() {
    return { type: 'REQUEST_COPY_CUSTOM_SHIFTS' };
}

function copyCustomShiftsSuccess() {
    return { type: 'COPY_CUSTOM_SHIFTS_SUCCESS' };
}

function requestCopyProfiles() {
    return { type: 'REQUEST_COPY_PROFILES' };
}

function copyProfilesSuccess() {
    return { type: 'COPY_PROFILES_SUCCESS' };
}

function usernameAvailable(boolean) {
    return {
        type: 'USERNAME_AVAILABILITY',
        usernameAvailable: boolean,
    };
}

export function validateUsername(username) {
    return dispatch => request.validateUsername(username)
        .then(() => dispatch(usernameAvailable(true)))
        .catch(() => {
            dispatch(usernameAvailable(false));
        });
}

export function requestCreateEmployer(data, copyData, redirect) {
    return dispatch => {
        dispatch(_requestCreateEmployer());
        return request.createEmployer(JSON.stringify(data))
            .then(resp => {
                let contactsPromise = Promise.resolve();
                let logoPromise = Promise.resolve();
                let coverPromise = Promise.resolve();
                let customShiftsPromise = Promise.resolve();
                let profilesPromise = Promise.resolve();

                if (copyData.contacts && copyData.contacts.length) {
                    dispatch(requestCopyContacts());

                    contactsPromise = copyData.contacts.forEach(contact => {
                        dispatch(createEmployerContact(resp.employer.id, contact)).then(() => {
                            dispatch(copyContactsSuccess());
                        });
                    });
                }

                if (copyData.logo) {
                    dispatch(requestCopyLogo());

                    logoPromise = dispatch(uploadAvatar(copyData.logo, resp.employer.id)).then(() => {
                        dispatch(copyLogoSuccess());
                    });
                }

                if (copyData.cover) {
                    dispatch(requestCopyCover());

                    coverPromise = dispatch(uploadCover(copyData.cover, resp.employer.id)).then(() => {
                        dispatch(copyCoverSuccess());
                    });
                }

                if (copyData.customShifts) {
                    dispatch(requestCopyCustomShifts());

                    customShiftsPromise = request.copyEmployerCustomShifts(resp.employer.id, copyData.customShifts).then(() => {
                        dispatch(copyCustomShiftsSuccess());
                    });
                }

                if (copyData.profiles) {
                    dispatch(requestCopyProfiles());

                    profilesPromise = request.copyEmployerProfiles(resp.employer.id, copyData.profiles).then(() => {
                        dispatch(copyProfilesSuccess());
                    });
                }

                Promise.all([contactsPromise, logoPromise, coverPromise, customShiftsPromise, profilesPromise]).then(() => {
                    dispatch(createEmployerSuccess(resp));
                });
                if (redirect) {
                    history.push(`/employers/${resp.employer.id}`);
                }
                else {
                    return resp.employer;
                }
            }, error => {
                dispatch(createEmployerError(JSON.parse(error.responseText).message));
            });
    };
}

export function requestCustomCreateEmployer(data) {
    return dispatch => {
        dispatch(_requestCreateEmployer());
        return request.createEmployer(JSON.stringify(data))
            .then(resp => {
                const contactsPromise = Promise.resolve();

                if (data.account_email && data.account_name) {
                    dispatch(requestCreateContact());

                    dispatch(createEmployerContact(resp.employer.id, {
                        email: data.account_email,
                        name: data.account_name,
                        phone: data.account_phone,
                        title: data.account_title,
                        contact_status: true,
                    })).then(() => {
                        dispatch(createContactSuccess());
                    });
                }

                Promise.all([contactsPromise]).then(() => {
                    dispatch(createEmployerSuccess(resp));
                });

                return resp.employer;
            }, error => {
                dispatch(createEmployerError(JSON.parse(error.responseText).message));
            });
    };
}
