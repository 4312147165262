import * as request from 'request';

export function requestLeads() {
    return { type: 'REQUEST_FETCH_LEADS' };
}

export function recieveLeads(data) {
    return {
        type: 'RECEIVE_RESPONSE_LEAD',
        data
    };
}

export function requestCreateLead() {
  return { type: 'REQUEST_CREATE_LEAD' };
}

export function createLead(form) {
    return dispatch => {
        dispatch(requestCreateLead());
        return request.createLead(form)
            .then(resp => dispatch(recieveLeads(resp)));
    };
}

export function getLeads() {
  return dispatch => {
      dispatch(requestLeads());
      return request.getLeads()
          .then(resp => dispatch(recieveLeads(resp)));
  };
}

export function convertToEmployer(id) {
    return dispatch => {
        return request.convertToEmployer(id)
            .then(resp => dispatch(recieveLeads(resp)));
    };
  }
