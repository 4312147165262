import React from 'react';
import {
    filter,
    groupBy,
    map,
} from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as consultantActions from 'actions/consultant';
import { humanReadableHours } from 'utils/datetimeformatters';
import {
    Table,
    Checkbox,
    Collapse,
    Spin,
    Empty,
} from 'antd';
import './styles.scss';

class ConsultantTimeReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = { timeReports: [] };

        this.showActiveOnly = this.showActiveOnly.bind(this);
    }

    componentDidMount() {
        this.props.fetchConsultantTimeReports(this.props.consultant.consultant.id).then(() => {
            this.setState({ timeReports: this.props.consultant.timeReports });
        });
    }

    _getDuration(data) {
        if (data.deviation !== 0) {
            return (data.duration - data.shift_break) + data.deviation;
        }

        return data.duration;
    }

    showActiveOnly(value) {
        const { timeReports } = this.props.consultant;
        const showActive = value.target.checked;

        if (showActive) {
            this.setState({ timeReports: filter(timeReports, timeReport => timeReport.report_status !== 'CONFIRMED') });
        }
        else {
            this.setState({ timeReports });
        }
    }

    childTable(data) {
        const columns = [
            {
                title: 'Pris',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Avvikelse',
                dataIndex: 'deviation',
                key: 'deviation',
                render: value => {
                    if (value < 0) {
                        return `-${humanReadableHours(Math.abs(value))}`;
                    }

                    return humanReadableHours(value);
                },
            },
            {
                title: 'Arbetad tid',
                dataIndex: '',
                key: 'duration',
                render: value => humanReadableHours(this._getDuration(value)),
            },
            {
                title: 'Rast',
                dataIndex: 'shift_break',
                key: 'shift_break',
                render: value => humanReadableHours(value),
            },
            {
                title: 'Lön',
                dataIndex: '',
                key: 'salary',
                render: value => {
                    const duration = this._getDuration(value);

                    return `${value.salary} SEK/h (Total: ${value.salary * duration} SEK)`;
                },
            },
            {
                title: 'EF ersättning',
                dataIndex: '',
                key: 'consultant_fee',
                render: value => `${value.consultant_fee} SEK/h (Total: ${value.consultant_fee * (value.duration - value.shift_break)} SEK)`,
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={[data]}
                pagination={false}
                size='middle'
            />
        );
    }

    renderTable(data, date) {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Status',
                dataIndex: 'report_status',
                key: 'report_status',
                render: (value, row) => {
                    let color;
                    let explanationText;

                    switch (value) {
                        case 'REPORTED':
                        case 'AUTO_REPORT':
                        case 'ATTEST_REMINDED':
                            explanationText = 'Ej attesterad';
                            color = '#ffcd00';
                            break;

                        case 'PENDING':
                        case 'REPORT_REMINDED':
                            explanationText = 'Ej rapporterad';
                            color = '#000';
                            break;

                        case 'CONFIRMED':
                            explanationText = 'Attesterad';
                            color = '#5fa94e';
                            break;

                        case 'DENIED':
                            explanationText = 'Nekad';
                            color = '#d9534f';
                            break;

                        default:
                            break;
                    }

                    return (
                        <div
                            style={{
                                color,
                                fontWeight: 500,
                            }}
                        >
                            {explanationText}
                            <span
                                style={{
                                    paddingLeft: 5,
                                    fontSize: 14,
                                    color: '#ccc',
                                }}
                            >
                                (
                                {value}
                                )

                            </span>
                        </div>
                    );
                },
            },
            {
                title: 'Behandlad av uppdragsgivare',
                dataIndex: 'attest_count',
                key: 'attest_count',
                width: 150,
                render: value => {
                    if (value) {
                        if (value === 1) {
                            return `${value} gång`;
                        }

                        return `${value} gånger`;
                    }

                    return '-';
                },
            },
            {
                title: 'Arbetstillfälle',
                dataIndex: '',
                key: 'start_time',
                render: (value, row) => `${moment.unix(value.start_time).format('DD MMM')} ${moment.unix(value.start_time).format('HH:mm')} - ${moment.unix(value.end_time).format('HH:mm')}`,
            },
            {
                title: 'Pass ID',
                dataIndex: '',
                key: 'shift_id',
                render: (value, row) => (
                    <Link to={`/employers/${value.employer.id}/shifts/${value.shift_id}`}>{value.shift_id}</Link>
                ),
            },
            {
                title: 'Uppdragsgivare',
                dataIndex: 'employer',
                key: 'employer',
                width: 300,
                render: (value, row) => (
                    <Link to={`/employers/${value.id}`}>{value.name}</Link>
                ),
            },
        ];

        const header = (
            <div
                style={{
                    textTransform: 'capitalize', fontSize: 20,
                }}
            >
                {moment(date).format('MMMM')}
                ,
                {' '}
                {data.length}
                {' '}
                reports
            </div>
        );

        return (
            <Collapse.Panel
                key={date}
                header={header}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    expandedRowRender={record => this.childTable(record)}
                    // loading={!hasFetchedTimeReports && !timeReports.length}
                    locale={{ emptyText: 'Nada, Njet, Nothing...' }}
                    pagination={false}
                    scroll={{ x: 1200 }}
                />
            </Collapse.Panel>
        );
    }

    renderYear(year, key) {
        const grouped = groupBy(year, item => moment.unix(item.start_time).format('YYYY-MM'));

        return (
            <div>
                <h3
                    style={{ marginTop: 20 }}
                >
                    {key}

                </h3>
                <Collapse
                    accordion
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    {map(grouped, (item, key) => this.renderTable(item, key))}
                </Collapse>
            </div>
        );
    }

    renderContent() {
        const { timeReports } = this.state;
        const { hasFetchedTimeReports } = this.props.consultant;

        const groupedByYear = groupBy(timeReports, timeReport => moment.unix(timeReport.start_time).format('YYYY'));

        if (!hasFetchedTimeReports) {
            return (
                <div
                    style={{ textAlign: 'center' }}
                >
                    <Spin />
                </div>
            );
        }

        return (
            <div>
                <Checkbox
                    style={{ marginBottom: 10 }}
                    onChange={this.showActiveOnly}
                >
                    Show only in progress
                </Checkbox>
                <div
                    style={{
                        flexDirection: 'column-reverse',
                        display: 'flex',
                    }}
                >
                    {map(groupedByYear, (year, key) => this.renderYear(year, key))}
                </div>

            </div>
        );
    }

    render() {
        const { timeReports } = this.props.consultant;

        if (!timeReports.length) {
            return (
                <div className='content-card-full'>
                    <Empty />
                </div>
            );
        }

        return (
            <div className='content-card-full'>
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...consultantActions }, dispatch),
)(ConsultantTimeReports);
