const initialState = {
    procurements: [],
    isFetching: false,
    hasFetched: false,
    error: null,
    updatingProcurement: null,
    errorUpdatingProcurement: null,
};

export function procurements(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_PROCUREMENTS':
            return {
                ...state,
                isFetching: true,
                error: null,
            };

        case 'RECEIVE_PROCUREMENTS':
            return {
                ...state,
                procurements: action.payload,
                isFetching: false,
                hasFetched: true,
                error: null,
            };

        case 'RECEIVE_PROCUREMENTS_ERROR':
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                error: action.error,
            };

        case 'REQUEST_UPDATE_PROCUREMENTS':
            return {
                ...state,
                updatingProcurement: action.payload,
                errorUpdatingProcurement: null,
            };

        case 'RECEIVE_UPDATED_PROCUREMENT':
            return {
                ...state,
                updatingProcurement: null,
                errorUpdatingProcurement: null,
                procurements: state.procurements.map(procurement => {
                    if (procurement.id === action.payload.id) {
                        return action.payload;
                    }
                    return procurement;
                }),
            };

        case 'RECEIVE_UPDATED_PROCUREMENT_ERROR':
            return {
                ...state,
                updatingProcurement: null,
                errorUpdatingProcurement: action.payload.id,
            };

        default:
            return state;
    }
}
