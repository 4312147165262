import React from 'react';
import PropTypes from 'prop-types';
import stringToColor from 'utils/stringtocolor';
import classNames from 'utils/classnames';
import './styles.scss';

export default class Tag extends React.Component {
    static propTypes = { tag: PropTypes.object.isRequired }

    constructor(props) {
        super(props);
    }

    render() {
        const { tag } = this.props;

        return (
            <div
                className={classNames({
                    'mpa-tag': true,
                    'mpa-tag--small': this.props.small,
                })}
                style={{ background: stringToColor(tag.tag) }}
            >
                <div className='mpa-tag__title'>
                    {tag.tag}
                </div>
                <div
                    className='mpa-tag__remove'
                    onClick={() => {
                        this.props.onRemove();
                    }}
                >
                    <span className='ion-close' />
                </div>
            </div>
        );
    }
}
