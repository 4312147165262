import React from 'react';
import moment from 'moment';
import './styles.scss';

export default class RemoveShiftModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { success: false };
    }

    renderWarning() {
        return (
            <div className='mpa-replace-consultant__warning'>
                <div className='mpa-replace-consultant__warning-icon'>
                    <i className='fa fa-warning' />
                </div>
                <div className='mpa-replace-consultant__warning-content'>
                    <h2>Warning! Do not touch this stuff unless you know exactly what you are doing!</h2>
                    <p>
                        This action will permanently alter existing production booking data.
                        The selected shift will be permantently removed from the user's booking. Do not use this feature unless you have
                        confirmed with both the employer and consultant that this shift should be removed.
                    </p>
                </div>
            </div>
        );
    }

    renderShift() {
        const {
            employer, booking, shift, user, scheduled,
        } = this.props;
        const date = moment.unix(shift.start_time).format('YYYY-MM-DD');
        const startTime = moment.unix(shift.start_time).format('HH:mm');
        const endTime = moment.unix(shift.end_time).format('HH:mm');
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '20px 0',
                }}
            >
                <div
                    style={{
                        width: 120,
                        height: 120,
                        borderRadius: 120,
                        background: '#EEE',
                        backgroundImage: `url(${user.avatar})`,
                        backgroundSize: 'cover',
                        marginRight: 20,
                    }}
                />
                <div>
                    <div
                        style={{
                            fontWeight: 600,
                            fontSize: 18,
                        }}
                    >
                        {user.firstname}
                        {' '}
                        {user.lastname}
                    </div>
                    <div>
                        {employer.name}
                    </div>
                    <div>{employer.department}</div>
                    <div>
                        {date}
                    </div>
                    <div>
                        {startTime}
                        {' '}
                        -
                        {' '}
                        {endTime}
                    </div>
                    <div>
                        {shift.duration * shift.price}
                        {' '}
                        kr
                    </div>
                </div>

            </div>
        );
    }

    renderSuccess() {
        const title = this.props.isBooking ? 'The shift has been removed from the booking' : 'This applicant has been removed from this shift';

        return (
            <div
                style={{
                    padding: 40,
                    minHeight: 300,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <h2>Done</h2>
                <div
                    style={{ margin: '20px 0' }}
                >
                    {title}
                </div>
                <button
                    className='btn btn-primary'
                    onClick={this.props.close}
                >
                    <i className='fa fa-close' />
                    {' '}
                    Close
                </button>
            </div>
        );
    }

    render() {
        const buttonText = this.props.isBooking ? 'Remove booked consultant from this shift' : 'Remove applied consultant from this shift';

        if (this.state.success) {
            return this.renderSuccess();
        }

        return (
            <div className='mpa-remove-shift-modal'>
                {this.renderWarning()}
                <div className='mpa-remove-shift-modal__content'>
                    {this.renderShift()}
                </div>
                {/* <div className="mpa-remove-shift-modal__actions">
                    <button
                        className="btn btn-danger btn-lg"
                        onClick={() => {

                            this.props.onConfirmRemove().then(() => {

                                this.setState({
                                    success: true,
                                });

                            });

                        }}
                    >
                        {buttonText}
                    </button>
                </div> */}
            </div>
        );
    }
}
