import React from 'react';
import PropTypes from 'prop-types';
import {
    groupBy,
    map,
} from 'lodash';

import AttestGroup from './attestgroup';

const AttestGroups = ({
    employerAttests,
    batchReportScheduled,
    isUpdatingScheduled,
}) => {
    const getFirstShift = attest_group => attest_group.scheduled.reduce((a, b) => (a.start_time < b.start_time ? a : b));
    const renderAttestGroup = attests => (
        <AttestGroup
            attests={attests}
            batchReportScheduled={batchReportScheduled}
            isUpdatingScheduled={isUpdatingScheduled}
        />
    );
    // const { employer_attests: { employer_attests: employerAttests } } = this.props;
    employerAttests.sort((a, b) => {
        const a_first_shift = getFirstShift(a);
        const b_first_shift = getFirstShift(b);
        return a_first_shift.start_time < b_first_shift.start_time ? -1 : 1;
    });
    if (employerAttests.length) {
        const grouped = groupBy(employerAttests, attestGroup => attestGroup.employer.id);
        return (
            <>
                {map(grouped, renderAttestGroup)}
            </>
        );
    }
    return (
        <p className='content-card-full empty-list'>No attests waiting</p>
    );
};
AttestGroups.propTypes = {
    employerAttests: PropTypes.arrayOf(PropTypes.shape({})),
    batchReportScheduled: PropTypes.func,
};

export default AttestGroups;
