const initialState = {
    isFetching: false,
    ltvs: [],
    error: null,
};

export function ltvs(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_LTVS':
            return {
                ...state,
                isFetching: true,
                error: null,
            };

        case 'RECEIVE_LTVS':
            return {
                ...state,
                isFetching: false,
                ltvs: action.payload,
                error: null,
            };

        case 'RECEIVE_LTVS_ERROR':
            return {
                ...state,
                isFetching: false,
                ltvs: [],
                error: action.payload,
            };

        case 'UPDATE_SINGLE_LTV':
            return {
                ...state,
                isFetching: false,
                ltvs: state.ltvs.map(ltv => {
                    if (ltv.id === action.payload.id) {
                        return {
                            ...ltv,
                            ...action.payload,
                        };
                    }
                    return ltv;
                }),
                error: null,
            };

        case 'DELETE_SINGLE_LTV':
            return {
                ...state,
                isFetching: false,
                ltvs: state.ltvs.filter(ltv => ltv.id !== action.payload.id),
                error: null,
            };

        default:
            return state;
    }
}
