import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    filter,
    map,
    reduce,
    keyBy,
    chain,
    find,
    groupBy,
} from 'lodash';
import moment from 'moment';

import * as consultantActions from 'actions/consultants';
import * as jobsActions from 'actions/jobs';
import * as employerActions from 'actions/employers';

import { Radio } from 'antd';

import Loader from 'components/shared/loader';
import RetentionGraph from './RetentionGraph';
import EmployerActivityTable from './EmployerActivityTable';
import './styles.scss';

const CUTOFF = moment('2017-11-01');

const filterConsultants = state => filter(state.consultants.consultants, c => c.occupation === 2 && !c.test_user /* && moment.unix(c.created).isSameOrAfter(CUTOFF, 'day') */);

const filterEmployers = state => filter(state.employers.employers, e => !e.test_employer /* && moment.unix(e.created_time).isSameOrAfter(CUTOFF, 'day') */);

const getShiftsByEmployerId = state => {
    const val = chain(state.jobs.jobs)
        .filter(j => !j.employer.test_employer)
        .reduce((arr, j) => arr.concat(j.shifts.map(s => ({
            ...s,
            employerId: j.employer.id,
        }))), [])
        .groupBy('employerId')
        /* .reduce((arr, b) => {

            return arr.concat(b.shifts);

        }, []) */
        .value();

    return val;
};

const getBookingsByConsultantId = state => {
    const val = chain(state.jobs.jobs)
        .filter(j => j.bookings.length)
        .reduce((arr, j) => arr.concat(j.bookings), [])
        .groupBy(b => {
            if (!b.user) {
                console.log('waaaaat', b);
            }

            return b.user.id;
        })
        .value();

    return val;
};

const getShiftsByConsultantId = state => {
    const val = chain(state.jobs.jobs)
        .filter(j => j.bookings.length)
        .reduce((arr, j) => {
            const b = j.bookings.map(booking => ({
                ...booking,
                shifts: booking.shifts.map(shiftId => find(j.shifts, { id: shiftId })),
            }));

            return arr.concat(b);
        }, [])
        .reduce((arr, b) => {
            const s = b.shifts.map(shift => ({
                ...shift,
                userId: b.user.id,
            }));

            return arr.concat(s);
        }, [])
        .groupBy('userId')
        .value();

    return val;
};

const mapStateToProps = state => {
    if (!(state.consultants.hasFetched && state.jobs.hasFetched && state.employers.hasFetched)) {
        return { ready: false };
    }

    return {
        employers: filterEmployers(state),
        consultants: filterConsultants(state),
        bookingsByConsultantId: getBookingsByConsultantId(state),
        shiftsByConsultantId: getShiftsByConsultantId(state),
        shiftsByEmployerId: getShiftsByEmployerId(state),
        jobs: state.jobs,
        ready: true,
        dataFetched: false,
    };
};

class Retention extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            param: 'activity',
            employerParam: 'shifts',
            employerTableParam: 'shifts',

        };
    }

    componentDidMount() {
        const promises = [];
        if (!this.props.jobs) {
            this.props.fetchJobs();
        }
        if (!this.props.conusltants) {
            promises.push(this.props.fetchConsultants());
        }
        if (!this.props.employers) {
            promises.push(this.props.fetchEmployers());
        }
    }

    getBookingsByConsultantId() {
        const val = chain(this.props.jobs.jobs)
            .filter(j => j.bookings.length)
            .reduce((arr, j) => arr.concat(j.bookings), [])
            .groupBy(b => {
                if (!b.user) {
                    console.log('waaaaat', b);
                }

                return b.user.id;
            })
            .value();

        // console.log('VAAAAAl', val)

        return val;
        // return this.props.jobs.jobs.
    }

    consultantHasShiftInMonth(consultant, month) {
        let ret = false;
        const consultantShifts = this.props.shiftsByConsultantId[consultant.id];

        if (!consultantShifts) {
            return ret;
        }

        consultantShifts.forEach(s => {
            if (moment.unix(s.start_time).isSame(moment(month), 'month')) {
                ret = true;
            }
        });

        return ret;
    }

    consultantHasBookingInMonth(consultant, month) {
        // console.log('MONTH', month)

        let ret = false;
        const consultantBookings = this.props.bookingsByConsultantId[consultant.id];

        if (!consultantBookings) {
            return ret;
        }

        consultantBookings.forEach(b => {
            if (moment.unix(b.created_time).isSame(moment(month), 'month')) {
                ret = true;
            }
        });

        return ret;

        let hasJobInMonth = false;

        const consultantJobs = reduce(this.props.jobs.jobs, (arr, job) => {
            if (job.bookings.length) {
                return reduce(job.bookings, (arr, booking) => {
                    if (booking.user.id === consultant.id) {
                        // console.log('iS SAME', booking.user.id, consultant, booking)

                        return arr.concat([booking]);
                    }

                    return arr;
                }, []);
            }

            return arr;
        }, []);

        // hasJobInMonth = true;

        if (consultantJobs.length) {
            // hasJobInMonth = true;

            consultantJobs.forEach(booking => {
                if (moment.unix(booking.created_time).isSame(moment(month), 'year')) {
                    hasJobInMonth = true;
                }
            });
        }

        if (hasJobInMonth) {

            // console.log(consultant.firstname, consultant.lastname, consultantJobs)

        }

        return hasJobInMonth;
    }

    getWorkByMonth(consultantsRegistered, month) {
        const consultants = [];

        consultantsRegistered.forEach(c => {
            if (this.consultantHasBookingInMonth(c, moment.unix(month).format('YYYY-MM-DD'))) {
                consultants.push(c);
            }
        });

        return consultants;
    }

    getShiftsByMonth(consultantsRegistered, month) {
        const consultants = [];

        consultantsRegistered.forEach(c => {
            if (this.consultantHasShiftInMonth(c, moment.unix(month).format('YYYY-MM-DD'))) {
                consultants.push(c);
            }
        });

        return consultants;
    }

    getMonthData() {
        const { consultants } = this.props;
        const months = {};
        const startMonth = moment('2017-03-01');
        const diff = moment().diff(startMonth, 'months');

        for (let i = 0; i <= diff; ++i) {
            const m = startMonth;
            months[m.format('YYYY-MM')] = { consultantsRegistered: [] };
            startMonth.add(1, 'month');
        }

        consultants.forEach(c => {
            const reg = moment.unix(c.created).startOf('month').format('YYYY-MM');
            months[reg].consultantsRegistered.push(c);
        });

        const data = map(months, (obj, month) => {
            const m = moment(`${month}-01`);
            const diff = moment().diff(m, 'months');
            const retentionByMonth = [];
            const wbm = [];
            const sbm = [];

            for (let i = 0; i <= diff; ++i) {
                const start = m.startOf('month').unix();

                const activeInMonth = reduce(obj.consultantsRegistered, (arr, c) => {
                    if (c.last_active && c.last_active >= start) {
                        return arr.concat([c]);
                    }

                    return arr;
                }, []);

                retentionByMonth.push(activeInMonth);

                const workByMonth = this.getWorkByMonth(obj.consultantsRegistered, start);
                wbm.push(workByMonth);

                const shiftsByMonth = this.getShiftsByMonth(obj.consultantsRegistered, start);
                sbm.push(shiftsByMonth);

                m.add(1, 'month');
            }

            return {
                month,
                ...obj,
                retentionByMonth,
                workByMonth: wbm,
                shiftsByMonth: sbm,
            };
        });
        const ret = keyBy(data, 'month');

        return ret;
    }

    renderAverages() {
        return (
            <div
                key={this.state.param}
                style={{ overflow: 'auto' }}
            >
                <div
                    style={{
                        display: 'flex',
                        minWidth: 80 * 20,
                    }}
                    // key={this.state.param + i}
                >
                    <div
                        style={{
                            width: 110,
                            minWidth: 110,
                            maxWidth: 110,
                            height: 80,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            // background: 'rgba(135, 81, 255)',
                            paddingLeft: 15,
                            fontWeight: 'bold',
                            // color: '#FFF',
                        }}
                    >
                        Genomsnitt
                    </div>

                </div>

            </div>
        );
    }

    employerHasShiftInMonth(employer, month) {
        let ret = false;
        const employerShifts = this.props.shiftsByEmployerId[employer.id];

        if (!employerShifts) {
            return ret;
        }

        employerShifts.forEach(s => {
            if (moment.unix(s.start_time).isSame(moment(month), 'month')) {
                ret = true;
            }
        });

        return ret;
    }

    employerHasBookingInMonth(employer, month) {
        let ret = false;
        const employerShifts = this.props.shiftsByEmployerId[employer.id];

        if (!employerShifts) {
            return ret;
        }

        employerShifts.forEach(s => {
            if (moment.unix(s.start_time).isSame(moment(month), 'month') && s.bookings.length) {
                ret = true;
            }
        });

        return ret;
    }

    getEmployerShiftsByMonth(employersRegistered, month) {
        const employers = [];

        employersRegistered.forEach(e => {
            if (this.employerHasShiftInMonth(e, moment.unix(month).format('YYYY-MM-DD'))) {
                employers.push(e);
            }
        });

        return employers;
    }

    getEmployerBookingsByMonth(employersRegistered, month) {
        const employers = [];

        employersRegistered.forEach(e => {
            if (this.employerHasBookingInMonth(e, moment.unix(month).format('YYYY-MM-DD'))) {
                employers.push(e);
            }
        });

        return employers;
    }

    getEmployerMonthData() {
        const { employers } = this.props;
        const months = {};
        const startMonth = moment('2017-03-01');
        const diff = moment().diff(startMonth, 'months');

        for (let i = 0; i <= diff; ++i) {
            const m = startMonth;
            months[m.format('YYYY-MM')] = { employersRegistered: [] };
            startMonth.add(1, 'month');
        }

        employers.forEach(e => {
            const reg = moment.unix(e.created_time).startOf('month').format('YYYY-MM');
            months[reg].employersRegistered.push(e);
        });

        const data = map(months, (obj, month) => {
            const m = moment(`${month}-01`);
            const diff = moment().diff(m, 'months');
            const sbm = [];
            const bbm = [];

            for (let i = 0; i <= diff; ++i) {
                const start = m.startOf('month').unix();

                const shiftsByMonth = this.getEmployerShiftsByMonth(obj.employersRegistered, start);
                sbm.push(shiftsByMonth);

                const bookingsByMonth = this.getEmployerBookingsByMonth(obj.employersRegistered, start);
                bbm.push(bookingsByMonth);

                m.add(1, 'month');
            }

            return {
                month,
                ...obj,
                // retentionByMonth,
                shiftsByMonth: sbm,
                bookingsByMonth: bbm,
            };
        });
        const ret = keyBy(data, 'month');

        return ret;
    }

    renderEmployerMonthData() {
        const monthData = this.getEmployerMonthData();

        return (
            <div>
                {this.renderEmployerRadio()}
                <div
                    key={this.state.employerParam}
                    style={{ overflow: 'auto' }}
                >
                    {map(monthData, (obj, month) => {
                        let mapObj = obj.shiftsByMonth;
                        if (this.state.employerParam === 'bookings') {
                            mapObj = obj.bookingsByMonth;
                        }

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    minWidth: 80 * 20,
                                }}
                                // key={this.state.param + i}
                            >
                                <div
                                    style={{
                                        width: 110,
                                        minWidth: 110,
                                        maxWidth: 110,
                                        height: 80,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        background: 'rgba(135, 81, 255)',
                                        paddingLeft: 15,
                                        color: '#FFF',
                                    }}
                                >
                                    <h6
                                        style={{
                                            margin: 0,
                                            color: '#FFF',
                                        }}
                                    >
                                        {month}
                                    </h6>
                                    <div>
                                        {obj.employersRegistered.length}
                                        {' '}
                                        uppdragsg.
                                    </div>
                                </div>
                                {mapObj.map(activeInMonth => {
                                    const percentage = obj.employersRegistered.length ? Math.min(((activeInMonth.length / obj.employersRegistered.length) * 100), 100).toFixed(1) : 0;
                                    return (
                                        <div
                                            style={{
                                                width: 80,
                                                height: 80,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: `rgba(135, 81, 255, ${percentage / 100})`,
                                            }}
                                        >
                                            <div>
                                                {percentage}
                                                %
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderRadio() {
        return (
            <Radio.Group
                buttonStyle='solid'
                className='radiogroup'
                defaultValue='activity'
                size='large'
                onChange={e => {
                    this.setState({ param: e.target.value });
                }}
            >
                <Radio.Button value='activity'>Aktivitet</Radio.Button>
                <Radio.Button value='bookings'>Bokning</Radio.Button>
                <Radio.Button value='shifts'>Pass</Radio.Button>
            </Radio.Group>
        );
    }

    renderEmployerRadio() {
        return (
            <div
                style={{ marginBottom: 20 }}
            >
                <Radio.Group
                    buttonStyle='solid'
                    defaultValue='shifts'
                    size='large'
                    onChange={e => {
                        this.setState({ employerParam: e.target.value });
                    }}
                >
                    <Radio.Button value='shifts'>Pass</Radio.Button>
                    <Radio.Button value='bookings'>Bokningar</Radio.Button>
                </Radio.Group>
            </div>
        );
    }

    getEmployerTableData(employers) {
        return employers.map(employer => {
            let shifts = this.props.shiftsByEmployerId[employer.id];

            if (shifts.length) {
                shifts = filter(shifts, s => moment.unix(s.start_time).isSameOrBefore(moment().endOf('month'), 'day'));
            }

            return {
                ...employer,
                shifts: groupBy(shifts, s => moment.unix(s.start_time).format('YYYY-MM')),
            };
        });
    }

    getEmployerMonthlyBookingValue(shifts) {
        if (!shifts) {
            return 0;
        }

        const val = reduce(shifts, (sum, s) => {
            if (s.bookings.length || this.state.employerTableParam === 'shifts') {
                return sum + ((s.price * s.duration) - (s.price * s.shift_break));
            }

            return sum;
        }, 0);

        return val;
    }

    employerWasActiveInMonth(employer, month) {
        const employerRegMonth = moment.unix(employer.created_time);
        const m = moment(`${month}-01`);

        return employerRegMonth.isSameOrBefore(m, 'month');
    }

    renderMonthData() {
        const monthData = this.getMonthData();

        return (

            <div
                key={this.state.param}
                style={{ overflow: 'auto' }}
            >
                {map(monthData, (obj, month) => {
                    let mapObj = obj.retentionByMonth;
                    if (this.state.param === 'bookings') {
                        mapObj = obj.workByMonth;
                    }

                    if (this.state.param === 'shifts') {
                        mapObj = obj.shiftsByMonth;
                    }

                    return (
                        <div
                            style={{
                                display: 'flex',
                                minWidth: 80 * 20,
                            }}
                            // key={this.state.param + i}
                        >
                            <div
                                style={{
                                    width: 110,
                                    minWidth: 110,
                                    maxWidth: 110,
                                    height: 80,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    background: 'rgba(135, 81, 255)',
                                    paddingLeft: 15,
                                    color: '#FFF',
                                }}
                            >
                                <h6
                                    style={{
                                        margin: 0,
                                        color: '#FFF',
                                    }}
                                >
                                    {month}
                                </h6>
                                <div>
                                    {obj.consultantsRegistered.length}
                                    {' '}
                                    konsulter
                                </div>
                            </div>
                            {mapObj.map(activeInMonth => {
                                const percentage = Math.min(((activeInMonth.length / (obj.consultantsRegistered.length * 0.8)) * 100), 100).toFixed(1);
                                return (
                                    <div
                                        style={{
                                            width: 80,
                                            height: 80,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: `rgba(135, 81, 255, ${percentage / 100})`,
                                        }}
                                    >
                                        <div>
                                            {percentage}
                                            %
                                        </div>
                                        {/* <div
                                            style={{
                                                opacity: 0.5,
                                            }}
                                        >
                                            ({activeInMonth.length})
                                        </div> */}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>

        );
    }

    getEmployerAverageTurnover(employer) {
        const total = reduce(employer.shifts, (sum, shifts) => sum + this.getEmployerMonthlyBookingValue(shifts), 0);

        if (!total) {
            return 0;
        }

        return total / this.getEmployerActiveMonths(employer);
    }

    getEmployerAverageActiveTurnover(employer) {
        let activeMonths = 0;
        const total = reduce(employer.shifts, (sum, shifts) => {
            const ret = this.getEmployerMonthlyBookingValue(shifts);
            if (ret) {
                activeMonths += 1;
            }
            return sum + ret;
        }, 0);

        if (!total) {
            return 0;
        }

        return total / activeMonths;
    }

    getEmployerActiveMonths(employer) {
        const startMonth = moment.unix(employer.created_time).startOf('month');
        return moment().startOf('month').diff(startMonth, 'months') + 1;
    }

    renderEmployerTableRadio() {
        return (
            <Radio.Group
                buttonStyle='solid'
                className='radiogroup'
                defaultValue='shifts'
                size='large'
                onChange={e => {
                    this.setState({ employerTableParam: e.target.value });
                }}
            >
                <Radio.Button value='shifts'>Pass</Radio.Button>
                <Radio.Button value='bookings'>Bokningar</Radio.Button>
            </Radio.Group>
        );
    }

    renderEmployerTable() {
        const employers = filter(this.props.employers, e => this.props.shiftsByEmployerId[e.id]);
        const months = {};
        const startMonth = moment('2017-03-01');
        const diff = moment().startOf('month').diff(startMonth, 'months');

        for (let i = 0; i <= diff; ++i) {
            const m = startMonth;
            months[m.format('YYYY-MM')] = {
                employersRegistered: [],
                month: m.format('YYYY-MM'),
            };
            startMonth.add(1, 'month');
        }

        const columns = map(months, (m, key) => ({
            dataIndex: `month${key}`,
            month: key,
        }));

        const data = this.getEmployerTableData(employers);

        return (
            <div
                key={this.state.employerTableParam}
                style={{ marginTop: 40 }}
            >
                {this.renderEmployerTableRadio()}
                <div
                    style={{ overflow: 'auto' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: (100 * 13) + 200,
                            marginBottom: 10,
                        }}
                    >
                        <div
                            style={{
                                minWidth: 200,
                                maxWidth: 200,
                                width: 200,
                            }}
                        >
                            &nbsp;
                        </div>
                        {columns.map(c => (
                            <div
                                style={{
                                    minWidth: 80,
                                    maxWidth: 80,
                                    width: 80,
                                    textAlign: 'center',
                                    fontWeight: 'bold',

                                }}
                            >
                                {c.month}
                            </div>
                        ))}
                        <div
                            style={{
                                minWidth: 100,
                                maxWidth: 100,
                                width: 100,
                                textAlign: 'center',
                                fontWeight: 'bold',

                            }}
                        >
                            Genomsnitt
                        </div>
                        <div
                            style={{
                                minWidth: 120,
                                maxWidth: 120,
                                width: 120,
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            Genomsnitt, aktiv
                        </div>
                    </div>
                    {data.map(e => (
                        <div
                            style={{
                                display: 'flex',
                                width: (100 * 13) + 200,
                                borderBottom: '1px solid #CCC',
                            }}
                        >
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200,
                                    width: 200,
                                    padding: '10px 0',
                                }}
                            >
                                <div>
                                    {e.name}
                                </div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        opacity: 0.5,
                                    }}
                                >
                                    {e.department}
                                </div>
                            </div>
                            {columns.map(c => (
                                <div
                                    style={{
                                        minWidth: 80,
                                        maxWidth: 80,
                                        width: 80,
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        backgroundColor: this.employerWasActiveInMonth(e, c.month) ? 'lavender' : 'transparent',
                                    }}
                                >
                                    {this.employerWasActiveInMonth(e, c.month) ? this.getEmployerMonthlyBookingValue(e.shifts[c.month]).toLocaleString('sv-se') : '-'}
                                </div>
                            ))}
                            <div
                                style={{
                                    minWidth: 100,
                                    maxWidth: 100,
                                    width: 100,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                {this.getEmployerAverageTurnover(e).toLocaleString('sv-se')}
                                kr
                            </div>
                            <div
                                style={{
                                    minWidth: 100,
                                    maxWidth: 100,
                                    width: 100,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                {this.getEmployerAverageActiveTurnover(e).toLocaleString('sv-se')}
                                kr
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.ready) {
            return (
                <Loader />
            );
        }
        return (
            <main className='retention'>
                <h1 className='heading'>
                    Konsulter
                </h1>
                {this.renderRadio()}
                {this.renderMonthData()}

                <RetentionGraph
                    consultants={this.props.consultants}
                    shiftsByConsultantId={this.props.shiftsByConsultantId}
                />

                <section className='client'>
                    <h2>
                        Uppdragsgivare
                    </h2>
                    {this.renderEmployerMonthData()}
                    {this.renderEmployerTable()}
                    <EmployerActivityTable
                        employers={this.props.employers}
                        shiftsByEmployerId={this.props.shiftsByEmployerId}
                    />
                </section>
            </main>
        );
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        ...consultantActions, ...jobsActions, ...employerActions,
    }, dispatch),
)(Retention);
