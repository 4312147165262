import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table } from 'antd';

export const ConsultantLtvApplications = ({ applications }) => (
    <div key='ltvApplications' style={{ position: 'flex' }}>
        <h3
            style={{ marginTop: 20 }}
        >
            LTV Applications
        </h3>
        <Table
            columns={[{
                title: 'Employer',
                dataIndex: '',
                key: 'employer',
                width: 250,
                render: (_, record) => (
                    <Link to={`/employers/${record.employer.id}/long-term-vacancies`}>
                        {record.employer.name}
                    </Link>
                ),
            },
            {
                title: 'Date',
                dataIndex: 'start_time',
                key: 'start_time',
                render: (_, record) => `${moment.unix(record.start_time).format('YYYY-MM-DD')} - ${moment.unix(record.end_time).format('YYYY-MM-DD')}`,
            },
            {
                title: 'Applied at',
                dataIndex: 'created_time',
                key: 'created_time',
                render: createdTime => moment.unix(createdTime).format('YYYY-MM-DD HH:mm'),
            }]}
            dataSource={applications}
            pagination={false}
        />
    </div>
);
ConsultantLtvApplications.propTypes = {
    applications: PropTypes.arrayOf(PropTypes.shape({
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        start_time: PropTypes.number.isRequired,
        created_time: PropTypes.number.isRequired,
        end_time: PropTypes.number,
    })),
};
