import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as consultantActions from 'actions/consultants';
import * as jobsActions from 'actions/jobs';
import * as employerActions from 'actions/employers';
import * as sickLeaveActions from 'actions/sickleave';
import {
    groupBy,
    filter,
    map,
    find,
    sortBy,
    reduce,
} from 'lodash';
import Avatar from 'components/shared/avatar';
import {
    Timeline,
    Spin,
} from 'antd';

class TimelinePage extends React.Component {
    componentDidMount() {
        if (!this.props.jobs.length) {
            this.props.fetchJobs();
        }

        if (!this.props.consultants.length) {
            this.props.fetchConsultants();
        }

        if (!this.props.employers.length) {
            this.props.fetchEmployers();
        }

        this.props.fetchSickLeavedConsultants();
    }

    renderBookingEvent(eventObj) {
        const {
            eventTime, data,
        } = eventObj;
        const {
            employer, consultant, shifts, value,
        } = data;

        return (
            <div
                key={eventTime}
                style={{
                    marginBottom: 20,
                    display: 'flex',
                    fontSize: 14,
                }}
            >
                <div
                    style={{
                        marginRight: 20,
                        position: 'relative',
                    }}
                >
                    <Avatar
                        size={30}
                        url={consultant.avatar}

                    />
                </div>
                <div>
                    <div
                        style={{ color: '#999' }}
                    >
                        {moment.unix(eventTime).format('HH:mm')}
                    </div>
                    <div>
                        <Link to={`/employers/${employer.id}`}>{employer.name}</Link>
                        {' '}
                        bokade
                        {' '}
                        <Link to={`/consultants/${consultant.id}`}>
                            {consultant.firstname}
                            {' '}
                            {consultant.lastname}
                        </Link>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <strong>
                                {shifts}
                                {' '}
                                pass,
                                {' '}
                                {value.toLocaleString('sv-se')}
                                kr
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    renderPublishEvent(eventObj) {
        const {
            eventTime, data,
        } = eventObj;
        const {
            employer, shifts, value,
        } = data;

        return (

            <div
                key={eventTime}
                style={{
                    marginBottom: 20,
                    fontSize: 14,
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                }}
            >
                <div
                    style={{ marginRight: 20 }}
                >
                    <Avatar
                        size={30}
                        url={employer.logo_url}
                    />
                </div>
                <div
                    style={{ flex: 1 }}
                >
                    <div
                        style={{ color: '#999' }}
                    >
                        {moment.unix(eventTime).format('HH:mm')}
                    </div>
                    <div>
                        <Link to={`/employers/${employer.id}`}>{employer.name}</Link>
                        {' '}
                        publicerade
                        {' '}
                        {shifts}
                        {' '}
                        pass
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <strong>
                                {value.toLocaleString('sv-se')}
                                kr
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    renderSickLeave(eventObj) {
        const {
            eventTime, data,
        } = eventObj;
        const {
            consultant, start_time: startTime, end_time: endTime, reason,
        } = data;
        const duration = moment.unix(endTime).diff(moment.unix(startTime), 'days');
        const durationText = duration > 1 ? 'dagar' : 'dag';

        if (!consultant) {
            return null;
        }

        return (

            <div
                // key={eventTime}
                style={{
                    marginBottom: 20,
                    fontSize: 14,
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                }}
            >
                <div
                    style={{ marginRight: 20 }}
                >
                    <Avatar
                        size={30}
                        url={consultant.avatar}
                    />
                </div>
                <div
                    style={{ flex: 1 }}
                >
                    <div>
                        <Link to={`/consultants/${consultant.id}`}>
                            {consultant.firstname}
                            {' '}
                            {consultant.lastname}
                        </Link>
                        {' '}
                        sjukskrev sig
                        <strong
                            style={{ marginLeft: 5 }}
                        >
                            {duration}
                            {' '}
                            {durationText}
                        </strong>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <strong>
                                {moment.unix(startTime).format('YYYY-MM-DD')}
                                {' '}
                                -
                                {' '}
                                {moment.unix(endTime).format('YYYY-MM-DD')}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    renderEvent = eventObj => {
        let content;

        switch (eventObj.eventType) {
            case 'published':
                content = this.renderPublishEvent(eventObj);
                break;
            case 'booked':
                content = this.renderBookingEvent(eventObj);
                break;
            case 'sick':
                content = this.renderSickLeave(eventObj);
                break;
        }

        return (
            <Timeline.Item key={eventObj.eventTime} label='2015-09-01'>
                <div
                    style={{
                        position: 'relative',
                        top: -5,
                    }}
                >
                    {content}
                </div>
            </Timeline.Item>
        );
    }

    renderEvents() {
        const { events } = this.props;

        return map(events, (events, day) => {
            const m = moment.unix(events[0].eventTime);
            const isToday = m.isSame(moment(), 'day');

            return (
                <div
                    key={day}
                >
                    <div
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 20,
                            backgroundColor: '#FFF',
                            paddingTop: 20,
                            paddingBottom: 20,
                        }}
                    >
                        <h3
                            style={{ margin: 0 }}
                        >
                            {isToday
                                ? 'I dag'
                                : `${day.toUpperCase()} ${m.format('D/M')}`}
                        </h3>
                    </div>
                    <div
                        style={{ marginTop: 20 }}
                    >
                        <Timeline mode='left'>
                            {sortBy(events, 'eventTime').reverse().map(this.renderEvent)}
                        </Timeline>
                    </div>
                </div>
            );
        });
    }

    renderLoading() {
        return (
            <div
                style={{ marginTop: 100 }}
            >
                <Spin />
            </div>
        );
    }

    renderContent() {
        const {
            jobs, employers, consultants,
        } = this.props;
        if (!jobs.length || !employers.length || !consultants.length) {
            return (
                this.renderLoading()
            );
        }

        return this.renderEvents();
    }

    render() {
        return (
            <div
                // className="mpa-employers"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: '80px',
                    right: 0,
                    bottom: 0,
                    overflow: 'scroll',
                    paddingLeft: '20%',
                }}
            >

                <h2
                    style={{ marginTop: 60 }}
                >
                    Events
                </h2>
                {this.renderContent()}
            </div>
        );
    }
}

function selectFilteredJobs(state) {
    return filter(state.jobs.jobs, j => !j.employer.test_employer);
}

function selectShifts(jobs) {
    return jobs.reduce((arr, job) => arr.concat(job.shifts.map(shift => ({
        ...shift,
        occupation: job.occupation_id,
        employerId: job.employer.id,
        isBooked: shift.bookings.length > 0,
        introduction: job.introduction,
    }))), []);
}

function getSickLeaveEvents(state, ownProps) {
    const { sickLeavedConsultants } = state.sickLeave;

    return map(sickLeavedConsultants, sickLeave => ({
        eventTime: sickLeave.created_time,
        eventType: 'sick',
        data: {
            ...sickLeave,
            consultant: find(state.consultants.consultants, consultant => consultant.id === sickLeave.user.id),
        },
    }));
}

function getBookingEvents(state, ownProps) {
    const cutoff = moment().startOf('day').subtract(4, 'days').unix();

    const bookings = reduce(state.jobs.jobs, (arr, job) => arr.concat(job.bookings), []);

    return filter(bookings, b => b.booked_time > cutoff).map(booking => ({
        eventTime: booking.booked_time,
        eventType: 'booked',
        data: {
            shifts: booking.shifts.length,
            value: booking.price,
            consultant: booking.user,
            employer: find(state.employers.employers, { id: booking.employer.id }),
        },
    }));
}

function getPublishEvents(state, ownProps, shifts) {
    const cutoff = moment().startOf('day').subtract(4, 'days').unix();
    const filtered = filter(shifts, s => s.published_time && s.published_time > cutoff);
    const groupedShifts = groupBy(filtered, 'published_time');

    return map(groupedShifts, (shifts, time) => ({
        eventTime: parseInt(time, 10),
        eventType: 'published',
        data: {
            shifts: shifts.length,
            value: shifts.reduce((sum, s) => sum + s.duration * s.price, 0),
            employer: find(state.employers.employers, { id: +shifts[0].employerId }),
        },
    }));
}

function getEvents(state, ownProps, shifts) {
    const events = [];
    const publishEvents = getPublishEvents(state, ownProps, shifts);
    const bookingEvents = getBookingEvents(state, ownProps);
    const sickLeaveEvents = getSickLeaveEvents(state, ownProps);

    return sortBy(bookingEvents.concat(publishEvents, sickLeaveEvents), 'eventTime').reverse().slice(0, 500);
}

function mapStateToProps(state, ownProps) {
    const jobs = selectFilteredJobs(state);
    const shifts = selectShifts(jobs);
    const events = getEvents(state, ownProps, shifts);

    return {
        events: groupBy(sortBy(events, e => e.eventTime).reverse(), e => moment.unix(e.eventTime).startOf('day').format('dddd')),
        shifts,
        jobs,
        consultants: state.consultants.consultants,
        employers: state.employers.employers,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(
        {
            ...consultantActions,
            ...jobsActions,
            ...employerActions,
            ...sickLeaveActions,
        }, dispatch,
    ),
)(TimelinePage);
