import * as request from 'request';
import {
    mergeWith,
    isArray,
    uniqWith,
    isEqual,
    omit,
} from 'lodash';

export function requestFetchSettings() {
    return { type: 'REQUEST_FETCH_SETTINGS' };
}

export function receiveFetchSettings(settings) {
    return {
        type: 'RECEIVE_FETCH_SETTINGS',
        settings,
    };
}

const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) {
        return uniqWith(objValue.concat(srcValue), isEqual);
    }
    return objValue;
};

export function fetchSettings() {
    return async dispatch => {
        dispatch(requestFetchSettings());
        // TODO: Replace this with a separate endpoint for fetching the allowed countries,
        // so we can avoid a duplicate fetch of the Swedish settings here
        const sweSettings = await request.fetchSWESettings();

        const allowedCountries = sweSettings.allowed_countries;
        const requests = allowedCountries.map(country => ({
            country_code: country.country_code,
            req: request.fetchInternationalizedSettings(country.country_code),
        }));
        let mergedSettings = { allowed_countries: allowedCountries };

        const localeDependentKeys = [
            'secondary_department_types',
        ];
        const excludeKeys = [
            'allowed_countries',
        ];

        const fetchedSettings = await Promise.all(requests.map(r => r.req));

        fetchedSettings.forEach((localizedSettings, i) => {
            localeDependentKeys.forEach(key => {
                // eslint-disable-next-line no-param-reassign
                localizedSettings[key] = localizedSettings[key].map(obj => ({
                    ...obj,
                    country_code: requests[i].country_code,
                }));
            });
            mergedSettings = mergeWith(mergedSettings, omit(localizedSettings, excludeKeys), customizer);
        });

        dispatch(receiveFetchSettings(mergedSettings));
    };
}

export function requestFetchEmployerMargins() {
    return { type: 'REQUEST_FETCH_EMPLOYER_MARGINS' };
}

export function receiveFetchEmployerMargins(marginOptions) {
    return {
        type: 'RECEIVE_FETCH_EMPLOYER_MARGINS',
        marginOptions,
    };
}

export function fetchEmployerMargins() {
    return dispatch => {
        dispatch(requestFetchEmployerMargins());

        return request.fetchEmployerMargins()
            .then(resp => dispatch(receiveFetchEmployerMargins(resp.collection)));
    };
}
