const initialState = {
    responseAddingLead: {},
    isFetching: true,
    hasFetched: false,
};

export function leads(state = initialState, action) {
  switch (action.type) {
      case 'REQUEST_FETCH_LEADS':
          return {
              ...state, 
              isFetching: true,
          };

      case 'RECEIVE_RESPONSE_LEAD':
          return {
              ...state,
              responseAddingLead: action.data,
              isFetching: false,
              hasFetched: true,
          };

      default:
          return state;
  }
}
