import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout } from 'antd';

import Login from 'containers/login';
import NavSideBar from 'components/navsidebar';

import * as settingActions from 'actions/settings';
import * as jobsActions from 'actions/jobs';
import * as tagsActions from 'actions/tags';
import { fetchTasks } from 'actions/tasks';
import { getUser } from 'actions/session';

// global styles for app
import './styles/app.scss';

const {
    Sider,
} = Layout;

const App = ({
    children,
    session,
    settings,
    fetchEmployerMargins,
    fetchSettings,
    fetchTags,
    fetchTasks,
    getUser,
    tasks,
}) => {
    useEffect(() => {
        if (session.loggedIn) {
            fetchSettings();
            fetchTags();
            fetchEmployerMargins();
            fetchTasks();
            getUser();
        }
        else {
            fetchSettings();
        }
    }, [fetchSettings, fetchTags, fetchEmployerMargins, session.loggedIn, fetchTasks, getUser]);

    if (!settings.hasFetchedSettings) return null;

    if (!session.loggedIn) return <Login />;

    const theme = JSON.parse(process.env.IS_DEV) ? 'dark' : 'light';

    return (
        <Layout className='layout'>

            <Sider
                className='sider'
                collapsed
                theme={theme}
            >
                <a href='/'>
                    <div className='mpa-nav-sidebar__logo' />
                </a>
                <NavSideBar
                    session={session}
                    tasks={tasks}
                />
            </Sider>
            {children}
        </Layout>
    );
};

App.propTypes = {
    children: PropTypes.node,
    employers: PropTypes.shape({}).isRequired,
    tasks: PropTypes.shape({}).isRequired,
    jobs: PropTypes.shape({}).isRequired,
    session: PropTypes.shape({ loggedIn: PropTypes.bool }).isRequired,
    settings: PropTypes.shape({ hasFetchedSettings: PropTypes.bool }).isRequired,
    fetchEmployerMargins: PropTypes.func.isRequired,
    fetchSettings: PropTypes.func.isRequired,
    fetchTags: PropTypes.func.isRequired,
};

export default connect(
    state => state,
    {
        getUser,
        fetchTasks,
        ...tagsActions,
        ...jobsActions,
        ...settingActions,
    },
)(App);
