import * as request from 'request';
import { message } from 'antd';
import { isArray } from 'lodash';

export const requestFetchConsultants = () => ({ type: 'REQUEST_FETCH_CONSULTANTS' });

export const receiveConsultants = resp => ({
    type: 'RECEIVE_CONSULTANTS',
    consultants: resp.collection,
    count: resp.count,
    page: resp.page,
    totalCount: resp.total_count,
    totalPages: resp.total_pages,
});

export function receiveConsultantsCallList({ success }) {
    return {
        type: 'RECEIVE_CONSULTANTS_CALL_LIST', success,
    };
}

export const fetchConsultants = filters => async dispatch => {
    dispatch(requestFetchConsultants());
    try {
        dispatch(receiveConsultants(await request.fetchConsultants(filters)));
    }
    catch (e) {
        message.error('Failed to fetch consultants');
    }
};

export const fetchConsultantsV4 = filters => async dispatch => {
    dispatch(requestFetchConsultants());
    try {
        dispatch(receiveConsultants(await request.fetchConsultantsV4(filters)));
    }
    catch (e) {
        message.error('Failed to fetch consultants');
    }
};

export function requestRemoveConsultantFromBookedShift() {
    return { type: 'REQUEST_REMOVE_CONSULTANT_FROM_BOOKED_SHIFT' };
}

export function removeConsultantFromBookedShiftFailed() {
    return { type: 'REMOVE_CONSULTANT_FROM_BOOKED_SHIFT_FAILED' };
}

export function removeConsultantFromBookedShiftSuccess() {
    return { type: 'REMOVE_CONSULTANT_FROM_BOOKED_SHIFT_SUCCESS' };
}

export function removeShiftFromConsultantBooking(bookingId, shiftId) {
    return dispatch => {
        dispatch(requestRemoveConsultantFromBookedShift());

        return request.applicationRemoveShifts(bookingId, { shift_ids: [shiftId] }).then(() => {
            message.success('Passet har uppdaterats');
            dispatch(removeConsultantFromBookedShiftSuccess());
        }, error => {
            message.error(error.responseJSON.message);
        });
    };
}

export function replaceConsultantInBooking(originalBookingId, profileId, shiftId, replacementConsultantId) {
    return dispatch => new Promise((resolve, reject) => {
        // 1 Remove old booking on this shift
        // Needs: Consultant's booking ID and the shift ID
        request.applicationRemoveShifts(originalBookingId, { shift_ids: [shiftId] }).then(() => {
            // 2 Create a new booking with the consultant you want to use to replace
            // Needs user id and profile ID
            // 3 Add the shift we want the new consultant to be booked on to the booking we just created
            // Needs the shift ID and the profile ID
            request.createBookingForProfile(profileId, replacementConsultantId, [shiftId])
                .then(() => {
                    resolve();
                });
        }, error => {
            message.error(error.responseJSON.message);
        });
    });
}

export function createEmptyConsultantBooking(profileId, consultantId) {
    return dispatch => new Promise((resolve, reject) => {
        request.createBookingForProfile(profileId, consultantId).then(resp => {
            message.success(`A booking was created for consultant ${consultantId}`);
            resolve();
        }, error => {
            message.error(error.responseJSON.message);
            reject();
        });
    });
}

export function requestRemoveShiftConsultantFromApplication() {
    return { type: 'REQUEST_REMOVE_SHIFT_FROM_CONSULTANT_APPLICATION' };
}

export function removeShiftConsultantFromApplicationSuccess(shiftId, applicationId) {
    return {
        type: 'REMOVE_SHIFT_FROM_CONSULTANT_APPLICATION_SUCCESS',
        shiftId,
        applicationId,
    };
}

export function removeShiftFromConsultansApplication(applicationId, shiftId) {
    return dispatch => {
        const shifts = isArray(shiftId) ? shiftId : [shiftId];

        dispatch(requestRemoveShiftConsultantFromApplication());

        return request.applicationRemoveShifts(applicationId, { shift_ids: shifts }).then(() => {
            message.success('Shift has been removed from application');
            dispatch(removeShiftConsultantFromApplicationSuccess(shiftId, applicationId));
        }, error => {
            message.error(error.responseJSON.message);
        });
    };
}

export function requestSetTagOnUsersInIntercom() {
    return { type: 'REQUEST_SET_TAG_ON_USERS_IN_INTERCOM' };
}

export function setTagOnUsersInIntercomSuccess() {
    return { type: 'SET_TAG_ON_USERS_IN_INTERCOM_SUCCESS' };
}

export function setTagOnUsersInIntercomFail() {
    return { type: 'SET_TAG_ON_USERS_IN_INTERCOM_FAIL' };
}

export function setTagOnUsersInIntercom(tag, consultants) {
    return dispatch => {
        dispatch(requestSetTagOnUsersInIntercom());

        return request.setTagInIntercom({
            tag,
            user_ids: consultants,
        }).then(() => {
            message.success(`The tag "${tag}" has been set on ${consultants.length} consultants`);
            dispatch(setTagOnUsersInIntercomSuccess());
        }, error => {
            dispatch(setTagOnUsersInIntercomFail());
            message.error(error.responseJSON.message);
        });
    };
}

export const postConsultantsCallList = data => async dispatch => {
    try {
        const resp = await request.postConsultantsCallList(data);
        dispatch(receiveConsultantsCallList(resp));
    }
    catch {
        message.error('Failed to send call list');
    }
};
