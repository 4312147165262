import React from 'react';
import PropTypes from 'prop-types';
import {
    groupBy,
    map,
} from 'lodash';
import { Link } from 'react-router-dom';
import UserAttest from './userattest';

const AttestGroup = ({
    attests,
    batchReportScheduled,
    isUpdatingScheduled,
}) => {
    const { employer } = attests[0];
    const userAttest = groupBy(attests, a => a.user.id);

    return (
        <section className='employer content-card-full'>
            <h2 className='heading'>
                <Link to={`/employers/${employer.id}`}>
                    {employer.name}
                </Link>
            </h2>
            <p>
                {employer.account_name}
                {' '}
                (
                {' '}
                {employer.account_phone}
                {' '}
                )
            </p>
            {map(userAttest, a => (
                <UserAttest
                    attests={a}
                    batchReportScheduled={batchReportScheduled}
                    isUpdatingScheduled={isUpdatingScheduled}
                />
            ))}
        </section>
    );
};
AttestGroup.propTypes = {
    attests: PropTypes.arrayOf(PropTypes.shape({
        employer: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            account_name: PropTypes.string,
            account_phone: PropTypes.string,
        }),
    })),
    batchReportScheduled: PropTypes.func,
};
export default AttestGroup;
