import {
    filter,
    includes,
    without,
} from 'lodash';

const initialState = {
    job_postings: [],
    job_postingsFetching: true,
    hasFetched: false,
    isUpdating: false,
    job_postingIsFetching: false,
    job_posting: null,
};

export function recruitments(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_RECRUITMENTS':
            return {
                ...state, job_postingsFetching: true,
            };

        case 'RECEIVE_RECRUITMENTS':
            return {
                ...state,
                job_postings: action.job_postings,
                job_postingsIsFetching: false,
                hasFetched: true,
            };

        case 'REQUEST_FETCH_RECRUITMENT':
            return {
                ...state, job_postingsIsFetching: true,
            };

        case 'RECEIVE_RECRUITMENT':
            return {
                ...state,
                job_posting: action.job_posting,
                job_postingsIsFetching: false,
            };

        case 'REQUEST_UPDATE_RECRUITMENT':
            return {
                ...state,
                isUpdating: true,
            };

        default:
            return state;
    }
}
