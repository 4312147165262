import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Avatar from 'components/shared/avatar';

const BookingEvent = ({
    avatar,
    header,
    details,
    time,
}) => (
    <div className='timeline__event'>
        <Avatar
            size={30}
            url={avatar}
        />
        <div className='timeline__event-header'>
            <div>{header}</div>

            <div className='timeline__event-details'>
                {details}
                {time && (
                    <div className='timeline__event-time'>
                        {moment.unix(time).format('HH:mm')}
                    </div>
                )}
            </div>
        </div>
    </div>
);

BookingEvent.propTypes = {
    avatar: PropTypes.string,
    header: PropTypes.node.isRequired,
    details: PropTypes.string.isRequired,
    time: PropTypes.number,
};

export default BookingEvent;
