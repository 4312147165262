import config from 'config';

// marginOptions is an array of objects {value, name} with length eq to config.margins.highest
// 'name' can be another string instead if needed
export function getMarginOptions(nameKey = 'name') {
    return Array.from(
        { length: config.margins.highest },
        (_, idx) => ({
            value: idx + 1,
            [nameKey]: `${idx + 1}%`,
        }),
    );
}
