import React from 'react';
import { Pagination } from 'antd';
import moment from 'moment';

export const renderJobs = (data) => (data.map((jobPosting) => {
  const { job_posting_id, title, user_details, user_id, employer, employer_id, event_time } = jobPosting
  return (
    <div key={job_posting_id} className="flex items-center my-1 p-2 bg-gray-50">
      <div className='flex flex-col flex-1'>
        <div className='flex flex-col'>
          <span className='text-xs'>{moment.unix(event_time).format('YYYY-MM-DD')}</span>
          <a className='font-semibold' href={`employers/${employer_id}/job-ads#${title}`} target="_blank">{employer}</a>
          <span className='text-xs'>{title}</span>
          <div className='text-xs'>
            <a href={`consultants/${user_id}`} target="_blank">{user_details.firstname} {user_details.lastname}</a>
          </div>
        </div>
      </div>
    </div>
  )
}));

const JobTrackingEvents = ({ data, onChangePage }) => {
  if(data.collection.length === 0) {
    return <div className='flex my-4 text-lg'>No job posts found.</div>
  }
  return (
    <div>
      {renderJobs(data.collection)}
      <div className='flex items-center'>
        <Pagination current={data.page} onChange={onChangePage} total={data.totalPages} />
        <div className='ml-2 text-xs'>Number of job posts ({data.count})</div>
      </div>
    </div>
  );
};

export default JobTrackingEvents;
