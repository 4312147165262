import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Divider,
} from 'antd';

function Filters({
    filters,
    setFilters,
}) {
    return (
        <>
            <h4>Filters</h4>
            <Divider orientation='left' plain>
                Hide checked items
            </Divider>
            <Checkbox
                checked={filters.hasEmployer}
                onChange={() => setFilters(prevState => ({
                    ...prevState,
                    hasEmployer: !prevState.hasEmployer,
                }))}
            >
                Has employer
            </Checkbox>
            <Checkbox
                checked={filters.hasJob}
                onChange={() => setFilters(prevState => ({
                    ...prevState,
                    hasJob: !prevState.hasJob,
                }))}
            >
                Has profile
            </Checkbox>
            <Checkbox
                checked={filters.isHidden}
                onChange={() => setFilters(prevState => ({
                    ...prevState,
                    isHidden: !prevState.isHidden,
                }))}
            >
                Marked hidden
            </Checkbox>
        </>
    );
}

Filters.propTypes = {
    filters: PropTypes.shape({
        hasEmployer: PropTypes.bool.isRequired,
        hasJob: PropTypes.bool.isRequired,
        isHidden: PropTypes.bool.isRequired,
    }).isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default Filters;
