import React, {
    useEffect,
    useState,
} from 'react';
import {
    sortBy,
    filter,
} from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    confirmApplication,
    denyApplication,
    fetchApplications,
    fetchLtvApplications,
    setHasPatchedApplicaton,
    commentApplications,
} from 'actions/applications';
import LoadingScreen from 'components/shared/loadingscreen';
import './styles.scss';

import {
    Table,
    Avatar,
    Switch,
    Button,
    Tag,
    Tooltip,
} from 'antd';
import * as Icon from '@ant-design/icons';
import ApplicationModal from 'containers/applications/applicationmodal';
import LtvApplicationModal from 'containers/applications/ltvapplicationmodal';
import { PropTypes } from 'prop-types';
import Config from '../../config';
import {
    copyShiftsToClipboard,
    copyLtvToClipboard,
} from '../../utils/copyToClipboard';
import { fetchEmployerConsultant } from '../../request';

const Applications = ({
    applications,
    ltvApplications,
    applicationsFetching,
    hasFetched,
    rejectReasons,
    hasPatchedApplication,
    introducedLoading,
    isPatching,
    denyApplication,
    fetchApplications,
    fetchLtvApplications,
    confirmApplication,
    setHasPatchedApplicaton,
}) => {
    const [testAccounts, setTestAccounts] = useState(false);
    const [applicationModalData, setApplicationModalData] = useState();
    const [ltvApplicationModalData, setLtvApplicationModalData] = useState();
    const {
        colors,
        adminComments,
    } = Config;
    const [recordBeiingCopied, setRecordBeiingCopied] = useState(null);

    useEffect(() => {
        if (applicationModalData) {
            const data = applications
                .filter(app => app.user.id === applicationModalData.user.id
                && app.employer.id === applicationModalData.employer.id)[0];
            setApplicationModalData(data);
        }
    }, [applications, applicationModalData]);

    useEffect(() => {
        if (!hasFetched) {
            fetchApplications();
            fetchLtvApplications();
        }
    }, [fetchApplications, hasFetched, fetchLtvApplications]);

    const hasUpdatedComment = scheduled => {
        const sortedSheduled = sortBy(scheduled, 'created_time');
        let hasComment = false;
        for (let i = 0; i < sortedSheduled.length; i += 1) {
            if (sortedSheduled[i].comment) {
                hasComment = true;
            }
            if (hasComment && !sortedSheduled[i].comment) {
                return true;
            }
        }
        return false;
    };

    const sortedComments = scheduled => {
        const commentSummary = {};

        scheduled.sort((a, b) => (a.comment < b.comment ? -1 : 1)).forEach(s => {
            if (s.comment) {
                commentSummary[s.comment] = commentSummary[s.comment] ? commentSummary[s.comment] + 1 : 1;
            }
        });
        return commentSummary;
    };

    const concatenatedComments = scheduled => {
        const sorted = sortedComments(scheduled);
        let concatenated = '';
        Object.keys(sorted).forEach(key => {
            concatenated += key;
        });
        return concatenated;
    };

    const renderCommentLabels = scheduled => {
        const commentSummary = sortedComments(scheduled);
        const labels = [];

        Object.entries(commentSummary).forEach(entry => {
            const [key, value] = entry;
            const adminComment = adminComments.find(ac => ac.value === key);
            const {
                icon,
                colorId,
            } = adminComment;
            const color = colors.find(c => c.id === colorId);

            const CommentIcon = Icon[icon];
            labels.push(
                <Tag
                    key={key + value}
                    color={color.hex}
                    icon={<CommentIcon />}
                >
                    {`${adminComment.display} (${value})`}

                </Tag>,
            );
        });
        return <div>{labels}</div>;
    };

    const copyToClipboard = async (record, type) => {
        setRecordBeiingCopied(record);
        let text = '';

        if (type === 'shifts') {
            text = await copyShiftsToClipboard(record);
        }
        else {
            text = await copyLtvToClipboard(record);
        }

        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard');
            setRecordBeiingCopied(null);
        });
    };

    const renderApplications = all => {
        const consultants = all;

        const columns = [{
            title: 'Employer',
            dataIndex: 'employer',
            key: 'employer',
            render: (text, record) => {
                const { employer } = record;
                return (
                    <Link to={`/employers/${employer.id}`}>
                        {employer.name}
                    </Link>
                );
            },
            sorter: (a, b) => (a.employer.name > b.employer.name ? 1 : -1),
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const { user } = record;
                return (
                    <Link to={`/consultants/${user.id}`}>
                        <Avatar
                            src={user.avatar}
                            style={{ marginRight: 5 }}
                        >
                            {!user.avatar ? user.firstname[0] + user.lastname[0] : ''}
                        </Avatar>
                        {`${user.firstname} ${user.lastname}`}
                    </Link>
                );
            },
            sorter: (a, b) => (a.user.firstname > b.user.firstname ? 1 : -1),
        }, {
            title: 'Type',
            dataIndex: 'automotive',
            key: 'automotive',
            render: (text, record) => {
                const label = record.employer.automotive ? 'Automotive' : 'Maintained';
                const color = record.employer.automotive ? 'blue' : 'magenta';
                return (
                    <Tag
                        color={color}
                    >
                        {label}
                    </Tag>
                );
            },
            sorter: (a, b) => a.employer.automotive !== b.employer.automotive,
        }, {
            title: 'Shifts',
            dataIndex: 'shifts',
            key: 'shifts',
            render: (text, record) => record.scheduled.length,
            sorter: (a, b) => a.scheduled.length - b.scheduled.length,
        }, {
            title: 'Start time',
            dataIndex: 'firstshift',
            key: 'firstshift',
            render: (text, record) => moment.unix(
                record.scheduled.reduce((a, b) => (a.start_time < b.start_time ? a : b)).start_time,
            ).format('D MMM'),
            sorter: (a, b) => a.scheduled.reduce((a, b) => (a.start_time < b.start_time ? a : b)).start_time - b.scheduled.reduce((a, b) => (a.start_time < b.start_time ? a : b)).start_time,
        }, {
            title: 'Applied time',
            dataIndex: 'createdtime',
            key: 'createdtime',
            render: (text, record) => {
                const m = moment.unix(
                    record.scheduled.reduce((a, b) => (a.created_time < b.created_time ? a : b)).created_time,
                );
                const diff = moment().diff(m, 'days');
                const isOld = diff > 7;
                return (
                    <p className={`applied-time${isOld && ' -warn'}`}>
                        {m.format('D MMM')}
                        {isOld
                            && (
                                <span className='days'>
                                    {diff}
                                    {' '}
                                    days old
                                </span>
                            )}
                    </p>
                );
            },
            sorter: (a, b) => a.scheduled.reduce((a, b) => (a.created_time > b.created_time ? a : b)).created_time - b.scheduled.reduce((a, b) => (a.created_time > b.created_time ? a : b)).created_time,
        }, {
            title: 'Last updated',
            dataIndex: 'lastupdated',
            key: 'lastupdated',
            render: (text, record) => {
                const m = moment.unix(
                    record.scheduled.reduce((a, b) => (a.created_time > b.created_time ? a : b)).created_time,
                );
                return (
                    <p className={`applied-time${hasUpdatedComment(record.scheduled) && ' -warn'}`}>
                        <Tooltip title={m.format('D MMM')}>
                            {m.fromNow()}
                        </Tooltip>
                    </p>
                );
            },
            sorter: (a, b) => b.scheduled.reduce((a, b) => (a.created_time > b.created_time ? a : b)).created_time - a.scheduled.reduce((a, b) => (a.created_time > b.created_time ? a : b)).created_time,
        }, {
            title: 'Comments',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, record) => (
                renderCommentLabels(record.scheduled)
            ),
            sorter: (a, b) => (concatenatedComments(a.scheduled) > concatenatedComments(b.scheduled) ? 1 : -1),
        }, {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            render: (text, record) => (
                <div>
                    <Button
                        type='primary'
                        onClick={async () => {
                            const extraData = await fetchEmployerConsultant(record.employer.id, record.user.id);
                            setApplicationModalData({
                                ...record, adminComment: extraData.employer_consultant.admin_comment,
                            });
                        }}
                    >
                        View details / Book
                    </Button>
                    <Button
                        icon={<Icon.CopyOutlined />}
                        loading={recordBeiingCopied === record}
                        style={{ marginLeft: 4 }}
                        title='Copy to clipboard'
                        type='primary'
                        onClick={() => copyToClipboard(record, 'shifts')}
                    />
                </div>
            ),
        }];

        if (consultants.length) {
            return (
                <Table
                    columns={columns}
                    dataSource={consultants}
                    pagination={false}
                    style={{
                        overflow: 'scroll',
                        backgroundColor: 'fff',
                    }}
                    title={() => 'Backup applications'}
                />
            );
        }
        return <></>;
    };

    const renderLtvApplications = all => {
        const consultants = all;
        const columns = [{
            title: 'Employer',
            dataIndex: 'employer',
            key: 'employer',
            render: (text, record) => {
                const { employer } = record;
                return (
                    <Link to={`/employers/${employer.id}`}>
                        {employer.name}
                    </Link>
                );
            },
            sorter: (a, b) => (a.employer.name > b.employer.name ? 1 : -1),
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const { user } = record;
                return (
                    <Link to={`/consultants/${user.id}`}>
                        <Avatar
                            src={user.avatar}
                            style={{ marginRight: 5 }}
                        >
                            {!user.avatar ? user.firstname[0] + user.lastname[0] : ''}
                        </Avatar>
                        {`${user.firstname} ${user.lastname}`}
                    </Link>
                );
            },
            sorter: (a, b) => (a.user.firstname > b.user.firstname ? 1 : -1),
        }, {
            title: 'LTV id',
            dataIndex: 'job',
            key: 'job',
            render: (job, record) => job.id,
        }, {
            title: 'Applied time',
            dataIndex: 'created_time',
            key: 'createdtime',
            render: (createdTime, record) => {
                const m = moment.unix(createdTime);
                const diff = moment().diff(m, 'days');
                const isOld = diff > 7;
                return (
                    <p className={`applied-time${isOld && ' -warn'}`}>
                        {m.format('D MMM')}
                        {isOld
                            && (
                                <span className='days'>
                                    {diff}
                                    {' '}
                                    days old
                                </span>
                            )}
                    </p>
                );
            },
            sorter: (a, b) => a.created_time - b.created_time,
        }, {
            title: 'Type',
            dataIndex: 'automotive',
            key: 'automotive',
            render: (text, record) => {
                const label = record.employer.automotive ? 'Automotive' : 'Maintained';
                const color = record.employer.automotive ? 'blue' : 'magenta';
                return (
                    <Tag
                        color={color}
                    >
                        {label}
                    </Tag>
                );
            },
            sorter: (a, b) => a.employer.automotive !== b.employer.automotive,
        }, {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            render: (text, record) => (
                <div>
                    <Button
                        type='primary'
                        onClick={async () => {
                            const extraData = await fetchEmployerConsultant(record.employer.id, record.user.id);
                            setLtvApplicationModalData({
                                ...record, adminComment: extraData.employer_consultant.admin_comment,
                            });
                        }}
                    >
                        View details / Book
                    </Button>
                    <Button
                        icon={<Icon.CopyOutlined />}
                        loading={recordBeiingCopied === record}
                        style={{ marginLeft: 4 }}
                        title='Copy to clipboard'
                        type='primary'
                        onClick={() => copyToClipboard(record, 'ltv')}
                    />
                </div>
            ),
        }];

        if (consultants.length) {
            return (
                <Table
                    columns={columns}
                    dataSource={consultants}
                    pagination={false}
                    title={() => 'LTV Applications'}
                />
            );
        }
        return <></>;
    };

    const renderTestAccountFilter = () => (
        <>
            <Switch
                checked={testAccounts}
                style={{ marginRight: 5 }}
                onChange={() => { setTestAccounts(!testAccounts); }}
            />
            Include test accounts
        </>
    );

    const renderLtvApplicationModal = () => (

        <LtvApplicationModal
            adminComment={ltvApplicationModalData.adminComment}
            applicationId={ltvApplicationModalData.id}
            close={() => {
                setLtvApplicationModalData(null);
            }}
            consultant={ltvApplicationModalData.user}
            employer={ltvApplicationModalData.employer}
            employerId={ltvApplicationModalData.employer.id}
            introducedLoading={introducedLoading}
            ltvApplicationModalData={ltvApplicationModalData}
            userId={ltvApplicationModalData.user.id}
        />
    );

    if (!hasFetched) {
        return (
            <LoadingScreen
                fullScreen
                text='Sebastian är en ninja boy'
            />
        );
    }
    const all = testAccounts ? applications : applications.filter(a => !a.user.test_user);
    const allLtv = testAccounts ? ltvApplications : ltvApplications.filter(a => !a.user.test_user);
    return (
        <main className='mpa-consultants'>
            <header className='header'>
                <h3 className='heading'>
                    Applications
                    {' '}
                    {applicationsFetching && (
                        <span className='inline-spinner'><i className='fa fa-circle-o-notch fa-spin' /></span>
                    )}
                </h3>
                {renderTestAccountFilter()}
            </header>
            {renderApplications(all)}
            {allLtv && allLtv.length > 0 && renderLtvApplications(allLtv)}
            <p className='sum'>
                {applications.reduce((val, a) => val + a.price, 0).toLocaleString('sv-se')}
                kr
            </p>
            {ltvApplicationModalData ? renderLtvApplicationModal() : <></>}
            {applicationModalData ? (
                <ApplicationModal
                    adminComment={applicationModalData.adminComment}
                    close={() => {
                        if (hasPatchedApplication) {
                            setHasPatchedApplicaton(false);
                        }
                        setApplicationModalData(null);
                    }}
                    commentApplications={commentApplications}
                    confirmApplication={confirmApplication}
                    consultant={applicationModalData.user}
                    denyApplication={denyApplication}
                    employer={applicationModalData.employer}
                    hasPatchedApplication={hasPatchedApplication}
                    introducedLoading={introducedLoading}
                    isPatching={isPatching}
                    rejectReasons={rejectReasons}
                    scheduled={applicationModalData.scheduled}
                />
            ) : <></>}
        </main>
    );
};

Applications.propTypes = {
    applications: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstname: PropTypes.string.isRequired,
            lastname: PropTypes.string.isRequired,
            avatar: PropTypes.string.isRequired,
        }),
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            automotive: PropTypes.bool.isRequired,
        }).isRequired,
        scheduled: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            start_time: PropTypes.number.isRequired,
            created_time: PropTypes.number.isRequired,
        })).isRequired,
    })),
    ltvApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
    confirmApplication: PropTypes.func.isRequired,
    denyApplication: PropTypes.func.isRequired,
    fetchApplications: PropTypes.func.isRequired,
    rejectReasons: PropTypes.arrayOf(PropTypes.shape({})),
    isPatching: PropTypes.bool.isRequired,
    hasPatchedApplication: PropTypes.bool.isRequired,
    applicationsFetching: PropTypes.bool.isRequired,
    hasFetched: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        applications: sortBy(state.applications.applications, 'start_time'),
        ltvApplications: filter(state.applications.ltvApplications, a => a.status === 'PENDING'),
        applicationsFetching: state.applications.applicationsFetching,
        hasFetched: state.applications.hasFetched,
        rejectReasons: state.settings.reject_reasons,
        hasPatchedApplication: state.applications.hasPatchedApplication,
        isPatching: state.applications.isPatching,
        introducedLoading: state.consultant.introducedLoading,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        confirmApplication,
        fetchApplications,
        fetchLtvApplications,
        denyApplication,
        setHasPatchedApplicaton,
    }, dispatch),
)(Applications);
