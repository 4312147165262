import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    updateEmployer,
    fetchInvoiceEmployer,
    clearInvoiceEmployer,
} from 'actions/employer';
import InputModal from 'components/inputmodal';
import EditTrigger from 'components/shared/edittrigger';
import modalController from 'utils/modalcontroller';
import config from 'config';

const EmployerInvoiceAddress = ({
    employer, invoiceEmployer, updateEmployer, fetchInvoiceEmployer, hasFetchedInvoiceEmployer, clearInvoiceEmployer,
}) => {
    useEffect(() => {
        // Using != instead of !== because no pretty way of converting form value to int.
        if (invoiceEmployer && employer.invoice_address && invoiceEmployer.id != employer.invoice_address.customer_number) {
            clearInvoiceEmployer();
        }
        else if (employer.invoice_address && employer.invoice_address.customer_number && !hasFetchedInvoiceEmployer) {
            fetchInvoiceEmployer(employer.invoice_address.customer_number);
        }
    }, [employer, invoiceEmployer, hasFetchedInvoiceEmployer, fetchInvoiceEmployer, clearInvoiceEmployer]);
    
    const adr = employer.invoice_address ? employer.invoice_address : null;
    
    return (
        <div className='content-card-centered'>
            {adr && (<><div
                style={{
                    fontSize: 18,
                    fontWeight: 600,
                }}
            >
                {adr.name}
            </div>
            <div>
                {adr.department}
            </div>
            <div style={{ marginTop: 10 }}>
                {adr.addressline1}
            </div>
            <div>
                {adr.addressline2}
            </div>
            <div>
                {adr.postalcode}
                {' '}
                {adr.city}
            </div>
            <div>
                {adr.country}
            </div>
            <div style={{ marginTop: 10 }}>
                <strong>Ref </strong>
                {adr.reference || 'N/A'}
            </div>
            <div>
                <strong>Org no: </strong>
                {adr.organization_number}
            </div>
            <div>
                <strong>Customer no: </strong>
                {adr.customer_number}
                (
                {invoiceEmployer ? invoiceEmployer.name : ''}
                )
            </div></>)}

            <EditTrigger
                title='Edit invoice address'
                onClick={() => {
                    modalController.showModal(InputModal, {

                        title: 'Edit invoice address',
                        width: 600,
                        props: {
                            inputs: [{
                                type: 'input',
                                name: 'name',
                                label: 'Name',
                                value: adr ? adr.name : '',
                            }, {
                                type: 'input',
                                name: 'organization_number',
                                label: 'Organization number',
                                value: adr ? adr.organization_number : '',
                            }, {
                                type: 'input',
                                name: 'department',
                                label: 'Department',
                                value: adr ? adr.department : '',
                            }, {
                                type: 'input',
                                name: 'reference',
                                label: 'Reference',
                                value: adr ? adr.reference : '',
                            }, {
                                type: 'input',
                                name: 'addressline1',
                                label: 'Address',
                                value: adr ? adr.addressline1 : '',
                            }, {
                                type: 'input',
                                name: 'addressline2',
                                value: adr ? adr.addressline2 : '',
                            }, {
                                type: 'input',
                                name: 'postalcode',
                                label: 'Postal code',
                                value: adr ? adr.postalcode : '',
                            }, {
                                type: 'input',
                                name: 'city',
                                label: 'City',
                                value: adr ? adr.city : '',
                            }, {
                                type: 'select',
                                name: 'country_code',
                                label: 'Country',
                                options: config.countries.map(c => ({
                                    id: c.id,
                                    value: c.value,
                                    title: c.name,
                                })),
                                value: adr ? adr.country_code : '',
                            }, {
                                type: 'input',
                                name: 'customer_number',
                                label: 'Customer no',
                                value: adr ? adr.customer_number : '',
                            }],

                            onSave: formData => updateEmployer(employer.id, { invoice_address: formData }),

                        },

                    });
                }}
            />

        </div>
    );
};

EmployerInvoiceAddress.propTypes = {
    employer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        invoice_address: PropTypes.shape({
            addressline1: PropTypes.string,
            addressline2: PropTypes.string,
            name: PropTypes.string,
            organization_number: PropTypes.string,
            department: PropTypes.string,
            reference: PropTypes.string,
            postalcode: PropTypes.number,
            city: PropTypes.string,
            customer_number: PropTypes.number,
        }),
    }).isRequired,
    invoiceEmployer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    hasFetchedInvoiceEmployer: PropTypes.bool.isRequired,
    updateEmployer: PropTypes.func.isRequired,
    fetchInvoiceEmployer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    employer: state.employer.employer,
    invoiceEmployer: state.employer.invoiceEmployer,
    hasFetchedInvoiceEmployer: state.employer.hasFetchedInvoiceEmployer,
});
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        updateEmployer, fetchInvoiceEmployer, clearInvoiceEmployer,
    }, dispatch),
)(EmployerInvoiceAddress);
