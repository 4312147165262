import React from 'react';

import PropTypes from 'prop-types';
import './styles.scss';

const EditTrigger = ({
    title,
    style,
    onClick,
    className = '',
}) => (

    <a
        className={`${className} mpa-edit-trigger`}
        href='#'
        style={({ ...style })}
        onClick={e => {
            e.preventDefault();
            onClick();
        }}
    >
        <span className='mpa-edit-trigger__icon'>
            <span className='ion-edit' />
        </span>
        {title}
    </a>

);

EditTrigger.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    onClick: PropTypes.func.isRequired,
};

export default EditTrigger;
