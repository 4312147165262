import * as request from 'request';
import { message } from 'antd';

export function receiveConsultant(resp) {
    return {
        type: 'RECEIVE_CONSULTANT',
        consultant: resp.user,
    };
}
const receiveFetchConsultantScheduled = scheduled => ({
    type: 'RECEIVE_CONSULTANT_SCHEDULED',
    scheduled,
});

export const fetchConsultantScheduled = userId => async dispatch => {
    const resp = await request.fetchConsultantScheduled(userId);
    dispatch(receiveFetchConsultantScheduled(resp.collection));
};

const requestBatchUpdateScheduled = () => ({ type: 'REQUEST_BATCH_UPDATING_SCHEDULED' });
const receieveBatchUpdateScheduled = () => ({ type: 'RECEIVE_BATCH_UPDATING_SCHEDULED' });

export const batchUpdateScheduled = (userId, scheduledData) => async dispatch => {
    dispatch(requestBatchUpdateScheduled());
    try {
        await request.batchUpdateScheduled(scheduledData);
        message.success('Batch update was completed');
        // dispatch(confirmCommentApplicationsSuccess());
        dispatch(fetchConsultantScheduled(userId));
        dispatch(receieveBatchUpdateScheduled());
    }
    catch (e) {
        message.error('Failed to update scheduled');
        throw e;
    }
};

export function requestFetchConsultant() {
    return { type: 'REQUEST_FETCH_CONSULTANT' };
}

export function fetchConsultant(userId) {
    return dispatch => {
        dispatch(requestFetchConsultant());
        return request.fetchConsultant(userId)
            .then(resp => dispatch(receiveConsultant(resp)));
    };
}

export const requestFetchConsultantSSN = () => ({ type: 'REQUEST_FETCH_CONSULTANT_SSN' });

export const receiveConsultantSSN = ssn => ({
    type: 'RECEIVE_CONSULTANT_SSN',
    ssn,
});

export const fetchConsultantSSN = consultantId => async dispatch => {
    dispatch(requestFetchConsultantSSN());
    try {
        const resp = await request.fetchConsultantSSN(consultantId);
        dispatch(receiveConsultantSSN(resp.ssn));
    }
    catch (e) {
        message.error('Failed to fetch consultant ssn');
        throw e;
    }
};

export function requestUpdateConsultant() {
    return { type: 'REQUEST_UPDATE_CONSULTANT' };
}

export function receiveUpdateConsultant(id, data) {
    return {
        type: 'RECEIVE_UPDATE_CONSULTANT',
        id,
        data,
    };
}

export function requestSupplierByConsultantID() {
    return { type: 'REQUEST_SUPPLIER_BY_ID' };
}

export function receiveSupplierByConsultantID(data) {
    return {
        type: 'RECEIVE_SUPPLIER_BY_ID',
        data,
    };
}

export function requestConsultantResetPassword() {
    return { type: 'REQUEST_RESET_CONSULTANT_PASSWORD' };
}

export function receiveConsultantResetPassword() {
    return { type: 'RECEIVE_RESET_CONSULTANT_PASSWORD' };
}

export function resetConsultantPassword(id) {
    return dispatch => {
        dispatch(requestConsultantResetPassword());
        return request.resetConsultantPassword(id)
            .then(dispatch(receiveConsultantResetPassword(id)));
    };
}

export function updateConsultant(id, data) {
    return dispatch => {
        dispatch(requestUpdateConsultant());
        return request.updateConsultant(id, data)
            .then(dispatch(receiveUpdateConsultant(id, data)));
    };
}

export function updateSupplier(data) {
    return dispatch => {
        dispatch(requestSupplierByConsultantID());
        return request.updateSupplier(data)
            .then(reponse => dispatch(receiveSupplierByConsultantID(reponse)));
    };
}

export function fetchSupplierByConsultantID(id) {
    return dispatch => {
        dispatch(requestSupplierByConsultantID());
        return request.fetchSupplierByConsultantID(id)
            .then(reponse => dispatch(receiveSupplierByConsultantID(reponse)));
    };
}

export function requestLogOutConsultant() {
    return { type: 'REQUEST_LOGOUT_CONSULTANT' };
}

export function reveiveLogOutConsultant() {
    return { type: 'RECEIVE_LOGOUT_CONSULTANT' };
}

export function logOutConsultant(consultantId) {
    return dispatch => {
        dispatch(requestLogOutConsultant());
        return request.logoutConsultant(consultantId)
            .then(dispatch(reveiveLogOutConsultant()));
    };
}

export function requestFetchConsultantBookings() {
    return { type: 'REQUEST_FETCH_CONSULTANT_BOOKINGS' };
}

export function receiveFetchConsultantBookings(applications) {
    return {
        type: 'RECEIVE_FETCH_CONSULTANT_BOOKINGS',
        applications,
    };
}

export function fetchConsultantBookings(userId) {
    return dispatch => {
        dispatch(requestFetchConsultantBookings());
        return request.fetchConsultantBookings(userId)
            .then(resp => dispatch(receiveFetchConsultantBookings(resp.collection)));
    };
}

export function requestFetchConsultantLtvApplications() {
    return { type: 'REQUEST_FETCH_CONSULTANT_LTV_APPLICATIONS' };
}

export function receiveFetchConsultantLtvApplcations(applications) {
    return {
        type: 'RECEIVE_FETCH_CONSULTANT_LTV_APPLICATIONS',
        applications,
    };
}

export function fetchConsultantLtvApplications(userId) {
    return dispatch => {
        dispatch(requestFetchConsultantLtvApplications());
        return request.fetchConsultantLtvApplications(userId)
            .then(resp => dispatch(receiveFetchConsultantLtvApplcations(resp.collection)));
    };
}

export function unregisterConsultant(consultantId) {
    return dispatch => request.unregisterConsultant(consultantId);
}

export function requestFetchConsultantTimeReports() {
    return { type: 'REQUEST_FETCH_CONSULTANT_TIME_REPORTS' };
}

export function receiveFetchConsultantTimeReports(timeReports) {
    return {
        type: 'RECEIVE_FETCH_CONSULTANT_TIME_REPORTS',
        timeReports,
    };
}

export function requestBanConsultant() {
    return { type: 'REQUEST_BAN_CONSULTANT' };
}

export function receiveBanConsultant() {
    return { type: 'RECEIVE_BAN_CONSULTANT' };
}

export function banConsultant(consultantId, reason) {
    return dispatch => {
        dispatch(requestBanConsultant());
        return request.banConsultant(consultantId, reason)
            .then(_ => dispatch(receiveBanConsultant()));
    };
}

export function requestUnBanConsultant() {
    return { type: 'REQUEST_UN_BAN_CONSULTANT' };
}

export function receiveUnBanConsultant() {
    return { type: 'RECEIVE_UN_BAN_CONSULTANT' };
}

export function unBanConsultant(consultantId, reason) {
    return dispatch => {
        dispatch(requestUnBanConsultant());
        return request.unBanConsultant(consultantId, reason)
            .then(_ => dispatch(receiveUnBanConsultant()));
    };
}

export function fetchConsultantTimeReports(consultantId) {
    return dispatch => {
        dispatch(requestFetchConsultantTimeReports());
        return request.fetchConsultantTimeReports(consultantId)
            .then(resp => {
                dispatch(receiveFetchConsultantTimeReports(resp.collection));
            });
    };
}

export function requestFetchConsultantPayments() {
    return { type: 'REQUEST_FETCH_CONSULTANT_PAYMENTS' };
}

export function receiveFetchConsultantPayments(payments, pendingSalary, preliminarySalary) {
    return {
        type: 'RECEIVE_FETCH_CONSULTANT_PAYMENTS',
        payments,
        pendingSalary,
        preliminarySalary,
    };
}

export function fetchConsultantPayments(consultantId) {
    return dispatch => {
        dispatch(requestFetchConsultantPayments());
        return request.fetchConsultantPayments(consultantId)
            .then(resp => {
                dispatch(receiveFetchConsultantPayments(resp.collection, resp.pending_salary, resp.preliminary_salary));
            });
    };
}

export function requestFetchConsultantContracts() {
    return { type: 'REQUEST_FETCH_CONSULTANT_CONTRACTS' };
}

export function receiveFetchConsultantContracts(contracts) {
    return {
        type: 'RECEIVE_FETCH_CONSULTANT_CONTRACTS',
        contracts,
    };
}

export function fetchConsultantContracts(consultantId) {
    return dispatch => {
        dispatch(requestFetchConsultantContracts());
        return request.fetchConsultantContracts(consultantId)
            .then(resp => {
                dispatch(receiveFetchConsultantContracts(resp.collection));
            });
    };
}
export const requestUpdateBookingPeriod = () => ({ type: 'REQUEST_UPDATE_BOOKING_PERIOD' });

export const updateBookingPeriod = (bookingPeriodId, data) => dispatch => {
    dispatch(requestUpdateBookingPeriod());
    return request.updateBookingPeriod(bookingPeriodId, data)
        .then(resp => {
            dispatch(receiveFetchConsultantContracts(resp.collection));
        });
};

export const receiveFetchConsultantHosp = hospChecks => ({
    type: 'RECEIVE_FETCH_CONSULTANT_HOSP',
    hospChecks,
});

export const fetchConsultantHosp = consultantId => async dispatch => {
    try {
        const resp = await request.fetchConsultantHosp(consultantId);
        dispatch(receiveFetchConsultantHosp(resp.collection));
    }
    catch (e) {
        message.error('Failed to fetch hosp for consultant');
        throw e;
    }
};

export const receiveCheckConsultantHosp = resp => ({
    type: 'RECEIVE_CHECK_CONSULTANT_HOSP',
    resp,
});

export const checkConsultantHosp = consultantId => async dispatch => {
    try {
        const resp = await request.checkConsultantHosp(consultantId);
        dispatch(receiveCheckConsultantHosp(resp));
    }
    catch (e) {
        message.error(e.responseJSON.message);
        throw e;
    }
};

export const requestFetchConsultantActivities = () => ({ type: 'REQUEST_FETCH_CONSULTANT_ACTIVITIES' });

export const receiveFetchConsultantActivities = activities => ({
    type: 'RECEIVE_FETCH_CONSULTANT_ACTIVITIES',
    activities,
});

export function fetchConsultantActivities(consultantId) {
    return dispatch => {
        dispatch(requestFetchConsultantActivities());
        return request.fetchConsultantActivities(consultantId)
            .then(resp => {
                dispatch(receiveFetchConsultantActivities(resp.collection));
            });
    };
}

export const receiveConsultantUploads = uploads => ({
    type: 'RECEIVE_FETCH_CONSULTANT_UPLOADS',
    uploads,
});

export const fetchConsultantUploads = consultantId => async dispatch => {
    try {
        const resp = await request.fetchUserUploads(consultantId);
        dispatch(receiveConsultantUploads(resp.collection));
    }
    catch (e) {
        message.error('Failed to fetch consultant uploads');
        throw e;
    }
};

export const receiveFetchConsultantEmployers = employers => ({
    type: 'RECEIVE_FETCH_CONSULTANT_EMPLOYERS',
    employers,
});

export const fetchConsultantEmployers = consultantId => async dispatch => {
    try {
        const resp = await request.fetchConsultantEmployers(consultantId);
        dispatch(receiveFetchConsultantEmployers(resp.collection));
    }
    catch (e) {
        message.error('Failed to fetch employers for consultant');
        throw e;
    }
};

export const receiveUpdateConsultantEmployer = consultantEmployer => ({
    type: 'RECEIVE_UPDATE_CONSULTANT_EMPLOYER',
    consultantEmployer,
});

export const requestUpdateConsultantEmployer = () => ({ type: 'REQUEST_UPDATE_CONSULTANT_EMPLOYER' });

export const updateConsultantEmployer = (consultantId, employerId, requestData) => async dispatch => {
    dispatch(requestUpdateConsultantEmployer());
    try {
        const resp = await request.updateConsultantEmployer(consultantId, employerId, requestData);
        dispatch(receiveUpdateConsultantEmployer(resp.employer_consultant));
        message.success('Updated consultant employer.');
    }
    catch (e) {
        message.error('Hmm. Something is wrong. Cant read the data. Be nice to tech!');
        throw e;
    }
};

export const receiveFetchConsultantWageTax = wageTax => ({
    type: 'RECEIVE_FETCH_CONSULTANT_WAGE_TAX',
    wageTax,
});

export const fetchConsultantWageTax = consultantId => async dispatch => {
    request.fetchUserWageTax(consultantId)
        .then(resp => {
            dispatch(receiveFetchConsultantWageTax(resp.collection));
        })
        .catch(() => {
            message.error('Failed to fetch consultant wage tax data');
        });
};

const requestFetchJobPostingsByUser = () => ({ type: 'REQUEST_FETCH_JOB_POSTING_BY_USER' });

const receiveFetchJobPostingsByUser = data => ({
    type: 'RECEIVE_FETCH_JOB_POSTING_BY_USER',
    data,
});

export const fetchJobPostingsByUserV1 = userId => dispatch => {
    dispatch(requestFetchJobPostingsByUser());
    return request.fetchJobPostingsByUserV1(userId)
        .then(resp => {
            dispatch(receiveFetchJobPostingsByUser({
                ...resp, userId,
            }));
        });
};
