import React, {useMemo} from 'react';
import { DatePicker } from 'antd';

const DateSpan = ({ onClick, selected, title, type, className='' }) => {
  const getSavedValue = (selected) => {
    return [
      {
        name: 'Start date',
        id: 'start_date',
        placeholder:"Select start date"
      },
      {
        name: 'End date',
        id: 'end_date',
        placeholder:"Select end date"
      }
      ].map((item) => {
        const placeholder = (selected && selected[item.id]) ? selected[item.id][0].split('::')[0] : item.placeholder;
        return (<DatePicker
          key={item.name}
          style={{ width: 180, marginRight: 8 }}
          placeholder={placeholder}
          onChange={(d) => {
            if(d) {
              onClick(item.id, `${d.format('YYYY-MM-DD')}::${item.name}`, type)
            }
          }}
        />)
    })
  }

  const renderDatePickers = useMemo(() => getSavedValue(selected));

  return (
    <div className={`flex flex-col mt-4 ${className}`}>
      <h5 className='cursor-pointer'>{title}</h5>
      <div className='flex'>{ renderDatePickers }</div>
    </div>
  );
};

export default DateSpan;
