import React from 'react';
import moment from 'moment';
import { includes } from 'lodash';
import {
    Radio,
    Table,
} from 'antd';

export default class EmployerActivityTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = { param: 'shifts' };
    }

    getData() {
        const { employers } = this.props;
        let months = [];
        const startMonth = moment('2017-03-01');
        const diff = moment().diff(startMonth, 'months');

        for (let i = 0; i <= diff; ++i) {
            const m = startMonth;

            months.push({
                month: m.format('YYYY-MM'),
                newEmployers: 0,
                totalEmployers: 0,
                activeEmployers: 0,
                totalActiveEmployers: [],
            });

            startMonth.add(1, 'month');
        }

        employers.forEach(c => {
            const reg = moment.unix(c.created_time).startOf('month').format('YYYY-MM');

            months = months.map(m => {
                let wasActive = false;
                const wasActiveOnOrBefore = false;
                const shifts = this.props.shiftsByEmployerId[c.id];

                if (shifts) {
                    shifts.forEach(s => {
                        if (moment.unix(s.start_time).format('YYYY-MM') === m.month) {
                            if (this.state.param === 'shifts' || (this.state.param === 'bookings' && s.bookings.length)) {
                                wasActive = true;
                            }
                        }

                        if (moment.unix(s.start_time).format('YYYY-MM') <= m.month) {
                            if (this.state.param === 'shifts' || (this.state.param === 'bookings' && s.bookings.length)) {
                                if (!includes(m.totalActiveEmployers, c.id)) {
                                    m.totalActiveEmployers.push(c.id);
                                }
                            }
                        }
                    });
                }

                return {
                    ...m,
                    newEmployers: reg === m.month ? m.newEmployers + 1 : m.newEmployers,
                    totalEmployers: reg <= m.month ? m.totalEmployers + 1 : m.totalEmployers,
                    activeEmployers: wasActive ? m.activeEmployers + 1 : m.activeEmployers,
                };
            });
        });

        return months;
    }

    renderRadio() {
        return (
            <Radio.Group
                buttonStyle='solid'
                className='radiogroup'
                defaultValue='shifts'
                size='large'
                onChange={e => {
                    this.setState({ param: e.target.value });
                }}
            >
                <Radio.Button value='shifts'>Pass</Radio.Button>
                <Radio.Button value='bookings'>Bokningar</Radio.Button>
            </Radio.Group>
        );
    }

    render() {
        const data = this.getData();

        const columns = [{
            dataIndex: 'month',
            key: 'month',
            title: 'Månad',
        }, {
            dataIndex: 'newEmployers',
            key: 'newEmployers',
            title: 'Nya uppdragsgivare',
        }, {
            dataIndex: 'totalEmployers',
            key: 'totalEmployers',
            title: 'Totalt antal uppdragsgivare',
        }, /* {
            dataIndex: 'activeEmployers',
            key: 'activeEmployers',
            title: 'Aktiva konsulter',
        }, */ {
            dataIndex: 'activityPercentage',
            key: 'activityPercentage',
            title: 'Aktiveringsgrad',
            render: (text, record) => `${((record.activeEmployers / record.totalEmployers) * 100).toFixed(1)}%`,
        }, {
            dataIndex: 'totalActivityPercentage',
            key: 'totalActivityPercentage',
            title: 'Total aktiveringsgrad',
            render: (text, record) => `${((record.totalActiveEmployers.length / record.totalEmployers) * 100).toFixed(1)}%`,
        }];

        return (
            <div
                style={{ marginTop: 40 }}
            >
                {this.renderRadio()}
                <div
                    style={{ backgroundColor: '#FFF' }}
                >
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            </div>
        );
    }
}
