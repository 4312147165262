import {
    filter,
    includes,
} from 'lodash';

const initialState = {
    invoiceList: [],
    isFetching: false,
    hasFetched: false,
    isExporting: false,
};

const invoiceList = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_INVOICE_LIST':
            return {
                ...state,
                isFetching: true,
                hasFetched: false,
            };

        case 'RECEIVE_INVOICE_LIST':
            return {
                ...state,
                invoiceList: action.invoiceList,
                isFetching: false,
                hasFetched: true,
            };

        case 'REQUEST_EXPORT_INVOICE_LIST':
            return {
                ...state,
                isExporting: true,
            };

        case 'RECEIVE_EXPORT_INVOICE_LIST':
            return {
                ...state,
                isExporting: false,
                invoiceList: filter(state.invoiceList, item => !includes(action.employerIds, item.employer.id)),
            };

        default:
            return state;
    }
};
export default invoiceList;
