import * as request from 'request';

const requestFetchJobPostingTracking = () => ({ type: 'REQUEST_FETCH_JOB_POSTING_TRACKING' });


const factorFilter = (jobPostingTrackingId) => {
    let filteredObject = { ...jobPostingTrackingId, }
    for (const key in jobPostingTrackingId) {
        const value = Array.isArray(jobPostingTrackingId[key]) ? jobPostingTrackingId[key][0] : jobPostingTrackingId[key]
        filteredObject = {
            ...filteredObject,
            [key]: value
        }
    }
    return filteredObject
}

const receiveFetchJobPostingTracking = data => ({
    type: 'RECEIVE_FETCH_JOB_POSTING_TRACKING',
    data,
});

export const fetchJobPostingTrackingV1 = jobPostingTrackingId => dispatch => {
    dispatch(requestFetchJobPostingTracking());
    return request.fetchJobPostingTrackingV1(factorFilter(jobPostingTrackingId))
        .then(resp => {
            dispatch(receiveFetchJobPostingTracking(resp));
        });
};

