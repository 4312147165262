import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    Avatar,
    Button,
    Input,
    Spin,
    Tag,
} from 'antd';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { occupationTitle } from 'utils/nameformatters';
import { Link } from 'react-router-dom';
import {
    fetchConsultants,
    setTagOnUsersInIntercom,
} from '../../actions/consultants';

import './styles.scss';
import useEmployerOccupationsIds from '../../hooks/useEmployerOccupationsIds';
import {
    skillTitle,
    specializationTitle,
} from '../../utils/nameformatters';
import usePrevious from '../../hooks/usePrevious';

const getAttributeFiltersByOccupation = (occupationIds, availableAttrs = []) => {
    // don't show filters if there are no available attributes
    if (!availableAttrs.length) {
        return null;
    }
    return occupationIds.map(occupationId => {
        const occupationChildren = availableAttrs.filter(s => s.occupation_id === occupationId);
        const occupationName = occupationTitle(occupationId);
        return {
            text: occupationName,
            value: occupationName,
            children: occupationChildren.map(s => ({
                text: s.name,
                value: s.id,
            })),
        };
    });
};

const ModalContent = ({
    refetchConsultants,
    userConditions,
    showDistance,
}) => {
    const dispatch = useDispatch();
    const settingIntercomTag = useSelector(state => state.consultants.settingIntercomTag);

    const [selectedConsultants, setSelectedConsultants] = useState([]);
    const [tag, setTag] = useState('');
    const [filteredInfo, setFilteredInfo] = useState(null);
    const occupationIds = useEmployerOccupationsIds();
    const previousOccupationIds = usePrevious(occupationIds);

    const employer = useSelector(state => state.employer.employer);
    const consultants = useSelector(state => state.consultants.consultants);
    const isFetching = useSelector(state => state.consultants.consultantsFetching);
    const allConsultants = useSelector(state => state.employer.allConsultants.collection);

    const consultantsList = useMemo(() => ((allConsultants && userConditions)
        ? allConsultants.filter(userConditions).map(c => c.user)
        : consultants),
    [allConsultants, consultants, userConditions]);

    const skills = useSelector(state => {
        const { skills: allSkills } = state.settings;
        return allSkills.filter(s => occupationIds.includes(s.occupation_id));
    });
    const specializations = useSelector(state => {
        const { specializations: allSpecializations } = state.settings;
        return allSpecializations.filter(s => occupationIds.includes(s.occupation_id));
    });

    const fetchCloseConsultants = useCallback((filters = {}) => {
        if (employer.address) {
            dispatch(fetchConsultants({
                includeTestAccounts: employer.test_employer,
                perPage: 100,
                geoDistance: {
                    distance: '1500km',
                    geo_point: {
                        lat: employer.address.lat,
                        lon: employer.address.lng,
                    },
                },
                pureSort: [
                    { _geo_distance: {
                        geo_point: {
                            lat: employer.address.lat,
                            lon: employer.address.lng,
                        },
                        order: 'asc',
                        unit: 'km',
                        distance_type: 'plane',
                    } },
                ],
                ...filters,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, employer.address, employer.test_employer]);

    useEffect(() => {
        if (occupationIds.length && !previousOccupationIds.length) {
            fetchCloseConsultants({ occupation: occupationIds });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [occupationIds, previousOccupationIds]);

    const handleTableChange = (_, filters) => {
        setFilteredInfo(filters);
        const filteringKeys = Object.keys(filters);

        if (refetchConsultants) {
            // We need to clear this data because antd sets as null filters that we need as undefined
            const populatedFilters = filteringKeys.filter(f => filters[f] != null).reduce((acc, k) => ({
                [k]: filters[k],
                ...acc,
            }), {});

            const fixedFilters = {};
            if (populatedFilters.specialization_ids) {
                fixedFilters.specializations = populatedFilters.specialization_ids.filter(s => typeof s === 'number');
            }
            if (populatedFilters.skills) {
                fixedFilters.skills = populatedFilters.skills.filter(s => typeof s === 'number');
            }
            if (!populatedFilters.occupation) {
                fixedFilters.occupation = occupationIds;
            }

            fetchCloseConsultants({
                ...populatedFilters,
                ...fixedFilters,
            });
        }

        // de-select selected consultants that are not being filtered
        const updatedConsultants = selectedConsultants
            .filter(consultant => filteringKeys.reduce((acc, key) => {
                if (!filters[key]) {
                    return acc;
                }
                let isFiltered;
                if (Array.isArray(consultant[key])) {
                    isFiltered = consultant[key].some(k => filters[key].includes(k));
                }
                else {
                    isFiltered = filters[key].includes(consultant[key]);
                }
                return acc && isFiltered;
            }, true));
        setSelectedConsultants(updatedConsultants);
    };

    const renderConsultants = () => {
        if (!consultantsList || isFetching) {
            return <Spin size='large' style={{ padding: 30 }} />;
        }

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedConsultants(selectedRows);
            },
            getCheckboxProps: record => ({

                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,

            }),
            selectedRowKeys: selectedConsultants.map(c => c.id),
        };

        const columns = [{
            title: 'User ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        }, {
            title: 'Image',
            dataIndex: 'image',
            render: (text, record) => (record.avatar ? <Avatar size='large' src={record.avatar} /> : null),
            width: 100,
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, user) => (
                <Link to={`/consultants/${user.id}`}>
                    {user.firstname}
                    {' '}
                    {user.lastname}
                </Link>
            ),
        }, {
            title: 'Occupation',
            dataIndex: 'occupation',
            key: 'occupation',
            render: (_, user) => (occupationTitle(user.occupation)),
            filters: occupationIds.map(occupationId => ({
                text: occupationTitle(occupationId),
                value: occupationId,
            })),
            filteredValue: filteredInfo ? filteredInfo.occupation : null,
            onFilter: (value, record) => record.occupation === value,
        }, {
            title: 'Specialization',
            dataIndex: 'specialization',
            key: 'specialization_ids',
            render: (_, user) => {
                if (user && user.specialization_ids) {
                    return user.specialization_ids.map(specId => (
                        <Tag key={user.id + specId}>{specializationTitle(specId)}</Tag>
                    ));
                }
                return null;
            },
            filters: getAttributeFiltersByOccupation(occupationIds, specializations),
            filterMode: 'tree',
            filteredValue: filteredInfo ? filteredInfo.specialization_ids : null,
            onFilter: (value, record) => record.specialization_ids && record.specialization_ids.includes(value)
            ,

        }, {
            title: 'Skill',
            dataIndex: 'skill',
            key: 'skills',
            render: (_, user) => {
                if (user && user.skills) {
                    return user.skills.map(skillId => (
                        <Tag key={user.id + skillId}>{skillTitle(skillId, user.occupation)}</Tag>
                    ));
                }
                return null;
            },
            filters: getAttributeFiltersByOccupation(occupationIds, skills),
            filterMode: 'tree',
            filteredValue: filteredInfo ? filteredInfo.skills : null,
            onFilter: (value, record) => record.skills && record.skills.includes(value),
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        }, {
            title: 'Rating',
            dataIndex: 'rating',
            render: (text, record) => (record.rating ? record.rating.user_rating || 'not set' : null),
            width: 100,
        }];
        if (showDistance) {
            columns.push({
                title: 'Distance',
                dataIndex: 'sort',
                render: (text, user) => (user.sort ? `${Math.round(user.sort[0], 2)} km` : null),
                width: 100,
            });
        }

        return (
            <Table
                columns={columns}
                dataSource={consultantsList}
                pagination={false}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                scroll={{
                    x: 1000,
                    y: 600,
                }}
                onChange={handleTableChange}
            />
        );
    };

    const handleChange = e => {
        setTag(e.target.value);
    };

    const renderInput = () => (
        <Input
            disabled={!selectedConsultants.length}
            placeholder='Set tag'
            size='large'
            onChange={handleChange}
        />
    );

    const renderSubmit = () => (
        <div
            style={{ marginTop: 10 }}
        >
            <Button
                disabled={!selectedConsultants.length || !tag.length}
                loading={settingIntercomTag}
                size='large'
                type='primary'
                onClick={() => {
                    const userIds = selectedConsultants.map(({ id }) => id);
                    dispatch(setTagOnUsersInIntercom(tag, userIds));
                }}
            >
                Set tag on consultants in Intercom
            </Button>
            <a
                href='https://app.intercom.io/a/apps/tr1zrh6t/users/segments/active'
                style={{ marginLeft: 20 }}
                target='_blank'
            >
                Open Intercom
                {' '}
                <span className='ion-android-open' />
            </a>
        </div>
    );

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <h2>
                    {selectedConsultants.length}
                    {' '}
                    Selected
                </h2>
                <h3>
                    Total:
                    {' '}
                    {consultantsList.length}
                </h3>
            </div>

            {renderConsultants()}
            {renderInput()}
            {renderSubmit()}
        </div>
    );
};

ModalContent.propTypes = {
    showDistance: PropTypes.bool,
    refetchConsultants: PropTypes.bool,
    userConditions: PropTypes.func,
};

export default ModalContent;
