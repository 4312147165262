import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Modal,
    Avatar,
    Spin,
    Button,
    Radio,
    Descriptions,
} from 'antd';
import { Link } from 'react-router-dom';
import {
    occupationTitle,
    countyName,
} from 'utils/nameformatters';
import { humanReadableHours } from 'utils/datetimeformatters';
import {
    debounce,
    sortBy,
} from 'lodash';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import {
    connect,
    useDispatch,
} from 'react-redux';
import * as consultantActions from 'actions/consultant';
import * as consultantsActions from 'actions/consultants';
import * as employerActions from 'actions/employer';
import * as applicationsActions from 'actions/applications';
import TextArea from 'antd/lib/input/TextArea';

const LtvApplicationModal = ({
    adminComment,
    applicationId,
    consultant,
    employer,
    ltvApplicationModalData,
    isConfirmingLtvApplication,
    isDenyingLtvApplication,
    visible,
    isFetching,
    confirmLtvApplication,
    denyLtvApplication,
    userId,
    close,
    preventConfirmation,
    reject_reasons,
    updateConsultantEmployer,
    introducedLoading,
}) => {
    const dispatch = useDispatch();
    const [rejectMode, setRejectMode] = useState(false);
    const [selectedRejectReason, setSelectedRejectReason] = useState(null);
    const [adminCommentUpdating, setAdminCommentUpdating] = useState(false);
    console.log('consultant', consultant, 'employer', employer);

    useEffect(() => {
        setAdminCommentUpdating(introducedLoading);
    }, [introducedLoading]);

    const onConfirmApplication = () => {
        confirmLtvApplication(applicationId);
    };

    const handleUpdateConsultantComment = consultantComment => {
        dispatch(updateConsultantEmployer(consultant.id, employer.id, {
            admin_comment: consultantComment,
        }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateAdminComment = useCallback(debounce(e => {
        console.log('updateAdminComment', consultant);
        setAdminCommentUpdating(true);
        handleUpdateConsultantComment(e.target.value);
    }, 500), [consultant.id, employer.id]);

    const renderTop = () => {
        console.log('applicationModalData', ltvApplicationModalData);

        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            size={60}
                            src={consultant.avatar}
                        />
                        <div
                            style={{ marginLeft: 10 }}
                        >
                            <h5
                                style={{ margin: 0 }}
                            >
                                <Link to={`/consultants/${consultant.id}`}>
                                    {consultant.firstname}
                                    {' '}
                                    {consultant.lastname}
                                </Link>
                            </h5>
                            <div>
                                {occupationTitle(consultant.occupation)}
                            </div>
                            <div>
                                {consultant.phone}
                            </div>
                            <div>
                                {consultant.email}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >

                            <Avatar
                                size={60}
                                src={employer.logo_url}
                            />
                            <div
                                style={{ marginLeft: 10 }}
                            >
                                <div>
                                    <h5
                                        style={{ margin: 0 }}
                                    >
                                        <Link to={`/employers/${employer.id}`}>
                                            {employer.name}
                                        </Link>
                                    </h5>
                                </div>
                                <div>
                                    {employer.department}
                                </div>
                                <div>
                                    {employer.address.city}
                                    ,
                                    {' '}
                                    {countyName(employer.county_id)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Spin size='large' spinning={adminCommentUpdating}>
                    <TextArea
                        defaultValue={adminComment}
                        onChange={updateAdminComment}
                    />
                </Spin>
                {/* div for showing application start_time and end_time converted using momentjs */}
                <Descriptions bordered title='Job description'>
                    <Descriptions.Item label='LTV id'>
                        {ltvApplicationModalData.job.id}
                    </Descriptions.Item>
                    <Descriptions.Item label='Start date'>
                        {moment.unix(ltvApplicationModalData.start_time).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                    <Descriptions.Item label='End date'>
                        {moment.unix(ltvApplicationModalData.end_time).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                </Descriptions>
            </div>
        );
    };

    const renderRejectReasons = () => (
        <div
            style={{ marginTop: 20 }}
        >
            <h6>
                Choose a reason for denying the application:
            </h6>
            <Radio.Group
                onChange={e => {
                    setSelectedRejectReason(e.target.value);
                }}
            >
                {sortBy(reject_reasons, 'order').map(r => (
                    <div
                        style={{ padding: 5 }}
                    >
                        <Radio
                            size='large'
                            value={r.id}
                        >
                            {r.message}
                        </Radio>
                    </div>
                ))}
            </Radio.Group>
        </div>
    );

    const renderConfirmButton = () => (
        <div
            style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <div>
                {!preventConfirmation && (
                    <Button
                        disabled={isConfirmingLtvApplication}
                        loading={isConfirmingLtvApplication}
                        size='large'
                        style={{ visibility: rejectMode ? 'hidden' : 'visible' }}
                        type='primary'
                        onClick={() => {
                            onConfirmApplication();
                        }}
                    >
                        Confirm booking
                    </Button>
                )}
                <Button
                    danger
                    disabled={(isDenyingLtvApplication) || (rejectMode && !selectedRejectReason)}
                    loading={isDenyingLtvApplication}
                    size='large'
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        if (rejectMode) {
                            denyLtvApplication(applicationId, selectedRejectReason)
                                .then(() => close('refetch'))
                                .catch(() => close('refetch'));
                        }
                        else {
                            setRejectMode(true);
                        }
                    }}
                >
                    Deny application
                </Button>
            </div>
        </div>
    );

    const renderContent = () => {
        if (!applicationId) {
            return null;
        }

        if (isFetching) {
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 300,
                    }}
                >
                    <Spin />
                </div>
            );
        }

        return (
            <div>
                {renderTop()}
                {rejectMode ? renderRejectReasons() : <></>}
                {renderConfirmButton()}
            </div>
        );
    };

    return (
        <Modal
            footer={null}
            title='Application'
            visible
            width={960}
            onCancel={() => {
                close();
            }}
        >
            {renderContent()}
        </Modal>
    );
};

const mapStateToProps = state => ({
    isConfirmingLtvApplication: state.applications.isConfirmingLtvApplication,
    reject_reasons: state.settings.reject_reasons,
    is_fetching: false,
    isDenyingLtvApplication: state.applications.isDenyingLtvApplication,
    introducedLoading: state.consultant.introducedLoading,
});

export default connect(
    state => mapStateToProps(state),
    dispatch => bindActionCreators({
        ...applicationsActions, ...consultantActions, ...consultantsActions, ...employerActions,
    }, dispatch),
)(LtvApplicationModal);
