import moment from 'moment';
import {
    fetchConsultant,
    fetchConsultantSSN,
} from '../request';
import { ssnFormatter } from './nameformatters';

export const copyShiftsToClipboard = async record => {
    const {
        user,
        employer,
        scheduled,
    } = record;
    const consultant = await fetchConsultant(user.id);
    const consultantSSN = await fetchConsultantSSN(user.id);

    const {
        firstname,
        lastname,
        email,
        phone,
        hsaid,
        ecert,
    } = consultant.user;

    const { ssn } = consultantSSN;

    const {
        name,
        department,
    } = employer;

    const shifts = scheduled
        .sort((a, b) => a.start_time - b.start_time)
        .reduce((acc, curr) => `${acc}\n- ${moment.unix(curr.start_time)
            .format('YYYY-MM-DD HH:mm')}-${moment.unix(curr.end_time).format('HH:mm')}`, '');

    const text = `
Namn: ${firstname} ${lastname}
Personnummer: ${ssnFormatter(ssn)}
HSAID: ${hsaid}
Har e-tjänstekort: ${ecert ? 'Ja' : 'Nej'}
Kontaktuppgifter till konsulten: ${email} +46 ${phone}

Avdelning: ${name}${department ? `: ${department}` : ''}
pass: ${shifts}`;

    return text;
};

export const copyLtvToClipboard = async record => {
    const {
        user,
        employer,
        start_time,
    } = record;

    const consultant = await fetchConsultant(user.id);
    const consultantSSN = await fetchConsultantSSN(user.id);

    const {
        firstname,
        lastname,
        email,
        phone,
        hsaid,
        ecert,
    } = consultant.user;

    const { ssn } = consultantSSN;

    const {
        name,
        department,
    } = employer;

    const text = `
Namn: ${firstname} ${lastname}
Personnummer: ${ssnFormatter(ssn)}
HSAID: ${hsaid}
Har e-tjänstekort: ${ecert ? 'Ja' : 'Nej'}
Kontaktuppgifter till konsulten: ${email} +46 ${phone}

Avdelning: ${name}${department ? `: ${department}` : ''}
Ltv starts: ${moment.unix(start_time).format('YYYY-MM-DD')}`;
    return text;
};
