import React, {
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
    Button,
    Input,
    Spin,
    Table,
    Popconfirm,
} from 'antd';
import moment from 'moment';

import {
    reduce,
    sortBy,
} from 'lodash';

import Avatar from 'components/shared/avatar';

const UserAttest = ({
    attests,
    batchReportScheduled,
    isUpdatingScheduled,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const employerMessageRef = useRef();
    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedIds(selectedRows.map(r => r.id));
        },
        getCheckboxProps: record => ({ name: record.name }),
    };
    const columns = [{
        title: 'Datum',
        className: 'column',
        dataIndex: 'date',
        render: (_, record) => moment.unix(record.start_time).format('YYYY-MM-DD ddd'),
    }, {
        title: 'Starttid',
        className: 'column',
        dataIndex: 'start_time',
        render: text => moment.unix(text).format('HH:mm'),
    }, {
        title: 'Sluttid',
        className: 'column',
        dataIndex: 'end_time',
        render: text => moment.unix(text).format('HH:mm'),
    }, {
        title: 'Varaktighet',
        className: 'column',
        dataIndex: 'duration',
    }, {
        title: 'Övertid',
        className: 'column',
        dataIndex: 'deviation',
    }, {
        title: 'Rast',
        className: 'column',
        dataIndex: 'shift_break',
    }, {
        title: 'Pris',
        className: 'column',
        dataIndex: 'price',
    }, {
        title: 'Pass id',
        className: 'column',
        dataIndex: 'shift_id',
    }, {
        title: 'Status',
        className: 'column',
        dataIndex: 'report_status',
    }, {
        title: 'Konsultmeddelande',
        className: 'column',
        dataIndex: 'user_report_message',
    }, {
        title: 'Uppdragsgivares medderlande',
        className: 'column',
        dataIndex: 'employer_attest_message',
    }];
    const attest = attests[0];

    const attestSelected = reportStatus => {
        const selectedRecords = attest.scheduled.filter(s => selectedIds.includes(s.id));
        const employerAttestMessage = employerMessageRef.current.input.value || undefined;
        batchReportScheduled(selectedRecords.map(s => ({
            id: s.id,
            report_status: reportStatus,
            employer_attest_message: employerAttestMessage,
            deviation: reportStatus === 'CONFIRMED' && s.deviation === null ? 0 : undefined,
        })));
    };

    return (
        <Spin spinning={isUpdatingScheduled}>
            <header className='header'>
                <Avatar
                    size={45}
                    url={attest.user.avatar}
                />
                <Link
                    className='link'
                    to={`/consultants/${attest.user.id}`}
                >
                    {attest.user.firstname}
                    {' '}
                    {attest.user.lastname}
                </Link>
            </header>
            <Table
                columns={columns}
                dataSource={sortBy(reduce(attests, (arr, att) => arr.concat(att.scheduled.map(a => ({
                    ...a,
                    application_id: att.application_id,
                }))), []), 'start_time')}
                pagination={false}
                rowClassName={record => {
                    if (['DENIED', 'PENDING', 'REPORT_REMINDED'].includes(record.report_status)) {
                        return 'mpa-add-shifts-list__error-row';
                    }
                    return '';
                }}
                rowKey='id'
                rowSelection={rowSelection}
            />
            <Input
                ref={employerMessageRef}
                name='employerMessage'
                placeholder='Employer message'
            />
            <Button size='large' onClick={() => attestSelected('CONFIRMED')}>Attest</Button>
            <Button size='large' onClick={() => attestSelected('DENIED')}>Deny</Button>
            <Popconfirm
                cancelText='no'
                okText='Yes'
                title='Are you sure you want to hide selected shifts?'
                onConfirm={() => attestSelected('HIDDEN')}
            >
                <Button size='large'>Hide</Button>
            </Popconfirm>
        </Spin>
    );
};

UserAttest.propTypes = {
    attests: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.shape({
            id: PropTypes.number,
            firstname: PropTypes.string,
            lastname: PropTypes.string,
            avatar: PropTypes.string,
        }),
        scheduled: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    batchReportScheduled: PropTypes.func,
};

export default UserAttest;
