import React from 'react';
import moment from 'moment';
import { filter } from 'lodash';
import config from 'config';

import { Tag } from 'antd';

export default class LastActive extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lastActive } = this.props;

        let backgroundColor = '';
        let diff;

        if (lastActive) {
            diff = Math.abs(moment.unix(lastActive).diff(moment(), 'days'));
            const obj = filter(config.lastActive, o => diff < o.maxDays && diff >= o.minDays);

            backgroundColor = obj[0].color;
        }

        return (
            <Tag
                color={backgroundColor}
            >
                {lastActive ? moment.unix(lastActive).fromNow().replace('för ', '').replace(' sedan', '') : 'N/A'}
            </Tag>
        );
    }
}
