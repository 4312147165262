import React from 'react';
import { useDispatch } from 'react-redux';
import * as sessionActions from 'actions/session';
import {
    Button,
    Form,
    Input,
} from 'antd';

const Login = () => {
    const dispatch = useDispatch();
    const onLogin = values => {
        const {
            username,
            password,
        } = values;
        dispatch(sessionActions.login(username, password));
    };

    return (
        <div className='flex flex-column items-center justify-center w-full h-full'>
            <div>
                <h1>Log in</h1>
                <Form
                    autoComplete='off'
                    layout='vertical'
                    name='login'
                    size='large'
                    onFinish={onLogin}
                >
                    <Form.Item
                        name='username'
                    >
                        <Input placeholder='Username' />
                    </Form.Item>

                    <Form.Item
                        name='password'
                    >
                        <Input.Password placeholder='Password' />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType='submit' type='primary'>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
