import React from 'react';
import classNames from 'utils/classnames';
import { withFormsy } from 'formsy-react';
import Label from 'components/shared/label';
import './styles.scss';

class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event) {
        const { value } = event.currentTarget;
        this.props.setValue(value);
    }

    renderLabel() {
        if (this.props.label) {
            return (
                <Label
                    {...this.props}
                />
            );
        }
    }

    render() {
        return (
            <div
                className={classNames({
                    'mp-input__wrapper': true,
                    'mp-input__wrapper--block': this.props.block,
                    [this.props.wrapperClass]: true,
                })}
            >
                {this.renderLabel()}
                <textarea
                    className='mp-textarea'
                    disabled={this.props.disabled}
                    id=''
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    resize='false'
                    rows={this.props.rows || 10}
                    value={this.props.value}
                    onChange={this.changeValue}
                />
            </div>
        );
    }
}

export default withFormsy(TextArea);
