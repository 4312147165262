import * as request from '../request';

export const requestProcurements = () => ({ type: 'REQUEST_FETCH_PROCUREMENTS' });

export const requestUpdateProcurements = id => ({
    type: 'REQUEST_UPDATE_PROCUREMENTS',
    payload: id,
});

export const recieveProcurements = procurements => ({
    type: 'RECEIVE_PROCUREMENTS',
    payload: procurements,
});

export const recieveUpdatedProcurement = procurement => ({
    type: 'RECEIVE_UPDATED_PROCUREMENT',
    payload: procurement,
});

export const recieveUpdatedProcurementError = (id, err) => ({
    type: 'RECEIVE_UPDATED_PROCUREMENT_ERROR',
    payload: {
        id,
        err,
    },
});

export const recieveProcurementsError = error => ({
    type: 'RECEIVE_PROCUREMENTS_ERROR',
    payload: error,
});

export const fetchProcurements = () => dispatch => {
    dispatch(requestProcurements());
    request.getProcurements()
        .then(({ collection }) => {
            dispatch(recieveProcurements(collection));
        })
        .catch(err => dispatch(recieveProcurementsError(err)));
};

export const updateProcurement = (id, data) => dispatch => {
    dispatch(requestUpdateProcurements(id));
    request.updateProcurement(id, data)
        .then(res => {
            dispatch(recieveUpdatedProcurement(res.procurement));
            return res;
        })
        .catch(err => dispatch(recieveUpdatedProcurementError(id, err)));
};
