import React from 'react';
import Ink from 'react-ink';
import classNames from 'utils/classnames';
import { withFormsy } from 'formsy-react';

import { styles } from './styles.scss'; //eslint-disable-line

class Switch extends React.Component {
    constructor(props) {
        super(props);
        // this.toggle = this.toggle.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue() {
        if (this.props.disabled) {
            return;
        }

        const value = !this.props.value;
        this.props.setValue(value);
        this.props.onChange && this.props.onChange();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ on: nextProps.on });
    }

    /* toggle () {
        if (this.props.disabled) {
            return;
        }
        this.setState({
            on: !this.state.on,
        });
    } */

    render() {
        const on = this.props.value;

        return (
            <div
                className={classNames({
                    'mp-switch': true,
                    'mp-switch--on': on,
                    'mp-switch--disabled': this.props.disabled,
                    [this.props.className]: true,
                })}
                style={this.props.style}
                onClick={this.changeValue}
            >
                <div
                    className={classNames({
                        'mp-switch__toggle': true,
                        'mp-switch__toggle--on': on,
                    })}
                >
                    {!this.props.disabled
                        && (
                            <Ink
                                opacity={1}
                                recenter
                            />
                        )}
                    <div
                        className={classNames({
                            'mp-switch__toggle-inner': true,
                            'mp-switch__toggle-inner--on': on,
                        })}
                    />

                </div>
            </div>
        );
    }
}

Switch.defaultProps = {
    className: '',
    disabled: false,
    style: {},
};

export default withFormsy(Switch);
