import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ModalContent from './ModalContent';

const SetTagsOnUsersModal = ({
    refetchConsultants,
    userConditions,
    visible,
    showDistance,
    close,
}) => (
    <Modal
        footer={null}
        title='Select consultants to contact'
        visible={visible}
        width={1500}
        onCancel={close}
    >
        {visible && (
            <ModalContent
                refetchConsultants={refetchConsultants}
                showDistance={showDistance}
                userConditions={userConditions}
            />
        )}
    </Modal>
);

SetTagsOnUsersModal.propTypes = {
    visible: PropTypes.bool,
    showDistance: PropTypes.bool,
    close: PropTypes.func,
    refetchConsultants: PropTypes.bool,
    userConditions: PropTypes.func,
};

export default SetTagsOnUsersModal;
