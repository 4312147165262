import React, {
    useEffect,
    useState,
} from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Table,
    Avatar,
    Button,
    Tag,
} from 'antd';
import {
    EyeOutlined,
    UserAddOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import LoadingScreen from '../../components/shared/loadingscreen';
import ErrorScreen from '../../components/shared/errorScreen';
import VacancyModal from '../../components/vacancyModal/VacancyModal';
import EditVacancyModal from '../../components/editvacancymodal';
import modalController from '../../utils/modalcontroller';
import ConfirmModal from '../../components/confirmmodal';
import ConsultantSearchModal from '../employer/longtermvacancies/vacancy/consultantsearchmodal';
import ExpandedRow from './ExpandedRow';
import ExpandIcon from './ExpandIcon';
import {
    fetchLtvs,
    fetchSingleLtv,
    updateSingleLtv,
    deleteSingleLtv,
} from '../../actions/ltvs';
import {
    updateJobProfile,
    deleteJobProfile,
} from '../../actions/job';
import './styles.scss';
import {
    pluralFormatter,
    occupationTitle,
} from '../../utils/nameformatters';
import LtvFilters from './LtvFilters';
import config from '../../config';

export default function Ltvs() {
    const [tableData, setTableData] = useState([]);
    const [showLtvDetailsModal, setShowLtvDetailsModal] = useState(false);
    const [showEditLtvModal, setShowEditLtvModal] = useState(false);
    const [showAddConsultantModal, setShowAddConsultantModal] = useState(false);
    const dispatch = useDispatch();
    const {
        ltvs,
        isFetching,
        error,
    } = useSelector(state => state.ltvs);

    const { settings } = useSelector(state => state);

    const [skills, setSkills] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [occupations, setOccupations] = useState([]);

    const [employers, setEmployers] = useState([]);

    const [filters, setFilters] = useState({
        skills: [],
        specializations: [],
        timeRange: {
            to: null,
            from: null,
        },
        timeRangeFilteringMode: 'overlapping',
        occupations: [],
        employers: [],
        withApplications: false,
        withBooking: false,
        includeTestAccounts: false,
        countries: [],
        counties: [],
    });

    const {
        skills: settingSkills,
        specializations: settingSpecializations,
        counties: settingCounties,
    } = settings;

    const {
        countries,
    } = config;

    const editLtv = formData => {
        const newPeriod = formData.period;
        const newEmploymentScope = formData.employment_scope;
        const newPrice = formData.price;
        const newMargin = formData.margin;
        const lastApplicationTime = formData.last_application_time;
        /* eslint-disable no-param-reassign */
        delete formData.price;
        delete formData.margin;
        delete formData.employment_scope;
        delete formData.jobProfile;
        delete formData.period;
        delete formData.salary;
        delete formData.last_application_time;
        /* eslint-enable no-param-reassign */

        const longTermProfile = {
            ...formData,
            is_long_term: true,
            long_term_details: {
                employment_scope: newEmploymentScope,
                start_time: moment(newPeriod[0]).unix(),
                end_time: moment(newPeriod[1]).unix(),
                last_application_time: moment(lastApplicationTime).unix(),
                price: newPrice,
                margin: newMargin,
            },
        };
        longTermProfile.id = showEditLtvModal.id;

        dispatch(updateJobProfile(longTermProfile)).then(res => {
            setShowEditLtvModal(false);
            dispatch(updateSingleLtv(res.job));
        });
    };

    const deleteLtv = ltvId => {
        modalController.showModal(ConfirmModal, {
            title: 'Delete long-term vacancy?',
            width: 600,
            props: {
                options: [
                    {
                        type: 'button',
                        className: 'btn-danger',
                        text: 'Delete',
                        action: () => dispatch(deleteJobProfile(ltvId))
                            .then(() => dispatch(deleteSingleLtv({ id: ltvId }))),
                    },
                ],
                text: 'Are you sure you want to delete this long-term vacancy? This action can not be undone.',
            },
        });
    };

    const setFilterOptions = longTermVacancies => {
        const uniqueEmployers = longTermVacancies.length ? longTermVacancies.reduce((acc, ltv) => {
            if (!acc.find(employer => employer.id === ltv.employer.id)) {
                return [...acc, ltv.employer];
            }
            return acc;
        }, []) : [];
        setEmployers(uniqueEmployers);

        const uniqueSkills = longTermVacancies.length ? longTermVacancies.reduce((acc, ltv) => {
            const ltvSkills = ltv.skills
                .map(skill => settingSkills
                    .find(settingSkill => settingSkill.id === skill));
            return [...acc, ...ltvSkills.reduce((prev, curr) => {
                if (acc.find(skill => skill.name === curr.name)) {
                    return prev;
                }
                return [...prev, curr];
            }, [])];
        }, []) : [];
        setSkills(uniqueSkills);

        const uniqueSpecializations = longTermVacancies.length ? longTermVacancies.reduce((acc, ltv) => {
            const ltvSpecializations = ltv.specialization_ids
                .map(specialization => settingSpecializations
                    .find(settingSkill => settingSkill.id === specialization));
            return [...acc, ...ltvSpecializations.reduce((prev, curr) => {
                if (acc.find(specialization => specialization.name === curr.name)) {
                    return prev;
                }
                return [...prev, curr];
            }, [])];
        }, []) : [];
        setSpecializations(uniqueSpecializations);

        const uniqueOccupations = longTermVacancies.length ? longTermVacancies.reduce((acc, ltv) => {
            const ltvOccupations = ltv.occupation_id
                ? [settings.occupations.find(occupation => occupation.id === ltv.occupation_id)]
                : [];
            return [...acc, ...ltvOccupations];
        }, []) : [];
        setOccupations([...new Set(uniqueOccupations)]);
    };

    const filterLtvs = data => {
        const {
            occupations: occupationsFilter,
            skills: skillsFilter,
            specializations: specializationsFilter,
            timeRange: timeRangeFilter,
            timeRangeFilteringMode,
            employers: employersFilter,
            withApplications: withApplicationsFilter,
            withBooking: withBookingFilter,
        } = filters;
        const {
            from,
            to,
        } = timeRangeFilter;

        const filtered = data.filter(ltv => {
            const {
                long_term_details: longTermDetails,
                employer: {
                    address,
                },
                employer: ltvEmployer,
            } = ltv;
            const {
                start_time: startTime,
                end_time: endTime,
            } = longTermDetails;

            const isInTimeRange = timeRangeFilteringMode === 'overlapping'
                ? (from
                    ? startTime >= from || endTime >= from : true)
                    && (to ? endTime <= to || startTime <= to : true)
                : (from
                    ? startTime >= from : true)
                    && (to ? endTime <= to : true);

            const ltvSkillsWithNames = ltv.skills.reduce((acc, skill) => {
                const skillWithName = settings.skills.find(settingSkill => settingSkill.id === skill);
                return [...acc, skillWithName];
            }, []);
            const isInSkills = skillsFilter.length
                ? skillsFilter.some(skill => ltvSkillsWithNames.find(s => s.name === skill))
                : true;

            const ltvSpecializationsWithNames = ltv.specialization_ids.reduce((acc, specialization) => {
                const specializationWithName = settings.specializations
                    .find(settingSpecialization => settingSpecialization.id === specialization);
                return [...acc, specializationWithName];
            }, []);
            const isInSpecializations = specializationsFilter.length
                ? specializationsFilter.some(specialization => ltvSpecializationsWithNames
                    .find(s => s.name === specialization))
                : true;

            const isInEmployers = employersFilter.length
                ? employersFilter.some(employer => employer === ltv.employer.id)
                : true;

            const isInOccupations = occupationsFilter.length
                ? occupationsFilter.find(occupation => ltv.occupation_id === occupation)
                : true;

            const hasBookings = withBookingFilter ? ltv.booking : true;
            const hasApplications = withApplicationsFilter ? ltv.applications.length && !ltv.booking : true;

            const isInCountry = filters.countries.length ? filters.countries.includes(address.country_code) : true;
            const isInCounty = filters.counties.length ? filters.counties.includes(ltvEmployer.county_id) : true;

            return isInTimeRange
                && isInSkills
                && isInSpecializations
                && isInEmployers
                && isInOccupations
                && hasBookings
                && hasApplications
                && isInCounty
                && isInCountry;
        });
        return filtered;
    };

    useEffect(() => {
        dispatch(fetchLtvs(filters.includeTestAccounts));
    }, [filters.includeTestAccounts, dispatch]);

    useEffect(() => {
        if (ltvs) {
            const data = ltvs.map(ltv => ({
                ...ltv,
                startTime: moment.unix(ltv.long_term_details.start_time).format('YYYY-MM-DD'),
                endTime: moment.unix(ltv.long_term_details.end_time).format('YYYY-MM-DD'),
                hourlyRate: ltv.long_term_details.hourly_rate,
                employmentScope: ltv.long_term_details.employment_scope,
                occupation: occupationTitle(ltv.occupation_id),
            }));
            setTableData(data);
            setFilterOptions(data);
        }
        else {
            setTableData([]);
        }
    // setTableData dependency removed to avoid too many re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ltvs]);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Employer',
            dataIndex: 'employer',
            key: 'employer',
            render: (text, record) => {
                const { employer } = record;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            size='large'
                            src={employer.logo_url}
                            style={{
                                display: 'flex',
                                minWidth: 40,
                                marginRight: 5,
                            }}
                        >
                            {!employer.logo_url && employer.name ? employer.name[0] : ''}
                        </Avatar>
                        <Link to={`/employers/${employer.id}/long-term-vacancies`}>
                            {employer.name}
                        </Link>
                    </div>
                );
            },
            sorter: (a, b) => (a.employer.name > b.employer.name ? 1 : -1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start',
            dataIndex: 'startTime',
            key: 'startTime',
            sorter: (a, b) => (a.startTime > b.startTime ? 1 : -1),
        },
        {
            title: 'End',
            dataIndex: 'endTime',
            key: 'endTime',
            sorter: (a, b) => (a.endTime > b.endTime ? 1 : -1),
        },
        {
            title: 'Hourly rate',
            dataIndex: 'hourlyRate',
            key: 'hourlyRate',
            sorter: (a, b) => (a.hourlyRate > b.hourlyRate ? 1 : -1),
        },
        {
            title: 'Scope',
            dataIndex: 'employmentScope',
            key: 'employmentScope',
            sorter: (a, b) => (a.employmentScope > b.employmentScope ? 1 : -1),
        },
        {
            title: 'Occupation',
            dataIndex: 'occupation',
            key: 'occupation',
            sorter: (a, b) => (a.occupation > b.occupation ? 1 : -1),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const {
                    booking,
                    applications,
                } = record;
                const hasBookings = !!booking?.id;
                const hasApplications = !!applications.length;

                let tagText = 'No applications';
                let tagColor = 'red';
                if (hasApplications) {
                    // eslint-disable-next-line max-len
                    tagText = `${applications.length} ${pluralFormatter(applications.length, 'application', 'applications')}`;
                    tagColor = 'orange';
                }
                if (hasBookings) {
                    tagText = 'Booked';
                    tagColor = 'green';
                }
                return (
                    <Tag color={tagColor}>{tagText}</Tag>
                );
            },
        },
        {
            title: '',
            dataIndex: 'details',
            key: 'details',
            render: (text, record) => {
                const hasBookings = record.booking?.id || record.applications.length;
                return (
                    <Button.Group>
                        <Button
                            icon={<EyeOutlined />}
                            title='View details'
                            onClick={() => setShowLtvDetailsModal(record)}
                        />
                        <Button
                            disabled={record.booking?.id}
                            icon={<UserAddOutlined />}
                            title='Add consultant'
                            onClick={() => (setShowAddConsultantModal(record))}
                        />
                        <Button
                            disabled={!!record.booking?.id}
                            icon={<EditOutlined />}
                            title='Edit'
                            onClick={() => { setShowEditLtvModal(record); }}
                        />
                        <Button
                            danger
                            disabled={hasBookings}
                            icon={<DeleteOutlined />}
                            title='Delete'
                            type='primary'
                            onClick={() => deleteLtv(record.id)}
                        />
                    </Button.Group>
                );
            },
        },
    ];

    if (isFetching) {
        return (
            <LoadingScreen
                fullScreen
                text='You gotta fight for your right to paaaaaaarty'
            />
        );
    }

    if (error) {
        return (
            <ErrorScreen
                fullScreen
                text='Oops... something went wrong. Try to reload the page or contact support'
            />
        );
    }

    return (
        <main className='mpa-ltvs'>
            <h3>
                Long term vacancies
            </h3>
            <LtvFilters
                counties={settingCounties}
                countries={countries}
                employers={employers}
                filters={filters}
                includeTestAccounts={filters.includeTestAccounts}
                occupations={occupations}
                setFilters={setFilters}
                setIncludeTestAccounts={filters.setIncludeTestAccounts}
                skills={skills}
                specializations={specializations}
            />
            {!!tableData.length && (
                <>
                    {showLtvDetailsModal && (
                        <VacancyModal
                            setShowLtvDetailsModal={setShowLtvDetailsModal}
                            settings={settings}
                            vacancy={showLtvDetailsModal}
                            visible={showLtvDetailsModal}
                        />
                    )}
                    {
                        showEditLtvModal && (
                            <EditVacancyModal
                                countryCode={showEditLtvModal?.country_code ? showEditLtvModal.country_code : 'SWE'}
                                employerId={showEditLtvModal.id}
                                jobProfiles={[]}
                                settings={settings}
                                setVisibility={setShowEditLtvModal}
                                vacancy={showEditLtvModal}
                                visible={showEditLtvModal}
                                onSave={editLtv}
                            />
                        )
                    }
                    {
                        showAddConsultantModal && (
                            <ConsultantSearchModal
                                close={() => setShowAddConsultantModal(false)}
                                includeTestAccounts={filters.includeTestAccounts}
                                profileId={showAddConsultantModal.id}
                                visible={showAddConsultantModal}
                            />
                        )
                    }
                    <Table
                        className='ltvs-view-background'
                        columns={columns}
                        dataSource={filterLtvs(tableData)}
                        expandable={{
                            expandedRowRender: record => (
                                <ExpandedRow fetchSingleLtv={fetchSingleLtv} ltv={record} />
                            ),
                            expandIcon: ({
                                // eslint-disable-next-line react/prop-types
                                expanded, onExpand, record,
                            }) => (<ExpandIcon expanded={expanded} record={record} onExpand={onExpand} />),
                            rowExpandable: record => record.booking?.id || !!record.applications.length,
                        }}
                        pagination={false}
                        rowKey='id'
                    />
                </>
            )}
        </main>
    );
}
