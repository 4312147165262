import * as request from 'request';

export function receiveInvoices(resp) {
    return {
        type: 'RECEIVE_INVOICES',
        invoices: resp.invoices,
    };
}

export function fetchInvoices() {
    return dispatch => request.fetchInvoices()
        .then(resp => dispatch(receiveInvoices(resp)));
}
