import React, { useMemo } from 'react';
import { Checkbox, Dropdown, Space } from 'antd';

const DropDownGroup = ({ data, onChange, selected, className='' }) => {
  const renderDropDown = useMemo(() => (data.map(({ label, value, children }) => {
    const selectedKeys = selected && selected[value] || [];
    const items = children.map((item, index) => {
      const keyValue = `${item.value}::${item.label}`
      const checked = selectedKeys.includes(keyValue);
      return ({
        key: index,
        label: <Checkbox key={item.value} checked={checked} onChange={() => onChange(value, keyValue)}>{item.label}</Checkbox>
      })
    })
    return (
        <Dropdown.Button
          menu={{ items }}
          key={label}
          trigger={['click']}
          style={{ width: 'auto' }}
        >
          <Space onClick={(e) => e.preventDefault()}>
            {label}
          </Space>
        </Dropdown.Button>
    )
  })), [data, selected])

  return (
    <div className={`flex flex-row ${className}`}>
      {renderDropDown}
    </div>
  );
}

export default DropDownGroup;

