import {
    isObject,
    reduce,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';
import {
    startEndTimeToDuration,
    startEndStringTimesToMoment,
} from 'utils/datetimeformatters';

import {
    Modal,
    Button,
} from 'antd';
import ShiftsList from 'components/createshiftslistmodal/shiftslist';
import ShiftSummaryList from 'components/createshiftslistmodal/shiftsummarylist';

const EditShiftsModal = ({
    mode,
    visible,
    onCancel,
    shifts,
    onSave,
    profiles,
    selectedProfileId,
    onShiftsChange,
}) => {
    const [editedShifts, setEditedShifts] = useState([]);
    const [totalDuration, setTotalDuration] = useState(0);

    // Initialize shifts when modal opens
    useEffect(() => {
        if (visible && shifts) {
            const formattedShifts = shifts.map(shift => ({
                ...shift,
                new: true,
                date: shift.start_time,
                start_time: moment.unix(shift.start_time).format('HH:mm'),
                end_time: moment.unix(shift.end_time).format('HH:mm'),
                profile: profiles.find(p => p.id === (shift.profile_id || selectedProfileId)),
                profile_id: shift.profile_id || selectedProfileId,
                last_application_datetime: shift.last_application_datetime,
            }));
            setEditedShifts(formattedShifts);
        }
    }, [visible, shifts, profiles, selectedProfileId]);

    // Calculate total duration
    useEffect(() => {
        const duration = reduce(editedShifts, (val, shift) => {
            if (shift.end_time.length === 5 && shift.start_time.length === 5) {
                const start = moment(`${moment.unix(shift.date).format('YYYY-MM-DD')} ${shift.start_time}`).unix();
                const end = moment(`${moment.unix(shift.date).format('YYYY-MM-DD')} ${shift.end_time}`).unix();
                return val + startEndTimeToDuration(start, end);
            }
            return val;
        }, 0);

        setTotalDuration(duration);
    }, [editedShifts]);

    const updateShift = (shiftId, key, value) => {
        setEditedShifts(editedShifts.map(shift => {
            if (shift.id === shiftId) {
                let newShift;
                if (isObject(key)) {
                    newShift = {
                        ...shift,
                        ...key,
                    };
                }
                else {
                    newShift = {
                        ...shift,
                        [key]: value,
                    };
                }
                return newShift;
            }
            return shift;
        }));
    };

    const updateAllShifts = (key, value) => {
        setEditedShifts(editedShifts.map(shift => ({
            ...shift,
            [key]: value,
        })));
    };

    const updateMultipleShifts = (shiftsToUpdate, key, value) => {
        setEditedShifts(editedShifts.map(s => {
            if (shiftsToUpdate.includes(s.id)) {
                return {
                    ...s,
                    [key]: value,
                };
            }
            return s;
        }));
    };

    const handleSave = () => {
        const formattedShifts = editedShifts.map(s => {
            const {
                date,
                start_time,
                end_time,
                shift_break,
                price,
                margin,
                consultant_fee_margin,
                last_application_datetime,
                is_price_hidden,
            } = s;

            const shiftDate = moment.unix(date).format('YYYY-MM-DD');
            const startTime = moment(`${shiftDate} ${start_time}`);
            const endTime = moment(`${shiftDate} ${end_time}`);
            const lastApplicationDatetime = last_application_datetime;

            return {
                ...s,
                start_time: startTime.format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
                end_time: endTime.format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
                duration: startEndTimeToDuration(startTime.unix(), endTime.unix()),
                shift_break: parseFloat(shift_break),
                price: parseFloat(price),
                margin,
                consultant_fee_margin,
                last_application_datetime: last_application_datetime
                    ? moment.unix(last_application_datetime).format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : null,
                is_price_hidden,
            };
        });

        onSave(formattedShifts);
        onCancel();
    };

    return (
        <Modal
            footer={[
                <Button key='cancel' onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key='save' type='primary' onClick={handleSave}>
                    Save Changes
                </Button>,
            ]}
            title='Edit Shifts'
            visible={visible}
            width={1000}
            onCancel={onCancel}
        >
            <ShiftSummaryList
                shifts={editedShifts}
                updateMultipleShifts={updateMultipleShifts}
            />
            <ShiftsList
                consultants={[]}
                deleteIds={[]}
                preselectedProfileId={selectedProfileId}
                profiles={profiles}
                rejectedShifts={{
                    booked: [], duplicated: [],
                }}
                // Disable unnecessary features
                removeShift={() => {}}
                searchConsultants={() => {}}
                shifts={editedShifts}
                toggleDeleteId={() => {}}
                updateAllShifts={updateAllShifts}
                updateShift={updateShift}
            />
        </Modal>
    );
};

EditShiftsModal.propTypes = {
    mode: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    shifts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    profiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })),
    selectedProfileId: PropTypes.number,
};

export default EditShiftsModal;
