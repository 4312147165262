import React from 'react';
import Avatar from 'components/shared/avatar';
import './styles.scss';

class MapMarker extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showInfo: false };
    }

    renderInfo() {
        if (this.state.showInfo) {
            return this.props.children;
        }
    }

    render() {
        const { isEmployer } = this.props;

        return (
            <div
                className={`mpa-map-marker ${isEmployer ? 'map-map-marker--employer' : ''}`}
                style={{ zIndex: this.state.showInfo ? 40 : 20 }}
                onMouseEnter={() => {
                    this.setState({ showInfo: true });
                }}
                onMouseLeave={() => {
                    this.setState({ showInfo: false });
                }}
                // lat={this.props.lat}
                // lng={this.props.lng}
                // style={this.props.markerStyle}
            >
                <Avatar
                    gender={this.props.gender}
                    size={isEmployer ? 30 : 24}
                    url={this.props.image}
                />
                {this.renderInfo()}
            </div>
        );
    }
}

MapMarker.defaultProps = { markerStyle: {} };

export default MapMarker;
