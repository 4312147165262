export function filterToggleIncludeTestAccounts() {
    return { type: 'TOGGLE_INCLUDE_TEST_ACCOUNTS' };
}

export function filterToggleIncludeTopConsultants() {
    return { type: 'TOGGLE_INCLUDE_TOP_CONSULTANTS' };
}

export function filterAddIncludeTag(tagId) {
    return {
        type: 'ADD_INCLUDE_TAG',
        tagId,
    };
}

export function filterRemoveIncludeTag(tagId) {
    return {
        type: 'REMOVE_INCLUDE_TAG',
        tagId,
    };
}

export function filterAddExcludeTag(tagId) {
    return {
        type: 'ADD_EXCLUDE_TAG',
        tagId,
    };
}

export function filterRemoveExcludeTag(tagId) {
    return {
        type: 'REMOVE_EXCLUDE_TAG',
        tagId,
    };
}

export function filterSetOccupation(occupation) {
    return {
        type: 'SET_OCCUPATION_FILTER',
        occupation,
    };
}

export function filterSetStudent(student) {
    return {
        type: 'SET_STUDENT_FILTER',
        student,
    };
}

export function filterSetPreset(preset) {
    return {
        type: 'SET_PRESET',
        preset,
    };
}

export function filterToggleLastActive(id) {
    return {
        type: 'TOGGLE_LAST_ACTIVE_ID',
        id,
    };
}

export function consultantsFilterSetIntentions(intentionIds) {
    return {
        type: 'CONSULTANTS_FILTER_SET_INTENTIONS',
        intentionIds,
    };
}

export function consultantsFilterSetSkills(skillIds) {
    return {
        type: 'CONSULTANTS_FILTER_SET_SKILLS',
        skillIds,
    };
}

export function consultantsFilterSetSpecializations(specializationIds) {
    return {
        type: 'CONSULTANTS_FILTER_SET_SPECIALIZATIONS',
        specializationIds,
    };
}

export function consultantsFilterSetIncludeTags(includeTagIds) {
    return {
        type: 'CONSULTANTS_FILTER_SET_INCLUDE_TAGS',
        includeTagIds,
    };
}

export function consultantsFilterSetExcludeTags(excludeTagIds) {
    return {
        type: 'CONSULTANTS_FILTER_SET_EXCLUDE_TAGS',
        excludeTagIds,
    };
}

export function setFilterEntityTypes(entityTypes) {
    return {
        type: 'CONSULTANTS_FILTER_SET_ENTITY_TYPES',
        entityTypes,
    };
}
