import {
    filter,
    includes,
} from 'lodash';

const initialState = {
    salaryList: [],
    isFetching: true,
    isExporting: false,
};

export function salaryList(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_SALARY_LIST':
            return {
                ...state,
                isFetching: true,
            };

        case 'RECEIVE_SALARY_LIST':
            return {
                ...state,
                salaryList: action.salaryList,
                isFetching: false,
            };

        case 'REQUEST_EXPORT_SALARY_LIST':
            return {
                ...state,
                isExporting: true,
            };

        case 'RECEIVE_EXPORT_SALARY_LIST':
            return {
                ...state,
                isExporting: false,
                salaryList: filter(state.salaryList, item => !includes(action.userIds, item.user.id)),
            };

        default:
            return state;
    }
}
