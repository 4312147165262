import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import GoogleMap from 'google-map-react';
import config from 'config';

import MapMarker from 'containers/usermap/mapmarker';
import ClusterMarker from 'containers/usermap/clustermarker';
import EmployerInfoPopover from 'containers/usermap/employerinfopopover';
import UserInfoPopover from 'containers/usermap/userinfopopover';
import supercluster from 'points-cluster';

import './styles.scss';

const mapStyles = [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            { color: '#e9e9e9' },
            { lightness: 17 },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            { color: '#f5f5f5' },
            { lightness: 20 },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            { color: '#ffffff' },
            { lightness: 17 },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            { color: '#ffffff' },
            { lightness: 29 },
            { weight: 0.2 },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            { color: '#ffffff' },
            { lightness: 18 },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            { color: '#ffffff' },
            { lightness: 16 },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            { color: '#f5f5f5' },
            { lightness: 21 },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            { color: '#dedede' },
            { lightness: 21 },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            { visibility: 'on' },
            { color: '#ffffff' },
            { lightness: 16 },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            { saturation: 36 },
            { color: '#333333' },
            { lightness: 40 },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            { color: '#f2f2f2' },
            { lightness: 19 },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            { color: '#fefefe' },
            { lightness: 20 },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            { color: '#fefefe' },
            { lightness: 17 },
            { weight: 1.2 },
        ],
    },
];

const Map = ({
    userMarkers,
    onConsultantMarkerClick,
    lat,
    lng,
    mapStyle,
    disableDefaultUI,
    defaultZoom,
    minZoom,
    maxZoom,
    employerMarkers,

}) => {
    const [clusters, setClusters] = useState([]);
    const [mapOptions, setMapOptions] = useState([]);
    const mapRef = useRef();

    const getClusters = (center, zoom, bounds) => supercluster(userMarkers.map(m => ({
        ...m,
        lat: +m.lat,
        lng: +m.lng,
    })), {
        minZoom: 0,
        maxZoom: 13,
        radius: 40,
    })({
        center, zoom, bounds,
    });
    const createClusters = ({
        center, zoom, bounds,
    }) => {
        setClusters(bounds
            ? getClusters(center, zoom, bounds).map(({
                wx, wy, numPoints, points,
            }) => ({
                lat: wy,
                lng: wx,
                numPoints,
                id: `${numPoints}_${points[0].id}`,
                points,
            }))
            : []);
    };

    useEffect(() => {
        createClusters(mapOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMarkers.length, mapOptions]);

    const renderEmployerMarkers = () => employerMarkers.map(employer => (
        <MapMarker
            key={employer.id}
            image={employer.logo_url}
            isEmployer
            lat={employer.address.lat}
            lng={employer.address.lng}
        >
            <EmployerInfoPopover employer={employer} />
        </MapMarker>
    ));

    const handleMapChange = ({
        center, zoom, bounds,
    }) => {
        setMapOptions({
            center, zoom, bounds,
        });
    };

    const renderMap = () => (
        <GoogleMap
            ref={mapRef}
            bootstrapURLKeys={{ key: config.google_maps_api_key }}
            center={{
                lat: +lat,
                lng: +lng,
            }}
            options={{
                styles: mapStyle,
                disableDefaultUI,
                minZoom,
                maxZoom,
            }}
            yesIWantToUseGoogleMapApiInternals
            zoom={defaultZoom}
            onChange={handleMapChange}
        >
            {renderEmployerMarkers()}
            {clusters.map((cluster, i) => (cluster.numPoints === 1
                ? (
                    <MapMarker
                        key={`u-${i}`}
                        gender={cluster.points[0].gender}
                        lat={cluster.points[0].lat}
                        lng={cluster.points[0].lng}
                    >
                        <UserInfoPopover
                            user={cluster.points[0]}
                            onClick={() => {
                                onConsultantMarkerClick(cluster.points[0]);
                            }}
                        />
                    </MapMarker>
                )
                : (
                    <ClusterMarker
                        key={cluster.id}
                        lat={cluster.lat}
                        lng={cluster.lng}
                        numPoints={cluster.numPoints}
                        points={cluster.points}
                    />
                )))}
        </GoogleMap>
    );
    return renderMap();
};
Map.defaultProps = {
    lat: 59.334591,
    lng: 18.063240,
    mapStyle: mapStyles,
    disableDefaultUI: false,
    defaultZoom: 11,
    minZoom: 3,
    maxZoom: 15,
    employerMarkers: [],
    userMarkers: [],
};

export default Map;
