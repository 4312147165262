import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivityTypes } from 'actions/activity_types'
import { fetchConsultantsV4, postConsultantsCallList } from 'actions/consultants';
import Loader from 'components/shared/loader';
import { fetchJobPostingsByUserV1 } from 'actions/consultant';
import { fetchEmployersV4 } from 'actions/employers';
import { fetchJobPostingTrackingV1 } from 'actions/jobPostingTracking';
import { createLead, getLeads, convertToEmployer } from 'actions/leads';
import { postTask } from 'request';
import TaskModal from '../tasks/taskmodal/index.js';
import { addUserCommentV1, fetchUserComments, addEmployerCommentV1 } from 'actions/comments'
import DropDownGroup from './types/DropDownGroup';
import RadioGroup from './types/RadioGroup';
import TagGroup from './tags';
import { Button } from 'antd';
import EmployerEvents from './EmployerEvents';
import CheckboxGroup from './types/CheckBoxGroup';
import { Checkbox } from 'antd';
import ConsultantEvents from './ConsultantEvents';
import SelectList from './types/SelectList';
import TaskListModal from './TaskListModal';
import getEventsByTypeGroup from './utils/eventsByTypeGroup';
import getQueryString from './utils/queryString';
import TaskList from './TaskList';
import removeItemFromFilter from './utils/removeItemFromFilter';
import JobTrackingEvents from './JobTrackingEvents';
import DateSpan from './components/DateSpan';
import SearchBar from '../../components/searchbar';
import LeadsModal from '../../components/leadsmodal';
import LeadsEvents from './LeadsEvent.js';

export const GENERAL = 'general'

const CONSULTANT = 'consultant'
const LEADS = 'leads'
const EMPLOYER = 'employer'
const JOB_POSTING_TRACKING = 'recruitment_view_job'
const JOB_STAFFING_TRACKING = 'b_view_job_details'
const ACTIVITY_TYPE = 'activity_type'
const STUDENT_KEY = 'students';
const STUDENT_GRADUATES_KEY = 'students_graduates';
const ONLY_STUDENTS = 'only_show_students::Only show students';
const NO_STUDENTS = 'dont_show_students::Dont show students';
const ONLY_STUDENTS_GRADUATES = 'only_show_students_graduates::Only show students graduates';

const employersTypes = [
  {
    "label": "Maintaining",
    "value": "maintaining",
    "children": [
      {
        label: "Automotive",
        value: "true",
      },
      {
        label: "Maintained",
        value: "false",
      }
    ]
  },
  {
    "label": "Employer type",
    "value": "employer_type",
    "children": [
      {
        label: "Public",
        value: "public",
      },
      {
        label: "Private",
        value: "private",
      }
    ]
  },
  {
    "label": "Margin configurations",
    "value": "margin_configurations",
    "children": [
      {
        label: "Standard",
        value: "standard",
      },
      {
        label: "Moms B",
        value: "moms_b",
      }
    ]
  },
] 

const types = [
  {
    "label": "Clinicians",
    "value": "consultant",
  },
  {
    "label": "Clinics",
    "value": "employer",
  },
  {
    "label": "HIRE post tracking",
    "value": JOB_POSTING_TRACKING,
  },
  {
    "label": "STAFFING post tracking",
    "value": JOB_STAFFING_TRACKING,
  },
  {
    "label": "Leads",
    "value": "leads",
  }
]

const studentTypes = [
  {
    "label": "All",
    "value": "all",
  },
  {
    "label": "Only show students",
    "value": "only_show_students",
  },
  {
    "label": "Don't show students",
    "value": "dont_show_students",
  }
]

const appendList = (selectedKeys, value) => {
  if(selectedKeys.length === 0) return [ ...selectedKeys, value ];
  const valueKey = value.split('::')[1];
  const filteredSelectedKeys = selectedKeys.filter((item) => {
    const b = item.split('::')[1];
    return b !== valueKey;
  });
  return [ ...filteredSelectedKeys, value ];
}

const ActivityBoard = () => {
  const [type, setType] = useState(types[0].value);
  const [studentType, setStudentType] = useState(studentTypes[0].value);
  const [selectedTask, setSelectedTask] = useState();
  const [isLeadsModalOpen, setIsLeadsModalOpen] = useState(false);
  const [selectedStudentGraduate, setSelectedStudentGraduate] = useState(false);
  const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState({});
  const [counties, setCounties] = useState([]);
  const [countries, setCountries] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [selectedConsultantTasks, setSelectedConsultantTasks] = useState([]);
  const [selectedEmployerTasks, setSelectedEmployerTasks] = useState([]);
  const [selectedLeadTasks, setSelectedLeadTasks] = useState([]);
  const [events, setEvents] = useState([]);
  const [skills, setSkills] = useState([]);
  const [occupations, setOccupations] = useState([])
  const [products, setProducts] = useState([])
  const [secondaryDepartmentTypes, setSecondaryDepartmentTypes] = useState([])
  const [isConsultantTaskListModalOpen, setIsConsultantTaskListModalOpen] = useState(false);
  const [isEmployerTaskListModalOpen, setIsEmployerTaskListModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const {
    activityTypes,
    settings,
    consultant,
    consultants,
    employers,
    session,
    comments,
    jobPostingTracking,
    leads
  } = useSelector((state) => {
      return state
  });
  
  const dispatchList = (typeB, filters = {}) => {
    setIsLoading(true);
    if (typeB === CONSULTANT) {
      dispatch(fetchConsultantsV4(filters));
    } else if (typeB === EMPLOYER){
      dispatch(fetchEmployersV4(filters));
    } else if (typeB === JOB_POSTING_TRACKING || typeB === JOB_STAFFING_TRACKING) {
      dispatch(fetchJobPostingTrackingV1({ type: typeB, ...filters }));
    } else if (typeB === LEADS) {
      dispatch(getLeads());
    }
  }

  useEffect(() => {
    if(
      (type === 'consultant' && !consultants.isFetching) ||
      (type === 'employer' && !employers.isFetching) ||
      (type === 'leads' && !leads.isFetching) ||
      ((type === 'recruitment_view_job' || type === 'b_view_job_details') && !jobPostingTracking.isFetching)
      ) {
      setIsLoading(false);
    }
  }, [consultants.isFetching , employers.isFetching , leads.isFetching , jobPostingTracking.isFetching]);

  useEffect(() => {
    dispatch(fetchActivityTypes());
  }, [dispatch, fetchActivityTypes]);

  useEffect(() => {
    let filters = { page: 1 };
    let selectedType = type;
    const types = JSON.parse(localStorage.getItem(ACTIVITY_TYPE));
    if(types) {
      setSelectedActivityTypes(types);
      if (types.selected) {
        setType(types.selected)
        selectedType = types.selected;
      }
      const consultantFilterExist = types[CONSULTANT];
      if(consultantFilterExist) {
        if (consultantFilterExist.students) {
          setStudentType(consultantFilterExist.students[0].split('::')[0]);
        }
        if(consultantFilterExist.students_graduates) {
          setSelectedStudentGraduate(true);
        }
      }
      const qsGeneral = getQueryString(types[GENERAL])
      const qsType = getQueryString(types[selectedType])
      filters = { ...filters, ...qsGeneral, ...qsType }
    }
    dispatchList(selectedType, filters);
  }, [setType]);

  useEffect(() => {
    if(settings.allowed_countries) {
      const allowedCountries = settings.allowed_countries.map((country) => {
        const { name, country_code } = country
        return {
          label: name,
          value: country_code,
        }
      });
      setCountries({
        label: 'Countries',
        value: 'country_code',
        options: allowedCountries,
      })
    }
  }, [settings.allowed_countries])

  useEffect(() => {
    const cnts = settings.counties.reduce((acc, current) => {
      const { country_code, id, name } = current
      acc = [...acc, {
        options: {
          country_code,
        },
        label: name,
        value: id
      }]
      return acc
    }, []);

    setCounties({
      label: 'Counties',
      value: 'county_ids',
      options: cnts,
    })
  }, [settings.counties, selectedActivityTypes.general])

  useEffect(() => {
    const secondaryDepartmentTypes = settings.secondary_department_types.map((current) => {
      const { id, name } = current
      return {
        label: name,
        value: id
      }
    });

    setSecondaryDepartmentTypes({
      label: 'Department types',
      value: 'department_type_ids',
      options: secondaryDepartmentTypes,
    })
  }, [settings.secondary_department_types])

  useEffect(() => {
    if(settings.skills) {
      const skills = settings.skills.map((current) => {
        const { id, name } = current
        return {
          label: name,
          value: id
        }
      });
  
      setSkills({
        label: 'Skills',
        value: 'skills',
        options: skills,
      })
    }
  }, [settings.skills])

  useEffect(() => {
    const occupations = settings.occupations.map((current) => {
      const { id, name } = current
      return {
        label: name,
        value: id
      }
    });

    setOccupations([
      {
        label: 'Occupation',
        value: 'occupation',
        children: occupations,
      }
    ])
  }, [settings.occupations])

  useEffect(() => {
    if(settings.products) {
      const products = settings.products.reduce((acc, current) => {
        const { id, name } = current
        if(id === 'network') return acc
        acc = [
          ...acc,
          {
            label: name.toUpperCase(),
            value: id
          }
        ]
        return acc
          
        
      }, []);

      setProducts([
        {
          label: 'Products',
          value: 'products',
          children: products,
        }
      ])
    }
  }, [settings.products])

  useEffect(() => {
    setEvents(
      getEventsByTypeGroup(type, activityTypes.data, selectedActivityTypes).sort((a, b) => a.order - b.order)
    );
  }, [activityTypes.data, selectedActivityTypes, type])

  if (!consultants.hasFetched && !employers.hasFetched && !jobPostingTracking.hasFetched && !leads.hasFetched) return <Loader />;

  const onSaveTypes = (key, value, fnKey) => {
    const existingTypes = selectedActivityTypes[fnKey] || {}
    const arr = existingTypes[key] || [];
    if(arr.includes(value)) {
      onRemoveTag(key, value)
    } else {
      const list = appendList(arr, value);
      const keyValue = list.length ? { [key]: list } : {};
      delete existingTypes[key];
      const newTagList = {
        [fnKey]: {
          ...existingTypes,
          ...keyValue,
        }
      }
      setSelectedActivityTypes(prev => {
        const mergedTags = { ...prev, ...newTagList}
        localStorage.setItem(ACTIVITY_TYPE, JSON.stringify(mergedTags));
        setEvents(
          getEventsByTypeGroup(type, activityTypes.data, mergedTags).sort((a, b) => a.order - b.order)
        );
        const qsGeneral = getQueryString(mergedTags[GENERAL])
        const qsType = getQueryString(mergedTags[type])
        const filters = { ...qsGeneral, ...qsType }
        dispatchList(type, filters);
        return mergedTags
      })
    }
  }

  const onRemoveTag = (key, item) => {
    const newData = removeItemFromFilter(GENERAL, type, selectedActivityTypes, key, item)
    setSelectedActivityTypes(newData)
    localStorage.setItem(ACTIVITY_TYPE, JSON.stringify(newData));
    setEvents(
      getEventsByTypeGroup(type, activityTypes.data, newData
    ).sort((a, b) => a.order - b.order));
    const qsGeneral = getQueryString(newData[GENERAL])
    const qsType = getQueryString(newData[CONSULTANT])
    const filters = { ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onClearAll = () => {
    setSelectedActivityTypes({})
    setStudentType(studentTypes[0].value);
    setSelectedStudentGraduate(false);
    localStorage.removeItem(ACTIVITY_TYPE);
    dispatchList(type);
  }

  const onSetGroup = (type) => {
    setType(type)
    setEvents(
      getEventsByTypeGroup(type, activityTypes.data, selectedActivityTypes
    ).sort((a, b) => a.order - b.order));
    const newData = {
      ...selectedActivityTypes,
      selected: type
    }
    localStorage.setItem(ACTIVITY_TYPE, JSON.stringify(newData));
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[type])
    const filters = { page: 1, ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onCreateTaskListModal = () => {
    if (type === CONSULTANT) {
      setIsConsultantTaskListModalOpen(true)
    } else {
      setIsEmployerTaskListModalOpen(true)
    }
  }

  const onAddTask = async (task, id) => {
    await postTask({
      ...task,
      ...id,
    });
  }

  const onAddComment = (id, content) => {
    if (type === CONSULTANT) {
      dispatch(addUserCommentV1(id, content))
    }
    if (type === EMPLOYER) {
      dispatch(addEmployerCommentV1(id, content))
    }
  }

  const onGetComments = (id) => {
    dispatch(fetchUserComments(id))
  }

  const onChangeConsultantPage = (page) => {
    //TODO: might be able to merge all these functions
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[CONSULTANT])
    const filters = { page, ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onChangeEmployerPage = (page) => {
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[EMPLOYER])
    const filters = { page, ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onChangeJobPostingTrackingPage = (page) => {
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[JOB_POSTING_TRACKING])
    const filters = { page, ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onChangeLeadsPage = (page) => {
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[LEADS])
    const filters = { page, ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }
  
  const onTaskListModalClose = () => {
    window.location.reload()
  }

  const onSetSelectedTask = (task) => {
    setSelectedTask(task)
    setEditTaskModalVisible(true)
  }

  const onShowGraduates = (e) => {
    const existingTypes = selectedActivityTypes[CONSULTANT] || {}
    const keyValue = e.target.checked ? { [STUDENT_GRADUATES_KEY]: [ONLY_STUDENTS_GRADUATES] } : {};
    delete existingTypes[STUDENT_GRADUATES_KEY];
    const newData =  {
      ...selectedActivityTypes,
      [CONSULTANT]: {
        ...existingTypes,
        ...keyValue,
      }
    };
    setSelectedActivityTypes(newData);
    setSelectedStudentGraduate(e.target.checked);
    localStorage.setItem(ACTIVITY_TYPE, JSON.stringify(newData));
    const qsGeneral = getQueryString(newData[GENERAL])
    const qsType = getQueryString(newData[CONSULTANT])
    const filters = { ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onSetStudentGroup = (studentType) => {
    setStudentType(studentType);
    const existingTypes = selectedActivityTypes[CONSULTANT] || {}
    const keyValue = studentType === "all" ? {} : { [STUDENT_KEY]: [ studentType === "only_show_students" ? ONLY_STUDENTS : NO_STUDENTS ] };
    delete existingTypes[STUDENT_KEY];
    const newData =  {
      ...selectedActivityTypes,
      [CONSULTANT]: {
        ...existingTypes,
        ...keyValue,
      }
    };
    setSelectedActivityTypes(newData);
    localStorage.setItem(ACTIVITY_TYPE, JSON.stringify(newData));
    const qsGeneral = getQueryString(newData[GENERAL])
    const qsType = getQueryString(newData[CONSULTANT])
    const filters = { ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  }

  const onSendCallList = (name) => {
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[type])
    const filters = { ...qsGeneral, ...qsType }
    if (type === CONSULTANT) {
      dispatch(postConsultantsCallList({ tag: name, filters }));
    } 
  }

  const onSelectJobPosting = (id) => {
    dispatch(fetchJobPostingsByUserV1(id));
  }

  const onSelectedConsultantTasks = (user) => {
    const userExist = selectedConsultantTasks.some((u) => u.user_id === user.user_id)
    if(userExist) {
      const newList = [
        ...selectedConsultantTasks.filter((u) => u.user_id !== user.user_id),
      ]
      setSelectedConsultantTasks(newList)
    } else {
      setSelectedConsultantTasks([
        ...selectedConsultantTasks,
        user
      ])
    }
  }

  const onSelectedEmployerTasks = (employer) => {
    const employerExist = selectedEmployerTasks.some((u) => u.employer_id === employer.employer_id)
    if(employerExist) {
      const newList = [
        ...selectedEmployerTasks.filter((u) => u.employer_id !== employer.employer_id),
      ]
      setSelectedEmployerTasks(newList)
    } else {
      setSelectedEmployerTasks([
        ...selectedEmployerTasks,
        employer
      ])
    }
  }

  const onSelectedLeadTasks = (lead) => {
    const leadExist = selectedLeadTasks.some((u) => u.employer_lead_id === lead.employer_lead_id)
    if(leadExist) {
      const newList = [
        ...selectedLeadTasks.filter((u) => u.employer_lead_id !== lead.employer_lead_id),
      ]
      setSelectedLeadTasks(newList)
    } else {
      setSelectedLeadTasks([
        ...selectedLeadTasks,
        lead
      ])
    }
  }

  const onConvertToEmployer = (id) => {
    dispatch(convertToEmployer(id))
  }

  const renderType = (type) => {
    switch (type) {
      case types[0].value:
        return (<ConsultantEvents
          intentionData={settings.intentions}
          data={consultants}
          onChangePage={onChangeConsultantPage}
          onSelectJobPosting={onSelectJobPosting}
          onAddTask={onAddTask}
          dataConsultant={consultant}
          onSelectedConsultantTasks={onSelectedConsultantTasks}
          selectedConsultantTasks={selectedConsultantTasks}
        />)

      case types[1].value:
        return (<EmployerEvents
          data={employers}
          onAddTask={onAddTask}
          onChangePage={onChangeEmployerPage}
          onSelectedEmployerTasks={onSelectedEmployerTasks}
          selectedEmployerTasks={selectedEmployerTasks}
        />)
      case types[2].value:
          return (<JobTrackingEvents
            data={jobPostingTracking.data}
            onChangePage={onChangeJobPostingTrackingPage}
          />)
      case types[3].value:
        return (<JobTrackingEvents
          data={jobPostingTracking.data}
          onChangePage={onChangeJobPostingTrackingPage}
        />)
      case types[4].value:
        return (<LeadsEvents
          data={leads.data}
          onChangePage={onChangeLeadsPage}
          onAddLead={() => setIsLeadsModalOpen(true)}
          onAddTask={onAddTask}
          onConvertToEmployer={onConvertToEmployer}
          onSelectedLeadTasks={onSelectedLeadTasks}
          selectedLeadTasks={selectedLeadTasks}
        />)
      default:
        break;
    }
  }

  const getListCount = (type) => {
    switch (type) {
      case types[0].value:
        return consultants ? consultants.count : 0
      case types[1].value:
        return employers ? employers.count : 0
      case types[2].value:
        return jobPostingTracking?.data ? jobPostingTracking.data.count : 0
      case types[3].value:
        return jobPostingTracking?.data ? jobPostingTracking.data.count : 0
      case types[4].value:
        return leads?.data ? leads.data.count : 0
      default:
        return 0
    }
  }

  const renderTypeHeader = (type) => {
    switch (type) {
      case types[0].value:
        return <h2 className='text-gray-600 mb-1'>Clinicians</h2>
      case types[1].value:
        return <h2 className='text-gray-600 mb-1'>Clinics</h2>
      case types[2].value:
        return <h2 className='text-gray-600 mb-1'>Job posting tracking</h2>
      case types[3].value:
        return <h2 className='text-gray-600 mb-1'>Job staffing tracking</h2>
      case types[4].value:
        return <div className='flex'><h2 className='text-gray-600 mb-1 mr-2'>Leads</h2><Button onClick={() => setIsLeadsModalOpen(true)}>Add new</Button></div>
      default:
        break;
    }
  }

  const renderTypeFilters = (type) => {
    switch (type) {
      case types[0].value:
        return (<>
        <div className='flex border p-2 border-gray-300 rounded-md'>
          <DropDownGroup className='mr-2' data={[ ...occupations ]} onChange={(key, value) => onSaveTypes(key, value, CONSULTANT)} selected={selectedActivityTypes[CONSULTANT]} />
          <SelectList className='mr-2' data={skills} onChange={(key, value) => onSaveTypes(key, value, CONSULTANT)} selected={selectedActivityTypes[CONSULTANT]} />
        </div>
        <DateSpan className='flex border p-2 border-gray-300 rounded-md' type={CONSULTANT} title="Show available clinicians" onClick={onSaveTypes} selected={selectedActivityTypes[CONSULTANT]} />
      </>)
      case types[1].value:
        return (<>
        <div className='flex border p-2 border-gray-300 rounded-md'>
          <DropDownGroup className='mr-2' data={employersTypes} onChange={(key, value) => onSaveTypes(key, value, EMPLOYER)}  selected={selectedActivityTypes[EMPLOYER]} />
          <SelectList data={secondaryDepartmentTypes} onChange={(key, value) => onSaveTypes(key, value, EMPLOYER)} selected={selectedActivityTypes[EMPLOYER]} />
          </div>
          </>)
      case types[2].value:
          return <DateSpan className='border p-2 border-gray-300 rounded-md' type={JOB_POSTING_TRACKING} title="Search between dates" onClick={onSaveTypes} selected={selectedActivityTypes[JOB_POSTING_TRACKING]} />
      case types[3].value:
          return <DateSpan className='border p-2 border-gray-300 rounded-md' type={JOB_STAFFING_TRACKING} title="Search between dates" onClick={onSaveTypes} selected={selectedActivityTypes[JOB_STAFFING_TRACKING]} />
      default:
        break;
    }
  }

  const renderTypes = () => {
    if(!selectedActivityTypes[GENERAL]?.products) return null;
    return selectedActivityTypes[GENERAL].products.map((product) => {
      const name = product.split('::')[0]
      const eventsByName = activityTypes.data[name]
      const filteredEventsByName = eventsByName.filter(({group}) => group === type)
      return (<div className='mt-2'>
        <div className='text-xs'>{name.toUpperCase()}</div>
        <CheckboxGroup onSetChange={(value) => onSaveTypes('events', value, type)} data={filteredEventsByName} selected={selectedActivityTypes[type]} className="my-2 text-xs" />
      </div>)
    })
  }

  const onSearchQueryChange = q => {
    const qsGeneral = getQueryString(selectedActivityTypes[GENERAL])
    const qsType = getQueryString(selectedActivityTypes[type])
    const filters = { query: q , ...qsGeneral, ...qsType }
    dispatchList(type, filters);
  };

  const onCreateLead = (form) => {
    return dispatch(createLead(form))
  }

  const onClearAllTasks = () => {
    setSelectedConsultantTasks([])
    setSelectedEmployerTasks([])
    setSelectedLeadTasks([])
  }
  console.log(isConsultantTaskListModalOpen)
  return (
    <main className='flex w-full m-8 flex-col'>
      <LeadsModal onSave={onCreateLead} setVisibility={setIsLeadsModalOpen} visible={isLeadsModalOpen} />
      {selectedTask && (
          <TaskModal
              task={selectedTask}
              visible={editTaskModalVisible}
              onCancel={() => window.location.reload()}
          />
        )
      }
      <div>
        <Button onClick={() => setShowFilters(!showFilters) }>{showFilters ? 'Hide ' : 'Show '} filters</Button>
      </div>
      {showFilters && <div className='w-full'>
        <div className='flex flex-col mb-4 border p-2 border-gray-300 rounded-md mt-2'>
        <h5>Filters</h5>
        <div className='flex'>
          <DropDownGroup
            data={products}
            onChange={(key, value) => onSaveTypes(key, value, GENERAL)} 
            selected={selectedActivityTypes[GENERAL]}
            className='mr-2'
          />
          <SelectList className='mr-2' data={countries} onChange={(key, value) => onSaveTypes(key, value, GENERAL)} selected={selectedActivityTypes[GENERAL]} />
          <SelectList className='mr-2' updateKey='country_code' data={counties} onChange={(key, value) => onSaveTypes(key, value, GENERAL)} selected={selectedActivityTypes[GENERAL]} />
          { Object.keys({ ...selectedActivityTypes[type], ...selectedActivityTypes[GENERAL]}).length > 0 && <Button onClick={onClearAll}>Clear all filters</Button>}
          </div>
        </div>
        <div className='flex flex-col mb-4'>
          <h5>Show types</h5>
          <div className='flex'>
            <RadioGroup className='border p-2 border-gray-300 rounded-md mr-2' onSetChange={onSetGroup} data={types.slice(0, 2)} value={type} />
            <RadioGroup className='border p-2 border-gray-300 rounded-md mr-2' onSetChange={onSetGroup} data={types.slice(2, 4)} value={type} />
            <RadioGroup className='border p-2 border-gray-300 rounded-md' onSetChange={onSetGroup} data={types.slice(4)} value={type} />
          </div>
        </div>
        {type === CONSULTANT && <div className='flex flex-col mb-4 border p-2 border-gray-300 rounded-md'>
          <h5>Show students</h5>
          <RadioGroup onSetChange={onSetStudentGroup} data={studentTypes} value={studentType} />
          <div>
            <Checkbox style={{ marginRight: '4px', marginBottom: 4 }} onChange={onShowGraduates} checked={selectedStudentGraduate}/>
            <span>Show graduate within 6 months</span>
          </div>
        </div>}
        <div className='flex flex-col mb-4'>
        { renderTypeFilters(type) }
        </div>
        { (events && events.length > 0) && (
          <div className='border p-2 border-gray-300 rounded-md'>
            <h5>Events</h5>
            <div className='mb-4'>
              <DateSpan type={CONSULTANT} title="Search between event dates" onClick={onSaveTypes} selected={selectedActivityTypes[CONSULTANT]} />
            </div>
            {renderTypes()}
          </div>
        )}
      </div>}
      { Object.keys({ ...selectedActivityTypes[type], ...selectedActivityTypes[GENERAL]}).length > 0 && <div className='my-2'>
        <h5>Tags</h5>
        <TagGroup
          onRemoveTag={onRemoveTag}
          selectedActivityTypes={
            {
              ...selectedActivityTypes[type],
              ...selectedActivityTypes[GENERAL],
            }
          }
        />
      </div>}
      <div className='flex'>
        <div className='flex-1 ml-2 mt-2'>
          {renderTypeHeader(type)}
          <div className=' bg-gray-200 p-2 rounded'>
            {(type === CONSULTANT || type === EMPLOYER) && (<div className='flex'>
              <Button type='primary' onClick={onCreateTaskListModal}>Lists ({getListCount(type)})</Button>
              <SearchBar
                  placeHolder={`Search ${type}.....`}
                  onChange={onSearchQueryChange}
                  containerStyle={{ position: 'relative', height: 'auto', left: 0, marginLeft: 4 }}
                  inputStyle={{ padding: '5px', width: '200px'}}
              />
            </div>)}
            {renderType(type)}
          </div>
        </div>
        <div className='flex-1 ml-2 mt-2'>
        <h2 className='text-gray-600 mb-1'>Tasks</h2>
          <div className=' bg-gray-200 p-2 rounded'>
            <TaskList
              onClick={onSetSelectedTask}
              onClearAllTasks={onClearAllTasks}
              selectedConsultantTasks={selectedConsultantTasks}
              selectedEmployerTasks={selectedEmployerTasks}
              selectedLeadTasks={selectedLeadTasks}
              />
          </div>
        </div>
      </div>
      {isConsultantTaskListModalOpen && <TaskListModal
        items={consultants.consultants}
        data={consultants}
        onChangePage={onChangeConsultantPage}
        onClose={onTaskListModalClose}
        keyName="fullname"
        onSaveComment={onAddComment}
        user={session.username}
        comments={comments}
        onGetComments={onGetComments}
        onAddTask={onAddTask}
        onSendCallList={onSendCallList}
      />}
      {isEmployerTaskListModalOpen && <TaskListModal 
        items={employers.employers}
        data={employers}
        onChangePage={onChangeEmployerPage}
        onClose={onTaskListModalClose}
        onSaveComment={onAddComment}
        keyName="name"
        user={session.username}
        comments={comments}
        onGetComments={onGetComments}
        onAddTask={onAddTask}
      />}
      { isLoading && <Loader />}
    </main>
  );
};

export default ActivityBoard;
