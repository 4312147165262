import React, { useEffect } from 'react';
import {
    useSelector,
    useDispatch,
    shallowEqual,
} from 'react-redux';
import {
    Link,
    useParams,
} from 'react-router-dom';
import {
    fetchConsultantEmployers,
    updateConsultantEmployer,
} from 'actions/consultant';
import { getMarginOptions } from 'utils/margins';

import {
    Avatar,
    Table,
    Switch,
    Select,
} from 'antd';

import './ConsultantEmployers.scss';

const marginOptions = getMarginOptions();

const ConsultantEmployers = () => {
    const { id } = useParams();

    const employers = useSelector(state => state.consultant.employers, shallowEqual).filter(e => e.added);
    const introducedLoading = useSelector(state => state.consultant.introducedLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchConsultantEmployers(id));
    }, [id, dispatch]);

    const handleUpdateConsultantEmployer = (record, {
        introduced,
        salaryMargin,
        consultantFeeMargin,
    }) => {
        dispatch(updateConsultantEmployer(id, record.employer.id, {
            introduced: introduced !== undefined ? introduced : record.introduced,
            salary_margin: salaryMargin !== undefined ? salaryMargin : record.salary_margin,
            consultant_fee_margin: consultantFeeMargin !== undefined ? consultantFeeMargin : record.consultant_fee_margin,
        }));
    };

    const columns = [{
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (_, consultant) => (
            <div className='employer'>
                <Avatar size='large' src={consultant.employer.logo_url} />
                <div className='info'>
                    <Link to={`/employers/${consultant.employer.id}`}>{consultant.employer.name}</Link>
                    {consultant.employer.department && <p>{consultant.employer.department}</p>}
                </div>
            </div>
        ),
    },
    {
        title: 'Introducerad',
        dataIndex: 'introduced',
        key: 'introduced',
        render: (_, record) => (
            <Switch
                checked={record.introduced}
                loading={introducedLoading}
                onChange={() => handleUpdateConsultantEmployer(record, { introduced: !record.introduced })}
            />
        ),
    }, {
        title: 'Salary Margin',
        dataIndex: 'salary_margin',
        key: 'salary_margin',
        render: (_, record) => (
            <Select
                value={record.salary_margin}
                onChange={salaryMargin => handleUpdateConsultantEmployer(record, { salaryMargin })}
            >
                <Select.Option value=''>Default</Select.Option>
                {marginOptions.map(option => (
                    <Select.Option value={option.value}>
                        {option.value}
                        %
                    </Select.Option>
                ))}

            </Select>
        ),
    }, {
        title: 'Consultant fee Margin',
        dataIndex: 'consultant_fee_margin',
        key: 'consultant_fee_margin',
        render: (_, record) => (
            <Select
                value={record.consultant_fee_margin}
                onChange={consultantFeeMargin => handleUpdateConsultantEmployer(record, { consultantFeeMargin })}
            >
                <Select.Option value=''>Default</Select.Option>
                {marginOptions.map(option => (
                    <Select.Option value={option.value}>
                        {option.value}
                        %
                    </Select.Option>
                ))}

            </Select>
        ),
    }];
    return (
        <div className='content-card-full consultant-employers'>
            <Table
                columns={columns}
                dataSource={employers}
                pagination={false}
            />
        </div>
    );
};

export default ConsultantEmployers;
