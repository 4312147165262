import * as request from 'request';

export function recieveJob(job) {
    return {
        type: 'RECEIVE_JOB',
        job,
    };
}

export function createJobSuccess(job) {
    return {
        type: 'CREATE_JOB_SUCCESS',
        job,
    };
}

export function deleteJobSuccess(jobId) {
    return {
        type: 'DELETE_JOB_SUCCESS',
        jobId,
    };
}

export function updateJobSuccess(job) {
    return {
        type: 'UPDATE_JOB_SUCCESS',
        job,
    };
}

export function fetchJob(jobId) {
    return dispatch => request.fetchJob(jobId)
        .then(resp => dispatch(recieveJob(resp.job)));
}

export function createJobProfile(employerId, jobProfile) {
    return dispatch => request.createEmployerProfile(employerId, jobProfile)
        .then(resp => dispatch(createJobSuccess(resp.profile)));
}

export function deleteJobProfile(profileId) {
    return dispatch => request.deleteJobProfile(profileId)
        .then(() => dispatch(deleteJobSuccess(profileId)));
}

export function updateJobProfile(jobProfile) {
    return dispatch => request.updateJobProfile(jobProfile.id, jobProfile)
        .then(resp => dispatch(updateJobSuccess(resp.profile)));
}
