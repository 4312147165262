import { message } from 'antd';
import * as request from 'request';

const requestFetchUserComments = () => ({ type: 'REQUEST_FETCH_USER_COMMENTS' });

const receiveFetchUserComments = comments => ({
    type: 'RECEIVE_FETCH_USER_COMMENTS',
    comments,
});

export const fetchUserComments = userId => dispatch => {
    dispatch(requestFetchUserComments());
    return request.fetchUserComments(userId)
        .then(resp => {
            dispatch(receiveFetchUserComments(resp.comments));
        });
};

const receiveAddUserComment = comment => ({
    type: 'RECEIVE_ADD_USER_COMMENT',
    comment,
});

export const addUserComment = (userId, content, taskId) => async dispatch => {
    try {
        const resp = await request.postUserComment(userId, {
            comment: content,
            task_id: taskId,
        });

        dispatch(receiveAddUserComment(resp.comment));
    }
    catch {
        message.error('Failed to post comment');
    }
};

export const addUserCommentV1 = (userId, content) => async dispatch => {
    try {
        const resp = await request.postUserComment(userId, content);

        dispatch(receiveAddUserComment(resp.comment));
    }
    catch {
        message.error('Failed to post comment');
    }
};

/** EMPLOYERS * */

const requestFetchEmployerComments = () => ({ type: 'REQUEST_FETCH_EMPLOYER_COMMENTS' });

const receiveFetchEmployerComments = comments => ({
    type: 'RECEIVE_FETCH_EMPLOYER_COMMENTS',
    comments,
});

export const fetchEmployerComments = employerId => dispatch => {
    dispatch(requestFetchEmployerComments());
    return request.fetchEmployerComments(employerId)
        .then(resp => {
            dispatch(receiveFetchEmployerComments(resp.comments));
        });
};

const receiveAddEmployerComment = comment => ({
    type: 'RECEIVE_ADD_EMPLOYER_COMMENT',
    comment,
});

export const addEmployerComment = (employerId, content, taskId) => dispatch => request.postEmployerComment(employerId, {
    comment: content, task_id: taskId,
}).then(resp => {
    dispatch(receiveAddEmployerComment(resp.comment));
});


export const addEmployerCommentV1 = (employerId, content) => async dispatch => {
    try {
        const resp = await request.postEmployerComment(employerId, content);

        dispatch(receiveAddEmployerComment(resp.comment));
    }
    catch {
        message.error('Failed to post comment');
    }
};
