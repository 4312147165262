const initialState = {
    activities: [],
    isFetching: true,
    hasFetched: false,
};

export default function activities(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_ACTIVITIES':
            return {
                ...state,
                isFetching: true,
            };

        case 'RECEIVE_ACTIVITIES':
            return {
                ...state,
                activities: action.activities,
                isFetching: false,
                hasFetched: true,
            };

        default:
            return state;
    }
}
