import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import { styles } from './styles.scss';

const Ink = require('react-ink');

class Button extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        onClick: PropTypes.func,
        loading: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        className: '',
        disabled: false,
        type: 'button',
        style: {},
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        this.props.onClick && this.props.onClick(e);
    }

    renderLoading() {
        return (
            <span className='mp-button__loader'>
                <i className='fa fa-circle-o-notch fa-spin' />
            </span>
        );
    }

    render() {
        return (
            <button
                className={classNames({
                    'mp-button': true,
                    'mp-button--block': this.props.block,
                    'mp-button--outline': this.props.outline,
                    'mp-button--outline-color': this.props.outlineColor,
                    'mp-button--outline-color-secondary': this.props.outlineColorSecondary,
                    'mp-button--warn': this.props.warn,
                    'mp-button--outline-warn': this.props.outlineWarn,
                    'mp-button--small': this.props.small,
                    'mp-button--confirm': this.props.confirm,
                    [this.props.className]: true,
                })}
                disabled={this.props.disabled}
                style={this.props.style}
                type={this.props.type}
                onClick={this.onClick}
            >
                <Ink />
                {this.props.loading && this.renderLoading()}
                {this.props.children}
            </button>
        );
    }
}

export default Button;
