import React from 'react';
import classNames from 'utils/classnames';
import { withFormsy } from 'formsy-react';
import Label from 'components/shared/label';
import Datepicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import styles from './styles.scss';

registerLocale('sv', sv);

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.renderError = this.renderError.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.state = {
            hasFocused: false,
            hasError: false,
        };
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            this.props.setValue(this.props.defaultValue);
        }

        if (this.props.autoFocus) {
            this.focus();
        }
    }

    changeValue(event) {
        this.props.setValue(event);
    }

    onBlur() {
        const error = this.props.errorMessage;

        if (error) {
            this.setState({ hasError: true });
        }

        if (this.props.reformatOnBlur) {
            this.props.setValue(this.props.reformatOnBlur(this.props.value));
        }

        this.props.onBlur && this.props.onBlur();
    }

    onFocus() {
        this.setState({
            hasFocused: true,
            hasError: false,
        });

        this.props.onFocus && this.props.onFocus();
    }

    renderError() {
        const error = this.props.errorMessage;

        if (error && this.state.hasFocused) {
            return (
                <div className='mp-datepicker__error'>
                    {error}
                </div>
            );
        }
    }

    renderLabel() {
        if (this.props.label) {
            return (
                <Label
                    {...this.props}
                />
            );
        }
    }

    focus() {
        this.refs.datepicker.focus();
    }

    render() {
        return (
            <div
                className={classNames({
                    'mp-datepicker__wrapper': true,
                    'mp-datepicker__wrapper--block': this.props.block,
                    'mp-datepicker__wrapper--error': this.state.hasError,
                    [`mp-datepicker__wrapper-error-message-style-${this.props.errorMessageStyle}`]: true,
                    [this.props.wrapperClass]: true,
                })}
                style={this.props.wrapperStyle}
            >
                {this.renderLabel()}
                <Datepicker // https://hacker0x01.github.io/react-datepicker/
                    ref='datepicker'
                    className={classNames({
                        'mp-datepicker': true,
                        [this.props.className]: true,
                    })}
                    dateFormat={this.props.dateFormat}
                    disabled={this.props.disabled}
                    inline
                    locale='sv'
                    name={this.props.name}
                    nextMonthButtonLabel=''
                    previousMonthButtonLabel=''
                    selected={this.props.value}
                    value={this.props.value}
                    onBlur={this.onBlur}
                    onChange={this.changeValue}
                    onFocus={this.onFocus}
                />
                {this.renderError()}
            </div>
        );
    }
}

DatePicker.defaultProps = {
    className: '',
    wrapperClass: '',
    dateFormat: 'YYYY/MM/DD',
    type: 'text',
    defaultValue: '',
    placeholder: '',
    required: false,
    validations: {},
    validationErrors: {},
    label: '',
    block: false,
    onBlur: null,
    onFocus: null,
    wrapperStyle: {},
    errorMessageStyle: 'hint', // 'hint' or 'error' is available
};

export default withFormsy(DatePicker);
