import React from 'react';

import { humanReadableHours } from 'utils/datetimeformatters';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Table } from 'antd';

import PropTypes from 'prop-types';
import './styles.scss';

const RowDetailsTable = ({ scheduled }) => {
    const data = scheduled.map((item, i) => ({
        ...item,
        key: i + 1,
    }));
    const columns = [{
        title: 'Konsult ID',
        dataIndex: 'consultant',
        key: 'consultant',
        render: (text, record) => (
            <Link to={`/consultants/${record.user_id}`}>
                {record.user_id}
            </Link>
        ),
    }, {
        title: 'Datum',
        dataIndex: 'start_time',
        key: 'date',
        render: (text, record) => moment.unix(record.start_time).format('YYYY-MM-DD'),
    }, {
        title: 'Arbetstid',
        dataIndex: 'scheduled',
        key: 'scheduled',
        render: (text, record) => `${moment.unix(record.start_time).format('HH:mm')} - ${moment.unix(record.end_time).format('HH:mm')}`,
    }, {
        title: 'Arbetad tid',
        dataIndex: 'duration',
        key: 'duration',
        render: text => humanReadableHours(text),
    }, {
        title: 'Övertid',
        dataIndex: 'deviation',
        key: 'deviation',
        render: text => humanReadableHours(text),
    }, {
        title: 'Rast',
        dataIndex: 'shift_break',
        key: 'shift_break',
        render: text => humanReadableHours(text),
    }, {
        title: 'Pris',
        dataIndex: 'price',
        key: 'price',
        render: text => `${text}kr`,
    }, {
        title: 'Profil',
        dataIndex: 'profile',
        key: 'profile',
        render: (text, record) => `${record.profile.name}`,
    }];

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            size='middle'
        />
    );
};

RowDetailsTable.propTypes = { scheduled: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.number,
    shift_break: PropTypes.number,
    price: PropTypes.number,
    duration: PropTypes.number,
    deviation: PropTypes.number,
    start_time: PropTypes.number,
    end_time: PropTypes.number,
    profile: PropTypes.shape({ name: PropTypes.string }),
})) };

export default RowDetailsTable;
