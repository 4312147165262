import {
    merge,
    omit,
} from 'lodash';

const initialState = {
    errorMessage: '',
    usernameAvailable: true,

    isCopying: false,

    shouldCopyContacts: false,
    copyContactsOngoing: false,
    copyContactsSuccess: false,
    contacts: [],

    shouldCopyLogo: false,
    copyLogoOngoing: false,
    copyLogoSuccess: false,
    logo_url: null,

    shouldCopyCover: false,
    copyCoverOngoing: false,
    copyCoverSuccess: false,
    cover_url: null,

    shouldCopyCustomShifts: false,
    copyCustomShiftsOngoing: false,
    copyCustomShiftsSuccess: false,
    copyProfilesOngoing: false,
    copyProfilesSuccess: false,
    customShifts: [],
    profiles: [],

    formData: {
        username: '',
        email: '',
        name: '',
        password: '',
        password_retype: '',
        organization_number: '',
        emr_system: undefined,
        phone: '',
        account_name: '',
        account_phoone: '',
        account_email: '',
        account_title: '',
        county_id: undefined,
        description: '',
        secondary_department_type_id: undefined,
        isprotected: null,
        address: {
            addressline1: '',
            addressline2: '',
            city: '',
            postalcode: '',
            country_code: 'SWE',
        },
        invoice_address: {
            name: '',
            department: '',
            addressline1: '',
            addressline2: '',
            city: '',
            postalcode: '',
            country_code: 'SWE',
            reference: '',
        },
    },
};

const resetState = { ...initialState };

const createEmployer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CREATE_EMPLOYER_FORM_DATA':
            return {
                ...state,
                isCopying: true,
                formData: merge({}, state.formData, omit(action.data, 'contacts', 'logo_url', 'cover_url')),
                shouldCopyContacts: action.data.shouldCopyContacts,
                contacts: action.data.shouldCopyContacts ? action.data.contacts : [],
                shouldCopyLogo: action.data.shouldCopyLogo,
                logo_url: action.data.shouldCopyLogo ? action.data.logo_url : null,
                shouldCopyCover: action.data.shouldCopyCover,
                cover_url: action.data.shouldCopyCover ? action.data.cover_url : null,
                shouldCopyCustomShifts: action.data.shouldCopyCustomShifts,
                customShifts: action.data.shouldCopyCustomShifts ? action.data.customShifts : [],
                shouldCopyProfiles: action.data.shouldCopyProfiles,
                profiles: action.data.shouldCopyProfiles ? action.data.profiles : [],
            };

        case 'RESET_CREATE_EMPLOYER_FORM':
            return { ...resetState };

        case 'REQUEST_CREATE_EMPLOYER':
            return {
                ...state,
                errorMessage: '',
            };

        case 'CREATE_EMPLOYER_SUCCESS':
            return { ...state };

        case 'CREATE_EMPLOYER_ERROR':
            return {
                ...state,
                errorMessage: action.errorMessage,
            };

        case 'REQUEST_COPY_CONTACTS':
            return {
                ...state,
                copyContactsOngoing: true,
            };

        case 'COPY_CONTACTS_SUCCESS':
            return {
                ...state,
                copyContactsOngoing: false,
                copyContactsSuccess: true,
            };

        case 'REQUEST_COPY_LOGO':
            return {
                ...state,
                copyLogoOngoing: true,
            };

        case 'COPY_LOGO_SUCCESS':
            return {
                ...state,
                copyLogoOngoing: false,
                copyLogoSuccess: true,
            };

        case 'REQUEST_COPY_COVER':
            return {
                ...state,
                copyCoverOngoing: true,
            };

        case 'COPY_COVER_SUCCESS':
            return {
                ...state,
                copyCoverOngoing: false,
                copyCoverSuccess: true,
            };

        case 'REQUEST_COPY_CUSTOM_SHIFTS':
            return {
                ...state,
                copyCustomShiftsOngoing: true,
            };

        case 'COPY_CUSTOM_SHIFTS_SUCCESS':
            return {
                ...state,
                copyCustomShiftsOngoing: false,
                copyCustomShiftsSuccess: true,
            };

        case 'REQUEST_COPY_PROFILES':
            return {
                ...state,
                copyProfilesOngoing: true,
            };

        case 'COPY_PROFILES_SUCCESS':
            return {
                ...state,
                copyProfilesOngoing: false,
                copyProfilesSuccess: true,
            };

        case 'USERNAME_AVAILABILITY':
            return {
                ...state,
                usernameAvailable: action.usernameAvailable,
            };
        default:
            return state;
    }
};

export default createEmployer;
