import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Button,
    Spin,
    AutoComplete,
} from 'antd';
import {
    CheckOutlined,
    CopyOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { debounce } from 'lodash';
import { fetchEmployersV3 } from '../../request';
import Documents from './handleprocurementmodal/Documents';

const InfoRow = ({
    procurement,
    mpSpecializations,
    procurementOccupation,
    counties,
    setSelectedEmployer,
    setIsModalVisible,
    setSelectedProcurement,
}) => {
    const newEmployer = {
        id: -1,
        value: -1,
        label: 'Create new employer',
    };
    const [employerOptions, setEmployerOptions] = useState([newEmployer]);
    const [onSelectedEmployer, setOnSelectedEmployer] = useState(null);
    const [isFectching, setIsFectching] = useState(false);
    useEffect(() => {
        if (onSelectedEmployer?.value === -1) {
            setSelectedEmployer(null);
            setIsModalVisible(true);
        }
    }, [onSelectedEmployer, setIsModalVisible, setSelectedEmployer]);
    const specializations = mpSpecializations(procurement);
    const occupation = procurementOccupation(procurement);

    const onEmployerButtonClick = copy => {
        setSelectedEmployer(copy ? {
            ...onSelectedEmployer, copy,
        } : onSelectedEmployer);
        setIsModalVisible(true);
    };

    const onProfileButtonClick = () => {
        setSelectedProcurement(procurement);
        const { employer } = procurement;
        employer.value = employer.id;
        setSelectedEmployer(employer);
        setIsModalVisible(true);
    };

    const searchEmployer = useCallback(debounce(val => {
        setIsFectching(true);
        if (val) {
            fetchEmployersV3(val)
                .then(res => {
                    const employerList = res.collection.map(employer => ({
                        id: employer.id,
                        value: employer.id,
                        label: employer.name,
                    }));
                    employerList.push(newEmployer);
                    setEmployerOptions(employerList);
                    setIsFectching(false);
                })
                .catch(() => {
                    setEmployerOptions([newEmployer]);
                    setIsFectching(false);
                });
        }
    }, 500), []);

    const antIcon = (
        <SyncOutlined
            spin
            style={{
                fontSize: 20,
            }}
        />
    );

    return (
        <Row>
            <Col className='info-item' span={4}>
                <div style={{ marginBottom: 12 }}>
                    <h6>Occupation</h6>
                    <p>{occupation.name}</p>
                </div>
                <h6>Specializations</h6>
                { specializations.length ? specializations.map(specialization => (
                    <p key={specialization.id}>{specialization.name}</p>
                )) : <p>No specializations</p>}
                <h6>
                    Driving licence
                    {' '}
                    {!procurement.driver_license && 'not'}
                    {' '}
                    required
                </h6>
            </Col>
            <Col className='info-item' span={8}>
                {procurement?.documents.length
                    ? (
                        <div style={{ marginBottom: 12 }}>
                            <Documents documents={procurement.documents} />
                        </div>
                    ) : <></>}
                <h6>Description</h6>
                <p style={{ whiteSpace: 'break-spaces' }}>{procurement.description}</p>
            </Col>
            <Col className='info-item' span={4}>
                <h6>Location</h6>
                <p>
                    Municipality:
                    {' '}
                    {procurement.municipality}
                    <br />
                    County:
                    {' '}
                    {counties.find(county => county.id === procurement.county_id).name}
                </p>
                <h6>Contact</h6>
                <p>
                    <a href={`mailto:${procurement.contact_person_email}`}>
                        {procurement.contact_person_name}
                    </a>
                </p>
            </Col>
            {!procurement.employer ? (
                <Col
                    className='info-item row'
                    span={8}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <AutoComplete
                        allowClear
                        options={employerOptions}
                        placeholder='Search employer'
                        style={{
                            width: '100%',
                            marginBottom: 5,
                        }}
                        value={onSelectedEmployer?.label}
                        onChange={val => {
                            setOnSelectedEmployer(null);
                            if (!val) {
                                setEmployerOptions([newEmployer]);
                            }
                        }}
                        onClear={() => {
                            setOnSelectedEmployer(null);
                            setEmployerOptions([newEmployer]);
                        }}
                        onFocus={() => setSelectedProcurement(procurement)}
                        onSearch={searchEmployer}
                        onSelect={val => {
                            if (val > -1) {
                                setOnSelectedEmployer(employerOptions.find(employer => employer.value === val));
                            }
                            else {
                                setOnSelectedEmployer({
                                    value: -1, label: 'Create new employer',
                                });
                            }
                        }}
                    />
                    <Spin
                        indicator={antIcon}
                        spinning={isFectching}
                        style={{
                            position: 'absolute',
                            right: 35,
                            top: 5,
                        }}
                    />
                    {onSelectedEmployer?.value > -1 && (
                        <div>
                            <Button
                                disabled={!onSelectedEmployer}
                                icon={<CheckOutlined />}
                                style={{ marginRight: 5 }}
                                onClick={() => onEmployerButtonClick(false)}
                            >
                                Match employer
                            </Button>
                            <Button
                                disabled={!onSelectedEmployer}
                                icon={<CopyOutlined />}
                                onClick={() => onEmployerButtonClick(true)}
                            >
                                Copy employer

                            </Button>
                        </div>
                    )}
                </Col>
            ) : (
                <Col
                    className='info-item row'
                    offset={1}
                    span={7}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <h6>Employer</h6>
                    <p>
                        <Link to={`/employers/${procurement.employer.id}`}>{procurement.employer.name}</Link>
                    </p>
                    <h6>Job id</h6>
                    {
                        procurement.job_id
                            ? <p>{procurement.job_id}</p>
                            : <Button onClick={() => onProfileButtonClick()}>Create / choose profile</Button>
                    }
                </Col>
            )}
        </Row>
    );
};

InfoRow.propTypes = {
    procurement: PropTypes.shape({
        id: PropTypes.number.isRequired,
        occupation_id: PropTypes.number.isRequired,
        specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
        description: PropTypes.string.isRequired,
        municipality: PropTypes.string.isRequired,
        county_id: PropTypes.number.isRequired,
        contact_person_name: PropTypes.string.isRequired,
        contact_person_email: PropTypes.string.isRequired,
        driver_license: PropTypes.bool.isRequired,
        employer: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string.isRequired,
            value: PropTypes.number,
        }),
        job_id: PropTypes.number,
    }),
    mpSpecializations: PropTypes.func.isRequired,
    procurementOccupation: PropTypes.func.isRequired,
    counties: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    setSelectedEmployer: PropTypes.func.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    setSelectedProcurement: PropTypes.func.isRequired,
};

export default InfoRow;
