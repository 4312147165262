import React from 'react';
import Ink from 'react-ink';
import $ from 'jquery';
import { filter } from 'lodash';
import Formsy from 'formsy-react';
import classNames from 'utils/classnames';
import Input from 'components/shared/input';
import onClickOutside from 'react-onclickoutside';

class SelectOptions extends React.Component {
    constructor(props) {
        super(props);
        this.renderOption = this.renderOption.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.state = {
            filteredOptions: props.options,
            selectedIndex: false,
        };
    }

    componentDidMount() {
        if (this.props.filter) {
            this.refs.searchinput.focus();
            this.setUpKeyListeners();
        }
    }

    componentWillUnmount() {
        if (this.props.filter) {
            this.unsetKeyListeners();
        }
    }

    setUpKeyListeners() {
        $(document).on('keydown.SCROLL_LIST', event => {
            switch (event.which) {
                case 40: // ARROW DOWN
                    this.increaseSelectedIndex();
                    break;
                case 38: // DOWN ARROW
                    this.decreaseSelectedIndex();
                    break;
                case 13: // ENTER KEY
                    this.submitSelectedIndex();
                    break;
                default:
                    break;
            }
        });
    }

    unsetKeyListeners() {
        $(document).off('keydown.SCROLL_LIST');
    }

    handleClickOutside() {
        this.props.close();
    }

    increaseSelectedIndex() {
        const nextIndex = Math.min((this.state.selectedIndex + 1), (this.state.filteredOptions.length - 1));
        const topOffset = nextIndex * 40 + 80;

        if (topOffset > (400 - 72)) {
            this.refs.list.scrollTop = this.refs.list.scrollTop + 40;
        }
        this.setState({ selectedIndex: nextIndex });
    }

    decreaseSelectedIndex() {
        const nextIndex = Math.max((this.state.selectedIndex - 1), 0);
        const topOffset = nextIndex * 40 - 80;

        if (topOffset < (400 - 72)) {
            this.refs.list.scrollTop = this.refs.list.scrollTop - 40;
        }
        this.setState({ selectedIndex: nextIndex });
    }

    onFilterChange(event) {
        const { value } = event.currentTarget;
        if (!value) {
            this.setState({ filteredOptions: this.props.options });
        }
        this.setState({ filteredOptions: filter(this.props.options, option => option.title.toLowerCase().indexOf(value.toLowerCase()) !== -1) });
    }

    renderFilter() {
        if (this.props.filter) {
            return (
                <div>
                    <div className='mp-select__filter'>
                        <input
                            ref='searchinput'
                            autoComplete='off'
                            block
                            className='mp-input mp-select__filter-input'
                            name='query'
                            placeholder='Sök specialistkompetens'
                            onChange={this.onFilterChange}
                        />
                    </div>
                </div>
            );
        }
    }

    setSelectedIndex(index) {
        this.setState({ selectedIndex: index });
    }

    submitSelectedIndex() {
        const {
            filteredOptions, selectedIndex,
        } = this.state;
        this.props.onSelect(filteredOptions[selectedIndex].value);
    }

    renderOption(option, i) {
        const { selectedIndex } = this.state;
        return (
            <div
                key={option.value + i.toString()}
                className={classNames({
                    'mp-select__option': true,
                    'mp-select__option--is-highlighted': selectedIndex !== false && i === this.state.selectedIndex,
                    'mp-select__option--is-selected': option.value === this.props.value,
                    'mp-select__option--is-disabled': option.disabled,
                })}
                onClick={() => {
                    this.props.onSelect(option.value);
                }}
                onMouseEnter={() => {
                    this.setSelectedIndex(i);
                }}
            >
                <Ink />
                {option.title}
            </div>
        );
    }

    render() {
        return (
            <div className='mp-select__options'>
                {this.renderFilter()}
                <div ref='list' className='mp-select__options-list'>
                    {this.state.filteredOptions.map(this.renderOption)}
                </div>
            </div>
        );
    }
}

export default onClickOutside(SelectOptions);
