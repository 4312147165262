import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

function ConsultantPreview({
    user,
    subTitle,
    link,
}) {
    const {
        avatar,
        firstname,
        lastname,
        id,
    } = user;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: subTitle ? 'space-between' : 'center',
            }}
        >
            <Avatar
                size='large'
                src={avatar}
                style={{ marginRight: 10 }}
            >
                {!avatar
                    ? firstname[0] + lastname[0]
                    : ''}
            </Avatar>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: subTitle ? 'space-between' : 'center',
                }}
            >
                <Link to={`/consultants/${link || id}`}>
                    {firstname}
                    {' '}
                    {lastname}
                </Link>
                {subTitle ?? (
                    <div>
                        {subTitle}
                    </div>
                )}
            </div>
        </div>
    );
}

ConsultantPreview.propTypes = {
    user: PropTypes.shape({
        avatar: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    subTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    link: PropTypes.string,
};

export default ConsultantPreview;
