import {
    CloseOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import {
    Button,
    Calendar,
    ConfigProvider,
    Popover,
} from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ShiftDatePicker = ({
    addShifts,
    disabledDate,
    validDateRange,
}) => {
    const [selectedDate, setSelectedDate] = useState(moment());
    const [open, setOpen] = useState(false);
    return (
        <Popover
            content={(
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '8px',
                        }}
                    >
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                    <ConfigProvider
                        locale={en_GB}
                    >
                        <Calendar
                            disabledDate={disabledDate}
                            fullscreen={false}
                            validRange={validDateRange}
                            onSelect={date => {
                                // Antd Calendar has a bug that causes onSelect to trigger when the user
                                // switches months with the built-in month picker. This is a simple way
                                // to override that behavior. It has the slight drawback of not allowing
                                // the user to select a date outside of the selected month even when the
                                // date is shown as a part of the current month date range, but at least
                                // we won't select dates by mistake.
                                if (date.isSame(selectedDate, 'month')) {
                                    addShifts([{ date }]);
                                }
                                setSelectedDate(date);
                            }}
                        />
                    </ConfigProvider>
                </div>
            )}
            trigger='click'
            visible={open} // Add this line
            onVisibleChange={setOpen} // Add this line
        >
            <Button
                icon={<PlusCircleOutlined />}
                size='large'

            >
                Add dates
            </Button>
        </Popover>
    );
};

ShiftDatePicker.propTypes = {
    addShifts: PropTypes.func.isRequired,
    disabledDate: PropTypes.func,
    validDateRange: PropTypes.arrayOf(PropTypes.object),
};

export default ShiftDatePicker;
