import React, {
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Card,
    Button,
    Spin,
} from 'antd';
import {
    EditOutlined,
    LoadingOutlined,
    PlusOutlined,
    ExpandAltOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchEmployerConsultant } from 'request';
import { getJobById } from '../../request/index';
import ConsultantPreview from '../../components/shared/consultantpreview';
import { pluralFormatter } from '../../utils/nameformatters';
import LtvApplicationModal from '../applications/ltvapplicationmodal';

function ExpandedRow({
    ltv,
    fetchSingleLtv,
}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [shifts, setShifts] = useState([]);
    const [loadingShifts, setLoadingShifts] = useState(true);
    const [applicationModalData, setApplicationModalData] = useState();

    const {
        id,
        booking,
        employer,
        applications,
    } = ltv;
    const isBooked = booking?.id;
    const hasApplication = !isBooked && !!applications.length;

    const onBookConsultantDone = action => {
        if (action === 'refetch') {
            dispatch(fetchSingleLtv(ltv.id));
            setApplicationModalData();
        }
        else {
            setApplicationModalData();
        }
    };

    useEffect(() => {
        if (isBooked) {
            getJobById(id)
                .then(({ collection }) => {
                    setShifts(collection);
                    setLoadingShifts(false);
                })
                .catch(err => {
                    setLoadingShifts(false);
                });
        }
        else {
            setLoadingShifts(false);
        }
    }, [id, isBooked, ltv]);

    const userCard = (user, title) => (
        <Card title={title}>
            <ConsultantPreview
                user={user}
            />
        </Card>
    );

    const ShiftsCardTitle = () => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {shifts.length ? `${shifts.length} ${pluralFormatter(applications.length, 'shift', 'shifts')} booked` : 'No booked shifts'}
            {' '}
            {isBooked && (
                <Button
                    icon={shifts.length ? <EditOutlined /> : <PlusOutlined />}
                    onClick={() => {
                        const action = shifts.length ? 'openeditshiftsmodal' : 'openaddshiftsmodal';
                        history.push(`employers/${employer.id}/long-term-vacancies`, {
                            action,
                            id,
                        });
                    }}
                />
            )}
        </div>
    );

    const ApplicationCard = () => (
        <Card title={`${applications.length} ${pluralFormatter(applications.length, 'application', 'applications')}`}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                {applications.map(application => {
                    const {
                        user,
                        status,
                    } = application;
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <ConsultantPreview
                                subTitle={`Application ${status.toLowerCase()}`}
                                user={user}
                            />
                            <Button
                                icon={<ExpandAltOutlined />}
                                onClick={async () => {
                                    console.log('what', application, user);
                                    const extraData = await fetchEmployerConsultant(
                                        employer.id,
                                        application.user.id,
                                    );
                                    setApplicationModalData({
                                        ...application,
                                        job: { id },
                                        start_time: ltv.long_term_details.start_time,
                                        end_time: ltv.long_term_details.end_time,
                                        adminComment: extraData.employer_consultant.admin_comment,
                                        isBooked,
                                    });
                                }}
                            />
                        </div>
                    );
                })}

            </div>
        </Card>
    );

    const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

    return (
        <>
            {applicationModalData ? (
                <LtvApplicationModal
                    applicationId={applicationModalData.id}
                    close={onBookConsultantDone}
                    consultant={applicationModalData.user}
                    employer={employer}
                    ltvApplicationModalData={applicationModalData}
                    preventConfirmation={applicationModalData.isBooked}
                />
            ) : <></>}
            <Row>
                <Col span={8} style={{ padding: 4 }}>
                    <Card title={employer.name}>
                        <p>{employer.address.addressline1}</p>
                        {employer.address.addressline2 && <p>{employer.address.addressline2}</p>}
                        <p>
                            {employer.address.postalcode}
                            ,
                            {' '}
                            {employer.address.city}
                        </p>
                    </Card>
                </Col>
                <Col span={8} style={{ padding: 4 }}>
                    {isBooked && userCard(booking.user, 'Booked consultant')}
                    {!isBooked && hasApplication && <ApplicationCard />}

                </Col>
                <Col span={8} style={{ padding: 4 }}>
                    <Card
                        title={!loadingShifts ? <ShiftsCardTitle /> : 'Loading'}
                    >
                        {!loadingShifts ? shifts.map(shift => {
                            const {
                                start_time: startTime,
                                end_time: endTime,
                                id: shiftId,
                            } = shift;
                            return (
                                <p key={`shiftListItem${shiftId}`}>
                                    {
                                        `${moment.unix(startTime).format('dddd YYYY-MM-DD - HH:mm')}
                                            to 
                                         ${moment.unix(endTime).format('HH:mm')} `
                                    }
                                </p>
                            );
                        }) : (
                            <>
                                <Spin indicator={antIcon} />
                            </>
                        ) }
                    </Card>

                </Col>
            </Row>
        </>
    );
}

ExpandedRow.propTypes = {
    ltv: PropTypes.shape({
        id: PropTypes.number.isRequired,
        booking: PropTypes.shape({
            id: PropTypes.number.isRequired,
            user: PropTypes.shape({}),
        }),
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            address: PropTypes.shape({
                addressline1: PropTypes.string.isRequired,
                addressline2: PropTypes.string,
                postalcode: PropTypes.string.isRequired,
                city: PropTypes.string.isRequired,
            }),
        }).isRequired,
        applications: PropTypes.arrayOf(PropTypes.shape({
            user: PropTypes.shape({
                avatar: PropTypes.string,
                firstname: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                lastname: PropTypes.string.isRequired,
                occupation: PropTypes.number.isRequired,
                occupation_id: PropTypes.number.isRequired,
            }),
            status: PropTypes.string.isRequired,
            booked_time: PropTypes.number,
            id: PropTypes.number.isRequired,
            created_time: PropTypes.number.isRequired,
        })),
        introduction: PropTypes.bool.isRequired,
        introduction_rules: PropTypes.shape({}).isRequired,
        currency: PropTypes.string.isRequired,
    }).isRequired,
    fetchSingleLtv: PropTypes.func.isRequired,
};

export default ExpandedRow;
