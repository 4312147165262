import {
    without,
    filter,
    includes,
} from 'lodash';

const initialState = {
    consultant: {
        tags: [], supplier: {},
    },
    ssn: null,
    employers: [],
    introducedLoading: false,
    isFetching: true,
    hasFetched: false,
    isUpdating: false,
    logOutRequestOngoing: false,
    timeReports: [],
    hasFetchedTimeReports: false,
    payments: [],
    hasFetchedPayments: false,
    contracts: [],
    hasFetchedContracts: false,
    isFetchingActivities: false,
    activities: [],
    applications: [],
    ltvApplications: [],
    isFetchingApplications: false,
    isFetchingSSN: false,
    hospChecks: [],
    uploads: [],
    scheduled: [],
    hasFetchedScheduled: false,
    hasBatchUpdatedScheduled: false,
    hasBannedUser: false,
    pendingSalary: 0,
    preliminarySalary: 0,
    jobPostings: [],
};

const consultant = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_CONSULTANT':
            return {
                ...state,
                ssn: null,
                consultant: {},
                isFetching: true,
                uploads: [],
                scheduled: [],
                hasFetchedScheduled: false,
                hasBatchUpdatedScheduled: false,
            };

        case 'RECEIVE_CONSULTANT':
            return {
                ...state,
                consultant: action.consultant,
                isFetching: false,
                hasFetched: true,
            };

        case 'REQUEST_FETCH_CONSULTANT_SSN':
            return {
                ...state,
                isFetchingSSN: true,
            };

        case 'RECEIVE_CONSULTANT_SSN':
            return {
                ...state,
                ssn: action.ssn,
                isFetchingSSN: false,
            };

        case 'REQUEST_UPDATE_CONSULTANT':
            return {
                ...state, isUpdating: true,
            };

        case 'RECEIVE_UPDATE_CONSULTANT':
            return {
                ...state,
                isUpdating: false,
                consultant: {
                    ...state.consultant,
                    ...action.data,
                },
            };

        case 'REQUEST_SUPPLIER_BY_ID':
            return {
                ...state,
                isFetchingSupplier: true,
            };

        case 'RECEIVE_SUPPLIER_BY_ID':
            return {
                ...state,
                isFetchingSupplier: false,
                consultant: {
                    ...state.consultant,
                    supplier: {
                        ...action.data,
                    },
                },
            };

        case 'REQUEST_LOGOUT_CONSULTANT':
            return {
                ...state, logOutRequestOngoing: true,
            };

        case 'RECEIVE_LOGOUT_CONSULTANT':
            return {
                ...state, logOutRequestOngoing: false,
            };

        case 'REQUEST_FETCH_CONSULTANT_BOOKINGS':
            return {
                ...state, isFetchingApplications: true,
            };

        case 'RECEIVE_FETCH_CONSULTANT_BOOKINGS':
            return {
                ...state,
                applications: action.applications,
                isFetchingApplications: false,
            };

        case 'REQUEST_FETCH_CONSULTANT_LTV_APPLICATIONS':
            return {
                ...state, isFetchingLTVApplications: true,
            };

        case 'RECEIVE_FETCH_CONSULTANT_LTV_APPLICATIONS':
            return {
                ...state,
                ltvApplications: action.applications,
                isFetchingLTVApplications: false,
            };

        case 'RECEIVE_ADD_TAG_TO_CONSULTANT':
            return {
                ...state,
                consultant: {
                    ...state.consultant,
                    tags: [...state.consultant.tags, action.tagId],
                },
            };

        case 'RECEIVE_REMOVE_TAG_FROM_CONSULTANT':
            return {
                ...state,
                consultant: {
                    ...state.consultant,
                    tags: without(state.consultant.tags, action.tagId),
                },
            };

        case 'REQUEST_FETCH_CONSULTANT_TIME_REPORTS':
            return {
                ...state, hasFetchedTimeReports: false,
            };

        case 'RECEIVE_FETCH_CONSULTANT_TIME_REPORTS':
            return {
                ...state,
                timeReports: action.timeReports,
                hasFetchedTimeReports: true,
            };

        case 'RECEIVE_BAN_CONSULTANT':
            return {
                ...state,
                hasBannedUser: true,
            };

        case 'REQUEST_FETCH_CONSULTANT_PAYMENTS':
            return {
                ...state, hasFetchedPayments: false,
            };

        case 'RECEIVE_FETCH_CONSULTANT_PAYMENTS':
            return {
                ...state,
                payments: action.payments,
                hasFetchedPayments: true,
                pendingSalary: action.pendingSalary,
                preliminarySalary: action.preliminarySalary,
            };

        case 'REQUEST_FETCH_CONSULTANT_CONTRACTS':
            return {
                ...state, hasFetchedContracts: false,
            };

        case 'RECEIVE_FETCH_CONSULTANT_CONTRACTS':
            return {
                ...state,
                contracts: action.contracts,
                hasFetchedContracts: true,
            };

        case 'REQUEST_FETCH_CONSULTANT_ACTIVITIES':
            return {
                ...state, isFetchingActivities: true,
            };

        case 'RECEIVE_FETCH_CONSULTANT_ACTIVITIES':
            return {
                ...state,
                activities: action.activities,
                isFetchingActivities: false,
            };

        case 'REMOVE_SHIFT_FROM_CONSULTANT_APPLICATION_SUCCESS':
            return {
                ...state,
                applications: state.applications.map(a => {
                    if (a.id === action.applicationId) {
                        return {
                            ...a,
                            shifts: filter(a.shifts, s => !includes(action.shiftId, s.id)),
                        };
                    }
                    return a;
                }),
            };

        case 'RECEIVE_FETCH_CONSULTANT_EMPLOYERS':
            return {
                ...state,
                employers: action.employers,
            };

        case 'REQUEST_UPDATE_CONSULTANT_EMPLOYER':
            return {
                ...state,
                introducedLoading: true,
            };

        case 'RECEIVE_UPDATE_CONSULTANT_EMPLOYER':
            return {
                ...state,
                employers: state.employers.map(
                    item => (action.consultantEmployer.employer.id == item.employer.id ? action.consultantEmployer : item),
                ),
                introducedLoading: false,

            };

        case 'RECEIVE_FETCH_CONSULTANT_HOSP':
            return {
                ...state,
                hospChecks: action.hospChecks,
            };

        case 'RECEIVE_CHECK_CONSULTANT_HOSP':
            return {
                ...state,
                hospChecks: [...state.hospChecks, action.resp],
            };

        case 'RECEIVE_FETCH_CONSULTANT_UPLOADS':
            return {
                ...state,
                uploads: action.uploads,
            };

        case 'RECEIVE_UPDATE_CONSULTANT_UPLOAD':
            return {
                ...state,
                uploads: state.uploads.some(item => item.id === action.upload.id) ? state.uploads.map(
                    item => (action.upload.id === item.id ? action.upload : item),
                ) : [...state.uploads, action.upload],
            };
        case 'RECEIVE_REMOVE_CONSULTANT_UPLOAD':
            return {
                ...state,
                uploads: state.uploads.filter(upload => upload.id !== action.uploadId),
            };

        case 'RECEIVE_FETCH_CONSULTANT_WAGE_TAX':
            return {
                ...state,
                consultant: {
                    ...state.consultant,
                    wageTax: action.wageTax,
                },

            };

        case 'RECEIVE_CONSULTANT_SCHEDULED':
            return {
                ...state,
                scheduled: action.scheduled,
                hasFetchedScheduled: true,
            };

        case 'REQUEST_BATCH_UPDATING_SCHEDULED':
            return {
                ...state,
                hasBatchUpdatedScheduled: false,
            };

        case 'RECEIVE_BATCH_UPDATING_SCHEDULED':
            return {
                ...state,
                hasBatchUpdatedScheduled: true,
            };

        case 'REQUEST_FETCH_JOB_POSTING_BY_USER':
            return {
                ...state,
                isFetching: true,
                hasFetched: false,
            };
        case 'RECEIVE_FETCH_JOB_POSTING_BY_USER':
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                consultant: {
                    ...state.consultant,
                    jobPostings: {
                        userId: action.data.userId,
                        data: action.data.collection,
                    },
                },
            };

        default:
            return state;
    }
};
export default consultant;
