import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as employerActions from 'actions/employer';
import InputModal from 'components/inputmodal';
import config from 'config';
import './styles.scss';
import {
    Button,
    Modal,
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import EmployerContact from './EmployerContact';

class EmployerContacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddNewContactModal: false,
            formData: {},
        };
    }

    renderCreateContact() {
        const employerId = this.props.match.params.id;

        return (
            <div>
                <Button
                    className='mpa-employer-add-new'
                    icon={<UserAddOutlined />}
                    size='large'
                    style={{ lineHeight: '24px' }}
                    onClick={() => {
                        this.setState({ showAddNewContactModal: true });
                    }}
                >
                    Lägg till ny kontaktperson
                </Button>
                <Modal
                    cancelText='Avbryt'
                    okText='Spara'
                    title='Lägg till ny kontaktperson'
                    visible={this.state.showAddNewContactModal}
                    onCancel={() => {
                        this.setState({
                            showAddNewContactModal: false,
                            formData: {},
                        });
                    }}
                    onOk={() => {
                        const { formData } = this.state;

                        this.props.createEmployerContact(employerId, formData).then(() => {
                            this.setState({
                                showAddNewContactModal: false,
                                formData: {},
                            });
                        });
                    }}
                >
                    <InputModal
                        ref='addNewContactPerson'
                        hideButton
                        inputs={[
                            {
                                type: 'input',
                                name: 'name',
                                label: 'Name',
                            },
                            {
                                type: 'input',
                                name: 'title',
                                label: 'Title',
                            },
                            {
                                type: 'input',
                                name: 'phone',
                                label: 'Phone',
                            },
                            {
                                type: 'input',
                                name: 'email',
                                label: 'Email',
                                inputType: 'email',
                                validations: { matchRegexp: config.regexp.email },
                                validationErrors: { matchRegexp: 'Not a valid email' },
                            },
                            {
                                type: 'switch',
                                name: 'contact_status',
                                label: 'Active contact person',
                                optionLabels: {
                                    on: 'Yes',
                                    off: 'No',
                                },
                                value: true,

                            },
                        ]}
                        onChange={formData => this.setState({ formData })}
                    />
                </Modal>
            </div>
        );
    }

    renderContacts(employerContacts) {
        return (
            <div
                className='mpa-employer-tab-content'
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {employerContacts.map(employerContact => (
                    <EmployerContact
                        {...this.props}
                        employerContact={employerContact}
                    />
                ))}
            </div>
        );
    }

    renderNoContacts() {
        return (
            <div className=''>
                <div className='mpa-employer-tab-content'>
                    No contacts exist
                </div>
            </div>
        );
    }

    renderContent() {
        const employerContacts = this.props.employer.employer.contacts;

        return employerContacts.length ? this.renderContacts(employerContacts) : this.renderNoContacts();
    }

    render() {
        return (

            <div>
                <div className='mpa-contacts__top'>
                    {this.renderCreateContact()}
                </div>
                {this.renderContent()}
            </div>

        );
    }
}

export default withRouter(connect(
    state => state,
    dispatch => bindActionCreators({ ...employerActions }, dispatch),
)(EmployerContacts));
