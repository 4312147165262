import {
    debounce,
    find,
    groupBy,
    includes,
    isObject,
    reduce,
    uniq,
    without,
} from 'lodash';
import moment from 'moment';
import uuid from 'node-uuid';
import PropTypes from 'prop-types';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { startEndTimeToDuration } from 'utils/datetimeformatters';
import './styles.scss';

import { startEndStringTimesToMoment } from '../../utils/datetimeformatters';
import LTVInfo from './ltvinfo';
import SelectOrderId from './selectorderid';
import ShiftsList from './shiftslist';
import ShiftSummaryList from './shiftsummarylist';
import Toolbar from './toolbar';

const CreateShiftsListModal = forwardRef(({
    checkShifts,
    close,
    consultants,
    createMultipleShifts,
    createAndAddMultipleShiftsToConsultant,
    createAndAddMultipleShiftsToLtv,
    customShifts,
    deleteShifts,
    employerId,
    employerShifts,
    isDeletingShifts,
    profiles,
    isCreatingShifts,
    fetchConsultants,
    preselectedConsultant = null,
    preselectedApplicationId = null,
    preselectedProfileId = null,
    preselectedLtv,
    removeShiftsFromBookedApplicationAndDeleteShifts,
    employerConsultants = [],
    refreshCreatedShiftsList,
    test_account,
}, ref) => {
    const [addedShifts, setAddedShifts] = useState([]);
    const [shiftsInOrderId, setShiftsInOrderId] = useState([]);
    const [shiftsInLtv, setShiftsInLtv] = useState([]);
    const [orderId, setOrderId] = useState(null);
    const [newOrderId, setNewOrderId] = useState(null);
    const [consultantsSearchResults, setConsultantsSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [consultantSearchQuery, setConsultantSearchQuery] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [deleteIds, setDeleteIds] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [sendNotifications, setSendNotifications] = useState(true);
    const [orderIdError, setOrderIdError] = useState(false);
    const [shouldEmptyRawInput, setShouldEmptyRawInput] = useState(false);
    const [failedRequests, setFailedRequests] = useState({
        booked: [],
        duplicated: [],
    });

    const [totalDuration, setTotalDuration] = useState(0);

    const resetState = () => {
        setShouldEmptyRawInput(true);
        setAddedShifts([]);
        setShiftsInOrderId([]);
        setShiftsInLtv([]);
        setOrderId(null);
        setNewOrderId(null);
        setConsultantsSearchResults([]);
        setConsultantSearchQuery('');
        setIsValid(false);
        setDeleteIds([]);
        setSelectedProfileId(null);
        setSendNotifications(true);
        setOrderIdError(false);
        setShouldEmptyRawInput(false);
    };

    useImperativeHandle(ref, () => ({ resetState() {
        if (!addedShifts.length) {
            resetState();
        }
    } }));

    // We can treat this as an LTV if an application id was sent in (for now)
    const isLtv = !!preselectedApplicationId;
    let ltvStart;
    let ltvEnd;
    if (isLtv) {
        ltvStart = moment.unix(preselectedLtv.long_term_details.start_time);
        ltvEnd = moment.unix(preselectedLtv.long_term_details.end_time);
    }

    const allOrderIds = uniq(employerShifts.map(({ order_id }) => order_id)).filter(item => item);

    // Keep a reference to all shifts that should be deleted upon submit
    const toggleDeleteId = id => {
        let newDeleteIds;
        if (includes(deleteIds, id)) {
            newDeleteIds = without(deleteIds, id);
        }
        else {
            newDeleteIds = [...deleteIds, id];
        }
        setDeleteIds(newDeleteIds);
    };

    const addShifts = (shifts, markMissingForDeletion) => {
        const newAddedShifts = [
            ...addedShifts,
            ...shifts.map(shift => ({
                id: uuid.v4(),
                new: true,
                start_time: '',
                end_time: '',
                shift_break: 0,
                price: 0,
                is_price_hidden: true,
                last_application_datetime: '',
                ...shift,
                date: shift.date.startOf('day').unix(),
                profile_id: shift.profile_id || selectedProfileId || preselectedProfileId,
                outOfRange: !isLtv ? false : shift.date.startOf('day').isBefore(ltvStart.startOf('day')) || shift.date.endOf('day').isAfter(ltvEnd.endOf('day')),
            })),
        ];
        setAddedShifts(newAddedShifts);

        // If the user is importing from a list and has selected to replace
        // the existing list, we need to mark all shifts in the current order id
        // that are not present in the new list for deletion, as they have been
        // removed from the order
        // Shifts that have a booking shouldn't be marked for deletion.
        if (markMissingForDeletion) {
            const newDeleteIds = [];
            shiftsInOrderId.forEach(shift => {
                const existsInAddedShifts = find(newAddedShifts, {
                    date: shift.date,
                    start_time: shift.start_time,
                    end_time: shift.end_time,
                });
                if (!existsInAddedShifts && !shift.scheduled.length) {
                    newDeleteIds.push(shift.id);
                }
            });
            if (newDeleteIds.length) {
                setDeleteIds([
                    ...deleteIds,
                    ...newDeleteIds,
                ]);
            }
        }
    };

    const removeShift = shiftId => {
        setAddedShifts(addedShifts.filter(s => s.id !== shiftId));
    };

    const updateShift = (shiftId, key, value) => {
        setAddedShifts(addedShifts.map(shift => {
            if (shift.id === shiftId) {
                let newShift;
                // For convenience, the second argument can be an object with multiple keys
                if (isObject(key)) {
                    newShift = {
                        ...shift,
                        ...key,
                    };
                }
                else {
                    newShift = {
                        ...shift,
                        [key]: value,
                        duration: 0,
                    };
                }

                return newShift;
            }
            return shift;
        }));
    };

    const updateAllShifts = (key, value) => {
        setAddedShifts(addedShifts.map(shift => ({
            ...shift,
            [key]: value,
        })));
    };

    const updateMultipleShifts = (shifts, key, value) => {
        setAddedShifts(addedShifts.map(s => {
            if (includes(shifts, s.id)) {
                return {
                    ...s,
                    [key]: value,
                };
            }
            return s;
        }));
    };

    const shiftExistsInOrderId = useCallback(shift => find(shiftsInOrderId.filter(s => !s.scheduled.length), {
        date: shift.date,
        start_time: shift.start_time,
        end_time: shift.end_time,
    }), [shiftsInOrderId]);

    // Consultant search
    useEffect(() => {
        const doSearch = query => {
            setIsSearching(true);
            fetchConsultants({
                query, includeTestAccounts: test_account,
            });
        };

        if (consultantSearchQuery) {
            doSearch(consultantSearchQuery);
        }
    }, [consultantSearchQuery, setIsSearching, fetchConsultants]);

    useEffect(() => {
        if (isSearching) {
            setConsultantsSearchResults(consultants);
        }
    }, [setConsultantsSearchResults, consultants, isSearching]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchConsultants = useCallback(debounce(setConsultantSearchQuery, 400), []);

    // Validation
    // The entire form should be considered invalid if:
    // - One or more shifts lacks a profile id and there is no preselected profile id set
    // - One or more shifts lacks a date, price, start_time or end_time
    //      - The formatting of start/end time is currently not validated, be careful
    // - We are in LTV mode and one or more shifts are outside the LTV date range
    // - A new order id is entered that collides with an existing order id
    useEffect(() => {
        let valid = !!addedShifts.length;
        let outOfRange = false;

        // Must be valid if the only change is that we're deleting something
        if (deleteIds.length) {
            valid = true;
        }
        addedShifts.filter(s => !shiftExistsInOrderId(s)).forEach(s => {
            const shiftIsValid = ((s.profile_id || selectedProfileId || preselectedProfileId) && (s.date && s.price && s.start_time && s.end_time));
            if (!shiftIsValid) {
                valid = false;
            }
            if (s.outOfRange) {
                valid = false;
                outOfRange = true;
            }
        });
        if (outOfRange || orderIdError) {
            valid = false;
        }
        setIsValid(valid);
    }, [addedShifts, selectedProfileId, preselectedProfileId, deleteIds, shiftsInLtv, shiftsInOrderId, orderIdError, shiftExistsInOrderId]);

    // Change order id
    useEffect(() => {
        if (orderId) {
            const shifts = employerShifts.filter(s => s.order_id === orderId).map(shift => ({
                ...shift,
                date: moment.unix(shift.start_time).startOf('day').unix(),
                start_time: moment.unix(shift.start_time).format('HH:mm'),
                end_time: moment.unix(shift.end_time).format('HH:mm'),
            }));
            setShiftsInOrderId(shifts);
            if (shifts.length) {
                setSelectedProfileId(shifts[0].profile.id);
            }
        }
        else {
            setSelectedProfileId(null);
        }
    }, [orderId, employerShifts]);

    // Prevent duplicate order id
    useEffect(() => {
        if (newOrderId) {
            if (includes(allOrderIds, newOrderId)) {
                setOrderIdError(true);
            }
            else {
                setOrderIdError(false);
            }
        }
        else {
            setOrderIdError(false);
        }
    }, [newOrderId, allOrderIds]);

    // Pick out LTV shifts
    useEffect(() => {
        if (preselectedLtv) {
            const shifts = preselectedLtv.bookings[0].shifts.map(id => {
                const shift = employerShifts.find(s => s.id === id);
                return {
                    ...shift,
                    consultant: preselectedLtv.bookings[0].user,
                    date: moment.unix(shift.start_time).startOf('day').unix(),
                    start_time: moment.unix(shift.start_time).format('HH:mm'),
                    end_time: moment.unix(shift.end_time).format('HH:mm'),
                    shouldDelete: includes(deleteIds, shift.id),
                };
            });
            setShiftsInLtv(shifts);
        }
    }, [preselectedLtv, employerShifts, deleteIds]);

    // Total duration
    useEffect(() => {
        const durationInAdded = reduce(addedShifts, (val, shift) => {
            if (shift.end_time.length === 5 && shift.start_time.length === 5) {
                const start = moment(`${moment.unix(shift.date).format('YYYY-MM-DD')} ${shift.start_time}`).unix();
                const end = moment(`${moment.unix(shift.date).format('YYYY-MM-DD')} ${shift.end_time}`).unix();
                return val + startEndTimeToDuration(start, end);
            }
            return val;
        }, 0);

        const durationInLtv = reduce(shiftsInLtv, (val, shift) => {
            if (includes(deleteIds, shift.id)) {
                return val;
            }
            return val + shift.duration;
        }, 0);

        setTotalDuration(durationInAdded + durationInLtv);
    }, [addedShifts, shiftsInLtv, deleteIds]);

    const getDeletedShifts = () => {
        // Shifts that exist in the order id but not in the added shifts,
        // they should be marked for deletion
        if (orderId) {
            return shiftsInOrderId.filter(shift => includes(deleteIds, shift.id) && !shift.scheduled.length).map(s => ({
                ...s,
                shouldDelete: true,
                profile_id: s.profile.id,
            }));
        }
        return [];
    };

    const getUnchangedShifts = () => {
        // Shifts that exist in both the order id and in the added shifts,
        // they should be left untouched
        let shifts = [];
        if (orderId) {
            shifts = shiftsInOrderId.filter(shift => !includes(deleteIds, shift.id));
        }
        return shifts.map(s => ({
            ...s,
            profile_id: s.profile.id,
            hasBooking: s.scheduled.length,
            consultant: s.scheduled.length ? find(employerConsultants, { id: s.scheduled[0].user.id }) : null,
        }));
    };

    const getShiftsInLtv = () => shiftsInLtv.map(s => ({
        ...s,
        profile_id: s.profile.id,
        shouldDelete: includes(deleteIds, s.id),
    }));

    const getNewShifts = () => {
        // Shifts that only exist in the added shifts, they should be marked as new
        if (orderId) {
            return addedShifts.filter(shift => !shiftExistsInOrderId(shift));
        }
        return addedShifts;
    };

    const getDeletedBookings = () => shiftsInLtv.filter(s => s.shouldDelete).map(s => s.id);

    const getNewShiftsFormatted = () => getNewShifts().map(s => {
        const {
            id,
            date,
            start_time,
            end_time,
            shift_break,
            price,
            profile_id,
            margin,
            consultant_fee_margin,
            consultant,
            last_application_datetime,
            is_price_hidden,
        } = s;

        const {
            startTime,
            endTime,
        } = startEndStringTimesToMoment(moment.unix(date), start_time, end_time);
        const duration = startEndTimeToDuration(startTime.unix(), endTime.unix());

        return {
            start_time: startTime.unix(),
            end_time: endTime.unix(),
            duration,
            price,
            shift_break: parseFloat(shift_break),
            margin,
            consultant_fee_margin,
            profile_id,
            consultant: consultant ? consultant.id : null,
            status: 1,
            uuid: id,
            last_application_datetime,
            is_price_hidden,
        };
    });

    const getUpdateLtvShiftsPromises = newShifts => {
        const promises = [];
        const deletedBookings = getDeletedBookings();
        if (deletedBookings.length) {
            promises.push(removeShiftsFromBookedApplicationAndDeleteShifts(
                preselectedApplicationId,
                deletedBookings,
                employerId,
            ));
        }

        if (newShifts.length) {
            promises.push(createAndAddMultipleShiftsToLtv(
                employerId,
                newShifts,
                preselectedApplicationId,
            ));
        }
        return promises;
    };

    const getUpdateRegularShiftsPromises = grouped => {
        const promises = [];
        Object.keys(grouped).forEach(key => {
            if (key === 'none') {
                promises.push(createMultipleShifts(
                    employerId,
                    grouped[key],
                    sendNotifications,
                    orderId || newOrderId,
                ));
            }
            else {
                promises.push(createAndAddMultipleShiftsToConsultant(
                    employerId,
                    grouped[key],
                    key,
                    orderId || newOrderId,
                ));
            }
        });
        if (deleteIds.length) {
            promises.push(deleteShifts(employerId, deleteIds));
        }
        return promises;
    };

    const getUpdateShiftPromises = (newShifts, grouped) => {
        if (isLtv) {
            return getUpdateLtvShiftsPromises(newShifts);
        }
        return getUpdateRegularShiftsPromises(grouped);
    };

    const onSubmit = async () => {
        const newShifts = getNewShiftsFormatted();

        const grouped = groupBy(newShifts, o => (o.consultant ? o.consultant : 'none'));

        // Check for conflicts on booked shifts
        const checkPromises = [];
        Object.keys(grouped).filter(k => k !== 'none').forEach(key => {
            const promise = checkShifts(employerId, Number(key), grouped[key]);
            checkPromises.push(promise);
        });

        Promise.allSettled(checkPromises).then(results => {
            const bookedShifts = [];
            const duplicatedShifts = [];
            const rejectedChecks = results.reduce((rejected, current) => {
                if (current.status === 'rejected') {
                    const rejectedResults = current.reason.responseJSON.collisions;
                    rejected.push(rejectedResults);
                    const {
                        booked,
                        new: duplicated,
                    } = rejectedResults;
                    if (duplicated) {
                        const duplicatedValues = Object.values(duplicated).flat();
                        duplicatedShifts.push(...duplicatedValues);
                    }
                    if (booked) {
                        const bookedKeys = Object.keys(booked);
                        bookedShifts.push(...bookedKeys);
                    }
                }
                return rejected;
            }, []);
            if (rejectedChecks.length) {
                setFailedRequests({
                    booked: bookedShifts,
                    duplicated: duplicatedShifts,
                });
                // At least one check was rejected, do not create anything
            }
            else {
                Promise.all(getUpdateShiftPromises(newShifts, grouped)).then(() => {
                    close();
                    resetState();
                    refreshCreatedShiftsList();
                });
            }
        });
    };

    // Roughly calculates the degree to which the LTV employment scope has been filled
    // Using 160 hours as a basis for full time ( = 5.3 hours per day accounting for weekends )
    // I.e if the ltv scope is at 50%, the value returned will be the total number of hours in the
    // ltv (booked and added) as a percentage of 80
    const getLtvCapacity = () => {
        const daysInLtv = ltvEnd.diff(ltvStart, 'days');
        const ltvAllowance = (daysInLtv * 5.3) * (preselectedLtv.long_term_details.employment_scope / 100);
        const hoursInLtv = totalDuration;

        return {
            allowance: Math.floor(ltvAllowance),
            used: hoursInLtv,
            percent: Math.floor(hoursInLtv / ltvAllowance * 100),
        };
    };

    // In The case if ltvs, we can send some options to the toolbar datepicker to prevent duplicate
    // or out of range dates being added. This is based on the 'disabledDate' and 'validRange' options
    // in the antd datepicker
    let disabledDate;
    let validDateRange;
    if (isLtv) {
        disabledDate = current => find(shiftsInLtv, s => moment.unix(s.date).isSame(current, 'day'));
        // Set end time of valid daterange to start of next day, prevents bug where last day in ltv is not selectable after selecting one date.
        validDateRange = [ltvStart, ltvEnd.add(1, 'day').startOf('day')];
    }

    return (
        <div
            style={{ padding: 20 }}
        >
            {!isLtv
                && (
                    <SelectOrderId
                        orderIds={allOrderIds}
                        selectedOrderId={orderId}
                        setOrderId={setOrderId}
                    />
                )}
            {isLtv && (
                <LTVInfo
                    capacity={getLtvCapacity()}
                    ltv={preselectedLtv}
                />
            )}
            <ShiftSummaryList
                shifts={getNewShifts()}
                updateMultipleShifts={updateMultipleShifts}
            />
            <ShiftsList
                consultants={consultantsSearchResults}
                customShifts={customShifts}
                deleteIds={deleteIds}
                preselectedConsultant={preselectedConsultant}
                preselectedProfileId={selectedProfileId || preselectedProfileId}
                profiles={profiles}
                rejectedShifts={failedRequests}
                removeShift={removeShift}
                searchConsultants={searchConsultants}
                shifts={[
                    ...getNewShifts(),
                    ...getUnchangedShifts(),
                    ...getDeletedShifts(),
                    ...getShiftsInLtv(),
                ]}
                toggleDeleteId={toggleDeleteId}
                updateAllShifts={updateAllShifts}
                updateShift={updateShift}
            />
            <Toolbar
                addShifts={addShifts}
                customShifts={customShifts}
                disabledDate={disabledDate}
                isLtv={isLtv}
                isValid={isValid}
                loading={isCreatingShifts || isDeletingShifts}
                newOrderId={newOrderId}
                orderIdError={orderIdError}
                selectedOrderId={orderId}
                sendNotifications={sendNotifications}
                setNewOrderId={setNewOrderId}
                setSendNotifications={setSendNotifications}
                shouldEmptyRawInput={shouldEmptyRawInput}
                validDateRange={validDateRange}
                onSubmit={onSubmit}
            />
        </div>
    );
});

CreateShiftsListModal.defaultProps = {
    test_account: false,
    preselectedLtv: undefined,
};

CreateShiftsListModal.propTypes = {
    close: PropTypes.func.isRequired,
    consultants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    checkShifts: PropTypes.func.isRequired,
    createMultipleShifts: PropTypes.func.isRequired,
    customShifts: PropTypes.arrayOf(PropTypes.shape({})),
    deleteShifts: PropTypes.func.isRequired,
    employerId: PropTypes.string.isRequired,
    employerShifts: PropTypes.arrayOf(PropTypes.shape({})),
    isDeletingShifts: PropTypes.bool.isRequired,
    test_account: PropTypes.bool,
    profiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })),
    preselectedLtv: PropTypes.shape({
        bookings: PropTypes.arrayOf(PropTypes.shape({
            shifts: PropTypes.arrayOf(PropTypes.shape({})),
            user: PropTypes.shape({}),
        })),
        long_term_details: PropTypes.shape({
            start_time: PropTypes.number,
            end_time: PropTypes.number,
            employment_scope: PropTypes.number,
        }),
    }),
    isCreatingShifts: PropTypes.bool.isRequired,
    createAndAddMultipleShiftsToConsultant: PropTypes.func.isRequired,
    createAndAddMultipleShiftsToLtv: PropTypes.func.isRequired,
    fetchConsultants: PropTypes.func.isRequired,
    removingShiftFromApplication: PropTypes.bool.isRequired,
    preselectedProfileId: PropTypes.number,
    preselectedConsultant: PropTypes.number,
    preselectedApplicationId: PropTypes.number,
    removeShiftsFromBookedApplicationAndDeleteShifts: PropTypes.func.isRequired,
    employerConsultants: PropTypes.arrayOf(PropTypes.object),
    refreshCreatedShiftsList: PropTypes.func.isRequired,
};

export default CreateShiftsListModal;
