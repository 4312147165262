import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const LabelledSwitch = ({
    isChecked,
    onChange,
    className,
    children,
    hidden,
}) => (
    <label className={className} hidden={hidden}>
        <Switch
            checked={isChecked}
            onChange={onChange}
        />
        {children}
    </label>
);

LabelledSwitch.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
};
LabelledSwitch.defaultProps = {
    hidden: false,
};

export default LabelledSwitch;
