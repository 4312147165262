import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { isEqual } from 'lodash';
import { TrophyOutlined } from '@ant-design/icons';
import LoadingScreen from '../../../components/shared/loadingscreen';
import CopyEmployer from './CopyEmployer';
import CreateEmployer from './CreateEmployer';
import * as employerActions from '../../../actions/employer';
import * as createEmployerActions from '../../../actions/createemployer';
import { createJobProfile } from '../../../actions/job';
import { getUser } from '../../../actions/session';
import HandleProfile from './HandleProfile';
import Documents from './Documents';
import { updateProcurement } from '../../../actions/procurements';

const HandleProcurementModal = ({
    employer,
    cancelText,
    visible,
    width,
    onCancel,
    procurement,
    step,
    setStep,
}) => {
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.session);
    const procurementEmployer = useSelector(state => state.employer);
    const settings = useSelector(state => state.settings);

    const [title, setTitle] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingText, setIsFetchingText] = useState("Le freak, c'est chic!");
    const [copiedEmployerData, setCopiedEmployerData] = useState({
        shouldCopyContacts: false,
        shouldCopyProfiles: false,
        shouldCopyCover: false,
        shouldCopyLogo: false,
        shouldCopyCustomShifts: false,
    });
    const [newProfile, setNewProfile] = useState({
        id: -1,
        additional_info: '',
        description: '',
        driver_license: false,
        name: '',
        occupation_id: 1,
        specialization_ids: [],
        price: procurement?.price,
    });
    const [profilesAreEqual, setProfilesAreEqual] = useState(false);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        if (procurement) {
            setNewProfile({
                id: -1,
                additional_info: '',
                description: procurement.description,
                driver_license: procurement.driver_license,
                name: procurement.simple_title,
                occupation_id: procurement.occupation_id,
                specialization_ids: procurement.specialization_ids,
                is_long_term: true,
                employment_scope: procurement.employment_scope,
                end_time: procurement.end_time,
                hourly_rate: procurement.price,
                start_time: procurement.start_time,
            });
        }
    }, [procurement, visible]);

    const areProfilesEquals = useCallback(() => {
        const selectedEmployerProfile = procurementEmployer.profiles.profiles.find(p => p.id === newProfile.id);

        const {
            id,
            additional_info,
            description,
            qualifications,
            driver_license,
            name,
            occupation_id,
            specialization_ids,
            skills,
        } = selectedEmployerProfile;

        const employerProfile = {
            id,
            additional_info,
            description,
            qualifications,
            driver_license,
            name,
            occupation_id,
            specialization_ids,
            skills,
        };

        const theNewProfile = {
            ...newProfile,
        };

        delete theNewProfile.is_long_term;

        return isEqual(employerProfile, theNewProfile);
    }, [newProfile, procurementEmployer.profiles.profiles]);

    useEffect(() => {
        if (newProfile?.id > -1 && !newProfile.is_long_term && procurementEmployer) {
            if (areProfilesEquals()) {
                setProfilesAreEqual(true);
                // setOkButtonText('Use profile');
            }
            else {
                setProfilesAreEqual(false);
                // setOkButtonText('Save');
            }
        }
        else {
            setProfilesAreEqual(false);
            // setOkButtonText('Save');
        }
    }, [areProfilesEquals, newProfile, procurementEmployer]);

    const generateRandomMedpeopleEmail = (length, email) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        const splitedEmail = email.split('@');
        return {
            email: `${splitedEmail[0]}+${result}@${splitedEmail[1]}`,
            randomString: result,
        };
    };

    const randomPassword = length => {
        // generate string password including numbers, letters and special characters
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const onCloseModal = () => {
        onCancel(false);
        setStep('checkEmployer');
        // setOkButtonText('Copy');
        setNewProfile({
            id: -1,
            additional_info: '',
            description: '',
            driver_license: false,
            name: '',
            occupation_id: 1,
            specialization_ids: [],
        });
    };

    const fetchCurrentEmployer = async (id, onDone) => {
        setIsFetching(true);
        dispatch(employerActions.fetchEmployerProfiles(id));
        dispatch(employerActions.fetchEmployerUsers(id));
        dispatch(employerActions.fetchEmployerJobads(id));
        dispatch(employerActions.fetchEmployer(id)).then(() => {
            onDone();
        });
    };

    const getImageDataURL = id => {
        const canvas = document.createElement('canvas');
        const img = document.getElementById(id);
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const base64String = canvas.toDataURL();
        return base64String;
    };

    const onCopyEmployer = () => {
        const password = randomPassword(8);
        const email = currentUser.email ? generateRandomMedpeopleEmail(5, currentUser.email) : '';

        dispatch(createEmployerActions.setCreateEmployerFormData({
            ...copiedEmployerData,
            email: email.email,
            name: currentUser.username,
            username: currentUser.username + email.randomString,
            password,
            password_retype: password,
            contact_email: email.email,
            contact_name: currentUser.username,
            account_email: email.email,
            account_name: currentUser.username,
            account_title: 'Autogenerated account',
            account_phone: '',
        }));
        setStep('createEmployer');
    };

    const onSubmitNewEmployer = async data => {
        setIsFetching(true);

        const copyData = {};

        const {
            shouldCopyContacts = false,
            shouldCopyProfiles = false,
            shouldCopyCover = false,
            shouldCopyLogo = false,
            shouldCopyCustomShifts = false,
            contacts,
            logoUrl,
            coverUrl,
            customShifts,
            profiles,
        } = copiedEmployerData;
        setIsFetchingText('');
        setIsFetching(true);

        if (shouldCopyContacts && contacts && contacts.length) {
            copyData.contacts = contacts;
        }

        if (shouldCopyLogo && logoUrl) {
            copyData.logo = getImageDataURL('logoSource');
        }

        if (shouldCopyCover && coverUrl) {
            copyData.cover = getImageDataURL('coverSource');
        }

        if (shouldCopyCustomShifts) {
            copyData.customShifts = customShifts;
        }

        if (shouldCopyProfiles) {
            copyData.profiles = profiles;
        }

        const newEmployer = await dispatch(createEmployerActions.requestCreateEmployer({
            ...data,
            create_shifts: !shouldCopyCustomShifts,
            create_profiles: !shouldCopyProfiles,
            automotive: data?.automotive === 'automotive',
        }, copyData, false));

        try {
            await dispatch(employerActions.updateEmployerStatus(newEmployer.id, 1));
            await dispatch(updateProcurement(procurement.id, { employer_id: newEmployer.id }));

            const onDone = () => {
                setIsFetching(false);
                setStep('matchProfile');
            };

            fetchCurrentEmployer(newEmployer.id, onDone);
        }
        catch (e) {
            setIsFetching(false);
        }
    };

    const createNewProfile = async formData => {
        const newP = await dispatch(createJobProfile(procurementEmployer.employer.id, formData));
        dispatch(updateProcurement(procurement.id, { job_id: newP.job.id }));
        setStep('done');
    };

    const onSumbitProfile = () => {
        const formData = {
            ...newProfile,
        };

        if (newProfile.is_long_term) {
            // create new profile

            const {
                employment_scope,
                price,
                margin,
                period,
                last_application_time,
            } = newProfile;
            const long_term_details = {
                employment_scope,
                price,
                margin,
                start_time: period[0].unix(),
                end_time: period[1].unix(),
                last_application_time: last_application_time.unix(),
            };

            delete formData.employment_scope;
            delete formData.period;
            delete formData.price;
            delete formData.margin;
            delete formData.salary;
            delete formData.last_application_time;

            formData.long_term_details = long_term_details;
            delete formData.id;

            createNewProfile(formData);
        }
        else if (newProfile.id !== -1) {
            if (areProfilesEquals()) {
                dispatch(updateProcurement(procurement.id, { job_id: newProfile.id }));
                setStep('done');
            }
            else {
                delete formData.id;
                delete formData.is_long_term;
                createNewProfile(formData);
            }
        }
        else {
            delete formData.id;
            delete formData.is_long_term;
            createNewProfile(formData);
        }
    };

    useEffect(() => {
        if (visible) {
            const password = randomPassword(8);
            const email = currentUser.email ? generateRandomMedpeopleEmail(5, currentUser.email) : '';
            if (step === 'checkEmployer') {
                setIsFetching(true);
                if (employer) {
                    fetchCurrentEmployer(employer.value, async () => {
                        if (employer.copy) {
                            setStep('copyEmployer');
                            setIsFetching(false);
                        }
                        else {
                            if (employer.id) {
                                await dispatch(updateProcurement(procurement.id, { employer_id: employer.value }));
                            }
                            setStep('matchProfile');
                            setIsFetching(false);
                        }
                    });
                }
                else {
                    setStep('createEmployer');
                    setIsFetching(false);
                }
            }
            else if (step === 'createEmployer') {
                dispatch(createEmployerActions.setCreateEmployerFormData({
                    email: email.email,
                    name: '',
                    username: currentUser.username + email.randomString,
                    password,
                    password_retype: password,
                    account_email: procurement.contact_person_email,
                    account_name: procurement.contact_person_name,
                    contact_email: email.email,
                    contact_name: currentUser.username,
                    account_title: 'Autogenerated account',
                    account_phone: '',
                    isCopying: false,
                    invoice_address: {
                        reference: procurement.contact_person_name,
                        city: procurement.municipality,
                    },
                    address: {
                        city: procurement.municipality,
                    },
                }));
                setTitle('Create new employer');
                setIsFetchingText('');
            }
            else if (step === 'copyEmployer') {
                setTitle(`Copy ${procurementEmployer.employer.name}`);
            }
            else if (step === 'matchProfile') {
                setTitle('Matching profile');
            }
        }
        else {
            setStep('checkEmployer');
        }
        // avoid inifite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, visible]);

    const child = () => {
        if (step === 'createEmployer') {
            return (
                <CreateEmployer
                    requestOngoing={isFetching}
                    setIsModalVisible={onCloseModal}
                    setRequestOngoing={setIsFetching}
                    validateUsername={username => dispatch(createEmployerActions.validateUsername(username))}
                    onSubmit={onSubmitNewEmployer}
                />
            );
        }

        if (step === 'copyEmployer') {
            return (
                <CopyEmployer
                    employer={procurementEmployer.employer}
                    procurement={procurement}
                    setCopiedEmployerData={setCopiedEmployerData}
                    setIsModalVisible={onCloseModal}
                    onSubmit={onCopyEmployer}
                />
            );
        }

        if (step === 'matchProfile') {
            return (
                <HandleProfile
                    employer={procurementEmployer.employer}
                    procurement={procurement}
                    profile={newProfile}
                    profilesAreEqual={profilesAreEqual}
                    setIsModalVisible={onCloseModal}
                    setProfile={setNewProfile}
                    settings={settings}
                    onSubmit={onSumbitProfile}
                />
            );
        }

        if (step === 'done') {
            return (
                <div
                    style={{
                        width: '100%',
                        height: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 100,
                        flexDirection: 'column',
                        color: 'gold',
                    }}
                >
                    <TrophyOutlined
                        style={{
                            marginBottom: 20,
                        }}
                    />
                    <h4 style={{ color: 'gold' }}>You are awesome!</h4>
                </div>
            );
        }
        return null;
    };

    return (
        <Modal
            cancelText={cancelText}
            footer={null}
            title={title}
            visible={visible}
            width={width}
            onCancel={onCloseModal}
        >
            {(isFetching && isFetchingText)
                ? (
                    <LoadingScreen
                        style={{ height: 500 }}
                        text={isFetchingText}
                    />
                )
                : (
                    <>
                        {procurement?.documents.length && step !== 'done' ? (
                            <Documents
                                documents={procurement.documents}
                            />
                        ) : null}
                        <hr />
                        {child()}
                    </>
                )}
        </Modal>
    );
};

HandleProcurementModal.propTypes = {
    employer: PropTypes.shape({
        id: PropTypes.number,
        copy: PropTypes.bool,
        value: PropTypes.number.isRequired,
    }),
    cancelText: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    setStep: PropTypes.func.isRequired,
    procurement: PropTypes.shape({
        id: PropTypes.number.isRequired,
        occupation_id: PropTypes.number.isRequired,
        specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
        description: PropTypes.string.isRequired,
        municipality: PropTypes.string.isRequired,
        county_id: PropTypes.number.isRequired,
        contact_person_name: PropTypes.string.isRequired,
        contact_person_email: PropTypes.string.isRequired,
        driver_license: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        specialization_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
        simple_title: PropTypes.string.isRequired,
        documents: PropTypes.arrayOf(PropTypes.shape({})),
        employment_scope: PropTypes.number,
        start_time: PropTypes.number,
        end_time: PropTypes.number,
        hourly_rate: PropTypes.number,
        price: PropTypes.number,
    }),
    setIsModalVisible: PropTypes.func.isRequired,
};

export default HandleProcurementModal;
