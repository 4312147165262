import React from 'react';
import moment from 'moment';
import { includes } from 'lodash';
import { Table } from 'antd';

import ConsultantChart from './ConsultantChart';

export default class RetentionGraph extends React.Component {
    constructor(props) {
        super(props);
    }

    getData() {
        const { consultants } = this.props;
        let months = [];
        const startMonth = moment('2017-03-01');
        const diff = moment().diff(startMonth, 'months');

        for (let i = 0; i <= diff; ++i) {
            const m = startMonth;
            months.push({
                month: m.format('YYYY-MM'),
                newConsultants: 0,
                totalConsultants: 0,
                activeConsultants: 0,
                totalActiveConsultants: [],
            });
            /* months[m.format('YYYY-MM')] = {
                newConsultants: 0,
                totalConsultants: 0,
            }; */
            startMonth.add(1, 'month');
        }

        consultants.forEach(c => {
            const reg = moment.unix(c.created).startOf('month').format('YYYY-MM');

            months = months.map(m => {
                let wasActive = false;
                const wasActiveOnOrBefore = false;
                const shifts = this.props.shiftsByConsultantId[c.id];
                if (shifts) {
                    shifts.forEach(s => {
                        if (moment.unix(s.start_time).format('YYYY-MM') === m.month) {
                            wasActive = true;
                        }

                        if (moment.unix(s.start_time).format('YYYY-MM') <= m.month) {
                            if (!includes(m.totalActiveConsultants, c.id)) {
                                m.totalActiveConsultants.push(c.id);
                            }
                        }
                    });
                }

                return {
                    ...m,
                    newConsultants: reg === m.month ? m.newConsultants + 1 : m.newConsultants,
                    totalConsultants: reg <= m.month ? m.totalConsultants + 1 : m.totalConsultants,
                    activeConsultants: wasActive ? m.activeConsultants + 1 : m.activeConsultants,
                };
            });
            // const reg = moment.unix(c.created).startOf('month').format('YYYY-MM');
            // months[reg].newConsultants += 1;
        });

        return months;
    }

    render() {
        const data = this.getData();

        const columns = [{
            dataIndex: 'month',
            key: 'month',
            title: 'Månad',
        }, {
            dataIndex: 'newConsultants',
            key: 'newConsultants',
            title: 'Nya konsulter',
        }, {
            dataIndex: 'totalConsultants',
            key: 'totalConsultants',
            title: 'Totalt antal konsulter',
        }, /* {
            dataIndex: 'activeConsultants',
            key: 'activeConsultants',
            title: 'Aktiva konsulter',
        }, */ {
            dataIndex: 'activityPercentage',
            key: 'activityPercentage',
            title: 'Aktiveringsgrad',
            render: (text, record) => `${((record.activeConsultants / (record.totalConsultants * 0.8)) * 100).toFixed(1)}%`,
        }, {
            dataIndex: 'totalActivityPercentage',
            key: 'totalActivityPercentage',
            title: 'Total aktiveringsgrad',
            render: (text, record) => `${((record.totalActiveConsultants.length / (record.totalConsultants * 0.8)) * 100).toFixed(1)}%`,
        }];

        return (
            <div
                style={{ marginTop: 40 }}
            >
                <div
                    style={{ backgroundColor: '#FFF' }}
                >
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
                <div
                    style={{
                        marginTop: 40,
                        paddingTop: 20,
                        paddingBottom: 20,
                        backgroundColor: '#FFF',
                    }}
                >
                    <div
                        style={{ margin: 40 }}
                    >
                        <ConsultantChart
                            data={data}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
