import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    groupBy,
    map,
} from 'lodash';
import {
    Input,
    InputNumber,
} from 'antd';
import { isHoliday } from 'utils/holidays';

const MassUpdateWeekday = ({
    title,
    weekdayShifts,
    updateMultipleShifts,
}) => {
    const [shiftBreak, setShiftBreak] = useState(weekdayShifts[0].shift_break || 0);
    const [price, setPrice] = useState(weekdayShifts[0].price || 0);

    return (
        <div>
            <InputNumber
                addonAfter='SEK/h'
                addonBefore={`${title} (${weekdayShifts.length})`}
                min={0}
                size='large'
                style={{
                    width: 300,
                    marginRight: 20,
                    marginBottom: 20,
                }}
                value={price}
                onBlur={e => {
                    updateMultipleShifts(weekdayShifts.map(s => s.id), 'price', price);
                }}
                onChange={e => {
                    setPrice(isNaN(e) || e === null ? 0 : e);
                }}

            />
            <InputNumber
                addonAfter='hours'
                addonBefore='Break'
                min={0}
                size='large'
                style={{
                    width: 300,
                    marginRight: 20,
                    marginBottom: 20,
                }}
                value={shiftBreak}
                onBlur={e => {
                    updateMultipleShifts(weekdayShifts.map(s => s.id), 'shift_break', shiftBreak);
                }}
                onChange={e => {
                    setShiftBreak(isNaN(e) || e === null ? 0 : e);
                }}
            />
        </div>
    );
};

const ShiftSummaryList = ({
    shifts,
    updateMultipleShifts,
}) => {
    const groupedByTime = groupBy(shifts, s => `${s.start_time} - ${s.end_time}`);

    return (
        <div>
            {map(groupedByTime, (timeShifts, time) => {
                const groupedByWeekend = groupBy(timeShifts, s => {
                    const date = moment.unix(s.date);
                    const holiday = isHoliday(date.format('YYYY-MM-DD'));
                    if (holiday) {
                        return holiday;
                    }

                    return date.isoWeekday() >= 6 ? '2weekend' : '1weekday';
                });

                return (
                    <div
                        key={timeShifts.id}
                        style={{ marginBottom: 40 }}
                    >
                        <h5
                            style={{ marginBottom: 20 }}
                        >
                            {time}
                        </h5>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {Object.keys(groupedByWeekend).sort().map(weekDayType => {
                                const weekdayShifts = groupedByWeekend[weekDayType];
                                let title = '';
                                switch (weekDayType) {
                                    case '1weekday':
                                        title = 'weekday';
                                        break;
                                    case '2weekend':
                                        title = 'holiday';
                                        break;
                                    default:
                                        title = weekDayType;
                                }

                                return (
                                    <MassUpdateWeekday
                                        title={title}
                                        updateMultipleShifts={updateMultipleShifts}
                                        weekdayShifts={weekdayShifts}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

ShiftSummaryList.propTypes = {
    shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateMultipleShifts: PropTypes.func.isRequired,
};

export default ShiftSummaryList;
