const initialData = {
    collection: [],
    count: 0,
    total_count: 0
}

const initialState = { data: initialData, isFetching: true, hasFetched: false };

export function jobPostingTracking(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_JOB_POSTING_TRACKING':
            return {
                ...state,
                isFetching: true,
                hasFetched: false,
                data: initialData,
            };
        case 'RECEIVE_FETCH_JOB_POSTING_TRACKING':
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                data: action.data
            };

        default:
            return state;
    }
}
