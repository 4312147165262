import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';

const StudentFilter = ({
    students,
    onChange,
    value,
}) => (
    <section className='student-filter'>
        <h4 className='heading'>Student:</h4>
        {students.map((student) => (
            <label
                key={student.name}
                htmlFor={student.name}
            >
                <input
                    checked={student.id === value}
                    className='input'
                    defaultChecked={student.id === config.students.STUDENT_ALL}
                    id={student.name}
                    name='student'
                    type='radio'
                    onChange={() => onChange(student.id)}
                />
                {student.name}
            </label>
        ))}
    </section>
);

StudentFilter.propTypes = {
    students: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        name: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    onChange: PropTypes.func.isRequired,
};

export default StudentFilter;
