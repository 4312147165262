import * as request from 'request';

export const receiveScheduledList = resp => ({
    type: 'RECEIVE_SCHEDULED_LIST',
    scheduled: resp.collection,
});

export const requestScheduledList = () => ({ type: 'REQUEST_SCHEDULED_LIST' });

export const fetchScheduledList = (startTime, endTime) => dispatch => {
    dispatch(requestScheduledList);
    request.fetchScheduled(startTime, endTime).then(resp => dispatch(receiveScheduledList(resp)));
};
