import React from 'react';
import './styles.scss';

export class TableRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div />
        );
    }
}

export default class Table extends React.Component {
    constructor(props) {
        super(props);
    }

    renderTitle() {
        if (!this.props.title) {
            return;
        }

        return (

            <div className='mp-table__title'>
                {this.props.title}
            </div>

        );
    }

    renderTableHead() {
        if (!this.props.headers) {
            return;
        }

        return (

            <thead>
                <tr>
                    {this.props.headers.map(header => (
                        <th>
                            {header}
                        </th>

                    ))}
                </tr>
            </thead>

        );
    }

    render() {
        return (
            <div className='mp-table' style={this.props.style || {}}>
                {this.renderTitle()}
                <table className='mp-table__table'>
                    {this.renderTableHead()}
                    <tbody>
                        {this.props.rows.map(this.props.renderRow)}
                    </tbody>
                </table>

            </div>
        );
    }
}
