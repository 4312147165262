import React, {
    useRef,
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { sortBy } from 'lodash';
import Textarea from 'components/shared/textarea';
import Input from 'components/shared/input';
import Button from 'components/shared/button';
import Switch from 'components/shared/switch';
import Select from 'components/shared/select';
import MultiSelect from 'components/shared/multiselect';
import DateRangePicker from 'components/shared/daterangepicker';
import DatePicker from 'components/shared/datepicker';
import HiddenFormInput from '../shared/hiddenforminput';
import './styles.scss';

const InputModal = ({
    isDisabled = false,
    hideButton = false,
    visible = false,
    data = {},
    inputs,
    onChange = () => {},
    onSave,
    close,
    validationErrors,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const form = useRef(null);
    useEffect(() => { if (visible === true) form.current.reset(data); }, [visible, form, data]);

    const renderInputs = () => inputs.map(input => {
        switch (input.type) {
            case 'input':
                return (

                    <Input
                        key={input.name}
                        block
                        disabled={input.isDisabled}
                        errorMessageStyle='error'
                        label={input.label}
                        name={input.name}
                        required={input.isRequired}
                        type={input.inputType || 'text'}
                        value={input.value}
                        // validationErrors={input.validationErrors || {}}
                        // validations={input.validations || {}}
                        onChange={input.onChange}
                    />
                );

            case 'textarea':
                return (
                    <Textarea
                        key={input.name}
                        block
                        label={input.label}
                        name={input.name}
                        required={input.isRequired}
                        value={input.value}
                    />
                );

            case 'switch':
                return (
                    <div key={input.name}>
                        <label className='mp-input__label' htmlFor={input.name}>{input.label}</label>
                        {input.optionLabels.off}
                        <Switch
                            id={input.name}
                            name={input.name}
                            required={input.isRequired}
                            value={input.value}
                        />
                        {input.optionLabels.on}
                    </div>
                );

            case 'select':
                return (
                    <div key={input.name}>
                        <label className='mp-input__label' htmlFor={input.name}>{input.label}</label>
                        <Select
                            emptyValue='Select value'
                            id={input.name}
                            name={input.name}
                            options={sortBy(input.options, ['title']).map(option => ({
                                value: option.value,
                                title: option.title,
                            }))}
                            required={input.isRequired}
                            value={input.value}
                        />
                    </div>
                );

            case 'multiselect':
                return (
                    <div key={input.name}>
                        <label className='mp-input__label' htmlFor={input.name}>{input.label}</label>
                        <MultiSelect
                            emptyValue='Select value'
                            id={input.name}
                            name={input.name}
                            options={typeof input.options === 'function' ? input.options(form.current ? form.current.getModel() : {}) : input.options}
                            required={input.isRequired}
                            value={input.value}
                        />
                    </div>
                );

            case 'dateRange':
                return (
                    <div key={input.name}>
                        <label className='mp-input__label' htmlFor={input.name}>{input.label}</label>
                        <DateRangePicker
                            disabledDate={input.disabledDate}
                            id={input.name}
                            name={input.name}
                            required={input.isRequired}
                            value={input.value}
                        />
                    </div>
                );

            case 'date':
                return (
                    <div key={input.name}>
                        <label className='mp-input__label' htmlFor={input.name}>{input.label}</label>
                        <DatePicker
                            id={input.name}
                            name={input.name}
                            required={input.isRequired}
                            value={input.value}
                        />
                    </div>
                );

            case 'custom':
                return input.render();

            case 'hidden':
                return (
                    <HiddenFormInput name={input.name} value={input.value} />
                );

            default:
                return null;
        }
    });

    return (
        <Formsy
            ref={form}
            className='input-modal'
            validationErrors={validationErrors}
            onChange={(currentValues, isChanged) => onChange(currentValues, isChanged, form.current)}
            onInvalid={() => setIsValid(false)}
            onValid={() => setIsValid(true)}
            onValidSubmit={() => {
                setIsSaving(true);
                onSave(form.current.getModel())
                    .then(() => {
                        setIsSaving(false);
                        if (Object.keys(data).length === 0) form.current.reset();
                        close();
                    });
            }}
        >

            {renderInputs()}

            {!hideButton && (
                <Button
                    className='button'
                    disabled={isDisabled || !isValid || isSaving}
                    loading={isSaving}
                    type='submit'
                >
                    Save
                </Button>
            )}
        </Formsy>
    );
};

InputModal.propTypes = {
    isDisabled: PropTypes.bool,
    hideButton: PropTypes.bool,
    visible: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
    inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default InputModal;
