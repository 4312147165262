import { message } from 'antd';
import * as request from 'request';

const requestOrders = () => ({ type: 'REQUEST_FETCH_ORDERS' });
const requestOrder = () => ({ type: 'REQUEST_FETCH_ORDER' });
const requestUpdateShifts = () => ({ type: 'REQUEST_UPDATE_SHIFTS' });
const requestUpdateShift = () => ({ type: 'REQUEST_UPDATE_SHIFT' });
const requestUpdateOrder = () => ({ type: 'REQUEST_UPDATE_ORDER' });
const requestDeleteOrder = () => ({ type: 'REQUEST_DELETE_ORDER' });
const receiveUpdateOrder = resp => ({
    type: 'RECEIVE_UPDATE_ORDER',
    order: resp,
});
const receiveUpdateOrderShifts = resp => ({
    type: 'RECEIVE_UPDATE_ORDER_SHIFTS',
    shifts: resp,
});

const receiveUpdateOrderShift = resp => ({
    type: 'RECEIVE_UPDATE_ORDER_SHIFT',
    shift: resp,
});

const receiveFetchOrders = resp => ({
    type: 'RECEIVE_FETCH_ORDERS',
    orders: resp.collection,
    count: resp.count,
    page: resp.page,
    totalCount: resp.total_count,
    totalPages: resp.total_pages,
});

const receiveFetchOrder = resp => ({
    type: 'RECEIVE_FETCH_ORDER',
    order: resp,
});

const receiveDeleteOrder = resp => ({
    type: 'RECEIVE_DELETE_ORDER',
    order: resp,
});

export const fetchOrders = filters => async dispatch => {
    dispatch(requestOrders());
    try {
        dispatch(receiveFetchOrders(await request.getOrdersV1(filters)));
    }
    catch {
        message.error('Failed to fetch the order');
    }
};

export const fetchOrder = id => async dispatch => {
    dispatch(requestOrder());
    try {
        dispatch(receiveFetchOrder(await request.getOrderV1(id)));
    }
    catch {
        message.error('Failed to fetch order');
    }
};

export const updateOrder = (id, data) => async dispatch => {
    dispatch(requestUpdateOrder());
    try {
        dispatch(receiveUpdateOrder(await request.updateOrder(id, data)));
    }
    catch {
        message.error('Failed to update order');
    }
};

export const updateShifts = (orderId, shifts) => async dispatch => {
    dispatch(requestUpdateShifts());
    try {
        dispatch(receiveUpdateOrderShifts(await request.updateOrderShifts(orderId, shifts)));
    }
    catch {
        message.error('Failed to update shifts');
    }
};

export const updateShift = (orderId, shiftId, shift) => async dispatch => {
    dispatch(requestUpdateShift());
    try {
        dispatch(receiveUpdateOrderShift(await request.updateOrderShift(orderId, shiftId, shift)));
    }
    catch {
        message.error('Failed to update shift');
    }
};

export const deleteOrder = id => async dispatch => {
    dispatch(requestDeleteOrder());
    try {
        dispatch(receiveDeleteOrder(await request.updateOrder(id, { is_posted: true })));
    }
    catch {
        message.error('Failed to delete order');
    }
};
