const initialState = {
    scheduled: [], hasFetched: false, isFetching: false,
};

const scheduled = (state = initialState, action) => {
    switch (action.type) {
        case 'RECEIVE_SCHEDULED_LIST':
            return {
                ...state,
                scheduled: action.scheduled,
                hasFetched: true,
                isFetching: false,
            };

        default:
            return { ...state };
    }
};

export default scheduled;
