import React from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Row,
    Col,
    Tag,
    Divider,
} from 'antd';
import moment from 'moment';
import './styles.scss';
import {
    getFormattedDateWithWeek,
    humanizedDurationFromNow,
    unixToTimeString,
} from 'utils/datetimeformatters';

const OrderDetailsCard = ({ order }) => (
    <div className='order-details-card'>
        <div className='order-details-card-header'>
            <Typography.Title level={4}>Order Details</Typography.Title>
            <Tag color={order.is_posted ? 'green' : 'red'}>
                {order.is_posted ? 'Published' : 'Draft'}
            </Tag>
        </div>
        <Divider />
        <Row gutter={[24, 24]}>
            <Col span={12}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Order ID</Typography.Text>
                    <Typography.Text strong>{order.id}</Typography.Text>
                </div>
            </Col>
            <Col span={12}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Customer</Typography.Text>
                    <Typography.Text strong>{order.customer_name}</Typography.Text>
                </div>
            </Col>
            <Col span={12}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Department</Typography.Text>
                    <Typography.Text strong>{order.department_name}</Typography.Text>
                </div>
            </Col>
            <Col span={12}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Order created date</Typography.Text>
                    <Typography.Text strong>
                        {getFormattedDateWithWeek(order.created_at)}
                    </Typography.Text>
                </div>
            </Col>
            <Col span={12}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Due Date</Typography.Text>
                    <Typography.Text strong>
                        {getFormattedDateWithWeek(order.order_due_date_datetime)}
                    </Typography.Text>
                </div>
            </Col>
            {/* show location */}
            <Col span={12}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Location</Typography.Text>
                    <Typography.Text strong>{order?.location?.city}</Typography.Text>
                </div>
            </Col>
            <Col span={24}>
                <div className='detail-item'>
                    <Typography.Text type='secondary'>Information</Typography.Text>
                    <Typography.Text strong>{order.information}</Typography.Text>
                </div>
            </Col>
        </Row>

        <Divider />

        <div className='roles-section'>
            <Typography.Title level={5}>Roles & Shifts</Typography.Title>
            {order.roles?.map(role => (
                <div key={role.id} className='role-item'>
                    <div className='role-header'>
                        <Typography.Text strong>{role.name}</Typography.Text>
                        {role.shifts?.length > 0 && (
                            <Tag color='blue'>
                                {role.shifts?.length || 0}
                                {' '}
                                shifts
                            </Tag>
                        )}
                        {role.periods?.length > 0 && (
                            <Tag color='purple'>
                                {role.periods?.length || 0}
                                {' '}
                                LTV
                            </Tag>
                        )}
                    </div>
                    <div
                        style={{
                            maxHeight: '200px', overflowY: 'auto', marginTop: '8px',
                        }}
                    >
                        {role.shifts?.map(shift => (
                            <div key={`shift-${shift.id}`} className='shift-item'>
                                <Typography.Text type='secondary'>
                                    {moment.unix(shift.start_time).format('MMM D, HH:mm')}
                                    {' '}
                                    -
                                    {' '}
                                    {moment.unix(shift.end_time).format('MMM D, HH:mm')}
                                </Typography.Text>
                                <Tag color={shift.is_posted ? 'green' : 'orange'} size='small'>
                                    {shift.is_posted ? 'Posted' : 'Pending'}
                                </Tag>
                            </div>
                        ))}
                        {role.periods?.map(period => (
                            <div key={`period-${period.id}`} className='shift-item'>
                                <Typography.Text type='secondary'>
                                    {moment.unix(period.start_time).format('MMM D, HH:mm')}
                                    {' '}
                                    -
                                    {' '}
                                    {moment.unix(period.end_time).format('MMM D, HH:mm')}
                                </Typography.Text>
                                <Tag color={period.is_posted ? 'green' : 'orange'} size='small'>
                                    {period.is_posted ? 'Posted' : 'Pending'}
                                </Tag>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    </div>
);

OrderDetailsCard.propTypes = {
    order: PropTypes.shape({
        id: PropTypes.number.isRequired,
        customer_name: PropTypes.string.isRequired,
        department_name: PropTypes.string.isRequired,
        is_posted: PropTypes.bool.isRequired,
        order_due_date_datetime: PropTypes.number.isRequired,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            shifts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                start_time: PropTypes.string.isRequired,
                end_time: PropTypes.string.isRequired,
                is_posted: PropTypes.bool.isRequired,
            })),
        })),
        information: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
        location: PropTypes.shape({
            city: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default OrderDetailsCard;
