export const employersFilterToggleIncludeTestAccounts = () => ({ type: 'EMPLOYERS_FILTER_TOGGLE_INCLUDE_TEST_ACCOUNTS' });

export const employersFilterToggleIncludeTopConsultants = () => ({ type: 'EMPLOYERS_FILTER_TOGGLE_INCLUDE_TOP_CONSULTANTS' });

export const employersFilterSetCountries = countryCodes => ({
    type: 'EMPLOYERS_FILTER_SET_COUNTRIES',
    countryCodes,
});

export const employersFilterSetCounties = countyIds => ({
    type: 'EMPLOYERS_FILTER_SET_COUNTIES',
    countyIds,
});

export const employersFilterSetDepartmentTypes = departmentTypeIds => ({
    type: 'EMPLOYERS_FILTER_SET_DEPARTMENT_TYPES',
    departmentTypeIds,
});

export const employersFilterSetQuery = query => ({
    type: 'EMPLOYERS_FILTER_SET_QUERY',
    query,
});

export const employersFilterSetMaintainingType = employerMaintainingType => ({
    type: 'EMPLOYERS_FILTER_SET_MAINTAINING_TYPE',
    employerMaintainingType,
});

export const employersFilterSetEmployerType = employerType => ({
    type: 'EMPLOYERS_FILTER_SET_TYPE',
    employerType,
});

export const employersFilterSetMarginConfigs = marginConfigs => ({
    type: 'EMPLOYERS_FILTER_SET_MARGIN_CONFIGS',
    marginConfigs,
});

export const employersFilterSetSort = sort => ({
    type: 'EMPLOYERS_FILTER_SET_SORT',
    sort,
});
