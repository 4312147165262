import React from 'react';
import {
    filter,
    map,
    groupBy,
    reduce,
    last,
    find,
} from 'lodash';
import moment from 'moment';
import Chart from 'chart.js';
// import Select from 'components/shared/select';
import config from 'config';
import {
    Form,
    Select,
} from 'antd';
import './styles.scss';
import FormItem from 'antd/lib/form/FormItem';

class MarketingSourceCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPeriod: null,
            monthChartRested: false,
        };
    }

    componentDidMount() {
        this._weeklyGraphData();

        const stacks = this.refs.monthlyGraph.getContext('2d');
        this.stacksChart = new Chart(stacks, {
            type: 'bar',
            options: {
                legend: { display: false },
                scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
            },
        });
    }

    _dataStacks(selectedPeriod) {
        const active = filter(this.props.options, option => !option.inactive);
        const labels = active.map(option => option.title);
        const data = selectedPeriod;
        const colors = map(selectedPeriod, (option, i) => {
            if (!config.colors[i]) {
                // Restart form beginning when all colors are used
                return config.colors[i - config.colors.length].hex;
            }

            return config.colors[i].hex;
        });

        const conf = { data: {
            labels,
            datasets: [{
                label: false,
                data,
                backgroundColor: colors,
            }],
        } };

        this.stacksChart.config.data = conf.data;
        this.stacksChart.update();
    }

    _weeklyGraphData() {
        const { options } = this.props;
        const active = filter(options, option => !option.inactive);
        const all = reduce(active, ((arr, a) => {
            const time = a.register_times.map(t => ({
                id: a.id,
                time: t,
                title: a.title,
            }));

            return arr.concat(time);
        }), []);

        const formated = groupBy(all.map(a => ({
            id: a.id,
            title: a.title,
            week: moment.unix(a.time).startOf('week').unix(),
        })), 'week');

        const labels = map(formated, (all, ts) => moment.unix(ts).format('v.w'));
        const totals = map(formated, items => items.length);
        const datasets = map(active, (item, i) => {
            let color;

            if (item.id !== -1) {
                color = config.colors[i] ? config.colors[i].hex : config.colors[i - config.colors.length].hex;
            }
            else {
                color = 'hotpink';
            }

            return {
                label: item.title,
                data: map(formated, (all, ts) => filter(all, i => i.id === item.id).length),
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: color,
                borderWidth: 2,
                pointRadius: 2,
                lineTension: 0,
            };
        });

        datasets.push({
            label: 'Total',
            data: totals,
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: '#000',
            borderWidth: 2,
            pointRadius: 2,
            lineTension: 0,
        });

        const ctx = this.refs.weeklyChart.getContext('2d');

        new Chart(ctx, {
            type: 'line',
            data: {
                labels,
                datasets,
            },
            options: { scales: {
                xAxes: [{ gridLines: { display: false } }],
                yAxes: [{
                    gridLines: { display: false },
                    ticks: { beginAtZero: true },
                }],
            } },
        });
    }

    renderWeeklyGraph() {
        const { monthChartRested } = this.state;

        return (
            <div className='graph'>
                <h3 className='title'>Source per week</h3>
                <canvas ref='weeklyChart' />
            </div>
        );
    }

    _getStacksDataForPeriod(itemsInPeriod) {
        const activeOptions = filter(this.props.options, option => !option.inactive);

        const count = map(activeOptions, option => ({ count: itemsInPeriod.filter(item => option.title === item.title).length }));

        return map(count, c => c.count);
    }

    renderMonthlyGraph() {
        const { options } = this.props;
        const active = filter(options, option => !option.inactive);
        const all = reduce(active, ((arr, a) => {
            const time = a.register_times.map(t => ({
                id: a.id,
                time: t,
                title: a.title,
            }));

            return arr.concat(time);
        }), []);

        const itemsByMonth = groupBy(all.map(a => ({
            id: a.id,
            title: a.title,
            month: moment.unix(a.time).startOf('month').unix(),
        })), 'month');

        const selectData = map(itemsByMonth, month => ({
            title: moment.unix(month[0].month).format('MMMM - YYYY'),
            value: this._getStacksDataForPeriod(month),
            id: month[0].month,
        }));

        return (
            <div className='graph'>
                <div
                    style={{
                        width: '100%',
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 30,
                    }}
                >
                    <h3
                        className='title'
                        style={{
                            display: 'flex',
                            marginRight: 20,
                            marginBottom: 0,
                        }}
                    >
                        Source per month
                    </h3>
                    <Form>
                        <FormItem
                            initialValue={last(selectData).id}
                            label='Select month'
                        >
                            <Select
                                onSelect={value => {
                                    const formValues = find(selectData, { id: +value });
                                    this._dataStacks(formValues.value);
                                }}
                            >
                                {selectData.map(item => (
                                    <Select.Option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Form>
                </div>
                <canvas ref='monthlyGraph' />
            </div>
        );
    }

    render() {
        return (

            <>
                {this.renderWeeklyGraph()}
                {this.renderMonthlyGraph()}
            </>
        );
    }
}

export default MarketingSourceCharts;
