import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    map,
    groupBy,
    sortBy,
} from 'lodash';
import en_GB from 'antd/lib/locale-provider/en_GB';
import {
    Table,
    Collapse,
    List,
    Button,
    Calendar,
    ConfigProvider,
    Empty,
    Tag,
    Tooltip,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from 'config';
import {
    fetchConsultantScheduled,
    batchUpdateScheduled,
} from 'actions/consultant';
import EditBookingModal from 'components/editbookingmodal';
import Loader from 'components/shared/loader';
import BatchUpdateScheduledModal from 'components/batchupdatescheduledmodal';

const getTimeOfDayIcon = startHour => {
    if (startHour > 5 && startHour < 10) {
        return '🌞';
    }
    if (startHour >= 10 && startHour <= 17) {
        return '🌥';
    }
    return '🌛';
};

const ConsultantBookingYear = ({
    year,
    key,
    setShowEditBookingModal,
    setShowEditBookingModalData,
}) => {
    const grouped = groupBy(year, item => moment.unix(item.booked_time).format('YYYY-MM'));
    const renderTable = (data, date) => {
        const columns = [
            {
                title: 'Employer',
                dataIndex: '',
                key: 'employer',
                width: 250,
                render: (_, record) => (
                    <Link to={`/employers/${record.employer.id}`}>
                        {record.employer.name}
                    </Link>
                ),
            },
            {
                title: 'Profile',
                dataIndex: '',
                key: 'booking_id',
                render: (_, record) => (
                    <div>
                        {record.profile.name}
                        {record.profile.is_long_term && <Tag style={{ marginLeft: 5 }}>LTV</Tag>}
                    </div>
                ),
            },
            {
                title: 'Booking ID',
                dataIndex: 'id',
                key: 'booking_id',
                render: text => text,
            },
            {
                title: 'Shifts',
                dataIndex: '',
                key: 'shifts',
                render: (_, record) => record.shifts.length,
            },
            {
                title: 'Booked at',
                dataIndex: 'booked_time',
                key: 'booked_at',
                render: booked_time => moment.unix(booked_time).format('YYYY-MM-DD HH:mm'),
            },
            {
                title: 'Show / Edit',
                dataIndex: '',
                key: 'edit',
                render: (_, record) => (
                    <Button
                        type='primary'
                        onClick={() => {
                            setShowEditBookingModal(true);
                            setShowEditBookingModalData({
                                bookingId: record.id,
                                employerId: record.employer.id,
                            });
                        }}
                    >
                        Show / edit booking
                    </Button>
                ),
            },

        ];

        const header = (
            <div
                style={{
                    textTransform: 'capitalize', fontSize: 20,
                }}
            >
                {moment(date).format('MMMM')}
                ,
                {' '}
                {data.length}
                {' '}
                bookings
            </div>
        );

        return (
            <Collapse.Panel
                key={date}
                header={header}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowKey='id'
                    scroll={{ x: 1100 }}
                />
            </Collapse.Panel>
        );
    };
    return (
        <div key={key}>
            <h3
                style={{ marginTop: 20 }}
            >
                {key}

            </h3>
            <Collapse accordion>
                {map(grouped, (item, monthKey) => renderTable(item, monthKey))}
            </Collapse>
        </div>
    );
};
ConsultantBookingYear.propTypes = {
    year: PropTypes.shape({}).isRequired,
    key: PropTypes.string.isRequired,
    setShowEditBookingModal: PropTypes.func.isRequired,
    setShowEditBookingModalData: PropTypes.func.isRequired,
};

const ConsultantBookings = ({
    scheduled,
    applications,
    hasFetchedScheduled,
    consultant,
    batchUpdateScheduled,
    fetchConsultantScheduled,
    hasBatchUpdatedScheduled,
}) => {
    let startDate = moment();
    const [showEditBookingModal, setShowEditBookingModal] = useState(false);
    const [showEditBookingModalData, setShowEditBookingModalData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(startDate);
    const [batchUpdateScheduledModalVisible, setBatchUpdateScheduledModalVisible] = useState(false);
    useEffect(() => {
        if (!hasFetchedScheduled) {
            fetchConsultantScheduled(consultant.id);
        }
    }, [consultant, hasFetchedScheduled, fetchConsultantScheduled]);

    const startDates = scheduled.map(shift => shift.start_time);
    const max = Math.max(...startDates, moment().add(2, 'months').endOf('month').format('X'));

    const availableDates = consultant.availability;

    if (moment.unix(max).startOf('day').unix() < moment().startOf('day').unix()) {
        startDate = moment.unix(max);
    }

    const onPanelChange = value => {
        setSelectedDate(value);
    };

    const closeEditModal = () => {
        setShowEditBookingModal(false);
        setShowEditBookingModalData(null);
    };

    const monthCellRender = value => {
        const events = scheduled.filter(
            shift => moment.unix(shift.start_time).format('YYYY-MM') === value.format('YYYY-MM'),
        );
        if (events.length) {
            // const count = countBy(events, (event) => event.employer.name);
            const items = groupBy(events, event => event.employer.name);

            return (
                <div
                    style={{}}
                >
                    {map(items, item => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                            style={{
                                backgroundColor: config.colors[5].hex,
                                color: '#fff',
                                padding: 5,
                                fontSize: 12,
                                borderRadius: 8,
                                marginBottom: 5,
                            }}
                            onClick={() => {
                                setShowEditBookingModal(true);
                                setShowEditBookingModalData({
                                    bookingId: item[0].application_id,
                                    employerId: item[0].employer.id,
                                });
                            }}
                        >

                            <strong
                                style={{
                                    marginBottom: 5,
                                    display: 'block',
                                }}
                            >
                                {item[0].employer.name}
                            </strong>
                            <span>
                                ID:
                                {item[0].application_id}
                            </span>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const dateCellRender = value => {
        const date = value.format('YYYY-MM-DD');
        const availability = availableDates.filter((d) => d.date === date)
        const events = scheduled.filter(
            shift => moment.unix(shift.start_time).format('YYYY-MM-DD') === date,
        );

        if (availability.length) {
            events.unshift({
                type: 'available'
            })
        }
        return events.map(event => event.type && event.type == 'available' ? (
            <div className="bg-green-200 rounded px-2 text-sm text-green-800">
                Available {/*availability.shifts.join(', ')*/}
            </div>
        ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
                style={{
                    backgroundColor: event.sick_leave ? config.colors[0].hex : config.colors[5].hex,
                    color: '#fff',
                    padding: 5,
                    fontSize: 12,
                    borderRadius: 8,
                }}
                onClick={() => {
                    setShowEditBookingModal(true);
                    setShowEditBookingModalData({
                        bookingId: event.application_id,
                        employerId: event.employer_id,
                    });
                }}
            >
                <strong
                    style={{
                        marginBottom: 5,
                        display: 'block',
                    }}
                >
                    <Tooltip
                        title={
                            `${moment.unix(event.start_time).format('HH:mm')}-${moment.unix(event.end_time).format('HH:mm')}`
                        }
                    >
                        {`${getTimeOfDayIcon(
                            parseInt(moment.unix(event.start_time).format('HH'), 10),
                        )} ${event.employer.name}`}
                    </Tooltip>

                </strong>
                <span>
                    ID:
                    {event.application_id}
                </span>
            </div>
        ));
    };

    const renderCalendar = () => {
        const min = Math.min(...startDates);
        const validRange = [moment.unix(min).startOf('day'), moment.unix(max).endOf('day')];

        return (
            <ConfigProvider locale={en_GB}>
                <Calendar
                    dateCellRender={dateCellRender}
                    defaultValue={false}
                    monthCellRender={monthCellRender}
                    validRange={validRange}
                    value={selectedDate}
                    onPanelChange={onPanelChange}
                />
            </ConfigProvider>
        );
    };

    const renderEmployers = data => {
        const employers = groupBy(data, item => item.employer.name);

        const formated = sortBy(map(employers, (employer, key) => ({
            name: key,
            id: employer[0].employer.id,
            count: employer.length,
        })), 'count').reverse();

        return (
            <Collapse accordion>
                <Collapse.Panel
                    key='employers'
                    header={<span style={{ fontSize: 20 }}>All previous employers</span>}
                >

                    <List
                        dataSource={formated}
                        renderItem={item => (
                            <List.Item>
                                <Link to={`/employers/${item.id}`}>
                                    {item.name}
                                </Link>
                                ,
                                {' '}
                                {item.count}
                                {' '}
                                shifts
                            </List.Item>
                        )}
                    />
                </Collapse.Panel>
            </Collapse>
        );
    };

    if (!hasFetchedScheduled) {
        return (
            <Loader />
        );
    }

    if (!applications.length) {
        return (
            <div className='content-card-full'>
                <Empty />
            </div>
        );
    }

    const allBookings = applications.filter(application => application.status === 'CONFIRMED');

    const data = sortBy(allBookings, 'booked_time').reverse();

    const groupedByYear = groupBy(data, item => moment.unix(item.booked_time).format('YYYY'));

    return (
        <div className='content-card-full'>
            <Button
                size='large'
                onClick={() => { setBatchUpdateScheduledModalVisible(true); }}
            >
                <i className='fa fa-pencil' style={{ paddingRight: 5 }} />
                Batch update bookings
            </Button>
            <BatchUpdateScheduledModal
                batchUpdateScheduled={batchUpdateScheduled}
                close={() => { setBatchUpdateScheduledModalVisible(false); }}
                consultant={consultant}
                hasBatchUpdatedScheduled={hasBatchUpdatedScheduled}
                scheduled={scheduled}
                visible={batchUpdateScheduledModalVisible}
            />
            {renderEmployers(data)}

            <div>
                {renderCalendar()}
            </div>

            <div
                style={{
                    flexDirection: 'column-reverse',
                    display: 'flex',
                }}
            >
                {map(groupedByYear, (year, key) => (
                    <ConsultantBookingYear
                        key={key}
                        setShowEditBookingModal={setShowEditBookingModal}
                        setShowEditBookingModalData={setShowEditBookingModalData}
                        year={year}
                    />
                ))}
            </div>
            {showEditBookingModal
                ? (
                    <EditBookingModal
                        close={closeEditModal}
                        data={showEditBookingModalData}
                        visible={showEditBookingModal}
                        onClose={() => showEditBookingModal(false)}
                    />
                )
                : null}

        </div>
    );
};
ConsultantBookings.propTypes = {
    scheduled: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        employer: PropTypes.shape({}),
    })).isRequired,
    applications: PropTypes.arrayOf(PropTypes.shape({})),
    hasFetchedScheduled: PropTypes.bool.isRequired,
    consultant: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
    batchUpdateScheduled: PropTypes.func.isRequired,
    fetchConsultantScheduled: PropTypes.func.isRequired,
    hasBatchUpdatedScheduled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    consultant: state.consultant.consultant,
    scheduled: state.consultant.scheduled.filter(
        s => (s.status === 'CONFIRMED'),
    ).sort((a, b) => a.start_time - b.start_time),
    applications: state.consultant.applications,
    hasFetchedScheduled: state.consultant.hasFetchedScheduled,
    hasBatchUpdatedScheduled: state.consultant.hasBatchUpdatedScheduled,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        batchUpdateScheduled,
        fetchConsultantScheduled,
    }, dispatch),
)(ConsultantBookings);
