import {
    Select,
    Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useState,
} from 'react';
import debounce from 'lodash/debounce';
import { fetchEmployersV2 } from '../../request';

const EmployerSearchList = ({
    employers: initialEmployers,
    onChange,
    placeholder = 'Select an customer',
    value,
    onSearch,
}) => {
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [remoteEmployers, setRemoteEmployers] = useState([]);

    const debouncedSearch = useCallback(
        debounce(async searchText => {
            if (!searchText) {
                setRemoteEmployers([]);
                return;
            }
            setLoading(true);
            try {
                const response = await fetchEmployersV2(
                    0, // testEmployer
                    null, // employerType
                    null, // automotive
                    [], // departmentTypeIds
                    [], // countyIds
                    'SWE', // countryCode
                    [], // marginConfigs
                    searchText, // name
                    1, // page
                    null, // sort
                );
                // Transform the response to match the expected format
                const formattedEmployers = (response.collection || []).map(employer => ({
                    id: employer.id,
                    value: employer.id,
                    label: employer.name,
                    name: employer.name,
                }));
                setRemoteEmployers(formattedEmployers);
            }
            catch (error) {
                console.error('Failed to fetch employers:', error);
                setRemoteEmployers([]);
            }
            finally {
                setLoading(false);
            }
        }, 500),
        [],
    );

    const handleSearch = searchValue => {
        debouncedSearch(searchValue);
        onSearch?.(searchValue);
    };

    const handleChange = selectedValue => {
        const allEmployers = [...remoteEmployers, ...initialEmployers];
        const selectedEmployer = allEmployers.find(emp => emp.id === selectedValue);

        if (selectedEmployer) {
            // Pass both the selected value and the employer object to the parent
            onChange?.(selectedValue, selectedEmployer);
        }
    };

    const employers = remoteEmployers.length > 0 ? remoteEmployers : initialEmployers;

    return (
        <Select
            allowClear
            filterOption={false}
            notFoundContent={loading ? <Spin size='small' /> : null}
            placeholder={placeholder}
            showSearch
            style={{ width: '100%' }}
            value={value}
            onChange={handleChange}
            onSearch={handleSearch}
        >
            {employers?.map(employer => (
                <Option
                    key={employer.id}
                    value={employer.id}
                >
                    {employer.name || employer.label}
                </Option>
            ))}
        </Select>
    );
};

EmployerSearchList.propTypes = {
    employers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.number,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onSearch: PropTypes.func,
};

export default EmployerSearchList;
