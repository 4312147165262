import {
    Modal,
    Form,
} from 'antd';
import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { fetchConsultants } from 'actions/consultants';
import {
    debounce,
    find,
} from 'lodash';
import ConsultantSelector from '../../../../components/createshiftslistmodal/consultantselector';
import SelectedConsultant from '../../../../components/createshiftslistmodal/selectedconsultant';
import { createEmptyConsultantBooking } from '../../../../actions/consultants';

const ConsultantSearchModal = ({
    profileId,
    visible,
    close,
    includeTestAccounts,
}) => {
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [consultantSearchQuery, setConsultantSearchQuery] = useState('');
    const [consultantsSearchResults, setConsultantsSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();
    const consultants = useSelector(state => state.consultants.consultants);

    useEffect(() => {
        const doSearch = query => {
            setIsSearching(true);
            dispatch(fetchConsultants({
                query, includeTestAccounts,
            }));
        };

        if (consultantSearchQuery) {
            doSearch(consultantSearchQuery);
        }
    }, [consultantSearchQuery, setIsSearching, dispatch]);

    useEffect(() => {
        if (isSearching) {
            setConsultantsSearchResults(consultants);
        }
    }, [setConsultantsSearchResults, consultants, isSearching]);

    const bookConsultant = () => {
        dispatch(createEmptyConsultantBooking(profileId, selectedConsultant.id))
            .then(() => {
                close();
                window.location.reload(); // Because state doesn't update...
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleConsultantSelectorSearch = useCallback(debounce(setConsultantSearchQuery, 400), []);

    return (
        <Modal
            okText='Book'
            title='Book consultant'
            visible={visible}
            onCancel={close}
            onOk={bookConsultant}
        >
            {selectedConsultant ? (
                <SelectedConsultant
                    consultant={selectedConsultant}
                    removeConsultant={() => {
                        setSelectedConsultant(null);
                    }}
                />
            ) : (
                <Form>
                    <ConsultantSelector
                        consultants={consultantsSearchResults}
                        onSearch={handleConsultantSelectorSearch}
                        onSelect={id => {
                            const consultant = find(consultants, { id: +id });
                            setSelectedConsultant(consultant);
                        }}
                    />
                </Form>
            )}
        </Modal>
    );
};

ConsultantSearchModal.propTypes = {
    profileId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    includeTestAccounts: PropTypes.bool.isRequired,
};

export default ConsultantSearchModal;
