import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as shiftsActions from 'actions/shifts';
import * as consultantsActions from 'actions/consultants';
import * as employerActions from 'actions/employer';
import * as applicationsActions from 'actions/applications';
import ReplaceConsultantModal from 'components/replaceconsultantmodal';
import RemoveShiftModal from 'components/removeshiftmodal';
import moment from 'moment';
import {
    Modal,
    Button,
    message,
} from 'antd';

class ReplaceConsultantPopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopover: false,
            showRemoveShiftModal: false,
            showReplaceConsultantModal: false,
        };

        this.renderModals = this.renderModals.bind(this);
        this.renderShowCreateNewShiftModal = this.renderShowCreateNewShiftModal.bind(this);
        this.onConfirmSickLeave = this.onConfirmSickLeave.bind(this);
        this.onCreateNewAvailableShift = this.onCreateNewAvailableShift.bind(this);
        this.fetchEmployerShifts = this.fetchEmployerShifts.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.consultants.removeConsultantFromBookedShiftSuccess
      && this.props.consultants.removeConsultantFromBookedShiftSuccess
      && !this.props.shifts.isFetchingShift
        ) {
            this.fetchEmployerShifts();
        }
    }

    fetchEmployerShifts() {
        const { id } = this.props.employer;

        this.props.fetchEmployerShifts(id);
    }

    onConfirmReplace(replacementConsultantId) {
        const {
            shift, applicant,
        } = this.props;

        return this.props.replaceConsultantInBooking(applicant.application_id, shift.profile.id, shift.id, replacementConsultantId);
    }

    onConfirmRemove() {
        const {
            shift, applicant,
        } = this.props;

        return this.props.removeShiftFromConsultantBooking(applicant.application_id, shift.id);
    }

    onConfirmSickLeave() {
        const { id } = this.props.shift.scheduled[0];

        return this.props.updateBookedShift(id, { sick_leave: true });
    }

    onCreateNewAvailableShift() {
        const {
            shift, employer,
        } = this.props;
        const {
            profile, start_time, duration, price, margin, shift_break,
        } = shift;

        const newShift = {
            status: 1,
            profile_id: profile.id,
            employer: employer.id,
            start_time,
            duration,
            price,
            margin,
            shift_break,
        };

        return this.props.createShift(employer.id, newShift, { notifications: true });
    }

    renderRemoveAction() {
        const applicantName = `${this.props.user.firstname} ${this.props.user.lastname}`;
        const title = this.props.isBooking ? `Ta bort ${applicantName} från pass` : `Ta bort ${applicantName}s ansökan från pass`;

        return (
            <div
                style={{ marginTop: 10 }}
            >
                <Button
                    block
                    danger
                    onClick={() => {
                        this.setState({ showRemoveShiftModal: true });
                    }}
                >
                    {title}
                </Button>
                <Modal
                    bodyStyle={{ padding: 0 }}
                    cancelText='Avbryt'
                    confirmLoading={this.state.applicantIsBeingRemovedFromShift}
                    okText={title}
                    okType='danger'
                    title={title}
                    visible={this.state.showRemoveShiftModal}
                    onCancel={() => {
                        this.setState({ showRemoveShiftModal: false });
                    }}
                    onOk={() => {
                        this.setState({ applicantIsBeingRemovedFromShift: true });

                        this.onConfirmRemove().then(() => {
                            this.setState({
                                showRemoveShiftModal: false,
                                applicantIsBeingRemovedFromShift: false,
                            });
                        }, error => {
                            this.setState({ applicantIsBeingRemovedFromShift: false });
                        });
                    }}
                >
                    <RemoveShiftModal
                        {
                            ...this.props
                        }
                    />
                </Modal>
            </div>
        );
    }

    renderPopover() {
        if (!this.state.showPopover) {
            return '';
        }

        return (
            <div
                style={{
                    position: 'absolute',
                    right: '100%',
                    top: 0,
                    padding: 20,
                    background: '#FFF',
                    color: '#333',
                    border: '1px solid #EEE',
                    boxShadow: '0 1px 5px rgba(0,0,0,0.2)',
                }}
            >
                <div>
                    Enter the consultant ID you want to replace
                    {this.props.user.firstname}
                    with:
                </div>
                <div>
                    <input
                        style={{
                            padding: 5,
                            marginTop: 5,
                        }}
                        type='text'
                    />
                </div>
                <div>
                    <Button
                        block
                        onClick={() => {
                            const {
                                user, applicant, shift,
                            } = this.props;
                            const replacementConsultantId = 138;

                            this.props.replaceConsultantInBooking(user.id, shift.profile.id, shift.id, replacementConsultantId);
                        }}
                    >
                        Replace
                    </Button>
                </div>
            </div>
        );
    }

    renderShowCreateNewShiftModal() {
        const {
            onConfirmSickLeave,
            onCreateNewAvailableShift,
            fetchEmployerShifts,
        } = this;
        const {
            shift, applicant,
        } = this.props;
        const shiftIsInFuture = moment().diff(moment.unix(shift.start_time), 'hours') <= 0;

        return (
            Modal.confirm({
                width: 500,
                title: 'Vill du skapa ett nytt pass för denna period?',
                cancelText: 'Nej, skapa inget nytt pass',
                okText: 'Skapa nytt pass för denna period',
                onOk() {
                    onConfirmSickLeave().then(() => {
                        message.success(`${applicant.firstname} ${applicant.lastname} har sjukskrivits för pass ID: ${shift.id}`, 5);
                        if (shiftIsInFuture) {
                            onCreateNewAvailableShift().then(() => {
                                message.success('Nytt tillgängligt pass has skapats', 5);
                                fetchEmployerShifts();
                            });
                        }
                    });
                },
                onCancel() {
                    onConfirmSickLeave().then(() => {
                        fetchEmployerShifts();
                        message.success('Konsult har sjukskrivits för passet');
                        this.onCancel();
                    });
                },
            })
        );
    }

    renderConfirmSickLeave() {
        const {
            renderShowCreateNewShiftModal, onConfirmSickLeave, fetchEmployerShifts,
        } = this;
        const {
            employer, shift, applicant,
        } = this.props;
        const date = moment.unix(shift.start_time).format('YYYY-MM-DD');
        const startTime = moment.unix(shift.start_time).format('HH:mm');
        const endTime = moment.unix(shift.end_time).format('HH:mm');
        const shiftIsInFuture = moment().diff(moment.unix(shift.start_time), 'hours') <= 0;
        const content = (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    margin: '20px 0',
                }}
            >
                <div
                    style={{
                        width: 120,
                        height: 120,
                        borderRadius: 120,
                        background: '#EEE',
                        backgroundImage: `url(${applicant.avatar})`,
                        backgroundSize: 'cover',
                        marginRight: 20,
                    }}
                />
                <div>
                    <div>
                        {employer.name}
                    </div>
                    <div>{employer.department}</div>
                    <div>
                        {date}
                    </div>
                    <div>
                        {startTime}
                        {' '}
                        -
                        {' '}
                        {endTime}
                    </div>
                    <div>
                        {shift.duration * shift.price}
                        {' '}
                        kr
                    </div>
                </div>

            </div>
        );

        return (
            Modal.confirm({
                title: `Vill du sjuk skriva ${applicant.firstname} ${applicant.lastname}`,
                content,
                okType: 'danger',
                okText: `Sjukskriv ${applicant.firstname} ${applicant.lastname}`,
                onOk() {
                    if (shiftIsInFuture) {
                        renderShowCreateNewShiftModal();
                    }
                    else {
                        onConfirmSickLeave().then(() => {
                            fetchEmployerShifts();
                            message.success(`${applicant.firstname} ${applicant.lastname} har sjukskrivits för pass: ${shift.id}`);
                            this.onCancel();
                        });
                    }
                },
                cancelText: 'Avbryt',
            })
        );
    }

    renderModals() {
        const {
            shift, applicant, isApplication,
        } = this.props;
        const shiftIsBooked = shift.scheduled.length;

        if (shiftIsBooked && !isApplication) {
            return (
                <div>
                    <div
                        style={{ marginTop: 10 }}
                    >
                        <Button
                            block
                            danger
                            onClick={this.renderConfirmSickLeave.bind(this)}
                        >
                            Sjukskriv
                            {' '}
                            {applicant.firstname}
                            {' '}
                            {applicant.lastname}
                        </Button>

                    </div>
                    <div
                        style={{ marginTop: 10 }}
                    >
                        <Modal
                            bodyStyle={{ padding: 0 }}
                            footer={null}
                            title='Byt ut konsult'
                            visible={this.state.showReplaceConsultantModal}
                            width={800}
                            onCancel={() => {
                                this.setState({ showReplaceConsultantModal: false });
                            }}
                        >
                            <ReplaceConsultantModal
                                consultant={this.props.consultant}
                                consultants={this.props.consultants.consultants}
                                employer={this.props.employer}
                                shift={this.props.shift}
                                user={this.props.user}
                                onClose={() => {
                                    this.setState({ showReplaceConsultantModal: false });
                                }}
                                onConfirmReplace={this.onConfirmReplace.bind(this)}
                            />
                        </Modal>
                        <Button
                            block
                            danger
                            onClick={() => {
                                this.setState({ showReplaceConsultantModal: true });
                            }}
                        >
                            Byt ut
                            {' '}
                            {applicant.firstname}
                            {' '}
                            {applicant.lastname}
                        </Button>

                    </div>
                </div>
            );
        }
    }

    renderActions() {
        return (
            <div>
                {this.renderModals()}
                {this.renderRemoveAction()}
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    position: 'relative',
                    color: '#333',
                }}
            >
                {this.renderActions()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    consultants: state.consultants,
    employer: state.employer.employer,
    applications: state.applications,
    shifts: state.shifts,
    user: ownProps.applicant.user,
    ownProps,
});

const mapDispatchToProps = dispatch => ({
    createShift: bindActionCreators(employerActions.createShift, dispatch),
    updateBookedShift: bindActionCreators(shiftsActions.updateBookedShift, dispatch),
    replaceConsultantInBooking: bindActionCreators(consultantsActions.replaceConsultantInBooking, dispatch),
    removeShiftFromConsultantBooking: bindActionCreators(consultantsActions.removeShiftFromConsultantBooking, dispatch),
    fetchApplication: bindActionCreators(applicationsActions.fetchApplication, dispatch),
    resetApplication: bindActionCreators(applicationsActions.resetApplication, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReplaceConsultantPopover);
