import React, {
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Table,
    Button,
    Modal,
    message,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import * as uploadsActions from 'actions/uploads';
import config from 'config';

import UploadsModal from '../../../components/UploadsModal/UploadsModal';
import './UploadsTable.scss';

const UploadsTable = ({
    uploads,
    fetchAdminUserFileUploads,
    updateAdminUserFileUpload,
    deleteAdminUserFileUpload,
}) => {
    const { id: userId } = useParams();
    const { confirm } = Modal;
    const { uploadFileTypes } = config;

    const defaultModalValues = {
        mode: 'new',
        isVisible: false,
        upload: null,
        userId,
        file_type: uploadFileTypes.IVO,
    };
    const [modalConfig, setModalConfig] = useState(defaultModalValues);
    useEffect(() => {
        fetchAdminUserFileUploads(userId);
    }, [userId, fetchAdminUserFileUploads]);

    const onSave = data => {
        updateAdminUserFileUpload(data, userId).then(() => {
            setModalConfig(defaultModalValues);
        }).catch(error => { message.error(error); });
    };

    const showConfirm = uploadId => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'You are about to delete the upload. This action is irreversible.',
            okText: 'Confirm deletion',
            okButtonProps: {
                danger: true,
                ghost: true,
            },
            onOk: () => deleteAdminUserFileUpload(userId, uploadId),
        });
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'file_type',
        },
        {
            title: 'Visible for employers',
            dataIndex: 'employer_visible',
            width: '5%',
            render: (_, record) => (record.employer_visible ? 'yes' : 'no'),
        },
        {
            title: 'Visible for consultants',
            dataIndex: 'user_visible',
            width: '5%',
            render: (_, record) => (record.user_visible ? 'yes' : 'no'),
        },
        {
            title: 'Created time',
            dataIndex: 'created_time',
            render: (_, record) => moment.unix(record.created_time).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Preview',
            dataIndex: 'url',
            render: (_, record) => <a href={record.url} rel='noreferrer' target='_blank'>Preview file</a>,
        },
        {
            title: 'Actions',
            className: 'actions-column',
            render: (_, record) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => setModalConfig({
                            ...defaultModalValues,
                            isVisible: true,
                            mode: 'edit',
                            upload: record,
                        })}
                    />

                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => showConfirm(record.id)}
                    />
                </>
            ),
        },
    ];
    return (
        <>
            <Button
                className='upload-button'
                ghost
                icon={<UploadOutlined />}
                type='primary'
                onClick={() => setModalConfig({
                    ...defaultModalValues,
                    isVisible: true,
                })}
            >
                Upload new file
            </Button>
            {modalConfig.isVisible && (
                <UploadsModal
                    modalConfig={modalConfig}
                    onClose={() => setModalConfig(defaultModalValues)}
                    onSave={onSave}
                />
            )}
            <Table
                className='uploads-table'
                columns={columns}
                dataSource={uploads}
                pagination={false}
                rowKey='id'
                size='large'
            />
        </>
    );
};

UploadsTable.propTypes = {
    uploads: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.url,
        file_type: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        employer_visible: PropTypes.bool,
        created_time: PropTypes.unix,
    })),
    fetchAdminUserFileUploads: PropTypes.func,
    updateAdminUserFileUpload: PropTypes.func,
    deleteAdminUserFileUpload: PropTypes.func,
};

const mapStateToProps = state => ({ uploads: state.consultant.uploads });
const mapDispatchToProps = dispatch => ({
    fetchAdminUserFileUploads: userId => dispatch(uploadsActions.fetchAdminUserFileUploads(userId)),
    updateAdminUserFileUpload: (data, userId) => dispatch(uploadsActions.updateAdminUserFileUpload(data, userId)),
    deleteAdminUserFileUpload: (userId, uploadId) => dispatch(uploadsActions.deleteAdminUserFileUpload(userId, uploadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadsTable);
