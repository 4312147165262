import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tagsActions from 'actions/tags';

import Tag from 'components/tag';

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.renderTag = this.renderTag.bind(this);
    }

    onCreate() {
        const val = this.refs.createInput.value;
        if (!val) {
            return;
        }

        this.props.createTag(val).then(() => {
            this.refs.createInput.value = '';
        });
    }

    renderTag(tag) {
        return (
            <div>
                <Tag
                    tag={tag}
                />
            </div>
        );
    }

    renderTags() {
        return (
            <div className='mpa-tags'>
                {this.props.tags.tags.map(this.renderTag)}
            </div>
        );
    }

    renderCreate() {
        return (
            <div className='mpa-tags__create-tag'>
                <input
                    ref='createInput'
                    placeholder='Tag name'
                />
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        this.onCreate();
                    }}
                >
                    Create
                </button>
            </div>
        );
    }

    render() {
        return (
            <div>
                Tags
                {this.renderTags()}
                {this.renderCreate()}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(tagsActions, dispatch),
)(Tags);
