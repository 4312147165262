import * as request from 'request';

export function receiveFetchUserMarketingSources(marketingSources) {
    return {
        type: 'RECEIVE_FETCH_USER_MARKETING_SOURCES',
        marketingSources,
    };
}

export function fetchUserMarketingSources() {
    return dispatch => request.fetchAllUserMarketingSources()
        .then(resp => {
            dispatch(receiveFetchUserMarketingSources(resp.collection));
        });
}

export function requestCreateUserMarketingSource() {
    return { type: 'REQUEST_CREATE_USER_MARKETING_SOURCE' };
}

export function receiveCreateUserMarketingSource(marketingSource) {
    return {
        type: 'RECEIVE_CREATE_USER_MARKETING_SOURCE',
        marketingSource,
    };
}

export function createUserMarketingSourceFailed(message) {
    return {
        type: 'CREATE_USER_MARKETING_SOURCE_FAILED',
        message,
    };
}

export function addNewUserMarketingSource(marketingSource, countryCode) {
    return dispatch => {
        dispatch(requestCreateUserMarketingSource());
        return request.addNewUserMarketingSource(marketingSource, countryCode)
            .then(resp => {
                dispatch(receiveCreateUserMarketingSource(resp));
            }, error => {
                dispatch(createUserMarketingSourceFailed(error.responseJSON.message));
            });
    };
}

export function userMarketingSourceInactivated(id) {
    return {
        type: 'USER_MARKETING_SOURCE_INACTIVATED',
        id,
    };
}

export function inactivateUserMarketingSource(id) {
    return dispatch => request.inactivateUserMarketingSource(id)
        .then(resp => {
            dispatch(userMarketingSourceInactivated(id));
        }, error => {});
}

export function receiveUserOtherMarketingSources(sources) {
    return {
        type: 'RECEIVE_USER_OTHER_MARKETING_SOURCES',
        otherMarketingSources: sources,
    };
}

export function fetchUserOtherMarketingSources() {
    return dispatch => request.fetchUserOtherMarketingSources()
        .then(resp => {
            dispatch(receiveUserOtherMarketingSources(resp));
        });
}
