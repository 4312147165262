import base64 from 'base-64';
import config from 'config';
import $ from 'jquery';
import moment from 'moment';

const API_BASE_URL = config.api_url;

function authToken(xhr) {
    const token = localStorage.getItem('mpa-token');
    // console.log('MAH TOKEN', token);
    xhr.setRequestHeader('Authorization', `Basic ${btoa(`${token}:`)}`);
}

function onRequestFail(response) {
    console.log('ON REQUEST FAIL', arguments);

    if (response.status === 401) {
        localStorage.removeItem('mpa-token');
        // window.location.reload();
    }
}

function utoa(data) {
    return btoa(unescape(encodeURIComponent(data)));
}

function ajax({
    type = 'GET',
    url = '/',
    contentType = 'application/json',
    data = {},
    auth = authToken,
} = {}) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: API_BASE_URL + url,
            beforeSend: auth,
            type,
            contentType,
            data,

            success: response => {
                resolve(response);
            },

            error: response => {
                onRequestFail(response);
                reject(response);
            },

        });
    });
}

export function get(url, data, auth = authToken) {
    return ajax({
        type: 'GET',
        url,
        data,
        auth,
    });
}

export function post(url, data, auth = authToken) {
    return ajax({
        type: 'POST',
        url,
        data,
        auth,
    });
}

export function patch(url, data, auth = authToken) {
    return ajax({
        type: 'PATCH',
        url,
        data,
        auth,
    });
}

export function put(url, data, auth = authToken) {
    return ajax({
        type: 'PUT',
        url,
        data,
        auth,
    });
}

export function _delete(url, data, auth = authToken) {
    return ajax({
        type: 'DELETE',
        url,
        data,
        auth,
    });
}

export function login(username, password) {
    return get('/admin/token', null, xhr => {
        xhr.setRequestHeader('Authorization', `Basic ${utoa(`${username}:${password}`)}`);
    });
}

export const getUser = () => get('/v1/admin_user');

export const getAdminUsersV1 = () => get('/v1/admin_users');

export const updateAdminUserV1 = (adminId, requestData) => patch(`/v1/admin/admin_user/${adminId}`, JSON.stringify(requestData));

export const deleteAdminUserV1 = (adminId, requestData) => patch(`/v1/admin/admin_user/${adminId}`, JSON.stringify(requestData));

export const fetchApplications = () => get('/v4/admin/applications');

export const fetchEmployerUserApplications = (employerId, userId) => get(`/v4/admin/employeruserapplications/${employerId}/${userId}`);

export function fetchLtvApplications() {
    return get('/v1/admin/ltv_applications');
}

/** EMPLOYERS * */
export function fetchEmployers() {
    return get('/admin/employers');
}

export const fetchEmployersV2 = (
    testEmployer = 0,
    employerType,
    automotive,
    departmentTypeIds,
    countyIds,
    countryCode,
    marginConfigs,
    name,
    page,
    sort,
) => {
    const params = [
        page ? `page=${page}` : '',
        `test_employer=${+testEmployer}`,
        employerType ? `employer_type=${employerType}` : '',
        automotive !== undefined ? `automotive=${automotive}` : '',
        departmentTypeIds ? `department_type_ids=[${departmentTypeIds.toString()}]` : '',
        countyIds ? `county_ids=[${countyIds}]` : '',
        countryCode ? `country_code=${countryCode}` : '',
        marginConfigs ? `margin_config_ids=[${marginConfigs}]` : '',
        name ? `name=${name}` : '',
        sort ? `sort=${JSON.stringify(sort)}` : '',
    ].filter(param => !!param).join('&');
    return get(`/v2/admin/employers?${params}`);
};

export const fetchEmployersV3 = name => {
    const params = [
        name ? `name=${name}` : '',
    ].filter(param => !!param).join('&');
    return get(`/v2/admin/employers?${params}`);
};

export const fetchContacts = query => {
    const params = [
        query ? `query=${query}` : '',
    ].filter(param => !!param).join('&');
    return get(`/v2/admin/contacts?${params}`);
};

export function fetchEmployer(employer_id) {
    return get(`/v1/admin/employers/${employer_id}`);
}

export function fetchEmployersAllConsultants(employerId) {
    return get(`/v1/admin/employers/${employerId}/consultants`);
}
export const fetchEmployerConsultant = (employerId, consultantId) => (
    get(`/v1/admin/employers/${employerId}/consultants/${consultantId}`)
);

export function employerCheckShift(employer_id, requestData) {
    return post(`/v1/admin/employers/${employer_id}/check_shifts`, JSON.stringify(requestData));
}

export function employerCreateShift(employer_id, requestData) {
    return post(`/v1/admin/employers/${employer_id}/shifts`, JSON.stringify(requestData));
}

export function employerDeleteShifts(employer_id, requestData) {
    return _delete(`/v1/admin/employers/${employer_id}/shifts`, JSON.stringify(requestData));
}

export function deleteEmployerContact(employerId, contactId) {
    return _delete(`/v1/admin/employers/${employerId}/contacts/${contactId}`);
}

export function updateEmployerContact(employerId, contactId, requestData) {
    return patch(`/v1/admin/employers/${employerId}/contacts/${contactId}`, JSON.stringify(requestData));
}

export function createEmployerContact(employerId, requestData) {
    return post(`/v1/admin/employers/${employerId}/contacts`, JSON.stringify(requestData));
}

export function employerAddNewCredential(employerId, requestData) {
    return post(`/v1/admin/employers/${employerId}/users`, JSON.stringify(requestData));
}

export function updateEmployerCredential(id, requestData) {
    return patch(`/v1/admin/employer_users/${id}`, JSON.stringify(requestData));
}

export function deleteEmployerCredential(id) {
    return _delete(`/v1/admin/employer_users/${id}`);
}

export function fetchEmployerProfiles(employerId) {
    return get(`/v1/admin/employers/${employerId}/profiles`);
}

export function createEmployerProfile(employerId, jobProfile) {
    return post(`/v1/admin/employers/${employerId}/profiles`, JSON.stringify(jobProfile));
}

export function createEmployer(formData) {
    return put('/admin/employer', formData);
}

export function validateUsername(username) {
    return get(`/v1/admin/employer_users/username_available/${username}`);
}

export function updateEmployer(employerId, formData) {
    return patch(`/v1/admin/employer/${employerId}`, JSON.stringify(formData));
}

export function logOutEmployer(employerId) {
    return post(`/v1/admin/employer/${employerId}/logout`);
}

export function activateEmployer(employerId) {
    return post(`/v1/admin/employers/${employerId}/activate`);
}

export function copyEmployerCustomShifts(employerId, shiftIds) {
    return post(`/v1/admin/employers/${employerId}/copy_custom_shifts`, JSON.stringify({
        employer_id: employerId,
        custom_shifts_ids: shiftIds,
    }));
}

export function copyEmployerProfiles(employerId, profileIds) {
    return post(`/v1/admin/employers/${employerId}/copy_profiles`, JSON.stringify({
        employer_id: employerId,
        profile_ids: profileIds,
    }));
}

/** CONSULTANTS * */

export function resetConsultantPassword(id) {
    return post('/v1/admin/user/password/request_change', JSON.stringify({
        user_id: id,
    }));
}

export function updateConsultant(user_id, formData) {
    return patch(`/v1/admin/users/${user_id}`, JSON.stringify(formData));
}

export function updateSupplier(formData) {
    return post('/v1/admin/user/supplier', JSON.stringify(formData));
}

export function fetchSupplierByConsultantID(id) {
    return get(`/v1/admin/user/${id}/supplier`);
}

export const getLastActivePeriod = id => {
    const {
        minDays,
        maxDays,
    } = config.lastActive.find(p => p.id === id);
    return {
        gt: maxDays === Infinity ? 0 : moment().subtract(maxDays, 'days').unix(),
        lt: moment().subtract(minDays, 'days').unix(),
    };
};

export function fetchConsultantsV4(filters) {
    return get(`/v4/admin/users?filter=${base64.encode(JSON.stringify(filters))}`);
}

export function postConsultantsCallList(data) {
    return post('/v1/admin/intercom/create_tag_with_filter', JSON.stringify(data));
}

export function fetchEmployersV4(filters = null) {
    return get(`/v4/admin/employers?filter=${base64.encode(JSON.stringify(filters))}`);
}

export function fetchJobPostingTrackingV1(filters = null) {
    return get(`/v1/admin/job_tracking?filter=${base64.encode(JSON.stringify(filters))}`);
}

export function fetchJobPostingsByUserV1(userId) {
    return get(`/v4/admin/users/${userId}/job_postings_tracking`);
}

export function fetchConsultants(filters = null) {
    if (filters === null) return get('/v2/admin/users');
    const {
        page = null,
        sortField = null,
        sortOrder = null,
        excludeTags = [],
        includeTags = [],
        includeTestAccounts = false,
        lastActive = [],
        occupation = config.occupations.OCCUPATION_ALL,
        student = config.students.STUDENT_ALL,
        intentions = [],
        query = null,
        skills = [],
        specializations = [],
        geoDistance = null,
        pureSort = null,
        perPage = null,
        includeTopConsultants = null,
    } = filters;

    const params = [
        page ? `page=${page}` : '',
        sortField && !pureSort ? `sort=[{"${sortField}": "${sortOrder === 'ascend' ? 'asc' : 'desc'}"}]` : '',
        pureSort ? `sort=${JSON.stringify(pureSort)}` : '',
        occupation !== config.occupations.OCCUPATION_ALL ? `occupation_ids=[${occupation}]` : '',
        student !== config.students.STUDENT_ALL ? `student=[${student}]` : '',
        intentions.length ? `intention_ids=${JSON.stringify(intentions)}` : '',
        includeTestAccounts ? 'test_user=1' : '',
        excludeTags.length ? `exclude_tag_ids=${JSON.stringify(excludeTags)}` : '',
        includeTags.length ? `tag_ids=${JSON.stringify(includeTags)}` : '',
        skills.length ? `skill_ids=${JSON.stringify(skills)}` : '',
        specializations.length ? `specialization_ids=${JSON.stringify(specializations)}` : '',
        lastActive.length ? `last_active=${JSON.stringify(lastActive.map(id => getLastActivePeriod(id)))}` : '', // TODO
        geoDistance ? `geo_distance=${JSON.stringify(geoDistance)}` : '',
        perPage ? `per_page=${perPage}` : '',
        query ? `search_query=${query}` : '',
        includeTopConsultants ? `top_consultants=${JSON.stringify(includeTopConsultants)}` : '',
    ].filter(param => !!param).join('&');

    return get(`/v3/admin/users?${params}`);
}

export const fetchConsultant = userId => get(`/admin/users/${userId}`);

export const fetchConsultantSSN = userId => get(`/v1/admin/users/${userId}/ssn`);

export const fetchConsultantBookings = userId => get(`/v1/admin/users/${userId}/applications`);

export const fetchConsultantLtvApplications = userId => get(`/v1/admin/users/${userId}/ltv_applications`);

export const fetchUserComments = userId => get(`/v1/admin/users/${userId}/comments`);

export const fetchUserUploads = userId => get(`/v2/admin/users/${userId}/uploads`);

export const fetchUserWageTax = userId => get(`/v2/admin/users/${userId}/wagetax`);

export const postUserComment = (userId, requestData) => post(`/v1/admin/users/${userId}/comments`, JSON.stringify(requestData));

export const fetchEmployerComments = employerId => get(`/v1/admin/employers/${employerId}/comments`);

export const postEmployerComment = (employerId, requestData) => post(`/v1/admin/employers/${employerId}/comments`, JSON.stringify(requestData));

export const logoutConsultant = consultantId => post(`/v1/admin/user/${consultantId}/logout`);

export const unregisterConsultant = consultantId => _delete(`/v1/admin/users/${consultantId}`);

export const banConsultant = (consultantId, reason) => post('/v1/admin/user/ban', JSON.stringify({
    user_id: consultantId, reason,
}));
export const unBanConsultant = (consultantId, reason) => post('/v1/admin/user/un_ban', JSON.stringify({
    user_id: consultantId, reason,
}));

export const fetchConsultantHosp = consultantId => get(`/v1/admin/users/${consultantId}/hosp_checks`);

export const checkConsultantHosp = consultantId => post(`/v1/admin/users/${consultantId}/hosp`);

export const fetchMAUConsultantsData = month => get(`/v1/admin/data/consultant_booking_funnel/${month}/consultants`);

/** SET INTECOM TAGS * */
export const setTagInIntercom = data => post('/v1/admin/intercom/tags', JSON.stringify(data));

/** SHIFTS * */

export const updateCustomShift = (employerId, customShiftId, data) => patch(`/v1/admin/employers/${employerId}/custom_shifts/${customShiftId}`, JSON.stringify(data));

export const createCustomShift = (employerId, data) => post(`/v1/admin/employers/${employerId}/custom_shifts`, JSON.stringify(data));

export const updateJobProfile = (profileId, data) => patch(`/v1/admin/profiles/${profileId}`, JSON.stringify(data));

export const deleteJobProfile = profileId => _delete(`/v1/admin/profiles/${profileId}`);

/** SETTINGS * */
export const fetchSWESettings = () => get('/settings?country_code=swe');
export const fetchInternationalizedSettings = countryCode => get(`/settings?country_code=${countryCode}`);

/** EMPLOYER MARGINS * */
export function fetchEmployerMargins() {
    return get('/v1/admin/margin_configs');
}

export function fetchInvoices() {
    return get('/v1/admin/employer_invoices');
}

export const fetchEmployerAttests = () => get('/v1/admin/attests');

export function updateBookedShift(bookedConsultantId, requestData) {
    return patch(`/v1/admin/scheduled/${bookedConsultantId}`, JSON.stringify(requestData));
}

export function updateShift(shiftId, requestData) {
    return patch(`/v1/admin/shifts/${shiftId}`, JSON.stringify(requestData));
}

export function fetchShift(shiftId) {
    return get(`/v2/admin/shifts/${shiftId}`);
}

export function fetchEmployerShifts(employerId) {
    return get(`/v1/admin/employers/${employerId}/shifts`);
}

/* ACTIVITIES */
export function fetchActivities(types = '') {
    return get(`/v1/admin/activities?per_page=75${types}`);
}

export function fetchActivityTypes() {
    return get('/v1/admin/activity_types');
}

/** JOBS * */
export function fetchJobs() {
    return get('/v2/admin/jobs');
}

export function fetchJob(job_id) {
    return get(`/v1/admin/jobs/${job_id}`);
}

/** APPLICATIONS * */
export function applicationAddShift(application_id, requestData) {
    return post(`/v1/admin/applications/${application_id}/shifts`, JSON.stringify(requestData));
}

export function applicationRemoveShifts(application_id, requestData) {
    return _delete(`/v1/admin/applications/${application_id}/shifts`, JSON.stringify(requestData));
}

export function cancelConsultantFromSchedule({
    user_id, schedule_id, cancel_reason_id,
}) {
    return patch(`/v4/admin/scheduled/${schedule_id}/cancel`, JSON.stringify({
        user_id, cancel_reason_id,
    }));
}

export function createBookingForProfile(profileId, userId, shiftIds, applicationStatus = 'CONFIRMED') {
    return post('/v1/admin/bookings', JSON.stringify({
        user_id: userId,
        profile_id: profileId,
        shift_ids: shiftIds,
        status: applicationStatus,
    }));
}

export const fetchConsultantScheduled = userId => get(`/v1/admin/user/${userId}/scheduled`);

export function fetchApplication(applicationId) {
    return get(`/v1/admin/applications/${applicationId}`);
}

export const confirmApplication = (userId, employerId, scheduled) => post('/v4/admin/bookings', JSON.stringify({
    user_id: userId,
    employer_id: employerId,
    scheduled,
}));

export const denyApplication = (employerId, scheduled, deleteShifts) => _delete('/v4/admin/scheduled', JSON.stringify({
    employer_id: employerId,
    scheduled,
    delete_shifts: deleteShifts,
}));

export function confirmLtvApplication(applicationId) {
    return post(`/v1/admin/applications/${applicationId}/book`);
}

export function denyLtvApplication(application_id, reason_id) {
    return post(`/v1/admin/applications/${application_id}/deny`, JSON.stringify({
        application_id,
        reason_id,
    }));
}

export const batchUpdateScheduled = scheduled => patch('/v1/admin/scheduled_batch', JSON.stringify({ scheduled }));

/** EMPLOYER AVATAR * */

export function requestUploadAvatarURL(contentLength, employerId) {
    return post(`/v1/admin/employers/${employerId}/uploads/upload_url`, JSON.stringify({
        content_type: 'image/png',
        content_length: contentLength,

    }));
}

/** FAQ * */

export function fetchFaqs() {
    return get('/v2/admin/settings/faqs');
}

export function fetchFaqCategories() {
    return get('/v2/admin/faq_categories');
}

export function createFaq(data) {
    return post('/v2/admin/faqs', JSON.stringify(data));
}

export function updateFaq(id, data) {
    return patch(`/v2/admin/faqs/${id}`, JSON.stringify(data));
}

export function createFaqCategory(data) {
    return post('/v2/admin/faq_categories', JSON.stringify(data));
}

export function updateFaqCategory(id, data) {
    return patch(`/v2/admin/faq_categories/${id}`, JSON.stringify(data));
}

export function removeFaq(id) {
    return _delete(`/v2/admin/faqs/${id}`);
}

export function uploadAvatar(uploadUrl, contentLength, imageData) {
    return new Promise(resolve => {
        const dataURL = imageData;
        const blob = dataURItoBlob(dataURL);
        const formData = new FormData();
        // formData.append('token', token)
        formData.append('body', blob);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUrl, true);
        xhr.onload = xhr.onerror = function (response) {
            resolve(response);
        };

        xhr.send(blob);

        function dataURItoBlob(dataURI) {
            const byteString = atob(dataURI.split(',')[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);

            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ab], { type: 'image/png' });
        }
    });
}

export function verifyImageUpload(uploadId, employerId) {
    return patch(`/v1/admin/employers/${employerId}/uploads/${uploadId}`, JSON.stringify({}));
}

export function setAvatar(logoId, employerId) {
    return patch(`/v1/admin/employer/${employerId}`, JSON.stringify({ logo_id: logoId }));
}

/** EMPLOYER COVER IMAGE * */

export function requestUploadCoverURL(contentLength, employerId) {
    return post(`/v1/admin/employers/${employerId}/uploads/upload_url`, JSON.stringify({
        content_type: 'image/png',
        content_length: contentLength,
    }));
}

export function uploadCover(uploadUrl, contentLength, imageData) {
    return new Promise(resolve => {
        const dataURL = imageData;
        const blob = dataURItoBlob(dataURL);
        const formData = new FormData();
        // formData.append('token', token)
        formData.append('body', blob);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUrl, true);
        xhr.onload = xhr.onerror = function (response) {
            resolve(response);
        };

        xhr.send(blob);

        function dataURItoBlob(dataURI) {
            const byteString = atob(dataURI.split(',')[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ab], { type: 'image/png' });
        }
    });
}

export function setCover(coverId, employerId) {
    return patch(`/v1/admin/employer/${employerId}`, JSON.stringify({ cover_id: coverId }));
}

/** TAGS * */

export const fetchTags = () => get('/v1/admin/tags');

export function createTag(tagName) {
    return post('/v1/admin/tags', JSON.stringify({ tag: tagName }));
}

export function addTagToConsultant(consultantId, tagId) {
    return put(`/v1/admin/users/${consultantId}/tags/${tagId}`, JSON.stringify({}));
}

export function removeTagFromConsultant(consultantId, tagId) {
    return _delete(`/v1/admin/users/${consultantId}/tags/${tagId}`, JSON.stringify({}));
}

/** TASKS */
export const fetchTasks = () => get('/v1/admin/tasks');

export const postTask = requestData => post('/v1/admin/tasks', JSON.stringify(requestData));

export const updateTask = (taskId, requestData) => patch(`/v1/admin/tasks/${taskId}`, JSON.stringify(requestData));

/** INVOICE LIST * */

export function fetchInvoiceList() {
    return get('/admin/employers/invoice_material');
}

export function exportInvoiceList(employer_ids) {
    return post('/v1/admin/export_invoices', JSON.stringify({ employer_ids }));
}

/** SALARY LIST * */

export function fetchSalaryList() {
    return get('/admin/users/invoice_material');
}

export function exportSalaryList(user_ids) {
    return post('/v1/admin/export_salary_transactions', JSON.stringify({ user_ids }));
}

/** SALARY ERROR LIST * */

export function fetchSalaryErrorList() {
    return get('/v1/admin/salary_errors');
}

/** MARKETING SOURCES * */

export function fetchAllUserMarketingSources() {
    return get('/v1/admin/user_marketing_sources');
}

export function addNewUserMarketingSource(title, country_code) {
    return post('/v1/admin/user_marketing_source', JSON.stringify({
        title,
        country_code,
    }));
}

export function inactivateUserMarketingSource(id) {
    return _delete(`/v1/admin/user_marketing_sources/${id}`);
}

export function fetchUserOtherMarketingSources() {
    return get('/v1/admin/user_other_marketing_sources');
}

/** CONSULTANT TIME REPORTS * */
export function fetchConsultantTimeReports(consultantId) {
    return get(`/v1/admin/users/${consultantId}/time_reports`);
}

/** CONSULTANT SALARIES * */
export function fetchConsultantPayments(consultantId) {
    return get(`/v1/admin/users/${consultantId}/salaries`);
}

/** CONSULTANT CONTRACTS * */
export function fetchConsultantContracts(consultantId) {
    return get(`/v1/admin/users/${consultantId}/contracts`);
}

export function updateBookingPeriod(bookingPeriodId, data) {
    return patch(`/v1/admin/booking_period/${bookingPeriodId}`, JSON.stringify(data));
}

/** SICK LEAVE * */
export function fetchSickLeavedConsultants() {
    return get('/v1/admin/sick_reports');
}

export function fetchSickLeavedConsultant(consultantId) {
    return get(`/v1/admin/users/${consultantId}/sick_reports`);
}

/** CONSULTANT EMPLOYERS */
export function fetchConsultantEmployers(consultantId) {
    return get(`/v1/admin/users/${consultantId}/employers`);
}

export function updateConsultantEmployer(consultantId, employerId, requestData) {
    return put(`/v1/admin/users/${consultantId}/employers/${employerId}`, JSON.stringify(requestData));
}

/** CONSULTANT ACTIVITIES * */
export function fetchConsultantActivities(consultantId) {
    return get(`/v1/admin/users/${consultantId}/activities`);
}

/** DASHBOARD * */
export function fetchFunnelData(month) {
    return get(`/v1/admin/data/funnel/${month}`);
}

/** AVAILABLE SHIFTS * */
export function fetchAvailableShifts(requestData) {
    return get('/v1/admin/available_shifts');
}

/** RECRUITMENT VIEW * */
export function fetchRecruitments() {
    return get('/v1/admin/job_postings');
}

export function updateRecruitment(id, data) {
    return patch(`/v1/admin/job_postings/${id}`, JSON.stringify(data));
}

export function republishJobPosting(id) {
    return patch(`/v1/admin/job_postings/${id}/re-publish`);
}

export function createRecruitment(employerId, data) {
    return post('/v1/admin/job_postings', JSON.stringify({
        employer_id: employerId,
        ...data,
    }));
}

export function rejectRecruitmentApplication(id, reason_id) {
    return post(`/v1/admin/job_posting_applications/${id}/reject`, JSON.stringify({
        reason_id,
    }));
}

export function acceptRecruitmentApplication(id) {
    return post(`/v1/admin/job_posting_applications/${id}/accept`);
}

/** A SPECIFIC JOB ADS * */
export function fetchEmployerJobads(employerId) {
    return get(`/v1/admin/employers/${employerId}/job_postings`);
}

/** EMPLOYERS USERS * */
export function fetchEmployerUsers(employerId) {
    return get(`/v1/admin/employers/${employerId}/users`);
}

/** BOOKED SHIHFT FOR CALENDAR * */
export function fetchScheduled(startTime, endTime) {
    return get(`/v1/admin/scheduled?start_time=${startTime}&end_time=${endTime}`);
}

/** BENEFITS * */
export function fetchBenefits(args) {
    return get('/v1/admin/promotions', args);
}

export function addBenefit(benefit) {
    return put('/v1/admin/promotions', JSON.stringify({ ...benefit }));
}

export function updateBenefit(benefit) {
    return patch(`/v1/admin/promotions/${benefit.promotion_id}`, JSON.stringify({ ...benefit }));
}

export function requestBenefitLogoUploadURL(contentLength, contentType) {
    return post('/v1/admin/promotions/uploads/logo', JSON.stringify({
        content_type: contentType,
        content_length: contentLength,
    }));
}

/** ADMIN USER FILE UPLOAD * */
export function fetchAdminUserFileUploads(userId) {
    return get(`/v3/admin/users/${userId}/uploads`);
}

export function requestAdminUserFileUploadURL(userId, formData, contentLength, contentType) {
    return post(`/v3/admin/users/${userId}/uploads/upload_url`, JSON.stringify({
        ...formData,
        content_type: contentType,
        content_length: contentLength,
    }));
}

export function updateAdminUserFileUpload(formData, userId, uploaded = true) {
    return patch(`/v3/admin/users/${userId}/uploads/${formData.id}`, JSON.stringify({
        ...formData,
        uploaded,
    }));
}

export function deleteAdminUserFileUpload(userId, uploadId) {
    return _delete(`/v3/admin/users/${userId}/uploads/${uploadId}`);
}

export function uploadFileToBucket(blobFile, uploadUrl, method) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, uploadUrl, true);
        xhr.onload = response => {
            resolve(response);
        };
        xhr.onerror = error => {
            reject(error);
        };
        xhr.send(blobFile);
    });
}

/**  LTVS * */
export function getLongThermVacancies(includeTestAccounts) {
    return includeTestAccounts ? get('/v1/admin/ltvs?test_employer=1') : get('/v1/admin/ltvs');
}

export function getSingleLongThermVacancies(id) {
    return get(`/v1/admin/ltvs/${id}`);
}

export function getJobById(id) {
    return get(`/v1/admin/jobs/${id}/bookings`);
}

/** PROCUREMENTS * */
export function getProcurements() {
    return get('/v1/admin/procurements');
}

export function updateProcurement(id, data) {
    return patch(`/v1/admin/procurements/${id}`, JSON.stringify(data));
}

export const getAvailableConsultants = requestData => get('/v1/admin/available_consultants', `shift_times=${JSON.stringify(requestData.shift_times)}`);

export function createLead(form) {
    return post('/v1/admin/leads', JSON.stringify(form));
}

export function getLeads() {
    return get('/v1/admin/leads');
}

export function convertToEmployer(id) {
    return post(`/v1/admin/leads/${id}/convert`);
}

export function getAds() {
    return get('/v1/admin/ads');
}

export function createAd(form) {
    return post('/v1/admin/create_external_ad', JSON.stringify(form));
}

export function getOrdersV1(args) {
    return get('/v1/admin/orders', args);
}

export function getOrderV1(id) {
    return get(`/v1/admin/orders/${id}`);
}

export function updateOrderShifts(orderId, shifts) {
    return patch(`/v1/admin/orders/${orderId}/shifts`, JSON.stringify(shifts));
}

export function updateOrderShift(orderId, shiftId, shift) {
    return patch(`/v1/admin/orders/${orderId}/shifts/${shiftId}`, JSON.stringify(shift));
}

export function updateOrder(orderId, data) {
    return patch(`/v1/admin/orders/${orderId}`, JSON.stringify(data));
}
