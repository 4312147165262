import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

const CheckRow = ({
    title,
    disabled,
    checked,
    onChange,
    key = '',
}) => (
    <div
        role='button'
        style={{
            padding: 5,
            cursor: disabled ? 'auto' : 'pointer',
        }}
        tabIndex={0}
        onChange={() => {
            if (!disabled) {
                onChange(key);
            }
        }}
        onKeyPress={() => null}
    >
        <Checkbox
            checked={checked}
            disabled={disabled}
            style={{ marginRight: 5 }}
        />
        {title}
    </div>
);

CheckRow.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    key: PropTypes.string.isRequired,
};

export default CheckRow;
