import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    groupBy,
    times,
} from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchScheduledList } from 'actions/scheduled';
import moment from 'moment';
import LoadingScreen from 'components/shared/loadingscreen';
import { humanReadableHours } from 'utils/datetimeformatters';

import './styles.scss';

const Calendar = ({
    scheduled, fetchScheduledList, hasFetched, isFetching,
}) => {
    const startDay = moment().startOf('day').startOf('week');
    const weeks = [
        startDay.unix(),
        startDay.clone().add(1, 'week').unix(),
        startDay.clone().add(2, 'weeks').unix(),
        startDay.clone().add(3, 'weeks').unix(),
        startDay.clone().add(4, 'weeks').unix(),
        startDay.clone().add(5, 'weeks').unix(),
        startDay.clone().add(6, 'weeks').unix(),
        startDay.clone().add(7, 'weeks').unix(),
        startDay.clone().add(8, 'weeks').unix(),
    ];
    useEffect(() => {
        if (!hasFetched && !isFetching) { fetchScheduledList(startDay.unix(), weeks[weeks.length - 1]); }
    }, [scheduled, fetchScheduledList]);

    const renderDayBookings = bookings => {
        if (!bookings) {
            return ('');
        }

        return bookings.map(booking => {
            const {
                user, employer,
            } = booking;

            return (
                <div style={{ marginTop: 5 }}>
                    <Link to={`/consultants/${user.id}`}>
                        <div
                            style={{
                                display: 'flex', alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 600, fontSize: 13,
                                }}
                            >
                                {user.firstname}
                                {' '}
                                {user.lastname}
                            </div>
                        </div>
                    </Link>
                    <div style={{ fontSize: 11 }}>

                        <div>
                            {moment.unix(booking.start_time).format('HH:mm')}
                            {' '}
                            -
                            {' '}
                            {moment.unix(booking.end_time).format('HH:mm')}
                            {' '}
                            | Rast:
                            {' '}
                            {humanReadableHours(booking.shift.shift_break)}
                        </div>
                        <div />
                        <div>
                            {employer.name}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const renderWeekDays = (week, bookings) => {
        const nodes = [];
        const m = moment.unix(week);

        times(7, () => {
            const date = m.format('ddd D/M');
            let dayBookings = [];
            if (bookings && bookings.length) {
                dayBookings = bookings.filter(s => moment.unix(s.start_time).isSame(m, 'day'));
            }
            nodes.push(
                <div
                    style={{
                        flex: 1,
                        minHeight: 150,
                        background: '#f9f9f9',
                        margin: 1,
                        padding: 10,
                    }}
                >
                    <div
                        style={{
                            fontSize: 12,
                            color: '#999',
                        }}
                    >
                        {date}
                    </div>
                    <div>
                        {renderDayBookings(dayBookings)}
                    </div>
                </div>,
            );
            m.add(1, 'day');
        });

        return nodes;
    };

    const renderWeek = (week, bookings) => (
        <div style={{ marginBottom: 20 }}>
            <div
                style={{
                    fontWeight: 600,
                    marginBottom: 5,
                }}
            >
                Week
                {' '}
                {moment.unix(week).week()}
            </div>
            <div
                style={{ display: 'flex' }}
            >
                {renderWeekDays(week, bookings)}
            </div>
        </div>
    );
    const renderWeeks = () => {
        const weeklyScheduled = groupBy(
            scheduled, b => moment.unix(b.start_time).startOf('day').startOf('week').unix(),
        );
        return (
            <div>
                {weeks.map(week => renderWeek(week, weeklyScheduled[week]))}
            </div>
        );
    };

    const renderContent = () => {
        if (!hasFetched && !isFetching) {
            return (
                <LoadingScreen
                    fullScreen
                    text='Me cago en la mar salada'
                />
            );
        }
        return renderWeeks();
    };

    return (
        <>
            <main className='mpa-calendar row justify-content-between'>
                <div className='col-md-12'>
                    <h2>Calendar</h2>
                </div>
                <div className='col-md-12 content-card-full' style={{ marginTop: 50 }}>
                    {renderContent()}
                </div>
            </main>
        </>
    );
};

Calendar.propTypes = {
    scheduled: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        start_time: PropTypes.number.isRequired,
        end_time: PropTypes.number.isRequired,
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstname: PropTypes.string.isRequired,
            lastname: PropTypes.string.isRequired,
        }).isRequired,
        shift: PropTypes.shape({
            id: PropTypes.number.isRequired,
            shift_break: PropTypes.number.isRequired,
        }).isRequired,
    })).isRequired,
    fetchScheduledList: PropTypes.func.isRequired,
    hasFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    scheduled: state.scheduled.scheduled,
    hasFetched: state.scheduled.hasFetched,
    isFetching: state.scheduled.isFetching,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ fetchScheduledList }, dispatch),
)(Calendar);
