const HOLIDAYS = {
    '2017-01-01': 'Nyårsdagen',
    '2017-01-06': 'Trettondagen',
    '2017-04-14': 'Långfredagen',
    '2017-04-16': 'Påskdagen',
    '2017-04-17': 'Annandag påsk',
    '2017-05-01': 'Första maj',
    '2017-05-25': 'Kristi himmelsfärdsdag',
    '2017-06-06': 'Sveriges nationaldag',
    '2017-06-24': 'Midsommardagen',
    '2017-11-04': 'Alla helgons dag',
    '2017-12-25': 'Juldagen',
    '2017-12-26': 'Annandag jul',
    '2018-01-01': 'Nyårsdagen',
    '2018-01-06': 'Trettondagen',
    '2018-03-30': 'Långfredagen',
    '2018-04-01': 'Påskdagen',
    '2018-04-02': 'Annandag påsk',
    '2018-05-01': 'Första maj',
    '2018-05-10': 'Kristi himmelsfärdsdag',
    '2018-06-06': 'Sveriges nationaldag',
    '2018-06-23': 'Midsommardagen',
    '2018-11-03': 'Alla helgons dag',
    '2018-12-24': 'Julafton',
    '2018-12-25': 'Juldagen',
    '2018-12-26': 'Annandag jul',
    '2018-12-31': 'Nyårsafton',
    '2019-01-01': 'Nyårsdagen',
    '2019-01-06': 'Trettondagen',
    '2019-04-19': 'Långfredagen',
    '2019-04-21': 'Påskdagen',
    '2019-04-22': 'Annandag påsk',
    '2019-05-01': 'Första maj',
    '2019-05-30': 'Kristi himmelsfärdsdag',
    '2019-06-06': 'Sveriges nationaldag',
    '2019-06-22': 'Midsommardagen',
    '2019-11-02': 'Alla helgons dag',
    '2019-12-24': 'Julafton',
    '2019-12-25': 'Juldagen',
    '2019-12-26': 'Annandag jul',
    '2019-12-31': 'Nyårsafton',
    '2020-01-01': 'Nyårsdagen',
    '2020-01-06': 'Trettondagen',
    '2020-04-10': 'Långfredagen',
    '2020-04-12': 'Påskdagen',
    '2020-04-13': 'Annandag påsk',
    '2020-05-01': 'Första maj',
    '2020-05-21': 'Kristi himmelsfärdsdag',
    '2020-06-06': 'Sveriges nationaldag',
    '2020-06-20': 'Midsommardagen',
    '2020-10-31': 'Alla helgons dag',
    '2020-12-24': 'Julafton',
    '2020-12-25': 'Juldagen',
    '2020-12-26': 'Annandag jul',
    '2020-12-31': 'Nyårsafton',
    '2021-01-01': 'Nyårsdagen',
    '2021-01-06': 'Trettondagen',
    '2021-04-02': 'Långfredagen',
    '2021-04-04': 'Påskdagen',
    '2021-04-05': 'Annandag påsk',
    '2021-05-01': 'Första maj',
    '2021-05-13': 'Kristi himmelsfärdsdag',
    '2021-06-06': 'Sveriges nationaldag',
    '2021-06-26': 'Midsommardagen',
    '2021-11-06': 'Alla helgons dag',
    '2021-12-24': 'Julafton',
    '2021-12-25': 'Juldagen',
    '2021-12-26': 'Annandag jul',
    '2021-12-31': 'Nyårsafton',
    '2022-01-01': 'Nyårsdagen',
    '2022-01-06': 'Trettondagen',
    '2022-04-15': 'Långfredagen',
    '2022-04-17': 'Påskdagen',
    '2022-04-18': 'Annandag påsk',
    '2022-05-01': 'Första maj',
    '2022-05-26': 'Kristi himmelsfärdsdag',
    '2022-06-06': 'Sveriges nationaldag',
    '2022-06-25': 'Midsommardagen',
    '2022-11-05': 'Alla helgons dag',
    '2022-12-24': 'Julafton',
    '2022-12-25': 'Juldagen',
    '2022-12-26': 'Annandag jul',
    '2022-12-31': 'Nyårsafton',
    '2023-01-01': 'Nyårsdagen',
    '2023-01-06': 'Trettondagen',
    '2023-04-07': 'Långfredagen',
    '2023-04-09': 'Påskdagen',
    '2023-04-10': 'Annandag påsk',
    '2023-05-01': 'Första maj',
    '2023-05-18': 'Kristi himmelsfärdsdag',
    '2023-06-06': 'Sveriges nationaldag',
    '2023-06-24': 'Midsommardagen',
    '2023-11-04': 'Alla helgons dag',
    '2023-12-24': 'Julafton',
    '2023-12-25': 'Juldagen',
    '2023-12-26': 'Annandag jul',
    '2023-12-31': 'Nyårsafton',
    '2024-01-01': 'Nyårsdagen',
    '2024-01-06': 'Trettondagen',
    '2024-03-29': 'Långfredagen',
    '2024-03-31': 'Påskdagen',
    '2024-04-01': 'Annandag påsk',
    '2024-05-01': 'Första maj',
    '2024-05-09': 'Kristi himmelsfärdsdag',
    '2024-06-06': 'Sveriges nationaldag',
    '2024-06-22': 'Midsommardagen',
    '2024-11-02': 'Alla helgons dag',
    '2024-12-24': 'Julafton',
    '2024-12-25': 'Juldagen',
    '2024-12-26': 'Annandag jul',
    '2024-12-31': 'Nyårsafton',
    '2025-01-01': 'Nyårsdagen',
    '2025-01-06': 'Trettondagen',
    '2025-04-18': 'Långfredagen',
    '2025-04-20': 'Påskdagen',
    '2025-04-21': 'Annandag påsk',
    '2025-05-01': 'Första maj',
    '2025-05-29': 'Kristi himmelsfärdsdag',
    '2025-06-06': 'Sveriges nationaldag',
    '2025-06-21': 'Midsommardagen',
    '2025-11-01': 'Alla helgons dag',
    '2025-12-24': 'Julafton',
    '2025-12-25': 'Juldagen',
    '2025-12-26': 'Annandag jul',
    '2025-12-31': 'Nyårsafton',
};

export function isHoliday(date) {
    return HOLIDAYS[date];
}
