import React, {
    useState,
    useReducer,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as consultantActions from 'actions/consultants';
import * as consultantsFilterActions from 'actions/consultantsfilter';
import LoadingScreen from 'components/shared/loadingscreen';
import ConsultantsSidebar from 'containers/consultantssidebar';
import ConsultantsFilter from './consultantsfilter';
import SearchBar from '../../components/searchbar';
import ConsultantsList from './ConsultantsList';

import './styles.scss';

// Reducer for local state.
//
// The reason to have it instead of useState is that some state variables should be batch-changed.
// E.g. changing search query requires changing page number at the same time.
// If this is done by two separate setQuery and setPage, useEffect is triggered two times,
// which means two consecutive API calls to fetch the data.
function reducer(state, action) {
    switch (action.type) {
        case 'SET_PAGE':
            return {
                ...state,
                page: action.page,
            };
        case 'SET_PAGE_SORTING': {
            const isStartSorting = (!state.sortField && action.sortField);
            const isModifySorting = (state.sortField
                && (action.sortField !== state.sortField || action.sortOrder !== state.sortOrder));

            return {
                ...state,
                sortField: action.sortField,
                sortOrder: action.sortOrder,
                // reset page on sorting start or on sorting criteria change
                page: isStartSorting || isModifySorting ? 0 : action.page,
            };
        }
        case 'SET_QUERY':
            return {
                ...state,
                query: action.query,
                page: 0, // reset page number on search query update
            };
        default:
            throw new Error(); // unknown action type
    }
}

const Consultants = ({
    consultants,
    consultantsPagination,
    fetchConsultants,
    hasConsultantsFetched,
    consultantsFilter,
}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarData, setSidebarData] = useState({});

    // Initialize local state.
    // Note! dispatch and reducer are local functions, have nothing to do with Redux.
    const [state, dispatch] = useReducer(reducer, {
        page: 0,
        query: '',
        sortField: null,
        sortOrder: null,
    });

    const { totalCount } = consultantsPagination;

    useEffect(() => {
        const filters = {
            page: state.page,
            query: state.query,
            sortField: state.sortField,
            sortOrder: state.sortOrder,
            ...consultantsFilter,
        };

        fetchConsultants(filters);
    }, [
        state.page, state.query, state.sortField, state.sortOrder, fetchConsultants, consultantsFilter,
    ]);

    // Callback executed when page number or sorting criteria is changed
    const onTableChange = (pagination, _, sorter) => {
        dispatch({
            type: 'SET_PAGE_SORTING',
            sortField: sorter.field || null,
            sortOrder: sorter.order || null,
            page: pagination.current,
        });
    };

    const initTableRow = record => ({ onClick: () => {
        if (sidebarOpen && sidebarData.id === record.id) {
            setSidebarOpen(false);
        }
        else {
            setSidebarOpen(true);
            setSidebarData(record);
        }
    } });

    const onSearchQueryChange = q => {
        dispatch({
            type: 'SET_QUERY',
            query: q,
        });
    };
    return (
        <>
            <SearchBar
                placeHolder='Search consultants...'
                onChange={onSearchQueryChange}
            />
            { hasConsultantsFetched ? (
                <main className='consultants'>
                    <div className='consultants__wrapper'>
                        <ConsultantsFilter hidden={state.query} />

                        <div className={`consultants__content ${state.query ? 'consultants__content--query' : ''}`}>
                            <h3>
                                {state.query
                                    ? `${totalCount} ${
                                        totalCount === 1 ? 'consultant' : 'consultants'
                                    } match "${state.query}"`
                                    : `Consultants (${totalCount})`}
                            </h3>

                            <ConsultantsList
                                consultants={consultants}
                                pagination={consultantsPagination}
                                onChange={onTableChange}
                                onRow={initTableRow}
                            />
                        </div>
                    </div>
                    <ConsultantsSidebar
                        consultantId={sidebarData.id}
                        open={sidebarOpen}
                        onClose={() => setSidebarOpen(false)}
                    />
                </main>
            ) : <LoadingScreen fullScreen text='Kasta inte spjut i radhus!' /> }
        </>
    );
};

Consultants.propTypes = {
    consultants: PropTypes.arrayOf(PropTypes.shape({})),
    consultantsPagination: PropTypes.shape({ totalCount: PropTypes.number }),
    fetchConsultants: PropTypes.func,
    hasConsultantsFetched: PropTypes.bool,

    consultantsFilter: PropTypes.shape({
        excludeTags: PropTypes.arrayOf(PropTypes.number),
        includeTags: PropTypes.arrayOf(PropTypes.number),
        includeTestAccounts: PropTypes.bool,
        includeTopConsultants: PropTypes.bool,
        lastActive: PropTypes.arrayOf(PropTypes.number),
        occupation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        query: PropTypes.string,
        skills: PropTypes.arrayOf(PropTypes.number),
        specializations: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
};

const mapStateToProps = state => ({
    consultants: state.consultants.consultants,
    consultantsPagination: {
        page: state.consultants.page,
        totalCount: state.consultants.totalCount,
        totalPages: state.consultants.totalPages,
    },
    hasConsultantsFetched: state.consultants.hasFetched,
    consultantsFilter: state.consultantsFilter,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        ...consultantActions,
        ...consultantsFilterActions,
    }, dispatch),
)(Consultants);
