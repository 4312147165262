import * as request from 'request';
import { message } from 'antd';

export function receiveSalaryList(resp) {
    return {
        type: 'RECEIVE_SALARY_LIST',
        salaryList: resp.users,
    };
}

export function requestFetchSalaryList() {
    return { type: 'REQUEST_FETCH_SALARY_LIST' };
}

export function fetchSalaryList() {
    return dispatch => {
        dispatch(requestFetchSalaryList());

        return request.fetchSalaryList()
            .then(resp => dispatch(receiveSalaryList(resp)));
    };
}

export function requestExportSalaryList() {
    return { type: 'REQUEST_EXPORT_SALARY_LIST' };
}

export function receiveExportSalaryList(userIds) {
    return {

        type: 'RECEIVE_EXPORT_SALARY_LIST',
        userIds,

    };
}

export function exportSalaryList(userIds) {
    return dispatch => {
        dispatch(requestExportSalaryList);

        return request.exportSalaryList(userIds).then(() => {
            dispatch(receiveExportSalaryList(userIds));
            message.success(`${userIds.length} löneexporter genomfördes`);
        });
    };
}
