import * as request from 'request';

export function requestAds() {
    return { type: 'REQUEST_FETCH_ADS' };
}

export function recieveResponseAd(data) {
    return {
        type: 'RECEIVE_RESPONSE_AD',
        data
    };
}

export function requestCreateAd() {
  return { type: 'REQUEST_CREATE_AD' };
}

export function createAd(form) {
    return dispatch => {
        dispatch(requestCreateAd());
        return request.createAd(form)
            .then(resp => dispatch(recieveResponseAd(resp)));
    };
}

export function getAds() {
  return dispatch => {
      dispatch(requestAds());
      return request.getAds()
          .then(resp => dispatch(recieveAds(resp)));
  };
}

export function convertToEmployer(id) {
    return dispatch => {
        return request.convertToEmployer(id)
            .then(resp => dispatch(recieveAds(resp)));
    };
  }
