import { Button } from 'antd';
import React from 'react';
// import './styles.scss';

export default class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isSaving: false };
    }

    renderButton(option) {
        return (
            <div>
                <Button
                    className={`btn ${option.className}`}
                    type='primary'
                    onClick={() => {
                        option.action();
                        this.props.close();
                    }}
                >
                    {option.text}
                </Button>
            </div>
        );
    }

    renderOptions(options) {
        return options.map(option => {
            switch (option.type) {
                case 'button':
                    return this.renderButton(option);
            }
        });
    }

    render() {
        return (
            <div
                style={{ padding: 20 }}
            >
                <p>{this.props.text}</p>
                {this.renderOptions(this.props.options)}
            </div>
        );
    }
}
