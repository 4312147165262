import React from 'react';
import classNames from 'utils/classnames';
import { withFormsy } from 'formsy-react';
import Label from 'components/shared/label';
import './styles.scss';
import { Select } from 'antd';

class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(value) {
        this.props.setValue(value);
    }

    renderLabel() {
        if (this.props.label) {
            return (
                <Label
                    {...this.props}
                />
            );
        }
    }

    render() {
        return (
            <>
                {this.renderLabel()}
                <Select
                    allowClear
                    className={classNames({
                        'mp-input__wrapper': true,
                        'mp-input__wrapper--block': this.props.block,
                        [this.props.wrapperClass]: true,
                    })}
                    defaultActiveFirstOption={false}
                    mode='multiple'
                    name={this.props.name}
                    notFoundContent={null}
                    optionFilterProp='title'
                    placeholder={this.props.name}
                    showArrow={false}
                    showSearch
                    size='large'
                    value={this.props.value}
                    onChange={this.changeValue}
                >
                    {this.props.options}
                </Select>
            </>
        );
    }
}

export default withFormsy(MultiSelect);
