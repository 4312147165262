import React from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const SelectedConsultant = ({
    consultant,
    removeConsultant,
    isPreselected,
}) => (
    <div
        style={{
            position: 'relative',
            paddingRight: 50,
            marginTop: 5,
        }}
    >
        {!isPreselected
            && (
                <div
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}
                >
                    <Button
                        danger
                        icon={<CloseOutlined />}
                        shape='circle'
                        size='small'
                        onClick={removeConsultant}
                    />
                </div>
            )}
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 0',
            }}
        >
            <div>
                <Avatar
                    src={consultant.avatar}
                    style={{ marginRight: 10 }}
                >
                    { consultant.avatar ? consultant.firstname[0] + consultant.lastname[0] : ''}
                </Avatar>
            </div>
            <div>
                <div
                    style={{
                        fontWeight: '600',
                        fontSize: 14,
                    }}
                >
                    {consultant.firstname}
                    {' '}
                    {consultant.lastname}
                </div>
                <div style={{ fontSize: 12 }}>
                    ID:
                    {' '}
                    {consultant.id}
                </div>
            </div>
        </div>
        {' '}
    </div>
);

SelectedConsultant.propTypes = {
    consultant: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        avatar: PropTypes.string,
    }),
    removeConsultant: PropTypes.func.isRequired,
    isPreselected: PropTypes.bool.isRequired,
};

export default SelectedConsultant;
