import {
    CaretDownOutlined,
    CloseOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Dropdown,
    Input,
    InputNumber,
    Menu,
    Select,
    Switch,
    Table,
    Tag,
} from 'antd';
import {
    find,
    sortBy,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isHoliday } from 'utils/holidays';
import './styles.scss';

import ConsultantSelector from './consultantselector';
import CopyButton from './copybutton';
import SelectedConsultant from './selectedconsultant';
import ShiftMarginDropdown from './shiftmargindropdown';

const ShiftsList = ({
    shifts,
    removeShift,
    updateShift,
    rejectedShifts,
    profiles,
    updateAllShifts,
    searchConsultants,
    consultants,
    toggleDeleteId,
    preselectedProfileId = null,
    preselectedConsultant = null,
    customShifts = [],
}) => {
    const columns = [{
        key: 'date',
        dataIndex: 'date',
        title: 'Date',
        render: (date, shift) => {
            const m = moment.unix(date);
            const dateText = m.format('YYYY-MM-DD');
            const weekday = m.format('ddd');
            const isWeekend = m.isoWeekday() >= 6;
            const holiday = isHoliday(dateText);
            const isDuplicated = rejectedShifts.duplicated.includes(shift.id);
            return (
                <div
                    style={{
                        paddingBottom: 18,
                        width: 130,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {dateText}
                        {' '}
                        <Tag
                            color={(isWeekend || holiday) ? 'red' : null}
                            style={{ marginLeft: 5 }}
                        >
                            {weekday}

                        </Tag>

                    </div>
                    {shift.outOfRange && (
                        <Tag
                            color='orangered'
                            style={{ marginTop: 5 }}
                        >
                            OUT OF RANGE
                        </Tag>
                    )}
                    {isDuplicated && (
                        <Tag
                            color='orangered'
                            style={{ marginTop: 5 }}
                        >
                            DUPLICATED SHIFT
                        </Tag>
                    )}
                </div>
            );
        },
    }, {
        key: 'profile_id',
        dataIndex: 'profile_id',
        title: 'Profile',
        render: (profile_id, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Select
                                className={!profile_id && !preselectedProfileId ? 'mpa-add-shifts-list__input-error' : ''}
                                disabled={preselectedProfileId}
                                dropdownMatchSelectWidth={false}
                                placeholder='Choose profile'
                                value={preselectedProfileId || profile_id || 0}
                                onChange={selectedProfileId => {
                                    updateShift(shift.id, 'profile_id', selectedProfileId);
                                }}
                            >
                                <Select.Option disabled value={0}>
                                    Select profile
                                </Select.Option>
                                {profiles.map(profile => (
                                    <Select.Option
                                        key={profile.id}
                                        value={profile.id}
                                    >
                                        #
                                        {profile.id}
                                        :
                                        {' '}
                                        {profile.name}
                                        {profile.is_long_term && <Tag style={{ marginLeft: 5 }}>LTV</Tag>}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('profile_id', profile_id)}
                            />
                        </div>
                    </div>
                );
            }
            const profile = find(profiles, { id: shift.profile_id });
            return (
                <div>
                    #
                    {shift.profile_id}
                    :
                    {profile.name}
                    {profile.is_long_term && <Tag style={{ marginLeft: 5 }}>LTV</Tag>}
                </div>
            );
        },
    }, {
        key: 'start_time',
        dataIndex: 'start_time',
        title: 'Start',
        render: (start_time, shift) => {
            if (shift.new) {
                const menu = (
                    <Menu
                        onClick={({ key: customShiftId }) => {
                            const cs = find(customShifts, { id: +customShiftId });
                            updateShift(shift.id, {
                                start_time: cs.start_time,
                                end_time: cs.end_time,
                                price: cs.price,
                                shift_break: cs.shift_break,
                            });
                        }}
                    >
                        {customShifts.map(cs => (
                            <Menu.Item key={cs.id}>
                                {cs.start_time}
                                {' '}
                                -
                                {' '}
                                {cs.end_time}
                                {' '}
                                /
                                {' '}
                                {cs.shift_break}
                                {' '}
                                /
                                {' '}
                                {cs.price}
                            </Menu.Item>
                        ))}
                    </Menu>
                );
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <div
                            style={{
                                display: 'flex', width: 80,
                            }}
                        >
                            <Input
                                className={!start_time ? 'mpa-add-shifts-list__input-error' : ''}
                                value={start_time}
                                onChange={e => {
                                    updateShift(shift.id, 'start_time', e.target.value);
                                }}
                            />
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button
                                    icon={<CaretDownOutlined />}
                                />
                            </Dropdown>
                        </div>
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('start_time', start_time)}
                            />
                        </div>
                    </div>
                );
            }
            return start_time;
        },
    }, {
        key: 'end_time',
        dataIndex: 'end_time',
        title: 'End',
        render: (end_time, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <div
                            style={{
                                display: 'flex', width: 60,
                            }}
                        >
                            <Input
                                className={!end_time ? 'mpa-add-shifts-list__input-error' : ''}
                                value={end_time}
                                onChange={e => {
                                    updateShift(shift.id, 'end_time', e.target.value);
                                }}
                            />
                        </div>

                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('end_time', end_time)}
                            />
                        </div>
                    </div>
                );
            }
            return end_time;
        },
    }, {
        key: 'last_application_datetime',
        dataIndex: 'last_application_datetime',
        title: 'Last',
        render: (last_application_datetime, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <div
                            style={{
                                display: 'flex', width: 150,
                            }}
                        />
                        <DatePicker
                            disabledDate={current => current && (
                                current.isBefore(moment(), 'day')
                                    || current.isAfter(moment.unix(shift.date), 'day')
                            )}
                            format='YYYY-MM-DD'
                            showTime={false}
                            value={last_application_datetime ? moment.unix(last_application_datetime) : null}
                            onChange={date => {
                                const timestamp = date ? date.startOf('day').unix() : null;
                                updateShift(shift.id, 'last_application_datetime', timestamp);
                            }}
                        />
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('last_application_datetime', last_application_datetime)}
                            />
                        </div>
                    </div>
                );
            }
            return last_application_datetime;
        },
    },
    {
        key: 'shift_break',
        dataIndex: 'shift_break',
        title: 'Break',
        render: (shift_break, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <InputNumber
                            min={0}
                            value={shift_break}
                            onChange={e => {
                                updateShift(shift.id, 'shift_break', e || 0);
                            }}
                        />
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('shift_break', shift_break)}
                            />
                        </div>
                    </div>
                );
            }
            return shift_break;
        },
    }, {
        key: 'price',
        dataIndex: 'price',
        title: 'Price',
        render: (price, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <div
                            style={{
                                display: 'flex', width: 80,
                            }}
                        >
                            <Input
                                className={!price ? 'mpa-add-shifts-list__input-error' : ''}
                                value={price}
                                onChange={e => {
                                    updateShift(shift.id, 'price', +e.target.value);
                                }}
                            />
                        </div>
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('price', price)}
                            />
                        </div>
                    </div>
                );
            }
            return price;
        },
    }, {
        key: 'margin',
        dataIndex: 'margin',
        title: 'Salary margin',
        render: (margin, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <ShiftMarginDropdown
                            value={margin}
                            onChange={val => updateShift(shift.id, 'margin', val)}
                        />
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('margin', margin)}
                            />
                        </div>
                    </div>
                );
            }
            return `${margin}%`;
        },
    }, {
        key: 'consultant_fee_margin',
        dataIndex: 'consultant_fee_margin',
        title: 'Invoice margin',
        render: (consultant_fee_margin, shift) => {
            if (shift.new) {
                return (
                    <div className='mpa-add-shifts-list__cell'>
                        <ShiftMarginDropdown
                            value={consultant_fee_margin}
                            onChange={val => updateShift(shift.id, 'consultant_fee_margin', val)}
                        />
                        <div className='mpa-add-shifts-list__copy' tabIndex='-1'>
                            <CopyButton
                                onClick={() => updateAllShifts('consultant_fee_margin', consultant_fee_margin)}
                            />
                        </div>
                    </div>
                );
            }
            return `${shift.consultant_fee_margin}%`;
        },
    }, {
        key: 'consultant',
        dataIndex: 'consultant',
        title: 'Consultant',
        render: (consultant, shift) => {
            if (!consultant && !preselectedConsultant && shift.new) {
                return (
                    <div
                        style={{ width: 250 }}

                    >
                        <ConsultantSelector
                            consultants={consultants}
                            onSearch={searchConsultants}
                            onSelect={id => {
                                updateShift(shift.id, 'consultant', find(consultants, { id: +id }));
                            }}
                        />
                    </div>
                );
            }
            if (consultant || preselectedConsultant) {
                const isConsultantBusy = rejectedShifts.booked.includes(shift.id);
                const consultantClassName = `mpa-add-shifts-list__cell${isConsultantBusy ? '--error' : ''}`;
                return (
                    <div
                        className={consultantClassName}
                        style={{ width: 250 }}
                    >
                        <SelectedConsultant
                            consultant={consultant || preselectedConsultant}
                            isPreselected={!!preselectedConsultant || shift.hasBooking}
                            removeConsultant={() => {
                                updateShift(shift.id, 'consultant', null);
                            }}
                        />
                        {isConsultantBusy ? (
                            <p className='mpa-add-shifts-list__cell--error-message'>
                                {' '}
                                Consultant is already booked at this time!
                            </p>
                        ) : (
                            <div className='mpa-add-shifts-list__copy'>
                                <CopyButton
                                    onClick={() => updateAllShifts('consultant', consultant)}
                                />
                            </div>
                        )}
                    </div>
                );
            }
            return null;
        },
    }, {
        key: 'change',
        dataIndex: 'change',
        title: 'Change',
        render: (text, shift) => {
            let tag;
            if (shift.new) {
                tag = <Tag color='green'>Add</Tag>;
            }
            else if (shift.shouldDelete) {
                tag = <Tag color='red'>Delete</Tag>;
            }
            else {
                tag = <Tag>Unchanged</Tag>;
            }
            return (
                <div style={{ marginBottom: 20 }}>
                    {tag}
                </div>
            );
        },
    }, {
        title: 'Delete',
        key: 'delete',
        dataIndex: 'shouldDelete',
        render: (value, shift) => {
            if (!shift.new && !shift.hasBooking) {
                return (
                    <Button
                        danger
                        ghost={!value}
                        icon={<DeleteOutlined />}
                        size='small'
                        style={{ marginBottom: 20 }}
                        onClick={() => {
                            toggleDeleteId(shift.id);
                        }}
                    />
                );
            }
            return null;
        },
    }, {
        title: 'Show salary',
        key: 'show_salary',
        dataIndex: '',
        render: (_, shift) => (
            <div
                style={{
                    display: 'flex', width: 80,
                }}
            >
                <Switch
                    checked={!shift.is_price_hidden || false}
                    onChange={() => {
                        updateShift(shift.id, 'is_price_hidden', !shift.is_price_hidden);
                    }}
                />
            </div>
        ),
    },
    {
        key: 'remove',
        dataIndex: 'remove',
        render: (value, shift) => {
            if (shift.new) {
                return (
                    <Button
                        icon={<CloseOutlined />}
                        shape='circle'
                        size='small'
                        style={{ marginBottom: 20 }}
                        onClick={() => {
                            removeShift(shift.id);
                        }}
                    />
                );
            }
            return null;
        },
    }];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={sortBy(shifts, s => s.date)}
                pagination={false}
                rowClassName={rowShift => {
                    // Some new shifts are colliding with each other
                    const isRowDuplicated = rejectedShifts.duplicated.includes(rowShift.id);
                    const isValid = shift => shift.start_time && shift.end_time && (shift.profile_id || preselectedProfileId) && shift.price && !isRowDuplicated;

                    if (rowShift.shouldDelete) {
                        return 'mpa-add-shifts-list__error-row--booked';
                    }

                    if (!isValid(rowShift)) {
                        return 'mpa-add-shifts-list__error-row--error';
                    }

                    return '';
                }}
                scroll={{ x: 1000 }}
                size='middle'
            />
        </div>
    );
};

ShiftsList.propTypes = {
    shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeShift: PropTypes.func.isRequired,
    updateShift: PropTypes.func.isRequired,
    profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateAllShifts: PropTypes.func.isRequired,
    searchConsultants: PropTypes.func.isRequired,
    consultants: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleDeleteId: PropTypes.func.isRequired,
    preselectedProfileId: PropTypes.number,
    preselectedConsultant: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        avatar: PropTypes.string,
    }),
    rejectedShifts: PropTypes.shape({
        booked: PropTypes.arrayOf(PropTypes.string).isRequired,
        duplicated: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    customShifts: PropTypes.arrayOf(PropTypes.object),
};

export default ShiftsList;
