import React, { useEffect } from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { fetchConsultantWageTax } from 'actions/consultant';
import {
    Divider,
    Badge,
} from 'antd';
import PropTypes from 'prop-types';
import { statusColor } from '../../../utils/nameformatters';

export default function WageTax() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { wageTax } = useSelector(state => state.consultant.consultant);

    useEffect(() => {
        dispatch(fetchConsultantWageTax(id));
    }, [dispatch, id]);

    const WageTaxCard = ({ wageTaxData }) => {
        const {
            id,
            addressline1,
            addressline2,
            postalcode,
            city,
            applicable_from,
            apply_tax_deduction,
            signed_time,
        } = wageTaxData;

        return (
            <div>
                <Divider
                    orientation='left'
                >
                    {`ID ${id} - Applicable from ${moment(applicable_from).format('YYYY-MM-DD')}`}
                </Divider>
                <div>
                    <div>
                        Apply tax deduction:
                        {' '}
                        {apply_tax_deduction ? 'Yes' : 'No'}
                    </div>
                    <ul>
                        <li>{addressline1}</li>
                        {addressline2 ? `, <li>${addressline2}</li>` : ''}
                        <li>
                            {postalcode}
                            {' '}
                            {city}
                        </li>

                    </ul>
                    <Badge
                        color={statusColor(signed_time ? 'activated' : 'pending')}
                        text={signed_time ? `Signed${moment(signed_time).format('YYYY-MM-DD')}` : 'Not signed'}
                    />
                </div>
            </div>
        );
    };

    WageTaxCard.propTypes = { wageTaxData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        addressline1: PropTypes.string.isRequired,
        addressline2: PropTypes.string,
        postalcode: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        applicable_from: PropTypes.string.isRequired,
        apply_tax_deduction: PropTypes.bool.isRequired,
        signed_time: PropTypes.string,
    }).isRequired };

    return (
        <div>
            {wageTax && wageTax.map(wageTaxData => (<WageTaxCard wageTaxData={wageTaxData} />))}
        </div>
    );
}
