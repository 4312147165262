import {
    filter,
    includes,
    without,
} from 'lodash';

const initialState = {
    applications: [],
    ltvApplications: [],
    applicationsFetching: true,
    hasFetched: false,
    applicationIsFetching: false,
    application: null,
    isPatching: false,
    hasPatchedApplication: false,
    removingShiftsFromBookedApplication: false,
    isConfirmingLtvApplication: false,
    isDenyingLtvApplication: false,
};

export const applications = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_APPLICATIONS':
        case 'REQUEST_FETCH_LTV_APPLICATIONS':
            return {
                ...state, applicationsFetching: true,
            };

        case 'RECEIVE_APPLICATIONS':
            return {
                ...state,
                applications: action.applications,
                applicationsFetching: false,
                hasFetched: true,
            };

        case 'RECEIVE_LTV_APPLICATIONS':
            return {
                ...state,
                ltvApplications: action.applications,
                applicationsFetching: false,
                hasFetched: true,
            };

        case 'REQUEST_FETCH_APPLICATION':
            return {
                ...state, applicationIsFetching: true,
            };

        case 'RECEIVE_APPLICATION':
            return {
                ...state,
                application: action.application,
                applicationIsFetching: false,
            };

        case 'RESET_APPLICATION':
            return {
                ...state, application: null,
            };

        case 'REMOVE_SHIFT_FROM_CONSULTANT_APPLICATION_SUCCESS':
            return {
                ...state,
                applications: state.applications.map(a => {
                    if (a.id === action.applicationId) {
                        return {
                            ...a,
                            shifts: filter(a.shifts, s => !includes(action.shiftId, s)),
                        };
                    }

                    return a;
                }),
            };

        case 'REQUEST_COMMENT_APPLICATIONS':
        case 'REQUEST_CONFIRM_APPLICATION':
            return {
                ...state,
                isPatching: true,
                hasPatchedApplication: false,
            };

        case 'REQUEST_DENY_APPLICATION':
            return {
                ...state,
                isPatching: true,
                hasPatchedApplication: false,
            };

        case 'COMMENT_APPLICATIONS_SUCCESS':
            return {
                ...state,
                isPatching: false,
                hasPatchedApplication: true,
            };

        case 'CONFIRM_APPLICATION_SUCCESS':
        case 'DENY_APPLICATION_SUCCESS':
            return {
                ...state,
                isPatching: false,
                hasPatchedApplication: true,
                applications: state.applications.reduce((filteredApplications, application) => {
                    const scheduled = application.scheduled.filter(
                        shift => !action.scheduled.some(deniedShift => deniedShift.id === shift.id),
                    );

                    if (scheduled.length > 0) {
                        filteredApplications.push({
                            ...application,
                            scheduled,
                        });
                    }

                    return filteredApplications;
                }, []),
            };

        case 'REQUEST_CONFIRM_LTV_APPLICATION':
            return {
                ...state,
                isConfirmingLtvApplication: true,
            };

        case 'REQUEST_DENY_LTV_APPLICATION':
            return {
                ...state,
                isDenyingLtvApplication: true,
            };

        case 'CONFIRM_LTV_APPLICATION_SUCCESS':
        case 'DENY_LTV_APPLICATION_SUCCESS':
            return {
                ...state,
                ltvApplications: filter(state.ltvApplications, a => a.id !== action.applicationId),
                isDenyingLtvApplication: false,
                isConfirmingLtvApplication: false,
            };

        case 'REQUEST_REMOVE_SHIFTS_FROM_BOOKED_APPLICATION':
            return {
                ...state, removingShiftsFromBookedApplication: true,
            };

        case 'REMOVE_SHIFTS_FROM_BOOKED_APPLICATION_SUCCESS':
            if (!state.application) {
                return { ...state };
            }
            return {
                ...state,
                application: {
                    ...state.application,
                    scheduled: state.application.scheduled.filter(scheduled => {
                        if (action.shiftIds.indexOf(scheduled.shift_id) !== -1) {
                            return false;
                        }

                        return true;
                    }),
                },
                removingShiftsFromBookedApplication: false,
            };

        case 'SET_HAS_PATCHED_APPLICATION':
            return {
                ...state,
                hasPatchedApplication: action.hasPatchedApplication,
            };
        default:
            return state;
    }
};

export default applications;
