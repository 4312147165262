import React from 'react';
import {
    Modal,
    Button,
} from 'antd';
import PropTypes from 'prop-types';

const DeleteAdminUserModal = ({
    visible, setVisibility, user, onDelete,
}) => (
    <Modal
        footer={[
            <Button key='back' onClick={() => setVisibility(false)}>
                Cancel
            </Button>,
            <Button
                key='submit'
                danger
                type='primary'
                onClick={() => {
                    onDelete(user.id);
                    setVisibility(false);
                }}
            >
                Delete
            </Button>,
        ]}
        title='Delete Admin User'
        visible={visible}
        onCancel={() => setVisibility(false)}
    >
        <p>
            Are you sure you want to delete
            {' '}
            {user?.username}
            ?
        </p>
    </Modal>
);

DeleteAdminUserModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
    user: PropTypes.object,
    onDelete: PropTypes.func.isRequired,
};

export default DeleteAdminUserModal;
