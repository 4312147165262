import {
    Modal,
    Radio,
    Space,
    Input,
} from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const RejectJobApplicationModal = ({
    onConfirm,
    visible,
    setVisibility,
    applicant,
    onReject,
}) => {
    const settings = useSelector(state => state.settings);
    const [value, setValue] = useState(1);

    const onOk = () => {
        onConfirm(value);
    };

    return (
        <Modal
            okText='Reject'
            okType='Danger'
            title='Reject applicant'
            visible={visible}
            onCancel={() => setVisibility(false)}
            onOk={onOk}
        >
            <p>
                Select a reason for rejecting
                {' '}
                <strong>
                    {applicant.firstname}
                    {' '}
                    {applicant.lastname}
                </strong>
                {' '}
                for this recruitment
            </p>
            <Radio.Group value={value} onChange={e => { setValue(e.target.value); }}>
                <Space direction='vertical'>
                    {settings.job_posting_reject_reasons.map(r => (
                        <Radio value={r.id}>{r.message}</Radio>
                    ))}

                </Space>
            </Radio.Group>

        </Modal>
    );
};

export default RejectJobApplicationModal;
