import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as invoicesActions from 'actions/invoices';

class Invoices extends React.Component {
    constructor(props) {
        super(props);
        this.renderInvoice = this.renderInvoice.bind(this);
    }

    componentDidMount() {
        this.props.fetchInvoices();
    }

    renderInvoice(invoice) {
        return (
            <tr>
                <td>{invoice.id}</td>
                <td>
                    {invoice.firstname}
                    {' '}
                    {invoice.lastname}
                </td>
                <td>{invoice.email}</td>
                <td>{moment.unix(invoice.created).format()}</td>
                <td>{invoice.state}</td>
                <td>
                    <button className='btn btn btn-success'>
                        <Link to={`/invoices/${invoice.id}`}>
                            Visa
                            {' '}
                            <i className='ion-chevron-right' />
                        </Link>
                    </button>
                </td>
            </tr>
        );
    }

    renderInvoices() {
        const invoices = this.props.consultants.consultants;
        if (invoices.length) {
            return (
                <table className='mpa-invoices__list table'>
                    <thead>
                        <th>ID</th>
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Created</th>
                        <th>State</th>
                        <th />
                    </thead>
                    <tbody>
                        {invoices.map(this.renderInvoice)}
                    </tbody>
                </table>
            );
        }
    }

    render() {
        return (
            <div className='mpa-employers row justify-content-between'>
                <div className='col-md-6'>
                    <h2>Invoices</h2>
                </div>
                <div className='col-md-12 pt-4'>
                    {this.renderInvoices()}
                </div>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(invoicesActions, dispatch),
)(Invoices);
