import React from 'react';
import './styles.scss';

export default class LoadingScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className='mpa-loading-screen'
                style={{
                    height: this.props.fullScreen ? '100vh' : 'auto',
                    flexDirection: 'column',
                    display: 'flex',
                    ...this.props.style,
                }}
            >
                <div>
                    {this.props.text
                        ? (
                            <h1
                                style={{
                                    textAlign: 'center',
                                    width: 450,
                                }}
                            >
                                {this.props.text}
                            </h1>
                        )
                        : null}
                </div>
                <div className='spinner-container'>
                    <i className='fa fa-circle-o-notch fa-spin' />
                </div>
            </div>
        );
    }
}
