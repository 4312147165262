import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AntdInputModal from '../../../../../components/antdinputmodal';
import config from '../../../../../config';
import {
    startEndStringTimesToMoment,
    startEndTimeToDuration,
    unixToTimeString,
} from '../../../../../utils/datetimeformatters';
import { getMarginOptions } from '../../../../../utils/margins';
import './styles.scss';

function getShiftsOptions(shifts) {
    return shifts.map(customShift => {
        const {
            start_time,
            end_time,
            price,
            shift_break,
        } = customShift;
        const title = `${start_time} - ${end_time} / ${shift_break} / ${price}`;
        const fields = [
            {
                name: 'start_time',
                value: start_time,
            },
            {
                name: 'end_time',
                value: end_time,
            },
            {
                name: 'price',
                value: price,
            },
            {
                name: 'shift_break',
                value: shift_break,
            },
        ];
        return {
            title,
            fields,
        };
    });
}

function getProfilesOptions(profiles) {
    return profiles.filter(profile => !profile.is_long_term).map(profile => {
        const {
            id,
            name,
        } = profile;
        return ({
            value: id,
            name: (
                <div>
                    {`#${id}: ${name}`}
                </div>
            ),
        });
    });
}

const EditShiftModal = props => {
    const {
        checkShifts,
        close,
        customShifts,
        shift,
        visible,
        saveShift,
        profiles,
    } = props;
    const marginOptions = getMarginOptions();
    const profilesOptions = getProfilesOptions(profiles);
    const newShift = !shift ? {} : {
        profile_id: shift.profile.id,
        ...shift,
    };
    const date = moment.unix(newShift.start_time).startOf('day');
    const [isConsultantBusy, setIsConsultantBusy] = useState(false);
    const customShiftsOptions = getShiftsOptions(customShifts);

    return (
        <AntdInputModal
            className='edit-shifts-modal'
            inputs={[
                {
                    type: 'date',
                    name: 'date',
                    label: 'Date',
                    value: date,
                },
                {
                    type: 'select',
                    name: 'profile_id',
                    label: 'Profile',
                    value: newShift.profile_id,
                    options: profilesOptions,
                    valueType: 'number',
                },
                {
                    type: 'input',
                    name: 'start_time',
                    label: 'Start time',
                    value: unixToTimeString(newShift.start_time),
                    options: customShiftsOptions,
                }, {
                    type: 'input',
                    name: 'end_time',
                    label: 'End time',
                    value: unixToTimeString(newShift.end_time),
                }, {
                    type: 'date',
                    name: 'last_application_datetime',
                    label: 'Last application date',
                    value: moment.unix(newShift.last_application_datetime).startOf('day'),
                }, {
                    type: 'switch',
                    name: 'is_price_hidden',
                    label: 'Show salary on app',
                    value: !newShift.is_price_hidden,
                    valueType: 'boolean',
                }, {
                    type: 'number',
                    name: 'shift_break',
                    label: 'Break',
                    value: newShift.shift_break,
                }, {
                    type: 'number',
                    name: 'price',
                    label: 'Price',
                    value: newShift.price,
                }, {
                    type: 'select',
                    name: 'margin',
                    label: 'Salary margin',
                    value: newShift.margin,
                    options: marginOptions,
                    valueType: 'number',
                    disabled: true,
                }, {
                    type: 'select',
                    name: 'consultant_fee_margin',
                    label: 'Invoice margin',
                    value: newShift.consultant_fee_margin,
                    options: marginOptions,
                    valueType: 'number',
                    disabled: true,
                },
            ]}
            visible={visible}
            onCancel={() => close()}
            onChange={() => {
                if (isConsultantBusy) {
                    setIsConsultantBusy(false);
                }
            }}
            onSave={formData => {
                const {
                    date: newDate,
                    ...rest
                } = formData;

                const { shiftStatus } = config;
                const isBooked = newShift.shift_status === shiftStatus.BOOKED;

                const {
                    startTime,
                    endTime,
                } = startEndStringTimesToMoment(newDate, formData.start_time, formData.end_time);

                const start_time = startTime.unix();
                const end_time = endTime.unix();
                const hasApplications = newShift.scheduled && !!newShift.scheduled.length;
                const scheduled = hasApplications ? newShift.scheduled : [];
                const applicationsSummary = scheduled.map(sch => ({
                    consultantId: sch.user.id,
                    status: sch.status,
                }));
                const isPriceHidden = !formData.is_price_hidden;

                const formattedData = {
                    id: newShift.id,
                    start_time,
                    end_time,
                    status: newShift.shift_status,
                    duration: startEndTimeToDuration(start_time, end_time),
                    margin: Number(formData.margin),
                    consultant_fee_margin: Number(formData.consultant_fee_margin),
                    applicationsSummary,
                    is_price_hidden: isPriceHidden,
                    last_application_datetime: formData.last_application_datetime.unix(),
                };

                const newData = {
                    ...rest,
                    ...formattedData,
                };

                if (isBooked && applicationsSummary.length) {
                    // checkShifts(employerId, consultantId, shiftData)
                    checkShifts(newShift.employer.id, applicationsSummary[0].consultantId, [newData])
                        .then(() => {
                            saveShift(newData);
                            close();
                        })
                        .catch(() => {
                            // We assume that the only error that is coming is that the consultant is busy,
                            // since there cannot be colliding new shifts (we're only sending one)
                            setIsConsultantBusy(true);
                        });
                }
                else {
                    saveShift(newData);
                    close();
                }
            }}
        >
            <p className='warning'>
                Warning: The Shift ID will change with this editing.
            </p>
            {isConsultantBusy && (
                <p className='error'>
                    Consultant is already booked on this time and day. Please, change the details and try again.
                </p>
            )}
        </AntdInputModal>
    );
};

EditShiftModal.propTypes = {
    customShifts: PropTypes.arrayOf(PropTypes.shape({
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        price: PropTypes.number,
        shift_break: PropTypes.number,
    })).isRequired,
    checkShifts: PropTypes.func.isRequired,
    shift: PropTypes.shape({
        id: PropTypes.number,
        start_time: PropTypes.number,
        end_time: PropTypes.number,
        profile: PropTypes.shape({ id: PropTypes.number }),
        shift_status: PropTypes.number,
        is_price_hidden: PropTypes.bool,
        last_application_datetime: PropTypes.number,
    }).isRequired,
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    saveShift: PropTypes.func.isRequired,
    profiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        is_long_term: PropTypes.bool,
    })).isRequired,
};

export default EditShiftModal;
