import React from 'react';
import classNames from 'utils/classnames';
import './styles.scss';

class Avatar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            size, url, hideRating, className, gender,
        } = this.props;

        const image = url;

        const style = {
            width: size,
            height: size,
            borderRadius: size,
        };

        if (url) {
            style.backgroundImage = `url(${url})`;
        }
        return (
            <div
                className={classNames({
                    'mpa-avatar': true,
                    'mpa-avatar--female': !gender,
                    'mpa-avatar--male': gender,
                    [className]: true,
                })}
                style={({
                    ...style, ...this.props.style,
                })}
            >
                {this.props.children}
            </div>
        );
    }
}

Avatar.defaultProps = {
    size: 60,
    className: '',
    url: null,
    style: {},
};

export default Avatar;
