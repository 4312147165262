import * as request from 'request';
import { message } from 'antd';

export function requestFetchRecruitments() {
    return { type: 'REQUEST_FETCH_RECRUITMENTS' };
}

export function receiveRecruitments(resp) {
    return {
        type: 'RECEIVE_RECRUITMENTS',
        job_postings: resp.collection,
    };
}

export function fetchRecruitments() {
    return dispatch => {
        dispatch(requestFetchRecruitments());
        return request.fetchRecruitments()
            .then(resp => dispatch(receiveRecruitments(resp)));
    };
}

export function requestFetchRecruitment() {
    return { type: 'REQUEST_FETCH_RECRUITMENT' };
}

export function receiveRecruitment(resp) {
    return {
        type: 'RECEIVE_RECRUITMENT',
        job_posting: resp.job_posting,
    };
}

export function requestUpdateRecruitment() {
    return {
        type: 'REQUEST_UPDATE_RECRUITMENT',
    };
}

export function updateRecruitmentSuccess(id, data) {
    return {
        type: 'UPDATE_RECRUITMENT_SUCCESS',
        id,
        data,
    };
}

export function updateRecruitment(id, data) {
    return dispatch => {
        dispatch(requestUpdateRecruitment());
        return request.updateRecruitment(id, data)
            .then(resp => dispatch(updateRecruitmentSuccess(id, data)));
    };
}

export function requestRepublishJobPosting() {
    return {
        type: 'REQUEST_REPUBLISH_JOB_POSTING',
    };
}

export function republishJobPostingSuccess(updatedJobPost) {
    return {
        type: 'UPDATE_REPUBLISH_JOB_POSTING_SUCCESS',
        updatedJobPost
    };
}

export function republishJobPosting(id) {
    return dispatch => {
        dispatch(requestRepublishJobPosting());
        return request.republishJobPosting(id)
            .then(resp => dispatch(republishJobPostingSuccess(resp)));
    };
}
export function createRecruitmentSuccess(employerId, data) {
    return {
        type: 'CREATE_RECRUITMENT_SUCCESS',
        employerId,
        data,
    };
}

export function createRecruitment(employerId, data) {
    return dispatch => request.createRecruitment(employerId, data)
        .then(resp => dispatch(createRecruitmentSuccess(employerId, resp)));
}

export function rejectRecruitmentApplicationSuccess(id) {
    return {
        type: 'REJECT_RECRUITMENT_APPLICATION_SUCCESS',
        id,
    };
}

export function rejectRecruitmentApplication(id, reason_id) {
    return dispatch => request.rejectRecruitmentApplication(id, reason_id)
        .then(resp => dispatch(rejectRecruitmentApplicationSuccess(id)));
}

export function acceptRecruitmentApplicationSuccess(id) {
    return {
        type: 'ACCEPT_RECRUITMENT_APPLICATION_SUCCESS',
        id,
    };
}

export function acceptRecruitmentApplication(id) {
    return dispatch => request.acceptRecruitmentApplication(id)
        .then(resp => dispatch(acceptRecruitmentApplicationSuccess(id)));
}
