const initialState = {
    tags: [],
    hasFetched: false,
};

export function tags(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_FETCH_TAGS':
            return {
                tags: action.tags,
                hasFetched: true,
            };

        case 'RECEIVE_CREATE_TAG':
            return { tags: [...state.tags, action.tag] };

        default:
            return state;
    }
}
