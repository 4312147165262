import React from 'react';
import {
    Table,
    Button,
} from 'antd';
import {
    filter,
    sortBy,
} from 'lodash';
import moment from 'moment';

export default class DeleteUnbookedShiftsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            selectedIds: [],
            selectedRowKeys: [],
            success: false,

        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.visible && !nextProps.visible) {
            this.reset();
        }
    }

    reset() {
        this.setState({

            selectedIds: [],
            selectedRowKeys: [],
            success: false,

        });
    }

    renderSuccess() {
        const { success } = this.state;

        if (success) {
            return (

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '40px 0',
                    }}
                >

                    <h2>
                        The shifts have been deleted
                    </h2>
                    <div>

                        <Button
                            ghost
                            size='large'
                            style={{ marginTop: 20 }}
                            type='primary'
                            onClick={() => {
                                this.props.close();
                            }}
                        >
                            Close
                        </Button>

                    </div>
                </div>

            );
        }
    }

    render() {
        if (!this.props.employerShifts) {
            return null;
        }

        if (this.state.success) {
            return this.renderSuccess();
        }

        const columns = [{
            title: 'Week',
            dataIndex: 'week',
            key: 'week',
            render: (text, record) => `w.${moment.unix(record.start_time).week()}`,
        }, {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => moment.unix(record.start_time).format('YYYY-MM-DD'),
        }, {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (text, record) => `${moment.unix(record.start_time).format('HH:mm')} - ${moment.unix(record.end_time).format('HH:mm')}`,
        }, {
            title: 'Break',
            dataIndex: 'shift_break',
            key: 'shift_break',
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        }, {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        }];

        const filteredShifts = filter(
            this.props.employerShifts, s => s.start_time > moment().format('X') && !s.scheduled.length
            && !s.applications.length,
        );

        const rowSelection = {

            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedIds: selectedRows.map(r => r.id),
                });
            },

            getCheckboxProps: record => ({ name: record.name }),
        };

        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={sortBy(filteredShifts, 'start_time')}
                    pagination={false}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 20,
                    }}
                >
                    <Button
                        disabled={!this.state.selectedIds.length}
                        size='large'
                        type='primary'
                        onClick={() => {
                            this.props.removeEmployerShifts(this.props.employer.id, this.state.selectedIds).then(() => {
                                this.setState({ success: true });
                            });
                        }}
                    >
                        Delete all selected
                    </Button>
                </div>
            </div>
        );
    }
}
