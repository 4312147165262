import React, {
    useEffect,
    useState,
} from 'react';
import {
    Modal,
    Button,
    Select,
    Form,
    Input,
    Switch,
    DatePicker,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormInput from '../shared/formInput';
import {
    requestBenefitLogoUploadURL,
    uploadFileToBucket,
} from '../../request';

import './styles.scss';

const BenefitsModal = ({
    modalConfig,
    onClose,
    onSave,
}) => {
    const { Option } = Select;
    const emptyFormData = {
        blurb: '',
        blurb_prefix: '',
        promo_code: '',
        company_name: '',
        hide_link: false,
        id: '',
        logo: '',
        logoFile: '',
        text: '',
        link: '',
        country_code: 'SWE',
        paused: false,
    };
    const [benefitData, setBenefitData] = useState({ emptyFormData });
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [hideLink, setHideLink] = useState(false);
    const [paused, setPaused] = useState(false);

    const [form] = Form.useForm();

    const uploadFile = file => {
        setShowLogo(false);
        const {
            type,
            size,
            name,
        } = file;

        requestBenefitLogoUploadURL(size, type)
            .then(res => {
                const blobFile = file;
                const formData = new FormData();
                formData.append('body', blobFile);

                setBenefitData({
                    ...benefitData,
                    logo: res.logo_url,
                    logoFile: name,
                });

                uploadFileToBucket(blobFile, res.upload_url, 'PUT')
                    .then(response => {
                        setUploadCompleted(true);
                        setShowLogo(true);
                    })
                    .catch(err => {
                        setUploadCompleted(true);
                        setShowLogo(true);
                    });
            })
            .catch(error => { console.error('error2', error); });
    };

    useEffect(() => {
        Object.keys(benefitData).forEach(key => {
            const value = benefitData[key];
            if (value || typeof value === 'boolean') {
                form.setFieldsValue({ [key]: value });
            }
        });
    }, [benefitData, form]);

    const validateMessages = {
        required: '${label} is required',
        types: { email: 'Please enter a valid email address' },
    };

    const {
        mode,
        benefit,
        isVisible,
    } = modalConfig;

    useEffect(() => {
        if (mode === 'edit') {
            const {
                id,
                start_datetime,
                end_datetime,
                logo,
                paused,
                hide_link,
            } = benefit;
            setBenefitData({
                ...emptyFormData,
                ...benefit,
                promotion_id: id,
                start_time: moment(start_datetime),
                end_time: end_datetime && moment(end_datetime),
            });
            setHideLink(hide_link);
            setPaused(paused);
            setShowLogo(!!logo);
        }
    }, []);

    const saveBenefit = data => {
        const newBenefit = {
            ...data,
            start_time: data.start_time.unix(),
            end_time: data.end_time ? data.end_time.unix() : null,
            logo: benefitData.logo,
            hide_link: hideLink,
            paused,
        };
        delete newBenefit.logoFile;
        onSave(newBenefit);
        setBenefitData(emptyFormData);
    };

    return (
        <Modal
            key='xo'
            footer={null}
            title={mode === 'edit' ? `Update ${benefitData.company_name}` : 'New benefit'}
            visible={isVisible}
            width='75%'
            onCancel={onClose}
        >
            {showLogo
                && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            aspectRatio: '160 / 100',
                            borderRadius: 5,
                            objectFit: 'cover',
                            backgroundColor: '#111',
                            padding: 50,
                        }}
                    >
                        <img
                            alt='Company logo'
                            src={benefitData.logo}
                            style={{
                                width: 300,
                                maxWidth: '100%',
                                maxHeight: 300 * 0.625,
                                objectFit: 'scale-down',
                            }}
                        />
                    </div>
                )}
            <Form
                className='form'
                form={form}
                initialValues={{ start_time: moment() }}
                labelCol={{ span: 8 }}
                name='benefit-form'
                validateMessages={validateMessages}
                wrapperCol={{ span: 16 }}
                onFinish={saveBenefit}
            >
                { benefitData.promotion_id
                    && (
                        <FormInput
                            formDataKey='promotion_id'
                            rules={[{ required: true }]}
                            type='hidden'
                        />
                    )}
                <FormInput
                    formDataKey='company_name'
                    label='Company name'
                    rules={[{ required: true }]}
                />
                <Form.Item
                    // className='form-item -inline'
                    label='Start Date'
                    name='start_time'
                    rules={[{ required: true }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    // className='form-item -inline'
                    label='End Date'
                    name='end_time'
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    // className='form-item -inline'
                    label='Country'
                    name='country_code'
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder='Select country'
                        size='large'
                    >

                        <Option key='SWE' value='SWE'>Sweden</Option>
                        <Option key='NLD' value='NLD'>Nederland</Option>

                    </Select>
                </Form.Item>
                <FormInput
                    formDataKey='promo_code'
                    isInline
                    label='Code'

                />
                <Form.Item
                    // className='form-item'
                    label='Text'
                    name='text'
                    rules={[{ required: true }]}
                >
                    <Input.TextArea
                        autoSize={{ minRows: 4 }}
                        label='Text'
                        name='text'

                    />
                </Form.Item>
                <FormInput
                    formDataKey='blurb'
                    isInline
                    label='Blurb'

                />
                <FormInput
                    formDataKey='blurb_prefix'
                    isInline
                    label='Blurb Prefix'

                />
                <FormInput
                    formDataKey='link'
                    label='Url'
                    // rules={[{ required: true }]}

                />
                <Form.Item
                    // className='form-item'
                    label='Hide link'
                    name='hide_link'
                >
                    <Switch
                        checked={hideLink}
                        onChange={() => setHideLink(!hideLink)}
                    />
                </Form.Item>
                <Form.Item
                    // className='form-item'
                    label='Is paused?'
                    name='paused'
                >
                    <Switch
                        checked={paused}
                        onChange={() => setPaused(!paused)}
                    />
                </Form.Item>
                <Form.Item
                    label={mode === 'edit' ? 'Change logo file' : 'Logo'}
                    name='logoFile'
                    rules={[{ required: true }]}
                >
                    <input
                        type='file'
                        onChange={e => uploadFile(e.target.files[0])}
                    />
                    {uploadCompleted && <div style={{ color: 'green' }}>File uploaded!</div>}
                </Form.Item>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        danger
                        style={{ marginRight: 5 }}
                        type='ghost'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button htmlType='submit' icon={<SaveOutlined />} type='primary'>
                        Save
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

BenefitsModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    modalConfig: PropTypes.shape({
        isVisible: PropTypes.bool.isRequired,
        mode: PropTypes.string.isRequired,
        benefit: PropTypes.shape({
            attachment: PropTypes.string,
            blurb: PropTypes.string.isRequired,
            blurb_prefix: PropTypes.string,
            company_name: PropTypes.string.isRequired,
            country_code: PropTypes.string.isRequired,
            end_datetime: PropTypes.string,
            end_time: PropTypes.number,
            start_datetime: PropTypes.string.isRequired,
            start_time: PropTypes.number,
            hide_link: PropTypes.bool,
            id: PropTypes.number.isRequired,
            link: PropTypes.string,
            logo: PropTypes.string.isRequired,
            paused: PropTypes.bool,
            promo_code: PropTypes.string,
            text: PropTypes.string.isRequired,
            timezone: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

export default BenefitsModal;
