import React, {useState} from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import config from 'config';

const UserBannedBaged = ({
    banned,
    styles,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    if(!banned.is_banned) return null
    return (
        <div>
            <div
                className='mpa-state-badge'
                style={({
                    background: config.statusColors.banned,
                    minWidth: 80,
                    ...styles,
                })}
                onClick={() => setIsOpen(!isOpen)}
            >
                Banned
            </div>
            {isOpen && <div className='banned-reason'>{banned.reason}</div>}
        </div>
        
    );
}

UserBannedBaged.propTypes = {
    banned: PropTypes.shape({is_banned: PropTypes.bool, reason:PropTypes.string}),
    styles: PropTypes.shape(),
};

export default UserBannedBaged;
