import { filter } from 'lodash';

export const selectFilteredJobs = state => filter(state.jobs.jobs, j => !j.employer.test_employer);

export const selectShifts = jobs => jobs.reduce((arr, job) => arr.concat(job.shifts.map(shift => ({
    ...shift,
    occupation: job.occupation_id,
    employerId: job.employer.id,
    isBooked: shift.bookings.length > 0,
    introduction: job.introduction,
}))), []);

export const selectShiftsHours = shifts => shifts.reduce((t, s) => t + s.duration, 0);
