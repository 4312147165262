import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import { size } from 'lodash';

import styles from './styles.scss';

export default class Sidebar extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        children: PropTypes.any,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    renderLoader() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 32,
                }}
            >
                <span
                    className='fa fa-circle-o-notch fa-spin'
                />
            </div>
        );
    }

    renderLargeCloseButton() {
        return (
            <div className='mpa-sidebar-large-close'>
                <div
                    className='mpa-sidebar-large-close-close'
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span
                        className='ion-arrow-back'
                    />
                </div>
            </div>
        );
    }

    renderInner() {
        const {
            isLoading, open, children,
        } = this.props;

        if (isLoading) {
            return this.renderLoader();
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflowX: 'visible',
                }}
            >
                {children}
            </div>
        );
    }

    render() {
        const {
            open, onClose, style,
        } = this.props;

        return (
            <div
                className={classNames({
                    'mpa-sidebar': true,
                    'mpa-sidebar--open': open,
                })}
                style={style}
            >
                <div className='mpa-sidebar-top-bar'>
                    <div
                        className='mpa-sidebar-close'
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <span
                            className='ion-close-round'
                        />
                    </div>
                </div>

                <div
                    className='mpa-sidebar-left-column'
                    onClick={() => {
                        onClose();
                    }}
                >
                    <div className='mpa-sidebar-close'>
                        <span
                            className='ion-chevron-right'
                        />
                    </div>
                </div>

                {this.renderInner()}

            </div>
        );
    }
}
