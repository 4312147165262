import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import {
    DatePicker,
    ConfigProvider,
} from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import './styles.scss';

const DateRangePicker = ({
    value,
    setValue,
    disabledDate = null,
}) => (
    <ConfigProvider
        locale={en_GB}
    >
        <DatePicker.RangePicker
            className='mpa-date-range-picker'
            disabledDate={disabledDate}
            dropdownClassName='mpa-date-range-picker__calendar'
            size='large'
            value={value}
            onChange={date => setValue(date)}
        />
    </ConfigProvider>
);

DateRangePicker.propTypes = {
    value: PropTypes.arrayOf(PropTypes.object),
    setValue: PropTypes.func.isRequired,
    disabledDate: PropTypes.func,
};

export default withFormsy(DateRangePicker);
