import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    groupBy,
    includes,
    times,
} from 'lodash';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchShifts } from 'actions/available_shifts';
import moment from 'moment';
import LoadingScreen from 'components/shared/loadingscreen';
import { humanReadableHours } from 'utils/datetimeformatters';
import { departmentTypeTitle } from 'utils/nameformatters';
import stringToColor from 'utils/stringtocolor';
import {
    employersFilterSetCounties,
    employersFilterSetCountries,
    employersFilterSetDepartmentTypes,
    employersFilterSetEmployerType,
    employersFilterSetMaintainingType,
    employersFilterToggleIncludeTestAccounts,
} from 'actions/employersfilter';
import classNames from 'utils/classnames';
import EmployerFilter from 'components/employerfilter';
import config from 'config';
import './styles.scss';

const shiftByTestAccountSelector = (shifts, includeTestAccounts) => {
    if (includeTestAccounts) {
        return shifts;
    }

    return shifts.filter(shift => !shift.employer.test_employer);
};

const shiftsByCountrySelector = (shifts, countryCode) => {
    if (!countryCode.length) {
        return shifts;
    }

    return shifts.filter(shift => countryCode === shift.employer.country_code);
};

const shiftsByCountySelector = (shifts, countyIds) => {
    if (!countyIds.length) {
        return shifts;
    }

    return shifts.filter(shift => includes(countyIds, shift.employer.county_id));
};

const shiftsByDepartmentTypeSelector = (shifts, departmentTypeIds) => {
    if (!departmentTypeIds.length) {
        return shifts;
    }

    return shifts.filter(shift => includes(departmentTypeIds, shift.employer.secondary_department_type_id));
};

const shiftsByTypeSelector = (shifts, employerType) => {
    switch (employerType) {
        case 'ALL':
            return shifts;
        case 'PUBLIC':
            return shifts.filter(shift => shift.employer.employer_type === 1);
        case 'PRIVATE':
            return shifts.filter(shift => shift.employer.employer_type === 2);

        default:
            return shifts;
    }
};

const shiftsByMaintainingTypeSelector = (shifts, employerMaintainingType) => {
    if (employerMaintainingType === 'ALL') {
        return shifts;
    } if (employerMaintainingType === 'AUTOMOTIVE') {
        return shifts.filter(shift => shift.employer.automotive);
    }

    return shifts.filter(shift => !shift.employer.automotive);
};

const shiftsSelector = (state, shiftsByTestAccount) => {
    const { employersFilter } = state;
    return shiftsByCountrySelector(
        shiftsByCountySelector(
            shiftsByDepartmentTypeSelector(
                shiftsByTypeSelector(
                    shiftsByMaintainingTypeSelector(
                        shiftsByTestAccount, employersFilter.employerMaintainingType,
                    ),
                    employersFilter.employerType,
                ),
                employersFilter.departmentTypes,
            ),
            employersFilter.counties,
        ),
        employersFilter.countryCode,
    );
};

const ShiftsCalendar = ({
    calendarShifts,
    hasFetched,
    isFetching,
    counties,
    secondaryDepartmentTypes,
    numAutomotive,
    numMaintained,
    numPrivate,
    numPublic,
    employersFilter,
    fetchShifts,
    employersFilterSetCounties,
    employersFilterSetCountries,
    employersFilterSetDepartmentTypes,
    employersFilterSetEmployerType,
    employersFilterSetMaintainingType,
    employersFilterToggleIncludeTestAccounts,
}) => {
    useEffect(() => {
        if (!hasFetched && !isFetching) { fetchShifts(); }
    }, [hasFetched, isFetching, fetchShifts]);

    const renderDayShifts = shifts => {
        if (!shifts) {
            return ('');
        }

        return shifts.map(shift => {
            const { employer } = shift;
            const departmentType = departmentTypeTitle(employer.secondary_department_type_id);
            return (
                <div
                    style={{
                        // display: 'flex',
                        marginTop: 5 }}
                >
                    <Link to={`/employers/${employer.id}`}>
                        <div
                            style={{
                                display: 'flex', alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 600, fontSize: 13,
                                }}
                            >
                                {employer.name}
                            </div>
                        </div>
                    </Link>
                    <div style={{ fontSize: 11 }}>

                        <div>
                            {moment.unix(shift.start_time).format('HH:mm')}
                            {' '}
                            -
                            {' '}
                            {moment.unix(shift.end_time).format('HH:mm')}
                            {' '}
                            | Rast:
                            {' '}
                            {humanReadableHours(shift.shift_break)}
                        </div>
                        <div
                            className={classNames({
                                'mpa-tag': true,
                                'mpa-tag--small': true,
                            })}
                            style={{ background: departmentType ? stringToColor(departmentType) : 'none' }}
                        >
                            {departmentType}
                        </div>
                        {/* <div>
                                {booking.checked_in ? <div style={{color: '#3dde96'}}>Checked in</div> : <div style={{color: '#ff8ba9'}}>Not checked in</div>}
                            </div> */}
                    </div>
                </div>
            );
        });
    };

    const renderWeekDays = (week, shifts) => {
        const nodes = [];
        const m = moment.unix(week);

        times(7, () => {
            const date = m.format('ddd D/M');
            let dayShifts = [];
            if (shifts && shifts.length) {
                dayShifts = shifts.filter(shift => moment.unix(shift.start_time).isSame(m, 'day'));
            }
            nodes.push(
                <div
                    style={{
                        flex: 1,
                        minHeight: 150,
                        background: '#f9f9f9',
                        margin: 1,
                        padding: 10,
                    }}
                >
                    <div
                        style={{
                            fontSize: 12,
                            color: '#999',
                        }}
                    >
                        {date}
                    </div>
                    <div>
                        {renderDayShifts(dayShifts)}
                    </div>
                </div>,
            );
            m.add(1, 'day');
        });

        return nodes;
    };

    const renderWeek = (week, shifts) =>
        // let days = groupBy(bookings, b => moment.unix(b.start_time).format('ddd D/M'));
        (
            <div style={{ marginBottom: 20 }}>
                <div
                    style={{
                        fontWeight: 600,
                        marginBottom: 5,
                    }}
                >
                    Week
                    {' '}
                    {moment.unix(week).week()}
                </div>
                <div
                    style={{ display: 'flex' }}
                >
                    {renderWeekDays(week, shifts)}
                </div>
            </div>
        );
    const renderWeeks = () => {
        const allBookings = calendarShifts.filter(b => moment.unix(b.end_time).startOf('week').unix() >= moment().startOf('week').unix());
        const weeklyBookings = groupBy(allBookings, b => moment.unix(b.start_time).startOf('day').startOf('week').unix());
        const m = moment().startOf('day').startOf('week');

        const weeks = [
            m.unix(),
            m.clone().add(1, 'week').unix(),
            m.clone().add(2, 'weeks').unix(),
            m.clone().add(3, 'weeks').unix(),
            m.clone().add(4, 'weeks').unix(),
            m.clone().add(5, 'weeks').unix(),
            m.clone().add(6, 'weeks').unix(),
            m.clone().add(7, 'weeks').unix(),
            m.clone().add(8, 'weeks').unix(),
            m.clone().add(9, 'weeks').unix(),
            m.clone().add(10, 'weeks').unix(),
            m.clone().add(11, 'weeks').unix(),
            m.clone().add(12, 'weeks').unix(),
            m.clone().add(13, 'weeks').unix(),
        ];

        return (
            <div>
                {weeks.map(week => renderWeek(week, weeklyBookings[week]))}
            </div>
        );
    };

    const renderContent = () => {
        if (isFetching) {
            return (
                <LoadingScreen
                    fullScreen
                    text='Me cago en la mar salada'
                />
            );
        }
        return renderWeeks();
    };

    const toggleTestAccounts = () => {
        employersFilterToggleIncludeTestAccounts();
    };

    if (!hasFetched) {
        return (
            <LoadingScreen
                fullScreen
                text='Never challenge Kajsa to play ping pong'
            />
        );
    }
    return (
        <main className='shifts-calendar'>
            <div className='col-md-3'>
                <h2>Calendar</h2>
            </div>
            <div
                style={{
                    // paddingTop: 65,
                    display: 'flex',
                    flex: 1,
                    height: '100%',
                    // height: 'calc(100vh - 65px)',
                    // background: '#FFF',
                }}
            >

                <div
                    style={{
                        // width: 300,
                        borderRight: '1px solid #eee' }}
                >
                    <EmployerFilter
                        counties={counties}
                        countries={config.countries}
                        employersFilter={employersFilter}
                        numAutomotive={numAutomotive}
                        numMaintained={numMaintained}
                        numPrivate={numPrivate}
                        numPublic={numPublic}
                        secondaryDepartmentTypes={secondaryDepartmentTypes}
                        setCounties={employersFilterSetCounties}
                        setCountries={employersFilterSetCountries}
                        setDepartmentTypes={employersFilterSetDepartmentTypes}
                        setEmployerType={employersFilterSetEmployerType}
                        setMaintainingType={employersFilterSetMaintainingType}
                        toggleTestAccounts={toggleTestAccounts}
                    />
                </div>
                <div className='col-md-11 row justify-content-between'>

                    <div
                        className='col-md-11 content-card-full'
                        style={{
                            marginTop: 50,
                            marginLeft: 0,
                            overflow: 'scroll',
                        }}
                    >
                        {renderContent()}
                    </div>
                </div>
            </div>
        </main>

    );
};

ShiftsCalendar.propTypes = {
    calendarShifts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        start_time: PropTypes.number.isRequired,
        end_time: PropTypes.number.isRequired,
        shift_break: PropTypes.number.isRequired,
        employer: PropTypes.shape({
            id: PropTypes.number.isRequired,
            automotive: PropTypes.bool.isRequired,
            test_employer: PropTypes.bool.isRequired,
            county_id: PropTypes.number.isRequired,
            employer_type: PropTypes.number.isRequired,
            secondary_department_type_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
    hasFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    numAutomotive: PropTypes.number.isRequired,
    numMaintained: PropTypes.number.isRequired,
    numPublic: PropTypes.number.isRequired,
    numPrivate: PropTypes.number.isRequired,
    fetchShifts: PropTypes.func.isRequired,
    employersFilterSetCounties: PropTypes.func.isRequired,
    employersFilterSetCountries: PropTypes.func.isRequired,
    employersFilterSetDepartmentTypes: PropTypes.func.isRequired,
    employersFilterSetEmployerType: PropTypes.func.isRequired,
    employersFilterSetMaintainingType: PropTypes.func.isRequired,
    employersFilterToggleIncludeTestAccounts: PropTypes.func.isRequired,
    counties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    secondaryDepartmentTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => {
    const shiftsByTestAccount = shiftByTestAccountSelector(state.availableShifts.shifts, state.employersFilter.includeTestAccounts);
    return {
        calendarShifts: shiftsSelector(state, shiftsByTestAccount),
        hasFetched: state.availableShifts.hasFetched,
        isFetching: state.availableShifts.isFetching,
        shiftByTestAccountSelector,
        numAutomotive: shiftsByTestAccount.filter(s => s.employer.automotive).length,
        numMaintained: shiftsByTestAccount.filter(s => !s.employer.automotive).length,
        numPublic: shiftsByTestAccount.filter(s => s.employer.employer_type === 1).length,
        numPrivate: shiftsByTestAccount.filter(s => s.employer.employer_type === 2).length,
        employersFilter: { ...state.employersFilter },
        counties: [...state.settings.counties],
        secondaryDepartmentTypes: [...state.settings.secondary_department_types],
    };
};

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        fetchShifts,
        employersFilterSetCounties,
        employersFilterSetCountries,
        employersFilterSetDepartmentTypes,
        employersFilterSetEmployerType,
        employersFilterSetMaintainingType,
        employersFilterToggleIncludeTestAccounts,
    }, dispatch),
)(ShiftsCalendar);
