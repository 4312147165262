import * as request from '../request';

export const requestLtvs = () => ({ type: 'REQUEST_LTVS' });

export const requestSingleLtv = () => ({ type: 'REQUEST_SINGLE_LTV' });

export const recieveLtvs = ltvs => ({
    type: 'RECEIVE_LTVS',
    payload: ltvs,
});

export const recieveLtvsError = error => ({
    type: 'RECEIVE_LTVS_ERROR',
    payload: error,
});

export const updateSingleLtv = ltv => ({
    type: 'UPDATE_SINGLE_LTV',
    payload: ltv,
});

export const deleteSingleLtv = ltv => ({
    type: 'DELETE_SINGLE_LTV',
    payload: ltv,
});

export const fetchLtvs = includeTestAccounts => dispatch => {
    dispatch(requestLtvs());
    request.getLongThermVacancies(includeTestAccounts)
        .then(({ collection }) => {
            dispatch(recieveLtvs(collection));
        })
        .catch(err => dispatch(recieveLtvsError(err)));
};

export const fetchSingleLtv = id => dispatch => {
    dispatch(requestSingleLtv());
    request.getSingleLongThermVacancies(id)
        .then(({ ltv }) => {
            dispatch(updateSingleLtv(ltv));
        })
        .catch(err => dispatch(recieveLtvsError(err)));
};
