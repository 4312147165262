const initialState = {
    employer_attests: [],
    isUpdatingScheduled: false,
    hasUpdatedScheduled: true,
};

export function employer_attests(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_EMPLOYER_ATTESTS':
            return {
                ...state, employer_attests: action.employer_attests,
            };

        case 'REQUEST_BATCH_REPORT_SCHEDULED':
            return {
                ...state,
                isUpdatingScheduled: true,
                hasUpdatedScheduled: false,
            };

        case 'RECEIVE_BATCH_REPORT_SCHEDULED':
            return {
                ...state,
                isUpdatingScheduled: false,
                hasUpdatedScheduled: true,
            };

        default:
            return state;
    }
}
