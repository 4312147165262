import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

const CopyImages = ({
    coverUrl,
    logoUrl,
    shouldCopyCover,
    shouldCopyLogo,
}) => (
    <header className='copy-images'>
        {shouldCopyLogo && (
            <div>
                <h3 className='heading'>Logo:</h3>
                <Avatar
                    id='copy-logo'
                    size={100}
                    src={logoUrl}
                    style={{ boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}
                />
                <img
                    alt=''
                    crossOrigin='anonymous'
                    id='logoSource'
                    src={logoUrl}
                    style={{ display: 'none' }}
                />
            </div>
        )}
        {shouldCopyCover && (
            <div>
                <h3 className='heading'>Omslagsbild:</h3>
                <img
                    alt='Omslagsbild'
                    height={100}
                    id='copy-cover'
                    src={coverUrl}
                />
                <img
                    alt='Omslagsbild'
                    crossOrigin='anonymous'
                    id='coverSource'
                    src={coverUrl}
                    style={{ display: 'none' }}
                />
            </div>
        )}
    </header>
);

CopyImages.propTypes = {
    shouldCopyLogo: PropTypes.bool,
    shouldCopyCover: PropTypes.bool,
    logoUrl: PropTypes.string,
    coverUrl: PropTypes.string,
};

export default CopyImages;
