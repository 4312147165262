import React, {
    useState,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchInvoiceList,
    exportInvoiceList,
} from 'actions/invoicelist';
import { reduce } from 'lodash';
import { Link } from 'react-router-dom';

import {
    Table,
    Button,
    Alert,
} from 'antd';
import './styles.scss';
import PropTypes from 'prop-types';
import { humanReadableHours } from 'utils/datetimeformatters';
import RowDetailsTable from './rowdetailstable';

const InvoiceList = ({
    invoiceList,
    isExporting,
    isFetching,
    hasFetched,
    exportInvoiceList,
    fetchInvoiceList,
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        if (!hasFetched) {
            fetchInvoiceList();
        }
    }, [fetchInvoiceList, hasFetched]);

    const renderTable = () => {
        const columns = [{
            title: 'Uppdragsgivare',
            dataIndex: 'employer',
            key: 'employer',
            render: (text, record) => (
                <Link to={`/employers/${record.employer.id}`}>
                    {record.employer.name}
                </Link>
            ),
        }, {
            title: 'Uppdragsgivare ID',
            dataIndex: 'employerid',
            key: 'employerid',
            render: (text, record) => record.employer.id,
        }, {
            title: 'Pass',
            dataIndex: 'shifts',
            key: 'shifts',
            render: (text, record) => record.scheduled.length,
        }, {
            title: 'Timmar',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => humanReadableHours(
                reduce(record.scheduled, (hours, r) => hours + ((r.duration - r.shift_break) + r.deviation), 0),
            ),
        }, {
            title: 'Övertid',
            dataIndex: 'deviation',
            key: 'deviation',
            render: (text, record) => humanReadableHours(reduce(record.scheduled, (hours, r) => hours + r.deviation, 0)),
        }, {
            title: 'Rast',
            dataIndex: 'shift_break',
            key: 'shift_break',
            render: (text, record) => humanReadableHours(reduce(record.scheduled, (hours, r) => hours + r.shift_break, 0)),
        }, {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text, record) => `${reduce(record.scheduled, (sum, r) => sum + (((r.duration - r.shift_break) + r.deviation) * r.price), 0).toLocaleString('sv-se')}kr`,
        }, {
            title: 'Fakturatyp',
            dataIndex: 'monthly_invoice',
            key: 'total',
            render: (text, record) => (record.monthly_invoice ? 'Månadsfaktura' : 'Veckofaktura'),
        }];

        const rowSelection = {
            selectedRowKeys,
            onChange: (_, selectedRows) => {
                setSelectedIds(selectedRows.map(r => r.employer.id));
                setSelectedRowKeys(selectedRows.map(r => r.key));
            },
        };

        const data = invoiceList.map((item, i) => ({
            ...item,
            key: i + 1,
        }));

        const getWeeklyInvoiceRows = () => data.reduce((filtered, row) => {
            if (!row.employer.monthly_invoice) {
                filtered.push(row);
            }
            return filtered;
        }, []);
        const getMonthlyInvoiceRows = () => data.reduce((filtered, row) => {
            if (row.employer.monthly_invoice) {
                filtered.push(row);
            }
            return filtered;
        }, []);

        return (
            <>
                <Button
                    onClick={() => {
                        const weeklyRows = getWeeklyInvoiceRows();
                        setSelectedRowKeys(weeklyRows.map(r => r.key));
                        setSelectedIds(weeklyRows.map(r => r.employer.id));
                    }}
                >
                    Markera Veckofaktura
                </Button>
                <Button
                    onClick={() => {
                        const monthlyRows = getMonthlyInvoiceRows();
                        setSelectedRowKeys(monthlyRows.map(r => r.key));
                        setSelectedIds(monthlyRows.map(r => r.employer.id));
                    }}
                >
                    Markera Månadsfaktura
                </Button>
                <Table
                    columns={columns}
                    dataSource={data}
                    expandedRowRender={record => (
                        <RowDetailsTable
                            scheduled={record.scheduled}
                        />
                    )}
                    pagination={false}
                    rowSelection={rowSelection}
                />
            </>

        );
    };

    const renderSubmit = () => (
        <div
            style={{
                display: 'flex',
                marginTop: 20,
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >

            <Alert
                description='Efter att fakturorna skapats behöver du gå in i Fortnox för att skicka iväg dem till kund.'
                type='warning'
            />
            <div
                style={{ display: 'flex' }}
            >
                <div>
                    <Button
                        disabled={!selectedIds.length || isExporting}
                        loading={isExporting}
                        size='large'
                        type='primary'
                        onClick={() => {
                            exportInvoiceList(selectedIds);
                        }}
                    >
                        Skapa
                        {' '}
                        {selectedIds.length}
                        {' '}
                        fakturor
                    </Button>
                </div>
            </div>
        </div>

    );

    const renderContent = () => {
        if (isFetching) {
            return (
                <div
                    style={{
                        height: 400,
                        margin: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 24,
                    }}
                >
                    <i className='fa fa-circle-o-notch fa-spin' />
                </div>
            );
        }

        if (!isFetching && !invoiceList.length) {
            return (
                <div>
                    Det finns inga fakturor att skapa
                </div>
            );
        }

        return (
            <div>
                <h3>Fakturaexport</h3>
                <div
                    style={{
                        background: '#FFF',
                        marginTop: 40,
                        overflow: 'scroll',
                    }}
                >
                    {renderTable()}

                </div>
                {renderSubmit()}
            </div>
        );
    };

    return (

        <main className='invoice-list'>
            {renderContent()}
        </main>

    );
};

InvoiceList.propTypes = {
    invoiceList: PropTypes.arrayOf(PropTypes.shape({
        employer: PropTypes.shape({
            monthly_invoice: PropTypes.number,
        }),
        scheduled: PropTypes.arrayOf(PropTypes.shape({
            user_id: PropTypes.number,
            shift_break: PropTypes.number,
            price: PropTypes.number,
            duration: PropTypes.number,
            deviation: PropTypes.number,
            start_time: PropTypes.number,
            end_time: PropTypes.number,
            profile: PropTypes.shape({ name: PropTypes.string }),
        })),
    })),
    isExporting: PropTypes.bool,
    isFetching: PropTypes.bool,
    hasFetched: PropTypes.bool,
    exportInvoiceList: PropTypes.func,
    fetchInvoiceList: PropTypes.func,
};

const mapStateToProps = state => ({
    isExporting: state.invoiceList.isExporting,
    isFetching: state.invoiceList.isFetching,
    hasFetched: state.invoiceList.hasFetched,
    invoiceList: state.invoiceList.invoiceList,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        exportInvoiceList, fetchInvoiceList,
    }, dispatch),
)(InvoiceList);
