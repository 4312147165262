import * as request from 'request';

export const requestFetchShifts = () => ({ type: 'REQUEST_FETCH_SHIFTS' });

export const receiveUpdateShift = shifts => ({
    type: 'RECEIVE_FETCH_SHIFTS',
    shifts,
});

export const fetchShifts = requestData => dispatch => {
    dispatch(requestFetchShifts());
    return request.fetchAvailableShifts(requestData)
        .then(resp => {
            dispatch(receiveUpdateShift(resp.collection));
        }, error => {
            message.error(error.responseJSON.message);
        });
};
