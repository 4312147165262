import React from 'react';
import EditTrigger from 'components/shared/edittrigger';
import DeleteTrigger from 'components/shared/deletetrigger';
import InputModal from 'components/inputmodal';
import config from 'config';
import './styles.scss';
import { Modal } from 'antd';

class EmployerContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            formData: {},
        };
    }

    onClose() {
        this.setState({
            showEditModal: false,
            formData: {},
        });
    }

    showDeleteContactModal() {
        const employerId = this.props.match.params.id;
        const { employerContact } = this.props;

        Modal.confirm({
            title: `Ta bort ${employerContact.name}`,
            content: `Bekräfta att du vill ta bort kontaktperson ${employerContact.name}. Detta går inte att ångra`,
            okText: 'Ta bort',
            okType: 'danger',
            cancelText: 'Avbryt',
            onOk: () => {
                this.props.deleteEmployerContact(employerId, employerContact.id);
            },
            onCancel() {},
        });
    }

    renderDelete() {
        const { employerContact } = this.props;

        return (
            <DeleteTrigger
                style={{
                    display: 'inline-block',
                    paddingLeft: 5,
                }}
                onClick={() => {
                    this.showDeleteContactModal(employerContact);
                }}
            />
        );
    }

    renderEdit() {
        const { id } = this.props.employer.employer;
        const { employerContact } = this.props;

        return (
            <span>
                <EditTrigger
                    style={{
                        display: 'inline-block',
                        paddingLeft: 5,
                    }}
                    title=''
                    onClick={() => {
                        this.setState({ showEditModal: true });
                    }}
                />
                <Modal
                    key={`contactModal${employerContact.id}`}
                    cancelText='Avbryt'
                    okText='Spara'
                    title='Ändra kontaktperson'
                    visible={this.state.showEditModal}
                    onCancel={() => {
                        this.onClose();
                    }}
                    onOk={() => {
                        const { formData } = this.state;
                        this.props.updateEmployerContact(id, employerContact.id, formData).then(() => {
                            this.onClose();
                        });
                    }}
                >
                    <InputModal
                        ref='editContactPerson'
                        hideButton
                        inputs={[
                            {
                                type: 'input',
                                name: 'name',
                                label: 'Name',
                                value: employerContact.name,
                            },
                            {
                                type: 'input',
                                name: 'title',
                                label: 'Title',
                                value: employerContact.title,
                            },
                            {
                                type: 'input',
                                name: 'phone',
                                label: 'Phone',
                                value: employerContact.phone,
                            },
                            {
                                type: 'input',
                                name: 'email',
                                label: 'Email',
                                inputType: 'email',
                                value: employerContact.email,
                                validations: { matchRegexp: config.regexp.email },
                                validationErrors: { matchRegexp: 'Not a valid email' },
                            },
                            {
                                type: 'switch',
                                name: 'contact_status',
                                label: 'Active contact person',
                                optionLabels: {
                                    on: 'Yes',
                                    off: 'No',
                                },
                                value: employerContact.contact_status,

                            },
                        ]}
                        onChange={formData => this.setState({ formData })}
                    />
                </Modal>
            </span>
        );
    }

    render() {
        const { employerContact } = this.props;

        return (
            <div
                className='content-card-inline col-sm-4'
                style={{
                    maxWidth: 'calc(33.33333% - 10px)',
                    minWidth: 300,
                }}
            >
                <div
                    style={{
                        fontWeight: 600, fontSize: 18,
                    }}
                >
                    <div>
                        {employerContact.name}
                        {this.renderEdit()}
                        {this.renderDelete()}
                    </div>
                </div>
                <div>{employerContact.title}</div>
                <div
                    style={{ marginTop: 5 }}
                >
                    <small>
                        Status:
                        {' '}
                        <strong>{employerContact.contact_status ? 'Active' : 'Inactive'}</strong>
                    </small>
                </div>
                <div
                    style={{ marginTop: 5 }}
                >
                    <div>
                        <i
                            className='fa fa-envelope'
                            style={{ paddingRight: 10 }}
                        />
                        {employerContact.email && employerContact.email.length > 1
                            ? <a href={`mailto:${employerContact.email}`}>{employerContact.email}</a>
                            : '-'}
                    </div>
                    <div>
                        <i
                            className='fa fa-phone'
                            style={{ paddingRight: 10 }}
                        />
                        {employerContact.phone && employerContact.phone.length > 1 ? employerContact.phone : '-'}
                    </div>
                </div>
            </div>
        );
    }
}

export default EmployerContact;
