import React from 'react';
import PropTypes from 'prop-types';
import {
    filter,
    find,
    includes,
} from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tagsActions from 'actions/tags';
import Tag from 'components/tag';
import './styles.scss';
import classNames from 'utils/classnames';
import AddTag from './addtag';

class TagsList extends React.Component {
    static propTypes = {
        tagsList: PropTypes.array.isRequired,
        consultantId: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = { showAddTag: false };
    }

    createAndAddTag(consultantId, tagTitle) {
        this.props.createAndAddTag(consultantId, tagTitle);
    }

    addTag(consultantId, tagId) {
        this.props.addTagToConsultant(consultantId, tagId);
    }

    removeTag(consultantId, tagId) {
        this.props.removeTagFromConsultant(consultantId, tagId);
    }

    renderAddTag() {
        if (!this.state.showAddTag) {
            return null;
        }

        const {
            tags, tagsList, consultantId,
        } = this.props;
        return (
            <AddTag
                addedTags={tagsList}
                addTag={this.addTag.bind(this, consultantId)}
                availableTags={tags}
                close={() => {
                    this.setState({ showAddTag: false });
                }}
                createAndAddTag={(this.createAndAddTag.bind(this, consultantId))}
            />
        );
    }

    renderAddTagTrigger() {
        return (
            <div
                className='mpa-tags-list__add-trigger'
                onClick={e => {
                    e.stopPropagation();
                    this.setState({ showAddTag: true });
                }}
            >
                <span
                    className='ion-android-add-circle'
                    style={{ marginRight: 5 }}
                />
                Add tag
                {this.renderAddTag()}
            </div>
        );
    }

    renderTags() {
        return this.props.tagsList.map(tag => (
            <span key={tag.id}>
                {tag && (
                    <Tag
                        small={this.props.small}
                        tag={tag}
                        onRemove={this.removeTag.bind(this, this.props.consultantId, tag.id)}
                    />
                )}
            </span>
        ));
    }

    render() {
        return (
            <div
                className={classNames({
                    consultant__tags: true,
                    'mpa-tags-list': true,
                    'mpa-tags-list--small': this.props.small,
                })}
            >
                {this.renderTags()}
                {this.renderAddTagTrigger()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    tagsList: ownProps.tagsList.map(tagId => find(state.tags.tags, { id: tagId })),
    tags: filter(state.tags.tags, t => !includes(ownProps.tagsList, t.id)),
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(tagsActions, dispatch),
)(TagsList);
