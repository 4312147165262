import React, { useState } from 'react';
import {
    occupationTitle,
    specializationTitle,
    jobPostingStatus,
} from 'utils/nameformatters';
import moment from 'moment';
import './styles.scss';
import {
    Button,
    Select,
    Tag,
} from 'antd';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { CopyOutlined } from '@ant-design/icons';
import EditJobAdModal from './editjobadmodal';
import config from '../../../config';
import {
    acceptRecruitmentApplication,
    rejectRecruitmentApplication,
} from '../../../actions/recruitments';
import JobAdApplications from './jobadapplications';

const JobAd = ({
    jobPosting,
    settings,
    onEdit,
    onRepublishJobPosting
}) => {
    const dispatch = useDispatch();
    const [showEditModal, setShowEditModal] = useState(false);

    const onRejectApplication = (applicationId, reasonId) => dispatch(rejectRecruitmentApplication(applicationId, reasonId));

    const onAcceptApplication = applicationId => dispatch(acceptRecruitmentApplication(applicationId));

    return (
        <div
            id={jobPosting.title}
            key={jobPosting.id}
            className='content-card-centered'
            style={{
                position: 'relative',
            }}
        >

            <div
                style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                }}
            >
                <Select
                    size='large'
                    style={{
                        marginRight: 10,
                    }}
                    value={jobPosting.job_posting_status}
                    onChange={val => {
                        onEdit({
                            ...jobPosting,
                            last_application_time: jobPosting.last_application_time * 1000,
                            job_posting_status: val,
                        }, jobPosting.id);
                    }}
                >
                    {map(config.jobPostingStatus, (value, key) => (
                        <Select.Option value={value}>
                            {jobPostingStatus(value)}
                        </Select.Option>
                    ))}
                </Select>
                <Button
                    shape='round'
                    style={{
                        marginRight: 10,
                    }}
                    size='large'
                    type='primary'
                    onClick={() => setShowEditModal(true)}
                >
                    Edit
                </Button>
                <Button
                    shape='round'
                    size='large'
                    onClick={() => onRepublishJobPosting(jobPosting.id)}
                >
                    Re-publish
                </Button>
            </div>
            <h2 className='m-0'>
                {jobPosting.title ? jobPosting.title : occupationTitle(jobPosting.occupation_id)}
            </h2>
            <div className='mb-2'>
                {occupationTitle(jobPosting.occupation_id)}
            </div>
            <div
                className='flex items-center mb-2'
            >
                <Tag
                    color={jobPosting.job_posting_status === config.jobPostingStatus.PUBLISHED ? 'green' : ''}
                >
                    {jobPostingStatus(jobPosting.job_posting_status)}
                </Tag>
                <Tag>
                    {moment.unix(jobPosting.created_time).fromNow()}
                </Tag>
            </div>

            <div className='content-specialization-div '>

                {jobPosting.specialization_ids.map(skill => (

                    <div key={skill.id} className='content-specialization-tag'>

                        {specializationTitle(skill)}

                    </div>
                ))}
            </div>
            <div className='content-info-div'>
                <div>
                    <strong>
                        id:
                        {' '}
                        {jobPosting.id}
                        {' '}
                        <CopyOutlined
                            onClick={() => {
                                navigator.clipboard.writeText(`https://medpeople.app.link/medpeople://recruitment-details/${jobPosting.id}`).then(() => {
                                    alert('Copied to clipboard');
                                });
                            }}
                        />
                    </strong>
                </div>
            </div>
            <div className='content-info-div'>
                <div>
                    <strong>What does this job mean?</strong>
                </div>
                {jobPosting.work_description}
            </div>

            <div className='content-info-div'>
                <div>
                    <strong>Expectation</strong>
                </div>
                {jobPosting.expectation}
            </div>

            <div className='content-info-div'>

                <div>
                    <strong>Additional Info</strong>
                </div>

                {jobPosting.additional_info}

            </div>

            <div className='content-info-div'>

                <div>
                    <strong>Is the salary negotiable?</strong>
                    {' '}
                    {jobPosting.salary_negotiable ? 'Yes' : 'No'}
                </div>

                <div>
                    <strong>Drivers license?</strong>
                    {' '}
                    {jobPosting.driver_license ? 'Yes' : 'No'}
                </div>

            </div>

            <div>
                <strong>Status:</strong>
                {' '}
                {jobPostingStatus(jobPosting.job_posting_status)}

            </div>

            <div>
                <strong>Created time:</strong>
                {' '}
                {`${moment.unix(jobPosting.created_time).format('dddd D MMMM')}`}
            </div>

            <div>
                <strong>Published: </strong>
                {jobPosting.job_posting_status === 1 ? `${moment.unix(jobPosting.published_time).format('dddd D MMMM')}` : 'This is not published yet.'}
                {jobPosting.updated_published_time && <span className='text-green-600 ml-2'>(Updated: {moment.unix(jobPosting.updated_published_time).format('dddd D MMMM')})</span>}
            </div>
            <div>
                <strong>Last possible application date: </strong>
                {jobPosting.job_posting_status === 1 ? `${moment.unix(jobPosting.last_application_time).format('dddd D MMMM')}` : 'This is not published yet.'}
            </div>
            <JobAdApplications
                applications={jobPosting.applications}
                onAcceptApplication={onAcceptApplication}
                onRejectApplication={onRejectApplication}
            />
            <EditJobAdModal
                jobPosting={jobPosting}
                settings={settings}
                setVisibility={val => { setShowEditModal(val); }}
                visible={showEditModal}
                onSave={data => onEdit(data, jobPosting.id)}
            />
        </div>
    );
};

export default JobAd;
