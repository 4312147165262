import React from 'react';
import PropTypes from 'prop-types';

export default function Company({
    name, logo,
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 100,
                    aspectRatio: '160 / 100',
                    backgroundColor: '#111',
                }}
            >
                {logo ? (
                    <img
                        alt={`${name} logo`}
                        src={logo}
                        style={{
                            width: 80,
                            maxHeight: '50',
                            objectFit: 'scale-down',
                        }}
                    />
                ) : (
                    <div
                        style={{
                            color: 'white',
                            fontSize: 8,
                            padding: 15,
                            textAlign: 'center',
                        }}
                    >
                        {name}
                    </div>
                )}
            </div>
            <div
                style={{
                    marginLeft: 12,
                    fontWeight: 'bold',
                    textAlign: 'right',
                }}
            >
                {name}
            </div>
        </div>
    );
}

Company.propTypes = {
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
};
