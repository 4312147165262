import * as request from 'request';

export function requestFetchEmployers() {
    return { type: 'REQUEST_FETCH_EMPLOYERS' };
}

const receiveEmployers = resp => {
    const totalCount = resp.employers ? resp.employers.length : 0;
    return {
        type: 'RECEIVE_EMPLOYERS',
        employers: resp.collection || resp.employers,
        totalCount,
        count: resp.count,
        page: resp.page,
        totalPages: resp.total_pages,
    };
}

export function fetchEmployers() {
    return dispatch => {
        dispatch(requestFetchEmployers());
        return request.fetchEmployers()
            .then(resp => dispatch(receiveEmployers(resp)));
    };
}


export function fetchEmployersV4(filters) {
    return dispatch => {
        dispatch(requestFetchEmployers());
        return request.fetchEmployersV4(filters)
            .then(resp => dispatch(receiveEmployers(resp)));
    };
}
