import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'antd';

import {
    userHasBankInfo,
    userHasAddress,
} from 'utils/user';
import StateBadge from 'components/shared/statebadge';
import LastActive from 'components/lastactive';
import UserIntentions from 'components/intentions';

import Consultant from './Consultant';
import InfoAvailable from './InfoAvailable';

const ConsultantsList = ({
    consultants,
    pagination: {
        page,
        totalCount,
    },
    onChange,
    onRow,
}) => {
    // Define table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstname',
            width: '50%',
            sorter: true,
            render: (_, consultant) => <Consultant consultant={consultant} />,
        },
        {
            title: 'E-Cert',
            dataIndex: 'ecert',
            key: 'ecert',
            render: text => <InfoAvailable state={!!text} />,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: text => <InfoAvailable state={!!text} />,
        },
        {
            title: 'Salary info',
            dataIndex: 'address',
            key: 'salaryInfo',
            render: (_, consultant) => <InfoAvailable state={userHasAddress(consultant) && userHasBankInfo(consultant)} />,
        },
        {
            title: 'Intentions',
            dataIndex: ['user_store', 'intended_usage'],
            key: ['user_store', 'intended_usage'],
            sorter: true,
            render: (_, consultant) => <UserIntentions intendedUsage={consultant.user_store.intended_usage} />,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            sorter: true,
            render: (_, consultant) => <StateBadge created={consultant.created} state={consultant.state} />,
        },
        {
            title: 'Last active',
            dataIndex: 'last_active',
            key: 'lastActive',
            sorter: true,
            render: text => <LastActive lastActive={text} />,
        },
        {
            title: 'Registered',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: text => moment.unix(text).format('D MMM YY'),
        },
        {
            title: 'Mail verified',
            dataIndex: 'email_verified',
            key: 'email_verified',
            sorter: true,
            render: bool => <InfoAvailable state={bool} />,
        },
    ];
    return consultants.length > 0 && (
        <Table
            columns={columns}
            dataSource={consultants}
            pagination={{
                position: 'both',
                current: page,
                pageSize: 20, // TODO: create a constant
                total: totalCount,
            }}
            rowKey='id'
            scroll={{ x: 1500 }}
            size='large'
            onChange={onChange}
            onRow={onRow}
        />
    );
};

ConsultantsList.propTypes = {
    consultants: PropTypes.arrayOf(PropTypes.shape({
        state: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        student: PropTypes.string,
        created: PropTypes.number,
        graduation_time: PropTypes.number,
    })).isRequired,
    pagination: PropTypes.shape({
        page: PropTypes.number,
        count: PropTypes.number,
        totalCount: PropTypes.number,
    }),
    onChange: PropTypes.func.isRequired,
    onRow: PropTypes.func.isRequired,
};

export default ConsultantsList;
