const initialState = {
    data: [],
    isFetching: true,
    hasFetched: false,
};

export default function activityTypes(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_ACTIVITY_TYPES':
            return {
                ...state,
                isFetching: true,
            };

        case 'RECEIVE_ACTIVITY_TYPES':
            return {
                ...state,
                data: action.activityTypes,
                isFetching: false,
                hasFetched: true,
            };

        default:
            return state;
    }
}
