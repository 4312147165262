import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Event from './Event';

const BookingEvent = ({ event: {
    created_time: eventTime,
    employer,
    user: {
        avatar,
        id,
        firstname,
        lastname,
    },
    meta_data: {
        shift_count: shifts,
        total_price: value,
    },
} }) => (
    <Event
        avatar={avatar}
        details={`${shifts} ${shifts === 1 ? 'shift' : 'shifts'}${value && `, ${value.toLocaleString('sv-se')} kr`}`}
        header={(
            <>
                <Link to={`/employers/${employer.id}`}>{employer.name}</Link>
                {' booked '}
                <Link to={`/consultants/${id}`}>
                    {`${firstname} ${lastname}`}
                </Link>
            </>
        )}
        time={eventTime}
    />
);

BookingEvent.propTypes = { event: PropTypes.shape({
    created_time: PropTypes.number,
    employer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    user: PropTypes.shape({
        id: PropTypes.number,
        avatar: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
    }),
    meta_data: PropTypes.shape({
        shift_count: PropTypes.number,
        total_price: PropTypes.number,
    }),
}).isRequired };

export default BookingEvent;
