import React from 'react';
import PropTypes from 'prop-types';

const LastActiveFilter = ({
    filters,
    activeFilters,
    onChange,
}) => (
    <div className='last-active-filter'>
        <label>Filter by last active:</label>

        {filters.map(({
            id,
            name,
            color,
        }) => (
            <div key={id}>
                <label className='last-active-filter__label'>
                    <input
                        checked={activeFilters.includes(id)}
                        type='checkbox'
                        onChange={() => onChange(id)}
                    />
                    {name}
                    <span
                        className='last-active-filter__color'
                        style={{ background: color }}
                    />
                </label>
            </div>
        ))}
    </div>
);

LastActiveFilter.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
    activeFilters: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
};

export default LastActiveFilter;
