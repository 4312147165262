import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    nl2br,
    mapToValueTitle,
    occupationTitle,
    skillTitle,
} from 'utils/nameformatters';
import { bindActionCreators } from 'redux';
import { createJobProfile } from 'actions/job';
import { updateJobProfile } from 'actions/employer';
import EditTrigger from 'components/shared/edittrigger';
import InputModal from 'components/inputmodal';
import {
    Alert,
    Modal,
    Popconfirm,
    Select,
} from 'antd';
import Button from 'components/shared/button';
import { BookOutlined } from '@ant-design/icons';
import './styles.scss';
import config from 'config';

const { Option } = Select;

const ProfileCard = ({
    profile,
    settings,
    employerId,
    createJobProfile,
    updateJobProfile,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedOccupation, setSelectedOccupation] = useState(profile ? profile.occupation_id : 1);
    const canDelete = profile && (
        profile.published.length + profile.bookings.length + profile.pending.length + profile.drafts.length
    ) === 0;
    const handleDelete = profileId => {
        updateJobProfile(profileId, { status: config.jobStatus.DELETED });
    };
    return (
        <div
            className='content-card-centered'
        >
            {profile ? (
                <>
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: 600,
                        }}
                    >
                        ID
                        {' '}
                        {profile.id}
                    </div>
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 600,
                        }}
                    >
                        {profile.name}
                    </div>
                    <div
                        style={{
                            color: '#999',
                            margin: '0 0 10px',
                            display: 'flex',
                        }}
                    >
                        {occupationTitle(profile.occupation_id)}
                        {!!profile.specialization_ids.length && (
                            <ul className='profile__list'>
                                {profile.specialization_ids.map(specializationId => (
                                    <li key={specializationId}>
                                        <BookOutlined />
                                        {settings.specializations.find(s => s.id === specializationId).name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: 5,
                        }}
                    >
                        {profile.skills.map(skill => (
                            <div
                                key={skill}
                                style={{
                                    padding: '2px 10px',
                                    background: '#EEE',
                                    color: '#999',
                                    borderRadius: 20,
                                    fontSize: 14,
                                    marginRight: 3,
                                }}
                            >
                                {skillTitle(skill, profile.occupation_id)}
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            marginTop: 10,
                        }}
                    >
                        <div>
                            <strong>Description</strong>
                        </div>
                        {profile.description ? nl2br(profile.description) : 'No description'}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            marginTop: 10,
                        }}
                    >
                        <div>
                            <strong>Qualifications</strong>
                        </div>
                        {profile.qualifications ? nl2br(profile.qualifications) : 'No qualifications'}
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            marginTop: 10,
                        }}
                    >
                        <div>
                            <strong>Additional info</strong>
                        </div>
                        {profile.additional_info ? nl2br(profile.additional_info) : 'No additional info'}
                    </div>
                    <div
                        style={{ marginTop: 20 }}
                    >
                        <div>
                            <strong>Drivers license:</strong>
                            {' '}
                            {profile.driver_license ? 'Yes' : 'No'}
                        </div>
                        <div>
                            <strong>Drafts:</strong>
                            {' '}
                            {profile.drafts.length}
                        </div>
                        <div>
                            <strong>Published:</strong>
                            {' '}
                            {profile.published.length}
                        </div>
                        <div>
                            <strong>Pending:</strong>
                            {' '}
                            {profile.pending.length}
                        </div>
                        <div>
                            <strong>Bookings:</strong>
                            {' '}
                            {profile.bookings.length}
                        </div>
                    </div>
                    <EditTrigger
                        title='Edit profile'
                        onClick={() => setShowModal(true)}
                    />
                    <div>
                        <Popconfirm
                            cancelText='No'
                            disabled={!canDelete}
                            okText='Yes'
                            title='Are you sure you want to delete the profile? '
                            onConfirm={() => { handleDelete(profile.id); }}
                        >
                            <Button
                                className='delete-job-btn'
                                disabled={!canDelete}
                                outlineWarn
                                small
                                type='danger'
                            >
                                Delete
                            </Button>

                        </Popconfirm>

                    </div>
                </>
            )
                : (
                    <EditTrigger
                        title='New profile'
                        onClick={() => setShowModal(true)}
                    />
                )}

            <Modal
                footer={null}
                title='Edit profile'
                visible={showModal}
                width={600}
                onCancel={() => setShowModal(false)}
            >
                {profile && (profile.bookings.length || profile.drafts.length || profile.pending.length || profile.published.length)
                    ? (
                        <Alert
                            // eslint-disable-next-line no-nested-ternary
                            description={`There are some ${profile.bookings.length ? 'booked' : profile.pending.length ? 'pending' : profile.published.length ? 'published' : 'drafted'} shifts associated`}
                            message='Job profile is in use'
                            showIcon
                            type='warning'
                        />
                    )
                    : ''}
                <InputModal
                    close={() => setShowModal(false)}
                    data={profile || {}}
                    inputs={
                        [{
                            type: 'input',
                            name: 'name',
                            label: 'Name',
                            value: profile ? profile.name : '',
                        }, {
                            type: 'select',
                            name: 'occupation_id',
                            label: 'Occupation',
                            options: mapToValueTitle(settings.occupations, 'id', 'name'),
                            value: profile ? profile.occupation_id : 2, // Default to swedish nurse
                        }, {
                            type: 'multiselect',
                            name: 'specialization_ids',
                            label: 'Specializations',
                            options: settings.specializations.filter(s => s.occupation_id === selectedOccupation)
                                .map(s => (
                                    <Option
                                        key={s.id}
                                        occupation={s.occupation_id}
                                        title={s.name}
                                        value={s.id}
                                    >
                                        {s.name}
                                    </Option>
                                )),
                            value: profile ? [...profile.specialization_ids] : [],
                        }, {
                            type: 'multiselect',
                            name: 'skills',
                            label: 'Skills',
                            options: settings.skills.filter(s => s.occupation_id === selectedOccupation)
                                .map(s => (
                                    <Option
                                        key={s.id}
                                        occupation={s.occupation_id}
                                        title={s.name}
                                        value={s.id}
                                    >
                                        {s.name}
                                    </Option>
                                )),
                            value: profile ? [...profile.skills] : [],
                        }, {
                            type: 'textarea',
                            name: 'description',
                            label: 'Description',
                            value: profile ? profile.description : '',
                        }, {
                            type: 'textarea',
                            name: 'qualifications',
                            label: 'Qualifications',
                            value: profile ? profile.qualifications : '',
                        }, {
                            type: 'textarea',
                            name: 'additional_info',
                            label: 'Additional info',
                            value: profile ? profile.additional_info : '',
                        }, {
                            type: 'switch',
                            name: 'driver_license',
                            label: 'Driver\'s license',
                            optionLabels: {
                                on: 'Yes',
                                off: 'No',
                            },
                            value: profile ? profile.driver_license : false,
                        }]
                    }
                    onChange={(formData, _, form) => {
                        if (selectedOccupation !== formData.occupation_id) {
                            const newSkills = formData.skills.filter(id => {
                                const skill = settings.skills.find(s => s.id === id);
                                return skill.occupation_id === formData.occupation;
                            });
                            const newSspecializations = formData.specialization_ids.filter(id => {
                                const specialization = settings.specializations.find(s => s.id === id);
                                return specialization.occupation_id === formData.occupation;
                            });
                            setSelectedOccupation(formData.occupation_id); // do not rely on this call for this function!
                            form.updateInputsWithValue({
                                skills: newSkills,
                                specialization_ids: newSspecializations,
                            });
                        }
                    }}
                    onSave={profile ? formData => updateJobProfile(profile.id, formData) : formData => createJobProfile(employerId, formData)}
                />
            </Modal>
        </div>
    );
};

ProfileCard.propTypes = {
    profile: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        occupation_id: PropTypes.number,
        description: PropTypes.string,
        qualifications: PropTypes.string,
        additional_info: PropTypes.string,
        driver_license: PropTypes.bool,
        specialization_ids: PropTypes.arrayOf(PropTypes.number),
        skills: PropTypes.arrayOf(PropTypes.number),
        bookings: PropTypes.shape({ length: PropTypes.number }),
        drafts: PropTypes.shape({ length: PropTypes.number }),
        pending: PropTypes.shape({ length: PropTypes.number }),
        published: PropTypes.shape({ length: PropTypes.number }),

    }).isRequired,
    settings: PropTypes.shape({
        occupations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        skills: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        specializations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }).isRequired,
    employerId: PropTypes.number.isRequired,
    createJobProfile: PropTypes.func.isRequired,
    updateJobProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profiles: state.employer.profiles.profiles || [],
    settings: state.settings || [],
});

const mapDispatchToProps = dispatch => bindActionCreators({
    createJobProfile, updateJobProfile,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
