const initialState = {
    jobs: [],
    isFetching: true,
    hasFetched: false,
};

export function jobs(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FETCH_JOBS':
            return {
                ...state, isFetching: true,
            };

        case 'RECEIVE_JOBS':
            return {
                ...state,
                jobs: action.jobs,
                isFetching: false,
                hasFetched: true,
            };

        default:
            return state;
    }
}
